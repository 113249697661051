import { IncLoadingSpinner } from "@inception/ui";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

type Position = "topcenter" | "center" | "topleft" | "right";
interface LoadingProps {
  titleText?: string;
  titleId?: string;
  position?: Position;
  className?: string;
}

const LoadingSpinner: React.FC<LoadingProps> = (props: LoadingProps) => {
  const { titleText, titleId, position = "center", className } = props;

  const { formatMessage } = useIntl();
  const text = useMemo(
    () => titleText || formatMessage({ id: titleId || "common.actions.loading.data.text" }),
    [formatMessage, titleId, titleText]
  );
  return (
    <>
      <IncLoadingSpinner
        className={className}
        label={text}
        position={position}
      />
    </>
  );
};

export default LoadingSpinner;
