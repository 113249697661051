import { CompareOperator, StaticThresholdDef } from "../../services/api/operationalise";

export const getThresholdDefByComparator = (comparator: CompareOperator, thresholdDef: StaticThresholdDef) => {
  const aboveThresholdDef =
    comparator === CompareOperator.Above || comparator === CompareOperator.AboveOrBelow ? thresholdDef : null;
  const belowThresholdDef =
    comparator === CompareOperator.Below || comparator === CompareOperator.AboveOrBelow ? thresholdDef : null;

  return {
    aboveThresholdDef,
    belowThresholdDef
  };
};
