import React, { CSSProperties, forwardRef, useMemo } from "react";
import { cx } from "emotion";
import { getStaticSpriteUrl } from "../constants";
import { ISaxIconProps, ISaxIconVariant } from "./types";
import { ISaxIconNames } from "./iconNames";
import { getPossibleMatchIcon } from "./getClosestMatch";

export const ISaxIcon = forwardRef<SVGSVGElement, ISaxIconProps>((props, ref) => {
  const {
    className: pClassName = "",
    spin = false,
    fade = false,
    beat = false,
    size = 16,
    variant = "Bold",
    iconName: pIconName,
    fill = "currentColor",
    color = "",
    style,
    ...restProps
  } = props;

  const iconName = ISaxIconNames.includes(pIconName) ? pIconName : getPossibleMatchIcon(pIconName);

  const className = useMemo(
    () =>
      cx("fa svg-inline--fa", {
        [pClassName]: Boolean(pClassName),
        "fa-spin": spin,
        "fa-pulse": beat,
        "fa-fade": fade
      }),
    [beat, fade, pClassName, spin]
  );

  const appliedStyle: CSSProperties = useMemo(() => {
    const appliedStyle: CSSProperties = {
      ...style,
      fontSize: size
    };

    if (color) {
      appliedStyle.fill = color;
      appliedStyle.color = color;
    }

    return appliedStyle;
  }, [color, size, style]);
  const spriteHref = getStaticSpriteUrl(`isax-icon-sprite/${folderMap[variant]}.svg#${iconName}`);

  return (
    <svg
      {...restProps}
      className={className}
      fill={fill}
      ref={ref}
      style={appliedStyle}
      viewBox="0 0 512 512"
    >
      <use xlinkHref={spriteHref} />
    </svg>
  );
});

const folderMap: Record<ISaxIconVariant, string> = {
  Outline: "outline",
  Bold: "bold",
  TwoTone: "two-tone"
};
