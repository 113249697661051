import { useCallback, useMemo } from "react";
import { FetchCallBackResult, FetchFn, InitialState, useDataFetch } from "../core";
import { BizEntityUserService, fieldPickerApiService } from "../services/api/explore";

export const useFetchBizEntityUserservices = (
  entityTypeId: string,
  startTimeMillis: number,
  endTimeMillis: number,
  includeSystemFieldInfo = false
) => {
  const initialState = useMemo<InitialState<BizEntityUserService[], string>>(
    () => ({
      data: [],
      error: "",
      isError: false,
      isFetching: true,
      isSuccess: false
    }),
    []
  );

  const fetchFn = useCallback<FetchFn<BizEntityUserService[], string>>(async () => {
    const result: FetchCallBackResult<BizEntityUserService[], string> = {
      data: [],
      error: "",
      isError: false,
      isSuccess: false
    };

    try {
      const { data, error, message } = await fieldPickerApiService.getBizEntityUserserviceList(
        entityTypeId,
        startTimeMillis,
        endTimeMillis,
        includeSystemFieldInfo
      );
      if (error) {
        result.error = message;
        result.isError = true;
      } else {
        result.data = data?.bizEntityUserServices || [];
        result.isSuccess = true;
      }
    } catch (err) {
      result.error = err.message || String(err);
      result.isError = true;
    }

    return result;
  }, [endTimeMillis, entityTypeId, includeSystemFieldInfo, startTimeMillis]);

  return useDataFetch(fetchFn, initialState, true);
};
