import { cloneDeep } from "lodash";
import { TimeRangeMillis, logger } from "../../../core";
import kbn from "../core/kbn";
import { ResultTransformer } from "../prometheus/result_transformer";
import { DatasourceApi } from "../../api/DatasourceService";
import {
  BaseApi,
  DataQuery,
  DataQueryRequest,
  DataQueryResponse,
  DatasourceSettings,
  InceptionError,
  InceptionRequestConfig,
  InceptionResponse
} from "../../api/types";
import { PREFIX_TAGS, PREFIX_FIELDS } from "../../api/traces/constants";
import { TraceQueryConfig, NestedAggregation, TraceQueryResult } from "./TracesTypes";

export interface TraceQuery extends DataQuery {
  periodicitySecs?: number;
  query: TraceQueryConfig;
  legendFormat?: string;
}

export interface TraceSettings extends DatasourceSettings {
  proxyUrl: string;
}

export interface TraceQueryRequest extends DataQueryRequest<TraceQuery> {
  camelizeResponse?: boolean;
}

export const traceFieldPrefixes = [PREFIX_TAGS, PREFIX_FIELDS];

/**
 * Common data source for trace and events
 */
export class TraceDatasource extends DatasourceApi<TraceQuery, TraceSettings> {
  resultsTransformer: ResultTransformer;

  constructor(name: string, settings: TraceSettings, baseService: BaseApi) {
    super(name, "traces", settings, baseService);
    this.resultsTransformer = new ResultTransformer();
  }

  getProxyUrl() {
    return this.settings.proxyUrl;
  }

  getUrl(url?: string) {
    return `${this.getProxyUrl()}/api/query/traces${url ? url : ""}`;
  }

  /**
   * Updates the results param with periodicitySecs with calculated periodicity if absent
   */
  setResultPeriodicity(results: TraceQueryResult[], periodicityInSecs: number) {
    results?.forEach(r => {
      if (!r.params?.periodicitySecs) {
        r.params = r.params ?? {};
        r.params.periodicitySecs = periodicityInSecs;
      }
    });
  }

  getLatestHour(timeRange: TimeRangeMillis): TimeRangeMillis {
    const { from, to } = timeRange;
    const oneHour = 60 * 60 * 1000;
    const timeDifference = to - from;

    if (timeDifference > oneHour) {
      return {
        from: to - oneHour,
        to: to
      };
    }
    return timeRange;
  }

  query(request: TraceQueryRequest): Promise<DataQueryResponse> {
    const target = request.targets[0];
    const query = cloneDeep(target.query);
    const requestedTimeRange = {
      from: request.startTime,
      to: request.endTime
    };

    query.timeRange = query.datasourceType === "events" ? this.getLatestHour(requestedTimeRange) : requestedTimeRange;

    if (request.interval) {
      const intervalObj = kbn.describe_interval(request.interval);
      const seconds: number = intervalObj.sec * intervalObj.count;
      target.periodicitySecs = seconds;

      // replace scoped variables in filter
      // TODO: figure a solution to replace variables and leave $notation intact in query
      // query.filters.value = templatesrv.replace(query.filters.value, request.scopedVars);

      // replace scoped variables in group by
      if (query.aggregations) {
        const aggrs: any = {};
        Object.keys(query.aggregations).forEach(aggr => {
          if (aggr !== "results") {
            // group by
            const prop = aggr; // TODO: same as filters replacement //templatesrv.replace(aggr, request.scopedVars);
            const value = query.aggregations[aggr] as NestedAggregation;
            // value.fromField = prop;      // TODO: retain tags but replace variables
            if (value.results) {
              this.setResultPeriodicity(value.results, seconds);
            }
            aggrs[prop] = value;
          } else {
            // results
            aggrs["results"] = cloneDeep(query.aggregations.results);
            this.setResultPeriodicity(aggrs["results"], seconds);
          }
        });
        query.aggregations = aggrs;
      }
    }

    return this.post("", query, { camelizeResponse: request.camelizeResponse })
      .then(response => this.processResults(response.data, request))
      .then((data): DataQueryResponse => ({ data }))
      .catch((err: InceptionError) => {
        const { message, response } = err;

        logger.error("Trace Datasource", `Fetch failed with: ${message}`);
        const errMessage = response?.data.msg || message;
        return {
          data: [],
          error: {
            data: {
              message: errMessage,
              error: message
            },
            message: message,
            status: response?.status?.toString(),
            statusText: response?.statusText
          }
        };
      });
  }

  private processResults(data: any, request: TraceQueryRequest) {
    const options = {
      ...request,
      legendFormat: request?.targets[0]?.legendFormat,
      ...{ step: request.targets[0].periodicitySecs }
    };

    const metricNames = Object.keys(data).filter(key => key !== "headers");
    // this datasource can process only 1 DQR target at a time
    const resultData = data[metricNames[0]];
    const transfromOptions = {
      ...options,
      start: parseInt(`${request.startTime / 1000}`, 10),
      end: parseInt(`${request.endTime / 1000}`, 10)
    };
    return this.resultsTransformer.transform(resultData, transfromOptions);
  }

  cancelQuery<R>(uuid: string): Promise<R> {
    const url = `/cancel/${uuid}`;
    return this.delete(url);
  }

  get<T, B, R = InceptionResponse<T>>(url: string, payload: B, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);
    return this.baseApiService.get(dsUrl, payload, config);
  }

  post<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);

    if (data) {
      (data as any).queryOrigin = "ui-analytics";
    }

    return this.baseApiService.post(dsUrl, data, config);
  }

  delete<T, R = InceptionResponse<T>>(url: string, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);
    return this.baseApiService.delete(dsUrl, config);
  }

  put<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);
    return this.baseApiService.put(dsUrl, data, config);
  }
}
