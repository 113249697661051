import { KPI } from "./kpi";
import { Operationalize } from "./operationalize";
import { Tenants } from "./tenants";
import { PlatformCypressConstants } from "./platform";
import { TriageCypressConstants } from "./triage";

export const ComponentCypressConstants = {
  Operationalize,
  Tenants,
  KPI,
  TriageCypressConstants,
  ...PlatformCypressConstants
};
