import { Popconfirm } from "antd";
import React, { FC, useMemo } from "react";
import { IncFaIcon } from "../../../icons";
import { IncPopConfirmProps } from "./types";

export const IncPopConfirm: FC<IncPopConfirmProps> = props => {
  const { buttonProps, iconProps, color = "#212B36", ...restProps } = props;

  const icon = useMemo(() => {
    if (!iconProps) {
      return null;
    }
    const { className = "", icon, iconName, isRegular = false } = iconProps;

    if (icon) {
      return icon;
    }

    if (iconName) {
      return (
        <IncFaIcon
          className={className}
          iconName={iconName}
          regular={isRegular}
        />
      );
    }

    return null;
  }, [iconProps]);

  const { okText, okIsDanger, cancelText, cancelIsDanger, showCancel } = useMemo(() => {
    let okText: JSX.Element | string = "";
    let okIsDanger = false;

    let cancelText: JSX.Element | string = "";
    let cancelIsDanger = false;
    let showCancel = true;

    if (buttonProps) {
      const { okButton, cancelButton } = buttonProps;

      okText = okButton?.text || "";
      okIsDanger = okButton?.danger || false;

      cancelText = cancelButton?.text || "";
      cancelIsDanger = cancelButton?.danger || false;
      showCancel = Boolean(cancelButton);
    }

    return {
      okText,
      okIsDanger,
      cancelText,
      cancelIsDanger,
      showCancel
    };
  }, [buttonProps]);

  return (
    <Popconfirm
      {...restProps}
      cancelButtonProps={{ danger: cancelIsDanger }}
      cancelText={cancelText}
      color={color}
      icon={icon}
      okButtonProps={{ danger: okIsDanger }}
      okText={okText}
      showCancel={showCancel}
    />
  );
};
