import translations from "../../translations/en-US.json";

const getLocalizedString = (id: string, replaceValues?: Array<string | number>) => {
  let rawString: string = (translations as Record<string, string>)[id] || "";
  if (rawString) {
    (replaceValues || []).map((value, index) => {
      const regex = new RegExp(`\\{${index}\\}`, "gi");
      rawString = rawString.replace(regex, value.toString());
      return value;
    });
  }
  return rawString;
};

export { getLocalizedString };
