import { FieldSchemaResponse } from "../../../../../src/platform/services/api/explore";

export const FieldPickerV1 = {
  attributes: {
    fieldPickerPopup: (parentCy: string) => `${parentCy}-field-picker-popup`,
    bizEntityFieldsTable: "biz-entity-fields-table",
    bizEntityMetricsTable: "biz-entity-metrics-table",
    userServiceMetricsTable: "user-service-metrics-table",
    businessFieldsTable: "business-fields-table",
    performanceFieldsTable: "performance-fields-table",
    businessEntitiesTable: "business-entities-table",
    fieldPickerDropDown: "field-picker-dropdown",
    fieldPickerRow: (fieldName: string) => `field-picker-row-${fieldName}`,
    fieldPickerSaveBtn: "field-picker-save-btn",
    fieldPickerFieldsTable: "field-picker-fields-table"
  },
  mockRequests: {
    "/api-proxy/api/userService/i_userService:00000000-0000-0000-0000-0001db848028:0/schema/fields?**": {
      body: {
        bizFields: [],
        userServiceFields: [
          {
            userServiceField: {
              userServices: [
                {
                  userServiceEntityId: "i_userService:00000000-0000-0000-0000-0001db848028:0"
                }
              ],
              fieldName: "fields.version",
              dataType: "LONG",
              bizEntityFieldName: "",
              displayBizEntityFieldName: "",
              allUserService: false,
              isMappingIncomplete: false,
              isAvailableInTenantContext: false,
              rawFieldName: "",
              isListDataType: false,
              displayDataType: "NA"
            },
            userServiceMetadata: {
              diagnostic: false,
              business: true,
              subType: "none",
              category: "BUSINESS",
              endUser: false,
              journey: false
            }
          },
          {
            userServiceField: {
              userServices: [
                {
                  userServiceEntityId: "i_userService:00000000-0000-0000-0000-0001db848028:0"
                }
              ],
              fieldName: "fields.workflowId",
              dataType: "STRING",
              bizEntityFieldName: "",
              displayBizEntityFieldName: "",
              allUserService: false,
              isMappingIncomplete: false,
              isAvailableInTenantContext: false,
              rawFieldName: "",
              isListDataType: false,
              displayDataType: "NA"
            },
            userServiceMetadata: {
              diagnostic: true,
              business: false,
              subType: "",
              category: "DIAGNOSTIC",
              endUser: false,
              journey: false
            }
          },
          {
            userServiceField: {
              userServices: [
                {
                  userServiceEntityId: "i_userService:00000000-0000-0000-0000-0001db848028:0"
                }
              ],
              fieldName: "fields.advancePurchaseDays",
              dataType: "STRING",
              bizEntityFieldName: "",
              displayBizEntityFieldName: "",
              allUserService: false,
              isMappingIncomplete: false,
              isAvailableInTenantContext: false,
              rawFieldName: "",
              isListDataType: false,
              displayDataType: "NA"
            },
            userServiceMetadata: {
              diagnostic: false,
              business: true,
              subType: "none",
              category: "BUSINESS",
              endUser: false,
              journey: false
            }
          },
          {
            userServiceField: {
              userServices: [
                {
                  userServiceEntityId: "i_userService:00000000-0000-0000-0000-0001db848028:0"
                }
              ],
              fieldName: "fields.items.Lane",
              dataType: "ENTITY",
              entityField: {
                entityType: "Lane",
                relNames: [],
                propType: "MAP",
                propName: "i_metadata",
                kindDescriptor: {
                  type: "not_set",
                  customTypeName: ""
                }
              },
              bizEntityFieldName: "",
              displayBizEntityFieldName: "",
              allUserService: false,
              isAvailableInTenantContext: false
            },
            userServiceMetadata: {
              diagnostic: false,
              business: true,
              subType: "",
              category: "BUSINESS",
              journey: false
            }
          },
          {
            userServiceField: {
              userServices: [
                {
                  userServiceEntityId: "i_userService:00000000-0000-0000-0000-0001db848028:0"
                }
              ],
              fieldName: "fields.items.itineraryType",
              dataType: "STRING",
              bizEntityFieldName: "",
              displayBizEntityFieldName: "",
              allUserService: false,
              isMappingIncomplete: false,
              isAvailableInTenantContext: false,
              rawFieldName: "",
              isListDataType: false,
              displayDataType: "NA"
            },
            userServiceMetadata: {
              diagnostic: false,
              business: true,
              subType: "none",
              category: "BUSINESS",
              endUser: false,
              journey: false
            }
          }
        ],
        userServiceMetrics: [],
        bizMetrics: [],
        resultMetadata: {
          resultEntityMap: {
            "i_userService:00000000-0000-0000-0000-0001db848028:0": {
              name: "Booking",
              entityId: "i_userService:00000000-0000-0000-0000-0001db848028:0",
              metadata: {}
            }
          }
        },
        status: 200,
        errors: []
      } as FieldSchemaResponse,
      statusCode: 200
    }
  }
};
