import { BizDataQuery, UserServiceFieldSlice } from "../../../../services/api/explore";
import {
  DiagnosticKPIs,
  OpAnalysisCreationConfig,
  OpAnalysisType,
  OpBizDataQuery,
  OpSchedule,
  StoryTemplateStr
} from "../../../../services/api/operationalise";
import { isJsonString } from "../../../../utils";

export const validateOpAnalysisConfig = (config: OpAnalysisCreationConfig) => {
  let errors: Record<string, string> = {};
  if (!config) {
    errors["Analysis Config"] = "Config is Required";
  } else {
    const { kpis, storyTemplates, dimensionsToAnalyze, schedule, diagnosticKpis, diagnosticFields, analysisType } =
      config;
    const kpiErrors = validateAnalysisKPI(kpis);
    const scheduleErrors = validateAnalysisSchedule(schedule);
    const dimensionsErrors = validateAnalysisDimensions(dimensionsToAnalyze);
    const storyTemplateErrors = validateAnalysisStoryTemplates(storyTemplates);
    const diagnosticKpisErrors = validateAnalysisDiagnosticKPI(diagnosticKpis);
    const diagnosticFieldsErrors = validateAnalysisDiagnosticFields(diagnosticFields);
    const analysisTypeErrors = validateAnalysisType(analysisType);
    errors = {
      ...errors,
      ...kpiErrors,
      ...scheduleErrors,
      ...dimensionsErrors,
      ...storyTemplateErrors,
      ...diagnosticKpisErrors,
      ...diagnosticFieldsErrors,
      ...analysisTypeErrors
    };
  }
  return errors;
};

export const validateAnalysisSchedule = (schedule: OpSchedule) => {
  const errors: Record<string, string> = {};
  if (!schedule) {
    errors["Schedule"] = "Schedule is required";
  }
  return errors;
};

export const validateAnalysisStoryTemplates = (storyTemplates: StoryTemplateStr[]) => {
  const errors: Record<string, string> = {};
  storyTemplates?.forEach(template => {
    const { name, templateJsonStr } = template;
    if (!name) {
      errors["Story Template Name"] = "Name is required";
    }
    if (!templateJsonStr) {
      errors["Story Template Config"] = "Config is required";
    } else if (!isJsonString(templateJsonStr)) {
      errors["Story Template Config"] = "Invalid Config";
    }
  });
  return errors;
};

export const validateAnalysisDimensions = (dimensionsToAnalyze: UserServiceFieldSlice[]) => {
  const errors: Record<string, string> = {};
  if (!dimensionsToAnalyze?.length) {
    errors["Dimensions"] = "Dimensions are required";
  }
  return errors;
};
export const validateAnalysisDiagnosticFields = (diagnosticFields: UserServiceFieldSlice[]) => {
  const errors: Record<string, string> = {};
  if (!diagnosticFields?.length) {
    errors["Diagnostic Fields"] = "Diagnostic fields are required";
  }
  return errors;
};

export const validateAnalysisKPI = (kpis: Record<string, OpBizDataQuery>) => {
  let errors: Record<string, string> = {};
  const keys = Object.keys(kpis || {});
  if (!keys.length) {
    errors["KPI"] = "Minimum one kpi is required";
  }
  keys.forEach(key => {
    const kpi = kpis[key];
    const { name, bizDataQuery } = kpi || {};
    if (!name) {
      errors["KPI Name"] = "Name is required";
    }
    const bizDataQueryErrors = validateBizDataQuery(bizDataQuery);
    errors = {
      ...errors,
      ...bizDataQueryErrors
    };
  });
  return errors;
};

export const validateAnalysisDiagnosticKPI = (kpis: Record<string, DiagnosticKPIs>) => {
  const errors: Record<string, string> = {};
  const keys = Object.keys(kpis || {});
  keys.forEach(key => {
    const diagnosticKpis = kpis[key];
    const { kpi: kpiList = [] } = diagnosticKpis || {};
    kpiList.forEach(dKpi => {
      const { name, bizDataQuery } = dKpi || {};
      if (!name) {
        errors["Diagnostic KPI Name"] = "Name is required";
      }
      const bizDataQueryErrors = validateBizDataQuery(bizDataQuery);
      if (Object.keys(bizDataQueryErrors).length) {
        errors["Diagnostic KPI BizDataQuery"] = "Invalid BizDataQuery";
      }
    });
  });
  return errors;
};

const validateBizDataQuery = (bizDataQuery: BizDataQuery) => {
  const errors: Record<string, string> = {};
  if (!bizDataQuery) {
    errors["BizDataQuery"] = "BizDataQuery is required";
  } else {
    const { sliceSpec, buildingBlockConfig, dataQuery, id, widgetConfig } = bizDataQuery;
    if (!sliceSpec) {
      errors["Slice Spec"] = "SliceSpec is required";
    } else {
      const { buildingBlockConfigId, fieldId, metricId } = sliceSpec;
      if (!fieldId && !metricId && !buildingBlockConfigId) {
        errors["Slice Spec"] = "fieldId or metricId or buildingBlockConfigId is required";
      }
    }
    if (!buildingBlockConfig && !dataQuery && !id && !widgetConfig) {
      errors["KPI Query Config"] = "BuildingBlocksConfig or Id or DataQuery or WidgetConfig is required";
    }
  }
  return errors;
};

const validateAnalysisType = (analysisTypes: OpAnalysisType[]) => {
  const errors: Record<string, string> = {};
  if (!analysisTypes?.length) {
    errors["Analysis Type"] = "Analysis type is required";
  }
  return errors;
};
