import { useMemo } from "react";
import { Column } from "react-table";
import { range } from "lodash";
import { TableDataItem } from "../types";

const useLoadingState = <T extends TableDataItem>(columns: Array<Column<T>>) =>
  useMemo(() => {
    const datum: any = {};
    columns.forEach(col => {
      const { accessor } = col;
      if (accessor) {
        datum[accessor] = "";
      }
    });

    return range(0, 5).map(() => ({ ...datum }));
  }, [columns]);

export default useLoadingState;
