import { createAction } from "@reduxjs/toolkit";
import { assignIn } from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import { Action } from "redux";
import { ApplicationState } from "../../../store/configureStore";
import { AuthType } from "../../core";
import { BootConfigState } from "./types";

// hook definition
const useBootConfig = () => {
  const bootConfigState = useSelector((storeState: ApplicationState) => ({ ...storeState.bootConfig }), shallowEqual);

  return {
    bootConfigState
  };
};

// actions
const BOOT_CONFIG_UPDATE = "bootConfig/update";
export const BootConfigUpdate = createAction<BootConfigState>(BOOT_CONFIG_UPDATE);

// initial state
export const initialState: BootConfigState = {
  user: null,
  org: null,
  userOrgs: [],
  preferences: null,
  authType: AuthType.password
};

// reducers
const bootConfigReducer = (state: BootConfigState = initialState, action: Action<unknown>) => {
  if (BootConfigUpdate.match(action)) {
    const { payload } = action;
    assignIn(state, payload);
  }

  return state;
};

export { useBootConfig, bootConfigReducer };
