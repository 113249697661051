import { AxiosError, AxiosRequestConfig, AxiosResponse, CancelToken } from "axios";
import { TimeRange } from "../../../core";

export type SupportedTypes = "apptuit" | "traces" | "mixed" | "explore" | "mock" | "operationalize";

export const SupportedDatasource: Record<SupportedTypes, string> = {
  apptuit: "apptuit",
  traces: "traces",
  mixed: "mixed",
  explore: "explore",
  mock: "mock",
  operationalize: "operationalize"
};

export interface IncDataSource {
  access: string;
  basicAuth?: boolean;
  database?: string;
  id: number;
  isDefault: true;
  jsonData: any;
  name: string;
  orgId: number;
  password?: string;
  readOnly?: boolean;
  type?: SupportedTypes;
  typeLogoUrl?: string;
  url?: string;
  user?: string;
}
export interface DataQuery {
  refId: string;
  query: any;

  hide?: boolean;
  /**
   * Unique, guid like, string used in explore mode
   */
  key?: string;
  /**
   * For mixed data sources the selected datasource is on the query level.
   * For non mixed scenarios this is undefined.
   */
  datasource?: string | null;
  // We want to support legend formatting to all the datasources eventually
  legendFormat?: string;
}

export interface DatasourceSettings {
  id?: number;
  isGrafanaDS: boolean;
}

export enum LoadingState {
  NotStarted = "NotStarted",
  Loading = "Loading",
  Streaming = "Streaming",
  Done = "Done",
  Error = "Error"
}

export interface DataQueryError {
  data?: {
    message?: string;
    error?: string;
  };
  message?: string;
  status?: string;
  statusText?: string;
  cancelled?: boolean;
  refId?: string;
}

export interface DataQueryResponse<T = unknown> {
  error?: DataQueryError;
  data?: T;
  state?: LoadingState;
  entityContext?: Map<string, string>;
}

export interface ScopedVar<T = any> {
  text: any;
  value: T;

  // Added these to accommodate dashboard variables.
  //We need these to handle the value replacement in queries
  multi?: boolean;
  includeAll?: boolean;
  allValue?: string;
}

export interface ScopedVars {
  [key: string]: ScopedVar;
}

export interface DataQueryRequest<TQuery extends DataQuery> {
  requestId: string;
  dashboardId: string | number;
  panelId: string | number;
  interval: string;
  intervalMs?: number;
  range?: TimeRange;
  targets: TQuery[];
  timezone?: string;
  cacheTimeout?: string;
  //rangeRaw?: RawTimeRange;
  startTime: number;
  endTime?: number;
  scopedVars?: ScopedVars;
  cancelToken?: CancelToken; // pass this to enable query cancellation
  resolveEntityIdsToName?: boolean;
}

export interface InceptionRequestConfig extends AxiosRequestConfig {
  camelizeResponse?: boolean;
  requestId?: string | number;
  retryCount?: number;

  preferMockResponse?: boolean;
  fetchIfNoMockResponse?: boolean;
}

export type UrlParams = Record<string, number | string>;

export interface InceptionResponse<T> extends AxiosResponse<T> {
  config: InceptionRequestConfig;
}

export interface InceptionError extends AxiosError {}

export interface BaseApi {
  requestInterceptors?: (config: InceptionRequestConfig) => Promise<InceptionRequestConfig>;
  request?<T, R = InceptionResponse<T>>(config: InceptionRequestConfig): Promise<R>;
  get?<T, B = UrlParams, R = InceptionResponse<T>>(
    url: string,
    payload?: B,
    config?: InceptionRequestConfig
  ): Promise<R>;
  delete?<T, R = InceptionResponse<T>>(url: string, config?: InceptionRequestConfig): Promise<R>;
  post?<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R>;
  put?<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R>;
  cancelRequest?: (id: string) => void;
}
