import { useCallback, useMemo } from "react";
import { FetchCallBackResult, FetchFn, InitialState, logger, useDataFetch } from "../../../../core";
import { operationaliseV2ApiService } from "../OperationaliseV2Api";
import { InsightMemberShipResponse } from "../types";

export const useFetchInsightMembership = (
  opConfigId: string,
  incidentId: string,
  startTimeMillis: number,
  endTimeMillis: number,
  generateDemoData = false,
  lazy = false
) => {
  const initialState = useMemo<InitialState<InsightMemberShipResponse, string>>(
    () => ({
      data: null,
      error: null,
      isError: false,
      isFetching: !lazy,
      isSuccess: false
    }),
    [lazy]
  );

  const fetchFn = useCallback<FetchFn<InsightMemberShipResponse, string>>(async () => {
    const result: FetchCallBackResult<InsightMemberShipResponse, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const { data, error, message } = await operationaliseV2ApiService.getInsightMembership(
        opConfigId,
        incidentId,
        startTimeMillis,
        endTimeMillis,
        generateDemoData
      );

      result.isError = error;
      result.isSuccess = !error;
      result.data = data;
      result.error = error ? message : null;
    } catch (err) {
      logger.error("UseFetchInsightMembership", "Error fetching incidents", err);
      result.isError = true;
      result.error = err.message?.toString() || err.toString();
    }

    return result;
  }, [endTimeMillis, generateDemoData, incidentId, opConfigId, startTimeMillis]);

  return useDataFetch(fetchFn, initialState, lazy);
};
