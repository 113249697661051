import { ValueType, GroupTypeBase } from "react-select";
import { IncSelectOption2 } from "../../components/Select/types";
import { getStringPossibleWidth } from "./string_width";

const FONT_SIZE = 12;

/**
 * @param selected currently selected options
 * @param optionLength total number of options rendered in select
 * @param label Select label
 * @param noSelectText Text to appear when no selection is done. Defaults to 'Select'
 * @param displayLiteralAll Returns literal 'All' if all items are selected
 * @param maxItems Max items to show in the value text. Defaults to 1.
 * @param maxWidth Max width the value text can take. Assumes font size is 12.
 */
export const getSelectValueText = <Option extends IncSelectOption2>(
  selected: Option[],
  optionLength: number,
  label = "",
  noSelectText = "Select",
  displayLiteralAll = false,
  maxItems = 1,
  maxWidth?: number
) => {
  let selectedStr = "";

  if (!selected || !selected.length) {
    return noSelectText;
  }
  if (selected.length === optionLength && displayLiteralAll) {
    return "All";
  }
  const len = selected.length;
  const suffix = len > maxItems ? `+${len - maxItems} more` : "";

  for (let i = 0; i < len; i++) {
    if (i === maxItems) {
      break;
    }
    selectedStr += `${selected[i]?.label}${i < len - 1 ? ", " : ""}`;
  }

  let { width } = getStringPossibleWidth(selectedStr + suffix + label, FONT_SIZE);
  let overflowExists = maxWidth && width > maxWidth;

  if (!overflowExists) {
    return selectedStr + suffix;
  }

  const singleSuff = `${len >= 2 ? ` +${len - 1}more` : ""}`;
  const subStr = `${selected[0]?.label}${singleSuff}`;
  width = getStringPossibleWidth(subStr, FONT_SIZE)?.width;
  overflowExists = maxWidth && width > maxWidth;

  return overflowExists && len > 1 ? `${len} selected` : subStr;
};

export const getSelectOptionsFromStringArray = (options: string[]) =>
  options.map(
    (op): IncSelectOption2 => ({
      label: op,
      value: op
    })
  );

export const handleIsValidNewOption = <OptionType extends IncSelectOption2, IsMulti extends boolean>(
  inputValue: string,
  value: ValueType<OptionType, IsMulti>,
  selectOptions: ReadonlyArray<OptionType | GroupTypeBase<OptionType>>
) => {
  // Check for the same value --> ASD === ASD ?
  const exactValueExists = selectOptions.find(el => el.value === inputValue);

  // Check if the value has a valid length.
  // Without this, it will show create option for empty values.
  const valueIsNotEmpty = Boolean(inputValue.trim().length);

  // If true show create option.
  return !exactValueExists && valueIsNotEmpty;
};
