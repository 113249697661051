import { logger } from "../../../core";
import { BizService } from "../explore/BizServiceCommon";
import {
  CalendarEventPreview,
  CalendarEventSearchResponse,
  CreateCalendarEventRequest,
  SuggestCalendarEvent
} from "./types";

interface Result<T> {
  data: T;
  error: boolean;
  message: string;
}

class CalendarEventsApiService extends BizService {
  private readonly baseUrl = `${this.getBizEntityUrl()}calendar/events`;

  async getCalenderEvents(
    startTimeMillis: number,
    endTimeMillis: number
  ): Promise<Result<CalendarEventSearchResponse>> {
    this.init();

    let url = this.baseUrl;
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    url += timeParams;
    const result: Result<CalendarEventSearchResponse> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.get<CalendarEventSearchResponse, unknown>(url);
      result.data = response.data;
    } catch (err) {
      logger.error("CalendarEventsApiService", "failed to fetch calendar events", err);
      result.error = true;
      result.message = err.message;
    }

    return result;
  }

  async suggestCalendarEvents(
    name: string,
    startTimeMillis: number,
    endTimeMillis: number
  ): Promise<Result<CalendarEventPreview>> {
    this.init();

    const payload: SuggestCalendarEvent = {
      calendarEventName: name,
      startTimeMillis,
      endTimeMillis
    };
    const url = `${this.baseUrl}/suggest`;
    const result: Result<CalendarEventPreview> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.post<CalendarEventPreview, SuggestCalendarEvent>(url, payload);
      result.data = response.data;
    } catch (err) {
      logger.error("CalendarEventsApiService", "failed to suggest calendar events", err);
      result.error = true;
      result.message = err.message;
    }

    return result;
  }

  async addCalendarEvent(createCalendarEventJson: CreateCalendarEventRequest): Promise<Result<any>> {
    this.init();

    const url = `${this.baseUrl}/add`;
    const result: Result<any> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.post<CreateCalendarEventRequest, any>(url, createCalendarEventJson);
      result.data = response.data;
    } catch (err) {
      logger.error("CalendarEventsApiService", "failed to add calendar event", err);
      result.error = true;
      result.message = err.message;
    }

    return result;
  }
}

const calendarEventsApiService = new CalendarEventsApiService();
export default calendarEventsApiService;
