// initialize
export const INITIALIZE_ANALYSIS_CONFIG = "INITIALIZE_ANALYSIS_CONFIG";
// schedule
export const SET_ANALYSIS_SCHEDULE = "SET_ANALYSIS_SCHEDULE";
export const SET_DRY_RUN = "SET_DRY_RUN";
// kpis
export const SET_ANALYSIS_KPI = "SET_ANALYSIS_KPI";
export const ADD_ANALYSIS_KPI = "ADD_ANALYSIS_KPI";
export const CLONE_ANALYSIS_KPI = "CLONE_ANALYSIS_KPI";
export const DELETE_ANALYSIS_KPI = "DELETE_ANALYSIS_KPI";
export const SET_ANALYSIS_KPI_NAME = "SET_ANALYSIS_KPI_NAME";
export const SET_ANALYSIS_KPI_QUERY = "SET_ANALYSIS_KPI_QUERY";
// diagnostic kpis
export const SET_ANALYSIS_DIAGNOSTIC_KPI = "SET_ANALYSIS_DIAGNOSTIC_KPI";
export const OVERRIDE_ANALYSIS_DIAGNOSTIC_KPI = "OVERRIDE_ANALYSIS_DIAGNOSTIC_KPI";
export const ADD_ANALYSIS_DIAGNOSTIC_KPI = "ADD_ANALYSIS_DIAGNOSTIC_KPI";
export const CLONE_ANALYSIS_DIAGNOSTIC_KPI = "CLONE_ANALYSIS_DIAGNOSTIC_KPI";
export const DELETE_ANALYSIS_DIAGNOSTIC_KPI = "DELETE_ANALYSIS_DIAGNOSTIC_KPI";
export const SET_ANALYSIS_DIAGNOSTIC_KPI_NAME = "SET_ANALYSIS_DIAGNOSTIC_KPI_NAME";
export const SET_ANALYSIS_DIAGNOSTIC_KPI_QUERY = "SET_ANALYSIS_DIAGNOSTIC_KPI_QUERY";
// rolling freq and compare offset
export const SET_ANALYSIS_ROLLING_FREQ = "SET_ANALYSIS_ROLLING_FREQ";
export const SET_ANALYSIS_COMPARE_TO_OFFSET = "SET_ANALYSIS_COMPARE_TO_OFFSET";
// dimensions
export const SET_ANALYSIS_DIMENSIONS_TO_ANALYZE = "SET_ANALYSIS_DIMENSIONS_TO_ANALYZE";
// diagnostic fields
export const SET_ANALYSIS_DIAGNOSTIC_FIELDS = "SET_ANALYSIS_DIAGNOSTIC_FIELDS";
// dimension sets
export const SET_ANALYSIS_DIMENSION_SETS_TO_ANALYZE = "SET_ANALYSIS_DIMENSION_SETS_TO_ANALYZE";
export const ADD_ANALYSIS_DIMENSION_SETS_TO_ANALYZE = "ADD_ANALYSIS_DIMENSION_SETS_TO_ANALYZE";
export const DELETE_ANALYSIS_DIMENSION_SETS_TO_ANALYZE = "DELETE_ANALYSIS_DIMENSION_SETS_TO_ANALYZE";
// template formate
export const SET_TEMPLATE_FORMAT = "SET_TEMPLATE_FORMAT";
// story template
export const SET_STORY_TEMPLATE_FORMAT = "SET_STORY_TEMPLATE_FORMAT";
export const ADD_STORY_TEMPLATE_FORMAT = "ADD_STORY_TEMPLATE_FORMAT";
export const CLONE_STORY_TEMPLATE_FORMAT = "CLONE_STORY_TEMPLATE_FORMAT";
export const SET_STORY_TEMPLATE_FORMAT_NAME = "SET_STORY_TEMPLATE_FORMAT_NAME";
export const SET_STORY_TEMPLATE_FORMAT_JSON = "SET_STORY_TEMPLATE_FORMAT_JSON";
export const DELETE_STORY_TEMPLATE_FORMAT = "DELETE_STORY_TEMPLATE_FORMAT";
// suppression
export const SET_SUPPRESSION = "SET_SUPPRESSION";
// analysis types
export const SET_ANALYSIS_TYPES = "SET_ANALYSIS_TYPES";
