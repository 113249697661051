import { TokenProp } from "./types";

class Token {
  private tokenType: string;
  private value: string;
  private startPosition: number;
  private endPosition: number;

  constructor({ tokenType, value, startPosition, endPosition }: TokenProp) {
    this.tokenType = tokenType;
    this.value = value;
    this.startPosition = startPosition;
    this.endPosition = endPosition;
  }

  getValue() {
    return this.value;
  }

  getTokenType() {
    return this.tokenType;
  }

  getStartPosition() {
    return this.startPosition;
  }

  getEndPosition() {
    return this.endPosition;
  }

  toString() {
    console.log(`Token:
            tokenType= ${this.tokenType},
            value= ${this.value},
            start= ${this.startPosition},
            end= ${this.endPosition},`);
  }
}

export default Token;
