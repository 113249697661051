import React, { useRef, useState, useEffect, useCallback } from "react";
import Body from "./components/Body";
import { TreeGridProps } from "./types";

function TreeGrid<T>(props: TreeGridProps<T>) {
  if (!props.columnDefs || props.columnDefs.length === 0) {
    console.error("ColumnDef needs to be specified");
  }

  if (!props.flatData) {
    console.error("Data must be specified");
  }

  const gridRef: any = useRef(null);
  const [width, setWidth] = useState<number>(0);

  const setGridWidth = useCallback(() => {
    if (gridRef.current) {
      setWidth(gridRef.current.getBoundingClientRect().width);
    }
  }, [gridRef]);

  useEffect(() => {
    setGridWidth();
  }, [gridRef, setGridWidth]);

  // Hande the window resize
  useEffect(() => {
    const handleResize = () => {
      setGridWidth();
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [setGridWidth]);

  return (
    <div
      className={"inc-tree-grid"}
      ref={gridRef}
    >
      <Body
        options={props}
        width={width}
      />
    </div>
  );
}

export default TreeGrid;
