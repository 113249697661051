import { sortBy, values } from "lodash";
import appConfig from "../../../../appConfig";
import { logger, Entity } from "../../../core";
import { getPickerUrlPrefix, getUserServiceUrlPrefix } from "../../../utils/BizEntityUrl";
import { entityEnricherRegistry } from "../../../utils/EntityEnricher";
import { request } from "../base-api";
import { FieldAggregationResponse } from "../types";
import { ALL_USERSERVICES_ENTITY_TYPE_ID } from "../../../utils/ExploreUtils";
import { BizService } from "./BizServiceCommon";
import {
  EntityField,
  BizFieldPredicate,
  DemoDataParams,
  EntityNamesRequest,
  EntityNamesResponse,
  UserServiceField
} from "./types";
import {
  BizEntityType,
  BizEntityTypeList,
  ExploreUserServicesList,
  FieldSchemaResponse,
  FieldPickerContext,
  ExploreUserService,
  TagValueField,
  TagValueFieldResponse,
  FieldValuesInput,
  FieldValues,
  BizJourneyListResponse,
  BizJourney,
  BizJourneyPath,
  BizJourneyPathResponse,
  BizJourneyPathsResponse,
  BizJourneyPathPayload,
  BizEntityUserServiceList,
  UserServiceInfoList,
  JourneyListResponse,
  JourneyFieldsResponse,
  JourneyFieldsRequest
} from "./types/fieldpickerTypes";

interface Result<T> {
  data: T;
  cancelled?: boolean;
  error: boolean;
  message: string;
}

class FieldPickerApiService extends BizService {
  private readonly pickerValueUrl = `${getPickerUrlPrefix()}/values`;

  async getBizEntityTypes(): Promise<Result<BizEntityType[]>> {
    await this.init();
    const url = this.getBizEntityUrl("list");
    const result: Result<BizEntityType[]> = {
      data: [],
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.get<BizEntityTypeList, unknown>(url);
      let bizEntityTypes = [...response.data.resultEntityTypes];
      bizEntityTypes = sortBy(bizEntityTypes, et => et.name);
      result.data.push(...bizEntityTypes);
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getJourneysList(): Promise<Result<BizJourney[]>> {
    await this.init();
    const url = this.getBizEntityUrl(`bizJourney`);
    const result: Result<BizJourney[]> = {
      data: [],
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.get<BizJourneyListResponse, unknown>(url);
      result.data = response.data.bizJourney;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getJourneysListV2(companyName: string, generateDemoData: boolean): Promise<Result<JourneyListResponse>> {
    this.init();

    const url = this.getBizEntityUrl(`journeys/v2/list`);

    const result: Result<JourneyListResponse> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.get<JourneyListResponse, unknown>(
        url,
        {},
        {
          params: {
            companyName,
            generateDemoData
          }
        }
      );
      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }

    return result;
  }

  async getJourneyFields(journeyIds: string[], companyName: string): Promise<Result<JourneyFieldsResponse>> {
    this.init();

    const url = this.getBizEntityUrl(`journeys/v2/fields?companyName=${companyName}&generateDemoData=true`);
    const result: Result<JourneyFieldsResponse> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.post<JourneyFieldsResponse, JourneyFieldsRequest>(url, {
        journeyIds
      });
      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }

    return result;
  }

  async getJourneyPaths(
    id: string,
    entityFilters: BizFieldPredicate[],
    startTimeMillis: number,
    endTimeMillis: number,
    compareSeconds: number
  ): Promise<Result<BizJourneyPathsResponse>> {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const url = this.getBizEntityUrl(`bizJourney/${id}/paths${timeParams}`);

    const result: Result<BizJourneyPathsResponse> = {
      data: null,
      error: false,
      message: ""
    };

    const payload: BizJourneyPathPayload = {
      entityFilters: [
        {
          filters: entityFilters
        }
      ],
      compareTimeInSeconds: compareSeconds
    };

    try {
      const response = await this.datasource.post<BizJourneyPathsResponse, BizJourneyPathPayload>(url, payload);
      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getJourneyPath(
    id: string,
    entityFilters: BizFieldPredicate[],
    startTimeMillis: number,
    endTimeMillis: number
  ): Promise<Result<BizJourneyPath>> {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const url = this.getBizEntityUrl(`bizJourney/${id}/path${timeParams}`);

    const result: Result<BizJourneyPath> = {
      data: null,
      error: false,
      message: ""
    };

    const payload = {
      entityFilters: [
        {
          filters: entityFilters
        }
      ]
    };

    try {
      const response = await this.datasource.post<BizJourneyPathResponse, unknown>(url, payload);
      result.data = response.data.path;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getBizEntityType(bizEntityTypeId?: string, generateDemoData = false): Promise<Result<BizEntityType>> {
    await this.init();
    const url = this.getBizEntityUrl(bizEntityTypeId);
    const result: Result<BizEntityType> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const { data } = await this.datasource.get<BizEntityType, unknown>(url, null, { params: { generateDemoData } });
      result.data = data;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getUserservicesList(
    startTimeMillis: number,
    endTimeMillis: number,
    demoDataParams?: DemoDataParams
  ): Promise<Result<ExploreUserService[]>> {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const url = this.getUserServiceUrl(`list${timeParams}`);
    const result: Result<ExploreUserService[]> = {
      data: [],
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.get<ExploreUserServicesList, unknown>(url, null, {
        params: demoDataParams || {}
      });
      let exploreUserservices = [...response.data.resultEntities];
      exploreUserservices = sortBy(exploreUserservices, et => et.name);
      result.data.push(...exploreUserservices);
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getUserserviceInfo(
    userServiceId: string,
    startTimeMillis: number,
    endTimeMillis: number,
    generateDemoData = false
  ): Promise<Result<ExploreUserService>> {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const subUrl = userServiceId + timeParams;
    const url = this.getUserServiceUrl(subUrl);
    const result: Result<ExploreUserService> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.get<ExploreUserService, unknown>(url, null, {
        params: { generateDemoData }
      });
      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getBizEntityUserserviceList(
    entityTypeId: string,
    startTimeMillis: number,
    endTimeMillis: number,
    includeSystemFieldInfo: boolean
  ) {
    await this.init();
    const subUrl = `${entityTypeId}/schema/userService`;
    const url = this.getBizEntityUrl(subUrl);
    const result: Result<BizEntityUserServiceList> = {
      data: null,
      error: false,
      message: ""
    };

    const params = {
      includeSystemFieldInfo,
      startTimeMillis,
      endTimeMillis
    };

    try {
      const response = await this.datasource.get<BizEntityUserServiceList, unknown>(url, null, { params });
      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  /**
   *
   * @param entityType Entity Type example i_merchant, i_sfAccount etc
   * @param pickerContext FieldSchemaContext
   *  example {
   *    startTimeMillis in millis
   *    endTimeMillis in millis
   *    showMetrics boolean
   *    showFields boolean
   *    ...etc
   * }
   */
  async getBizEntityFields(
    entityType: string,
    pickerContext: FieldPickerContext,
    startTimeMillis: number,
    endTimeMillis: number,
    isJourney = false,
    demoParams: DemoDataParams = null
  ): Promise<FieldSchemaResponse> {
    const shareId = appConfig.anomShareId;
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const subUrl = shareId ? `${timeParams}&shareId=${shareId}` : timeParams;
    const url = this.getFieldsUrl(null, entityType, subUrl, isJourney);
    const processedPickerContext = this.getProcessedPickerContext(pickerContext);

    const params: Record<string, any> = {
      ...(demoParams || {})
    };

    if (shareId) {
      const apiUrl = `${appConfig.apiDomainUrl}${url}`;
      const response = await request.post<FieldSchemaResponse, FieldPickerContext>(apiUrl, processedPickerContext, {
        params
      });
      return response.data;
    } else {
      this.init();

      const response = await this.datasource.post<FieldSchemaResponse, FieldPickerContext>(
        url,
        processedPickerContext,
        { params }
      );
      return response.data;
    }
  }

  async getAllUserServiceFields(startTimeMillis: number, endTimeMillis: number): Promise<FieldSchemaResponse> {
    const shareId = appConfig.anomShareId;
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    let subUrl = shareId ? `${timeParams}&shareId=${shareId}` : timeParams;
    subUrl = subUrl.startsWith("?") ? subUrl : `?${subUrl}`;

    const url = this.getUserServiceUrl(`/filters/fields${subUrl}`);

    if (shareId) {
      const apiUrl = `${appConfig.apiDomainUrl}${url}`;
      const response = await request.get<FieldSchemaResponse>(apiUrl);
      return response.data;
    } else {
      this.init();
      const response = await this.datasource.get<FieldSchemaResponse>(url);
      return response.data;
    }
  }

  async getBizEntityFilterFields(
    entityType: string,
    startTimeMillis: number,
    endTimeMillis: number
  ): Promise<FieldSchemaResponse> {
    await this.init();
    const shareId = appConfig.anomShareId;

    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const subUrl = shareId ? `${timeParams}&shareId=${shareId}` : timeParams;
    const url = `/api/bizEntity/${entityType}/filters/fields${subUrl}`;

    if (shareId) {
      const apiUrl = `${appConfig.apiDomainUrl}${url}`;
      const response = await request.get<FieldSchemaResponse>(apiUrl);
      return response.data;
    } else {
      const response = await this.datasource.get<FieldSchemaResponse, unknown>(url);
      return response.data;
    }
  }

  async getUserServices(entityTypeId: string, cohortId: string, startTimeMillis: number, endTimeMillis: number) {
    const pickerContext: FieldPickerContext = {
      bizEntityType: entityTypeId,
      cohort: cohortId,
      showFields: true
    };
    const schemaResponse = await this.getBizEntityFields(
      pickerContext.bizEntityType,
      pickerContext,
      startTimeMillis,
      endTimeMillis
    );
    const userServices: Entity[] = values(schemaResponse.resultMetadata?.resultEntityMap)
      .filter(meta => meta.entityId.startsWith(ALL_USERSERVICES_ENTITY_TYPE_ID))
      .map(meta => ({
        id: meta.entityId,
        name: meta.name,
        displayName: meta.name
      }));
    return userServices;
  }

  async getUserserviceEntityField(
    userserviceEntityId: string,
    pickerContext: FieldPickerContext,
    startTimeMillis: number,
    endTimeMillis: number,
    demoParams: DemoDataParams = null
  ): Promise<FieldSchemaResponse> {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const url = this.getFieldsUrl(userserviceEntityId, null, timeParams);
    const processedPickerContext = this.getProcessedPickerContext(pickerContext);

    const context = {
      ...processedPickerContext,
      startTimeMillis,
      endTimeMillis
    };

    delete context.bizEntityType;

    const params: Record<string, any> = {
      ...(demoParams || {})
    };

    const body: FieldPickerContext = context;
    const response = await this.datasource.post<FieldSchemaResponse, FieldPickerContext>(url, body, { params });
    return response.data;
  }

  async getUserserviceEntityFilterFields(
    userserviceEntityId: string,
    startTimeMillis: number,
    endTimeMillis: number,
    demoParams: DemoDataParams = null
  ): Promise<FieldSchemaResponse> {
    await this.init();

    const shareId = appConfig.anomShareId;
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    let subUrl = shareId ? `${timeParams}&shareId=${shareId}` : timeParams;
    if (demoParams?.generateDemoData) {
      subUrl += `&generateDemoData=true`;
    }
    const url = `/api/userService/${userserviceEntityId}/filters/fields${subUrl}`;

    if (shareId) {
      const apiUrl = `${appConfig.apiDomainUrl}${url}`;
      const response = await request.get<FieldSchemaResponse>(apiUrl);
      return response.data;
    } else {
      const response = await this.datasource.get<FieldSchemaResponse, unknown>(url);
      return response.data;
    }
  }

  async getTagCompletion(
    tagField: TagValueField,
    startTimeMillis: number,
    endTimeMillis: number,
    entityType: string = null
  ) {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const url = `${this.pickerValueUrl}/tag${timeParams}&entityType=${entityType}`;

    const response = await this.datasource.post<TagValueFieldResponse, unknown>(url, tagField);
    if (response.data.headers) {
      logger.debug("Tag Value Fetch", "Headers", response.headers);
    }

    const tagName = tagField.tagName as string;
    const tagResults = response.data[tagName];
    if (tagResults && tagResults.result) {
      return tagResults.result;
    }
    return [];
  }

  async getBizEntityFieldCompletion(entityField: EntityField, startTimeMillis: number, endTimeMillis: number) {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const url = `${this.pickerValueUrl}/field${timeParams}`;
    const response = await this.datasource.post(url, entityField);
    return response.data as string[];
  }

  async getFieldValueCompletion(
    payload: FieldValuesInput,
    startTimeMillis: number,
    endTimeMillis: number,
    searchText = "",
    limit = 1000,
    useFilterFieldValuesUrl = false
  ) {
    this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);

    const shareId = appConfig.anomShareId;
    let url = "";

    if (useFilterFieldValuesUrl) {
      const userServiceId = payload?.userServiceField?.userServices?.[0]?.userServiceEntityId || "";
      url = `${this.getUserServiceUrl()}${userServiceId}/filters/fields/values${timeParams}&filterText=${searchText}&limit=${limit}`;
    } else {
      url = `${this.pickerValueUrl}${timeParams}&fieldFilter=${searchText}&limit=${limit}`;
    }
    url += shareId ? `&shareId=${shareId}` : "";

    let response = null;
    if (shareId) {
      const newUrl = `${appConfig.apiDomainUrl}${url}`;
      response = await request.post<FieldValues, FieldValuesInput>(newUrl, payload);
    }
    response = await this.datasource.post<FieldValues, FieldValuesInput>(url, payload);
    const data: FieldValues = response?.data as FieldValues;
    entityEnricherRegistry.addToEntityToCache(data.entityLookupData || {});
    return data;
  }

  async getEntityLookup(entityIds: string[]) {
    this.init();

    const payload: EntityNamesRequest = {
      entityId: entityIds
    };
    const result: Result<EntityNamesResponse> = {
      data: null,
      error: false,
      message: ""
    };
    const shareId = appConfig.anomShareId;
    let url = `${getUserServiceUrlPrefix()}/filters/entity/names`;
    url += shareId ? `?shareId=${shareId}` : "";

    try {
      let response = null;
      if (shareId) {
        const newUrl = `${appConfig.apiDomainUrl}${url}`;
        response = await request.post<EntityNamesResponse, EntityNamesRequest>(newUrl, payload);
      } else {
        response = await this.datasource.post<EntityNamesResponse, EntityNamesRequest>(url, payload);
      }
      if (response.data) {
        result.data = response.data;
      }
    } catch (e) {
      result.error = true;
      result.message = e.message;
      logger.debug("FieldPickerApiService", "failed to fetch entity lookup", e);
    }
    return result;
  }

  async getFieldAggregations(fields: UserServiceField[]): Promise<Result<FieldAggregationResponse>> {
    this.init();
    const shareId = appConfig.anomShareId;
    let url = this.getUserServiceUrl("filters/fields/aggregations");
    url += shareId ? `&shareId=${shareId}` : "";

    const payload = {
      fields: fields
    };

    const result: Result<FieldAggregationResponse> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      let response = null;
      if (shareId) {
        const newUrl = `${appConfig.apiDomainUrl}${url}`;
        response = await request.post<FieldAggregationResponse, typeof payload>(newUrl, payload);
      } else {
        response = await this.datasource.post<FieldAggregationResponse, typeof payload>(url, payload);
      }
      if (response.data) {
        result.data = response.data;
      }
    } catch (e) {
      result.error = true;
      result.message = e.message;
      logger.debug("FieldPickerApiService", "failed to fetch field aggregations", e);
    }

    return result;
  }

  async getUserServiceListByUseCaseId(useCaseId: string, companyName: string) {
    this.init();
    const url = this.getBizEntityUrl(
      `useCase/v2/userService${useCaseId ? `?useCaseId=${useCaseId}&companyName=${companyName}` : `?companyName=${companyName}`}`
    );

    const result: Result<UserServiceInfoList> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await this.datasource.get<UserServiceInfoList>(url);

      if (response.data) {
        result.data = response.data;
      }
    } catch (e) {
      result.error = true;
      result.message = e.message;
      logger.debug("FieldPickerApiService", "failed to fetch user service list", e);
    }

    return result;
  }

  private getProcessedPickerContext(context: FieldPickerContext) {
    const processedPickerContext = { ...(context || {}) };
    if (processedPickerContext.userServices) {
      processedPickerContext.userServices = processedPickerContext.userServices.filter(usEntry =>
        Boolean(usEntry.userServiceEntityId)
      );
    }
    return processedPickerContext;
  }
}

const fieldPickerApiService = new FieldPickerApiService();

export default fieldPickerApiService;
