import { BizService } from "../explore/BizServiceCommon";
import { UberOperationalizePreviewTypes, UberOpertionalizeApiTypes } from "./types";

class UberOpertionalizeApiService extends BizService {
  createUberOperationalize(opSetupPayload: UberOpertionalizeApiTypes.UberOpSetupRequest) {
    this.init();

    const url = this.getBizEntityUrl("uber/op10ze");
    const request = () =>
      this.datasource.post<UberOpertionalizeApiTypes.UberOpSetupResponse, UberOpertionalizeApiTypes.UberOpSetupRequest>(
        url,
        opSetupPayload
      );

    return this.getResult(request);
  }

  getUberOpSetupStatus(jobId: string) {
    this.init();

    const url = this.getBizEntityUrl(`uber/op10ze/${jobId}/status`);
    const request = () => this.datasource.get<UberOpertionalizeApiTypes.UberOpSetupResponse>(url);

    return this.getResult(request);
  }

  getUberOpPreview(opSetupRequest: UberOpertionalizeApiTypes.UberOpSetupRequest) {
    this.init();

    const url = this.getBizEntityUrl("uber/op10ze/preview");
    const request = () =>
      this.datasource.post<
        UberOperationalizePreviewTypes.UberOpPreviewResponse,
        UberOpertionalizeApiTypes.UberOpSetupRequest
      >(url, opSetupRequest);

    return this.getResult(request);
  }
}

export const uberOpertionalizeApiService = new UberOpertionalizeApiService();
