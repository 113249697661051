import { NumericDataConguration } from "./types";

export const defNumberFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  notation: "compact",
  compactDisplay: "short"
} as any);

export const localeVsConfigMap: Record<string, NumericDataConguration> = {
  "en-US": {
    quickValues: ["250K", "500K", "750K", "1M", "1B"],
    regex: new RegExp(/^([0-9]*\.?[0-9]+)(M|m|B|b|K|k|T|t)?$/),
    abbreviation: {
      T: 1000000000000,
      B: 1000000000,
      M: 1000000,
      K: 1000
    },
    formatter: defNumberFormatter,
    convertToNumber: function (input: string): number {
      return convertToNumber(input, {
        abbreviationMap: this.abbreviation,
        regex: this.regex
      });
    }
  },
  "en-IN": {
    quickValues: ["1L", "5L", "10L", "1Cr", "5Cr"],
    regex: new RegExp(/^([0-9]*\.?[0-9]+)(L|l|Cr|cr|T|t)?$/),
    abbreviation: {
      Cr: 10000000,
      L: 100000,
      T: 1000
    },
    formatter: defNumberFormatter,
    convertToNumber: function (input: string): number {
      return convertToNumber(input, {
        abbreviationMap: this.abbreviation,
        regex: this.regex
      });
    }
  }
};

export const getNumericDataConfiguration = (locale?: string): NumericDataConguration => {
  locale = locale || "en-US";
  return localeVsConfigMap[locale] || localeVsConfigMap["en-US"];
};

type Options = {
  regex: RegExp;
  abbreviationMap: Record<string, number>;
};

export const convertToNumber = (input: string, options?: Options): number => {
  const fRegex = options?.regex ?? new RegExp(/^([0-9]*\.?[0-9]+)(M|m|B|b|K|k|T|t)?$/);
  const fAbbreviationMap = options?.abbreviationMap ?? getNumericDataConfiguration().abbreviation;

  if (fRegex.test(input)) {
    const val = fRegex.exec(input)?.[1];
    let abbv = fRegex.exec(input)?.[2];

    if (abbv && !fAbbreviationMap[abbv]) {
      abbv = abbv.toUpperCase();
    }
    const number = parseFloat(val || "");
    const abbvUnit = abbv ? fAbbreviationMap[abbv] : 1;
    return number * abbvUnit;
  }
  return 0;
};
