import { IncSelectOption } from "@inception/ui";
import { DataFrame, TimeSeries, EntityPropertyValue } from "../../../../core";
import { MetricAggregator, QuantileFraction } from "../../../../core/data/types/MetricTypes";
import TableModel from "../../../datasources/prometheus/core/table_model";
import { PromDataResponse, AggrOverTime, MetricInfo, PromData } from "../../../datasources/prometheus/core/types";
import { ActionSourceTypes, ActionCategoryType, PrimType, TypedObjectValue } from "../../operationalise";
import { StructureTag, EventField } from "../../traces/types";
import {
  UserServiceField,
  SliceSet,
  SliceSpec,
  OverTagAgg,
  OverTimeAgg,
  WidgetQuerySchemaResponse,
  MetricDefLabels,
  SelectorSpec,
  BizField,
  EntityOperation,
  PredicateOp,
  QueryMappingStatus,
  WorkBookDef,
  TimeObj
} from "./common";

export type MetricSourceType =
  | "bizEntityMetric"
  | "userServiceField"
  | "userServiceMetric"
  | "expression"
  | "widgetId"
  | "entity";
export type MetricConfigType =
  | BizEntityMetricConfig
  | UserServiceFieldMetricConfig
  | UserServiceMetricConfig
  | ExpressionMetricConfig
  | EntityMetricConfig;

export type FieldSourceType = "bizEntityField" | "eventField";
export type FieldConfigType = BizEntityFieldConfig | EventFieldQueryConfig;

interface FieldConfigDefinition {
  id: string;
  sourceType: FieldSourceType;
}

interface BizEntityFieldConfig {
  bizField: BizField;
}

export interface EventFieldQueryConfig {
  userServiceField?: UserServiceField /** Deprecated */;
  userServiceFields?: UserServiceField[];
  filterExpressions: UserServiceFilterExpression[];
}

export interface EventFieldFieldDefinition extends FieldConfigDefinition {
  sourceType: "eventField";
  eventFieldQueryConfig: EventFieldQueryConfig;
}

export interface BizFieldDefinition extends FieldConfigDefinition {
  sourceType: "bizEntityField";
  bizEntityFieldConfig: BizEntityFieldConfig;
}

export type FieldDefinition = BizFieldDefinition | EventFieldFieldDefinition;

interface MetricQueryConfig {
  metricName: string;
  tagFilter: unknown; // TODO: update after backend bug fix
  timeAgg: {
    timeAggFunction: AggrOverTime;
    interval: {
      value: number;
      unit: string;
    };
    traceMetricQuery: string;
    binaryOperator: string;
  };
}

interface CompareConfigDetails {
  scheduledQueryConfig: {
    sqIds: string[];
    metricToMQId: Record<string, { mqId: string; sqId: string }>;
  };
  metricMetadataId: string;
  metricQueryConfigs: MetricQueryConfig[];
  metricEntityId: string;
}

type MetricInternalFetchConfig = {
  generatedMetricConfig: {
    metricEntityId: string;
    metricMetadataId: string;
    scheduledQueryConfig: {
      sqIds: string[];
      mqIds: Record<string, string>;
    };
    metricQueryConfigs: MetricQueryConfig[];
  };
  generatedCompareConfig: {
    compareConfigDetails: Record<string, CompareConfigDetails>;
  };
};

type MetricOperationalizeConfig = {
  compareConfigs?: Record<string, CompareConfig>;
};

export interface MetricConfigDefinition {
  id: string;
  name: string;
  sourceType: MetricSourceType;

  labels?: MetricDefLabels;
  doNotSave?: boolean;

  subType?: string;
  isSpikePositive?: boolean;
  kpiId?: string;
  setupSketch?: boolean;

  /**
   * @deprecated
   */
  internalFetchConfig?: MetricInternalFetchConfig;
  /**
   * @deprecated
   */
  operationalizeConfig?: MetricOperationalizeConfig;
}

export interface BizEntityMetricConfigDefinition extends MetricConfigDefinition {
  sourceType: "bizEntityMetric";
  bizEntityMetricConfig: BizEntityMetricConfig;
}

export interface UserServiceMetricConfigDefinition extends MetricConfigDefinition {
  sourceType: "userServiceMetric";
  userServiceMetricConfig: UserServiceMetricConfig;
}

export interface UserServiceFieldMetricConfigDefinition extends MetricConfigDefinition {
  sourceType: "userServiceField";
  userServiceFieldMetricConfig: UserServiceFieldMetricConfig;
}

export interface ExpressionMetricConfigDefinition extends MetricConfigDefinition {
  sourceType: "expression";
  expressionMetricConfig: ExpressionMetricConfig;
}

export interface EntityMetricConfigDefinition extends MetricConfigDefinition {
  sourceType: "entity";
  entityMetricConfig: EntityMetricConfig;
}

export type WidgetConfigModes = "create" | "edit";

export interface UserServiceFieldSliceSet {
  slices: UserServiceFieldSlice[];
}

export interface UserServiceFieldSlice {
  tagName: string;
  userServiceField: UserServiceField;
}

export interface TagFilter {
  tagName: string;
  op: string;
  tagValue: string;
  tagValues?: string[];
  fieldName: string;
}

export interface BizEntityMetricConfig {
  metricName: string;
  metricId: string;
  filter: TagFilter[];
  aggregator: string;
  sliceSet: SliceSet;
  internalFetchConfig?: InternalFetchConfig;
}

export interface UserServiceMetricConfig {
  metricName: string;
  metricId: string;
  filter: TagFilter[];
  aggregator: string;
  sliceSet: SliceSet;
  internalFetchConfig?: InternalFetchConfig;
}

export interface Expression {
  // One of scalar or expressionMetricConfig needs to be present
  scalar?: number;
  expressionMetricConfig?: ExpressionMetricConfig;
}

export enum BinaryArithmeticOperator {
  binaryOperatorUnset = "binary_operator_unset",
  addition = "addition",
  subtraction = "subtraction",
  multiplication = "multiplication",
  division = "division"
  // modulo = "modulo",
  // power = "power",
  // equal = "equal",
  // notEqual = "not_equal",
  // greaterThan = "greater_than",
  // lessThan = "less_than",
  // greaterOrEqual = "greater_or_equal",
  // lessOrEqual = "less_or_equal",
}

export interface ExpressionDef {
  leftExpr: Expression;
  op: BinaryArithmeticOperator;
  rightExpr: Expression;
}
export interface ExpressionMetricConfig {
  // oneOf sliceSpec or expression
  sliceSpec?: SliceSpec;
  expression?: ExpressionDef;
  tagAgg?: OverTagAgg;
  timeAgg?: OverTimeAgg;
}

export interface EntitySlice {
  slice: BizField; // update to bizField
  tagName: string;
}

export interface EntityMetricConfig {
  aggregator: string;
  bizField: BizField;
  lookback: {
    unit: string;
    value: number;
  };
  slices: EntitySlice[];
}

export interface UserServiceFieldMetricConfig {
  userServiceField: UserServiceField;
  aggregator: string;

  /** @deprecated Use eventFilters instead */
  filterExpressions?: UserServiceFilterExpression[];
  eventFilters?: UserServiceFilterList;

  sliceSets: UserServiceFieldSliceSet[];
  internalFetchConfig?: InternalFetchConfig;
  metricDefProperties?: MetricDefProperties;
  lookBack?: TimeObj;
}

export interface UserServiceFilter {
  userServiceFilterExpressions: UserServiceFilterExpression[]; // AND
}

export interface UserServiceFilterList {
  userServiceFilters?: UserServiceFilter[]; // OR
  expressionTree?: UserServiceFilterExpressionTree;
}

export type MetricUserServiceFilters = Record<string, UserServiceFilterList>;

export type MetricUserServiceFilterExpression = Record<string, UserServiceFilterExpressionTree>;

export enum LogicalOperator {
  AND = "AND",
  OR = "OR"
}

export interface UserServiceFilterExpressionTree {
  logicalOperator: LogicalOperator;
  filterNodes: UserServiceFilterNode[];
}

export interface UserServiceFilterNode {
  expression?: UserServiceFilterExpression;
  expressionTree?: UserServiceFilterExpressionTree;
}

export interface UserServiceFilterExpression {
  field: UserServiceField;
  operator: string;
  value: string;
  values?: string[];
}

interface InternalFetchConfig {
  queryConfigId: string;
  internalMetricName: string;
  configDesc: string;
  sourceType: MetricSourceType;
  sqIds: string[];
  mqIds: string[];
}

export enum CompareConfigType {
  peer = "peerGroupCompare",
  timeshift = "timeShiftCompare",
  baseline = "compareBaseline",
  static = "staticCompare"
}

type TagFilterList = {
  filters: TagFilter[];
};

type CompareConfigProperties = {
  linkedDashboards: EntityPropertyValue; // SetValue
};

interface CompareConfigDefinition {
  type: CompareConfigType;
  compareName: string;
  compareConfigId: string;
  incidentConfig: IncidentConfig;
  actionConfigs?: Record<string, NotifyActionConfig>;
  includes?: TagFilterList[];
  disabled?: boolean;
  dsFreqSecs?: number;
  dsFunction?: string;
  sliceSet?: SliceSet;
  usfSlices?: UserServiceFieldSliceSet;
  props?: CompareConfigProperties;
  mqProps?: MqProps;
  rollingFunction?: string;
  rollingFreqSecs?: number;
}

export interface PeerGroupCompareConfig {
  aggFunc: string;
  bizFields: BizField[];
}

export interface TimeShiftCompareConfig {
  shiftSecs: number;
}

export interface StaticCompareConfig {
  lowerBound?: number;
  upperBound?: number;
}

export interface MqProps {
  "season.secs"?: string;
  "train.hist.secs"?: string;
  "fill.value"?: string;
}

export type CompareConfig =
  | PeerGroupCompareConfigDefinition
  | TimeShiftCompareConfigDefinition
  | BaselineCompareConfigDefinition
  | StaticCompareConfigDefinition;

export interface PeerGroupCompareConfigDefinition extends CompareConfigDefinition {
  type: CompareConfigType.peer;
  peerGroupCompareConfig: PeerGroupCompareConfig;
}

export interface TimeShiftCompareConfigDefinition extends CompareConfigDefinition {
  type: CompareConfigType.timeshift;
  timeShiftCompareConfig: TimeShiftCompareConfig;
}

export interface BaselineCompareConfigDefinition extends CompareConfigDefinition {
  type: CompareConfigType.baseline;
  shConfig: ShConfig;
  advancedConfig?: Record<string, any>;
}

export interface ShConfig {
  type: AshTypes;
  frequency: number[];
}

export type AshTypes = "auto" | "specified";

export interface StaticCompareConfigDefinition extends CompareConfigDefinition {
  type: CompareConfigType.static;
  staticCompareConfig: StaticCompareConfig;
}

// interface Notification {
//   notificationTypes: string[];
// }

export interface IncidentConfig {
  id: string;
  evaluationCriteria: string;
  lowerThresholdMultiplier?: number; // Percentage
  upperThresholdMultiplier?: number; // Percentage
}

type BizFieldSource = {
  sourceType: string;
  sourceName: string;
};

type BizFieldMeta = {
  bizFieldSources: BizFieldSource[];
};

/**
 * Represents Business entity fields that are associated to an Entity Type.
 * Example : Entity Type is merchent and biz fields is ltv, type number.
 */
export type BizFieldInfo = {
  bizField: BizField;
  metadata?: BizFieldMeta;
};

export type MetricDefinition =
  | BizEntityMetricConfigDefinition
  | UserServiceFieldMetricConfigDefinition
  | UserServiceMetricConfigDefinition
  | ExpressionMetricConfigDefinition
  | EntityMetricConfigDefinition;

export interface DataDefinition {
  fields: Record<string, FieldDefinition>;
  metrics: Record<string, MetricDefinition>;
}

export interface VisualizationDataDef {
  id: string;
  type: MetricSourceType;
  enabled: boolean;
}

export interface Visualization {
  id: string;
  type: string;
  dataDefs: VisualizationDataDef[];
  properties?: Record<string, any>;
}

export interface MetricDefSliceProperties extends UserServiceFieldSliceSet {
  legendsFormat: string;
}

export interface MetricDefProperties {
  sliceProperties: MetricDefSliceProperties[];
}

export interface CommonMetricProperties {
  id: "common-metric-properties";
  type: "common-metric-properties";
  properties: Record<string, MetricDefProperties>;
}

export interface WidgetConfig {
  name: string;
  bizEntityType?: string;
  userServiceEntityId?: string; // not present for noun case;
  cohortDefinition?: CohortConfig;
  dataDefinition: DataDefinition;
  visualizations: string[]; // visualizations also consists of stringified common metric properties as a entry in the list
  isStatic: boolean; // This is true for system generated widgets
  createdBy?: AuditInfo;
  lastUpdatedBy?: AuditInfo;
  labels?: Record<string, string>;
  mappingStatus?: QueryMappingStatus;

  metricUserServiceFilters?: Record<string, UserServiceFilterList>;
  entityFilters?: ExploreEntityFilter[];
  description?: string;
}

export interface WidgetConfigDTO extends Omit<WidgetConfig, "visualizations"> {
  visualizations: Visualization[];
  userServiceEntityName?: string; // not present for noun case;
  entityTypeName?: string; // present for noun case
  commonMetricProperties?: CommonMetricProperties;
}

export type ExploreDataFrame = DataFrame;

export type WidgetRequest = {
  widgetConfig: WidgetConfig;
  version?: number;
  actions?: WidgetAction;
};

export type WidgetActionType = "convertToBizMetric" | "saveAsNamedCohort" | "delete";

export type WidgetAction = {
  metricActions?: Record<string, WidgetActionType>;
  fieldActions?: Record<string, WidgetActionType>;
  cohortAction?: WidgetActionType;
};

export interface WidgetListResponse {
  widgetResponse: WidgetResponse[];
  errors: string[];
  status: number;
  opBasicInfo: Record<string, OpBasicInfoList>;
}

export interface OpBasicInfoList {
  opBasicInfo: OpBasicInfo[];
}

export interface OpBasicInfo {
  opConfigId: string;
  name: string;
  isDisabled: boolean;
  isDraft: boolean;
}

export interface WidgetResponse {
  widgetConfig: WidgetConfig;
  widgetId: string;
  version: string;
  widgetName?: string;
  errors?: string[];
  entityValidationInfo?: Record<string, EntityValidationInfo>;
  querySchema: WidgetQuerySchemaResponse;
  opBasicInfo?: OpBasicInfoList;
}

export interface WidgetResponseDTO extends Omit<WidgetResponse, "widgetConfig" | "version"> {
  widgetConfig: WidgetConfigDTO;
  version: number;
  opInfos?: OpBasicInfo[]; // Will be added at the API level for search call
}

export interface WidgetUpsertResponse {
  widgetId: string;
  querySchema: WidgetResponse["querySchema"];
  message: string;
  errors: string[];
}

export type ExploreApiWarning = {
  message: string;
};

export interface ExploreEntityFilter {
  filters: BizFieldPredicate[];
}

export interface DownloadOptions {
  fileName: string;
  tagToHeaderName: Record<string, string>;
  metricToHeaderName: Record<string, string>;
}

export interface WidgetDataRequest {
  limit: number;
  sliceSpec: SliceSpec[];
  entityFilters: ExploreEntityFilter[];
  tagFilters: TagFilter[];
  excludeWidgetData: boolean;
  metricUserServiceFilters?: MetricUserServiceFilters;
  download?: DownloadOptions;
}

export interface WidgetAdhocDataRequest extends WidgetDataRequest {
  widgetConfig: WidgetConfig;
  widgetId?: string;
  version?: number;
}

export interface DataSortSpec {
  sliceSpecId?: string;
}

export interface WidgetAdhocDataRequestV2 extends WidgetDataRequest {
  widgetConfig?: WidgetConfig;
  widgetId?: string;
  version?: number;
  limit: number;
  sliceSpec: SliceSpec[];
  dataSortSpec: DataSortSpec;
  entityFilters: ExploreEntityFilter[];
  metricUserServiceFilters: MetricUserServiceFilters;
}

export interface WidgetAdhocDataRequestV2Download {
  dataRequest: WidgetAdhocDataRequestV2;
  workbookDef: WorkBookDef;
}

export interface WidgetCompareDataRequest {
  metricId: string;
  compareId: string;
  tagFilters: TagFilter[];
  selectorSpec: SelectorSpec;
  frequency: TimeObj;
  forCount: boolean;
}

export interface WidgetAdhocCompareDataRequest extends WidgetCompareDataRequest {
  widgetConfig: WidgetConfig;
}

/// Widget Types here

type WidgetMetaData = {
  metricErrors: Record<string, string>;
  fieldErrors: Record<string, string>;
};

interface ExploreStructureTag extends StructureTag {
  key: string;
  value: string;
  values?: string[];
}

export interface ExploreEvent extends EventField {
  fields: ExploreStructureTag[];
}

export interface FieldResult {
  id: string;
  dataDefinitionId: string;
  data: Record<string, ExploreEvent[]>;
  entityLookupData: Record<string, string>;
  warnings: ExploreApiWarning[];
}

export interface ExploreData extends PromData {
  resultSchema: MetricInfo[];
  preLimitSelectionCount: number;
}

export interface ExploreDataResponse extends Omit<PromDataResponse, "data"> {
  data: ExploreData;

  // These exist only for operationalize case in compareConfigData
  seasonSecs?: number;
  suppressionInfo?: SuppressionInfo[];
  suppressionReasonInfo?: Record<string, string>;
}

export interface PostAggResultResponse {
  data: Record<string, ExploreDataResponse>;
  entityLookupData: Record<string, string>;
  percentageChangeData: Record<string, ExploreDataResponse>;
  timeShiftedData: Record<string, ExploreDataResponse>;
}

export interface MetricResult {
  dataDefinitionId: string;
  data: Record<string, ExploreDataResponse>;
  compareConfigData: Record<string, ExploreDataResponse>;
  postAggResult: PostAggResultResponse;
  forecastResult: ForecastResultResponse;
  entityLookupData: Record<string, string>;
  dataQueryConfig?: Record<string, any>;
  warnings: ExploreApiWarning[];
  fieldResult?: FieldResult[];
}

export type SuppressionInfo = {
  start: number;
  end: number;
};

export interface MetricResultData {
  data: Array<TimeSeries | TableModel>;
  schema: MetricInfo[];
  preLimitSelectionCount: number;

  // These exist only for operationalize case in compareConfigData
  seasonSecs?: number;
  suppressionInfo?: SuppressionInfo[];
  suppressionReasonInfo?: Record<string, string>;
}

export interface MetricResultDataDTO extends Omit<MetricResultData, "data"> {
  data: DataFrame[];
  implicitSliceTag: string;
  bizTag: string;
  resultSeriesName: string;
  resultSeriesId: string;
  dataDefinitionId: string;
}

export interface PostAggResult extends Pick<PostAggResultResponse, "entityLookupData"> {
  data: Record<string, MetricResultData>;
  percentageChangeData: Record<string, MetricResultData>;
  timeShiftedData: Record<string, MetricResultData>;
}

export interface PostAggResultDTO extends Pick<PostAggResultResponse, "entityLookupData"> {
  data: Record<string, MetricResultDataDTO>; // this is converted.
  percentChangeData: Record<string, MetricResultDataDTO>;
  timeShiftData: Record<string, MetricResultDataDTO>;
}

export interface ForecastResultResponse {
  data: Record<string, ExploreDataResponse>;
  delta: Record<string, ExploreDataResponse>;
  entityLookupData: Record<string, string>;
  percentageChangeData: Record<string, ExploreDataResponse>;
  lowerBoundData: Record<string, ExploreDataResponse>;
  upperBoundData: Record<string, ExploreDataResponse>;
}

export interface ForcastResult extends Pick<ForecastResultResponse, "entityLookupData"> {
  data: Record<string, MetricResultData>; // this is converted.
  delta: Record<string, MetricResultData>; // this is converted.
  percentageChangeData: Record<string, MetricResultData>;
  lowerBoundData: Record<string, MetricResultData>;
  upperBoundData: Record<string, MetricResultData>;
}

export interface ForecastResultDTO extends Pick<ForecastResultResponse, "entityLookupData"> {
  data: Record<string, MetricResultDataDTO>; // this is converted.
  delta: Record<string, MetricResultDataDTO>; // this is converted.
  percentChangeData: Record<string, MetricResultDataDTO>;
  lowerBoundData: Record<string, MetricResultDataDTO>;
  upperBoundData: Record<string, MetricResultDataDTO>;
}

export interface MetricResultDTO
  extends Pick<MetricResult, "dataDefinitionId" | "entityLookupData" | "dataQueryConfig" | "warnings" | "fieldResult"> {
  data: Record<string, MetricResultData>; // this is converted.
  compareConfigData: Record<string, MetricResultData>; // this is converted.
  postAggResult: PostAggResult;
  forecastResult: ForcastResult;
}

type ImpactedRegion = {
  impactedNodes: string[];
};

export interface WidgetData {
  metricResults: MetricResult[];
  fieldResult: FieldResult[];
  metadata?: WidgetMetaData;
  funnelResult?: FunnelData;
}

export interface FunnelData {
  path: string;
  stages: FunnelStageData[];
  impactedRegions?: ImpactedRegion[];
}

export type ConversionAnalysis = {
  upperBoundForCount: number;
  lowerBoundForCount: number;
  isImpacted: boolean;
  isMonitored: boolean;
};

export interface FunnelStageData {
  userServiceEntityId: string;
  name: string;
  count: number;
  countForComparison: number;
  analysis: ConversionAnalysis;
  nodeId: string;
}

export interface WidgetDataDTO extends Omit<WidgetData, "metricResults"> {
  metricResults: MetricResultDTO[];
}

export interface CohortSegment {
  cohortEntityId: string;
  cohortId: string;
  draft: boolean;
  function: "top" | "bottom";
  range: {
    from: number;
    to: number;
  };
}

export interface CohortSegmentList {
  segment: CohortSegment[];
}

export interface CohortConfig {
  cohortId: string;
  name: string;
  cohortCreationConfig?: CohortCreationConfig;
  isNamedCohort?: boolean;
  internalConfig?: {
    cohortEntityId?: string;
  };
  isExternal?: boolean;
  segmentCreationConfigs?: CohortSegmentList[];
}

export interface CohortCreationConfig {
  bizFieldPredicates: BizFieldPredicate[];
  metricPredicates: MetricPredicate[];
  createdBy?: AuditInfo;
}

export interface BizFieldPredicate {
  bizField: BizField;
  op: EntityOperation;
  value: string;
  values: string[];
}

/**
 * Maps EntityOperation to possible trace/event query operations.
 * Map should be updated manually on any change in type of EntityOperation.
 */
export enum EntityOpToTraceQueryOpMap {
  "eq" = "=",
  "neq" = "!=",
  "lt" = "<",
  "le" = "<=",
  "gt" = ">",
  "ge" = ">=",
  "regex" = "=~",
  "in" = "in",
  "range" = "in",
  "startsWith" = "=~",
  "exists" = "!=",
  "doesNotExist" = "=",
  "contains" = "=~"
}

export interface MetricPredicate {
  metric: MetricDefinition;
  /** @deprecated This property has been deprecated on the backend. Please make sure aggregateDuration property is non-empty */
  aggregateDurationSeconds: number;
  aggregateDuration: TimeObj;
  aggregator: string;
  op: PredicateOp;
  value: string;
}

export interface DraftWidgetResponse {
  errors: string[];
  message: string;
  status: number;
  widgetId: string;
}

export interface DraftWidgetPayload {
  widgetConfig: WidgetConfig;
}

export type CohortState = "draft" | "saved";

export type ComputationStatus = {
  isNotReady: boolean;
  lastRunAtMillis: number;
  percentCompleted: number;
};

export interface ResultCohort {
  id: string;
  name: string;
  cohortEntityId?: string;
  membersCount?: number;
  cohortDefinition?: CohortConfigResponse;
  segments?: ResultCohort[];
  computationStatus?: ComputationStatus;
}

export interface CohortListResponse {
  cohorts: ResultCohort[];
}

export interface AuditInfo {
  log: string;
  timeMillis: string;
  user: string;
}

export interface ResultCohortStateResponse {
  membersCount: number;
  status: "processing" | "success" | "failed";
  entityLookupData: Record<string, string>;
  totalCount: string;
}

export interface CohortStatus {
  isProcessing: boolean;
  error?: unknown;
  statusResponse?: ResultCohortStateResponse;
}

export interface CohortConfigResponse {
  cohortDefinition: CohortConfig;
  entityLookupData: Record<string, string>;
}

export type CohortPredicateType = "bizField" | "metric";

export interface CohortSelectOption extends IncSelectOption {
  cohort: ResultCohort;
}

export interface NotifyExpressions {
  expressions: BizFieldPredicate[];
}

export interface ActionRuleConfig {
  ruleId?: number;
  conditions?: NotifyExpressions[];
}

export interface TypedObject {
  type?: PrimType;
  bizField?: BizFieldInfo;
  value: TypedObjectValue;
}

export interface ActionChannelConfig {
  actionType: ActionCategoryType;
  sourceTypeId: ActionSourceTypes;
  integrationActionConfigId: string;
  params: Record<string, TypedObject>;
  triggerOnlyOnce: boolean;
}

export interface NotifyActionConfig {
  actionId: string;
  actionRuleConfig: ActionRuleConfig;
  actionChannelConfig: ActionChannelConfig;
}

export interface TraceUIAggregation {
  aggrType: MetricAggregator;
  fraction?: QuantileFraction; // if agg type is "quantile" , fraction value should be present
}

export interface ActionConfigResponse {
  actionConfigDetails: ActionConfig[];
}

export interface ActionConfig {
  actionConfig: NotifyActionConfig;
  cohortId: string;
  compareId: string;
  metricId: string;
  widgetId: string;
  isCompareDisabled: boolean;
}

export interface ActionDetailsResponse {
  actionDetails: ActionDetails[];
  entityLookupData: Record<string, string>;
}

export interface ActionDetails {
  // action details
  actionType: ActionCategoryType;
  actionSubType: ActionSourceTypes;
  integrationActionConfigName: string;
  isCompareDisabled: boolean;

  // Incident details
  incidentId: string;
  createdAtMillis: string;
  updatedAtMillis: string;
  incidentCreatedAtMillis: string;
  incidentEndedAtMillis: string;
  entityIds: string[];

  // Widget details
  actionId: string;
  widgetId: string;
  compareId: string;
  metricId: string;

  // Entity details
  bizEntity: string;
  cohortId: string;

  // utility props
  widgetName: string;
  metricName: string;
  channel: string;
  drillDownUrl: string;
  summary: string;
}

export interface ActionCountResponse {
  actionCount: ActionCount[];
}
export interface ActionCount {
  actionType: ActionCategoryType;
  actionSubType: ActionSourceTypes;
  count: string;
}

export interface ActionDetailsSummary {
  timestampMillis: string;
  details: ActionDetailsSummaryDetails[];
}

interface ActionDetailsSummaryDetails {
  entityIds: string[];
}

export interface IncidentsCountResponse {
  incidentsCount: number;
  status: string;
}

export interface ActionPreview {
  paramName: string;
  typedObject: TypedObject;
}

export interface ActionPreviewResponse {
  actionPreview: ActionPreview[];
}

export interface EntityValidationInfo {
  lastSeenMillis: string;
  name: string;
}

export interface CompareQuerySchemaResponse {
  resultSchema: MetricInfo[];
  frequencies: TimeObj[];
  entityLookUpData: Record<string, string>;
}

export interface CompareQuerySchemaRequest {
  // oneOf
  version?: string;
  incidentId?: string;

  generateDemoData?: boolean;
  v2Api?: boolean;
}

export interface CompareOperatorResponse {
  compareoperator: "None" | "Above" | "Below" | "AboveOrBelow";
}

interface SerieInfo {
  tags: Record<string, string>;
}

type OpDemoDatum = {
  status: "open" | "closed";
  totalAnomalyPoints: number;
  incidentRelativeStartTime: {
    value: number;
    unit: string;
  };
  incidentRelativeEndTime?: {
    value: number;
    unit: string;
  };
  violatingSeries: SerieInfo[];
  incidentSummary: {
    name: string;
    description: string;
    causeType: string;
    causeDimensions: {
      serverError: string;
      networkLatency: string;
      browserVersion: string;
    };
    slices: SerieInfo[];
    impactFields: SerieInfo[];
  };
  drilldownUrl: string;
};

export type OpDemoData = {
  demoIncident: OpDemoDatum[];
};

export type DemoDataGenInfo = {
  companyName: string;
  purpose: string;
  usecase: string;
  vertical: string;
  subVertical: string;
  opDemoData?: OpDemoData;
};

export type DemoDataParams = Partial<
  DemoDataGenInfo & {
    generateDemoData: boolean;
  }
>;

export type EntityNamesRequest = {
  entityId: string[];
};

export type EntityNamesResponse = {
  entityLookupData: Record<string, string>;
};
