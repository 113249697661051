import { IncSelectOption } from "@inception/ui";
import { InsightDescription, UseCaseConfig } from "../services/api";
import { logger } from "../core";
import { CompareOperator, OpCreationConfig, OpInsightType } from "../services/api/operationalise";

export class CopilotUtils {
  static getKpiOptions(useCaseConfig: UseCaseConfig): IncSelectOption[] {
    const { dataPreferences } = useCaseConfig || {};
    const { topKPIs } = dataPreferences || {};
    return (topKPIs?.bizDataQuery || [])
      .map(bizDataQuery => {
        const { buildingBlockConfig, labels, widgetConfig } = bizDataQuery;
        const name = buildingBlockConfig?.name || widgetConfig?.name || labels.name || "";

        if (!name) {
          logger.warn("CopilotUtils", `Could not find name for KPI`, bizDataQuery);
        }

        return name
          ? {
              label: name,
              value: name
            }
          : null;
      })
      .filter(Boolean);
  }

  static getInsightDescriptionForOpCreationConfig(opCreationConfig: OpCreationConfig) {
    const { bizActions, description, name, opCreationConfigMetadata, idProps } = opCreationConfig;

    const { insightType, isOpportunity, spikeDropCompareOperator, causeMetaData, importance } =
      opCreationConfigMetadata || {};

    const automation = Object.values(bizActions || {})
      .map(act => act.alertActionConfig?.name)
      .filter(Boolean)
      .join(", ");
    const causes = causeMetaData?.causes || [];

    const insightDesc: InsightDescription = {
      automation,
      causes,
      description,
      name,
      icon: "diagram",
      importance: importance || "",
      insightType: insightType || OpInsightType.REAL_TIME_DETECTION,
      isOpportunity: isOpportunity || false,
      spikeDropCompareOperator: spikeDropCompareOperator || CompareOperator.Below,
      metric: "",
      trigger: "",
      causeMetaData,
      involvedDimensions: [],
      involvedEvents: (idProps?.primary?.eventTypes?.userServiceInfo || []).map(et => et.userServiceEntityId)
    };

    return insightDesc;
  }
}
