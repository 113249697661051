import { produce, setAutoFreeze } from "immer";
import {
  OpAnalysisCreationConfig,
  OpBizDataQuery,
  OpSchedule,
  DiagnosticKPIs,
  StoryTemplateStr,
  SuppressionConfigDef,
  OpAnalysisType
} from "../../../services/api/operationalise";
import { BizDataQuery, TimeObj, UserServiceFieldSlice } from "../../../services/api/explore";
import { AnalysisListChildPayload, OpAnalysisState, OpAnalysisStateAction, SetAnalysisRecordEntry } from "./types";
import {
  ADD_ANALYSIS_DIAGNOSTIC_KPI,
  ADD_ANALYSIS_DIMENSION_SETS_TO_ANALYZE,
  ADD_ANALYSIS_KPI,
  ADD_STORY_TEMPLATE_FORMAT,
  CLONE_ANALYSIS_DIAGNOSTIC_KPI,
  CLONE_ANALYSIS_KPI,
  CLONE_STORY_TEMPLATE_FORMAT,
  DELETE_ANALYSIS_DIAGNOSTIC_KPI,
  DELETE_ANALYSIS_DIMENSION_SETS_TO_ANALYZE,
  DELETE_ANALYSIS_KPI,
  DELETE_STORY_TEMPLATE_FORMAT,
  INITIALIZE_ANALYSIS_CONFIG,
  OVERRIDE_ANALYSIS_DIAGNOSTIC_KPI,
  SET_ANALYSIS_COMPARE_TO_OFFSET,
  SET_ANALYSIS_DIAGNOSTIC_FIELDS,
  SET_ANALYSIS_DIAGNOSTIC_KPI,
  SET_ANALYSIS_DIAGNOSTIC_KPI_NAME,
  SET_ANALYSIS_DIAGNOSTIC_KPI_QUERY,
  SET_ANALYSIS_DIMENSIONS_TO_ANALYZE,
  SET_ANALYSIS_DIMENSION_SETS_TO_ANALYZE,
  SET_ANALYSIS_KPI,
  SET_ANALYSIS_KPI_NAME,
  SET_ANALYSIS_KPI_QUERY,
  SET_ANALYSIS_ROLLING_FREQ,
  SET_ANALYSIS_SCHEDULE,
  SET_ANALYSIS_TYPES,
  SET_DRY_RUN,
  SET_STORY_TEMPLATE_FORMAT,
  SET_STORY_TEMPLATE_FORMAT_JSON,
  SET_STORY_TEMPLATE_FORMAT_NAME,
  SET_SUPPRESSION,
  SET_TEMPLATE_FORMAT
} from "./action_names";
import AnalysisOperationalizeImpl from "./AnalysisOperationalizeImpl";

const opAnalysisReducer = (state: OpAnalysisState, action: OpAnalysisStateAction): OpAnalysisState => {
  const { payload, type } = action;

  switch (type) {
    // initialize
    case INITIALIZE_ANALYSIS_CONFIG: {
      const nContext = payload as OpAnalysisCreationConfig;
      state.opAnalysisImpl = new AnalysisOperationalizeImpl(nContext);
      return state;
    }
    case SET_DRY_RUN: {
      const nContext = payload as boolean;
      state.dryRun = nContext;
      return state;
    }
    // schedule
    case SET_ANALYSIS_SCHEDULE: {
      const nContext = payload as OpSchedule;
      state.opAnalysisImpl.setSchedule(nContext);
      return state;
    }
    // kpis
    case SET_ANALYSIS_KPI: {
      const nContext = payload as Record<string, OpBizDataQuery>;
      state.opAnalysisImpl.setKpi(nContext);
      return state;
    }
    case ADD_ANALYSIS_KPI: {
      const nContext = payload as string;
      state.opAnalysisImpl.addKpiEntry(nContext);
      return state;
    }
    case CLONE_ANALYSIS_KPI: {
      const nContext = payload as string;
      state.opAnalysisImpl.cloneKpiEntry(nContext);
      return state;
    }
    case DELETE_ANALYSIS_KPI: {
      const nContext = payload as string;
      state.opAnalysisImpl.deleteKpiEntry(nContext);
      return state;
    }
    case SET_ANALYSIS_KPI_NAME: {
      const nContext = payload as SetAnalysisRecordEntry<string>;
      state.opAnalysisImpl.setKpiEntryName(nContext.key, nContext.payload);
      return state;
    }
    case SET_ANALYSIS_KPI_QUERY: {
      const nContext = payload as SetAnalysisRecordEntry<BizDataQuery>;
      state.opAnalysisImpl.setKpiEntryBizDataQuery(nContext.key, nContext.payload);
      return state;
    }
    // diagnostic kpis
    case SET_ANALYSIS_DIAGNOSTIC_KPI: {
      const nContext = payload as Record<string, DiagnosticKPIs>;
      state.opAnalysisImpl.setDiagnosticKpi(nContext);
      return state;
    }
    case OVERRIDE_ANALYSIS_DIAGNOSTIC_KPI: {
      const nContext = payload as SetAnalysisRecordEntry<DiagnosticKPIs>;
      state.opAnalysisImpl.overrideDiagnosticKpi(nContext.key, nContext.payload);
      return state;
    }
    case ADD_ANALYSIS_DIAGNOSTIC_KPI: {
      const nContext = payload as SetAnalysisRecordEntry<string>;
      state.opAnalysisImpl.addDiagnosticKpiEntry(nContext.key, nContext.payload);
      return state;
    }
    case CLONE_ANALYSIS_DIAGNOSTIC_KPI: {
      const nContext = payload as SetAnalysisRecordEntry<number>;
      state.opAnalysisImpl.cloneDiagnosticKpiEntry(nContext.key, nContext.payload);
      return state;
    }
    case DELETE_ANALYSIS_DIAGNOSTIC_KPI: {
      const nContext = payload as SetAnalysisRecordEntry<number>;
      state.opAnalysisImpl.deleteDiagnosticKpiEntry(nContext.key, nContext.payload);
      return state;
    }
    case SET_ANALYSIS_DIAGNOSTIC_KPI_NAME: {
      const nContext = payload as SetAnalysisRecordEntry<AnalysisListChildPayload<string>>;
      state.opAnalysisImpl.setDiagnosticKpiEntryName(nContext.key, nContext.payload.index, nContext.payload.payload);
      return state;
    }
    case SET_ANALYSIS_DIAGNOSTIC_KPI_QUERY: {
      const nContext = payload as SetAnalysisRecordEntry<AnalysisListChildPayload<BizDataQuery>>;
      state.opAnalysisImpl.setDiagnosticKpiEntryBizDataQuery(
        nContext.key,
        nContext.payload.index,
        nContext.payload.payload
      );
      return state;
    }
    // rolling freq and compare offset
    case SET_ANALYSIS_ROLLING_FREQ: {
      const nContext = payload as TimeObj;
      state.opAnalysisImpl.setRollingFreq(nContext);
      return state;
    }
    case SET_ANALYSIS_COMPARE_TO_OFFSET: {
      const nContext = payload as TimeObj;
      state.opAnalysisImpl.setCompareToOffset(nContext);
      return state;
    }
    // dimensions
    case SET_ANALYSIS_DIMENSIONS_TO_ANALYZE: {
      const nContext = payload as UserServiceFieldSlice[];
      state.opAnalysisImpl.setDimensionsToAnalyze(nContext);
      return state;
    }
    // diagnostic fields
    case SET_ANALYSIS_DIAGNOSTIC_FIELDS: {
      const nContext = payload as UserServiceFieldSlice[];
      state.opAnalysisImpl.setDiagnosticFields(nContext);
      return state;
    }
    // dimension sets
    case ADD_ANALYSIS_DIMENSION_SETS_TO_ANALYZE: {
      state.opAnalysisImpl.addDimensionSetToAnalyze();
      return state;
    }
    case SET_ANALYSIS_DIMENSION_SETS_TO_ANALYZE: {
      const nContext = payload as AnalysisListChildPayload<UserServiceFieldSlice[]>;
      state.opAnalysisImpl.setDimensionSetToAnalyze(nContext.index, nContext.payload);
      return state;
    }
    case DELETE_ANALYSIS_DIMENSION_SETS_TO_ANALYZE: {
      const nContext = payload as AnalysisListChildPayload<any>;
      state.opAnalysisImpl.deleteDimensionSetToAnalyze(nContext.index);
      return state;
    }
    // template format
    case SET_TEMPLATE_FORMAT: {
      const nContext = payload as string;
      state.opAnalysisImpl.setTemplateFormat(nContext);
      return state;
    }
    // story template
    case SET_STORY_TEMPLATE_FORMAT: {
      const nContext = payload as StoryTemplateStr[];
      state.opAnalysisImpl.setStoryTemplates(nContext);
      return state;
    }
    case ADD_STORY_TEMPLATE_FORMAT: {
      state.opAnalysisImpl.addStoryTemplateEntry();
      return state;
    }
    case CLONE_STORY_TEMPLATE_FORMAT: {
      const nContext = payload as number;
      state.opAnalysisImpl.cloneStoryTemplateEntry(nContext);
      return state;
    }
    case SET_STORY_TEMPLATE_FORMAT_JSON: {
      const nContext = payload as AnalysisListChildPayload<string>;
      state.opAnalysisImpl.setStoryTemplateJson(nContext.index, nContext.payload);
      return state;
    }
    case SET_STORY_TEMPLATE_FORMAT_NAME: {
      const nContext = payload as AnalysisListChildPayload<string>;
      state.opAnalysisImpl.setStoryTemplateName(nContext.index, nContext.payload);
      return state;
    }
    case DELETE_STORY_TEMPLATE_FORMAT: {
      const nContext = payload as number;
      state.opAnalysisImpl.deleteStoryTemplateEntry(nContext);
      return state;
    }
    // suppression
    case SET_SUPPRESSION: {
      const nContext = payload as SuppressionConfigDef[];
      state.opAnalysisImpl.setSuppression(nContext);
      return state;
    }

    case SET_ANALYSIS_TYPES: {
      const nContext = payload as OpAnalysisType[];
      state.opAnalysisImpl.setAnalysisTypes(nContext);
      return state;
    }

    default:
      return state;
  }
};

setAutoFreeze(false);
export default produce(opAnalysisReducer);
