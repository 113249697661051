import { Action } from "./features";

//place all actions under busniess entity here
export enum BUSINESS_ENTITY_ACTIONS {
  addEntity = "addEntity",
  addBusinessField = "addBusinessField",
  addRelationship = "addRelationship",
  addMapping = "addMapping",
  editEntityConfig = "editEntityConfig",
  updateIcon = "updateIcon",
  showEntities = "showEntities"
}

export const BusinessEntityFeatureActions: Record<keyof typeof Action, Array<keyof typeof BUSINESS_ENTITY_ACTIONS>> = {
  //All CREATE actions goes here
  //Enabled only for Admins,Editors
  CREATE: ["addEntity", "addBusinessField", "addMapping", "addRelationship"],

  //All UPDATE actions goes here
  //Enabled only for Admins,Editors
  UPDATE: ["editEntityConfig", "updateIcon"],

  //All READ actions goes here
  //Enabled for All
  READ: ["showEntities"],

  //All DELETE actions goes here
  //Enabled only for Admins,Editors
  DELETE: []
};
