import { Method } from "axios";
import { isNil } from "lodash";
import { InceptionRequestConfig } from "../types";
import { logger } from "../../../core";
import { mockResponseConfig } from "./mock-response-config";

const DEFAULT_TIMEOUT_MS = 500;

class MockResponseHandler {
  private cache: Record<string, any[]> = {};
  private executionSequence: Record<string, number> = {};
  private handlerMap: HandlerMap = {};
  private urls: Set<string> = new Set();

  constructor() {
    import("./mock-responses");
  }

  registerMockResponse(
    name: string,
    url: string,
    method: Method,
    responseHandler: ResponseHandler,
    timeoutMs = DEFAULT_TIMEOUT_MS
  ) {
    const key = this.getKey(url, method);
    const handlerEntries: HandlerMapEntry[] = this.handlerMap[key] || [];

    if (this.handlerMap[key]) {
      logger.warn("MockResponseHandler", "Mock response already registered", {
        url,
        method,
        name
      });
    }

    this.handlerMap[key] = handlerEntries;

    const baseUrl = url.split("?")[0];
    this.urls.add(baseUrl);

    return this.addHandler(name, responseHandler, timeoutMs, handlerEntries);
  }

  get<T, R>(url: string, config: InceptionRequestConfig) {
    return this.handleRequest<T, R>(url, "GET", config);
  }

  post<T, R>(url: string, config: InceptionRequestConfig) {
    return this.handleRequest<T, R>(url, "POST", config);
  }

  put<T, R>(url: string, config: InceptionRequestConfig) {
    return this.handleRequest<T, R>(url, "PUT", config);
  }

  patch<T, R>(url: string, config: InceptionRequestConfig) {
    return this.handleRequest<T, R>(url, "PATCH", config);
  }

  delete<T, R>(url: string, config: InceptionRequestConfig) {
    return this.handleRequest<T, R>(url, "DELETE", config);
  }

  private addHandler(
    name: string,
    responseHandler: ResponseHandler,
    timeoutMs: number,
    handlerEntries: HandlerMapEntry[]
  ) {
    handlerEntries.push({
      name,
      timeoutMs,
      handler: responseHandler
    });

    return {
      handleNext: (name: string, responseHandler: ResponseHandler, timeoutMs = DEFAULT_TIMEOUT_MS) =>
        this.addHandler(name, responseHandler, timeoutMs, handlerEntries)
    };
  }

  private async handleRequest<T, R>(
    url: string,
    method: Method,
    config: InceptionRequestConfig
  ): Promise<HandleRequestResponse<R>> {
    url = this.getMatchingUrl(url);

    try {
      const shouldHandleRequest = isNil(config?.preferMockResponse)
        ? mockResponseConfig.preferMockResponse
        : config.preferMockResponse;

      if (!shouldHandleRequest) {
        return {
          handled: false,
          response: Promise.resolve({
            config: {},
            data: {} as T,
            headers: {},
            status: 200,
            statusText: "OK"
          } as R)
        };
      }

      const fetchIfNoMockResponse = isNil(config?.fetchIfNoMockResponse)
        ? mockResponseConfig.fetchIfNoMockResponse
        : config.fetchIfNoMockResponse;

      const key = this.getKey(url, method);

      const currentExecution = isNil(this.executionSequence[key]) ? 0 : this.executionSequence[key] + 1;
      this.executionSequence[key] = currentExecution;

      const handlerEntries = this.handlerMap[key] || [];
      const handlerIdx = Math.max(currentExecution, handlerEntries.length - 1);

      const cacheEntry = this.cache[key]?.[handlerIdx];
      const { timeoutMs } = handlerEntries[handlerIdx];

      if (cacheEntry) {
        return new Promise(resolve => {
          setTimeout(
            () =>
              resolve({
                handled: true,
                response: Promise.resolve(cacheEntry)
              }),
            timeoutMs
          );
        });
      }

      const handler = handlerEntries[handlerIdx];
      if (handler) {
        const { data, statusCode = 200, statusText = "OK" } = await handler.handler();
        const response = {
          config: {},
          data: data as T,
          headers: {},
          status: statusCode,
          statusText
        } as R;

        this.cache[key] = this.cache[key] || [];
        this.cache[key][handlerIdx] = response;

        return new Promise(resolve => {
          setTimeout(
            () =>
              resolve({
                handled: true,
                response: Promise.resolve(response)
              }),
            timeoutMs
          );
        });
      } else {
        return {
          handled: !fetchIfNoMockResponse,
          response: Promise.resolve({
            config: {},
            data: null,
            headers: {},
            status: fetchIfNoMockResponse ? 200 : DEFAULT_TIMEOUT_MS,
            statusText: fetchIfNoMockResponse ? "OK" : "No mock response handler has been configured"
          } as R)
        };
      }
    } catch (error) {
      logger.fatal("MockResponseHandler", "Error while processing request with MockResponseHandler", error);

      return {
        handled: false,
        response: Promise.resolve({
          config: {},
          data: null,
          headers: {},
          status: DEFAULT_TIMEOUT_MS,
          statusText: "Error while processing request with MockResponseHandler"
        } as R)
      };
    }
  }

  private getKey(url: string, method: Method) {
    return `${method.toLowerCase()}: ${url}`;
  }

  private getMatchingUrl(url: string) {
    let matchingUrl = "";
    const baseIncomingUrl = (url || "").split("?")[0];

    try {
      this.urls.forEach((storedUrl: string) => {
        if (baseIncomingUrl.endsWith(storedUrl)) {
          matchingUrl = storedUrl;
          throw new Error();
        }
      });
    } catch (error) {
      // Ignoring, since this is to stop forEach once we find a match
    }

    return matchingUrl;
  }
}

export const mockResponseHandler = new MockResponseHandler();

type ResponseHandler = () => Promise<{
  statusCode?: number;
  statusText?: string;
  data: Record<string, any>;
}>;

type HandlerMapEntry = {
  name: string;
  timeoutMs: number;
  handler: ResponseHandler;
};

type HandlerMap = Record<string, HandlerMapEntry[]>;

type HandleRequestResponse<R> = {
  handled: boolean;
  response: Promise<R>;
};
