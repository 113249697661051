export enum RelativeDurationType {
  SECONDS,
  MINUTES,
  HOURS,
  DAYS,
  WEEKS,
  MONTHS
}

export interface IncDuration {
  durationType: RelativeDurationType;
  duration: number;
}
