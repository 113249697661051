import { generateId } from "../../utils";
import { Node, RowDataInternal } from "./types";

export function createRowDataInternalObject<T>(node: Node<T>): RowDataInternal<T> {
  return {
    ...node,
    _id: generateId(),
    _hasChildren: node.children.length > 0,
    _level: 0,
    _parent: "",
    _key: generateId(),
    _showChildren: false,
    _visible: true,
    _children: []
  };
}

export function flattenArray<T>(
  rowData: Array<RowDataInternal<T>>,
  expandAll: boolean,
  parent?: RowDataInternal<T>,
  returnArray?: Array<RowDataInternal<T>>
) {
  returnArray = returnArray || [];

  parent = parent || {
    _id: generateId(),
    children: [],
    _hasChildren: false,
    _level: 0,
    _parent: "",
    _key: generateId(),
    _showChildren: false,
    _visible: true,
    _children: []
  };

  const level = parent?._level === undefined ? 0 : parent?._level + 1;
  rowData.forEach((element: RowDataInternal<T>) => {
    const elemToAdd: RowDataInternal<T> = {
      ...element,
      _hasChildren: element._hasChildren || false,
      _level: level,
      _parent: parent?._key || "",
      _key: element._key || generateId(),
      _showChildren: element._showChildren || expandAll,
      _visible: parent?._showChildren || true
    };

    returnArray?.push(elemToAdd);
    if (element.children && element.children.length > 0) {
      elemToAdd._hasChildren = true;
      elemToAdd._children = element.children.map(
        (child: Node<T>): RowDataInternal<T> => createRowDataInternalObject(child)
      );
      if (expandAll) {
        flattenArray(elemToAdd._children, expandAll, elemToAdd, returnArray);
      }
    }
  });

  return returnArray;
}

export function removeChildren<T>(array: Array<RowDataInternal<T>>, key: string) {
  let childrenIndex = indexOfProperty(array, "_parent", key);

  while (childrenIndex !== -1) {
    removeChildren(array, array[childrenIndex]._key);
    array.splice(childrenIndex, 1);
    childrenIndex = indexOfProperty(array, "_parent", key);
  }
}

export function insertInArray<T>(
  array: Array<RowDataInternal<T>>,
  index: number,
  obj: Array<RowDataInternal<T>> | RowDataInternal<T>
) {
  if (Array.isArray(obj)) {
    obj?.forEach((elem: RowDataInternal<T>, i: number) => {
      array.splice(index + i, 0, elem);
    });
  } else {
    array.splice(index, 0, obj as RowDataInternal<T>);
  }
}

export function indexOfProperty<T>(array: Array<RowDataInternal<T>>, property: string, value: string) {
  for (let i = 0; i < array.length; i++) {
    const v = getKeyValue<any, any>(property)(array[i]);

    if (v === value) {
      return i;
    }
  }

  return -1;
}

export const getKeyValue =
  <T extends Record<string, any>, U extends keyof T>(key: U) =>
  (obj: T) =>
    obj[key];
