import { cx } from "emotion";
import React, { useCallback } from "react";
import IncSmartText from "../SmartText/SmartText";
import { Tag } from "./types";

type TagProps = {
  tag: Tag;
  onClick?: ((tag: Tag) => void) | null;

  // Ideally used this as part of tag group where you might want some to be selected
  // and some not to be selected.
  // The variable nme is weird but since the default style is something which shows selection, I had to
  // create a varaible name for not selected state
  isNotSelected?: boolean;

  /**
   * Class name to be applied to the tag
   */
  tagClassName?: string;

  /**
   * Style to be applied to the tags
   */
  tagStyle?: React.CSSProperties;
};

const IncTag: React.FC<TagProps> = props => {
  const { tag, onClick, isNotSelected, tagClassName = "", tagStyle } = props;

  const onClickInternal = useCallback(
    (evt: React.MouseEvent<HTMLDivElement>) => {
      evt.stopPropagation();
      onClick && onClick(tag);
    },
    [tag, onClick]
  );

  // if we have specific on-click we need to add the pointer cursor
  const defaultClassName = cx("inc-tag", onClick ? "inc-tag-clickable" : "");
  const nonSelectionClassName = isNotSelected ? "inc-tag-nonSelected" : "";

  return (
    <div
      className={cx(defaultClassName, nonSelectionClassName, tagClassName)}
      onClick={onClickInternal}
      style={tagStyle || tag.style || {}}
    >
      <IncSmartText text={tag.label} />
    </div>
  );
};

export default IncTag;
