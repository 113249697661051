import { cx } from "emotion";
import { isEmpty, omit } from "lodash";
import React from "react";
import { Switch } from "antd";
import { IncToolTip } from "../Tooltip";
import { IncInfoIcon } from "../../../icons";
import IncToggleProps from "./types";

const omitProps = ["label", "className", "color", "ref", "helpText", "labelVariant", "onChange"];

export const IncToggle: React.FC<IncToggleProps> = props => {
  const {
    label: labelStr,
    className = "",
    checked = false,
    helpText,
    onChange = () => {},
    variant = "primary",
    labelVariant = "default",
    required
  } = props;

  const toggleClasses = cx({
    toggle: true,
    "toggle-border": !checked
  });

  const toggleProps = omit(props, omitProps);
  const toggle = (
    <Switch
      className={toggleClasses}
      onChange={checked => onChange(checked)}
      {...toggleProps}
    />
  );

  const labelClassName = cx("inc-label-common", {
    "with-info": !isEmpty(helpText),
    "card-header-label": labelVariant === "card-header"
  });
  const label = labelStr ? <label className={labelClassName}>{labelStr}</label> : null;

  return (
    <div
      className={`toggle-container toggle-container--${variant} ${className}`}
      data-checked={checked}
      onClick={e => e.stopPropagation()}
    >
      <div className="inc-flex-row inc-flex-center-vertical marginRt6">
        {label}
        {required && <span className="inc-span-required-star marginLt2">*</span>}
        {helpText && (
          <IncToolTip
            placement="top-start"
            showArrow
            titleText={helpText}
          >
            <IncInfoIcon />
          </IncToolTip>
        )}
      </div>
      {toggle}
    </div>
  );
};
