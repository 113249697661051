import React, { useState, ReactElement, FC, useMemo } from "react";

type Props = {
  className?: string;
  wrapperClassName?: string;
  noChildrenWrapper?: boolean;

  children: ReactElement[];
  limit: number;
  preText?: string;
};

export const RenderWithMore: FC<Props> = React.memo(props => {
  const {
    children = [],
    limit,
    className: pClassName = "",
    wrapperClassName = "",
    noChildrenWrapper = false,
    preText = ""
  } = props;

  const [show, setShow] = useState<boolean>(false);

  const showMore = children.length > props.limit;

  const restChildren = useMemo(
    () => (
      <>
        {show && children.slice(limit, children.length)}
        {!show && (
          <div
            className="inc-link"
            onClick={() => setShow(true)}
          >
            +{(children || []).slice(limit, children.length).length} More
          </div>
        )}
        {show && (
          <div
            className="inc-link"
            onClick={() => setShow(false)}
          >
            {" "}
            Hide
          </div>
        )}
      </>
    ),
    [children, limit, show]
  );

  const className = `inc-flex-row inc-flex-row-wrap ${pClassName}`;
  return (
    <div className={className}>
      {preText}
      {(children || []).slice(0, limit)}
      {showMore && (
        <>
          {!noChildrenWrapper && <div className={wrapperClassName}>{restChildren}</div>}
          {noChildrenWrapper && restChildren}
        </>
      )}
    </div>
  );
});
