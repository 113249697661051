import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { isEmpty } from "lodash";
import { darken } from "polished";
import { ApplicationState } from "../../../../store/configureStore";
import { logger } from "../../logging/Logger";
import { Role, useRefState, useTenantConfig, useTypedQueryParams } from "../..";
import {
  CompanyInfo,
  KPI,
  MemberSettings,
  useCaseApiService,
  useCaseCatalogApiService,
  UseCaseConfig,
  UseCaseConfigList,
  UseCaseDataQueryConfig,
  UseCaseOp10zeQueryParams,
  UseCaseSummaryWithSchema
} from "../../../services/api";
import useLocalStorage from "../useLocalStorage";
import { useAuth } from "../../../login/state/useAuth";
import appConfig from "../../../../appConfig";
import { useInceptionRoute } from "../useInceptionRouteHook";
import { OpCreationConfig } from "../../../services/api/operationalise";
import { FEATURE_FLAGS, featureFlagService } from "../../../services/feature-flags";
import {
  setCompanyNameAction,
  setSelectedSubVerticalAction,
  setVerticalConfigAction,
  setSelectedUseCaseAction,
  setSchemaFetchingAction,
  updateUseCaseSchemaAction,
  updateVerticalUseCaseMapWithSchemaAction,
  addUseCaseSchemaAction,
  deleteUseCaseSchemaAction,
  addToMyUseCasesAction,
  updateUseCaseUnderlyingInfo,
  deleteOp10zeFromUseCaseAction,
  updateOp10zeOfUseCaseAction,
  addWidgetToUseCaseAction,
  removeFromMyUseCasesAction
} from "./reducer";
import {
  VerticalConfig,
  VerticalConfigCompanyInfo,
  VerticalUseCaseSummary,
  VerticalUseCaseUnderlyingInfo
} from "./types";
import { getUseCaseColor, MY_USE_CASE_ID } from "./utils";

const useVerticalConfig = () => {
  const { authState } = useAuth();
  const { currentOrg, user } = authState || {};
  const userRole = user?.role;
  const orgId = currentOrg?.id;
  const orgName = currentOrg?.name;

  const { tenantConfigState, isTenantConfigFetched } = useTenantConfig();
  const { useNewNavigation, demoTenant } = tenantConfigState || {};

  const companyNameLSKey = useMemo(() => [COMPANY_NAME_LS_KEY, orgId].join("_"), [orgId]);

  const { updateQueryParams } = useInceptionRoute();
  const updateQueryParamsCallbackRef = useRefState(updateQueryParams);

  const dispatch = useDispatch();
  const verticalConfigState = useSelector((state: ApplicationState) => state.verticalConfig, shallowEqual);
  const { verticalConfig, isSchemaFetching, useCaseSchemaExists } = verticalConfigState || {};

  const {
    companyName,
    subVerticalId,
    verticalId,
    verticalIds,
    useCaseId,
    useCaseDTOs,
    memberUseCaseDTOs,
    companyInfos
  } = verticalConfig || {};

  const queryParams = useTypedQueryParams<VerticalContextParams>();
  const queryParamsRef = useRefState(queryParams);

  const { getItem, setItem } = useLocalStorage();

  const fetchUseCasesForVerticals = useCallback(
    async (useCasesFetchParams: Array<[string, string]>, subVerticalIdToMatch: string, useCaseIdToMatch: string) => {
      const useCaseDTOs: VerticalUseCaseSummary[] = [
        {
          color: darken(0.25, "#F4F4F4"),
          dataQueryConfigs: [],
          description: "",
          icon: "user",
          id: MY_USE_CASE_ID,
          insights: [],
          name: "My Copilots",
          objects: [],
          textualSummary: "",
          uiVerticalInfo: {
            companyName: "",
            subVerticalId: "",
            verticalId: ""
          }
        }
      ];

      let matchingCompanyName: string = undefined;
      let matchingVerticalId: string = undefined;

      if (!appConfig.anomShareId) {
        const showHiddenUseCases = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.showHiddenUseCases);
        const queryParams: UseCaseOp10zeQueryParams = { includeHidden: showHiddenUseCases };

        const promises = useCasesFetchParams.map(params => {
          const [companyName, verticalId] = params;
          return useCaseCatalogApiService.getUseCaseCatalogConfig(verticalId, "", companyName, queryParams);
        });
        const results = await Promise.allSettled(promises);

        let useCaseColorIdx = 0;
        results.forEach((result, idx) => {
          if (result.status === "fulfilled") {
            const { data, error, message } = result.value;

            if (error) {
              const [companyName, verticalId] = useCasesFetchParams[idx];
              logger.error("useVerticalConfig", "Error while fetching Copilot catalog", {
                verticalId,
                subVerticalId: "",
                companyName,
                error: message
              });
            } else {
              (data?.catalogs || []).forEach(catalog => {
                if (catalog) {
                  const {
                    useCases: catalogUseCases = [],
                    subVertical: subVerticalId,
                    vertical: verticalId,
                    companyName
                  } = catalog;

                  if (catalogUseCases.length) {
                    catalogUseCases.forEach(useCase => {
                      const subVerticalMatches = subVerticalIdToMatch ? subVerticalIdToMatch === subVerticalId : true;
                      const useCaseMatches = useCaseIdToMatch && useCaseIdToMatch === useCase.id;
                      if (subVerticalMatches && useCaseMatches) {
                        matchingCompanyName = companyName;
                        matchingVerticalId = verticalId;
                      }

                      if (useCase.name) {
                        useCaseDTOs.push({
                          ...useCase,
                          color: getUseCaseColor(useCaseColorIdx++),
                          dataQueryConfigs: [],
                          uiVerticalInfo: {
                            companyName,
                            subVerticalId,
                            verticalId
                          }
                        });
                      } else {
                        logger.info("useVerticalConfig", "Skipping Copilot without name", useCase);
                      }
                    });
                  }
                }
              });
            }
          } else {
            logger.error("UseVerticalConfig", "Error while fetching Copilot catalog", result.reason.message);
          }
        });
      }

      return {
        useCaseDTOs,
        matchingCompanyName,
        matchingVerticalId
      };
    },
    []
  );

  const fetchVerticalConfig = useCallback(async () => {
    if (isTenantConfigFetched) {
      const {
        subVerticalId: urlSubVerticalId = "",
        verticalId: urlVerticalId = "",
        companyName: urlCompanyName = "",
        useCaseId: urlUseCaseId = ""
      } = queryParamsRef.current || {};

      const lsCompanyName = getItem(companyNameLSKey);
      const defaultCompanyName = urlCompanyName || lsCompanyName;

      try {
        let companyInfos: CompanyInfo[] = [];

        let verticalConfig = getConfigWithDefaults();
        let appliedCompanyName = defaultCompanyName;
        let hasError = false;
        let errMessage = "";

        if (useNewNavigation) {
          if (defaultCompanyName) {
            const { data, error, message } = await useCaseCatalogApiService.getCompanyInfo(
              userRole,
              defaultCompanyName
            );

            if (error) {
              logger.error("useVerticalConfig", "Error while fetching company info", {
                companyName: defaultCompanyName,
                error: message
              });
              hasError = true;
              errMessage = message;

              companyInfos = [
                {
                  name: defaultCompanyName,
                  verticalToSubVerticals: {}
                }
              ];
            } else {
              companyInfos = [data];
            }
          } else {
            if (demoTenant && appliedCompanyName) {
              companyInfos = [
                {
                  name: appliedCompanyName,
                  verticalToSubVerticals: {}
                }
              ];
            } else {
              const { data, error, message } = await useCaseCatalogApiService.getCompanyInfos(userRole);

              if (error) {
                logger.error("useVerticalConfig", "Error while fetching company infos", message);

                hasError = true;
                errMessage = message;

                companyInfos = [
                  {
                    name: defaultCompanyName,
                    verticalToSubVerticals: {}
                  }
                ];
              } else {
                companyInfos = data.companyInfo || [];
                appliedCompanyName = appliedCompanyName || companyInfos[0]?.name;
              }
            }
          }
        } else {
          companyInfos = [
            {
              name: appliedCompanyName || orgName,
              verticalToSubVerticals: {}
            }
          ];
        }

        if (!hasError) {
          const vcCompanyInfos: VerticalConfigCompanyInfo[] = [];

          const sortedCompanyInfos = companyInfos.sort((a, b) => {
            const labelA = (a.name || "").toLowerCase();
            const labelB = (b.name || "").toLowerCase();

            return labelA.localeCompare(labelB);
          });

          let colorIdx = 1;

          const useCasesFetchParams: Array<[string, string]> = [];
          sortedCompanyInfos.forEach(companyInfo => {
            const { verticalToSubVerticals, ...restCompanyInfo } = companyInfo;

            const verticalMap: typeof verticalToSubVerticals = isEmpty(verticalToSubVerticals)
              ? {
                  "": {
                    subVerticals: []
                  }
                }
              : verticalToSubVerticals;

            useNewNavigation && useCasesFetchParams.push([companyInfo.name, ""]);

            const verticals = Object.keys(verticalMap);
            Object.keys(verticalMap).forEach(verticalId => {
              const subVerticals = (verticalToSubVerticals[verticalId]?.subVerticals || []).filter(Boolean);
              const sortedSubVerticals = subVerticals.sort((a, b) => {
                const labelA = (a || "").toLowerCase();
                const labelB = (b || "").toLowerCase();

                return labelA.localeCompare(labelB);
              });

              sortedSubVerticals.forEach(subVerticalId => {
                vcCompanyInfos.push({
                  ...restCompanyInfo,
                  subVerticalId,
                  verticalId,
                  color: getUseCaseColor(colorIdx++),
                  verticalIds: [verticalId]
                });
              });
            });

            if (verticals.length > 1) {
              vcCompanyInfos.unshift({
                ...restCompanyInfo,
                subVerticalId: "", // All subVerticals
                verticalId: "",
                verticalIds: verticals,
                color: appConfig.allUseCaseOrVerticalColor
              });
            } else {
              vcCompanyInfos.unshift({
                ...restCompanyInfo,
                subVerticalId: "", // All subVerticals
                verticalId: verticals[0],
                verticalIds: verticals,
                color: appConfig.allUseCaseOrVerticalColor
              });
            }
          });

          if (!sortedCompanyInfos.length) {
            useCasesFetchParams.push(["", ""]);
          }

          let companyName: string;
          let subVerticalId: string;
          let verticalId: string;

          if (defaultCompanyName !== null || defaultCompanyName !== undefined) {
            const selectedCompanyInfos = vcCompanyInfos.filter(
              vcCompanyInfo => vcCompanyInfo.name === defaultCompanyName
            );
            if (selectedCompanyInfos.length) {
              companyName = defaultCompanyName;

              const cInfosByVertical = selectedCompanyInfos.filter(vcCompanyInfo =>
                urlVerticalId ? vcCompanyInfo.verticalId === urlVerticalId : true
              );

              if (cInfosByVertical.length) {
                const cInfoBySubVertical = cInfosByVertical.find(
                  vcCompanyInfo => vcCompanyInfo.subVerticalId === urlSubVerticalId
                );

                if (cInfoBySubVertical) {
                  subVerticalId = urlSubVerticalId;
                  verticalId = cInfoBySubVertical.verticalId;
                } else {
                  subVerticalId = selectedCompanyInfos[0].subVerticalId;
                  verticalId = selectedCompanyInfos[0].verticalId;
                }
              } else {
                verticalId = selectedCompanyInfos[0].verticalId;
                subVerticalId = selectedCompanyInfos[0].subVerticalId;
              }
            }
          }

          if (!companyName && vcCompanyInfos.length) {
            if (urlSubVerticalId) {
              const entry = vcCompanyInfos.find(vci => vci.subVerticalId === urlSubVerticalId);
              if (entry) {
                companyName = entry.name;
                subVerticalId = entry.subVerticalId;
                verticalId = entry.verticalId;
              }
            }

            if (!companyName) {
              const firstEntry = vcCompanyInfos[0];
              companyName = firstEntry.name;
              subVerticalId = firstEntry.subVerticalId;
              verticalId = firstEntry.verticalId;
            }
          }

          const companyUseCaseFetchParams = useCasesFetchParams.filter(
            ([fetchCompanyName]) => fetchCompanyName === companyName
          );
          const { useCaseDTOs, matchingCompanyName, matchingVerticalId } = await fetchUseCasesForVerticals(
            companyUseCaseFetchParams,
            urlSubVerticalId,
            urlUseCaseId
          );

          companyName = matchingCompanyName !== undefined ? matchingCompanyName : companyName;
          verticalId = matchingVerticalId !== undefined ? matchingVerticalId : verticalId;

          subVerticalId =
            matchingCompanyName !== undefined && matchingVerticalId !== undefined
              ? urlSubVerticalId || ""
              : subVerticalId;
          const useCaseId =
            urlUseCaseId === MY_USE_CASE_ID
              ? urlUseCaseId
              : matchingCompanyName !== undefined && matchingVerticalId !== undefined
                ? urlUseCaseId || ""
                : "";

          companyName = companyName || "";
          subVerticalId = subVerticalId || "";
          verticalId = verticalId || "";
          appliedCompanyName = companyName;

          setItem(companyNameLSKey, companyName);

          verticalConfig = getConfigWithDefaults({
            subVerticalId,
            verticalId,
            verticalIds: vcCompanyInfos.find(vcCompanyInfo => vcCompanyInfo.name === companyName)?.verticalIds || [
              verticalId
            ],
            companyName,
            useCaseId,
            companyInfos: vcCompanyInfos,
            memberUseCaseDTOs: [],
            useCaseDTOs,
            useCaseToUnderlyingInfo: {}
          });
        } else {
          logger.error(
            "useVerticalConfig",
            "Error while fetching vertical config from vertical context service",
            errMessage
          );

          const verticalConfig: VerticalConfig =
            urlSubVerticalId && urlVerticalId && urlCompanyName
              ? {
                  subVerticalId: urlSubVerticalId,
                  verticalId: urlVerticalId,
                  verticalIds: [urlVerticalId],
                  companyName: urlCompanyName,
                  useCaseId: urlUseCaseId || "",
                  companyInfos: [
                    {
                      name: urlCompanyName,
                      subVerticalId: urlSubVerticalId,
                      verticalId: urlVerticalId,
                      verticalIds: [urlVerticalId],
                      color: getUseCaseColor(0)
                    }
                  ],
                  memberUseCaseDTOs: [],
                  useCaseDTOs: [],
                  useCaseToUnderlyingInfo: {}
                }
              : getConfigWithDefaults();

          appliedCompanyName = urlCompanyName;
          dispatch(
            setVerticalConfigAction({
              verticalConfig,
              isFetching: false,
              isSchemaFetching: false,
              useCaseSchemaExists: false
            })
          );
        }

        const isGuestOrAnonUser = userRole === Role.Guest || Boolean(appConfig.anomShareId);
        try {
          const memberUseCasesPromise =
            useNewNavigation && !isGuestOrAnonUser
              ? useCaseApiService.getUseCasesForMember(appliedCompanyName)
              : Promise.reject(new Error("Skipping Copilot fetch"));
          const { data, error, message } = await memberUseCasesPromise;

          if (!error) {
            const { usecaseConfigList: useCaseConfigList } = data as UseCaseConfigList;
            let colorIdx = verticalConfig.useCaseDTOs.length + 1;

            useCaseConfigList?.forEach(useCaseConfig => {
              if (useCaseConfig.name) {
                const existingEntry = verticalConfig.useCaseDTOs.find(uc => uc.id === useCaseConfig.id);

                verticalConfig.memberUseCaseDTOs.push({
                  ...useCaseConfig,
                  insights: existingEntry?.insights || [],
                  objects: existingEntry?.objects || [],
                  uiVerticalInfo: {
                    companyName: existingEntry?.uiVerticalInfo?.companyName || "",
                    subVerticalId: existingEntry?.uiVerticalInfo?.subVerticalId || "",
                    verticalId: existingEntry?.uiVerticalInfo?.verticalId || ""
                  },
                  color: existingEntry?.color || getUseCaseColor(colorIdx++)
                });
              } else {
                logger.info("useVerticalConfig", "Skipping member Copilot without name", useCaseConfig);
              }
            });
          } else {
            logger.error(
              "useVerticalConfig",
              "Error while fetching member Copilots from vertical context service",
              message
            );
          }
        } catch (e) {
          logger.warn("MemberUseCases", "Not setting member usecases. Reason: ", e);
        }

        dispatch(
          setVerticalConfigAction({
            verticalConfig,
            isFetching: false,
            isSchemaFetching: false,
            useCaseSchemaExists: false
          })
        );
      } catch (e) {
        logger.error(
          "useVerticalConfig",
          "JS Error while fetching vertical config from vertical context service",
          e as any
        );
        dispatch(
          setVerticalConfigAction({
            verticalConfig: getConfigWithDefaults(),
            isFetching: false,
            isSchemaFetching: false,
            useCaseSchemaExists: false
          })
        );
      }
    }
  }, [
    companyNameLSKey,
    demoTenant,
    dispatch,
    fetchUseCasesForVerticals,
    getItem,
    isTenantConfigFetched,
    orgName,
    queryParamsRef,
    setItem,
    useNewNavigation,
    userRole
  ]);

  const fetchUseCaseSchemasForVerticals = useCallback(async () => {
    if (!appConfig.anomShareId) {
      dispatch(setSchemaFetchingAction(true));

      const useCaseToSchemaMap: Record<string, UseCaseConfig> = {};
      const useCaseIds = new Set<string>();
      useCaseDTOs.forEach(useCase => useCase.id !== MY_USE_CASE_ID && useCaseIds.add(useCase.id));
      memberUseCaseDTOs.forEach(useCase => useCaseIds.add(useCase.id));

      const promises: Array<Promise<void>> = Array.from(useCaseIds).map(useCaseId => {
        const promise = useCaseApiService
          .getUseCaseSchema(useCaseId)
          .then(response => {
            const { data, error, message } = response;
            if (error) {
              logger.error(
                "useVerticalConfig",
                `Error while fetching Copilot schema for useCaseId: ${useCaseId}`,
                message
              );
            } else if (data.useCaseConfig) {
              useCaseToSchemaMap[useCaseId] = data.useCaseConfig;
            } else {
              logger.error(
                "useVerticalConfig",
                `Error while fetching Copilot schema for useCaseId: ${useCaseId}`,
                "useCaseConfig not found in response"
              );
            }
          })
          .catch(err => {
            logger.error("useVerticalConfig", `Error while fetching Copilot schema for useCaseId: ${useCaseId}`, err);
          });

        return promise;
      });

      await Promise.allSettled(promises);
      dispatch(updateVerticalUseCaseMapWithSchemaAction(useCaseToSchemaMap));
    }
  }, [dispatch, memberUseCaseDTOs, useCaseDTOs]);

  const getUseCasesForCurrentSubVertical = useCallback(() => {
    const nonEmptyVerticals = verticalIds.filter(Boolean);
    const useCasesForSubVertical = useCaseDTOs.filter(useCase => {
      const {
        companyName: ucCompanyName,
        subVerticalId: ucSubVerticalId,
        verticalId: ucVerticalId
      } = useCase.uiVerticalInfo;

      // Ignore verticalID mathc when all subVerticals
      const verticalMatches = !subVerticalId
        ? true
        : !verticalId
          ? ucVerticalId === verticalId
          : nonEmptyVerticals.length
            ? nonEmptyVerticals.includes(ucVerticalId)
            : true;
      const companyMatches = companyName ? ucCompanyName === companyName : true;
      const subVerticalMatches = subVerticalId ? ucSubVerticalId === subVerticalId : true;

      return verticalMatches && companyMatches && subVerticalMatches;
    });

    return useCasesForSubVertical;
  }, [verticalIds, useCaseDTOs, verticalId, companyName, subVerticalId]);

  const getMyUseCasesForCurrentSubVertical = useCallback(() => {
    const nonEmptyVerticals = verticalIds.filter(Boolean);
    const useCasesForSubVertical = memberUseCaseDTOs.filter(useCase => {
      const {
        companyName: ucCompanyName,
        subVerticalId: ucSubVerticalId,
        verticalId: ucVerticalId
      } = useCase.uiVerticalInfo;

      // Ignore verticalID mathc when all subVerticals
      const verticalMatches = !subVerticalId
        ? true
        : !verticalId
          ? ucVerticalId === verticalId
          : nonEmptyVerticals.length
            ? nonEmptyVerticals.includes(ucVerticalId)
            : true;
      const companyMatches = companyName ? ucCompanyName === companyName : true;
      const subVerticalMatches = subVerticalId ? ucSubVerticalId === subVerticalId : true;

      return verticalMatches && companyMatches && subVerticalMatches;
    });

    return useCasesForSubVertical;
  }, [verticalIds, memberUseCaseDTOs, subVerticalId, verticalId, companyName]);

  const getSelectedUseCases = useCallback(() => {
    if (useCaseId === MY_USE_CASE_ID) {
      return memberUseCaseDTOs;
    }
    const allUseCases = getUseCasesForCurrentSubVertical();
    return useCaseId ? allUseCases.filter(useCase => useCaseId === useCase.id) : allUseCases;
  }, [getUseCasesForCurrentSubVertical, memberUseCaseDTOs, useCaseId]);

  const getKpisForSelectedUseCases = useCallback(() => {
    const selectedUseCases = getSelectedUseCases();
    const kpis: KPI[] = [];

    if (useCaseId && useCaseId !== MY_USE_CASE_ID) {
      selectedUseCases.forEach(useCase => {
        kpis.push(...(useCase.kpis || []));
      });
    }

    return kpis;
  }, [getSelectedUseCases, useCaseId]);

  const getAllKpis = useCallback(() => {
    const selectedUseCases = getSelectedUseCases();
    const kpis: KPI[] = [];

    selectedUseCases.forEach(useCase => {
      kpis.push(...(useCase.kpis || []));
    });

    return kpis;
  }, [getSelectedUseCases]);

  const getSubVerticals = useCallback(() => {
    const subVerticals: string[] = [];
    companyInfos.forEach(companyInfo => {
      const { subVerticalId, name } = companyInfo;

      if (name === companyName && subVerticalId) {
        subVerticals.push(subVerticalId);
      }
    });
    return subVerticals;
  }, [companyInfos, companyName]);

  const addUseCaseSchema = useCallback(
    (verticalId: string, companyName: string, subVerticalId: string, useCase: UseCaseSummaryWithSchema) => {
      dispatch(
        addUseCaseSchemaAction({
          verticalId,
          companyName,
          subVerticalId,
          useCase
        })
      );
    },
    [dispatch]
  );

  const deleteUseCaseSchema = useCallback(
    (useCaseId: string) => {
      dispatch(deleteUseCaseSchemaAction(useCaseId));
    },
    [dispatch]
  );

  const updateUseCaseSchema = useCallback(
    (useCase: UseCaseSummaryWithSchema, incremental = true) => {
      dispatch(
        updateUseCaseSchemaAction({
          useCase,
          incremental
        })
      );
    },
    [dispatch]
  );

  const deleteOp10zeFromUseCase = useCallback(
    (useCaseId: string, dqcId: string, opConfigId: string) => {
      dispatch(
        deleteOp10zeFromUseCaseAction({
          useCaseId,
          dqcId,
          opConfigId
        })
      );
    },
    [dispatch]
  );

  const updateOp10zeOfUseCase = useCallback(
    (useCaseId: string, dqcId: string, opConfigId: string, opCreationConfig: OpCreationConfig) => {
      dispatch(
        updateOp10zeOfUseCaseAction({
          useCaseId,
          dqcId,
          opConfigId,
          opCreationConfig
        })
      );
    },
    [dispatch]
  );

  const addWidgetToUseCase = useCallback(
    (useCaseId: string, dataQueryConfig: UseCaseDataQueryConfig) => {
      dispatch(
        addWidgetToUseCaseAction({
          useCaseId,
          dataQueryConfig
        })
      );
    },
    [dispatch]
  );

  const addToMyUseCases = useCallback(
    (memberSettings: MemberSettings, useCaseId: string) => {
      dispatch(
        addToMyUseCasesAction({
          memberSettings,
          useCaseId
        })
      );
    },
    [dispatch]
  );

  const removeFromMyUseCases = useCallback(
    (memberSettings: MemberSettings, useCaseId: string) => {
      dispatch(
        removeFromMyUseCasesAction({
          memberSettings,
          useCaseId
        })
      );
    },
    [dispatch]
  );

  const updateUnderlyingInfo = useCallback(
    (useCaseToUnderlyingInfo: Record<string, VerticalUseCaseUnderlyingInfo>) => {
      dispatch(updateUseCaseUnderlyingInfo(useCaseToUnderlyingInfo));
    },
    [dispatch]
  );

  const setSubVertical = useCallback(
    (subVerticalId: string, verticalId: string) => {
      dispatch(
        setSelectedSubVerticalAction({
          subVerticalId,
          verticalId
        })
      );
    },
    [dispatch]
  );

  const setUseCase = useCallback(
    (useCaseId: string) => {
      dispatch(setSelectedUseCaseAction(useCaseId));
    },
    [dispatch]
  );

  const setCompanyNameInternal = useCallback(
    (companyName: string) => {
      localStorage.setItem(companyNameLSKey, companyName);
      dispatch(setCompanyNameAction(companyName));
    },
    [companyNameLSKey, dispatch]
  );

  const isVerticalConfigFetched = !verticalConfigState?.isFetching;
  const verticalsInitialised = useMemo(() => !isEmpty(companyInfos), [companyInfos]);

  useEffect(() => {
    if (verticalsInitialised) {
      const updateQueryParams = updateQueryParamsCallbackRef.current;
      updateQueryParams({
        useCaseId,
        subVerticalId
      });
    }
  }, [companyName, subVerticalId, updateQueryParamsCallbackRef, useCaseId, verticalId, verticalsInitialised]);

  return {
    fetchVerticalConfig,
    getSubVerticals,

    setSubVertical,
    setCompanyName: setCompanyNameInternal,
    setUseCase,

    useCaseApi: {
      getUseCasesForCurrentSubVertical,
      getMyUseCasesForCurrentSubVertical,
      getSelectedUseCases,
      removeFromMyUseCases,
      addToMyUseCases,
      getKpisForSelectedUseCases,
      getAllKpis
    },
    useCaseSchemaApi: {
      fetchUseCaseSchemasForVerticals,
      addUseCaseSchema,
      deleteUseCaseSchema,
      updateUseCaseSchema,
      updateUnderlyingInfo,
      addWidgetToUseCase,
      deleteOp10zeFromUseCase,
      updateOp10zeOfUseCase
    },

    verticalConfig: verticalConfigState?.verticalConfig,
    isVerticalConfigFetched,
    useCaseSchemasFetching: isSchemaFetching,
    useCaseSchemaExists: useCaseSchemaExists || false
  };
};

export { useVerticalConfig };

const getConfigWithDefaults = (config?: VerticalConfig): VerticalConfig => ({
  companyName: "",
  subVerticalId: "default",
  verticalId: "default",
  verticalIds: ["default"],
  useCaseId: "",
  companyInfos: [],
  useCaseDTOs: [],
  memberUseCaseDTOs: [],
  useCaseToUnderlyingInfo: {},
  ...(config || {})
});

type VerticalContextParams = {
  verticalId: string;
  subVerticalId: string;
  companyName: string;
  useCaseId: string;
};

const COMPANY_NAME_LS_KEY = `ONBOARDING_CCOMPANY_NAME`;
