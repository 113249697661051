import { CurrencyType } from "./types";

const SUPPORTED_CURRENCIES = Intl.supportedValuesOf("currency") as CurrencyType[];
const POPULAR_CURRENCIES = ["USD", "INR"] as CurrencyType[];

export const getAllSupportedCurrencies = (): CurrencyType[] => SUPPORTED_CURRENCIES;

export const getPopularCurrencies = (): CurrencyType[] => POPULAR_CURRENCIES;

export const isCurrencyTypeValid = (currency: CurrencyType): boolean => SUPPORTED_CURRENCIES.includes(currency);

export const getSymbolForCurrency = (currency: CurrencyType) => {
  if (isCurrencyTypeValid(currency)) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
      .format(1)
      .replace("1", "");
  }

  return "";
};
