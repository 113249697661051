import { debounce, isEmpty } from "lodash";
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIsVisible } from "../../hooks";
import { generateId } from "../../utils";
import { Card } from "../Layouts";
import IncLoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import IncSmartText from "../SmartText/SmartText";
import IncTextfield from "../Textfield/TextField";

export type IncListData = {
  keys: string[];
  lookupData?: Record<string, string>;
};

export interface IncListProps {
  searchText: string;
  initialData: IncListData;
  searchable: boolean;
  title: string;
  sortData: boolean;
  onSearch: (searchStr: string) => Promise<IncListData>;
  debounceTimeSecs: number;
  renderer: (key: string, lookupValue: string) => JSX.Element | string;
  sortFn: (aKey: string, bKey: string) => 0 | 1 | -1;
  size?: "sm" | "md" | "lg";
}

type Props = Partial<IncListProps>;

export const IncList: FC<Props> = React.memo(props => {
  const {
    searchText,
    searchable,
    title,
    sortData = false,
    initialData,
    onSearch,
    debounceTimeSecs = 1,
    renderer,
    sortFn,
    size = "sm"
  } = props;

  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [data, setData] = useState<IncListData>({
    keys: initialData?.keys || [],
    lookupData: initialData?.lookupData || {}
  });
  const [sliceIndex, setSliceIndex] = useState(20);

  const searchChanged = useRef(false);
  const currentLastRef = useRef<HTMLDivElement>(null);

  const isLastEntryVisible = useIsVisible(currentLastRef);

  const fetchLatestData = useMemo(
    () =>
      debounce(async (searchStr: string) => {
        if (onSearch) {
          setLoading(true);
          const nData = await onSearch(searchStr);
          setData(nData);
          setLoading(false);
        }
      }, debounceTimeSecs * 1000),
    [debounceTimeSecs, onSearch]
  );

  const { sortedItems, valueToKeyMap } = useMemo(() => {
    const { keys = [], lookupData = {} } = data;
    const valueToKeyMap: Record<string, string> = {};
    const values = keys.map(key => {
      const value = lookupData[key] || key;
      valueToKeyMap[value] = key;
      return value;
    });

    const fSortFn = sortFn || ((aKey: string, bKey: string) => aKey.localeCompare(bKey));
    const sortedItems = sortData ? values.sort(fSortFn) : values;
    return {
      sortedItems,
      valueToKeyMap
    };
  }, [data, sortData, sortFn]);

  useEffect(() => {
    if (isLastEntryVisible) {
      setSliceIndex(prev => prev + 10);
      (currentLastRef.current as any) = null;
    }
  }, [isLastEntryVisible]);

  const listItems = useMemo(() => {
    const renderItems = sortedItems.slice(0, sliceIndex);
    return renderItems.map((name, idx) => {
      const isLastEntry = idx === sliceIndex - 1;
      const ref = isLastEntry ? currentLastRef : null;
      return (
        <div
          className="marginBt16"
          key={generateId()}
          ref={ref}
        >
          <div
            className="inc-flex-row"
            style={{ whiteSpace: "nowrap" }}
          >
            {renderer ? renderer(valueToKeyMap[name], name) : <IncSmartText text={name} />}
          </div>
        </div>
      );
    });
  }, [renderer, sliceIndex, sortedItems, valueToKeyMap]);

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const nSearchStr = e.target.value;
    searchChanged.current = true;
    setSearchStr(nSearchStr);
  }, []);

  useEffect(() => {
    if (searchChanged.current || isEmpty(initialData)) {
      fetchLatestData(searchStr);
    }
  }, [fetchLatestData, initialData, searchStr]);

  const entityList = useMemo(() => {
    const className = `inc-list inc-list--${size}`;
    return (
      <div className={className}>
        {title && <div className="inc-list--title">{title}</div>}

        {searchable && (
          <IncTextfield
            className="marginBt10"
            onChange={onChange}
            placeholder={`Search ${searchText}`}
            value={searchStr}
          />
        )}

        <div className="list-wrapper">
          {loading && <IncLoadingSpinner />}
          {!loading && listItems}
        </div>
      </div>
    );
  }, [size, title, searchable, onChange, searchText, searchStr, loading, listItems]);

  return <Card>{entityList}</Card>;
});
