import React, { FC } from "react";
import { getFormattedDateTime, IncDateTimeFormat, IncHighchartsDateTimeFormat, DateTimeOptions } from "../../utils";

/**
 * Component to generate formatted date based on given format and locale (if any) or current locale.
 */
export const IncDateTime: FC<Props> = props => {
  const { format, value, options } = props;

  return <>{getFormattedDateTime(value, format, options)}</>;
};

interface Props {
  /**
   * @description Format for the date.
   */
  format: IncDateTimeFormat | IncHighchartsDateTimeFormat;

  /**
   * @description Date or timestamp in millis.
   */
  value: number | Date;

  /**
   * @description Additional options for formatting.
   */
  options?: DateTimeOptions;
}
