import React from "react";
import IncPopper from "../Popper/Popper";
import { IncPopperProps } from "../Popper/types";
import IncClickAway from "./ClickAway";

interface IncClickAwayPopperProps extends IncPopperProps {
  onClickAway: () => void;
}

export const IncClickAwayPopper: React.FC<IncClickAwayPopperProps> = props => {
  const { onClickAway, children, ...popperProps } = props;

  return (
    <IncClickAway onClickAway={onClickAway}>
      {refFn => (
        <IncPopper
          {...popperProps}
          ref={refFn}
        >
          {children}
        </IncPopper>
      )}
    </IncClickAway>
  );
};
