import { DataQueryRequest, DataQuery } from "../../api/types";

export abstract class WidgetMockDataHandler<Q extends DataQuery = DataQuery, R = any> {
  abstract supportsWidget(requestId: string): boolean;

  abstract getData(request: DataQueryRequest<Q>): R;
}

class WidgetMockDataHandlerRegistry<T extends WidgetMockDataHandler> {
  private handlerRegistry: T[];

  constructor() {
    this.handlerRegistry = [];
  }

  register(t: T) {
    this.handlerRegistry.push(t);
  }

  getHandler(requestId: string): T {
    for (let i = 0; i < this.handlerRegistry.length; i++) {
      const t: T = this.handlerRegistry[i];
      if (t.supportsWidget(requestId)) {
        return t;
      }
    }
  }
}

const widgetMockDataHandlerRegistry = new WidgetMockDataHandlerRegistry();

export default widgetMockDataHandlerRegistry;
