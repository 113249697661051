import React, { Fragment, CSSProperties } from "react";
import {
  getFormattedDateTime,
  IncDateTimeFormat,
  formatNumber,
  RenderWithMore,
  formatPercent,
  convertTimeToHumanString,
  IncPercentRenderer,
  IncFaIcon,
  CurrencyType,
  getCurrencyFormatter
} from "@inception/ui";
import { startCase } from "lodash";
import { DataType, generateId, FieldSubType } from "../core";
import { EntityListWithLimit } from "../components/entity-list/EntityListWithLimit";
import { ReactComponent as BooleanIcon } from "../../images/icons/data-types/boolean.svg";
import { ReactComponent as DateTimeIcon } from "../../images/icons/data-types/datetime.svg";
import { ReactComponent as NumberIcon } from "../../images/icons/data-types/number.svg";
import { ReactComponent as StringIcon } from "../../images/icons/data-types/string.svg";
import { ReactComponent as EmailIcon } from "../../images/icons/data-types/email.svg";
import { ReactComponent as IpAddressIcon } from "../../images/icons/data-types/ip_Address.svg";
import { ReactComponent as UrlIcon } from "../../images/icons/data-types/url.svg";
import { ReactComponent as SetIcon } from "../../images/icons/data-types/set.svg";
import { SimpleEntityNameRenderer } from "../components/entity-properties-popover";
import { DurationPrecision } from "../dashboard/widgets/Catalog/maximize-view/components/Customization/components";
import { entityEnricherRegistry } from "./EntityEnricher";

type Values = string[] | string | number | boolean | number[];
type FormattedValues = string | string[];
type RendererFunction = (value: Values) => JSX.Element;

export type FormatterFnOptions = {
  unit?: string;
  appendUnitAtFirst?: boolean;
  numberFormatOptions?: {
    currencyType?: CurrencyType;
    locale?: string;
    compact?: boolean;
  };
  durationFormatOptions?: {
    maxTerms: number;
    precision: DurationPrecision;
  };
  percentFormatOptions?: {
    precision: number;
    showValueIfZero?: boolean;
  };
};

export type FormatterFn = (value: Values, options?: FormatterFnOptions) => FormattedValues;

export interface DataTypeDescriptor {
  displayName: string;
  dataType: DataType;
  getIcon: (iconStyles: CSSProperties) => JSX.Element;
  getRenderer: RendererFunction;
  getFormattedValue: FormatterFn;
}

class DataTypeManager {
  getDataTypeDescriptor(dataType: DataType): DataTypeDescriptor {
    const displayName = dataType;
    const getIcon = (iconStyles: CSSProperties) => this.getIconByDataType(dataType, iconStyles);
    const getFormattedValue = (value: Values, options?: FormatterFnOptions) =>
      this.getFormattedValue(dataType, value, options);
    const getRenderer = this.getRendererByDataType(dataType);

    return {
      dataType,
      displayName,
      getIcon,
      getFormattedValue,
      getRenderer
    };
  }

  getDataTypeDescriptorByKind(kind: string | DataType, subType: FieldSubType): DataTypeDescriptor {
    const [displayName, dataType] = this.getDataTypeWithNameFromKind(kind, subType);
    const descriptor = this.getDataTypeDescriptor(dataType);
    descriptor.displayName = displayName;
    return descriptor;
  }

  private getFormattedValue = (dataType: DataType, value: Values, options?: FormatterFnOptions): FormattedValues => {
    const {
      appendUnitAtFirst = false,
      unit = "",
      numberFormatOptions,
      durationFormatOptions,
      percentFormatOptions
    } = options || {};

    const { compact = false, currencyType = "USD", locale } = numberFormatOptions || {};

    switch (dataType) {
      case "LIST_LONG":
      case "LIST_DOUBLE": {
        const values = (value as number[]).map(v => (isNaN(v) ? "-" : formatNumber(v)));
        return formatStrings(values, unit, appendUnitAtFirst);
      }

      case "LIST_STRING": {
        const values = value as string[];
        return formatStrings(values, unit, appendUnitAtFirst);
      }

      case "LIST_ENTITY": {
        const values = formatEntities(value as string[]);
        return formatStrings(values, unit, appendUnitAtFirst);
      }

      case "ENTITY": {
        const valStr = formatEntities([value] as string[])[0];
        return appendUnit(valStr, unit, appendUnitAtFirst);
      }

      case "DATE":
      case "DATETIME":
      case "DATE_STRING": {
        const strValue = value?.toString();
        return appendUnit(strValue, unit, appendUnitAtFirst);
      }

      case "EPOCH":
      case "EPOCH_MILLIS":
      case "EPOCH_SECS": {
        const val = dataType === "EPOCH_SECS" ? (value as number) * 1000 : value;
        const date = new Date(val as string);
        const fDateStr = getFormattedDateTime(date, IncDateTimeFormat.minimal);
        return appendUnit(fDateStr, unit, appendUnitAtFirst);
      }

      case "LONG":
      case "DOUBLE":
      case "NUMBER": {
        if (isNaN(value as number)) {
          return "-";
        }

        const fValue = formatNumber(value as number);
        return appendUnit(fValue, unit, appendUnitAtFirst);
      }

      case "CURRENCY": {
        if (isNaN(value as number)) {
          return "-";
        }

        const formatter = getCurrencyFormatter(currencyType, compact, locale);
        const fValue = formatter.format(value as number);
        return appendUnit(fValue, unit, appendUnitAtFirst);
      }

      case "DURATION":
      case "DURATION_MILLIS":
      case "DURATION_SECS":
      case "DURATION_MINS":
      case "DURATION_HOURS":
      case "DURATION_DAYS": {
        if (isNaN(value as number)) {
          return "-";
        }

        const { maxTerms, precision: defPrecision } = durationFormatOptions || {};

        let numVal = value as number;
        let precision = defPrecision;
        switch (dataType) {
          case "DURATION_MILLIS":
            numVal *= 1000;
            precision = defPrecision || "MILLISECONDS";
            break;
          case "DURATION_SECS":
            numVal *= 1000000;
            precision = defPrecision || "SECONDS";
            break;
          case "DURATION_MINS":
            numVal *= 60 * 1000000;
            precision = defPrecision || "MINUTES";
            break;
          case "DURATION_HOURS":
            numVal *= 60 * 60 * 1000000;
            precision = defPrecision || "HOURS";
            break;
          case "DURATION_DAYS":
            numVal *= 24 * 60 * 60 * 1000000;
            precision = defPrecision || "DAYS";
            break;
          default:
            break;
        }

        return convertTimeToHumanString(numVal, 0, maxTerms, precision);
      }

      case "PERCENT_1":
      case "PERCENT_100": {
        let numValue = value as number;
        if (isNaN(numValue) || numValue === undefined || numValue === null) {
          return "-";
        } else if (numValue === Infinity) {
          return "∞%";
        }

        const { precision = 2, showValueIfZero = false } = percentFormatOptions || {};
        if (showValueIfZero && numValue === 0) {
          return "0%";
        }

        numValue = dataType === "PERCENT_1" ? numValue * 100 : numValue;
        const fValue = formatPercent(numValue, 100, precision);
        return appendUnit(fValue, unit, appendUnitAtFirst);
      }

      default: {
        const strValue = typeof value === "number" ? formatNumber(value).toString() : value?.toString();
        return appendUnit(strValue, unit, appendUnitAtFirst);
      }
    }
  };

  private getRendererByDataType = (dataType: DataType): RendererFunction => {
    switch (dataType) {
      case "LIST_LONG":
      case "LIST_DOUBLE": {
        return (values: Values) => {
          const strValues = (values as number[]).map(v => <Fragment key={generateId()}>{formatNumber(v)}</Fragment>);
          return <RenderWithMore limit={4}>{strValues}</RenderWithMore>;
        };
      }
      case "LIST_STRING": {
        return (values: Values) => {
          const strValues = (values as string[]).map(v => <Fragment key={generateId()}>{v}</Fragment>);
          return <RenderWithMore limit={4}>{strValues}</RenderWithMore>;
        };
      }
      case "LIST_ENTITY": {
        return (values: Values) => {
          const entityIds = values as string[];
          const entityType = entityIds[0]?.split(":")[0];
          return (
            <EntityListWithLimit
              entityIds={entityIds}
              entityTypeId={entityType}
            />
          );
        };
      }
      case "ENTITY": {
        return (values: Values) => {
          const entityId = values as string;
          return <SimpleEntityNameRenderer id={entityId} />;
        };
      }
      case "PERCENT_1":
      case "PERCENT_100": {
        return (values: Values) => {
          const value = values as number;
          return <IncPercentRenderer value={value} />;
        };
      }
      default: {
        return (values: Values) => {
          const fValue = this.getFormattedValue(dataType, values);
          return <Fragment>{fValue}</Fragment>;
        };
      }
    }
  };

  private getIconByDataType(dataType: DataType, iconStyles: CSSProperties = {}): JSX.Element {
    switch (dataType) {
      case "STRING":
      case "ENTITY":
      case "DATE_STRING":
        return <StringIcon style={iconStyles} />;
      case "LONG":
      case "DOUBLE":
      case "NUMBER":
      case "DURATION":
      case "PERCENT_1":
      case "PERCENT_100":
      case "EPOCH":
      case "DURATION_MILLIS":
      case "DURATION_SECS":
      case "DURATION_MINS":
      case "DURATION_HOURS":
      case "DURATION_DAYS":
      case "EPOCH_MILLIS":
      case "EPOCH_SECS":
        return <NumberIcon style={iconStyles} />;
      case "BOOLEAN":
        return <BooleanIcon style={iconStyles} />;
      case "DATE":
      case "DATETIME":
        return <DateTimeIcon style={iconStyles} />;
      case "EMAIL":
        return <EmailIcon style={iconStyles} />;
      case "LIST_STRING":
      case "LIST_LONG":
      case "LIST_ENTITY":
      case "LIST_DOUBLE":
        return (
          <IncFaIcon
            iconName="bars"
            style={iconStyles}
          />
        );
      case "CURRENCY":
        return (
          <IncFaIcon
            iconName="dollar-sign"
            style={iconStyles}
          />
        );
      case "ZIP_CODE":
      case "GEOLOCATION":
      case "IP_ADDRESS":
        return <IpAddressIcon style={iconStyles} />;
      case "URL":
        return <UrlIcon style={iconStyles} />;
      case "ADDRESS":
        return (
          <IncFaIcon
            iconName="location-arrow"
            style={iconStyles}
          />
        );
      case "MAP":
      case "OBJECT_MAP":
        return (
          <IncFaIcon
            iconName="arrows-left-right"
            style={iconStyles}
          />
        );
      case "SET":
        return <SetIcon style={iconStyles} />;
      case "TELEPHONE":
        return (
          <IncFaIcon
            iconName="phone"
            style={iconStyles}
          />
        );
      default:
        return (
          <IncFaIcon
            iconName="circle"
            style={iconStyles}
          />
        );
    }
  }

  getDataTypeWithNameFromKind(kindType: string, subType?: FieldSubType): [string, DataType] {
    switch (kindType) {
      case "_str":
      case "STRING":
      case "LIST_STRING":
      case "ENTITY":
      case "LIST_ENTITY": {
        const dataType = kindType === "_str" ? "STRING" : kindType;
        const displayName = startCase(kindType);

        switch (subType) {
          case "email":
            return ["Email", "EMAIL"];

          case "url":
            return ["URL", "URL"];

          case "ip_address":
            return ["ip_address", "IP_ADDRESS"];

          case "geolocation":
            return ["geolocation", "GEOLOCATION"];

          case "zip_code":
            return ["zip_code", "ZIP_CODE"];

          case "tel":
            return ["tel", "TELEPHONE"];

          case "address":
            return ["address", "ADDRESS"];

          case "date":
            return ["date", "DATE"];

          case "datetime":
            return ["datetime", "DATETIME"];

          case "entity":
            return ["Entity", "ENTITY"];

          default:
            return [displayName, dataType];
        }
      }

      case "_long":
      case "LONG":
      case "LIST_LONG": {
        return this.handleNumberDataType(subType, "LONG", "Long");
      }

      case "_double":
      case "DOUBLE":
      case "LIST_DOUBLE": {
        return this.handleNumberDataType(subType, "LONG", "Double");
      }

      case "_set":
      case "SET":
        return ["Set", "SET"];

      case "_map":
      case "MAP":
        return ["Map", "MAP"];

      case "_objectmap":
      case "OBJECT_MAP":
        return ["ObjectMap", "OBJECT_MAP"];

      case "_bool":
      case "BOOLEAN":
        return ["Boolean", "BOOLEAN"];

      case "_date":
      case "DATE":
        return ["Date", "DATE"];

      case "_datetime":
      case "DATETIME":
        return ["DateTime", "DATETIME"];

      case "_list_str":
        return ["_list_str", "LIST_STRING"];

      case "_list_long":
        return ["_list_long", "LIST_LONG"];

      case "_list_entity":
        return ["_list_entity", "LIST_ENTITY"];

      case "_list_double":
        return ["_list_double", "LIST_DOUBLE"];

      default:
        return ["Unknown", "STRING"];
    }
  }

  private handleNumberDataType(subType: FieldSubType, dataType: DataType, dataTypeName: string): [string, DataType] {
    if (!subType) {
      return [dataTypeName, dataType];
    }

    switch (subType) {
      case "currency":
        return ["Currency", "CURRENCY"];

      case "geolocation":
        return ["geolocation", "GEOLOCATION"];

      case "duration":
        return ["duration", "DURATION"];

      case "epoch":
        return ["epoch", "EPOCH"];

      case "epoch_millis":
        return ["epoch_millis", "EPOCH_MILLIS"];

      case "epoch_secs":
        return ["epoch_secs", "EPOCH_SECS"];

      case "duration_millis":
        return ["duration_millis", "DURATION_MILLIS"];

      case "duration_secs":
        return ["duration_secs", "DURATION_SECS"];

      case "duration_mins":
        return ["duration_mins", "DURATION_MINS"];

      case "duration_hours":
        return ["duration_hours", "DURATION_HOURS"];

      case "duration_days":
        return ["duration_days", "DURATION_DAYS"];

      case "percent_1":
        return ["percent_1", "PERCENT_1"];

      case "percent_100":
        return ["percent_100", "PERCENT_100"];

      default: {
        if ((subType || "").toLowerCase().startsWith("percent_1")) {
          return [subType, "PERCENT_1"];
        } else if ((subType || "").toLowerCase().startsWith("percent_100")) {
          return [subType, "PERCENT_100"];
        } else if ((subType || "").toLowerCase().startsWith("duration")) {
          return [subType, "DURATION"];
        } else if ((subType || "").toLowerCase().startsWith("epoch")) {
          return [subType, "EPOCH"];
        } else if ((subType || "").toLowerCase().startsWith("duration_millis")) {
          return [subType, "DURATION_MILLIS"];
        } else if ((subType || "").toLowerCase().startsWith("duration_secs")) {
          return [subType, "DURATION_SECS"];
        } else if ((subType || "").toLowerCase().startsWith("duration_mins")) {
          return [subType, "DURATION_MINS"];
        } else if ((subType || "").toLowerCase().startsWith("duration_hours")) {
          return [subType, "DURATION_HOURS"];
        } else if ((subType || "").toLowerCase().startsWith("duration_days")) {
          return [subType, "DURATION_DAYS"];
        } else if ((subType || "").toLowerCase().startsWith("epoch_millis")) {
          return [subType, "EPOCH_MILLIS"];
        } else if ((subType || "").toLowerCase().startsWith("epoch_secs")) {
          return [subType, "EPOCH_SECS"];
        } else if ((subType || "").toLowerCase().startsWith("currency")) {
          return [subType, "CURRENCY"];
        } else if ((subType || "").toLowerCase().startsWith("geolocation")) {
          return [subType, "GEOLOCATION"];
        } else {
          return [subType, "NUMBER"];
        }
      }
    }
  }
}

export const dataTypeManager = new DataTypeManager();

const formatStrings = (strings: string[], unit: string, appendUnitAtFirst: boolean) =>
  strings.map(v => appendUnit(v, unit, appendUnitAtFirst)).join(", ");

const appendUnit = (value: string, unit: string, appendUnitAtFirst: boolean) =>
  unit ? (appendUnitAtFirst ? `${unit} ${value}` : `${value} ${unit}`) : value;

const formatEntities = (entities: Array<string | number>) =>
  entities.map(v => {
    if (typeof v === "number") {
      return formatNumber(v);
    } else {
      return entityEnricherRegistry.lookupEntityCache(v) || v;
    }
  });
