import { range } from "lodash";
import React, { CSSProperties, useMemo, useEffect } from "react";
import { UsePaginationInstanceProps } from "react-table";
import { cx } from "emotion";
import { TableDataItem } from "../types";
import { IncFaIcon } from "../../../icons";
import IncButton from "../../Button/Button";
import { IncSelect } from "../../Select";

interface Props<T extends TableDataItem> extends Omit<UsePaginationInstanceProps<T>, "page" | "pageOptions"> {
  pageIndex: number;
  pageSize: number;

  minimal: boolean;
  showDisplayStats: boolean;

  rowStartIdx: number;
  rowCount: number;
  visibleRowCount: number;
}

const PaginationController = <T extends Record<string, any>>(props: Props<T>) => {
  const {
    canNextPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    pageCount,
    pageIndex,
    pageSize,
    previousPage,
    setPageSize,
    minimal,
    rowCount,
    visibleRowCount,
    showDisplayStats,
    rowStartIdx
  } = props;

  const maxPageNums = 6;

  // Calculate start page as cur page minus half of max pages and adjust it to be a positive number
  let start = pageIndex - maxPageNums / 2;
  start = Math.max(0, start);

  // Calculate end page as cur page plus remaining pages and adjust it to be in par with pageCount
  const remPages = maxPageNums - (pageIndex - start);
  let end = pageIndex + remPages;
  end = Math.min(end, pageCount);

  // If the required number of pages are not achieved, check for the start and end diff and if start is not 0, set it to accomodate max pages
  start = end - start === maxPageNums ? start : start === 0 ? start : Math.max(end - maxPageNums, 0);

  const pageOpts = range(start, end);

  const activeStyle: CSSProperties = {
    textDecoration: "underline",
    fontWeight: "bolder"
  };

  const resultOptions = useMemo(
    () =>
      [10, 20, 30, 40, 50].map(res => ({
        label: res.toString(),
        value: res.toString()
      })),
    []
  );

  const activeResOption = useMemo(
    () => ({
      label: pageSize.toString(),
      value: pageSize.toString()
    }),
    [pageSize]
  );

  useEffect(() => {
    if (activeResOption && resultOptions) {
      const pageSizeOpt = resultOptions?.find(opt => opt.value === activeResOption.value);
      if (!pageSizeOpt) {
        resultOptions.push(activeResOption);
      }
    }
  }, [activeResOption, resultOptions]);

  const statsClassName = cx("inc-text-subtext-medium", {
    marginRt12: !minimal,
    marginRtAuto: minimal
  });

  const resStart = rowStartIdx + 1;
  const resEnd = resStart + (visibleRowCount - 1);

  return (
    <div className="inc-table-pagination">
      {showDisplayStats && (
        <div className={statsClassName}>
          Showing {resStart} - {resEnd} of {rowCount} results
        </div>
      )}

      {/* Skip Go to first in minimal mode */}
      {!minimal && (
        <IncButton
          color={"secondary"}
          disabled={!canPreviousPage}
          iconType="icon"
          onClick={() => gotoPage(0)}
          size="small"
        >
          <IncFaIcon iconName="angle-double-left" />
        </IncButton>
      )}

      <IncButton
        color={"secondary"}
        disabled={!canPreviousPage}
        iconType="icon"
        onClick={() => previousPage()}
        size="small"
      >
        <IncFaIcon
          iconName="angle-left"
          title="Previous page"
        />
      </IncButton>

      {/* Skip page options in minimal mode */}
      {!minimal && (
        <>
          {pageOpts.map(po => (
            <IncButton
              color="link"
              key={po}
              onClick={() => gotoPage(po)}
              style={po === pageIndex ? activeStyle : {}}
            >
              {po + 1}
            </IncButton>
          ))}
        </>
      )}

      <IncButton
        color={"secondary"}
        disabled={!canNextPage}
        iconType="icon"
        onClick={() => nextPage()}
        size="small"
      >
        <IncFaIcon
          iconName="angle-right"
          title="Next page"
        />
      </IncButton>

      {/* Skip Go to last in minimal mode */}
      {!minimal && (
        <IncButton
          color={"secondary"}
          disabled={!canNextPage}
          iconType="icon"
          onClick={() => gotoPage(pageCount - 1)}
          size="small"
        >
          <IncFaIcon iconName="angle-double-right" />
        </IncButton>
      )}

      {/* Skip page size options in minimal mode */}
      {!minimal && (
        <IncSelect
          autoSort={false}
          isMulti={false}
          label="Results per page"
          menuPlacement="auto"
          menuPortalTarget={document.body}
          onChange={opt => setPageSize(Number(opt?.value || "10"))}
          options={resultOptions}
          value={activeResOption}
        />
      )}
    </div>
  );
};

export default PaginationController;
