import React from "react";
import { IncDateTime, IncDateTimeFormat } from "@inception/ui";
import { TimeObj, TimeObjUnit } from "../services/api/explore";
import { dateTime } from "../core";

function DateTimeRenderer(props: { timeInMicrosec: number }) {
  const millis = Math.floor(props.timeInMicrosec / 1000);

  return (
    <IncDateTime
      format={IncDateTimeFormat.minimal}
      options={{
        withMilliSeconds: true,
        withSeconds: true
      }}
      value={millis}
    />
  );
}

export function getTimeObjFromMillis(value: number, unit: TimeObjUnit): TimeObj {
  switch (unit) {
    case TimeObjUnit.millis:
      return {
        unit,
        value
      };
    case TimeObjUnit.seconds:
      return {
        value: parseInt(String(value / 1000), 10),
        unit
      };
    case TimeObjUnit.minutes:
      return {
        value: parseInt(String(value / (60 * 1000)), 10),
        unit
      };
    case TimeObjUnit.hours:
      return {
        value: parseInt(String(value / (60 * 60 * 1000)), 10),
        unit
      };
    case TimeObjUnit.days:
      return {
        value: parseInt(String(value / (24 * 60 * 60 * 1000)), 10),
        unit
      };
    case TimeObjUnit.weeks:
      return {
        value: parseInt(String(value / (7 * 24 * 60 * 60 * 1000)), 10),
        unit
      };
    case TimeObjUnit.months:
      return {
        value: parseInt(String(value / (30 * 24 * 60 * 60 * 1000)), 10),
        unit
      };
    case TimeObjUnit.years:
      return {
        value: parseInt(String(value / (365 * 24 * 60 * 60 * 1000)), 10),
        unit
      };
    default:
      return {
        value,
        unit
      };
  }
}
export function getMillisFromTimeObj(timeObj: TimeObj): number {
  const { value, unit } = timeObj;
  switch (unit) {
    case TimeObjUnit.millis:
      return value;
    case TimeObjUnit.seconds:
      return value * 1000;
    case TimeObjUnit.minutes:
      return value * 60 * 1000;
    case TimeObjUnit.hours:
      return value * 60 * 60 * 1000;
    case TimeObjUnit.days:
      return value * 24 * 60 * 60 * 1000;
    case TimeObjUnit.weeks:
      return value * 7 * 24 * 60 * 60 * 1000;
    case TimeObjUnit.months:
      return value * 30 * 24 * 60 * 60 * 1000;
    case TimeObjUnit.years:
      return value * 365 * 24 * 60 * 60 * 1000;
    default:
      return value;
  }
}

export const getBufferTimeMinsForIncidentSeries = (
  startTimeMillis: number,
  endTimeMillis: number,
  frequencyMillis?: number,
  ignoreEndBuffer?: boolean
) => {
  const h12Buffer = 12 * 60 * 60 * 1000; // 12 hours
  const maxEndBuffer = 6 * 60 * 60 * 1000; // 6 hours

  const now = dateTime().seconds(0).milliseconds(0).valueOf();

  if (frequencyMillis && frequencyMillis >= 21600000) {
    const bufferRange = frequencyMillis * 6;
    const startTimeMillisWithBuffer = startTimeMillis - (ignoreEndBuffer ? bufferRange * 2 : bufferRange);
    const endTimeMillisWithBuffer = endTimeMillis + bufferRange;

    return {
      startTimeMillisWithBuffer,
      endTimeMillisWithBuffer: ignoreEndBuffer ? endTimeMillis : endTimeMillisWithBuffer
    };
  }

  const endBuffer = Math.min(now - endTimeMillis, maxEndBuffer);
  const startBuffer = h12Buffer - endBuffer;

  const from = dateTime(startTimeMillis).subtract(startBuffer, "milliseconds");
  const to = dateTime(endTimeMillis).add(endBuffer, "milliseconds");

  return {
    startTimeMillisWithBuffer: from.valueOf(),
    endTimeMillisWithBuffer: ignoreEndBuffer ? endTimeMillis : to.valueOf()
  };
};

export default DateTimeRenderer;
