import { useMemo, useCallback } from "react";
import { configApiService } from "../../services/api/configuration/ConfigApiService";
import { logger } from "../logging/Logger";
import appConfig from "../../../appConfig";
import { InitialState, FetchFn, useDataFetch } from "./useDataFetch";

export type DemoMonkeyConfig = {
  id: string;
  config: string;
  triageLink: string;
};

export const useFetchDemoMonkeyConfigs = () => {
  const initialState = useMemo<InitialState<Record<string, DemoMonkeyConfig>, string>>(
    () => ({
      data: {},
      error: "",
      isFetching: true,
      isError: false,
      isSuccess: false
    }),
    []
  );

  const fetchFn = useCallback<FetchFn<Record<string, DemoMonkeyConfig>, string>>(async () => {
    const { demoMonkeyConfigs, error } = await fetchDemoMonkeyConfigs();

    return {
      data: demoMonkeyConfigs,
      error,
      isFetching: false,
      isError: Boolean(error),
      isSuccess: !error
    };
  }, []);

  return useDataFetch(fetchFn, initialState);
};

export const fetchDemoMonkeyConfigs = async () => {
  const { demoMonkeyConfigType } = appConfig;

  const demoMonkeyConfigs: Record<string, DemoMonkeyConfig> = {};
  let errMsg = "";

  try {
    const { data, error, message } = await configApiService.getConfigList(demoMonkeyConfigType);

    if (!error && data) {
      data.forEach(config => {
        const { name, payload, id } = config;
        const { demoMonkeyConfig, triageLink } = payload;
        demoMonkeyConfigs[name] = {
          id,
          config: demoMonkeyConfig.stringVal,
          triageLink: triageLink.stringVal
        };
      });
    } else {
      errMsg = message || "No demo monkey configs found";
    }
  } catch (err) {
    errMsg = err.message;
    logger.error("Demo monkey customisation", "Error fetching demo monkey config", err);
  }

  return {
    demoMonkeyConfigs,
    error: errMsg
  };
};
