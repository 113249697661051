import { DataQuery, InceptionRequestConfig } from "../../../api/types";

export enum FieldType {
  time = "time",
  number = "number",
  string = "string",
  boolean = "boolean",
  other = "other"
}

export interface PromQuery extends DataQuery {
  expr: string;
  format?: string;
  instant?: boolean;
  hinting?: boolean;
  interval?: string;
  intervalFactor?: number;
  legendFormat?: string;
  valueWithRefId?: boolean;
  requestId?: string;
  showingGraph?: boolean;
  showingTable?: boolean;
}

export interface PromQueryRequest extends PromQuery {
  step?: number;
  requestId?: string;
  start: number;
  end: number;
  headers?: any;
}

export interface PromMetricsMetadataItem {
  type: string;
  help: string;
  unit?: string;
}

export interface PromMetricsMetadata {
  [metric: string]: PromMetricsMetadataItem[];
}

export interface RequestOptions extends InceptionRequestConfig {
  headers?: Record<string, string>;
  silent?: boolean;
  requestId?: string;
}

/**
 * TODO: DataFrame and DataFrameDTO are dependent on various other types, which we aren't using currently
 * @grafana/data types:
 * export type LegacyResponseData = TimeSeries | TableData | any;
 * export type DataQueryResponseData = DataFrame | DataFrameDTO | LegacyResponseData;
 */

export type DataQueryResponseData = PromSeriesResult | PromInstantResult;

export type AggrOverTime = "avg_over_time" | "min_over_time" | "max_over_time" | "sum_over_time";

export type MetricInfo = Record<string, string>;

export interface PromSeriesResult {
  metric: MetricInfo;
  values: Array<[number, string]>;
}

export interface PromInstantResult {
  metric: MetricInfo;
  value: [number, string];
}

export interface PromData {
  resultType: string;
  result: DataQueryResponseData[];
}

export interface PromDataResponse {
  status: string;
  data: PromData;
  error?: string;
  errorType?: string;
}

export interface PromDataQueryResponse {
  data: PromDataResponse;
  cancelled?: boolean;
}

export interface PromLabelQueryResponse {
  data: {
    status: string;
    data: string[];
  };
  cancelled?: boolean;
}
