import Axios, { CancelTokenSource } from "axios";
import { useCallback, useMemo, useRef } from "react";
import { FetchCallBackResult, FetchFn, InitialState, useDataFetch } from "../../../../core";
import { ResponseStatus } from "../../explore";
import { UseCaseFeedResponse } from "../types";
import { useCaseApiService } from "../UseCaseApiService";

type Result = {
  feedResponseList: UseCaseFeedResponse[];
  significantFeed: UseCaseFeedResponse;
};

export const useFetchFeedForUseCase = (useCaseId: string, lazy = false) => {
  const initialState = useMemo<InitialState<Result, string>>(
    () => ({
      data: null,
      error: "",
      isError: false,
      isFetching: !lazy,
      isSuccess: false
    }),
    [lazy]
  );

  const cancelTokenRef = useRef<CancelTokenSource>();

  const fetchFn = useCallback<FetchFn<Result, string>>(async () => {
    cancelTokenRef.current?.cancel();
    cancelTokenRef.current = Axios.CancelToken.source();

    const result: FetchCallBackResult<Result, string> = {
      data: null,
      error: "",
      isError: false,
      isSuccess: false
    };

    try {
      const errors: string[] = [];

      const { data, error, message, cancelled } = await useCaseApiService.getFeedListForUseCase(
        useCaseId,
        cancelTokenRef.current.token
      );

      if (!cancelled) {
        if (error) {
          result.error = message;
          result.isError = true;
        } else {
          const { responseInfo, feedResponseList, significantFeed } = data || {};

          if (responseInfo?.status === ResponseStatus.FAILED || !feedResponseList) {
            errors.push(responseInfo.errors?.join(", ") || `Error fetching feed for Copilot ${useCaseId}`);
          } else {
            result.data = {
              feedResponseList,
              significantFeed
            };
          }

          result.isError = errors.length > 0;
          result.isSuccess = errors.length === 0;
          result.error = errors.join(", ");
        }
      } else {
        result.isCancelled = true;
      }
    } catch (err) {
      result.isError = true;
      result.error = err.message || String(err);
    }

    return result;
  }, [useCaseId]);

  return useDataFetch(fetchFn, initialState, lazy);
};
