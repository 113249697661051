import { Common } from "./common";
import { ScheduleEditor } from "./schedule";
import { ThresholdEditor } from "./threshold";
import { TriggersEditor } from "./triggers";

export const Operationalize = {
  ScheduleEditor,
  TriggersEditor,
  ThresholdEditor,
  Common
};
