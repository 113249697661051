import { darken, lighten, transparentize } from "polished";
import { inceptionLightColorPalette } from "@inception/ui-styles";
import { InceptionTheme } from "./types/theme";
import Plugins from "./types/plugins";
import Inputs from "./types/inputs";
import lightColors, { lightChartColors } from "./colors.light";

const { gray, black } = lightColors;
const pageBg = lightColors.white;
const pageText = gray.dark2;

const plugins: Plugins = {
  table: {
    background: lightColors.white,
    text: pageText,
    titleBackground: gray.light3,
    titleText: pageText,
    border: `1px solid ${gray.light3}`,
    hoverBackground: gray.light3
  },
  graph: {
    background: lightColors.white,
    text: pageText,
    legendText: lighten(0.2, pageText),
    legendTextHover: pageText,
    legendDisabled: transparentize(0.7, pageText),
    titleText: pageText,
    titleBackground: gray.light3
  },
  donut: {
    background: lightColors.white,
    text: pageText,
    legendText: pageText,
    titleText: pageText,
    titleBackground: gray.light3
  }
};

const inputs: Inputs = {
  button: {
    background: "transparent",
    defaultBackground: gray.light5,
    primaryBackground: lightColors.green.shade,
    secondaryBackground: lightColors.blue.shade,
    errorBackground: lightColors.brand.danger,
    infoBackground: lightColors.blue.base,
    successBackground: lightColors.green.base,
    warningBackground: lightColors.brand.warning,
    text: pageText,
    defaultText: gray.dark2,
    defaultHover: darken(0.05, gray.light5),
    navBackground: gray.light8,
    navHover: darken(0.03, gray.light6),
    navBorder: `1px solid ${gray.light3}`
  },
  checkbox: {
    background: gray.light7,
    border: `1px solid ${gray.light2}`,
    checkedBackground: "linear-gradient(0deg, #0E5A8A, #48AFF0)",
    color: gray.light8,
    containerBg: lightColors.white,
    containerBorder: `1px solid ${gray.light5}`,
    containerDisabledBg: gray.light5
  },
  toggle: {
    background: lightColors.white,
    shadow: `0 0 3px ${black.light1}`,
    thumbColor: gray.light8,
    trackOffBackground: gray.light5,
    trackOnBackground: "linear-gradient(90deg, #0E5A8A, #48AFF0)",
    containerBg: lightColors.white,
    containerBorder: `1px solid ${gray.light5}`,
    containerDisabledBg: gray.light5
  },
  label: {
    background: pageBg,
    text: pageText,
    disabledText: lighten(0.1, pageText),
    border: `1px solid ${gray.light5}`
  },
  textField: {
    background: lightColors.white,
    disabledBackground: gray.light6,
    disabledText: lighten(0.1, gray.dark3),
    text: gray.dark4,
    border: `1px solid ${gray.light5}`,
    focusBorder: `1px solid ${gray.light5}`,
    focusShadow: `0 0 3px ${lightColors.blue.light}`
  },
  radioButton: {
    color: gray.light2,
    checkedColor: darken(0.1, "#48AFF0"),
    containerBg: lightColors.white,
    containerBorder: `1px solid ${gray.light5}`,
    containerDisabledBg: gray.light5
  },
  dropdown: {
    background: lightColors.white,
    disabledBackground: gray.light6,
    disabledText: lighten(0.1, gray.dark3),
    text: gray.dark4,
    border: `1px solid ${gray.light5}`,
    focusBorder: `1px solid ${gray.light5}`,
    focusShadow: `0 0 5px ${lightColors.blue.light}`
  }
};

const lightTheme: InceptionTheme = {
  name: "Light",
  type: "light",

  colors: lightColors,
  inceptionColors: inceptionLightColorPalette,
  chartColors: lightChartColors,
  plugins,
  inputs
};

export default lightTheme;
