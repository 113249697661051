export const Login = {
  attributes: {
    username: "login-username",
    password: "login-password",
    performLoginButton: "login-button",
    validateSSOButton: "continue-login",
    errorMessage: "login-error"
  },
  requestUrls: {
    bootConfig: "/ui/api/boot_config",
    validateSSO: "/ui/api/login/sso/validate?*",
    performLogin: "/apptuit/login"
  }
};
