import { TagFilter, TSQuery } from "../../../core";

export type QueryType = "incident" | "timeseries" | "query";

export interface RawQuery {
  query: string;
  id: string;
  alias: string;
}

export interface IncidentQuery {
  id: string;
  //mtsQuery: MTSQuery;
  mqId: string;
  mtsId: string;
  showBounds: boolean;
  showBands: boolean;
  showAdvanced: {
    anomScore: boolean;
    predicted: boolean;
    scored: boolean;
    anomaly: boolean;
  };
  name?: string;
  tags?: TagFilter[];
  metric?: string;
  query?: string;
  errors?: any;
}

type ApptuitDP = Array<[number, number | "NaN" | null]>;

export type OutputResult = {
  aggregatedTags: string[];
  dps: ApptuitDP;
  metric: string;
  tags: Record<string, string>;
};

export type ApptuitMetricResponse = {
  id: string;
  result: OutputResult[];
};

export type ApptuitDataResponse = {
  hints: Array<Record<string, string | number>>;
  outputs: ApptuitMetricResponse[];
  query: Record<string, any>;
  query_stats: Record<string, any>;
  timing_diagnostics: Array<Record<string, any>>;
};

export const IncidentQueryResultMap: Record<string, string> = {
  anomaly: "anomalies",
  "anom-score": "anom-score",
  lower: "lower",
  upper: "upper",
  predicted: "predicted",
  original: "scored",
  mtsincidents: "mtsincidents"
};

export type Target = IncidentQuery | TSQuery | RawQuery;
