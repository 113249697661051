import { useMemo, useCallback } from "react";
import { InitialState, FetchFn, FetchCallBackResult, useDataFetch } from "../useDataFetch";
import {
  OpCreationConfig,
  operationaliseV2ApiService,
  BizIdProps,
  Op10zeAlertParams,
  AlertCount,
  OpState
} from "../../../services/api/operationalise";

export type OpConfigListItem = {
  op10zeId: string;
  opCreationConfig: OpCreationConfig;
  alertCountList: AlertCount[];
  opState: OpState;
};

export const useFetchOpConfigs = (
  widgetId?: string,
  op10zeId?: string | string[],
  idProps?: BizIdProps,
  alertCountParams?: Op10zeAlertParams,
  widgetIds?: string[],
  disableAutoFetch?: boolean
) => {
  const initialState: InitialState<OpConfigListItem[], string> = useMemo(
    () => ({
      data: [],
      isFetching: !disableAutoFetch,
      error: null
    }),
    [disableAutoFetch]
  );

  const fetchFn = useCallback<FetchFn<OpConfigListItem[], string>>(async () => {
    const result: FetchCallBackResult<OpConfigListItem[], string> = {
      data: [],
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const { data, error, message } = await operationaliseV2ApiService.getOpConfigs(
        widgetId,
        op10zeId,
        idProps,
        alertCountParams,
        null,
        null,
        widgetIds
      );

      const { opCreationConfigs = {}, alertCounts = {}, opStatus = {} } = data || {};

      const op10zeIds = Object.keys(opCreationConfigs || {});
      op10zeIds.forEach(op10zeId => {
        const opCreationConfig = opCreationConfigs[op10zeId];
        const alertCountList = alertCounts?.[op10zeId]?.count || [];
        const opState = opStatus[op10zeId];

        result.data.push({
          op10zeId,
          opCreationConfig,
          alertCountList,
          opState
        });
      });

      result.isError = error;
      result.isSuccess = !error;
      result.error = error ? message : "";
    } catch (err) {
      result.isError = true;
      result.isSuccess = false;
      result.error = err.message;
    }

    return result;
  }, [alertCountParams, idProps, op10zeId, widgetId, widgetIds]);

  return useDataFetch(fetchFn, initialState, disableAutoFetch);
};
