import { User } from "../core";
import { ActionsMapType } from "./accessConfig";
import { Feature } from "./features";

export interface PermissionsInterface {
  hasAccess: (feature: Feature, action: ActionsMapType) => boolean;
}

export default class PermissionsManager implements PermissionsInterface {
  private permissions: Map<string, any>;
  private user: User;

  constructor(accesses: Map<string, any>, user: User) {
    this.permissions = accesses ? accesses : new Map();
    this.user = user ? user : null;
    this.initializeUser(user);
  }

  private initializeUser(user: User) {
    this.permissions.forEach((feature: any) => {
      feature.forEach((roles: any, action: any) => {
        if (user?.permissions?.feature?.action) {
          feature.set(action, user.permissions?.feature?.action);
        } else if (Array.isArray(roles) && roles?.includes(user.role)) {
          feature.set(action, true);
        } else {
          feature.set(action, false);
        }
      });
    });
    return this.permissions;
  }

  hasAccess(feature: Feature, action: ActionsMapType) {
    if (this.user.role === "Admin") {
      return true;
    }
    return this.permissions.get(feature)?.get(action);
  }
}
