import { TimeObj } from "../../explore";

export interface OpTriggerCondition {
  startTriggerCondition: WindowTriggerCondition;
  endTriggerCondition?: WindowTriggerCondition;
}

export interface WindowTriggerCondition {
  howManyTimesToViolate: number;
  outOfTime?: TimeObj;
  outOfPoints?: number;
}

export interface OpCloseTrigger {
  anyMatchStartTrigger?: AnyMatchStartTrigger;
}

export interface OpStartTrigger {
  // oneOf
  allMatchStartTrigger?: AllMatchStartTrigger;
  anyMatchStartTrigger?: AnyMatchStartTrigger;
}

export interface AllMatchStartTrigger {
  triggerConditions: TriggerCondition[];
}

export interface AnyMatchStartTrigger {
  triggerConditions: TriggerCondition[];
}

export interface TriggerCondition {
  /**
   * @deprecated This is a UI-only property, this is not populated by the backend, nor is saved by backend
   * added solely for using as a key prop while rendering
   */
  id?: string;

  // oneOf
  windowTrigger?: WindowTriggerCondition;
  accumulatorTrigger?: AccumulatorBasedTrigger;
  accumulatorTriggerV2?: AccumulatorBasedTriggerV2;
}

export enum TriggerType {
  WINDOW = "WINDOW",
  ACCUMULATOR = "ACCUMULATOR"
}

export interface AccumulatorBasedTrigger {
  unitDiff: number;

  // oneOf
  time?: TimeObj;
  count?: number;
}

export interface AccumulatorBasedTriggerV2 {
  unitDiff: number;
}
