import datasoureApiManager from "../DatasourceApiService";
import { ActionConfigTestPayload, MetricDefinition, CompareConfig, ActionPreviewResponse } from "../explore/types";
import {
  ActionDefConfigResponse,
  ActionsLookUpResponse,
  ActionTypesResponse,
  SlackChannel,
  TemplateVariableResponse
} from "./types";

interface Result<T> {
  data: T;
  error: boolean;
  message: string;
}

interface ResponseMessage {
  message: string;
}

class OperationaliseApiService {
  async getSlackChannels(): Promise<Result<SlackChannel[]>> {
    const url = "/api/operationalize/slack/channels";
    const result: Result<SlackChannel[]> = {
      data: [],
      error: false,
      message: ""
    };

    try {
      const response = await datasoureApiManager.getDefault().get<SlackChannel[], any>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response.data.message;
    }

    return result;
  }

  async testAction(actionConfig: ActionConfigTestPayload): Promise<Result<ResponseMessage>> {
    const url = "/api/operationalize/action/test";
    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };
    try {
      const response = await datasoureApiManager
        .getDefault()
        .post<ResponseMessage, ActionConfigTestPayload>(url, actionConfig);
      result.data.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response.data.message;
    }

    return result;
  }

  async previewAction(actionConfig: ActionConfigTestPayload): Promise<Result<ActionPreviewResponse>> {
    const url = "/api/operationalize/action/preview";
    const result: Result<ActionPreviewResponse> = {
      data: {
        actionPreview: []
      },
      error: false,
      message: ""
    };
    try {
      const response = await datasoureApiManager
        .getDefault()
        .post<ActionPreviewResponse, ActionConfigTestPayload>(url, actionConfig);
      result.data = response.data;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response.data.message;
    }

    return result;
  }

  async getTemplateVariables(): Promise<Result<TemplateVariableResponse>> {
    const url = "/api/operationalize/template-variables";
    const result: Result<TemplateVariableResponse> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await datasoureApiManager.getDefault().get<TemplateVariableResponse, any>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response.data.message;
    }

    return result;
  }

  async addCompareConfig(
    compareConfig: CompareConfig,
    metricId: string,
    widgetId: string,
    contextType: "userService" | "bizEntity",
    contextId: string
  ): Promise<Result<MetricDefinition>> {
    const url = `/api/${contextType}/${contextId}/schema/widget/${widgetId}/metric/${metricId}/compare`;
    const result: Result<MetricDefinition> = {
      data: null,
      error: false,
      message: ""
    };
    try {
      const response = await datasoureApiManager.getDefault().put<MetricDefinition, CompareConfig>(url, compareConfig);
      result.data = response.data;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response.data.message;
    }

    return result;
  }

  async getActionConfigTypes(
    contextType: string,
    contextId: string,
    widgetId: string,
    metricId: string
  ): Promise<Result<ActionTypesResponse>> {
    const url = `/api/${contextType}/${contextId}/schema/widget/${widgetId}/metric/${metricId}/action/types`;
    const result: Result<ActionTypesResponse> = {
      data: null,
      error: false,
      message: ""
    };
    try {
      const response = await datasoureApiManager.getDefault().get<ActionTypesResponse, any>(url);
      result.data = response.data;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response.data.message;
    }
    return result;
  }

  async getActionConfigDef(
    contextType: string,
    contextId: string,
    actionTypeId: string
  ): Promise<Result<ActionDefConfigResponse>> {
    const url = `/api/${contextType}/${contextId}/action/${actionTypeId}/actionDefConfigs`;
    const result: Result<ActionDefConfigResponse> = {
      data: null,
      error: false,
      message: ""
    };
    try {
      const response = await datasoureApiManager.getDefault().get<ActionDefConfigResponse, any>(url);
      result.data = response.data;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response.data.message;
    }
    return result;
  }

  async getLookUpFieldValues(actionConfigId: string, paramName: string): Promise<Result<ActionsLookUpResponse>> {
    const url = `/api/operationalize/action/${actionConfigId}/params/${paramName}/values`;
    const result: Result<any> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const response = await datasoureApiManager.getDefault().get<ActionsLookUpResponse, any>(url);
      result.data = response.data;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response.data.message;
    }

    return result;
  }

  async disableCompareConfig(
    compareConfig: CompareConfig,
    metricId: string,
    widgetId: string,
    contextType: "userService" | "bizEntity",
    contextId: string
  ): Promise<Result<MetricDefinition>> {
    const url = `/api/${contextType}/${contextId}/schema/widget/${widgetId}/metric/${metricId}/compare/${compareConfig.compareConfigId}/disable`;
    const result: Result<MetricDefinition> = {
      data: null,
      error: false,
      message: ""
    };
    try {
      const response = await datasoureApiManager.getDefault().put<MetricDefinition, CompareConfig>(url, compareConfig);
      result.data = response.data;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response.data.message;
    }

    return result;
  }
}

const operationaliseApiService = new OperationaliseApiService();
export default operationaliseApiService;
