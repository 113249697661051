import { ComponentType } from "react";
import appConfig from "../../../appConfig";
import {
  DataQuery,
  DatasourceSettings,
  BaseApi,
  SupportedTypes,
  DataQueryRequest,
  DataQueryResponse,
  UrlParams,
  InceptionRequestConfig,
  InceptionResponse
} from "./types";

export abstract class DatasourceApi<
  TQuery extends DataQuery = DataQuery,
  Settings extends DatasourceSettings = DatasourceSettings
> {
  name: string;
  type: SupportedTypes;
  proxyUrl: string;

  settings: Settings;
  baseApiService: BaseApi;

  constructor(name: string, type: SupportedTypes, settings: Settings, baseApiService: BaseApi) {
    this.settings = settings;
    this.name = name;
    this.type = type;
    this.proxyUrl = this.settings.isGrafanaDS ? this.getProxyUrl(appConfig.datasourceUrl, this.settings.id) : "";
    this.baseApiService = baseApiService;
  }

  protected getProxyUrl(dsUrl: string, id: number): string {
    return `${dsUrl}/proxy/${id}`;
  }

  getUrl(url: string): string {
    return `${this.proxyUrl}${url}`;
  }

  abstract query(request: DataQueryRequest<TQuery>): Promise<DataQueryResponse<unknown>>;
  cancelQuery?<R>(id: string): Promise<R>;
  get?<T, B = UrlParams, R = InceptionResponse<T>>(
    url: string,
    payload?: B,
    config?: InceptionRequestConfig
  ): Promise<R>;
  delete?<T, R = InceptionResponse<T>>(url: string, config?: InceptionRequestConfig): Promise<R>;
  post?<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R>;
  put?<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R>;
  suggestQuery?(query: string): Promise<DataQueryResponse<string[]>>;

  // FIX: Typing fix
  components?: DatasourceComponents<any, TQuery, Settings>;
  getDefaultQuery(): DataQuery {
    return {
      query: "",
      refId: "A",
      datasource: this.name,
      hide: false
    };
  }
  toggleQuery(oldQuery: DataQuery): DataQuery {
    return {
      ...oldQuery
    };
  }
}

export interface DatasourceComponents<
  DSType extends DatasourceApi<TQuery, Settings>,
  TQuery extends DataQuery = DataQuery,
  Settings extends DatasourceSettings = DatasourceSettings
> {
  QueryEditor?: ComponentType<QueryEditorProps<DSType, TQuery, Settings>>;
}

export interface QueryEditorProps<
  DSType extends DatasourceApi<TQuery, Settings>,
  TQuery extends DataQuery = DataQuery,
  Settings extends DatasourceSettings = DatasourceSettings
> {
  datasource: DSType;
  query: TQuery;
  onRunQuery: () => void;
  onChange?: (newQuery: TQuery) => void;
}
