import { createAction } from "@reduxjs/toolkit";
import { EntityType } from "../../../services/api/entity-mapping";
import { EntityTypeWrapper } from "../../../services/api/types";

export interface SchemaStore {
  fetchInProgress: boolean;
  entityTypes: Record<string, EntityType>;
  rawEntityTypesData: EntityTypeWrapper[];
}

const defaultSchemaState: SchemaStore = {
  entityTypes: {},
  rawEntityTypesData: [],
  fetchInProgress: false
};

const setSchemaStore = createAction<SchemaStore>("set-schema-store");
const setFetchState = createAction<boolean>("set-fetch-state");

const schemaStoreReducer = (state = defaultSchemaState, { type, payload }: any) => {
  switch (type) {
    case "set-schema-store":
      return {
        ...state,
        ...payload
      };
    case "set-fetch-state":
      return {
        ...state,
        fetchInProgress: Object.keys(state.entityTypes).length === 0 ? payload : false
      };
    default:
      return {
        ...state
      };
  }
};

export { schemaStoreReducer, setSchemaStore, setFetchState };
