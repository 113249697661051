import React from "react";
import { components, SingleValueProps, OptionProps } from "react-select";
import { IncSelect, IncSelectOption, IncSelectProps } from "../Select/InceptionSelect";

export interface IncSelectWithIconOption<T = any> extends IncSelectOption<T> {
  icon: JSX.Element;
}

export interface IncGroupSelectWithIconOption<T = any> {
  groupLabel: string;
  options: Array<IncSelectWithIconOption<T>>;
}

interface IncSelectWithIconProps<T = any> extends IncSelectProps<IncSelectWithIconOption<T>, false> {
  textClassName?: string;
}

export const IncSelectWithIcon = <T = any,>(props: IncSelectWithIconProps<T>) => {
  const { className, ...restProps } = props;

  return (
    <IncSelect<IncSelectWithIconOption, false>
      className={`inc-select-with-icon ${className}`}
      components={{
        SingleValue,
        Option
      }}
      {...restProps}
    />
  );
};

const SingleValue = (props: SingleValueProps<IncSelectWithIconOption>) => {
  const selectedOption = props.getValue()[0];
  const textClassName = `inc-flex-row inc-flex-center-vertical single-value flex-gap-6 ${(props.selectProps as any).textClassName}`;

  return (
    <components.SingleValue {...(props as SingleValueProps<IncSelectWithIconOption>)}>
      <div className={textClassName}>
        {selectedOption?.icon}
        <span>{selectedOption.label}</span>
      </div>
    </components.SingleValue>
  );
};

const Option = (props: OptionProps<IncSelectWithIconOption, false>) => {
  const { icon } = props.data || {};

  return (
    <components.Option {...props}>
      <div className="inc-flex-row inc-flex-center-vertical">
        {Boolean(icon) && <div className="inc-flex-row inc-flex-center-vertical marginRt6">{icon}</div>}
        {props.children}
      </div>
    </components.Option>
  );
};
