import { each, first, isEmpty } from "lodash";
import { TagFilter, MQuery, Filter, Tag } from "../core";
import { shouldExcludeTag } from "../core/utils";

export const Downsamplers: Record<string, number> = {
  "1m": 60,
  "2m": 120,
  "5m": 300,
  "10m": 600,
  "15m": 900,
  "30m": 1800,
  "1h": 3600,
  "24h": 86400,
  "1w": 604800
};

export const DSAutoInterval = "[[__interval]]";

export const constructQuery = (
  metric: string,
  rate?: string,
  tagFilters?: TagFilter[],
  downsampleFn?: string,
  dsIntervalSec?: number
) => {
  rate = rate || "false";
  tagFilters = tagFilters || [];
  downsampleFn = downsampleFn || "avg";
  dsIntervalSec = dsIntervalSec || -1;
  const dsIntervalStr = dsIntervalSec === -1 ? DSAutoInterval : `${dsIntervalSec}s`;

  let query = `fetch('${metric}' $(align) $(rateEquation))`;
  const rateEqu = rate && rate !== "false" ? `, rate='${rate}'` : "";
  query = query.replace("$(align)", `, align='${dsIntervalStr}'`);
  query = query.replace("$(rateEquation)", rateEqu);

  if (!isEmpty(tagFilters)) {
    each(tagFilters, (t: TagFilter) => {
      query += `.tag_filter('${t.tagK}${t.operator}${t.tagV}')`;
    });
  }
  query += `.downsample('${DSAutoInterval}', '${downsampleFn}')`;

  const filters: Filter[] = [];
  each(tagFilters, tf => {
    const matchCase = tf.operator !== "=~";
    const type = tf.operator === "=~" ? "regex" : tf.operator === "==" ? "=" : "!=";
    filters.push({
      enable: true,
      matchCase,
      tagk: tf.tagK,
      type,
      filter: tf.tagV
    });
  });

  const targets = [
    {
      refId: "A",
      ts: {
        agg: "none",
        dsDisable: dsIntervalSec !== -1 ? false : true,
        dsInterval: dsIntervalSec !== -1 ? `${dsIntervalSec}s` : "",
        dsFn: downsampleFn,
        filters: filters,
        id: "A",
        rate: rate,
        metric: metric,
        output: true
      },
      type: "Time Series"
    }
  ];

  return {
    query,
    targets
  };
};

export default class MQueryModel implements MQuery {
  metric: string;
  tagFilters: TagFilter[];
  downsampleFn: string;
  rate: string;
  dsIntervalSec: number;
  delaySec: number;
  target: any;

  constructor(model?: MQuery) {
    model = model || ({} as MQuery);
    this.metric = model.metric || "";
    this.tagFilters = model.tagFilters || [];
    this.delaySec = model.delaySec || 30;
    this.rate = model.rate || "false";
    this.dsIntervalSec = model.dsIntervalSec || Downsamplers["1m"];
    this.downsampleFn = model.downsampleFn || "avg";
  }

  addFilter(key: string, value: string, operator: string) {
    const filter: TagFilter = {
      tagK: key,
      tagV: value,
      operator: operator
    };
    this.tagFilters.push(filter);
  }

  processTarget(target: any, downsampleKey: string, isEdit?: boolean) {
    this.metric = !isEdit ? target.ts.metric : this.metric;
    this.tagFilters = [];
    each(target.ts.filters, f => {
      const operator = f.matchCase && f.type !== "regex" ? `${f.type}=` : f.type;
      this.addFilter(f.tagk, f.filter, operator);
    });
    this.target = target;
    this.rate = target.ts.rate ? `${target.ts.rate}` : "false";
    this.dsIntervalSec = Downsamplers[downsampleKey];
  }

  getTimeseriesTarget() {
    return constructQuery(this.metric, this.rate, this.tagFilters, this.downsampleFn, this.dsIntervalSec).targets;
  }

  getQueryString() {
    return constructQuery(this.metric, this.rate, this.tagFilters, this.downsampleFn, this.dsIntervalSec).query;
  }

  getTags(): Tag {
    const tags: Tag = {};
    (this.tagFilters || []).forEach(tf => {
      tags[tf.tagK] = tf.tagV;
    });
    return tags;
  }

  getLegendFormat(incomingIdTags?: string[]): string {
    let legends: string[] = [];
    const tagsObj = this.getTags();
    const tagsToRender = !isEmpty(incomingIdTags)
      ? incomingIdTags
      : Object.keys(tagsObj)
          .map(t => (shouldExcludeTag(t) ? null : t))
          .filter(Boolean);

    const tagKeys = tagsToRender;
    const getEntityname = (tagValue: string, tagKey: string) =>
      tagKey.startsWith("i_tapi") ? "Traffic" : first((tagValue || "").split(":")) || tagKey;

    legends = tagKeys.map(tagKey =>
      tagKey.startsWith("i_") ? `${getEntityname(tagsObj[tagKey], tagKey)}: {{${tagKey}}}` : `${tagKey}: {{${tagKey}}}`
    );

    // This should not be derived but fetched from entity type to entity name mapping.

    const legendFormat = legends.join(", ");
    return isEmpty(legendFormat) ? this.metric : legendFormat;
  }
}
