import { cloneDeep, difference } from "lodash";
import { useCallback, useMemo, useRef } from "react";
import { ActionType, TableInstance, TableState } from "react-table";
import { IncRTableProps } from "../props";
import { TableDataItem } from "../types";
import { setSelectedOrExpanded } from "./utils";
import { parseColumnIdForAccessor } from "./useConstructColumns";

type CallbackProps<T extends TableDataItem> = {
  data: T[];
  onSelectionChange: IncRTableProps<T>["onSelectionChange"];
  onExpansionChange: IncRTableProps<T>["onExpansionChange"];
  onGlobalFilterChange: IncRTableProps<T>["onGlobalFilterChange"];
  onPageChange: IncRTableProps<T>["onPageChange"];
  onSortChange: IncRTableProps<T>["onSortChange"];
};

type ReducerCallback<T extends TableDataItem> = (
  newState: TableState<T>,
  action: ActionType,
  previousState: TableState<T>,
  instance?: TableInstance<T>
) => TableState<T>;

type Action = "selection" | "page" | "globalFilter" | "sort" | "expand" | "resize" | "none";

const useStateReducerCallback = <T extends TableDataItem>({
  data,
  onSelectionChange,
  onGlobalFilterChange,
  onPageChange,
  onExpansionChange,
  onSortChange
}: CallbackProps<T>) => {
  const columnWidthsRef = useRef<TableState<T>["columnResizing"]>({} as any);
  const selectionEvents = useMemo(
    () => ["toggleRowSelected", "toggleAllPageRowsSelected", "toggleAllRowsSelected"],
    []
  );
  const pageEvents = useMemo(() => ["gotoPage", "setPageSize"], []);
  const globalFilterEvents = useMemo(() => ["setGlobalFilter"], []);
  const sortEvents = useMemo(() => ["toggleSortBy"], []);
  const expandEvents = useMemo(() => ["toggleRowExpanded"], []);
  const resizeEvents = useMemo(() => ["resetResize"], []);

  const getActionType = useCallback(
    (actionType: string): Action =>
      selectionEvents.indexOf(actionType) !== -1
        ? "selection"
        : pageEvents.indexOf(actionType) !== -1
          ? "page"
          : globalFilterEvents.indexOf(actionType) !== -1
            ? "globalFilter"
            : sortEvents.indexOf(actionType) !== -1
              ? "sort"
              : expandEvents.indexOf(actionType) !== -1
                ? "expand"
                : resizeEvents.indexOf(actionType) !== -1
                  ? "resize"
                  : "none",
    [expandEvents, globalFilterEvents, pageEvents, resizeEvents, selectionEvents, sortEvents]
  );

  return useCallback<ReducerCallback<T>>(
    (state, action, prev, next) => {
      const { type } = action;
      const actionType = getActionType(type);

      const { globalFilter, selectedRowIds, pageSize, pageIndex, expanded, columnResizing, sortBy } = state;

      switch (actionType) {
        case "globalFilter": {
          if (onGlobalFilterChange) {
            const newRows = (next?.globalFilteredRows || []).map(row => row.original);
            window.setTimeout(() => onGlobalFilterChange(globalFilter, newRows), 100);
          }
          break;
        }
        case "page": {
          if (onPageChange) {
            const start = pageIndex * pageSize;
            const end = start + pageSize;

            let selectedData = data.slice(start, end);
            selectedData = cloneDeep(selectedData);
            window.setTimeout(() => onPageChange(selectedData, pageIndex + 1, pageSize), 100);
          }
          break;
        }
        case "selection": {
          const selectedData: T[] = [];
          setSelectedOrExpanded(data, "", selectedRowIds, selectedData);

          if (onSelectionChange) {
            window.setTimeout(() => onSelectionChange(selectedData), 100);
          }
          break;
        }
        case "expand": {
          const expandedData: T[] = [];
          setSelectedOrExpanded(data, "", expanded, expandedData);

          const prevExpandedKeys = Object.keys(prev.expanded);
          const expandedKeys = Object.keys(expanded);
          const diffA = difference(expandedKeys, prevExpandedKeys);
          const diffB = difference(prevExpandedKeys, expandedKeys);
          const expandedRowIdx = [...diffA, ...diffB][0];
          const rowData: TableDataItem | undefined = next?.rowsById[expandedRowIdx];
          if (rowData && onExpansionChange) {
            window.setTimeout(() => onExpansionChange(expandedData, rowData.original, rowData.depth, rowData.id), 100);
          }
          break;
        }
        case "sort": {
          const sortEntry = sortBy[0];
          if (onSortChange && sortEntry) {
            const { id, desc } = sortEntry;
            const accessor = parseColumnIdForAccessor(id);
            const sortOrder = desc ? "desc" : "asc";
            window.setTimeout(() => onSortChange(accessor, sortOrder), 100);
          }
          break;
        }
        case "resize": {
          if (type === "resetResize") {
            state.columnResizing = { ...columnWidthsRef.current };
          }
          break;
        }
        case "none": {
          columnWidthsRef.current = { ...columnResizing };
          break;
        }
        default:
          break;
      }

      return state;
    },
    [data, getActionType, onExpansionChange, onGlobalFilterChange, onPageChange, onSelectionChange, onSortChange]
  );
};

export default useStateReducerCallback;
