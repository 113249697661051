import { isEmpty, isObject, isUndefined, omit, forEach } from "lodash";
import Axios, { AxiosError } from "axios";
import { logger, schemaCacheService } from "../../../core";
import { ApptuitDatasource } from "../../datasources/apptuit/ApptuitDatasource";
import datasoureApiManager from "../DatasourceApiService";
import { InceptionResponse } from "../types";
import { getBizEntityUrlPrefix, getUserServiceUrlPrefix } from "../../../utils/BizEntityUrl";
import { WidgetConfig, WidgetConfigDTO, Visualization, CommonMetricProperties } from "./types";
import { BizDataProcessor } from "./BizDataProcessor";

/**
 * Common code for Biz service
 * Generates Urls based on noun or verb first types
 * Handles Error, Initiates Datasource
 *
 */

interface WidgetLabelFilter {
  key: string;
  op: "eq" | "notContains";
  value: string;
}
export interface WidgetFilters {
  filters: WidgetLabelFilter[];
  cohortId?: string;
}

export type WidgetFilterType = "all" | "static" | "non-static";

export const WidgetFilterPreset: Record<WidgetFilterType, WidgetLabelFilter> = {
  all: null,
  "non-static": {
    key: "isStatic",
    op: "notContains",
    value: "true"
  },
  static: {
    key: "isStatic",
    op: "eq",
    value: "true"
  }
};

export interface BizEntityApiResult<T> {
  data: T;
  cancelled?: boolean;
  error: boolean;
  message: string;
}

export class BizService extends BizDataProcessor {
  private readonly bizEntityUrl = getBizEntityUrlPrefix();
  private readonly userserviceUrl = getUserServiceUrlPrefix();

  private readonly fieldSubUrl = "/schema/fields";
  private readonly journeyFieldsSubUrl = "/schema/journeyFields";
  protected readonly widgetSubUrl = "/schema/widget";

  protected datasource: ApptuitDatasource;

  protected getBizEntityUrl(url = ""): string {
    if (url.startsWith("/")) {
      url = url.substring(1);
    }
    return `${this.bizEntityUrl}/${url}`;
  }

  protected getUserServiceUrl(url = ""): string {
    if (url.startsWith("/")) {
      url = url.substring(1);
    }
    return `${this.userserviceUrl}/${url}`;
  }

  protected getFieldsUrl(entityId: string, entityType: string, url = "", isJourney = false): string {
    if (!isEmpty(entityId) && !isEmpty(entityType)) {
      logger.error("Explore Api Service", `EntityId ${entityId} EntityType ${entityType} both cannot be present`);
    }

    if (!isEmpty(entityId)) {
      const subUrl = `${entityId}${this.fieldSubUrl}${url}`;
      return this.getUserServiceUrl(subUrl);
    } else if (!isEmpty(entityType)) {
      let subUrl = `${entityType}${this.fieldSubUrl}${url}`;
      if (isJourney) {
        subUrl = `${entityType}${this.journeyFieldsSubUrl}${url}`;
      }
      return this.getBizEntityUrl(subUrl);
    } else {
      logger.error("Biz Service", `EntityId ${entityId} EntityType ${entityType} both are null`);
      const subUrl = `${entityType}${this.fieldSubUrl}${url}`;
      return this.getBizEntityUrl(subUrl);
    }
  }

  protected getWidgetUrl(entityId: string, entityType: string, url = ""): string {
    if (!isEmpty(entityId) && !isEmpty(entityType)) {
      logger.error("Explore Api Service", `EntityId ${entityId} EntityType ${entityType} both cannot be present`);
    }

    if (!isEmpty(entityId)) {
      const subUrl = `${entityId}${this.widgetSubUrl}${url}`;
      return this.getUserServiceUrl(subUrl);
    } else if (!isEmpty(entityType)) {
      const subUrl = `${entityType}${this.widgetSubUrl}${url}`;
      return this.getBizEntityUrl(subUrl);
    } else {
      logger.error("Explore Api Service", `EntityId ${entityId} EntityType ${entityType} both are null`);
      const subUrl = `${entityType}${this.widgetSubUrl}${url}`;
      return this.getBizEntityUrl(subUrl);
    }
  }

  protected getTimeParams(startTimeMillis: number, endTimeMillis: number, intervalSecs?: number) {
    if (startTimeMillis && endTimeMillis) {
      let timeParams = `?startTimeMillis=${startTimeMillis}&endTimeMillis=${endTimeMillis}`;
      timeParams = intervalSecs ? `${timeParams}&downSampleSeconds=${intervalSecs}` : timeParams;
      return timeParams;
    }

    return "";
  }

  protected async getResult<T>(request: () => Promise<InceptionResponse<T>>) {
    const result: BizEntityApiResult<T> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    try {
      const response = await request();
      const { errors } = response.data as any;
      if (!errors?.length) {
        result.data = response.data;
      } else {
        result.error = true;
        result.message = errors.join("\n");
      }
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  protected handleError<T>(err: any, result: BizEntityApiResult<T>) {
    let { message } = err;
    const error = err as AxiosError;

    if (error.isAxiosError) {
      if (err.response?.data) {
        message = isObject(err.response.data) ? err.response.data?.message || message : err.response.data;
      }
    }

    const cancelled = Axios.isCancel(err);
    result.cancelled = cancelled;
    result.error = true;
    result.message = cancelled ? "Request has been cancelled" : message;
  }

  getDtoFromWidgetConfig(widgetConfig: WidgetConfig): WidgetConfigDTO {
    const visualizations: Visualization[] = [];
    let commonMetricProperties: CommonMetricProperties = null;
    (widgetConfig.visualizations || []).forEach(vizStr => {
      const vizJson: Partial<Visualization> = JSON.parse(vizStr || "{}");
      if (vizJson.type === "common-metric-properties") {
        commonMetricProperties = vizJson as CommonMetricProperties;
      } else {
        visualizations.push({
          ...vizJson,
          dataDefs: (vizJson?.dataDefs || []).map(dd => ({
            ...dd,
            enabled: !isUndefined(dd.enabled) ? dd.enabled : true
          }))
        } as Visualization);
      }
    });

    const { bizEntityType } = widgetConfig;
    const entityTypeName = bizEntityType ? schemaCacheService.getEntityTypeName(bizEntityType) || bizEntityType : "";

    return {
      ...widgetConfig,
      visualizations,
      commonMetricProperties,
      entityTypeName
    };
  }

  getWidgetConfigFromDto(widgetConfigDto: WidgetConfigDTO): WidgetConfig {
    const widgetConfigWithoutViz = omit(widgetConfigDto, [
      "visualizations",
      "entityTypeName",
      "userServiceEntityName",
      "createdBy",
      "lastUpdatedBy",
      "commonMetricProperties"
    ]);

    // Remove compareConfig if the config is null/ undefined
    forEach(widgetConfigWithoutViz.dataDefinition.metrics, metricDef => {
      if (!metricDef.operationalizeConfig) {
        delete metricDef.operationalizeConfig;
      }
    });
    const visualizations: string[] = (widgetConfigDto.visualizations || []).map(viz => JSON.stringify(viz));
    if (widgetConfigDto.commonMetricProperties) {
      // pushing stringified commonMetricProperties object to visualizations
      // todo: update this when backend supports storing commonMetricProperties
      visualizations.push(JSON.stringify(widgetConfigDto.commonMetricProperties));
    }

    return {
      ...widgetConfigWithoutViz,
      visualizations
    };
  }

  protected init() {
    this.datasource = datasoureApiManager.getDefault();
  }
}
