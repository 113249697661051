import { useCallback } from "react";
import {
  fieldPickerApiService,
  FieldSchemaResponse,
  FieldPickerContextDTO,
  FieldPickerContext,
  DemoDataParams
} from "../services/api/explore";
import { logger, useFetchDataStatus } from "../core";
import { ALL_USERSERVICES_ENTITY_TYPE_ID } from "../utils/ExploreUtils";
import { FieldPickerModel } from "./FieldPickerModel";

export const useFieldPicker = () => {
  const fetchStatus = useFetchDataStatus<FieldPickerModel>();
  const { setStatus } = fetchStatus;

  /**
   * based on exploreType pass entityType or entityId for noun vs verb first case
   * */
  const getFields = useCallback(
    async (
      pickerContextDto: FieldPickerContextDTO,
      startTimeMills: number,
      endTimeMillis: number,
      isJourney = false,
      demoParams: DemoDataParams = null
    ) => {
      const { entityType, entityId, ...rest } = pickerContextDto || {};

      logger.debug("FieldPicker", "Fetching data for context", pickerContextDto);
      setStatus(null, true, false, false, null);
      const model = new FieldPickerModel(pickerContextDto);

      const shouldFetchAllUsFields =
        pickerContextDto?.entityType === ALL_USERSERVICES_ENTITY_TYPE_ID && !pickerContextDto?.userServices?.length;

      try {
        let data: FieldSchemaResponse = null;

        if (shouldFetchAllUsFields) {
          data = await fieldPickerApiService.getAllUserServiceFields(startTimeMills, endTimeMillis);
        } else {
          if (entityType) {
            data = await fieldPickerApiService.getBizEntityFields(
              entityType,
              rest as FieldPickerContext,
              startTimeMills,
              endTimeMillis,
              isJourney,
              demoParams
            );
          } else {
            data = await fieldPickerApiService.getUserserviceEntityField(
              entityId,
              rest as FieldPickerContext,
              startTimeMills,
              endTimeMillis,
              demoParams
            );
          }
        }

        model.init(data);
        setStatus(model, false, true, false, null);
      } catch (e) {
        setStatus(null, false, false, true, e);
        logger.error("useFieldPicker", "Failed to fetch entity fields", e);
      }
    },
    [setStatus]
  );

  return {
    ...fetchStatus,
    getFields
  };
};

export const getFieldPickerModel = async (
  pickerContextDto: FieldPickerContextDTO,
  startTimeMills: number,
  endTimeMillis: number
) => {
  const { entityType, entityId, ...rest } = pickerContextDto || {};

  const model = new FieldPickerModel(pickerContextDto);

  try {
    let data: FieldSchemaResponse = null;

    if (entityType) {
      data = await fieldPickerApiService.getBizEntityFields(
        entityType,
        rest as FieldPickerContext,
        startTimeMills,
        endTimeMillis
      );
    } else {
      data = await fieldPickerApiService.getUserserviceEntityField(
        entityId,
        rest as FieldPickerContext,
        startTimeMills,
        endTimeMillis
      );
    }

    model.init(data);
    return model;
  } catch (e) {
    logger.error("getFieldPickerModel", "Failed to fetch entity fields", e);
    return model;
  }
};
