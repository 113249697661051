export const ThresholdEditor = {
  attributes: {
    manualThresholdWrapper: "manual-threshold-wrapper",
    manualThresholdTextToggle: "manual-threshold-text-toggle",
    manualSeasonalityDropdown: "seasonality-dropdown",
    manualPeriodsInput: "manual-periods-input",
    manualLookbackRadios: "manual-threshold-lookback-radios",
    manualTrueLookbackRadio: "manual-threshold-lookback-radio",
    manualBaseMetricRadio: "manual-base-metric-radio",
    manualAggregatorRadioButtons: {
      linearRegression: "aggregator-linear-regression-radio",
      average: "aggregator-average-radio",
      max: "aggregator-max-radio",
      min: "aggregator-min-radio",
      simpleMovingAvg: "aggregator-simple-moving-avg-radio",
      exponentialWeightedMovingAvg: "aggregator-exponential-weighted-moving-avg-radio",
      distinctCount: "aggregator-distinct-count-radio",
      P25: "aggregator-p25-radio",
      P50: "aggregator-p50-radio",
      P75: "aggregator-p75-radio",
      P95: "aggregator-p95-radio",
      P99: "aggregator-p99-radio"
    },
    manualAggregatorsShowMore: "manual-aggregators-show-more",
    manualApplyButton: "manual-apply-button",
    peerThresholdWrapper: "peer-threshold-wrapper",
    peerSliceSelector: "peer-slice-selector",
    peerFieldSelector: "peer-field-selector"
  }
};
