import React, { useEffect, useMemo, useRef, useState } from "react";
import { throttle } from "lodash";
import { TableDataItem } from "./types";
import { IncRTableProps } from "./props";
import { IncTableContainer } from "./TableContainer";

const IncTable = <T extends TableDataItem>(props: IncRTableProps<T>) => {
  const { isLoading = false } = props;

  const tableRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  const updateWidth = useMemo<ResizeObserverCallback>(
    () =>
      throttle(target => {
        window.requestAnimationFrame(() => {
          if (target?.length) {
            setWidth(prev => {
              const { contentRect } = target[0];
              const nWidth = contentRect?.width;

              if (nWidth) {
                const diff = Math.abs(prev - nWidth);
                if (diff > 25) {
                  return nWidth;
                }
              }

              return prev;
            });
          }
        });
      }, 500),
    []
  );

  useEffect(() => {
    if (tableRef.current) {
      const target = tableRef.current;

      const observer = new ResizeObserver(updateWidth);
      observer.observe(target);

      return () => {
        observer?.unobserve(target);
      };
    }
  }, [updateWidth]);

  useEffect(() => {
    if (tableRef.current) {
      setWidth(tableRef.current.clientWidth);
    }
  }, []);

  return (
    <div
      className="inc-table-container"
      data-loading={`${isLoading}`}
      ref={tableRef}
    >
      {Boolean(width) && (
        <IncTableContainer
          {...props}
          tableWidth={width}
        />
      )}
    </div>
  );
};

export default IncTable;
