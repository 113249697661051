import { useCallback, useEffect } from "react";
import { useFetchDataStatus } from "../../../../core";
import exploreApiService from "../ExploreApiService";
import { WidgetResponseDTO } from "../types/exploreTypes";

const useFetchExploreWidgets = (
  entityId: string,
  entityType: string,
  filterLabels?: Record<string, string>,
  autoFetch?: boolean
) => {
  const {
    data: widgetConfigs,
    error,
    isError,
    isFetching,
    isSuccess,
    setStatus
  } = useFetchDataStatus<WidgetResponseDTO[], string>({ data: [] });

  const fetchWidgetConfigs = useCallback(async () => {
    setStatus([], true, false, false, "");
    let wConfigItems: WidgetResponseDTO[] = [];
    let errMsg = "";
    let err = false;
    if (entityType || entityId) {
      const { data, error, message } = await exploreApiService.getWidgetConfigs(
        entityId,
        entityType,
        "",
        undefined,
        filterLabels
      );

      if (!error) {
        wConfigItems = data?.map(d => {
          const { widgetConfig } = d;
          // Doing this since some configs do not have bizEntityType
          widgetConfig.bizEntityType = widgetConfig.bizEntityType || entityType;
          return d;
        });
      }
      err = error;
      errMsg = error ? message : "";
    }

    setStatus(wConfigItems, false, !err, err, errMsg);
  }, [entityId, entityType, filterLabels, setStatus]);

  useEffect(() => {
    if (autoFetch !== false) {
      fetchWidgetConfigs();
    }
  }, [fetchWidgetConfigs, autoFetch]);

  return {
    widgetConfigs,
    isError,
    isFetching,
    isSuccess,
    error,
    fetchWidgetConfigs
  };
};

export default useFetchExploreWidgets;
