import React, { FC, lazy, Suspense } from "react";
import { useInceptionTheme } from "../../platform/core";

const LightTheme = lazy(() => import("./LightTheme"));
const DarkTheme = lazy(() => import("./DarkTheme"));

const ThemeImporter: FC = props => {
  const { inceptionTheme: theme } = useInceptionTheme();

  return (
    <>
      <Suspense fallback={<div className={"progressIcon"}></div>}>
        {theme === "dark" && <DarkTheme>{props.children}</DarkTheme>}
        {theme === "light" && <LightTheme>{props.children}</LightTheme>}
      </Suspense>
    </>
  );
};

export default ThemeImporter;
