import { useCallback, useEffect, useState } from "react";
import { BizDataQuery, WidgetConfigDTO, WidgetConfigUtils, exploreApiService } from "../../services/api/explore";
import { getDtoFromWidgetConfig } from "../../utils/ExploreUtils";
import { logger } from "../logging/Logger";

export const useFetchWidgetConfigForBizDataQuery = (bizDataQuery: BizDataQuery, skipAutoFetch = false) => {
  const [widgetConfigDto, setWidgetConfigDto] = useState<WidgetConfigDTO>();
  const [widgetConfigFetchInProgress, setWidgetConfigFetchInProgress] = useState(true);

  const fetchWidgetConfig = useCallback(async () => {
    setWidgetConfigFetchInProgress(true);

    const { buildingBlockConfig, id: widgetId, idProps, widgetConfig } = bizDataQuery;
    const { entityTypeId, eventTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(idProps);

    if (widgetConfig) {
      setWidgetConfigDto(getDtoFromWidgetConfig(widgetConfig));
      setWidgetConfigFetchInProgress(false);
    } else if (widgetId) {
      const { data, error, message } = await exploreApiService.getWidgetConfig(eventTypeId, entityTypeId, widgetId);

      if (error || !data) {
        logger.fatal("useFetchWidgetConfigForBizDataQuery", "Failed to fetch widget config", {
          message,
          data
        });
      } else {
        setWidgetConfigDto(data.widgetConfig);
      }
      setWidgetConfigFetchInProgress(false);
    } else if (buildingBlockConfig) {
      setWidgetConfigDto(WidgetConfigUtils.getWidgetConfigDtoFromBuildingBlockConfig(buildingBlockConfig));
      setWidgetConfigFetchInProgress(false);
    }
  }, [bizDataQuery]);

  useEffect(() => {
    if (!skipAutoFetch && bizDataQuery) {
      fetchWidgetConfig();
    }
  }, [bizDataQuery, fetchWidgetConfig, skipAutoFetch]);

  return {
    widgetConfigDto,
    widgetConfigFetchInProgress,
    fetchWidgetConfig
  };
};
