import React, { ReactElement } from "react";
import { Sparklines, SparklinesBars, SparklinesLine, SparklinesProps, SparklinesReferenceLine } from "react-sparklines";
import { useTheme } from "emotion-theming";
import { formatNumber } from "../../utils/formatters/NumberFormatter";
import { InceptionTheme } from "../../themes/types/theme";
import { IncToolTip } from "../antd-components";

interface IncSparklinesProps extends SparklinesProps {
  data: number[];
  type: "line" | "bar";

  referenceValue?: number;

  showAggregateValue?: boolean;
  aggregateValue?: number;
  units?: string;

  isForErrorSeries?: boolean;
}

const limitOnNumDataPoints = 40;

// this can probably be accepted as props in future. But when doing that the aggregate value also needs to be shifted to be aligned
const sparklineHeight = 30;

/**
 * Note: Type bar cannot handle large number of points. Restrict the number of points sent or use line type;
 * @param props
 */
const IncSparklines: React.FC<IncSparklinesProps> = (props: IncSparklinesProps) => {
  const {
    type,
    referenceValue,
    aggregateValue,
    isForErrorSeries,
    units = "",
    showAggregateValue,
    limit,
    ...sparklineProps
  } = props;

  const theme: InceptionTheme = useTheme();

  const getSparklineColor = (): string => {
    if (isForErrorSeries) {
      return theme.inceptionColors.palette.R400;
    }

    return theme.inceptionColors.palette.B600;
  };

  const getTypeElem = (): ReactElement => {
    let typeElem: ReactElement = <></>;

    switch (type) {
      case "line":
        typeElem = (
          <SparklinesLine
            style={{
              strokeWidth: 2,
              stroke: getSparklineColor(),
              fill: "none"
            }}
          />
        );
        break;

      case "bar":
        typeElem = (
          <SparklinesBars
            style={{
              stroke: "white",
              strokeWidth: "1",
              fill: getSparklineColor()
            }}
          />
        );
        break;

      default:
        throw new Error("unsupported sparkline type");
    }

    return typeElem;
  };

  const getReferenceLine = (): ReactElement =>
    referenceValue ? <SparklinesReferenceLine value={referenceValue} /> : <></>;

  const chartDivStyle: React.CSSProperties = {
    width: showAggregateValue ? "80%" : "100%",
    marginLeft: 16,
    paddingTop: 6,
    marginRight: 16
  };

  const aggregateValueStyle: React.CSSProperties = {
    padding: "5px 4px",
    border: "1px solid #DDD",
    borderRadius: 4,
    height: 28,
    width: 60,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
    cursor: "default"
  };

  const aggregateValueStr = `${formatNumber(aggregateValue || 0)} ${units}`;

  return (
    <div className="inc-flex-row inc-flex-center-vertical paddingRt16 inc-flex-grow">
      {showAggregateValue && (
        <IncToolTip
          placement="top"
          titleText={aggregateValueStr}
        >
          <div
            className="inc-text-subtext-medium"
            style={aggregateValueStyle}
          >
            {aggregateValueStr}
          </div>
        </IncToolTip>
      )}
      <div style={chartDivStyle}>
        <Sparklines
          {...sparklineProps}
          height={sparklineHeight}
          limit={limit || limitOnNumDataPoints}
        >
          {getTypeElem()}
          {getReferenceLine()}
        </Sparklines>
      </div>
    </div>
  );
};

export default IncSparklines;
