import React, { FC, forwardRef } from "react";
import { IncTextfieldProps } from "../../TextField";
import IncNumericField from "../numeric/NumericField";
import { CurrencyType } from "../../../../utils";

interface IncCurrencyFieldProps extends IncTextfieldProps {
  prefix: string;
  currency: CurrencyType;
  disableAbbreviations?: boolean;
}

const IncCurrencyField: FC<IncCurrencyFieldProps> = forwardRef<HTMLInputElement, IncCurrencyFieldProps>(
  (props, ref) => {
    const { currency, containerClassName, ...otherProps } = props;

    const appliedContainerClassName = `currencyfield-container ${containerClassName}`;

    return (
      <IncNumericField
        {...otherProps}
        containerClassName={appliedContainerClassName}
        kindDescriptor={{
          kind: "currency",
          subType: currency
        }}
        ref={ref}
      />
    );
  }
);

export default IncCurrencyField;
