import React, { useMemo, FC, memo, useState, useCallback, useEffect } from "react";
import { IncFaIconName, IncFaIcon } from "@inception/ui";
import { isEmpty } from "lodash";
import { cx, css } from "emotion";
import { ServiceIcon, ComponentIcon, APIIcon } from "../../core/iconwrapper";
import { useSchemaStore } from "../../core";
import { schemaApiService } from "../../services/api";
import { EntityType } from "../../services/api/entity-mapping";
import { EntityDetails } from "./types";

type NProps = {
  entityDetails: EntityDetails;
  hideIcon?: boolean;
  hideTooltipOnEllipsis?: boolean;
};

export const EntityNameRenderer: FC<NProps> = memo((props: NProps) => {
  const { entityDetails, hideIcon, hideTooltipOnEllipsis } = props;

  const iconComponent = useMemo(
    () => (hideIcon ? null : <EntityIconRenderer entityType={entityDetails?.type} />),
    [entityDetails, hideIcon]
  );

  const nameComponent = useMemo(() => {
    if (entityDetails) {
      const { name, id } = entityDetails;

      const entityName = name || id;
      const title = hideTooltipOnEllipsis ? null : entityName;
      const ellipsis = css`
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      `;
      const ncClassName = cx("entity-name-renderer", ellipsis, {
        marginLt6: !isEmpty(iconComponent)
      });

      return (
        <div className="inc-flex-row inc-flex-center-vertical entity-name-renderer">
          {iconComponent}
          <div
            className={ncClassName}
            title={title}
          >
            {entityName}
          </div>
        </div>
      );
    }
  }, [entityDetails, hideTooltipOnEllipsis, iconComponent]);

  return nameComponent;
});

type ICProps = {
  entityType: string;
  className?: string;
};

export const EntityIconRenderer: FC<ICProps> = memo(props => {
  const { entityType, className = "" } = props;

  const [icon, setIcon] = useState<IncFaIconName | null>(null);
  const { entityTypes, fetchInProgress } = useSchemaStore();

  const fetchData = useCallback(async () => {
    let nIcon: IncFaIconName;
    let etInfo: EntityType;
    if (entityType) {
      const entityTypeInfo = entityTypes[entityType];
      if (entityTypeInfo) {
        etInfo = entityTypeInfo;
      } else {
        const { data } = await schemaApiService.getEntityTypeInfo(entityType);
        etInfo = data?.entityType;
      }

      const { typeReference } = etInfo || {};
      const { metadata } = typeReference || {};
      const { icon: eIcon } = metadata || {};

      nIcon = eIcon as IncFaIconName;
      setIcon(nIcon);
    }
  }, [entityType, entityTypes]);

  const iconComponent = useMemo(() => {
    const faIcon = icon || "image";
    return (
      <IncFaIcon
        className={className}
        iconName={faIcon}
      />
    );
  }, [className, icon]);

  useEffect(() => {
    if (entityType && !fetchInProgress) {
      fetchData();
    }
  }, [entityType, fetchData, fetchInProgress]);

  if (!entityType) {
    return null;
  }

  if (icon) {
    return iconComponent;
  }

  switch (entityType) {
    case "i_service":
      return <ServiceIcon />;
    case "i_api":
      return <APIIcon />;
    case "i_component":
      return <ComponentIcon />;
    default:
      return iconComponent;
  }
});
