import { OpFragment } from "../../chat";
import { OpCreationFragmentV2List } from "./OpFragmentV2";
import { OpCreationConfig } from "./OperationaliseV2Types";

export type OpEditViaFragmentsRequest = {
  opCreationConfig: OpCreationConfig;
  opFragment: OpFragment;
  editType: OpCreationFragmentEditType;
};

export type OpPreviewViaFragmentsRequest = {
  opCreationFragmentList: OpCreationFragmentV2List;
};

export enum OpCreationFragmentEditType {
  UNSET = "UNSET",
  SCHEDULE = "SCHEDULE",
  KPI_WITH_COMPARATOR = "KPI_WITH_COMPARATOR",
  TRIGGERS = "TRIGGERS",
  THRESHOLD = "THRESHOLD",
  LOOKBACK = "LOOKBACK",
  BIZ_ACTIONS = "BIZ_ACTIONS",
  SUPPRESSIONS = "SUPPRESSIONS",
  EVENT_CRITERIA = "EVENT_CRITERIA",
  SEASONALITY_OVERRIDES = "SEASONALITY_OVERRIDES",
  KPI_WITH_COMPARATOR_AND_TREND = "KPI_WITH_COMPARATOR_AND_TREND",
  HISTORY_TO_CONSIDER = "HISTORY_TO_CONSIDER"
}
