import { useCallback, useMemo } from "react";
import { FetchCallBackResult, FetchFn, InitialState, useDataFetch } from "../../../../core";
import { ResponseStatus } from "../../explore";
import { UseCaseOp10zeQueryParams, UseCaseOp10zeSearchResponse } from "../types";
import { useCaseApiService } from "../UseCaseApiService";

export const useFetchOp10zeForUseCases = (
  useCaseIdOrIds: string | string[],
  companyName: string,
  queryParams?: UseCaseOp10zeQueryParams,
  lazyFetch = false
) => {
  const useCaseIds = useMemo(
    () => (!useCaseIdOrIds ? [] : typeof useCaseIdOrIds === "string" ? [useCaseIdOrIds] : useCaseIdOrIds),
    [useCaseIdOrIds]
  );

  const initialState = useMemo<InitialState<UseCaseOp10zeSearchResponse["useCaseOp10zeMap"], string>>(
    () => ({
      data: {},
      error: "",
      isError: false,
      isFetching: !lazyFetch,
      isSuccess: false
    }),
    [lazyFetch]
  );

  const fetchFn = useCallback<FetchFn<UseCaseOp10zeSearchResponse["useCaseOp10zeMap"], string>>(async () => {
    const result: FetchCallBackResult<UseCaseOp10zeSearchResponse["useCaseOp10zeMap"], string> = {
      data: {},
      error: "",
      isError: false,
      isSuccess: false
    };

    try {
      const { data, error, message } = await useCaseApiService.getOp10zeForUseCases(
        useCaseIds,
        companyName,
        queryParams
      );

      if (error) {
        result.error = message;
        result.isError = true;
      } else {
        const { responseInfo, useCaseOp10zeMap } = data;

        if (responseInfo?.status === ResponseStatus.FAILED) {
          result.isError = true;
          result.error = responseInfo.errors?.join(", ") || "Error fetching op10ze for Copilots";
        } else {
          result.data = useCaseOp10zeMap;
          result.isSuccess = true;
        }
      }
    } catch (err) {
      result.isError = true;
      result.error = err.message || String(err);
    }

    return result;
  }, [useCaseIds, companyName, queryParams]);

  return useDataFetch(fetchFn, initialState, lazyFetch);
};
