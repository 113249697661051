export enum ValueType {
  string,
  listString,
  date,
  timeInMicros,
  long,
  boolean,
  entityId,
  listEntity
}
