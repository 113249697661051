import React, { useState, forwardRef, Ref, useMemo } from "react";
import { IncTextfieldProps } from "../../TextField";
import { getInceptionTheme } from "../../../../themes/ThemeProvider";
import { IncToolTip } from "../../../antd-components";
import {
  IncNumericKind,
  IncNumericKindDescriptor,
  getCurrencyInfo,
  getDurationInfo,
  CurrencyType
} from "../../../../utils";
import { IncErrorIcon, IncInfoIcon } from "../../../../icons";

interface Props<T extends IncNumericKind> extends IncTextfieldProps {
  prefix?: string;
  kindDescriptor?: IncNumericKindDescriptor<T>;
  disableAbbreviations?: boolean;
}

const IncNumericFieldComponent = <T extends IncNumericKind>(props: Props<T>, ref: Ref<HTMLInputElement>) => {
  const {
    label,
    kindDescriptor,
    prefix,
    disabled = false,
    className = "",
    hasError,
    errorText,
    value,
    helpTextId,
    helpText,
    containerClassName = "",
    disableAbbreviations = false,
    ...otherProps
  } = props;

  const isValid = useMemo(() => {
    if (kindDescriptor && kindDescriptor.kind === "currency") {
      const currency = kindDescriptor.subType;
      return getCurrencyInfo(value, currency as CurrencyType, disableAbbreviations).isValid;
    }
    if (kindDescriptor && kindDescriptor.kind === "duration") {
      return getDurationInfo(value, disableAbbreviations).isValid;
    }
    return true;
  }, [disableAbbreviations, kindDescriptor, value]);
  const errorMessage = "Enter a valid value";
  const [isFieldFocused, setIsFieldFocused] = useState(false);

  const input = (
    <div
      className={`inc-flex-row inc-flex-center-vertical input-numeric-field ${className} ${isFieldFocused ? "focus" : ""} ${!isValid || hasError ? "inc-text-error" : ""}`}
    >
      <span className="prefix">{prefix}</span>
      <input
        autoComplete="off"
        className="input-field"
        disabled={disabled}
        onBlur={() => setIsFieldFocused(false)}
        onFocus={() => setIsFieldFocused(true)}
        ref={ref}
        type="number"
        value={value}
        {...otherProps}
      />
    </div>
  );

  const appliedContainerClassName = `numeric-field-container ${containerClassName}`;

  const errComponent =
    !isValid || hasError ? (
      <IncToolTip
        placement="top-start"
        showArrow
        titleText={!isValid ? errorMessage : errorText}
        variant="error"
      >
        <IncErrorIcon
          style={{
            color: getInceptionTheme().inceptionColors.palette.R500,
            marginLeft: !label ? 8 : 0
          }}
        />
      </IncToolTip>
    ) : (
      <></>
    );

  return (
    <div className={appliedContainerClassName}>
      {label && (
        <div className="input-label">
          {label}
          {(helpTextId || helpText) && (
            <IncToolTip
              placement="top-start"
              showArrow
              titleId={helpTextId}
              titleText={helpText}
            >
              <IncInfoIcon />
            </IncToolTip>
          )}
          {errComponent}
        </div>
      )}

      <div className="inc-flex-row">
        {input}
        {!label && errComponent}
      </div>
    </div>
  );
};

const IncNumericField = forwardRef(IncNumericFieldComponent);

export default IncNumericField;
