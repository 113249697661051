import { Action } from "./features";

//place all actions under configuration list here
export enum DASHBOARD_ACTIONS {
  createDashboard = "createDashboard",
  updateDashboard = "updateDashboard",
  showDashboards = "showDashboards",
  deleteDashboard = "deleteDashboard"
}

export const DashboardListFeatureActions: Record<keyof typeof Action, Array<keyof typeof DASHBOARD_ACTIONS>> = {
  //All CREATE actions goes here
  //Enabled only for Admins,Editors
  CREATE: ["createDashboard"],

  //All UPDATE actions goes here
  //Enabled only for Admins,Editors
  UPDATE: ["updateDashboard"],

  //All READ actions goes here
  //Enabled for All
  READ: ["showDashboards"],

  //All DELETE actions goes here
  //Enabled only for Admins,Editors
  DELETE: ["deleteDashboard"]
};
