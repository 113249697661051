import { ExpressionTree } from "./ExpressionTree";
import { LiteralUtils } from "./utils";

export const getExpressionTree = (expression: string) => {
  const { result, isValid } = convertToPostFixExpression(expression);

  const expressionTree = new ExpressionTree(result);
  return {
    expressionTree,
    isValid
  };
};

const convertToPostFixExpression = (expression: string) => {
  let resultExpr: string[] = [];

  const stack: string[] = [];
  const literals = getExpressionLiterals(expression);

  literals.forEach(literal => {
    const isNumLiteral = LiteralUtils.isNumber(literal);
    const isVarLiteral = LiteralUtils.isVariable(literal);

    if (isNumLiteral || isVarLiteral) {
      resultExpr.push(literal);
    } else if (literal === "(") {
      stack.push("(");
    } else if (literal === ")") {
      while (stack[stack.length - 1] !== "(") {
        const _literal = stack[stack.length - 1];
        resultExpr.push(_literal);
        stack.pop();
      }
      stack.pop();
    } else {
      while (stack.length !== 0 && getPrecedence(literal) <= getPrecedence(stack[stack.length - 1])) {
        const _literal = stack[stack.length - 1];
        resultExpr.push(_literal);
        stack.pop();
      }
      stack.push(literal);
    }
  });

  resultExpr.push(...stack);

  const isValid = validatePostFixExpression(resultExpr);
  resultExpr = isValid ? resultExpr : [];

  return {
    result: resultExpr,
    isValid
  };
};

const validatePostFixExpression = (postFixExpr: string[]) => {
  let isValid = false;
  let numOperators = 0;
  let numOperands = 0;

  const numLiterals = postFixExpr.length;
  for (let idx = 0; idx < numLiterals; idx++) {
    const literal = postFixExpr[idx];
    const isOperator = LiteralUtils.isOperator(literal);
    const isOperand = LiteralUtils.isNumber(literal) || LiteralUtils.isVariable(literal);
    const isSymbol = LiteralUtils.isSymbol(literal);

    const invalidateExpression = isSymbol || (idx < 2 && !isOperand);

    if (invalidateExpression) {
      numOperands = 0;
      numOperators = 0;
      break;
    }

    if (isOperator) {
      numOperators += 1;
    } else if (isOperand) {
      numOperands += 1;
    }
  }

  isValid = numOperands === numOperators + 1;

  return isValid;
};

const getPrecedence = (char: string) => {
  switch (char) {
    case "/":
    case "*":
      return 2;
    case "+":
    case "-":
      return 1;
    default:
      return 0;
  }
};

const getExpressionLiterals = (expression: string): string[] => {
  const literals: string[] = [];
  let stack: string[] = [];

  expression.split("").forEach(ch => {
    const isNumLiteral = LiteralUtils.isNumber(ch);
    const isValidLiteral =
      isNumLiteral || LiteralUtils.isOperator(ch) || LiteralUtils.isSymbol(ch) || LiteralUtils.isVariable(ch);
    if (isValidLiteral) {
      if (isNumLiteral) {
        // Push to stack if number literal, since the number can be multi-digit
        stack.push(ch);
      } else {
        /**
         * When a non-numeric literal is encountered, check stack for
         * numeric literals. If exists, join and push to characters and
         * push current character.
         */
        if (stack.length) {
          const numCharacter = stack.join("");
          literals.push(numCharacter);
          stack = [];
        }
        literals.push(ch);
      }
    }
  });

  // This is required when the expression ends with a number
  if (stack.length) {
    const numCharacter = stack.join("");
    literals.push(numCharacter);
    stack = [];
  }

  return literals;
};
