import { groupBy } from "lodash";
import { LiteralUtils } from "./utils";

type TraverseCallback = (node: ExpressionTreeNode) => void;

export class ExpressionTree {
  private expression: string[];
  private rootNode: ExpressionTreeNode;
  private variableNodes: ExpressionTreeNode[];
  private scalarNodes: ExpressionTreeNode[];

  constructor(postFixExpression: string[]) {
    this.expression = postFixExpression;
    this.variableNodes = [];
    this.scalarNodes = [];
    this.constructTree();
  }

  private constructTree() {
    const stack: ExpressionTreeNode[] = [];
    let t1: ExpressionTreeNode;
    let t2: ExpressionTreeNode;
    let root: ExpressionTreeNode;

    this.expression.forEach(expr => {
      const isOperator = LiteralUtils.isOperator(expr);
      const isScalar = LiteralUtils.isNumber(expr);

      if (!isOperator) {
        const type: ExpressionNodeType = isScalar ? "scalar" : "variable";
        const scalar = isScalar ? parseInt(expr, 10) : null;
        const variable = !isScalar ? expr : null;
        root = new ExpressionTreeNode(type, scalar, null, variable);

        isScalar && this.scalarNodes.push(root);
        !isScalar && this.variableNodes.push(root);

        stack.push(root);
      } else {
        root = new ExpressionTreeNode("operator", null, expr as ExpressionNodeOperators, null);

        t1 = stack.pop();
        t2 = stack.pop();

        root.addLeft(t2);
        root.addRight(t1);

        stack.push(root);
      }
    });

    this.rootNode = stack.pop();
  }

  traverse(callback: TraverseCallback) {
    this.inorderTraversal(this.rootNode, callback);
  }

  getRootNode() {
    return this.rootNode;
  }

  getScalarNodes() {
    return this.scalarNodes;
  }

  getVariableNodes() {
    return this.variableNodes;
  }

  getVariableNodesByVariable() {
    return groupBy(this.variableNodes, node => node.getVariable());
  }

  private inorderTraversal(node: ExpressionTreeNode, callback: TraverseCallback) {
    if (node == null) {
      return;
    }

    const left = node.getLeft();
    const right = node.getRight();

    this.inorderTraversal(left, callback);
    callback(node);
    this.inorderTraversal(right, callback);
  }
}

export class ExpressionTreeNode {
  private type: ExpressionNodeType;
  private scalar: number;
  private operator: ExpressionNodeOperators;
  private variable: string;
  private left: ExpressionTreeNode;
  private right: ExpressionTreeNode;

  constructor(type: ExpressionNodeType, scalar: number, operator: ExpressionNodeOperators, variable: string) {
    this.type = type;
    this.scalar = scalar;
    this.operator = operator;
    this.variable = variable;
  }

  isVariableNode() {
    return this.type === "variable";
  }

  isScalarNode() {
    return this.type === "scalar";
  }

  isOperatorNode() {
    return this.type === "operator";
  }

  getOperator() {
    if (this.isOperatorNode()) {
      return this.operator;
    }
    throw Error(`${this.type} node doesn't have operator`);
  }

  getScalar() {
    if (this.isScalarNode()) {
      return this.scalar;
    }
    throw Error(`${this.type} node doesn't have scalar`);
  }

  getVariable() {
    if (this.isVariableNode()) {
      return this.variable;
    }
    throw Error(`${this.type} node doesn't have variable`);
  }

  getLeft() {
    return this.left;
  }

  getRight() {
    return this.right;
  }

  addRight(node: ExpressionTreeNode) {
    this.right = node;
  }

  addLeft(node: ExpressionTreeNode) {
    this.left = node;
  }
}

export type ExpressionNodeType = "scalar" | "operator" | "variable";
export type ExpressionNodeOperators = "+" | "-" | "*" | "/";
