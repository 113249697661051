import { cloneDeep, random } from "lodash";
import { dateTime } from "../core";
import { PrimType, TypeObjectValKeys } from "../services/api/operationalise";

export const getTypedObjValueKeyBasedOnType = (type: PrimType): TypeObjectValKeys => {
  switch (type) {
    case "_str": {
      return "stringVal";
    }
    case "_bool": {
      return "booleanVal";
    }
    case "_date": {
      return "dateVal";
    }
    case "_datetime": {
      return "dateTimeVal";
    }
    case "_double": {
      return "doubleVal";
    }
    case "_long": {
      return "longVal";
    }
    case "_map": {
      return "mapValue";
    }
    case "_set": {
      return "setValue";
    }

    default:
      return null;
  }
};

export const getTypeFromPrimType = (type: PrimType) => {
  switch (type) {
    case "_str":
      return "string";
    case "_datetime":
      return "string";
    case "_date":
      return "string";
    case "_bool":
      return "boolean";
    case "_double":
      return "integer";
    case "_long":
      return "integer";
    case "_map":
    case "_set":
    case "_objectmap":
      return "object";
    default:
      return "string";
  }
};

export const getUpdatedAnomalyDetectionMockJson = (
  violationCount: number,
  boundType: string,
  jsonPayload: any,
  seriesName?: string
) => {
  const payload = cloneDeep(jsonPayload);

  const series = [...jsonPayload.series];
  const dataPointsLength = violationCount * 4;
  series[0].data = [[]];
  series[1].data = [[]];
  series[2].data = [[]];

  series[0].name = seriesName || series[0].label;

  const startTime = dateTime().subtract(dataPointsLength, "minutes");
  const baseValue = 100;
  payload.options.xAxis.max = dateTime().unix();
  payload.options.xAxis.min = dateTime(startTime).unix();

  for (let i = 0; i < dataPointsLength; i++) {
    series[0].data[i] = [];
    series[1].data[i] = [];
    series[2].data[i] = [];
    const timeSecs = dateTime(startTime).add(i, "minutes").unix();
    series[0].data[i][0] = timeSecs;
    series[1].data[i][0] = timeSecs;
    series[2].data[i][0] = timeSecs;

    const confidentBandVal1 = random(baseValue - 5, baseValue - 10, true);
    const confidentBandVal2 = random(baseValue + 5, baseValue + 10, true);

    series[2].data[i][1] = confidentBandVal1;
    series[2].data[i][2] = confidentBandVal2;

    const point = random(baseValue, baseValue + 5, true);
    series[0].data[i][1] = point;

    let anomPoint = 0;

    const posAnomPoint = random(baseValue + 20, baseValue + 15, true);
    const negAnomPoint = random(baseValue - 20, baseValue - 15, true);

    if (violationCount >= 3) {
      if (
        i >= Number(dataPointsLength / 5) * 1 &&
        i <= Number(dataPointsLength / 5) * 1 + Number(dataPointsLength / 5) + 1
      ) {
        anomPoint = negAnomPoint;
        series[0].data[i][1] = anomPoint;

        if (boundType === "above") {
          series[1].data[i][1] = null;
        } else if (boundType === "below") {
          series[1].data[i][1] = anomPoint;
        } else {
          series[1].data[i][1] = anomPoint;
        }
      } else if (
        i >= Number(dataPointsLength / 5) * 3 &&
        i <= Number(dataPointsLength / 5) * 3 + Number(dataPointsLength / 5) + 1
      ) {
        anomPoint = posAnomPoint;
        series[0].data[i][1] = anomPoint;

        if (boundType === "above") {
          series[1].data[i][1] = anomPoint;
        } else if (boundType === "below") {
          series[1].data[i][1] = null;
        } else {
          series[1].data[i][1] = anomPoint;
        }
      } else {
        series[1].data[i][1] = null;
      }
    } else {
      if (i >= Number(dataPointsLength / 4) && i <= Number((dataPointsLength / 2) * 1.5)) {
        let anomPoint = 0;

        const posAnomPoint = random(baseValue + 15, baseValue + 10, true);
        const negAnomPoint = random(baseValue - 15, baseValue - 10, true);

        if (boundType === "above") {
          anomPoint = posAnomPoint;
        } else if (boundType === "below") {
          anomPoint = negAnomPoint;
        } else {
          const bool = random(1, 10, false) % 2 === 0;
          anomPoint = bool ? posAnomPoint : negAnomPoint;
        }

        series[1].data[i][1] = anomPoint;
        series[0].data[i][1] = anomPoint;
      } else {
        series[1].data[i][1] = null;
      }
    }
  }

  payload.series = series;
  // console.log('payload is ', series);
  return payload;
};
