/* eslint-disable @typescript-eslint/no-namespace */
import { BizDataQuery, TimeObj } from "../../explore";
import { OpSchedule, WhatsNewConfig } from "./CommonTypesV2";
import { SuppressionConfigDef } from "./suppressions";
import { OpThreshold } from "./thresholds";
import { OpStartTrigger } from "./triggers";

export namespace UberOperationalizeTypes {
  export interface Override {
    kpiId: string;
    threshold: OpThreshold;
    trigger: OpStartTrigger;
    suppression: SuppressionConfigDef[];
    schedule: OpSchedule;
    skipOverride: boolean;
    whatsNewConfig: WhatsNewConfig;
  }

  export interface OpBizDataQuery {
    bizDataQuery: BizDataQuery;

    /**
     * @deprecated Handled by backend
     */
    rollingFunction: string;

    /**
     * @deprecated Handled by backend
     */
    rollingFreq: TimeObj;
  }
}
