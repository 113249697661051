import { IncSelectOption } from "@inception/ui";

export const timePeriodOptions: IncSelectOption[] = [
  {
    label: "1m",
    value: "60"
  },
  {
    label: "2m",
    value: "120"
  },
  {
    label: "3m",
    value: "180"
  },
  {
    label: "5m",
    value: "300"
  },
  {
    label: "10m",
    value: "600"
  },
  {
    label: "15m",
    value: "900"
  },
  {
    label: "30m",
    value: "1800"
  },
  {
    label: "1h",
    value: "3600"
  },
  {
    label: "24h",
    value: "86400"
  },
  {
    label: "1w",
    value: "604800"
  }
];

export const seasonalityOptions: IncSelectOption[] = [
  {
    label: "Hourly",
    value: "3600"
  },
  {
    label: "Daily",
    value: "86400"
  },
  {
    label: "Weekly",
    value: "604800"
  }
];
