import { castArray, isEmpty } from "lodash";

export const EXCLUDE_TAGS: string[] = ["_apptuit_named_query", "__apptuit__metric", "__apptuit__mq", "__apptuit_ts"];
export const QUANTILE_TAG = "quantile";
export const NAME_TAG = "__name__";

export const shouldExcludeTag = (tag: string, excludeNameTag?: boolean) => {
  const excludeRegEx = /^(__apptuit__|__inception__|_inception_).*$/g;
  const excludeTags = [...EXCLUDE_TAGS];
  excludeNameTag && excludeTags.push(NAME_TAG);

  return !isEmpty(tag) && (excludeTags.includes(tag) || excludeRegEx.test(tag));
};

export const DataTypeWithDefault = {
  /**
   * Takes a input and if its not empty return it else fallsback to default value
   * @param val
   * @param defaultVal
   */
  getStr: function (val: string | number, defaultVal: string = null): string {
    return !isEmpty(val) ? val.toString() : defaultVal;
  },

  getStrArr: function (val: string | string[], defaultVal: string[] = []): string[] {
    return !isEmpty(val) ? (castArray(val) as string[]) : defaultVal;
  },

  getTypedArr: function <T>(val: T | T[], defaultVal: T[] = []): T[] {
    return !isEmpty(val) ? (castArray(val) as T[]) : defaultVal;
  }
};

export const getSafeNumber = (num: number, safeDefault = 0) => {
  const safeNum = parseInt(num.toString(), 10);
  return isNaN(safeNum) ? safeDefault : safeNum;
};

export const getChangePercent = (value: number, timeShiftValue: number) => {
  const changeValue = Math.abs(timeShiftValue - value);
  const changePercent = (changeValue / timeShiftValue) * 100;

  const changePercentStr = isNaN(changePercent) ? "" : changePercent.toFixed(2);

  return {
    changePercent,
    changePercentStr
  };
};

function jsonToCsv(json: Array<Record<string, any>>) {
  const keys = Object.keys(json[0]);
  const csvRows = [];

  // Add headers
  csvRows.push(keys.join(","));

  // Add rows
  json.forEach(obj => {
    const values = keys.map(key => {
      if (obj[key]) {
        const escapedValue = obj[key].toString().replace(/"/g, '\\"');
        return `"${escapedValue}"`; // Enclose values in double quotes
      }
      return "";
    });
    csvRows.push(values.join(","));
  });

  return csvRows.join("\n");
}

export const downloadCSVFromJsonRecords = (jsonList: Array<Record<string, any>>, fileName?: string) => {
  const csvString = jsonToCsv(jsonList);
  const blob = new Blob([csvString], { type: "text/csv" });

  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName ? `${fileName}.csv` : "data.csv";
  a.click();

  URL.revokeObjectURL(url);
};

export const updateSvgColor = (svgString: string, newColor: string) => {
  // Parse the SVG string into a DOM object
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgString, "image/svg+xml");

  // Get all path elements (you can also target other elements like rect, circle, etc.)
  const paths = svgDoc.querySelectorAll("path");

  // Update the color of each path element
  paths.forEach(path => {
    path.setAttribute("fill", newColor);
  });

  // Serialize the updated SVG back to a string
  const serializer = new XMLSerializer();
  const updatedSvgString = serializer.serializeToString(svgDoc);

  return updatedSvgString;
};
