import { createAction } from "@reduxjs/toolkit";
import { Org } from "../../core";
import { AuthErrorState, AuthUserState, AuthValidateState } from "./types";

export const AUTH_CHECK_ERROR = "auth/check/error";
export const AUTH_SUCCESS = "auth/success";
export const AUTH_ERROR = "auth/error";
export const AUTH_USER = "auth/user";
export const AUTH_USER_LOGOUT = "auth/user/logout";
export const AUTH_VALIDATE = "auth/validate";
export const AUTH_UPDATE_ORGS = "auth/update/orgs";

export const AuthCheckError = createAction<AuthErrorState>(AUTH_CHECK_ERROR);
export const AuthValidate = createAction<AuthValidateState>(AUTH_VALIDATE);
export const AuthSuccess = createAction<AuthUserState>(AUTH_SUCCESS);
export const AuthLogout = createAction(AUTH_USER_LOGOUT);
export const AuthError = createAction<AuthErrorState>(AUTH_ERROR);
export const AuthUpdateOrgs = createAction<Org[]>(AUTH_UPDATE_ORGS);
