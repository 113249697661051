export type StringOperation =
  | "eq"
  | "contains"
  | "regex"
  | "startsWith"
  | "endsWith"
  | "in"
  | "neq"
  | "exists"
  | "doesNotExist"
  | "notContains";
export type NumberOperation = "lt" | "gt" | "neq" | "ge" | "le" | "eq" | "exists" | "doesNotExist";
export type ArrayOperation = "contains" | "isEmpty" | "isNotEmpty" | "exists" | "doesNotExist" | "notContains";
export type ObjectOperation = "isNull" | "isNotNull" | "exists" | "doesNotExist";
export type BooleanOperation = "eq" | "neq" | "exists" | "doesNotExist";
export type DateTimeOperation = "eq" | "lt" | "gt" | "range" | "le" | "ge" | "neq" | "exists" | "doesNotExist";

export type LogicalOperation =
  | StringOperation
  | NumberOperation
  | ArrayOperation
  | ObjectOperation
  | BooleanOperation
  | DateTimeOperation;

export const NO_VALUE_OPERATIONS = ["isNull", "isNotNull", "isEmpty", "isNotEmpty", "exists", "doesNotExist"];
