import React from "react";

type SpacerMargin = 4 | 8 | 12 | 16 | "";

interface Props {
  spacerMargin?: SpacerMargin;
}

const HorizontalSpacer: React.FC<Props> = (props: Props) => {
  const spacerMargin: SpacerMargin = props.spacerMargin || "";
  return <div className={`inc-flex-spacer${spacerMargin}`}></div>;
};

export default React.memo(HorizontalSpacer);
