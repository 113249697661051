import { forEach } from "lodash";
import React, { useMemo } from "react";
import { IncFaIcon, IncFaIconName } from "../../../icons";
import IncSmartText from "../../SmartText/SmartText";

interface RoundCardProps {
  label: string;
  primaryIcon?: IncFaIconName | JSX.Element;
  primaryIconSize?: "small" | "regular" | "large";
  subText?: string;
  secondaryIcons?: Array<JSX.Element | IncFaIconName>;
  background?: string;
}

const RoundCard: React.FC<RoundCardProps> = props => {
  const {
    label,
    primaryIcon,
    subText,
    background = "#7C83FD",
    primaryIconSize = "regular",
    secondaryIcons: pSecondaryIcons
  } = props;

  const secondaryIcons = useMemo(() => {
    const icons: JSX.Element[] = [];
    forEach(pSecondaryIcons, (icon, index) =>
      icons.push(
        <div
          className="marginBt16"
          key={index}
        >
          {typeof icon === "string" ? <IncFaIcon iconName={icon} /> : icon}
        </div>
      )
    );

    return <div className="secondary-icons-container inc-flex-column">{icons}</div>;
  }, [pSecondaryIcons]);

  return (
    <div className="round-card-container">
      <div
        className="round-card"
        style={{ background: background }}
      >
        {primaryIcon && (
          <div className="marginBt8">
            {typeof primaryIcon === "string" ? (
              <IncFaIcon
                className={`icon-${primaryIconSize}`}
                iconName={primaryIcon}
              />
            ) : (
              primaryIcon
            )}
          </div>
        )}
        <div className="inc-text-body-medium label-container">
          <IncSmartText text={label} />
        </div>
        <div className="inc-text-subtext">{subText}</div>
      </div>
      {pSecondaryIcons && secondaryIcons}
    </div>
  );
};

export default RoundCard;
