import cronParser from "cron-parser";
import { range } from "lodash";

export const computeFurtherOccurrencesForCron = (cronExpr: string, numOccurrances = 5) => {
  const parsedExpr = cronParser.parseExpression(cronExpr);

  return range(0, numOccurrances).map(() => {
    const dateIso = parsedExpr.next().toISOString();
    return new Date(dateIso);
  });
};
