import { Trace, generateId as uuid } from "@inception/ui";
import axios, { AxiosResponse, CancelToken, CancelTokenSource } from "axios";
import { cloneDeep, merge } from "lodash";
import { useQuery } from "react-query";
import { useMemo } from "react";
import { DateTime } from "../../../core/moment_wrapper";
import dataSourceApiManager from "../../../services/api/DatasourceApiService";
import { TraceResultParams } from "../../datasources/traces/TracesTypes";
import { queryOptions } from "./constants";
import { constructPayload, trimDollarInstancesFromTraceResponse } from "./transformers";
import { IncStructure, Payload, QueryMode, TraceQueryOptions } from "./types";

type QueryParams = {
  includeSpans?: boolean;
  queryId?: string;
};

type QueryOptions = {
  params?: QueryParams;
  cancelToken?: CancelToken;
};
class TraceApi {
  private getCancelTokenSource(): CancelTokenSource {
    const cancelTokenSource = axios.CancelToken.source();

    // eslint-disable-next-line
    cancelTokenSource.token.throwIfRequested = cancelTokenSource.token.throwIfRequested;
    return cancelTokenSource;
  }

  getUUId(): string {
    return uuid();
  }

  private cancelTokenSourceMap: Map<string, CancelTokenSource> = new Map();

  getTraceDetails(
    traceId: string,
    queryMode: QueryMode,
    startTime: number,
    endTime: number,
    limit?: number
  ): Promise<Trace> {
    // TODO: where to get the time range from
    const cameliseResponse = true;
    const params = {
      timeRange: {
        from: startTime,
        to: endTime
      },
      filters: {},
      aggregations: {
        results: [
          {
            type: "SPAN_FIELDS",
            name: "traceFields",
            params: {
              limit: limit || 10000,
              includeLogs: true
            }
          }
        ]
      }
    } as any;

    if (queryMode === "events") {
      params.datasourceType = queryMode;
    }

    const url = `/${traceId}`;
    return dataSourceApiManager
      .get("traces")
      .post(url, params, {
        camelizeResponse: !cameliseResponse,
        params: queryMode !== "events" ? { includeSpans: true } : {}
      })
      .then(result => trimDollarInstancesFromTraceResponse(result.data) as Trace);
  }

  getSpansForError(errorId: string, fromTime: number, endTime: number): Promise<Trace> {
    const params = {
      timeRange: {
        from: fromTime,
        to: endTime
      },
      filters: {
        type: "string",
        value: `errors.__id__ = "${errorId}"`
      },
      aggregations: {
        results: [
          {
            type: "SPAN_FIELDS",
            name: "traceFields",
            params: {
              limit: 5,
              order: "-,startTime,LONG",
              includeLogs: true
            }
          }
        ]
      }
    };

    const cameliseResponse = true;
    return dataSourceApiManager
      .get("traces")
      .post("", params, {
        camelizeResponse: !cameliseResponse,
        params: { includeSpans: true }
      })
      .then(result => result.data as Trace);
  }

  async getCallerCalleeData(
    errorId: string,
    apiId: string,
    rootApiIds: string[],
    componentVersionId: string,
    fromTime: number,
    endTime: number
  ): Promise<CallerCalledData> {
    // Get the error related data first
    const { caller, called } = await this.getCallerCalleInfoInternal(
      errorId,
      apiId,
      rootApiIds,
      componentVersionId,
      fromTime,
      endTime
    );

    const errorCaller = cloneDeep(caller);
    const errorCalled = cloneDeep(called);

    //Get the non-error related data for same api and rootApi. Pass errorId as null
    const { caller: allCaller, called: allCalled } = await this.getCallerCalleInfoInternal(
      null,
      apiId,
      rootApiIds,
      componentVersionId,
      fromTime,
      endTime
    );

    const mergedCaller = merge(errorCaller, allCaller);
    const mergedCalled = merge(errorCalled, allCalled);

    return {
      caller: mergedCaller,
      called: mergedCalled
    };
  }

  private getCallerCalleInfoInternal(
    errorId: string,
    apiId: string,
    rootApiIds: string[],
    componentVersionId: string,
    fromTime: number,
    endTime: number
  ): Promise<CallerCalledData> {
    let filter = `api.__id__ = '${apiId}' and rootApi.__id__ in ['${rootApiIds.join()}'] and
      serviceInstance.rel:componentVersion.__id__ = '${componentVersionId}'`;

    // if error id is passed add that condition
    if (errorId) {
      filter = `errors.__id__ in ['${errorId}'] and ${filter}`;
    }
    //debugger;
    const query = {
      timeRange: {
        from: fromTime,
        to: endTime
      },
      filters: {
        type: "string",
        value: filter
      },
      aggregations: {
        kind: {
          fromField: "spanKind",
          type: "term",
          aggregations: {
            caller: {
              fromField: "callerApi",
              type: "term",
              results: [
                {
                  type: "METRICS",
                  name: "caller_count",
                  metricType: "count",
                  params: {}
                }
              ]
            },
            called: {
              fromField: "calledApi",
              type: "term",
              results: [
                {
                  type: "METRICS",
                  name: "called_count",
                  metricType: "count",
                  params: {}
                }
              ]
            }
          }
        }
      }
    };

    const cameliseResponse = true;
    return dataSourceApiManager
      .get("traces")
      .post("", query, {
        camelizeResponse: !cameliseResponse,
        params: { includeSpans: true }
      })
      .then((res: any) => {
        const result = res.data as CallerCalledResult;
        //debugger;
        const calledList: CallerCalleeApiData[] = [];
        const callerList: CallerCalleeApiData[] = [];
        //debugger;
        // Called related data
        if (result.called_count && result.called_count.status === "success") {
          result.called_count.data.result.forEach(data => {
            const apiId = data.metric.called;
            const value = data.value[1];
            if (apiId !== "__missing__") {
              // If we have passed errorId, set the value for numErrors
              if (errorId) {
                calledList.push({
                  id: apiId,
                  numCallsWithError: parseInt(value, 10)
                });
              } else {
                // set the value for total
                calledList.push({
                  id: apiId,
                  numCallsWithoutError: parseInt(value, 10)
                });
              }
            }
          });
        }

        // Caller related data
        if (result.caller_count && result.caller_count.status === "success") {
          result.caller_count.data.result.forEach(data => {
            const apiId = data.metric.caller;
            const value = data.value[1];
            if (apiId !== "__missing__") {
              if (errorId) {
                callerList.push({
                  id: apiId,
                  numCallsWithError: parseInt(value, 10)
                });
              } else {
                callerList.push({
                  id: apiId,
                  numCallsWithoutError: parseInt(value, 10)
                });
              }
            }
          });
        }

        return {
          called: calledList,
          caller: callerList
        };
      });
  }

  getTraceFields(params: Payload, uuId?: string): Promise<any> {
    const { mode } = params;
    let queryParams: QueryParams = {};
    let options: QueryOptions = {};

    if (mode) {
      queryParams = mode === "spans" ? { includeSpans: true } : {};
      delete params.mode;
    }

    options = {
      params: queryParams
    };

    const url = ``;
    if (uuId) {
      const cancelTokenSource = this.getCancelTokenSource();

      params.queryId = uuId;
      options.cancelToken = cancelTokenSource.token;
      this.cancelTokenSourceMap.set(uuId, cancelTokenSource);
    }

    return dataSourceApiManager
      .get("traces")
      .post(url, params, options)
      .then((response: AxiosResponse) => {
        const { queryId } = JSON.parse(response?.config?.data || "{}");

        if (queryId && this.cancelTokenSourceMap.has(queryId)) {
          this.cancelTokenSourceMap.delete(queryId);
        }
        return trimDollarInstancesFromTraceResponse(response.data);
      });
  }

  getTracefieldsMultiple(promises: any): Promise<any> {
    return Promise.all([...promises]);
  }

  async getTraceStructure(
    from: DateTime,
    to: DateTime,
    mode: QueryMode,
    query = "",
    uuid?: string,
    params?: TraceResultParams
  ): Promise<IncStructure> {
    const structParams = constructPayload({
      type: "structure",
      from,
      to,
      mode,
      query,
      params
    });
    const incStruct = await this.getTraceFields(structParams, uuid);
    if (params?.configTypes && incStruct) {
      // structure query does not apply config type filter on global fields
      incStruct.structure.globalFields = [];
    }
    this.removeMetaFields(incStruct);
    return incStruct;
  }

  cancelRequest(uuId: string) {
    if (this.cancelTokenSourceMap.has(uuId)) {
      const cancelTokenSource = this.cancelTokenSourceMap.get(uuId);

      cancelTokenSource.cancel(`cancelled`);
      this.cancelTokenSourceMap.delete(uuId);
    }
  }

  cancel(uuId: string | string[]) {
    if (!uuId || !uuId.length) {
      return;
    }
    if (Array.isArray(uuId)) {
      const cancelPromises: Array<Promise<any>> = [];

      uuId.forEach(id => {
        this.cancelRequest(id);
        cancelPromises.push(dataSourceApiManager.get("traces").cancelQuery(id));
      });
      return Promise.all(cancelPromises);
    }
    this.cancelRequest(uuId);
    return dataSourceApiManager.get("traces").cancelQuery(uuId);
  }

  private removeMetaFields(incStruct: IncStructure) {
    /**
     * Sometimes, the structure query returns META types.
     * These should be omitted.
     */
    if (incStruct?.structure?.fields) {
      incStruct.structure.fields = incStruct.structure.fields.filter(field => !field?.type?.endsWith("ENTITY_META"));
    }
  }
}

const traceApiInstance = new TraceApi();

const useTraceStructure = (from: DateTime, to: DateTime, traceQueryOptions: TraceQueryOptions) => {
  const { mode, configType } = traceQueryOptions;
  const params: TraceResultParams = configType ? { configTypes: configType } : null;
  const getStructure = async () => await traceApiInstance.getTraceStructure(from, to, mode, "", null, params);
  const queryKey = useMemo(() => (from && to ? uuid() : null), [from, to]);

  const { data, isError, isFetching, isSuccess } = useQuery<IncStructure>(queryKey, getStructure, queryOptions);
  return {
    traceStructure: data,
    isError,
    isFetching,
    isSuccess
  };
};

export { traceApiInstance as traceApi, useTraceStructure };

type CallerCalledResult = {
  called_count: {
    status: string;
    data: {
      result: Array<{
        metric: {
          called: string;
        };
        value: string[];
      }>;
    };
  };
  caller_count: {
    status: string;
    data: {
      result: Array<{
        metric: {
          caller: string;
        };
        value: string[];
      }>;
    };
  };
};

export type CallerCalledData = {
  caller: CallerCalleeApiData[];
  called: CallerCalleeApiData[];
};

export type CallerCalleeApiData = {
  id: string;
  numCallsWithoutError?: number;
  numCallsWithError?: number;
};
