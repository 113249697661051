const iconNameToSpriteIdMap = {
  "0": "0",
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "100": "hundred-points",
  "fill-drip": "fill-drip",
  "arrows-to-circle": "arrows-to-circle",
  "circle-chevron-right": "circle-chevron-right",
  "chevron-circle-right": "circle-chevron-right",
  "wagon-covered": "wagon-covered",
  "line-height": "line-height",
  bagel: "bagel",
  "transporter-7": "transporter-7",
  at: "at",
  "rectangles-mixed": "rectangles-mixed",
  "phone-arrow-up-right": "phone-arrow-up-right",
  "phone-arrow-up": "phone-arrow-up-right",
  "phone-outgoing": "phone-arrow-up-right",
  "trash-can": "trash-can",
  "trash-alt": "trash-can",
  "circle-l": "circle-l",
  "head-side-goggles": "head-side-goggles",
  "head-vr": "head-side-goggles",
  "text-height": "text-height",
  "user-xmark": "user-xmark",
  "user-times": "user-xmark",
  "face-hand-yawn": "face-hand-yawn",
  "gauge-simple-min": "gauge-simple-min",
  "tachometer-slowest": "gauge-simple-min",
  stethoscope: "stethoscope",
  coffin: "coffin",
  message: "message",
  "comment-alt": "message",
  salad: "salad",
  "bowl-salad": "salad",
  info: "info",
  "robot-astromech": "robot-astromech",
  "ring-diamond": "ring-diamond",
  "fondue-pot": "fondue-pot",
  theta: "theta",
  "face-hand-peeking": "face-hand-peeking",
  "square-user": "square-user",
  "down-left-and-up-right-to-center": "down-left-and-up-right-to-center",
  "compress-alt": "down-left-and-up-right-to-center",
  explosion: "explosion",
  "file-lines": "file-lines",
  "file-alt": "file-lines",
  "file-text": "file-lines",
  "wave-square": "wave-square",
  ring: "ring",
  "building-un": "building-un",
  "dice-three": "dice-three",
  "tire-pressure-warning": "tire-pressure-warning",
  "wifi-fair": "wifi-fair",
  "wifi-2": "wifi-fair",
  "calendar-days": "calendar-days",
  "calendar-alt": "calendar-days",
  "mp3-player": "mp3-player",
  "anchor-circle-check": "anchor-circle-check",
  "tally-4": "tally-4",
  "rectangle-history": "rectangle-history",
  "building-circle-arrow-right": "building-circle-arrow-right",
  volleyball: "volleyball",
  "volleyball-ball": "volleyball",
  "sun-haze": "sun-haze",
  "text-size": "text-size",
  ufo: "ufo",
  fork: "fork",
  "utensil-fork": "fork",
  "arrows-up-to-line": "arrows-up-to-line",
  "mobile-signal": "mobile-signal",
  "barcode-scan": "barcode-scan",
  "sort-down": "sort-down",
  "sort-desc": "sort-down",
  "folder-arrow-down": "folder-arrow-down",
  "folder-download": "folder-arrow-down",
  "circle-minus": "circle-minus",
  "minus-circle": "circle-minus",
  "face-icicles": "face-icicles",
  shovel: "shovel",
  "door-open": "door-open",
  films: "films",
  "right-from-bracket": "right-from-bracket",
  "sign-out-alt": "right-from-bracket",
  "face-glasses": "face-glasses",
  nfc: "nfc",
  atom: "atom",
  soap: "soap",
  icons: "icons",
  "heart-music-camera-bolt": "icons",
  "microphone-lines-slash": "microphone-lines-slash",
  "microphone-alt-slash": "microphone-lines-slash",
  "closed-captioning-slash": "closed-captioning-slash",
  "calculator-simple": "calculator-simple",
  "calculator-alt": "calculator-simple",
  "bridge-circle-check": "bridge-circle-check",
  "sliders-up": "sliders-up",
  "sliders-v": "sliders-up",
  "location-minus": "location-minus",
  "map-marker-minus": "location-minus",
  "pump-medical": "pump-medical",
  fingerprint: "fingerprint",
  "ski-boot": "ski-boot",
  "standard-definition": "standard-definition",
  "rectangle-sd": "standard-definition",
  h1: "h1",
  "hand-point-right": "hand-point-right",
  "magnifying-glass-location": "magnifying-glass-location",
  "search-location": "magnifying-glass-location",
  "message-bot": "message-bot",
  "forward-step": "forward-step",
  "step-forward": "forward-step",
  "face-smile-beam": "face-smile-beam",
  "smile-beam": "face-smile-beam",
  "light-ceiling": "light-ceiling",
  "message-exclamation": "message-exclamation",
  "comment-alt-exclamation": "message-exclamation",
  "bowl-scoop": "bowl-scoop",
  "bowl-shaved-ice": "bowl-scoop",
  "square-x": "square-x",
  "building-memo": "building-memo",
  "utility-pole-double": "utility-pole-double",
  "flag-checkered": "flag-checkered",
  "chevrons-up": "chevrons-up",
  "chevron-double-up": "chevrons-up",
  football: "football",
  "football-ball": "football",
  "user-vneck": "user-vneck",
  "school-circle-exclamation": "school-circle-exclamation",
  crop: "crop",
  "angles-down": "angles-down",
  "angle-double-down": "angles-down",
  "users-rectangle": "users-rectangle",
  "people-roof": "people-roof",
  "square-arrow-right": "square-arrow-right",
  "arrow-square-right": "square-arrow-right",
  "location-plus": "location-plus",
  "map-marker-plus": "location-plus",
  "lightbulb-exclamation-on": "lightbulb-exclamation-on",
  "people-line": "people-line",
  "beer-mug-empty": "beer-mug-empty",
  beer: "beer-mug-empty",
  "crate-empty": "crate-empty",
  "diagram-predecessor": "diagram-predecessor",
  transporter: "transporter",
  "calendar-circle-user": "calendar-circle-user",
  "arrow-up-long": "arrow-up-long",
  "long-arrow-up": "arrow-up-long",
  "person-carry-box": "person-carry-box",
  "person-carry": "person-carry-box",
  "fire-flame-simple": "fire-flame-simple",
  burn: "fire-flame-simple",
  person: "person",
  male: "person",
  laptop: "laptop",
  "file-csv": "file-csv",
  menorah: "menorah",
  union: "union",
  "chevrons-left": "chevrons-left",
  "chevron-double-left": "chevrons-left",
  "circle-heart": "circle-heart",
  "heart-circle": "circle-heart",
  "truck-plane": "truck-plane",
  "record-vinyl": "record-vinyl",
  "bring-forward": "bring-forward",
  "square-p": "square-p",
  "face-grin-stars": "face-grin-stars",
  "grin-stars": "face-grin-stars",
  sigma: "sigma",
  "camera-movie": "camera-movie",
  bong: "bong",
  clarinet: "clarinet",
  "truck-flatbed": "truck-flatbed",
  "spaghetti-monster-flying": "spaghetti-monster-flying",
  pastafarianism: "spaghetti-monster-flying",
  "arrow-down-up-across-line": "arrow-down-up-across-line",
  "arrows-rotate-reverse": "arrows-rotate-reverse",
  "leaf-heart": "leaf-heart",
  "house-building": "house-building",
  "cheese-swiss": "cheese-swiss",
  spoon: "spoon",
  "utensil-spoon": "spoon",
  "jar-wheat": "jar-wheat",
  "envelopes-bulk": "envelopes-bulk",
  "mail-bulk": "envelopes-bulk",
  "file-circle-exclamation": "file-circle-exclamation",
  "bow-arrow": "bow-arrow",
  "cart-xmark": "cart-xmark",
  "hexagon-xmark": "hexagon-xmark",
  "times-hexagon": "hexagon-xmark",
  "xmark-hexagon": "hexagon-xmark",
  "circle-h": "circle-h",
  "hospital-symbol": "circle-h",
  merge: "merge",
  pager: "pager",
  "cart-minus": "cart-minus",
  "address-book": "address-book",
  "contact-book": "address-book",
  "pan-frying": "pan-frying",
  grid: "grid",
  "grid-3": "grid",
  "football-helmet": "football-helmet",
  "hand-love": "hand-love",
  trees: "trees",
  strikethrough: "strikethrough",
  page: "page",
  k: "k",
  "diagram-previous": "diagram-previous",
  "gauge-min": "gauge-min",
  "tachometer-alt-slowest": "gauge-min",
  "folder-grid": "folder-grid",
  eggplant: "eggplant",
  excavator: "excavator",
  ram: "ram",
  "landmark-flag": "landmark-flag",
  lips: "lips",
  pencil: "pencil",
  "pencil-alt": "pencil",
  backward: "backward",
  "caret-right": "caret-right",
  comments: "comments",
  paste: "paste",
  "file-clipboard": "paste",
  "desktop-arrow-down": "desktop-arrow-down",
  "code-pull-request": "code-pull-request",
  pumpkin: "pumpkin",
  "clipboard-list": "clipboard-list",
  "pen-field": "pen-field",
  blueberries: "blueberries",
  "truck-ramp-box": "truck-ramp-box",
  "truck-loading": "truck-ramp-box",
  note: "note",
  "arrow-down-to-square": "arrow-down-to-square",
  "user-check": "user-check",
  "cloud-xmark": "cloud-xmark",
  "vial-virus": "vial-virus",
  "book-blank": "book-blank",
  "book-alt": "book-blank",
  "golf-flag-hole": "golf-flag-hole",
  "message-arrow-down": "message-arrow-down",
  "comment-alt-arrow-down": "message-arrow-down",
  "face-unamused": "face-unamused",
  "sheet-plastic": "sheet-plastic",
  "circle-9": "circle-9",
  blog: "blog",
  "user-ninja": "user-ninja",
  "pencil-slash": "pencil-slash",
  "bowling-pins": "bowling-pins",
  "person-arrow-up-from-line": "person-arrow-up-from-line",
  "down-right": "down-right",
  "scroll-torah": "scroll-torah",
  torah: "scroll-torah",
  webhook: "webhook",
  "blinds-open": "blinds-open",
  fence: "fence",
  up: "up",
  "arrow-alt-up": "up",
  "broom-ball": "broom-ball",
  quidditch: "broom-ball",
  "quidditch-broom-ball": "broom-ball",
  drumstick: "drumstick",
  "square-v": "square-v",
  "face-awesome": "face-awesome",
  "gave-dandy": "face-awesome",
  "dial-off": "dial-off",
  "toggle-off": "toggle-off",
  "face-smile-horns": "face-smile-horns",
  "box-archive": "box-archive",
  archive: "box-archive",
  grapes: "grapes",
  "person-drowning": "person-drowning",
  "dial-max": "dial-max",
  "circle-m": "circle-m",
  "calendar-image": "calendar-image",
  "circle-caret-down": "circle-caret-down",
  "caret-circle-down": "circle-caret-down",
  "arrow-down-9-1": "arrow-down-9-1",
  "sort-numeric-desc": "arrow-down-9-1",
  "sort-numeric-down-alt": "arrow-down-9-1",
  "face-grin-tongue-squint": "face-grin-tongue-squint",
  "grin-tongue-squint": "face-grin-tongue-squint",
  "shish-kebab": "shish-kebab",
  "spray-can": "spray-can",
  "alarm-snooze": "alarm-snooze",
  scarecrow: "scarecrow",
  "truck-monster": "truck-monster",
  "gift-card": "gift-card",
  w: "w",
  "code-pull-request-draft": "code-pull-request-draft",
  "square-b": "square-b",
  elephant: "elephant",
  "earth-africa": "earth-africa",
  "globe-africa": "earth-africa",
  rainbow: "rainbow",
  "circle-notch": "circle-notch",
  "tablet-screen-button": "tablet-screen-button",
  "tablet-alt": "tablet-screen-button",
  paw: "paw",
  "message-question": "message-question",
  cloud: "cloud",
  "trowel-bricks": "trowel-bricks",
  "square-3": "square-3",
  "face-flushed": "face-flushed",
  flushed: "face-flushed",
  "hospital-user": "hospital-user",
  microwave: "microwave",
  "chf-sign": "chf-sign",
  "tent-arrow-left-right": "tent-arrow-left-right",
  "cart-circle-arrow-up": "cart-circle-arrow-up",
  "trash-clock": "trash-clock",
  "reflect-both": "reflect-both",
  gavel: "gavel",
  legal: "gavel",
  "sprinkler-ceiling": "sprinkler-ceiling",
  browsers: "browsers",
  trillium: "trillium",
  "music-slash": "music-slash",
  "truck-ramp": "truck-ramp",
  binoculars: "binoculars",
  "microphone-slash": "microphone-slash",
  "box-tissue": "box-tissue",
  "circle-c": "circle-c",
  "star-christmas": "star-christmas",
  "chart-bullet": "chart-bullet",
  motorcycle: "motorcycle",
  "tree-christmas": "tree-christmas",
  "tire-flat": "tire-flat",
  sunglasses: "sunglasses",
  badge: "badge",
  "message-pen": "message-pen",
  "comment-alt-edit": "message-pen",
  "message-edit": "message-pen",
  "bell-concierge": "bell-concierge",
  "concierge-bell": "bell-concierge",
  "pen-ruler": "pen-ruler",
  "pencil-ruler": "pen-ruler",
  "file-mp3": "file-mp3",
  "arrow-progress": "arrow-progress",
  "chess-rook-piece": "chess-rook-piece",
  "chess-rook-alt": "chess-rook-piece",
  "square-root": "square-root",
  "album-collection-circle-plus": "album-collection-circle-plus",
  "people-arrows": "people-arrows",
  "people-arrows-left-right": "people-arrows",
  "sign-post": "sign-post",
  "face-angry-horns": "face-angry-horns",
  "mars-and-venus-burst": "mars-and-venus-burst",
  tombstone: "tombstone",
  "square-caret-right": "square-caret-right",
  "caret-square-right": "square-caret-right",
  scissors: "scissors",
  cut: "scissors",
  "list-music": "list-music",
  "sun-plant-wilt": "sun-plant-wilt",
  "toilets-portable": "toilets-portable",
  "hockey-puck": "hockey-puck",
  mustache: "mustache",
  hyphen: "hyphen",
  table: "table",
  "user-chef": "user-chef",
  "message-image": "message-image",
  "comment-alt-image": "message-image",
  "users-medical": "users-medical",
  "sensor-triangle-exclamation": "sensor-triangle-exclamation",
  "sensor-alert": "sensor-triangle-exclamation",
  "magnifying-glass-arrow-right": "magnifying-glass-arrow-right",
  "tachograph-digital": "tachograph-digital",
  "digital-tachograph": "tachograph-digital",
  "face-mask": "face-mask",
  pickleball: "pickleball",
  "star-sharp-half": "star-sharp-half",
  "users-slash": "users-slash",
  clover: "clover",
  meat: "meat",
  reply: "reply",
  "mail-reply": "reply",
  "star-and-crescent": "star-and-crescent",
  "empty-set": "empty-set",
  "house-fire": "house-fire",
  "square-minus": "square-minus",
  "minus-square": "square-minus",
  helicopter: "helicopter",
  bird: "bird",
  compass: "compass",
  "square-caret-down": "square-caret-down",
  "caret-square-down": "square-caret-down",
  "heart-half-stroke": "heart-half-stroke",
  "heart-half-alt": "heart-half-stroke",
  "file-circle-question": "file-circle-question",
  "truck-utensils": "truck-utensils",
  "laptop-code": "laptop-code",
  joystick: "joystick",
  "grill-fire": "grill-fire",
  "rectangle-vertical-history": "rectangle-vertical-history",
  swatchbook: "swatchbook",
  "prescription-bottle": "prescription-bottle",
  bars: "bars",
  navicon: "bars",
  "keyboard-left": "keyboard-left",
  "people-group": "people-group",
  "hourglass-end": "hourglass-end",
  "hourglass-3": "hourglass-end",
  "heart-crack": "heart-crack",
  "heart-broken": "heart-crack",
  "face-beam-hand-over-mouth": "face-beam-hand-over-mouth",
  "droplet-percent": "droplet-percent",
  humidity: "droplet-percent",
  "square-up-right": "square-up-right",
  "external-link-square-alt": "square-up-right",
  "face-kiss-beam": "face-kiss-beam",
  "kiss-beam": "face-kiss-beam",
  corn: "corn",
  "roller-coaster": "roller-coaster",
  "photo-film-music": "photo-film-music",
  radar: "radar",
  sickle: "sickle",
  film: "film",
  coconut: "coconut",
  "ruler-horizontal": "ruler-horizontal",
  "shield-cross": "shield-cross",
  "cassette-tape": "cassette-tape",
  "square-terminal": "square-terminal",
  "people-robbery": "people-robbery",
  lightbulb: "lightbulb",
  "caret-left": "caret-left",
  "comment-middle": "comment-middle",
  "trash-can-list": "trash-can-list",
  block: "block",
  "circle-exclamation": "circle-exclamation",
  "exclamation-circle": "circle-exclamation",
  "school-circle-xmark": "school-circle-xmark",
  "arrow-right-from-bracket": "arrow-right-from-bracket",
  "sign-out": "arrow-right-from-bracket",
  "face-frown-slight": "face-frown-slight",
  "circle-chevron-down": "circle-chevron-down",
  "chevron-circle-down": "circle-chevron-down",
  "sidebar-flip": "sidebar-flip",
  "unlock-keyhole": "unlock-keyhole",
  "unlock-alt": "unlock-keyhole",
  "temperature-list": "temperature-list",
  "cloud-showers-heavy": "cloud-showers-heavy",
  "headphones-simple": "headphones-simple",
  "headphones-alt": "headphones-simple",
  sitemap: "sitemap",
  "pipe-section": "pipe-section",
  "space-station-moon-construction": "space-station-moon-construction",
  "space-station-moon-alt": "space-station-moon-construction",
  "circle-dollar-to-slot": "circle-dollar-to-slot",
  donate: "circle-dollar-to-slot",
  memory: "memory",
  "face-sleeping": "face-sleeping",
  "road-spikes": "road-spikes",
  "fire-burner": "fire-burner",
  squirrel: "squirrel",
  "arrow-up-to-line": "arrow-up-to-line",
  "arrow-to-top": "arrow-up-to-line",
  flag: "flag",
  "face-cowboy-hat": "face-cowboy-hat",
  hanukiah: "hanukiah",
  "chart-scatter-3d": "chart-scatter-3d",
  "display-chart-up": "display-chart-up",
  "square-code": "square-code",
  feather: "feather",
  "volume-low": "volume-low",
  "volume-down": "volume-low",
  "xmark-to-slot": "xmark-to-slot",
  "times-to-slot": "xmark-to-slot",
  "vote-nay": "xmark-to-slot",
  "box-taped": "box-taped",
  "box-alt": "box-taped",
  "comment-slash": "comment-slash",
  swords: "swords",
  "cloud-sun-rain": "cloud-sun-rain",
  album: "album",
  "circle-n": "circle-n",
  compress: "compress",
  "wheat-awn": "wheat-awn",
  "wheat-alt": "wheat-awn",
  ankh: "ankh",
  "hands-holding-child": "hands-holding-child",
  asterisk: "asterisk",
  "key-skeleton-left-right": "key-skeleton-left-right",
  "comment-lines": "comment-lines",
  "luchador-mask": "luchador-mask",
  luchador: "luchador-mask",
  "mask-luchador": "luchador-mask",
  "square-check": "square-check",
  "check-square": "square-check",
  shredder: "shredder",
  "book-open-cover": "book-open-cover",
  "book-open-alt": "book-open-cover",
  sandwich: "sandwich",
  "peseta-sign": "peseta-sign",
  "square-parking-slash": "square-parking-slash",
  "parking-slash": "square-parking-slash",
  "train-tunnel": "train-tunnel",
  heading: "heading",
  header: "heading",
  ghost: "ghost",
  "face-anguished": "face-anguished",
  "hockey-sticks": "hockey-sticks",
  abacus: "abacus",
  "film-simple": "film-simple",
  "film-alt": "film-simple",
  list: "list",
  "list-squares": "list",
  "tree-palm": "tree-palm",
  "square-phone-flip": "square-phone-flip",
  "phone-square-alt": "square-phone-flip",
  "cart-plus": "cart-plus",
  gamepad: "gamepad",
  "border-center-v": "border-center-v",
  "circle-dot": "circle-dot",
  "dot-circle": "circle-dot",
  "clipboard-medical": "clipboard-medical",
  "face-dizzy": "face-dizzy",
  dizzy: "face-dizzy",
  egg: "egg",
  "up-to-line": "up-to-line",
  "arrow-alt-to-top": "up-to-line",
  "house-medical-circle-xmark": "house-medical-circle-xmark",
  "watch-fitness": "watch-fitness",
  "clock-nine-thirty": "clock-nine-thirty",
  campground: "campground",
  "folder-plus": "folder-plus",
  jug: "jug",
  futbol: "futbol",
  "futbol-ball": "futbol",
  "soccer-ball": "futbol",
  "snow-blowing": "snow-blowing",
  paintbrush: "paintbrush",
  "paint-brush": "paintbrush",
  lock: "lock",
  "arrow-down-from-line": "arrow-down-from-line",
  "arrow-from-top": "arrow-down-from-line",
  "gas-pump": "gas-pump",
  "signal-bars-slash": "signal-bars-slash",
  "signal-alt-slash": "signal-bars-slash",
  monkey: "monkey",
  "rectangle-pro": "rectangle-pro",
  pro: "rectangle-pro",
  "house-night": "house-night",
  "hot-tub-person": "hot-tub-person",
  "hot-tub": "hot-tub-person",
  "globe-pointer": "globe-pointer",
  blanket: "blanket",
  "map-location": "map-location",
  "map-marked": "map-location",
  "house-flood-water": "house-flood-water",
  "comments-question-check": "comments-question-check",
  tree: "tree",
  "arrows-cross": "arrows-cross",
  backpack: "backpack",
  "square-small": "square-small",
  "folder-arrow-up": "folder-arrow-up",
  "folder-upload": "folder-arrow-up",
  "bridge-lock": "bridge-lock",
  "crosshairs-simple": "crosshairs-simple",
  "sack-dollar": "sack-dollar",
  "pen-to-square": "pen-to-square",
  edit: "pen-to-square",
  "square-sliders": "square-sliders",
  "sliders-h-square": "square-sliders",
  "car-side": "car-side",
  "message-middle-top": "message-middle-top",
  "comment-middle-top-alt": "message-middle-top",
  "lightbulb-on": "lightbulb-on",
  knife: "knife",
  "utensil-knife": "knife",
  "share-nodes": "share-nodes",
  "share-alt": "share-nodes",
  "display-chart-up-circle-dollar": "display-chart-up-circle-dollar",
  "wave-sine": "wave-sine",
  "heart-circle-minus": "heart-circle-minus",
  "circle-w": "circle-w",
  "circle-calendar": "circle-calendar",
  "calendar-circle": "circle-calendar",
  "hourglass-half": "hourglass-half",
  "hourglass-2": "hourglass-half",
  microscope: "microscope",
  sunset: "sunset",
  sink: "sink",
  "calendar-exclamation": "calendar-exclamation",
  "truck-container-empty": "truck-container-empty",
  "hand-heart": "hand-heart",
  "bag-shopping": "bag-shopping",
  "shopping-bag": "bag-shopping",
  "arrow-down-z-a": "arrow-down-z-a",
  "sort-alpha-desc": "arrow-down-z-a",
  "sort-alpha-down-alt": "arrow-down-z-a",
  mitten: "mitten",
  "reply-clock": "reply-clock",
  "reply-time": "reply-clock",
  "person-rays": "person-rays",
  right: "right",
  "arrow-alt-right": "right",
  "circle-f": "circle-f",
  users: "users",
  "face-pleading": "face-pleading",
  "eye-slash": "eye-slash",
  "flask-vial": "flask-vial",
  "police-box": "police-box",
  cucumber: "cucumber",
  "head-side-brain": "head-side-brain",
  hand: "hand",
  "hand-paper": "hand",
  "person-biking-mountain": "person-biking-mountain",
  "biking-mountain": "person-biking-mountain",
  "utensils-slash": "utensils-slash",
  "print-magnifying-glass": "print-magnifying-glass",
  "print-search": "print-magnifying-glass",
  "turn-right": "turn-right",
  "folder-bookmark": "folder-bookmark",
  "arrow-turn-left-down": "arrow-turn-left-down",
  om: "om",
  pi: "pi",
  "flask-round-potion": "flask-round-potion",
  "flask-potion": "flask-round-potion",
  "face-shush": "face-shush",
  worm: "worm",
  "house-circle-xmark": "house-circle-xmark",
  plug: "plug",
  "calendar-circle-exclamation": "calendar-circle-exclamation",
  "square-i": "square-i",
  "chevron-up": "chevron-up",
  "face-saluting": "face-saluting",
  "gauge-simple-low": "gauge-simple-low",
  "tachometer-slow": "gauge-simple-low",
  "face-persevering": "face-persevering",
  "circle-camera": "circle-camera",
  "camera-circle": "circle-camera",
  "hand-spock": "hand-spock",
  "spider-web": "spider-web",
  "circle-microphone": "circle-microphone",
  "microphone-circle": "circle-microphone",
  "book-arrow-up": "book-arrow-up",
  popsicle: "popsicle",
  command: "command",
  blinds: "blinds",
  stopwatch: "stopwatch",
  saxophone: "saxophone",
  "square-2": "square-2",
  "field-hockey-stick-ball": "field-hockey-stick-ball",
  "field-hockey": "field-hockey-stick-ball",
  "arrow-up-square-triangle": "arrow-up-square-triangle",
  "sort-shapes-up-alt": "arrow-up-square-triangle",
  "face-scream": "face-scream",
  "square-m": "square-m",
  "camera-web": "camera-web",
  webcam: "camera-web",
  "comment-arrow-down": "comment-arrow-down",
  "lightbulb-cfl": "lightbulb-cfl",
  "window-frame-open": "window-frame-open",
  "face-kiss": "face-kiss",
  kiss: "face-kiss",
  "bridge-circle-xmark": "bridge-circle-xmark",
  period: "period",
  "face-grin-tongue": "face-grin-tongue",
  "grin-tongue": "face-grin-tongue",
  "up-to-dotted-line": "up-to-dotted-line",
  "thought-bubble": "thought-bubble",
  "skeleton-ribs": "skeleton-ribs",
  raygun: "raygun",
  flute: "flute",
  acorn: "acorn",
  "video-arrow-up-right": "video-arrow-up-right",
  "grate-droplet": "grate-droplet",
  "seal-exclamation": "seal-exclamation",
  "chess-bishop": "chess-bishop",
  "message-sms": "message-sms",
  "coffee-beans": "coffee-beans",
  "hat-witch": "hat-witch",
  "face-grin-wink": "face-grin-wink",
  "grin-wink": "face-grin-wink",
  "clock-three-thirty": "clock-three-thirty",
  "ear-deaf": "ear-deaf",
  deaf: "ear-deaf",
  deafness: "ear-deaf",
  "hard-of-hearing": "ear-deaf",
  "alarm-clock": "alarm-clock",
  eclipse: "eclipse",
  "face-relieved": "face-relieved",
  "road-circle-check": "road-circle-check",
  "dice-five": "dice-five",
  "octagon-minus": "octagon-minus",
  "minus-octagon": "octagon-minus",
  "square-rss": "square-rss",
  "rss-square": "square-rss",
  "face-zany": "face-zany",
  tricycle: "tricycle",
  "land-mine-on": "land-mine-on",
  "square-arrow-up-left": "square-arrow-up-left",
  "i-cursor": "i-cursor",
  "chart-mixed-up-circle-dollar": "chart-mixed-up-circle-dollar",
  "salt-shaker": "salt-shaker",
  stamp: "stamp",
  "file-plus": "file-plus",
  "draw-square": "draw-square",
  "toilet-paper-under-slash": "toilet-paper-under-slash",
  "toilet-paper-reverse-slash": "toilet-paper-under-slash",
  stairs: "stairs",
  "drone-front": "drone-front",
  "drone-alt": "drone-front",
  "glass-empty": "glass-empty",
  "dial-high": "dial-high",
  "user-helmet-safety": "user-helmet-safety",
  "user-construction": "user-helmet-safety",
  "user-hard-hat": "user-helmet-safety",
  i: "i",
  "hryvnia-sign": "hryvnia-sign",
  hryvnia: "hryvnia-sign",
  "arrow-down-left-and-arrow-up-right-to-center": "arrow-down-left-and-arrow-up-right-to-center",
  pills: "pills",
  "face-grin-wide": "face-grin-wide",
  "grin-alt": "face-grin-wide",
  tooth: "tooth",
  "basketball-hoop": "basketball-hoop",
  "objects-align-bottom": "objects-align-bottom",
  v: "v",
  sparkles: "sparkles",
  squid: "squid",
  "leafy-green": "leafy-green",
  "circle-arrow-up-right": "circle-arrow-up-right",
  calendars: "calendars",
  "bangladeshi-taka-sign": "bangladeshi-taka-sign",
  bicycle: "bicycle",
  "hammer-war": "hammer-war",
  "circle-d": "circle-d",
  "spider-black-widow": "spider-black-widow",
  "staff-snake": "staff-snake",
  "rod-asclepius": "staff-snake",
  "rod-snake": "staff-snake",
  "staff-aesculapius": "staff-snake",
  pear: "pear",
  "head-side-cough-slash": "head-side-cough-slash",
  "file-mov": "file-mov",
  triangle: "triangle",
  apartment: "apartment",
  "truck-medical": "truck-medical",
  ambulance: "truck-medical",
  pepper: "pepper",
  piano: "piano",
  "gun-squirt": "gun-squirt",
  "wheat-awn-circle-exclamation": "wheat-awn-circle-exclamation",
  snowman: "snowman",
  "user-alien": "user-alien",
  "shield-check": "shield-check",
  "mortar-pestle": "mortar-pestle",
  "road-barrier": "road-barrier",
  "chart-candlestick": "chart-candlestick",
  "briefcase-blank": "briefcase-blank",
  school: "school",
  igloo: "igloo",
  "bracket-round": "bracket-round",
  parenthesis: "bracket-round",
  joint: "joint",
  "horse-saddle": "horse-saddle",
  "mug-marshmallows": "mug-marshmallows",
  filters: "filters",
  "bell-on": "bell-on",
  "angle-right": "angle-right",
  "dial-med": "dial-med",
  horse: "horse",
  q: "q",
  "monitor-waveform": "monitor-waveform",
  "monitor-heart-rate": "monitor-waveform",
  "link-simple": "link-simple",
  whistle: "whistle",
  g: "g",
  "wine-glass-crack": "wine-glass-crack",
  fragile: "wine-glass-crack",
  "slot-machine": "slot-machine",
  "notes-medical": "notes-medical",
  "car-wash": "car-wash",
  escalator: "escalator",
  "comment-image": "comment-image",
  "temperature-half": "temperature-half",
  "temperature-2": "temperature-half",
  "thermometer-2": "temperature-half",
  "thermometer-half": "temperature-half",
  "dong-sign": "dong-sign",
  donut: "donut",
  doughnut: "donut",
  capsules: "capsules",
  "poo-storm": "poo-storm",
  "poo-bolt": "poo-storm",
  "tally-1": "tally-1",
  "file-vector": "file-vector",
  "face-frown-open": "face-frown-open",
  "frown-open": "face-frown-open",
  "square-dashed": "square-dashed",
  "bag-shopping-plus": "bag-shopping-plus",
  "square-j": "square-j",
  "hand-point-up": "hand-point-up",
  "money-bill": "money-bill",
  "arrow-up-big-small": "arrow-up-big-small",
  "sort-size-up": "arrow-up-big-small",
  "barcode-read": "barcode-read",
  baguette: "baguette",
  "bowl-soft-serve": "bowl-soft-serve",
  "face-holding-back-tears": "face-holding-back-tears",
  "square-up": "square-up",
  "arrow-alt-square-up": "square-up",
  "train-subway-tunnel": "train-subway-tunnel",
  "subway-tunnel": "train-subway-tunnel",
  "square-exclamation": "square-exclamation",
  "exclamation-square": "square-exclamation",
  semicolon: "semicolon",
  bookmark: "bookmark",
  "fan-table": "fan-table",
  "align-justify": "align-justify",
  "battery-low": "battery-low",
  "battery-1": "battery-low",
  "credit-card-front": "credit-card-front",
  "brain-arrow-curved-right": "brain-arrow-curved-right",
  "mind-share": "brain-arrow-curved-right",
  "umbrella-beach": "umbrella-beach",
  "helmet-un": "helmet-un",
  "location-smile": "location-smile",
  "map-marker-smile": "location-smile",
  "arrow-left-to-line": "arrow-left-to-line",
  "arrow-to-left": "arrow-left-to-line",
  bullseye: "bullseye",
  sushi: "sushi",
  nigiri: "sushi",
  "message-captions": "message-captions",
  "comment-alt-captions": "message-captions",
  "trash-list": "trash-list",
  bacon: "bacon",
  option: "option",
  raccoon: "raccoon",
  "hand-point-down": "hand-point-down",
  "arrow-up-from-bracket": "arrow-up-from-bracket",
  "head-side-gear": "head-side-gear",
  "trash-plus": "trash-plus",
  "file-cad": "file-cad",
  "objects-align-top": "objects-align-top",
  folder: "folder",
  "folder-blank": "folder",
  "face-anxious-sweat": "face-anxious-sweat",
  "credit-card-blank": "credit-card-blank",
  "file-waveform": "file-waveform",
  "file-medical-alt": "file-waveform",
  "microchip-ai": "microchip-ai",
  mug: "mug",
  "plane-up-slash": "plane-up-slash",
  radiation: "radiation",
  "pen-circle": "pen-circle",
  "bag-seedling": "bag-seedling",
  "chart-simple": "chart-simple",
  crutches: "crutches",
  "circle-parking": "circle-parking",
  "parking-circle": "circle-parking",
  "mars-stroke": "mars-stroke",
  "leaf-oak": "leaf-oak",
  "square-bolt": "square-bolt",
  vial: "vial",
  gauge: "gauge",
  dashboard: "gauge",
  "gauge-med": "gauge",
  "tachometer-alt-average": "gauge",
  "wand-magic-sparkles": "wand-magic-sparkles",
  "magic-wand-sparkles": "wand-magic-sparkles",
  lambda: "lambda",
  e: "e",
  pizza: "pizza",
  "bowl-chopsticks-noodles": "bowl-chopsticks-noodles",
  h3: "h3",
  "pen-clip": "pen-clip",
  "pen-alt": "pen-clip",
  "bridge-circle-exclamation": "bridge-circle-exclamation",
  "badge-percent": "badge-percent",
  "rotate-reverse": "rotate-reverse",
  user: "user",
  sensor: "sensor",
  comma: "comma",
  "school-circle-check": "school-circle-check",
  "toilet-paper-under": "toilet-paper-under",
  "toilet-paper-reverse": "toilet-paper-under",
  "light-emergency": "light-emergency",
  "arrow-down-to-arc": "arrow-down-to-arc",
  dumpster: "dumpster",
  "van-shuttle": "van-shuttle",
  "shuttle-van": "van-shuttle",
  "building-user": "building-user",
  "light-switch": "light-switch",
  "square-caret-left": "square-caret-left",
  "caret-square-left": "square-caret-left",
  highlighter: "highlighter",
  "wave-pulse": "wave-pulse",
  "heart-rate": "wave-pulse",
  key: "key",
  "arrow-left-to-bracket": "arrow-left-to-bracket",
  "hat-santa": "hat-santa",
  tamale: "tamale",
  "box-check": "box-check",
  bullhorn: "bullhorn",
  steak: "steak",
  "location-crosshairs-slash": "location-crosshairs-slash",
  "location-slash": "location-crosshairs-slash",
  "person-dolly": "person-dolly",
  globe: "globe",
  synagogue: "synagogue",
  "file-chart-column": "file-chart-column",
  "file-chart-line": "file-chart-column",
  "person-half-dress": "person-half-dress",
  "folder-image": "folder-image",
  "calendar-pen": "calendar-pen",
  "calendar-edit": "calendar-pen",
  "road-bridge": "road-bridge",
  "face-smile-tear": "face-smile-tear",
  "message-plus": "message-plus",
  "comment-alt-plus": "message-plus",
  "location-arrow": "location-arrow",
  c: "c",
  "tablet-button": "tablet-button",
  "person-dress-fairy": "person-dress-fairy",
  "rectangle-history-circle-user": "rectangle-history-circle-user",
  "building-lock": "building-lock",
  "chart-line-up": "chart-line-up",
  mailbox: "mailbox",
  "sign-posts": "sign-posts",
  "truck-bolt": "truck-bolt",
  "pizza-slice": "pizza-slice",
  "money-bill-wave": "money-bill-wave",
  "chart-area": "chart-area",
  "area-chart": "chart-area",
  "house-flag": "house-flag",
  "circle-three-quarters-stroke": "circle-three-quarters-stroke",
  "person-circle-minus": "person-circle-minus",
  scalpel: "scalpel",
  ban: "ban",
  cancel: "ban",
  "bell-exclamation": "bell-exclamation",
  "circle-bookmark": "circle-bookmark",
  "bookmark-circle": "circle-bookmark",
  "egg-fried": "egg-fried",
  "face-weary": "face-weary",
  "uniform-martial-arts": "uniform-martial-arts",
  "camera-rotate": "camera-rotate",
  "sun-dust": "sun-dust",
  "comment-text": "comment-text",
  "spray-can-sparkles": "spray-can-sparkles",
  "air-freshener": "spray-can-sparkles",
  "signal-bars": "signal-bars",
  "signal-alt": "signal-bars",
  "signal-alt-4": "signal-bars",
  "signal-bars-strong": "signal-bars",
  "diamond-exclamation": "diamond-exclamation",
  star: "star",
  "dial-min": "dial-min",
  repeat: "repeat",
  cross: "cross",
  "page-caret-down": "page-caret-down",
  "file-caret-down": "page-caret-down",
  box: "box",
  "venus-mars": "venus-mars",
  "clock-seven-thirty": "clock-seven-thirty",
  "arrow-pointer": "arrow-pointer",
  "mouse-pointer": "arrow-pointer",
  "clock-four-thirty": "clock-four-thirty",
  "signal-bars-good": "signal-bars-good",
  "signal-alt-3": "signal-bars-good",
  cactus: "cactus",
  "lightbulb-gear": "lightbulb-gear",
  maximize: "maximize",
  "expand-arrows-alt": "maximize",
  "charging-station": "charging-station",
  shapes: "shapes",
  "triangle-circle-square": "shapes",
  "plane-tail": "plane-tail",
  "gauge-simple-max": "gauge-simple-max",
  "tachometer-fastest": "gauge-simple-max",
  "circle-u": "circle-u",
  "shield-slash": "shield-slash",
  "square-phone-hangup": "square-phone-hangup",
  "phone-square-down": "square-phone-hangup",
  "arrow-up-left": "arrow-up-left",
  "transporter-1": "transporter-1",
  peanuts: "peanuts",
  shuffle: "shuffle",
  random: "shuffle",
  "person-running": "person-running",
  running: "person-running",
  "mobile-retro": "mobile-retro",
  "grip-lines-vertical": "grip-lines-vertical",
  "bin-bottles-recycle": "bin-bottles-recycle",
  "arrow-up-from-square": "arrow-up-from-square",
  "file-dashed-line": "file-dashed-line",
  "page-break": "file-dashed-line",
  "bracket-curly-right": "bracket-curly-right",
  spider: "spider",
  "clock-three": "clock-three",
  "hands-bound": "hands-bound",
  "scalpel-line-dashed": "scalpel-line-dashed",
  "scalpel-path": "scalpel-line-dashed",
  "file-invoice-dollar": "file-invoice-dollar",
  "pipe-smoking": "pipe-smoking",
  "face-astonished": "face-astonished",
  window: "window",
  "plane-circle-exclamation": "plane-circle-exclamation",
  ear: "ear",
  "file-lock": "file-lock",
  "diagram-venn": "diagram-venn",
  "arrow-down-from-bracket": "arrow-down-from-bracket",
  "x-ray": "x-ray",
  "goal-net": "goal-net",
  "coffin-cross": "coffin-cross",
  "spell-check": "spell-check",
  "location-xmark": "location-xmark",
  "map-marker-times": "location-xmark",
  "map-marker-xmark": "location-xmark",
  "circle-quarter-stroke": "circle-quarter-stroke",
  lasso: "lasso",
  slash: "slash",
  "person-to-portal": "person-to-portal",
  "portal-enter": "person-to-portal",
  "calendar-star": "calendar-star",
  "computer-mouse": "computer-mouse",
  mouse: "computer-mouse",
  "arrow-right-to-bracket": "arrow-right-to-bracket",
  "sign-in": "arrow-right-to-bracket",
  pegasus: "pegasus",
  "files-medical": "files-medical",
  cannon: "cannon",
  "nfc-lock": "nfc-lock",
  "person-ski-lift": "person-ski-lift",
  "ski-lift": "person-ski-lift",
  "square-6": "square-6",
  "shop-slash": "shop-slash",
  "store-alt-slash": "shop-slash",
  "wind-turbine": "wind-turbine",
  "sliders-simple": "sliders-simple",
  "grid-round": "grid-round",
  "badge-sheriff": "badge-sheriff",
  server: "server",
  "virus-covid-slash": "virus-covid-slash",
  intersection: "intersection",
  "shop-lock": "shop-lock",
  family: "family",
  "hourglass-start": "hourglass-start",
  "hourglass-1": "hourglass-start",
  "user-hair-buns": "user-hair-buns",
  "blender-phone": "blender-phone",
  "hourglass-clock": "hourglass-clock",
  "person-seat-reclined": "person-seat-reclined",
  "paper-plane-top": "paper-plane-top",
  "paper-plane-alt": "paper-plane-top",
  send: "paper-plane-top",
  "message-arrow-up": "message-arrow-up",
  "comment-alt-arrow-up": "message-arrow-up",
  "lightbulb-exclamation": "lightbulb-exclamation",
  "layer-minus": "layer-minus",
  "layer-group-minus": "layer-minus",
  "chart-pie-simple-circle-currency": "chart-pie-simple-circle-currency",
  "circle-e": "circle-e",
  "building-wheat": "building-wheat",
  "gauge-max": "gauge-max",
  "tachometer-alt-fastest": "gauge-max",
  "person-breastfeeding": "person-breastfeeding",
  apostrophe: "apostrophe",
  "file-png": "file-png",
  "fire-hydrant": "fire-hydrant",
  "right-to-bracket": "right-to-bracket",
  "sign-in-alt": "right-to-bracket",
  "video-plus": "video-plus",
  "square-right": "square-right",
  "arrow-alt-square-right": "square-right",
  "comment-smile": "comment-smile",
  venus: "venus",
  passport: "passport",
  "inbox-in": "inbox-in",
  "inbox-arrow-down": "inbox-in",
  "heart-pulse": "heart-pulse",
  heartbeat: "heart-pulse",
  "circle-8": "circle-8",
  "clouds-moon": "clouds-moon",
  "clock-ten-thirty": "clock-ten-thirty",
  "people-carry-box": "people-carry-box",
  "people-carry": "people-carry-box",
  "folder-user": "folder-user",
  "trash-can-xmark": "trash-can-xmark",
  "temperature-high": "temperature-high",
  microchip: "microchip",
  "left-long-to-line": "left-long-to-line",
  crown: "crown",
  "weight-hanging": "weight-hanging",
  "xmarks-lines": "xmarks-lines",
  "file-prescription": "file-prescription",
  "table-cells-lock": "table-cells-lock",
  "calendar-range": "calendar-range",
  "flower-daffodil": "flower-daffodil",
  "hand-back-point-up": "hand-back-point-up",
  "weight-scale": "weight-scale",
  weight: "weight-scale",
  "arrow-up-to-arc": "arrow-up-to-arc",
  "star-exclamation": "star-exclamation",
  books: "books",
  "user-group": "user-group",
  "user-friends": "user-group",
  "arrow-up-a-z": "arrow-up-a-z",
  "sort-alpha-up": "arrow-up-a-z",
  "layer-plus": "layer-plus",
  "layer-group-plus": "layer-plus",
  "play-pause": "play-pause",
  "block-question": "block-question",
  snooze: "snooze",
  zzz: "snooze",
  "scanner-image": "scanner-image",
  "tv-retro": "tv-retro",
  "square-t": "square-t",
  farm: "farm",
  "barn-silo": "farm",
  "chess-knight": "chess-knight",
  "bars-sort": "bars-sort",
  "pallet-boxes": "pallet-boxes",
  "palette-boxes": "pallet-boxes",
  "pallet-alt": "pallet-boxes",
  "face-laugh-squint": "face-laugh-squint",
  "laugh-squint": "face-laugh-squint",
  "code-simple": "code-simple",
  "bolt-slash": "bolt-slash",
  "panel-fire": "panel-fire",
  "binary-circle-check": "binary-circle-check",
  "comment-minus": "comment-minus",
  burrito: "burrito",
  violin: "violin",
  "objects-column": "objects-column",
  "square-chevron-down": "square-chevron-down",
  "chevron-square-down": "square-chevron-down",
  "comment-plus": "comment-plus",
  "triangle-instrument": "triangle-instrument",
  "triangle-music": "triangle-instrument",
  wheelchair: "wheelchair",
  "user-pilot-tie": "user-pilot-tie",
  "piano-keyboard": "piano-keyboard",
  "bed-empty": "bed-empty",
  "circle-arrow-up": "circle-arrow-up",
  "arrow-circle-up": "circle-arrow-up",
  "toggle-on": "toggle-on",
  "rectangle-vertical": "rectangle-vertical",
  "rectangle-portrait": "rectangle-vertical",
  "person-walking": "person-walking",
  walking: "person-walking",
  l: "l",
  "signal-stream": "signal-stream",
  "down-to-bracket": "down-to-bracket",
  "circle-z": "circle-z",
  stars: "stars",
  fire: "fire",
  "bed-pulse": "bed-pulse",
  procedures: "bed-pulse",
  "house-day": "house-day",
  "shuttle-space": "shuttle-space",
  "space-shuttle": "shuttle-space",
  "shirt-long-sleeve": "shirt-long-sleeve",
  "chart-pie-simple": "chart-pie-simple",
  "chart-pie-alt": "chart-pie-simple",
  "face-laugh": "face-laugh",
  laugh: "face-laugh",
  "folder-open": "folder-open",
  "album-collection-circle-user": "album-collection-circle-user",
  candy: "candy",
  "bowl-hot": "bowl-hot",
  soup: "bowl-hot",
  flatbread: "flatbread",
  "heart-circle-plus": "heart-circle-plus",
  "code-fork": "code-fork",
  city: "city",
  "signal-bars-weak": "signal-bars-weak",
  "signal-alt-1": "signal-bars-weak",
  "microphone-lines": "microphone-lines",
  "microphone-alt": "microphone-lines",
  "clock-twelve": "clock-twelve",
  "pepper-hot": "pepper-hot",
  "citrus-slice": "citrus-slice",
  sheep: "sheep",
  unlock: "unlock",
  "colon-sign": "colon-sign",
  headset: "headset",
  "badger-honey": "badger-honey",
  h4: "h4",
  "store-slash": "store-slash",
  "road-circle-xmark": "road-circle-xmark",
  "signal-slash": "signal-slash",
  "user-minus": "user-minus",
  "mars-stroke-up": "mars-stroke-up",
  "mars-stroke-v": "mars-stroke-up",
  "champagne-glasses": "champagne-glasses",
  "glass-cheers": "champagne-glasses",
  taco: "taco",
  "hexagon-plus": "hexagon-plus",
  "plus-hexagon": "hexagon-plus",
  clipboard: "clipboard",
  "house-circle-exclamation": "house-circle-exclamation",
  "file-arrow-up": "file-arrow-up",
  "file-upload": "file-arrow-up",
  wifi: "wifi",
  "wifi-3": "wifi",
  "wifi-strong": "wifi",
  messages: "messages",
  "comments-alt": "messages",
  bath: "bath",
  bathtub: "bath",
  "umbrella-simple": "umbrella-simple",
  "umbrella-alt": "umbrella-simple",
  "rectangle-history-circle-plus": "rectangle-history-circle-plus",
  underline: "underline",
  "prescription-bottle-pill": "prescription-bottle-pill",
  "user-pen": "user-pen",
  "user-edit": "user-pen",
  "binary-slash": "binary-slash",
  "square-o": "square-o",
  caduceus: "caduceus",
  signature: "signature",
  stroopwafel: "stroopwafel",
  bold: "bold",
  "anchor-lock": "anchor-lock",
  "building-ngo": "building-ngo",
  "transporter-3": "transporter-3",
  "engine-warning": "engine-warning",
  "engine-exclamation": "engine-warning",
  "circle-down-right": "circle-down-right",
  "square-k": "square-k",
  "manat-sign": "manat-sign",
  "money-check-pen": "money-check-pen",
  "money-check-edit": "money-check-pen",
  "not-equal": "not-equal",
  "border-top-left": "border-top-left",
  "border-style": "border-top-left",
  "map-location-dot": "map-location-dot",
  "map-marked-alt": "map-location-dot",
  tilde: "tilde",
  jedi: "jedi",
  "square-poll-vertical": "square-poll-vertical",
  poll: "square-poll-vertical",
  "arrow-down-square-triangle": "arrow-down-square-triangle",
  "sort-shapes-down-alt": "arrow-down-square-triangle",
  "mug-hot": "mug-hot",
  "dog-leashed": "dog-leashed",
  "car-battery": "car-battery",
  "battery-car": "car-battery",
  "face-downcast-sweat": "face-downcast-sweat",
  "mailbox-flag-up": "mailbox-flag-up",
  "memo-circle-info": "memo-circle-info",
  gift: "gift",
  "dice-two": "dice-two",
  volume: "volume",
  "volume-medium": "volume",
  "transporter-5": "transporter-5",
  "gauge-circle-bolt": "gauge-circle-bolt",
  "coin-front": "coin-front",
  "file-slash": "file-slash",
  "message-arrow-up-right": "message-arrow-up-right",
  "treasure-chest": "treasure-chest",
  "chess-queen": "chess-queen",
  "paintbrush-fine": "paintbrush-fine",
  "paint-brush-alt": "paintbrush-fine",
  "paint-brush-fine": "paintbrush-fine",
  "paintbrush-alt": "paintbrush-fine",
  glasses: "glasses",
  "hood-cloak": "hood-cloak",
  "square-quote": "square-quote",
  "up-left": "up-left",
  "bring-front": "bring-front",
  "chess-board": "chess-board",
  "burger-cheese": "burger-cheese",
  cheeseburger: "burger-cheese",
  "building-circle-check": "building-circle-check",
  "repeat-1": "repeat-1",
  "arrow-down-to-line": "arrow-down-to-line",
  "arrow-to-bottom": "arrow-down-to-line",
  "grid-5": "grid-5",
  "swap-arrows": "swap-arrows",
  "right-long-to-line": "right-long-to-line",
  "person-chalkboard": "person-chalkboard",
  "mars-stroke-right": "mars-stroke-right",
  "mars-stroke-h": "mars-stroke-right",
  "hand-back-fist": "hand-back-fist",
  "hand-rock": "hand-back-fist",
  "grid-round-5": "grid-round-5",
  tally: "tally",
  "tally-5": "tally",
  "square-caret-up": "square-caret-up",
  "caret-square-up": "square-caret-up",
  "cloud-showers-water": "cloud-showers-water",
  "chart-bar": "chart-bar",
  "bar-chart": "chart-bar",
  "hands-bubbles": "hands-bubbles",
  "hands-wash": "hands-bubbles",
  "less-than-equal": "less-than-equal",
  train: "train",
  "up-from-dotted-line": "up-from-dotted-line",
  "eye-low-vision": "eye-low-vision",
  "low-vision": "eye-low-vision",
  "traffic-light-go": "traffic-light-go",
  "face-exhaling": "face-exhaling",
  "sensor-fire": "sensor-fire",
  "user-unlock": "user-unlock",
  "hexagon-divide": "hexagon-divide",
  "00": "00",
  crow: "crow",
  "cassette-betamax": "cassette-betamax",
  betamax: "cassette-betamax",
  sailboat: "sailboat",
  "window-restore": "window-restore",
  "nfc-magnifying-glass": "nfc-magnifying-glass",
  "file-binary": "file-binary",
  "circle-v": "circle-v",
  "square-plus": "square-plus",
  "plus-square": "square-plus",
  "bowl-scoops": "bowl-scoops",
  mistletoe: "mistletoe",
  custard: "custard",
  "lacrosse-stick": "lacrosse-stick",
  "hockey-mask": "hockey-mask",
  sunrise: "sunrise",
  subtitles: "subtitles",
  "panel-ews": "panel-ews",
  "torii-gate": "torii-gate",
  "cloud-exclamation": "cloud-exclamation",
  "message-lines": "message-lines",
  "comment-alt-lines": "message-lines",
  frog: "frog",
  bucket: "bucket",
  "floppy-disk-pen": "floppy-disk-pen",
  image: "image",
  "window-frame": "window-frame",
  microphone: "microphone",
  cow: "cow",
  "file-zip": "file-zip",
  "square-ring": "square-ring",
  "down-from-line": "down-from-line",
  "arrow-alt-from-top": "down-from-line",
  "caret-up": "caret-up",
  "shield-xmark": "shield-xmark",
  "shield-times": "shield-xmark",
  screwdriver: "screwdriver",
  "circle-sort-down": "circle-sort-down",
  "sort-circle-down": "circle-sort-down",
  "folder-closed": "folder-closed",
  "house-tsunami": "house-tsunami",
  "square-nfi": "square-nfi",
  forklift: "forklift",
  "arrow-up-from-ground-water": "arrow-up-from-ground-water",
  "bracket-square-right": "bracket-square-right",
  "martini-glass": "martini-glass",
  "glass-martini-alt": "martini-glass",
  "rotate-left": "rotate-left",
  "rotate-back": "rotate-left",
  "rotate-backward": "rotate-left",
  "undo-alt": "rotate-left",
  "table-columns": "table-columns",
  columns: "table-columns",
  "square-a": "square-a",
  tick: "tick",
  lemon: "lemon",
  "head-side-mask": "head-side-mask",
  handshake: "handshake",
  gem: "gem",
  dolly: "dolly",
  "dolly-box": "dolly",
  smoking: "smoking",
  minimize: "minimize",
  "compress-arrows-alt": "minimize",
  refrigerator: "refrigerator",
  monument: "monument",
  "octagon-xmark": "octagon-xmark",
  "times-octagon": "octagon-xmark",
  "xmark-octagon": "octagon-xmark",
  "align-slash": "align-slash",
  snowplow: "snowplow",
  "angles-right": "angles-right",
  "angle-double-right": "angles-right",
  "truck-ramp-couch": "truck-ramp-couch",
  "truck-couch": "truck-ramp-couch",
  cannabis: "cannabis",
  "circle-play": "circle-play",
  "play-circle": "circle-play",
  "arrow-up-right-and-arrow-down-left-from-center": "arrow-up-right-and-arrow-down-left-from-center",
  "location-arrow-up": "location-arrow-up",
  tablets: "tablets",
  "360-degrees": "360-degrees",
  ethernet: "ethernet",
  "euro-sign": "euro-sign",
  eur: "euro-sign",
  euro: "euro-sign",
  chair: "chair",
  "circle-check": "circle-check",
  "check-circle": "circle-check",
  "square-dashed-circle-plus": "square-dashed-circle-plus",
  "hand-holding-circle-dollar": "hand-holding-circle-dollar",
  "money-simple-from-bracket": "money-simple-from-bracket",
  bat: "bat",
  "circle-stop": "circle-stop",
  "stop-circle": "circle-stop",
  "head-side-headphones": "head-side-headphones",
  "phone-rotary": "phone-rotary",
  "arrow-up-to-bracket": "arrow-up-to-bracket",
  "compass-drafting": "compass-drafting",
  "drafting-compass": "compass-drafting",
  "plate-wheat": "plate-wheat",
  "calendar-circle-minus": "calendar-circle-minus",
  chopsticks: "chopsticks",
  "car-wrench": "car-wrench",
  "car-mechanic": "car-wrench",
  icicles: "icicles",
  "person-shelter": "person-shelter",
  neuter: "neuter",
  "id-badge": "id-badge",
  kazoo: "kazoo",
  marker: "marker",
  "bin-bottles": "bin-bottles",
  "face-laugh-beam": "face-laugh-beam",
  "laugh-beam": "face-laugh-beam",
  "square-arrow-down-left": "square-arrow-down-left",
  "battery-bolt": "battery-bolt",
  "tree-large": "tree-large",
  "helicopter-symbol": "helicopter-symbol",
  aperture: "aperture",
  "universal-access": "universal-access",
  "gear-complex": "gear-complex",
  "file-magnifying-glass": "file-magnifying-glass",
  "file-search": "file-magnifying-glass",
  "up-right": "up-right",
  "circle-chevron-up": "circle-chevron-up",
  "chevron-circle-up": "circle-chevron-up",
  "user-police": "user-police",
  "lari-sign": "lari-sign",
  volcano: "volcano",
  "teddy-bear": "teddy-bear",
  stocking: "stocking",
  "person-walking-dashed-line-arrow-right": "person-walking-dashed-line-arrow-right",
  "image-slash": "image-slash",
  "mask-snorkel": "mask-snorkel",
  smoke: "smoke",
  "sterling-sign": "sterling-sign",
  gbp: "sterling-sign",
  "pound-sign": "sterling-sign",
  "battery-exclamation": "battery-exclamation",
  viruses: "viruses",
  "square-person-confined": "square-person-confined",
  "user-tie": "user-tie",
  "up-to-bracket": "up-to-bracket",
  "arrow-down-long": "arrow-down-long",
  "long-arrow-down": "arrow-down-long",
  "tent-arrow-down-to-line": "tent-arrow-down-to-line",
  certificate: "certificate",
  "crystal-ball": "crystal-ball",
  "reply-all": "reply-all",
  "mail-reply-all": "reply-all",
  suitcase: "suitcase",
  "person-skating": "person-skating",
  skating: "person-skating",
  "star-shooting": "star-shooting",
  "binary-lock": "binary-lock",
  "filter-circle-dollar": "filter-circle-dollar",
  "funnel-dollar": "filter-circle-dollar",
  "camera-retro": "camera-retro",
  "circle-arrow-down": "circle-arrow-down",
  "arrow-circle-down": "circle-arrow-down",
  "comment-pen": "comment-pen",
  "comment-edit": "comment-pen",
  "file-import": "file-import",
  "arrow-right-to-file": "file-import",
  banjo: "banjo",
  "square-arrow-up-right": "square-arrow-up-right",
  "external-link-square": "square-arrow-up-right",
  "light-emergency-on": "light-emergency-on",
  kerning: "kerning",
  "box-open": "box-open",
  "square-f": "square-f",
  scroll: "scroll",
  spa: "spa",
  "arrow-left-from-line": "arrow-left-from-line",
  "arrow-from-right": "arrow-left-from-line",
  strawberry: "strawberry",
  "location-pin-lock": "location-pin-lock",
  pause: "pause",
  "clock-eight-thirty": "clock-eight-thirty",
  "plane-engines": "plane-engines",
  "plane-alt": "plane-engines",
  "hill-avalanche": "hill-avalanche",
  "temperature-empty": "temperature-empty",
  "temperature-0": "temperature-empty",
  "thermometer-0": "temperature-empty",
  "thermometer-empty": "temperature-empty",
  bomb: "bomb",
  "gauge-low": "gauge-low",
  "tachometer-alt-slow": "gauge-low",
  registered: "registered",
  "trash-can-plus": "trash-can-plus",
  "address-card": "address-card",
  "contact-card": "address-card",
  vcard: "address-card",
  "scale-unbalanced-flip": "scale-unbalanced-flip",
  "balance-scale-right": "scale-unbalanced-flip",
  "globe-snow": "globe-snow",
  subscript: "subscript",
  "diamond-turn-right": "diamond-turn-right",
  directions: "diamond-turn-right",
  integral: "integral",
  burst: "burst",
  "house-laptop": "house-laptop",
  "laptop-house": "house-laptop",
  "face-tired": "face-tired",
  tired: "face-tired",
  "money-bills": "money-bills",
  "blinds-raised": "blinds-raised",
  smog: "smog",
  "ufo-beam": "ufo-beam",
  "circle-caret-up": "circle-caret-up",
  "caret-circle-up": "circle-caret-up",
  "user-vneck-hair-long": "user-vneck-hair-long",
  "square-a-lock": "square-a-lock",
  crutch: "crutch",
  "gas-pump-slash": "gas-pump-slash",
  "font-awesome": "font-awesome",
  "font-awesome-flag": "font-awesome",
  "font-awesome-logo-full": "font-awesome",
  "cloud-arrow-up": "cloud-arrow-up",
  "cloud-upload": "cloud-arrow-up",
  "cloud-upload-alt": "cloud-arrow-up",
  palette: "palette",
  "transporter-4": "transporter-4",
  "chart-mixed-up-circle-currency": "chart-mixed-up-circle-currency",
  "objects-align-right": "objects-align-right",
  "arrows-turn-right": "arrows-turn-right",
  vest: "vest",
  pig: "pig",
  "inbox-full": "inbox-full",
  "circle-envelope": "circle-envelope",
  "envelope-circle": "circle-envelope",
  "triangle-person-digging": "triangle-person-digging",
  construction: "triangle-person-digging",
  ferry: "ferry",
  "bullseye-arrow": "bullseye-arrow",
  "arrows-down-to-people": "arrows-down-to-people",
  seedling: "seedling",
  sprout: "seedling",
  "clock-seven": "clock-seven",
  "left-right": "left-right",
  "arrows-alt-h": "left-right",
  "boxes-packing": "boxes-packing",
  "circle-arrow-left": "circle-arrow-left",
  "arrow-circle-left": "circle-arrow-left",
  flashlight: "flashlight",
  "file-jpg": "file-jpg",
  "group-arrows-rotate": "group-arrows-rotate",
  "bowl-food": "bowl-food",
  "square-9": "square-9",
  "candy-cane": "candy-cane",
  "arrow-down-wide-short": "arrow-down-wide-short",
  "sort-amount-asc": "arrow-down-wide-short",
  "sort-amount-down": "arrow-down-wide-short",
  "square-dollar": "square-dollar",
  "dollar-square": "square-dollar",
  "usd-square": "square-dollar",
  "phone-arrow-right": "phone-arrow-right",
  "hand-holding-seedling": "hand-holding-seedling",
  "message-check": "message-check",
  "comment-alt-check": "message-check",
  "cloud-bolt": "cloud-bolt",
  thunderstorm: "cloud-bolt",
  "chart-line-up-down": "chart-line-up-down",
  "text-slash": "text-slash",
  "remove-format": "text-slash",
  watch: "watch",
  "circle-down-left": "circle-down-left",
  text: "text",
  projector: "projector",
  "face-smile-wink": "face-smile-wink",
  "smile-wink": "face-smile-wink",
  "tombstone-blank": "tombstone-blank",
  "tombstone-alt": "tombstone-blank",
  "chess-king-piece": "chess-king-piece",
  "chess-king-alt": "chess-king-piece",
  "circle-6": "circle-6",
  "waves-sine": "waves-sine",
  left: "left",
  "arrow-alt-left": "left",
  "file-word": "file-word",
  "file-powerpoint": "file-powerpoint",
  "square-down": "square-down",
  "arrow-alt-square-down": "square-down",
  "objects-align-center-vertical": "objects-align-center-vertical",
  "arrows-left-right": "arrows-left-right",
  "arrows-h": "arrows-left-right",
  "house-lock": "house-lock",
  "cloud-arrow-down": "cloud-arrow-down",
  "cloud-download": "cloud-arrow-down",
  "cloud-download-alt": "cloud-arrow-down",
  wreath: "wreath",
  children: "children",
  "meter-droplet": "meter-droplet",
  chalkboard: "chalkboard",
  blackboard: "chalkboard",
  "user-large-slash": "user-large-slash",
  "user-alt-slash": "user-large-slash",
  "signal-strong": "signal-strong",
  "signal-4": "signal-strong",
  lollipop: "lollipop",
  lollypop: "lollipop",
  "list-tree": "list-tree",
  "envelope-open": "envelope-open",
  "draw-circle": "draw-circle",
  "cat-space": "cat-space",
  "handshake-simple-slash": "handshake-simple-slash",
  "handshake-alt-slash": "handshake-simple-slash",
  "rabbit-running": "rabbit-running",
  "rabbit-fast": "rabbit-running",
  "memo-pad": "memo-pad",
  "mattress-pillow": "mattress-pillow",
  "alarm-plus": "alarm-plus",
  alicorn: "alicorn",
  "comment-question": "comment-question",
  "gingerbread-man": "gingerbread-man",
  "guarani-sign": "guarani-sign",
  "burger-fries": "burger-fries",
  "mug-tea": "mug-tea",
  "border-top": "border-top",
  "arrows-rotate": "arrows-rotate",
  refresh: "arrows-rotate",
  sync: "arrows-rotate",
  "circle-book-open": "circle-book-open",
  "book-circle": "circle-book-open",
  "arrows-to-dotted-line": "arrows-to-dotted-line",
  "fire-extinguisher": "fire-extinguisher",
  "magnifying-glass-arrows-rotate": "magnifying-glass-arrows-rotate",
  "garage-open": "garage-open",
  "shelves-empty": "shelves-empty",
  "cruzeiro-sign": "cruzeiro-sign",
  "watch-apple": "watch-apple",
  "watch-calculator": "watch-calculator",
  "list-dropdown": "list-dropdown",
  "cabinet-filing": "cabinet-filing",
  "burger-soda": "burger-soda",
  "square-arrow-up": "square-arrow-up",
  "arrow-square-up": "square-arrow-up",
  "greater-than-equal": "greater-than-equal",
  "pallet-box": "pallet-box",
  "face-confounded": "face-confounded",
  "shield-halved": "shield-halved",
  "shield-alt": "shield-halved",
  "truck-plow": "truck-plow",
  "book-atlas": "book-atlas",
  atlas: "book-atlas",
  virus: "virus",
  "grid-round-2": "grid-round-2",
  "comment-middle-top": "comment-middle-top",
  wave: "wave",
  "envelope-circle-check": "envelope-circle-check",
  "layer-group": "layer-group",
  "restroom-simple": "restroom-simple",
  "arrows-to-dot": "arrows-to-dot",
  "border-outer": "border-outer",
  "hashtag-lock": "hashtag-lock",
  "clock-two-thirty": "clock-two-thirty",
  archway: "archway",
  "heart-circle-check": "heart-circle-check",
  "house-chimney-crack": "house-chimney-crack",
  "house-damage": "house-chimney-crack",
  "file-zipper": "file-zipper",
  "file-archive": "file-zipper",
  "ticket-perforated": "ticket-perforated",
  "heart-half": "heart-half",
  "comment-check": "comment-check",
  square: "square",
  memo: "memo",
  "martini-glass-empty": "martini-glass-empty",
  "glass-martini": "martini-glass-empty",
  couch: "couch",
  "cedi-sign": "cedi-sign",
  italic: "italic",
  "glass-citrus": "glass-citrus",
  "calendar-lines-pen": "calendar-lines-pen",
  "table-cells-column-lock": "table-cells-column-lock",
  church: "church",
  "person-snowmobiling": "person-snowmobiling",
  snowmobile: "person-snowmobiling",
  "face-hushed": "face-hushed",
  "comments-dollar": "comments-dollar",
  "tickets-simple": "tickets-simple",
  pickaxe: "pickaxe",
  "link-simple-slash": "link-simple-slash",
  democrat: "democrat",
  "face-confused": "face-confused",
  pinball: "pinball",
  z: "z",
  "person-skiing": "person-skiing",
  skiing: "person-skiing",
  deer: "deer",
  "input-pipe": "input-pipe",
  "road-lock": "road-lock",
  a: "a",
  "bookmark-slash": "bookmark-slash",
  "temperature-arrow-down": "temperature-arrow-down",
  "temperature-down": "temperature-arrow-down",
  mace: "mace",
  "feather-pointed": "feather-pointed",
  "feather-alt": "feather-pointed",
  sausage: "sausage",
  "trash-can-clock": "trash-can-clock",
  p: "p",
  "broom-wide": "broom-wide",
  snowflake: "snowflake",
  stomach: "stomach",
  newspaper: "newspaper",
  "rectangle-ad": "rectangle-ad",
  ad: "rectangle-ad",
  "guitar-electric": "guitar-electric",
  "arrow-turn-down-right": "arrow-turn-down-right",
  "moon-cloud": "moon-cloud",
  "bread-slice-butter": "bread-slice-butter",
  "circle-arrow-right": "circle-arrow-right",
  "arrow-circle-right": "circle-arrow-right",
  "user-group-crown": "user-group-crown",
  "users-crown": "user-group-crown",
  "circle-i": "circle-i",
  "toilet-paper-check": "toilet-paper-check",
  "filter-circle-xmark": "filter-circle-xmark",
  locust: "locust",
  sort: "sort",
  unsorted: "sort",
  "list-ol": "list-ol",
  "list-1-2": "list-ol",
  "list-numeric": "list-ol",
  "chart-waterfall": "chart-waterfall",
  sparkle: "sparkle",
  "face-party": "face-party",
  kidneys: "kidneys",
  "wifi-exclamation": "wifi-exclamation",
  "chart-network": "chart-network",
  "person-dress-burst": "person-dress-burst",
  "dice-d4": "dice-d4",
  "money-check-dollar": "money-check-dollar",
  "money-check-alt": "money-check-dollar",
  "vector-square": "vector-square",
  "bread-slice": "bread-slice",
  language: "language",
  "wheat-awn-slash": "wheat-awn-slash",
  "face-kiss-wink-heart": "face-kiss-wink-heart",
  "kiss-wink-heart": "face-kiss-wink-heart",
  dagger: "dagger",
  podium: "podium",
  "memo-circle-check": "memo-circle-check",
  "route-highway": "route-highway",
  "down-to-line": "down-to-line",
  "arrow-alt-to-bottom": "down-to-line",
  filter: "filter",
  "square-g": "square-g",
  "circle-phone": "circle-phone",
  "phone-circle": "circle-phone",
  "clipboard-prescription": "clipboard-prescription",
  "user-nurse-hair": "user-nurse-hair",
  question: "question",
  "file-signature": "file-signature",
  "toggle-large-on": "toggle-large-on",
  "up-down-left-right": "up-down-left-right",
  "arrows-alt": "up-down-left-right",
  "dryer-heat": "dryer-heat",
  "dryer-alt": "dryer-heat",
  "house-chimney-user": "house-chimney-user",
  "hand-holding-heart": "hand-holding-heart",
  "arrow-up-small-big": "arrow-up-small-big",
  "sort-size-up-alt": "arrow-up-small-big",
  "train-track": "train-track",
  "puzzle-piece": "puzzle-piece",
  "money-check": "money-check",
  "star-half-stroke": "star-half-stroke",
  "star-half-alt": "star-half-stroke",
  "file-exclamation": "file-exclamation",
  code: "code",
  "whiskey-glass": "whiskey-glass",
  "glass-whiskey": "whiskey-glass",
  "moon-stars": "moon-stars",
  "building-circle-exclamation": "building-circle-exclamation",
  "clothes-hanger": "clothes-hanger",
  "mobile-notch": "mobile-notch",
  "mobile-iphone": "mobile-notch",
  "magnifying-glass-chart": "magnifying-glass-chart",
  "arrow-up-right-from-square": "arrow-up-right-from-square",
  "external-link": "arrow-up-right-from-square",
  "cubes-stacked": "cubes-stacked",
  "images-user": "images-user",
  "won-sign": "won-sign",
  krw: "won-sign",
  won: "won-sign",
  "image-polaroid-user": "image-polaroid-user",
  "virus-covid": "virus-covid",
  "square-ellipsis": "square-ellipsis",
  pie: "pie",
  "chess-knight-piece": "chess-knight-piece",
  "chess-knight-alt": "chess-knight-piece",
  "austral-sign": "austral-sign",
  "cloud-plus": "cloud-plus",
  f: "f",
  leaf: "leaf",
  "bed-bunk": "bed-bunk",
  road: "road",
  taxi: "taxi",
  cab: "taxi",
  "person-circle-plus": "person-circle-plus",
  "chart-pie": "chart-pie",
  "pie-chart": "chart-pie",
  "bolt-lightning": "bolt-lightning",
  "clock-eight": "clock-eight",
  "sack-xmark": "sack-xmark",
  "file-xls": "file-xls",
  "file-excel": "file-excel",
  "file-contract": "file-contract",
  "fish-fins": "fish-fins",
  "circle-q": "circle-q",
  "building-flag": "building-flag",
  "face-grin-beam": "face-grin-beam",
  "grin-beam": "face-grin-beam",
  "object-ungroup": "object-ungroup",
  "face-disguise": "face-disguise",
  "circle-arrow-down-right": "circle-arrow-down-right",
  "alien-8bit": "alien-8bit",
  "alien-monster": "alien-8bit",
  "hand-point-ribbon": "hand-point-ribbon",
  poop: "poop",
  "object-exclude": "object-exclude",
  telescope: "telescope",
  "location-pin": "location-pin",
  "map-marker": "location-pin",
  "square-list": "square-list",
  kaaba: "kaaba",
  "toilet-paper": "toilet-paper",
  "helmet-safety": "helmet-safety",
  "hard-hat": "helmet-safety",
  "hat-hard": "helmet-safety",
  "comment-code": "comment-code",
  "sim-cards": "sim-cards",
  starship: "starship",
  eject: "eject",
  "circle-right": "circle-right",
  "arrow-alt-circle-right": "circle-right",
  "plane-circle-check": "plane-circle-check",
  seal: "seal",
  "user-cowboy": "user-cowboy",
  "hexagon-vertical-nft": "hexagon-vertical-nft",
  "face-rolling-eyes": "face-rolling-eyes",
  "meh-rolling-eyes": "face-rolling-eyes",
  "bread-loaf": "bread-loaf",
  "rings-wedding": "rings-wedding",
  "object-group": "object-group",
  "french-fries": "french-fries",
  "chart-line": "chart-line",
  "line-chart": "chart-line",
  "calendar-arrow-down": "calendar-arrow-down",
  "calendar-download": "calendar-arrow-down",
  "send-back": "send-back",
  "mask-ventilator": "mask-ventilator",
  tickets: "tickets",
  "signature-lock": "signature-lock",
  "arrow-right": "arrow-right",
  "signs-post": "signs-post",
  "map-signs": "signs-post",
  "octagon-plus": "octagon-plus",
  "plus-octagon": "octagon-plus",
  "cash-register": "cash-register",
  "person-circle-question": "person-circle-question",
  "melon-slice": "melon-slice",
  "space-station-moon": "space-station-moon",
  "message-smile": "message-smile",
  "comment-alt-smile": "message-smile",
  "cup-straw": "cup-straw",
  "left-from-line": "left-from-line",
  "arrow-alt-from-right": "left-from-line",
  h: "h",
  "basket-shopping-simple": "basket-shopping-simple",
  "shopping-basket-alt": "basket-shopping-simple",
  "hands-holding-heart": "hands-holding-heart",
  "hands-heart": "hands-holding-heart",
  "clock-nine": "clock-nine",
  "hammer-brush": "hammer-brush",
  tarp: "tarp",
  "face-sleepy": "face-sleepy",
  "hand-horns": "hand-horns",
  "screwdriver-wrench": "screwdriver-wrench",
  tools: "screwdriver-wrench",
  "arrows-to-eye": "arrows-to-eye",
  "circle-three-quarters": "circle-three-quarters",
  "trophy-star": "trophy-star",
  "trophy-alt": "trophy-star",
  "plug-circle-bolt": "plug-circle-bolt",
  "face-thermometer": "face-thermometer",
  "grid-round-4": "grid-round-4",
  "sign-posts-wrench": "sign-posts-wrench",
  "shirt-running": "shirt-running",
  "book-circle-arrow-up": "book-circle-arrow-up",
  "face-nauseated": "face-nauseated",
  heart: "heart",
  "file-chart-pie": "file-chart-pie",
  "mars-and-venus": "mars-and-venus",
  "house-user": "house-user",
  "home-user": "house-user",
  "circle-arrow-down-left": "circle-arrow-down-left",
  "dumpster-fire": "dumpster-fire",
  "hexagon-minus": "hexagon-minus",
  "minus-hexagon": "hexagon-minus",
  "left-to-line": "left-to-line",
  "arrow-alt-to-left": "left-to-line",
  "house-crack": "house-crack",
  "paw-simple": "paw-simple",
  "paw-alt": "paw-simple",
  "arrow-left-long-to-line": "arrow-left-long-to-line",
  "brackets-round": "brackets-round",
  parentheses: "brackets-round",
  "martini-glass-citrus": "martini-glass-citrus",
  cocktail: "martini-glass-citrus",
  "user-shakespeare": "user-shakespeare",
  "arrow-right-to-arc": "arrow-right-to-arc",
  "face-surprise": "face-surprise",
  surprise: "face-surprise",
  "bottle-water": "bottle-water",
  "circle-pause": "circle-pause",
  "pause-circle": "circle-pause",
  "gauge-circle-plus": "gauge-circle-plus",
  folders: "folders",
  angel: "angel",
  "value-absolute": "value-absolute",
  rabbit: "rabbit",
  "toilet-paper-slash": "toilet-paper-slash",
  "circle-euro": "circle-euro",
  "apple-whole": "apple-whole",
  "apple-alt": "apple-whole",
  "kitchen-set": "kitchen-set",
  "diamond-half": "diamond-half",
  "lock-keyhole": "lock-keyhole",
  "lock-alt": "lock-keyhole",
  r: "r",
  "temperature-quarter": "temperature-quarter",
  "temperature-1": "temperature-quarter",
  "thermometer-1": "temperature-quarter",
  "thermometer-quarter": "temperature-quarter",
  "square-info": "square-info",
  "info-square": "square-info",
  "wifi-slash": "wifi-slash",
  "toilet-paper-xmark": "toilet-paper-xmark",
  "hands-holding-dollar": "hands-holding-dollar",
  "hands-usd": "hands-holding-dollar",
  cube: "cube",
  "arrow-down-triangle-square": "arrow-down-triangle-square",
  "sort-shapes-down": "arrow-down-triangle-square",
  "bitcoin-sign": "bitcoin-sign",
  shutters: "shutters",
  "shield-dog": "shield-dog",
  "solar-panel": "solar-panel",
  "lock-open": "lock-open",
  "table-tree": "table-tree",
  "house-chimney-heart": "house-chimney-heart",
  "tally-3": "tally-3",
  elevator: "elevator",
  "money-bill-transfer": "money-bill-transfer",
  "money-bill-trend-up": "money-bill-trend-up",
  "house-flood-water-circle-arrow-right": "house-flood-water-circle-arrow-right",
  "square-poll-horizontal": "square-poll-horizontal",
  "poll-h": "square-poll-horizontal",
  circle: "circle",
  "left-to-bracket": "left-to-bracket",
  "cart-circle-exclamation": "cart-circle-exclamation",
  sword: "sword",
  "backward-fast": "backward-fast",
  "fast-backward": "backward-fast",
  recycle: "recycle",
  "user-astronaut": "user-astronaut",
  interrobang: "interrobang",
  "plane-slash": "plane-slash",
  "circle-dashed": "circle-dashed",
  trademark: "trademark",
  basketball: "basketball",
  "basketball-ball": "basketball",
  "fork-knife": "fork-knife",
  "utensils-alt": "fork-knife",
  "satellite-dish": "satellite-dish",
  "badge-check": "badge-check",
  "circle-up": "circle-up",
  "arrow-alt-circle-up": "circle-up",
  slider: "slider",
  "mobile-screen-button": "mobile-screen-button",
  "mobile-alt": "mobile-screen-button",
  "clock-one-thirty": "clock-one-thirty",
  "inbox-out": "inbox-out",
  "inbox-arrow-up": "inbox-out",
  "cloud-slash": "cloud-slash",
  "volume-high": "volume-high",
  "volume-up": "volume-high",
  "users-rays": "users-rays",
  wallet: "wallet",
  "octagon-check": "octagon-check",
  "flatbread-stuffed": "flatbread-stuffed",
  "clipboard-check": "clipboard-check",
  "cart-circle-plus": "cart-circle-plus",
  "truck-clock": "truck-clock",
  "shipping-timed": "truck-clock",
  "pool-8-ball": "pool-8-ball",
  "file-audio": "file-audio",
  "turn-down-left": "turn-down-left",
  "lock-hashtag": "lock-hashtag",
  "chart-radar": "chart-radar",
  staff: "staff",
  burger: "burger",
  hamburger: "burger",
  "utility-pole": "utility-pole",
  "transporter-6": "transporter-6",
  "arrow-turn-left": "arrow-turn-left",
  wrench: "wrench",
  bugs: "bugs",
  "vector-polygon": "vector-polygon",
  "diagram-nested": "diagram-nested",
  "rupee-sign": "rupee-sign",
  rupee: "rupee-sign",
  "file-image": "file-image",
  "circle-question": "circle-question",
  "question-circle": "circle-question",
  "tickets-perforated": "tickets-perforated",
  "image-user": "image-user",
  buoy: "buoy",
  "plane-departure": "plane-departure",
  "handshake-slash": "handshake-slash",
  "book-bookmark": "book-bookmark",
  "border-center-h": "border-center-h",
  "can-food": "can-food",
  typewriter: "typewriter",
  "arrow-right-from-arc": "arrow-right-from-arc",
  "circle-k": "circle-k",
  "face-hand-over-mouth": "face-hand-over-mouth",
  popcorn: "popcorn",
  "house-water": "house-water",
  "house-flood": "house-water",
  "object-subtract": "object-subtract",
  "code-branch": "code-branch",
  "warehouse-full": "warehouse-full",
  "warehouse-alt": "warehouse-full",
  "hat-cowboy": "hat-cowboy",
  bridge: "bridge",
  "phone-flip": "phone-flip",
  "phone-alt": "phone-flip",
  "arrow-down-from-dotted-line": "arrow-down-from-dotted-line",
  "file-doc": "file-doc",
  "square-quarters": "square-quarters",
  "truck-front": "truck-front",
  cat: "cat",
  "trash-xmark": "trash-xmark",
  "circle-caret-left": "circle-caret-left",
  "caret-circle-left": "circle-caret-left",
  files: "files",
  "anchor-circle-exclamation": "anchor-circle-exclamation",
  "face-clouds": "face-clouds",
  "user-crown": "user-crown",
  "basket-shopping-plus": "basket-shopping-plus",
  "truck-field": "truck-field",
  route: "route",
  "cart-circle-check": "cart-circle-check",
  "clipboard-question": "clipboard-question",
  panorama: "panorama",
  "comment-medical": "comment-medical",
  "teeth-open": "teeth-open",
  "user-tie-hair-long": "user-tie-hair-long",
  "file-circle-minus": "file-circle-minus",
  "head-side-medical": "head-side-medical",
  "arrow-turn-right": "arrow-turn-right",
  tags: "tags",
  "wine-glass": "wine-glass",
  "forward-fast": "forward-fast",
  "fast-forward": "forward-fast",
  "face-meh-blank": "face-meh-blank",
  "meh-blank": "face-meh-blank",
  "user-robot": "user-robot",
  "square-parking": "square-parking",
  parking: "square-parking",
  "card-diamond": "card-diamond",
  "face-zipper": "face-zipper",
  "face-raised-eyebrow": "face-raised-eyebrow",
  "house-signal": "house-signal",
  "square-chevron-up": "square-chevron-up",
  "chevron-square-up": "square-chevron-up",
  "nfc-symbol": "nfc-symbol",
  "bars-progress": "bars-progress",
  "tasks-alt": "bars-progress",
  "faucet-drip": "faucet-drip",
  "arrows-to-line": "arrows-to-line",
  dolphin: "dolphin",
  "arrow-up-right": "arrow-up-right",
  "circle-r": "circle-r",
  "cart-flatbed": "cart-flatbed",
  "dolly-flatbed": "cart-flatbed",
  "ban-smoking": "ban-smoking",
  "smoking-ban": "ban-smoking",
  "circle-sort-up": "circle-sort-up",
  "sort-circle-up": "circle-sort-up",
  terminal: "terminal",
  "mobile-button": "mobile-button",
  "house-medical-flag": "house-medical-flag",
  "basket-shopping": "basket-shopping",
  "shopping-basket": "basket-shopping",
  tape: "tape",
  chestnut: "chestnut",
  "bus-simple": "bus-simple",
  "bus-alt": "bus-simple",
  eye: "eye",
  "face-sad-cry": "face-sad-cry",
  "sad-cry": "face-sad-cry",
  heat: "heat",
  "ticket-airline": "ticket-airline",
  "ticket-perforated-plane": "ticket-airline",
  "ticket-plane": "ticket-airline",
  "boot-heeled": "boot-heeled",
  "arrows-minimize": "arrows-minimize",
  "compress-arrows": "arrows-minimize",
  "audio-description": "audio-description",
  "person-military-to-person": "person-military-to-person",
  "file-shield": "file-shield",
  hexagon: "hexagon",
  manhole: "manhole",
  "user-slash": "user-slash",
  pen: "pen",
  "tower-observation": "tower-observation",
  "floppy-disks": "floppy-disks",
  "toilet-paper-blank-under": "toilet-paper-blank-under",
  "toilet-paper-reverse-alt": "toilet-paper-blank-under",
  "file-code": "file-code",
  signal: "signal",
  "signal-5": "signal",
  "signal-perfect": "signal",
  pump: "pump",
  bus: "bus",
  "heart-circle-xmark": "heart-circle-xmark",
  "arrow-up-left-from-circle": "arrow-up-left-from-circle",
  "house-chimney": "house-chimney",
  "home-lg": "house-chimney",
  "window-maximize": "window-maximize",
  dryer: "dryer",
  "face-frown": "face-frown",
  frown: "face-frown",
  "chess-bishop-piece": "chess-bishop-piece",
  "chess-bishop-alt": "chess-bishop-piece",
  "shirt-tank-top": "shirt-tank-top",
  diploma: "diploma",
  "scroll-ribbon": "diploma",
  screencast: "screencast",
  walker: "walker",
  prescription: "prescription",
  shop: "shop",
  "store-alt": "shop",
  "floppy-disk": "floppy-disk",
  save: "floppy-disk",
  vihara: "vihara",
  "face-kiss-closed-eyes": "face-kiss-closed-eyes",
  "scale-unbalanced": "scale-unbalanced",
  "balance-scale-left": "scale-unbalanced",
  "file-user": "file-user",
  "user-police-tie": "user-police-tie",
  "face-tongue-money": "face-tongue-money",
  "tennis-ball": "tennis-ball",
  "square-l": "square-l",
  "sort-up": "sort-up",
  "sort-asc": "sort-up",
  "calendar-arrow-up": "calendar-arrow-up",
  "calendar-upload": "calendar-arrow-up",
  "comment-dots": "comment-dots",
  commenting: "comment-dots",
  "plant-wilt": "plant-wilt",
  scarf: "scarf",
  "album-circle-plus": "album-circle-plus",
  "user-nurse-hair-long": "user-nurse-hair-long",
  diamond: "diamond",
  "square-left": "square-left",
  "arrow-alt-square-left": "square-left",
  "face-grin-squint": "face-grin-squint",
  "grin-squint": "face-grin-squint",
  "circle-ellipsis-vertical": "circle-ellipsis-vertical",
  "hand-holding-dollar": "hand-holding-dollar",
  "hand-holding-usd": "hand-holding-dollar",
  "grid-dividers": "grid-dividers",
  bacterium: "bacterium",
  "hand-pointer": "hand-pointer",
  "drum-steelpan": "drum-steelpan",
  "hand-scissors": "hand-scissors",
  "hands-praying": "hands-praying",
  "praying-hands": "hands-praying",
  "face-pensive": "face-pensive",
  "user-music": "user-music",
  "arrow-rotate-right": "arrow-rotate-right",
  "arrow-right-rotate": "arrow-rotate-right",
  "arrow-rotate-forward": "arrow-rotate-right",
  redo: "arrow-rotate-right",
  "messages-dollar": "messages-dollar",
  "comments-alt-dollar": "messages-dollar",
  "sensor-on": "sensor-on",
  balloon: "balloon",
  "web-awesome": "web-awesome",
  biohazard: "biohazard",
  "chess-queen-piece": "chess-queen-piece",
  "chess-queen-alt": "chess-queen-piece",
  "location-crosshairs": "location-crosshairs",
  location: "location-crosshairs",
  "mars-double": "mars-double",
  "left-from-bracket": "left-from-bracket",
  "house-person-leave": "house-person-leave",
  "house-leave": "house-person-leave",
  "house-person-depart": "house-person-leave",
  "ruler-triangle": "ruler-triangle",
  "card-club": "card-club",
  "child-dress": "child-dress",
  "users-between-lines": "users-between-lines",
  "lungs-virus": "lungs-virus",
  "spinner-third": "spinner-third",
  "face-grin-tears": "face-grin-tears",
  "grin-tears": "face-grin-tears",
  phone: "phone",
  "computer-mouse-scrollwheel": "computer-mouse-scrollwheel",
  "mouse-alt": "computer-mouse-scrollwheel",
  "calendar-xmark": "calendar-xmark",
  "calendar-times": "calendar-xmark",
  "child-reaching": "child-reaching",
  "table-layout": "table-layout",
  narwhal: "narwhal",
  "ramp-loading": "ramp-loading",
  "calendar-circle-plus": "calendar-circle-plus",
  toothbrush: "toothbrush",
  "border-inner": "border-inner",
  "paw-claws": "paw-claws",
  "kiwi-fruit": "kiwi-fruit",
  "traffic-light-slow": "traffic-light-slow",
  "rectangle-code": "rectangle-code",
  "head-side-virus": "head-side-virus",
  "keyboard-brightness": "keyboard-brightness",
  "books-medical": "books-medical",
  "lightbulb-slash": "lightbulb-slash",
  "house-blank": "house-blank",
  "home-blank": "house-blank",
  "square-5": "square-5",
  "square-heart": "square-heart",
  "heart-square": "square-heart",
  puzzle: "puzzle",
  "user-gear": "user-gear",
  "user-cog": "user-gear",
  "pipe-circle-check": "pipe-circle-check",
  "arrow-up-1-9": "arrow-up-1-9",
  "sort-numeric-up": "arrow-up-1-9",
  "octagon-exclamation": "octagon-exclamation",
  "dial-low": "dial-low",
  "door-closed": "door-closed",
  "laptop-mobile": "laptop-mobile",
  "phone-laptop": "laptop-mobile",
  "conveyor-belt-boxes": "conveyor-belt-boxes",
  "conveyor-belt-alt": "conveyor-belt-boxes",
  "shield-virus": "shield-virus",
  "starfighter-twin-ion-engine-advanced": "starfighter-twin-ion-engine-advanced",
  "starfighter-alt-advanced": "starfighter-twin-ion-engine-advanced",
  "dice-six": "dice-six",
  "starfighter-twin-ion-engine": "starfighter-twin-ion-engine",
  "starfighter-alt": "starfighter-twin-ion-engine",
  "rocket-launch": "rocket-launch",
  "mosquito-net": "mosquito-net",
  "vent-damper": "vent-damper",
  "bridge-water": "bridge-water",
  "ban-bug": "ban-bug",
  debug: "ban-bug",
  "person-booth": "person-booth",
  "text-width": "text-width",
  "garage-car": "garage-car",
  "square-kanban": "square-kanban",
  "hat-wizard": "hat-wizard",
  "chart-kanban": "chart-kanban",
  "pen-fancy": "pen-fancy",
  "coffee-pot": "coffee-pot",
  "mouse-field": "mouse-field",
  "person-digging": "person-digging",
  digging: "person-digging",
  "shower-down": "shower-down",
  "shower-alt": "shower-down",
  "box-circle-check": "box-circle-check",
  brightness: "brightness",
  "car-side-bolt": "car-side-bolt",
  "file-xml": "file-xml",
  ornament: "ornament",
  "phone-arrow-down-left": "phone-arrow-down-left",
  "phone-arrow-down": "phone-arrow-down-left",
  "phone-incoming": "phone-arrow-down-left",
  "cloud-word": "cloud-word",
  "hand-fingers-crossed": "hand-fingers-crossed",
  trash: "trash",
  "gauge-simple": "gauge-simple",
  "gauge-simple-med": "gauge-simple",
  "tachometer-average": "gauge-simple",
  "arrow-down-small-big": "arrow-down-small-big",
  "sort-size-down-alt": "arrow-down-small-big",
  "book-medical": "book-medical",
  "face-melting": "face-melting",
  poo: "poo",
  "pen-clip-slash": "pen-clip-slash",
  "pen-alt-slash": "pen-clip-slash",
  "quote-right": "quote-right",
  "quote-right-alt": "quote-right",
  "scroll-old": "scroll-old",
  guitars: "guitars",
  "phone-xmark": "phone-xmark",
  hose: "hose",
  "clock-six": "clock-six",
  shirt: "shirt",
  "t-shirt": "shirt",
  tshirt: "shirt",
  billboard: "billboard",
  "square-r": "square-r",
  cubes: "cubes",
  "envelope-open-dollar": "envelope-open-dollar",
  divide: "divide",
  "sun-cloud": "sun-cloud",
  "lamp-floor": "lamp-floor",
  "square-7": "square-7",
  "tenge-sign": "tenge-sign",
  tenge: "tenge-sign",
  headphones: "headphones",
  "hands-holding": "hands-holding",
  campfire: "campfire",
  "circle-ampersand": "circle-ampersand",
  snowflakes: "snowflakes",
  "hands-clapping": "hands-clapping",
  republican: "republican",
  "leaf-maple": "leaf-maple",
  "arrow-left": "arrow-left",
  "person-circle-xmark": "person-circle-xmark",
  ruler: "ruler",
  "arrow-left-from-bracket": "arrow-left-from-bracket",
  "cup-straw-swoosh": "cup-straw-swoosh",
  "temperature-sun": "temperature-sun",
  "temperature-hot": "temperature-sun",
  "align-left": "align-left",
  "dice-d6": "dice-d6",
  restroom: "restroom",
  "high-definition": "high-definition",
  "rectangle-hd": "high-definition",
  j: "j",
  galaxy: "galaxy",
  "users-viewfinder": "users-viewfinder",
  "file-video": "file-video",
  cherries: "cherries",
  "up-right-from-square": "up-right-from-square",
  "external-link-alt": "up-right-from-square",
  "circle-sort": "circle-sort",
  "sort-circle": "circle-sort",
  "table-cells": "table-cells",
  th: "table-cells",
  "bag-shopping-minus": "bag-shopping-minus",
  "file-pdf": "file-pdf",
  siren: "siren",
  "arrow-up-to-dotted-line": "arrow-up-to-dotted-line",
  "image-landscape": "image-landscape",
  landscape: "image-landscape",
  "tank-water": "tank-water",
  "curling-stone": "curling-stone",
  curling: "curling-stone",
  "gamepad-modern": "gamepad-modern",
  "gamepad-alt": "gamepad-modern",
  "messages-question": "messages-question",
  "book-bible": "book-bible",
  bible: "book-bible",
  o: "o",
  "suitcase-medical": "suitcase-medical",
  medkit: "suitcase-medical",
  "briefcase-arrow-right": "briefcase-arrow-right",
  "expand-wide": "expand-wide",
  "clock-eleven-thirty": "clock-eleven-thirty",
  rv: "rv",
  "user-secret": "user-secret",
  otter: "otter",
  dreidel: "dreidel",
  "person-dress": "person-dress",
  female: "person-dress",
  "comment-dollar": "comment-dollar",
  "business-time": "business-time",
  "briefcase-clock": "business-time",
  "flower-tulip": "flower-tulip",
  "people-pants-simple": "people-pants-simple",
  "cloud-drizzle": "cloud-drizzle",
  "table-cells-large": "table-cells-large",
  "th-large": "table-cells-large",
  "book-tanakh": "book-tanakh",
  tanakh: "book-tanakh",
  "solar-system": "solar-system",
  "seal-question": "seal-question",
  "phone-volume": "phone-volume",
  "volume-control-phone": "phone-volume",
  "disc-drive": "disc-drive",
  "hat-cowboy-side": "hat-cowboy-side",
  "table-rows": "table-rows",
  rows: "table-rows",
  "location-exclamation": "location-exclamation",
  "map-marker-exclamation": "location-exclamation",
  "face-fearful": "face-fearful",
  "clipboard-user": "clipboard-user",
  "bus-school": "bus-school",
  "film-slash": "film-slash",
  "square-arrow-down-right": "square-arrow-down-right",
  "book-sparkles": "book-sparkles",
  "book-spells": "book-sparkles",
  "washing-machine": "washing-machine",
  washer: "washing-machine",
  child: "child",
  "lira-sign": "lira-sign",
  "user-visor": "user-visor",
  "file-plus-minus": "file-plus-minus",
  "chess-clock-flip": "chess-clock-flip",
  "chess-clock-alt": "chess-clock-flip",
  satellite: "satellite",
  "truck-fire": "truck-fire",
  "plane-lock": "plane-lock",
  "steering-wheel": "steering-wheel",
  tag: "tag",
  stretcher: "stretcher",
  "book-section": "book-section",
  "book-law": "book-section",
  inboxes: "inboxes",
  "coffee-bean": "coffee-bean",
  "circle-yen": "circle-yen",
  "brackets-curly": "brackets-curly",
  "ellipsis-stroke-vertical": "ellipsis-stroke-vertical",
  "ellipsis-v-alt": "ellipsis-stroke-vertical",
  comment: "comment",
  "square-1": "square-1",
  "cake-candles": "cake-candles",
  "birthday-cake": "cake-candles",
  cake: "cake-candles",
  "head-side": "head-side",
  "truck-ladder": "truck-ladder",
  envelope: "envelope",
  "dolly-empty": "dolly-empty",
  "face-tissue": "face-tissue",
  "angles-up": "angles-up",
  "angle-double-up": "angles-up",
  "bin-recycle": "bin-recycle",
  paperclip: "paperclip",
  "chart-line-down": "chart-line-down",
  "arrow-right-to-city": "arrow-right-to-city",
  "lock-a": "lock-a",
  ribbon: "ribbon",
  lungs: "lungs",
  "person-pinball": "person-pinball",
  "arrow-up-9-1": "arrow-up-9-1",
  "sort-numeric-up-alt": "arrow-up-9-1",
  "apple-core": "apple-core",
  "circle-y": "circle-y",
  h6: "h6",
  "litecoin-sign": "litecoin-sign",
  "bottle-baby": "bottle-baby",
  "circle-small": "circle-small",
  "border-none": "border-none",
  "arrow-turn-down-left": "arrow-turn-down-left",
  "circle-wifi-circle-wifi": "circle-wifi-circle-wifi",
  "circle-wifi-group": "circle-wifi-circle-wifi",
  "circle-nodes": "circle-nodes",
  "parachute-box": "parachute-box",
  "reflect-horizontal": "reflect-horizontal",
  "message-medical": "message-medical",
  "comment-alt-medical": "message-medical",
  "rugby-ball": "rugby-ball",
  "comment-music": "comment-music",
  indent: "indent",
  "tree-deciduous": "tree-deciduous",
  "tree-alt": "tree-deciduous",
  "puzzle-piece-simple": "puzzle-piece-simple",
  "puzzle-piece-alt": "puzzle-piece-simple",
  "truck-field-un": "truck-field-un",
  "nfc-trash": "nfc-trash",
  hourglass: "hourglass",
  "hourglass-empty": "hourglass",
  mountain: "mountain",
  "file-xmark": "file-xmark",
  "file-times": "file-xmark",
  "house-heart": "house-heart",
  "home-heart": "house-heart",
  "house-chimney-blank": "house-chimney-blank",
  "meter-bolt": "meter-bolt",
  "user-doctor": "user-doctor",
  "user-md": "user-doctor",
  "slash-back": "slash-back",
  "circle-info": "circle-info",
  "info-circle": "circle-info",
  "fishing-rod": "fishing-rod",
  "hammer-crash": "hammer-crash",
  "message-heart": "message-heart",
  "cloud-meatball": "cloud-meatball",
  "camera-polaroid": "camera-polaroid",
  camera: "camera",
  "camera-alt": "camera",
  "square-virus": "square-virus",
  "cart-arrow-up": "cart-arrow-up",
  meteor: "meteor",
  "car-on": "car-on",
  sleigh: "sleigh",
  "arrow-down-1-9": "arrow-down-1-9",
  "sort-numeric-asc": "arrow-down-1-9",
  "sort-numeric-down": "arrow-down-1-9",
  "buoy-mooring": "buoy-mooring",
  "square-4": "square-4",
  "hand-holding-droplet": "hand-holding-droplet",
  "hand-holding-water": "hand-holding-droplet",
  "file-eps": "file-eps",
  "tricycle-adult": "tricycle-adult",
  waveform: "waveform",
  water: "water",
  "star-sharp-half-stroke": "star-sharp-half-stroke",
  "star-sharp-half-alt": "star-sharp-half-stroke",
  "nfc-signal": "nfc-signal",
  "plane-prop": "plane-prop",
  "calendar-check": "calendar-check",
  "clock-desk": "clock-desk",
  "calendar-clock": "calendar-clock",
  "calendar-time": "calendar-clock",
  braille: "braille",
  "prescription-bottle-medical": "prescription-bottle-medical",
  "prescription-bottle-alt": "prescription-bottle-medical",
  "plate-utensils": "plate-utensils",
  "family-pants": "family-pants",
  "hose-reel": "hose-reel",
  "house-window": "house-window",
  landmark: "landmark",
  truck: "truck",
  "music-magnifying-glass": "music-magnifying-glass",
  crosshairs: "crosshairs",
  "cloud-rainbow": "cloud-rainbow",
  "person-cane": "person-cane",
  alien: "alien",
  tent: "tent",
  "laptop-binary": "laptop-binary",
  "vest-patches": "vest-patches",
  "people-dress-simple": "people-dress-simple",
  "check-double": "check-double",
  "arrow-down-a-z": "arrow-down-a-z",
  "sort-alpha-asc": "arrow-down-a-z",
  "sort-alpha-down": "arrow-down-a-z",
  "bowling-ball-pin": "bowling-ball-pin",
  "bell-school-slash": "bell-school-slash",
  "plus-large": "plus-large",
  "money-bill-wheat": "money-bill-wheat",
  "camera-viewfinder": "camera-viewfinder",
  screenshot: "camera-viewfinder",
  "message-music": "message-music",
  "comment-alt-music": "message-music",
  "car-building": "car-building",
  "border-bottom-right": "border-bottom-right",
  "border-style-alt": "border-bottom-right",
  octagon: "octagon",
  "comment-arrow-up-right": "comment-arrow-up-right",
  "octagon-divide": "octagon-divide",
  cookie: "cookie",
  "arrow-rotate-left": "arrow-rotate-left",
  "arrow-left-rotate": "arrow-rotate-left",
  "arrow-rotate-back": "arrow-rotate-left",
  "arrow-rotate-backward": "arrow-rotate-left",
  undo: "arrow-rotate-left",
  "tv-music": "tv-music",
  "hard-drive": "hard-drive",
  hdd: "hard-drive",
  reel: "reel",
  "face-grin-squint-tears": "face-grin-squint-tears",
  "grin-squint-tears": "face-grin-squint-tears",
  dumbbell: "dumbbell",
  "rectangle-list": "rectangle-list",
  "list-alt": "rectangle-list",
  "tarp-droplet": "tarp-droplet",
  "alarm-exclamation": "alarm-exclamation",
  "house-medical-circle-check": "house-medical-circle-check",
  "traffic-cone": "traffic-cone",
  grate: "grate",
  "arrow-down-right": "arrow-down-right",
  "person-skiing-nordic": "person-skiing-nordic",
  "skiing-nordic": "person-skiing-nordic",
  "calendar-plus": "calendar-plus",
  "person-from-portal": "person-from-portal",
  "portal-exit": "person-from-portal",
  "plane-arrival": "plane-arrival",
  "cowbell-circle-plus": "cowbell-circle-plus",
  "cowbell-more": "cowbell-circle-plus",
  "circle-left": "circle-left",
  "arrow-alt-circle-left": "circle-left",
  "distribute-spacing-vertical": "distribute-spacing-vertical",
  "signal-bars-fair": "signal-bars-fair",
  "signal-alt-2": "signal-bars-fair",
  sportsball: "sportsball",
  "game-console-handheld-crank": "game-console-handheld-crank",
  "train-subway": "train-subway",
  subway: "train-subway",
  "chart-gantt": "chart-gantt",
  "face-smile-upside-down": "face-smile-upside-down",
  "ball-pile": "ball-pile",
  "badge-dollar": "badge-dollar",
  "money-bills-simple": "money-bills-simple",
  "money-bills-alt": "money-bills-simple",
  "list-timeline": "list-timeline",
  "indian-rupee-sign": "indian-rupee-sign",
  "indian-rupee": "indian-rupee-sign",
  inr: "indian-rupee-sign",
  "crop-simple": "crop-simple",
  "crop-alt": "crop-simple",
  "money-bill-1": "money-bill-1",
  "money-bill-alt": "money-bill-1",
  "left-long": "left-long",
  "long-arrow-alt-left": "left-long",
  "keyboard-down": "keyboard-down",
  "circle-up-right": "circle-up-right",
  "cloud-bolt-moon": "cloud-bolt-moon",
  "thunderstorm-moon": "cloud-bolt-moon",
  "turn-left-up": "turn-left-up",
  dna: "dna",
  "virus-slash": "virus-slash",
  "bracket-round-right": "bracket-round-right",
  "circle-sterling": "circle-sterling",
  "circle-5": "circle-5",
  minus: "minus",
  subtract: "minus",
  "fire-flame": "fire-flame",
  flame: "fire-flame",
  "right-to-line": "right-to-line",
  "arrow-alt-to-right": "right-to-line",
  gif: "gif",
  chess: "chess",
  "trash-slash": "trash-slash",
  "arrow-left-long": "arrow-left-long",
  "long-arrow-left": "arrow-left-long",
  "plug-circle-check": "plug-circle-check",
  "font-case": "font-case",
  "street-view": "street-view",
  "arrow-down-left": "arrow-down-left",
  "franc-sign": "franc-sign",
  "flask-round-poison": "flask-round-poison",
  "flask-poison": "flask-round-poison",
  "volume-off": "volume-off",
  "book-circle-arrow-right": "book-circle-arrow-right",
  "chart-user": "chart-user",
  "user-chart": "chart-user",
  "hands-asl-interpreting": "hands-asl-interpreting",
  "american-sign-language-interpreting": "hands-asl-interpreting",
  "asl-interpreting": "hands-asl-interpreting",
  "hands-american-sign-language-interpreting": "hands-asl-interpreting",
  "presentation-screen": "presentation-screen",
  presentation: "presentation-screen",
  "circle-bolt": "circle-bolt",
  "face-smile-halo": "face-smile-halo",
  "cart-circle-arrow-down": "cart-circle-arrow-down",
  "house-person-return": "house-person-return",
  "house-person-arrive": "house-person-return",
  "house-return": "house-person-return",
  "message-xmark": "message-xmark",
  "comment-alt-times": "message-xmark",
  "message-times": "message-xmark",
  "file-certificate": "file-certificate",
  "file-award": "file-certificate",
  "user-doctor-hair-long": "user-doctor-hair-long",
  "camera-security": "camera-security",
  "camera-home": "camera-security",
  gear: "gear",
  cog: "gear",
  "droplet-slash": "droplet-slash",
  "tint-slash": "droplet-slash",
  "book-heart": "book-heart",
  mosque: "mosque",
  duck: "duck",
  mosquito: "mosquito",
  "star-of-david": "star-of-david",
  "flag-swallowtail": "flag-swallowtail",
  "flag-alt": "flag-swallowtail",
  "person-military-rifle": "person-military-rifle",
  "car-garage": "car-garage",
  "cart-shopping": "cart-shopping",
  "shopping-cart": "cart-shopping",
  "book-font": "book-font",
  "shield-plus": "shield-plus",
  vials: "vials",
  "eye-dropper-full": "eye-dropper-full",
  "distribute-spacing-horizontal": "distribute-spacing-horizontal",
  "tablet-rugged": "tablet-rugged",
  "temperature-snow": "temperature-snow",
  "temperature-frigid": "temperature-snow",
  moped: "moped",
  "face-smile-plus": "face-smile-plus",
  "smile-plus": "face-smile-plus",
  "radio-tuner": "radio-tuner",
  "radio-alt": "radio-tuner",
  "face-swear": "face-swear",
  "water-arrow-down": "water-arrow-down",
  "water-lower": "water-arrow-down",
  "scanner-touchscreen": "scanner-touchscreen",
  "circle-7": "circle-7",
  "plug-circle-plus": "plug-circle-plus",
  "person-ski-jumping": "person-ski-jumping",
  "ski-jump": "person-ski-jumping",
  "place-of-worship": "place-of-worship",
  "water-arrow-up": "water-arrow-up",
  "water-rise": "water-arrow-up",
  "waveform-lines": "waveform-lines",
  "waveform-path": "waveform-lines",
  split: "split",
  "film-canister": "film-canister",
  "film-cannister": "film-canister",
  "folder-xmark": "folder-xmark",
  "folder-times": "folder-xmark",
  "toilet-paper-blank": "toilet-paper-blank",
  "toilet-paper-alt": "toilet-paper-blank",
  "tablet-screen": "tablet-screen",
  "tablet-android-alt": "tablet-screen",
  "hexagon-vertical-nft-slanted": "hexagon-vertical-nft-slanted",
  "folder-music": "folder-music",
  "display-medical": "display-medical",
  "desktop-medical": "display-medical",
  "share-all": "share-all",
  peapod: "peapod",
  "chess-clock": "chess-clock",
  axe: "axe",
  "square-d": "square-d",
  "grip-vertical": "grip-vertical",
  "mobile-signal-out": "mobile-signal-out",
  "arrow-turn-up": "arrow-turn-up",
  "level-up": "arrow-turn-up",
  u: "u",
  "arrow-up-from-dotted-line": "arrow-up-from-dotted-line",
  "square-root-variable": "square-root-variable",
  "square-root-alt": "square-root-variable",
  "light-switch-on": "light-switch-on",
  "arrow-down-arrow-up": "arrow-down-arrow-up",
  "sort-alt": "arrow-down-arrow-up",
  raindrops: "raindrops",
  dash: "dash",
  "minus-large": "dash",
  clock: "clock",
  "clock-four": "clock",
  "input-numeric": "input-numeric",
  "truck-tow": "truck-tow",
  "backward-step": "backward-step",
  "step-backward": "backward-step",
  pallet: "pallet",
  "car-bolt": "car-bolt",
  "arrows-maximize": "arrows-maximize",
  "expand-arrows": "arrows-maximize",
  faucet: "faucet",
  "cloud-sleet": "cloud-sleet",
  "lamp-street": "lamp-street",
  "list-radio": "list-radio",
  "pen-nib-slash": "pen-nib-slash",
  "baseball-bat-ball": "baseball-bat-ball",
  "square-up-left": "square-up-left",
  overline: "overline",
  s: "s",
  timeline: "timeline",
  keyboard: "keyboard",
  "arrows-from-dotted-line": "arrows-from-dotted-line",
  "usb-drive": "usb-drive",
  ballot: "ballot",
  "caret-down": "caret-down",
  "location-dot-slash": "location-dot-slash",
  "map-marker-alt-slash": "location-dot-slash",
  cards: "cards",
  "house-chimney-medical": "house-chimney-medical",
  "clinic-medical": "house-chimney-medical",
  "boxing-glove": "boxing-glove",
  "glove-boxing": "boxing-glove",
  "temperature-three-quarters": "temperature-three-quarters",
  "temperature-3": "temperature-three-quarters",
  "thermometer-3": "temperature-three-quarters",
  "thermometer-three-quarters": "temperature-three-quarters",
  "bell-school": "bell-school",
  "mobile-screen": "mobile-screen",
  "mobile-android-alt": "mobile-screen",
  "plane-up": "plane-up",
  "folder-heart": "folder-heart",
  "circle-location-arrow": "circle-location-arrow",
  "location-circle": "circle-location-arrow",
  "face-head-bandage": "face-head-bandage",
  "sushi-roll": "sushi-roll",
  "maki-roll": "sushi-roll",
  makizushi: "sushi-roll",
  "car-bump": "car-bump",
  "piggy-bank": "piggy-bank",
  racquet: "racquet",
  "car-mirrors": "car-mirrors",
  "industry-windows": "industry-windows",
  "industry-alt": "industry-windows",
  "bolt-auto": "bolt-auto",
  "battery-half": "battery-half",
  "battery-3": "battery-half",
  "flux-capacitor": "flux-capacitor",
  "mountain-city": "mountain-city",
  coins: "coins",
  "honey-pot": "honey-pot",
  olive: "olive",
  khanda: "khanda",
  "filter-list": "filter-list",
  outlet: "outlet",
  sliders: "sliders",
  "sliders-h": "sliders",
  cauldron: "cauldron",
  people: "people",
  "folder-tree": "folder-tree",
  "network-wired": "network-wired",
  croissant: "croissant",
  "map-pin": "map-pin",
  hamsa: "hamsa",
  "cent-sign": "cent-sign",
  "swords-laser": "swords-laser",
  flask: "flask",
  "person-pregnant": "person-pregnant",
  "square-u": "square-u",
  "wand-sparkles": "wand-sparkles",
  router: "router",
  "ellipsis-vertical": "ellipsis-vertical",
  "ellipsis-v": "ellipsis-vertical",
  "sword-laser-alt": "sword-laser-alt",
  ticket: "ticket",
  "power-off": "power-off",
  coin: "coin",
  "laptop-slash": "laptop-slash",
  "right-long": "right-long",
  "long-arrow-alt-right": "right-long",
  "circle-b": "circle-b",
  "person-dress-simple": "person-dress-simple",
  "pipe-collar": "pipe-collar",
  "lights-holiday": "lights-holiday",
  citrus: "citrus",
  "flag-usa": "flag-usa",
  "laptop-file": "laptop-file",
  tty: "tty",
  teletype: "tty",
  "chart-tree-map": "chart-tree-map",
  "diagram-next": "diagram-next",
  "person-rifle": "person-rifle",
  "clock-five-thirty": "clock-five-thirty",
  "pipe-valve": "pipe-valve",
  "arrow-up-from-arc": "arrow-up-from-arc",
  "face-spiral-eyes": "face-spiral-eyes",
  "compress-wide": "compress-wide",
  "circle-phone-hangup": "circle-phone-hangup",
  "phone-circle-down": "circle-phone-hangup",
  "gear-complex-code": "gear-complex-code",
  "house-medical-circle-exclamation": "house-medical-circle-exclamation",
  badminton: "badminton",
  "closed-captioning": "closed-captioning",
  "person-hiking": "person-hiking",
  hiking: "person-hiking",
  "right-from-line": "right-from-line",
  "arrow-alt-from-left": "right-from-line",
  "venus-double": "venus-double",
  images: "images",
  calculator: "calculator",
  shuttlecock: "shuttlecock",
  "user-hair": "user-hair",
  "eye-evil": "eye-evil",
  "people-pulling": "people-pulling",
  n: "n",
  swap: "swap",
  garage: "garage",
  "cable-car": "cable-car",
  tram: "cable-car",
  "shovel-snow": "shovel-snow",
  "cloud-rain": "cloud-rain",
  "face-lying": "face-lying",
  sprinkler: "sprinkler",
  "building-circle-xmark": "building-circle-xmark",
  "person-sledding": "person-sledding",
  sledding: "person-sledding",
  "game-console-handheld": "game-console-handheld",
  ship: "ship",
  "clock-six-thirty": "clock-six-thirty",
  "battery-slash": "battery-slash",
  "tugrik-sign": "tugrik-sign",
  "arrows-down-to-line": "arrows-down-to-line",
  download: "download",
  "angles-up-down": "angles-up-down",
  shelves: "shelves",
  inventory: "shelves",
  "cloud-snow": "cloud-snow",
  "face-grin": "face-grin",
  grin: "face-grin",
  "delete-left": "delete-left",
  backspace: "delete-left",
  oven: "oven",
  "cloud-binary": "cloud-binary",
  "eye-dropper": "eye-dropper",
  "eye-dropper-empty": "eye-dropper",
  eyedropper: "eye-dropper",
  "comment-captions": "comment-captions",
  "comments-question": "comments-question",
  scribble: "scribble",
  "rotate-exclamation": "rotate-exclamation",
  "file-circle-check": "file-circle-check",
  glass: "glass",
  loader: "loader",
  forward: "forward",
  "user-pilot": "user-pilot",
  mobile: "mobile",
  "mobile-android": "mobile",
  "mobile-phone": "mobile",
  "code-pull-request-closed": "code-pull-request-closed",
  "face-meh": "face-meh",
  meh: "face-meh",
  "align-center": "align-center",
  "book-skull": "book-skull",
  "book-dead": "book-skull",
  "id-card": "id-card",
  "drivers-license": "id-card",
  "face-dotted": "face-dotted",
  "face-worried": "face-worried",
  outdent: "outdent",
  dedent: "outdent",
  "court-sport": "court-sport",
  "heart-circle-exclamation": "heart-circle-exclamation",
  house: "house",
  home: "house",
  "home-alt": "house",
  "home-lg-alt": "house",
  "vector-circle": "vector-circle",
  "car-circle-bolt": "car-circle-bolt",
  "calendar-week": "calendar-week",
  "flying-disc": "flying-disc",
  "laptop-medical": "laptop-medical",
  "square-down-right": "square-down-right",
  b: "b",
  "seat-airline": "seat-airline",
  "moon-over-sun": "moon-over-sun",
  "eclipse-alt": "moon-over-sun",
  pipe: "pipe",
  "file-medical": "file-medical",
  potato: "potato",
  "dice-one": "dice-one",
  "circle-a": "circle-a",
  "helmet-battle": "helmet-battle",
  butter: "butter",
  "blanket-fire": "blanket-fire",
  "kiwi-bird": "kiwi-bird",
  castle: "castle",
  "golf-club": "golf-club",
  "arrow-right-arrow-left": "arrow-right-arrow-left",
  exchange: "arrow-right-arrow-left",
  "rotate-right": "rotate-right",
  "redo-alt": "rotate-right",
  "rotate-forward": "rotate-right",
  utensils: "utensils",
  cutlery: "utensils",
  "arrow-up-wide-short": "arrow-up-wide-short",
  "sort-amount-up": "arrow-up-wide-short",
  "chart-pie-simple-circle-dollar": "chart-pie-simple-circle-dollar",
  balloons: "balloons",
  "mill-sign": "mill-sign",
  "bowl-rice": "bowl-rice",
  "timeline-arrow": "timeline-arrow",
  skull: "skull",
  "game-board-simple": "game-board-simple",
  "game-board-alt": "game-board-simple",
  "circle-video": "circle-video",
  "video-circle": "circle-video",
  "chart-scatter-bubble": "chart-scatter-bubble",
  "house-turret": "house-turret",
  banana: "banana",
  "hand-holding-skull": "hand-holding-skull",
  "people-dress": "people-dress",
  loveseat: "loveseat",
  "couch-small": "loveseat",
  "tower-broadcast": "tower-broadcast",
  "broadcast-tower": "tower-broadcast",
  "truck-pickup": "truck-pickup",
  "block-quote": "block-quote",
  "up-long": "up-long",
  "long-arrow-alt-up": "up-long",
  stop: "stop",
  "code-merge": "code-merge",
  "money-check-dollar-pen": "money-check-dollar-pen",
  "money-check-edit-alt": "money-check-dollar-pen",
  "up-from-line": "up-from-line",
  "arrow-alt-from-bottom": "up-from-line",
  upload: "upload",
  hurricane: "hurricane",
  "grid-round-2-plus": "grid-round-2-plus",
  "people-pants": "people-pants",
  mound: "mound",
  windsock: "windsock",
  "circle-half": "circle-half",
  "brake-warning": "brake-warning",
  "toilet-portable": "toilet-portable",
  "compact-disc": "compact-disc",
  "file-arrow-down": "file-arrow-down",
  "file-download": "file-arrow-down",
  "saxophone-fire": "saxophone-fire",
  "sax-hot": "saxophone-fire",
  "camera-web-slash": "camera-web-slash",
  "webcam-slash": "camera-web-slash",
  "folder-medical": "folder-medical",
  "folder-gear": "folder-gear",
  "folder-cog": "folder-gear",
  "hand-wave": "hand-wave",
  "arrow-up-arrow-down": "arrow-up-arrow-down",
  "sort-up-down": "arrow-up-arrow-down",
  caravan: "caravan",
  "shield-cat": "shield-cat",
  "message-slash": "message-slash",
  "comment-alt-slash": "message-slash",
  bolt: "bolt",
  zap: "bolt",
  "trash-can-check": "trash-can-check",
  "glass-water": "glass-water",
  "oil-well": "oil-well",
  "person-simple": "person-simple",
  "arrow-turn-left-up": "arrow-turn-left-up",
  vault: "vault",
  mars: "mars",
  toilet: "toilet",
  "plane-circle-xmark": "plane-circle-xmark",
  "yen-sign": "yen-sign",
  cny: "yen-sign",
  jpy: "yen-sign",
  rmb: "yen-sign",
  yen: "yen-sign",
  "gear-code": "gear-code",
  notes: "notes",
  "ruble-sign": "ruble-sign",
  rouble: "ruble-sign",
  rub: "ruble-sign",
  ruble: "ruble-sign",
  "trash-undo": "trash-undo",
  "trash-arrow-turn-left": "trash-undo",
  "champagne-glass": "champagne-glass",
  "glass-champagne": "champagne-glass",
  "objects-align-center-horizontal": "objects-align-center-horizontal",
  sun: "sun",
  "trash-can-slash": "trash-can-slash",
  "trash-alt-slash": "trash-can-slash",
  "screen-users": "screen-users",
  "users-class": "screen-users",
  guitar: "guitar",
  "square-arrow-left": "square-arrow-left",
  "arrow-square-left": "square-arrow-left",
  "square-8": "square-8",
  "face-smile-hearts": "face-smile-hearts",
  "brackets-square": "brackets-square",
  brackets: "brackets-square",
  "laptop-arrow-down": "laptop-arrow-down",
  "hockey-stick-puck": "hockey-stick-puck",
  "house-tree": "house-tree",
  "signal-fair": "signal-fair",
  "signal-2": "signal-fair",
  "face-laugh-wink": "face-laugh-wink",
  "laugh-wink": "face-laugh-wink",
  "circle-dollar": "circle-dollar",
  "dollar-circle": "circle-dollar",
  "usd-circle": "circle-dollar",
  "horse-head": "horse-head",
  "arrows-repeat": "arrows-repeat",
  "repeat-alt": "arrows-repeat",
  "bore-hole": "bore-hole",
  industry: "industry",
  "image-polaroid": "image-polaroid",
  "wave-triangle": "wave-triangle",
  "turn-left-down": "turn-left-down",
  "person-running-fast": "person-running-fast",
  "circle-down": "circle-down",
  "arrow-alt-circle-down": "circle-down",
  grill: "grill",
  "arrows-turn-to-dots": "arrows-turn-to-dots",
  "chart-mixed": "chart-mixed",
  analytics: "chart-mixed",
  "florin-sign": "florin-sign",
  "arrow-down-short-wide": "arrow-down-short-wide",
  "sort-amount-desc": "arrow-down-short-wide",
  "sort-amount-down-alt": "arrow-down-short-wide",
  "less-than": "less-than",
  "display-code": "display-code",
  "desktop-code": "display-code",
  "face-drooling": "face-drooling",
  "oil-temperature": "oil-temperature",
  "oil-temp": "oil-temperature",
  "square-question": "square-question",
  "question-square": "square-question",
  "air-conditioner": "air-conditioner",
  "angle-down": "angle-down",
  mountains: "mountains",
  omega: "omega",
  "car-tunnel": "car-tunnel",
  "person-dolly-empty": "person-dolly-empty",
  "pan-food": "pan-food",
  "head-side-cough": "head-side-cough",
  "grip-lines": "grip-lines",
  "thumbs-down": "thumbs-down",
  "user-lock": "user-lock",
  "arrow-right-long": "arrow-right-long",
  "long-arrow-right": "arrow-right-long",
  "tickets-airline": "tickets-airline",
  "tickets-perforated-plane": "tickets-airline",
  "tickets-plane": "tickets-airline",
  "tent-double-peak": "tent-double-peak",
  "anchor-circle-xmark": "anchor-circle-xmark",
  ellipsis: "ellipsis",
  "ellipsis-h": "ellipsis",
  "nfc-slash": "nfc-slash",
  "chess-pawn": "chess-pawn",
  "kit-medical": "kit-medical",
  "first-aid": "kit-medical",
  "grid-2-plus": "grid-2-plus",
  bells: "bells",
  "person-through-window": "person-through-window",
  toolbox: "toolbox",
  "envelope-dot": "envelope-dot",
  "envelope-badge": "envelope-dot",
  "magnifying-glass-waveform": "magnifying-glass-waveform",
  "hands-holding-circle": "hands-holding-circle",
  bug: "bug",
  "bowl-chopsticks": "bowl-chopsticks",
  "credit-card": "credit-card",
  "credit-card-alt": "credit-card",
  "circle-s": "circle-s",
  "box-ballot": "box-ballot",
  car: "car",
  automobile: "car",
  "hand-holding-hand": "hand-holding-hand",
  "user-tie-hair": "user-tie-hair",
  "podium-star": "podium-star",
  "user-hair-mullet": "user-hair-mullet",
  "business-front": "user-hair-mullet",
  "party-back": "user-hair-mullet",
  "trian-balbot": "user-hair-mullet",
  "microphone-stand": "microphone-stand",
  "book-open-reader": "book-open-reader",
  "book-reader": "book-open-reader",
  "family-dress": "family-dress",
  "circle-x": "circle-x",
  cabin: "cabin",
  "mountain-sun": "mountain-sun",
  "chart-simple-horizontal": "chart-simple-horizontal",
  bluetooth: "bluetooth",
  "arrows-left-right-to-line": "arrows-left-right-to-line",
  "hand-back-point-left": "hand-back-point-left",
  "message-dots": "message-dots",
  "comment-alt-dots": "message-dots",
  messaging: "message-dots",
  "file-heart": "file-heart",
  "beer-mug": "beer-mug",
  "beer-foam": "beer-mug",
  "dice-d20": "dice-d20",
  drone: "drone",
  "truck-droplet": "truck-droplet",
  "file-circle-xmark": "file-circle-xmark",
  "temperature-arrow-up": "temperature-arrow-up",
  "temperature-up": "temperature-arrow-up",
  medal: "medal",
  "person-fairy": "person-fairy",
  bed: "bed",
  "book-copy": "book-copy",
  "square-h": "square-h",
  "h-square": "square-h",
  "square-c": "square-c",
  "clock-two": "clock-two",
  "square-ellipsis-vertical": "square-ellipsis-vertical",
  "calendar-users": "calendar-users",
  podcast: "podcast",
  bee: "bee",
  "temperature-full": "temperature-full",
  "temperature-4": "temperature-full",
  "thermometer-4": "temperature-full",
  "thermometer-full": "temperature-full",
  bell: "bell",
  "candy-bar": "candy-bar",
  "chocolate-bar": "candy-bar",
  "xmark-large": "xmark-large",
  pinata: "pinata",
  "file-ppt": "file-ppt",
  "arrows-from-line": "arrows-from-line",
  superscript: "superscript",
  "bowl-spoon": "bowl-spoon",
  "hexagon-check": "hexagon-check",
  "plug-circle-xmark": "plug-circle-xmark",
  "star-of-life": "star-of-life",
  "phone-slash": "phone-slash",
  "traffic-light-stop": "traffic-light-stop",
  "paint-roller": "paint-roller",
  "accent-grave": "accent-grave",
  "handshake-angle": "handshake-angle",
  "hands-helping": "handshake-angle",
  "circle-0": "circle-0",
  "dial-med-low": "dial-med-low",
  "location-dot": "location-dot",
  "map-marker-alt": "location-dot",
  crab: "crab",
  "box-open-full": "box-open-full",
  "box-full": "box-open-full",
  file: "file",
  "greater-than": "greater-than",
  quotes: "quotes",
  pretzel: "pretzel",
  "t-rex": "t-rex",
  "person-swimming": "person-swimming",
  swimmer: "person-swimming",
  "arrow-down": "arrow-down",
  "user-robot-xmarks": "user-robot-xmarks",
  "message-quote": "message-quote",
  "comment-alt-quote": "message-quote",
  "candy-corn": "candy-corn",
  "folder-magnifying-glass": "folder-magnifying-glass",
  "folder-search": "folder-magnifying-glass",
  notebook: "notebook",
  "circle-wifi": "circle-wifi",
  droplet: "droplet",
  tint: "droplet",
  "bullseye-pointer": "bullseye-pointer",
  eraser: "eraser",
  "hexagon-image": "hexagon-image",
  "earth-americas": "earth-americas",
  earth: "earth-americas",
  "earth-america": "earth-americas",
  "globe-americas": "earth-americas",
  "file-svg": "file-svg",
  "crate-apple": "crate-apple",
  "apple-crate": "crate-apple",
  "person-burst": "person-burst",
  "game-board": "game-board",
  "hat-chef": "hat-chef",
  "hand-back-point-right": "hand-back-point-right",
  dove: "dove",
  "snowflake-droplets": "snowflake-droplets",
  "battery-empty": "battery-empty",
  "battery-0": "battery-empty",
  "grid-4": "grid-4",
  socks: "socks",
  "face-sunglasses": "face-sunglasses",
  inbox: "inbox",
  "square-0": "square-0",
  section: "section",
  "square-this-way-up": "square-this-way-up",
  "box-up": "square-this-way-up",
  "gauge-high": "gauge-high",
  "tachometer-alt": "gauge-high",
  "tachometer-alt-fast": "gauge-high",
  "square-ampersand": "square-ampersand",
  "envelope-open-text": "envelope-open-text",
  "lamp-desk": "lamp-desk",
  hospital: "hospital",
  "hospital-alt": "hospital",
  "hospital-wide": "hospital",
  "poll-people": "poll-people",
  "whiskey-glass-ice": "whiskey-glass-ice",
  "glass-whiskey-rocks": "whiskey-glass-ice",
  "wine-bottle": "wine-bottle",
  "chess-rook": "chess-rook",
  "user-bounty-hunter": "user-bounty-hunter",
  "bars-staggered": "bars-staggered",
  reorder: "bars-staggered",
  stream: "bars-staggered",
  "diagram-sankey": "diagram-sankey",
  "cloud-hail-mixed": "cloud-hail-mixed",
  "circle-up-left": "circle-up-left",
  dharmachakra: "dharmachakra",
  "objects-align-left": "objects-align-left",
  "oil-can-drip": "oil-can-drip",
  "face-smiling-hands": "face-smiling-hands",
  broccoli: "broccoli",
  "route-interstate": "route-interstate",
  "ear-muffs": "ear-muffs",
  hotdog: "hotdog",
  "transporter-empty": "transporter-empty",
  "person-walking-with-cane": "person-walking-with-cane",
  blind: "person-walking-with-cane",
  "angle-90": "angle-90",
  "rectangle-terminal": "rectangle-terminal",
  kite: "kite",
  drum: "drum",
  scrubber: "scrubber",
  "ice-cream": "ice-cream",
  "heart-circle-bolt": "heart-circle-bolt",
  "fish-bones": "fish-bones",
  "deer-rudolph": "deer-rudolph",
  fax: "fax",
  paragraph: "paragraph",
  "head-side-heart": "head-side-heart",
  "square-e": "square-e",
  "meter-fire": "meter-fire",
  "cloud-hail": "cloud-hail",
  "check-to-slot": "check-to-slot",
  "vote-yea": "check-to-slot",
  "money-from-bracket": "money-from-bracket",
  "star-half": "star-half",
  "car-bus": "car-bus",
  speaker: "speaker",
  timer: "timer",
  "boxes-stacked": "boxes-stacked",
  boxes: "boxes-stacked",
  "boxes-alt": "boxes-stacked",
  "landmark-magnifying-glass": "landmark-magnifying-glass",
  "grill-hot": "grill-hot",
  "ballot-check": "ballot-check",
  link: "link",
  chain: "link",
  "ear-listen": "ear-listen",
  "assistive-listening-systems": "ear-listen",
  "file-minus": "file-minus",
  "tree-city": "tree-city",
  play: "play",
  font: "font",
  "cup-togo": "cup-togo",
  "coffee-togo": "cup-togo",
  "square-down-left": "square-down-left",
  "burger-lettuce": "burger-lettuce",
  "table-cells-row-lock": "table-cells-row-lock",
  "rupiah-sign": "rupiah-sign",
  "magnifying-glass": "magnifying-glass",
  search: "magnifying-glass",
  "table-tennis-paddle-ball": "table-tennis-paddle-ball",
  "ping-pong-paddle-ball": "table-tennis-paddle-ball",
  "table-tennis": "table-tennis-paddle-ball",
  "person-dots-from-line": "person-dots-from-line",
  diagnoses: "person-dots-from-line",
  "chevrons-down": "chevrons-down",
  "chevron-double-down": "chevrons-down",
  "trash-can-arrow-up": "trash-can-arrow-up",
  "trash-restore-alt": "trash-can-arrow-up",
  "signal-good": "signal-good",
  "signal-3": "signal-good",
  "location-question": "location-question",
  "map-marker-question": "location-question",
  "floppy-disk-circle-xmark": "floppy-disk-circle-xmark",
  "floppy-disk-times": "floppy-disk-circle-xmark",
  "save-circle-xmark": "floppy-disk-circle-xmark",
  "save-times": "floppy-disk-circle-xmark",
  "naira-sign": "naira-sign",
  peach: "peach",
  "taxi-bus": "taxi-bus",
  "bracket-curly": "bracket-curly",
  "bracket-curly-left": "bracket-curly",
  lobster: "lobster",
  "cart-flatbed-empty": "cart-flatbed-empty",
  "dolly-flatbed-empty": "cart-flatbed-empty",
  colon: "colon",
  "cart-arrow-down": "cart-arrow-down",
  wand: "wand",
  "walkie-talkie": "walkie-talkie",
  "file-pen": "file-pen",
  "file-edit": "file-pen",
  receipt: "receipt",
  "table-picnic": "table-picnic",
  "square-pen": "square-pen",
  "pen-square": "square-pen",
  "pencil-square": "square-pen",
  "circle-microphone-lines": "circle-microphone-lines",
  "microphone-circle-alt": "circle-microphone-lines",
  "display-slash": "display-slash",
  "desktop-slash": "display-slash",
  "suitcase-rolling": "suitcase-rolling",
  "person-circle-exclamation": "person-circle-exclamation",
  "transporter-2": "transporter-2",
  "hands-holding-diamond": "hands-holding-diamond",
  "hand-receiving": "hands-holding-diamond",
  "money-bill-simple-wave": "money-bill-simple-wave",
  "chevron-down": "chevron-down",
  "battery-full": "battery-full",
  battery: "battery-full",
  "battery-5": "battery-full",
  "bell-plus": "bell-plus",
  "book-arrow-right": "book-arrow-right",
  hospitals: "hospitals",
  club: "club",
  "skull-crossbones": "skull-crossbones",
  "droplet-degree": "droplet-degree",
  dewpoint: "droplet-degree",
  "code-compare": "code-compare",
  "list-ul": "list-ul",
  "list-dots": "list-ul",
  "hand-holding-magic": "hand-holding-magic",
  "watermelon-slice": "watermelon-slice",
  "circle-ellipsis": "circle-ellipsis",
  "school-lock": "school-lock",
  "tower-cell": "tower-cell",
  "sd-cards": "sd-cards",
  "jug-bottle": "jug-bottle",
  "down-long": "down-long",
  "long-arrow-alt-down": "down-long",
  envelopes: "envelopes",
  "phone-office": "phone-office",
  "ranking-star": "ranking-star",
  "chess-king": "chess-king",
  "nfc-pen": "nfc-pen",
  "person-harassing": "person-harassing",
  "magnifying-glass-play": "magnifying-glass-play",
  "hat-winter": "hat-winter",
  "brazilian-real-sign": "brazilian-real-sign",
  "landmark-dome": "landmark-dome",
  "landmark-alt": "landmark-dome",
  "bone-break": "bone-break",
  "arrow-up": "arrow-up",
  "down-from-dotted-line": "down-from-dotted-line",
  tv: "tv",
  television: "tv",
  "tv-alt": "tv",
  "border-left": "border-left",
  "circle-divide": "circle-divide",
  shrimp: "shrimp",
  "list-check": "list-check",
  tasks: "list-check",
  "diagram-subtask": "diagram-subtask",
  "jug-detergent": "jug-detergent",
  "circle-user": "circle-user",
  "user-circle": "circle-user",
  "square-y": "square-y",
  "user-doctor-hair": "user-doctor-hair",
  "planet-ringed": "planet-ringed",
  mushroom: "mushroom",
  "user-shield": "user-shield",
  megaphone: "megaphone",
  "wreath-laurel": "wreath-laurel",
  "circle-exclamation-check": "circle-exclamation-check",
  wind: "wind",
  "box-dollar": "box-dollar",
  "box-usd": "box-dollar",
  "car-burst": "car-burst",
  "car-crash": "car-burst",
  y: "y",
  "user-headset": "user-headset",
  "arrows-retweet": "arrows-retweet",
  "retweet-alt": "arrows-retweet",
  "person-snowboarding": "person-snowboarding",
  snowboarding: "person-snowboarding",
  "square-chevron-right": "square-chevron-right",
  "chevron-square-right": "square-chevron-right",
  "lacrosse-stick-ball": "lacrosse-stick-ball",
  "truck-fast": "truck-fast",
  "shipping-fast": "truck-fast",
  "user-magnifying-glass": "user-magnifying-glass",
  "star-sharp": "star-sharp",
  "comment-heart": "comment-heart",
  "circle-1": "circle-1",
  "circle-star": "circle-star",
  "star-circle": "circle-star",
  fish: "fish",
  "cloud-fog": "cloud-fog",
  fog: "cloud-fog",
  waffle: "waffle",
  "music-note": "music-note",
  "music-alt": "music-note",
  "hexagon-exclamation": "hexagon-exclamation",
  "cart-shopping-fast": "cart-shopping-fast",
  "object-union": "object-union",
  "user-graduate": "user-graduate",
  starfighter: "starfighter",
  "circle-half-stroke": "circle-half-stroke",
  adjust: "circle-half-stroke",
  "arrow-right-long-to-line": "arrow-right-long-to-line",
  "square-arrow-down": "square-arrow-down",
  "arrow-square-down": "square-arrow-down",
  "diamond-half-stroke": "diamond-half-stroke",
  clapperboard: "clapperboard",
  "square-chevron-left": "square-chevron-left",
  "chevron-square-left": "square-chevron-left",
  "phone-intercom": "phone-intercom",
  "link-horizontal": "link-horizontal",
  "chain-horizontal": "link-horizontal",
  mango: "mango",
  "music-note-slash": "music-note-slash",
  "music-alt-slash": "music-note-slash",
  "circle-radiation": "circle-radiation",
  "radiation-alt": "circle-radiation",
  "face-tongue-sweat": "face-tongue-sweat",
  "globe-stand": "globe-stand",
  baseball: "baseball",
  "baseball-ball": "baseball",
  "circle-p": "circle-p",
  "award-simple": "award-simple",
  "jet-fighter-up": "jet-fighter-up",
  "diagram-project": "diagram-project",
  "project-diagram": "diagram-project",
  pedestal: "pedestal",
  "chart-pyramid": "chart-pyramid",
  sidebar: "sidebar",
  "snowman-head": "snowman-head",
  "frosty-head": "snowman-head",
  copy: "copy",
  "burger-glass": "burger-glass",
  "volume-xmark": "volume-xmark",
  "volume-mute": "volume-xmark",
  "volume-times": "volume-xmark",
  "hand-sparkles": "hand-sparkles",
  "bars-filter": "bars-filter",
  "paintbrush-pencil": "paintbrush-pencil",
  "party-bell": "party-bell",
  "user-vneck-hair": "user-vneck-hair",
  "jack-o-lantern": "jack-o-lantern",
  grip: "grip",
  "grip-horizontal": "grip",
  "share-from-square": "share-from-square",
  "share-square": "share-from-square",
  keynote: "keynote",
  "child-combatant": "child-combatant",
  "child-rifle": "child-combatant",
  gun: "gun",
  "square-phone": "square-phone",
  "phone-square": "square-phone",
  "hat-beach": "hat-beach",
  plus: "plus",
  add: "plus",
  expand: "expand",
  computer: "computer",
  fort: "fort",
  "cloud-check": "cloud-check",
  xmark: "xmark",
  close: "xmark",
  multiply: "xmark",
  remove: "xmark",
  times: "xmark",
  "face-smirking": "face-smirking",
  "arrows-up-down-left-right": "arrows-up-down-left-right",
  arrows: "arrows-up-down-left-right",
  "chalkboard-user": "chalkboard-user",
  "chalkboard-teacher": "chalkboard-user",
  rhombus: "rhombus",
  "claw-marks": "claw-marks",
  "peso-sign": "peso-sign",
  "face-smile-tongue": "face-smile-tongue",
  "cart-circle-xmark": "cart-circle-xmark",
  "building-shield": "building-shield",
  "circle-phone-flip": "circle-phone-flip",
  "phone-circle-alt": "circle-phone-flip",
  baby: "baby",
  "users-line": "users-line",
  "quote-left": "quote-left",
  "quote-left-alt": "quote-left",
  tractor: "tractor",
  "down-from-bracket": "down-from-bracket",
  "key-skeleton": "key-skeleton",
  "trash-arrow-up": "trash-arrow-up",
  "trash-restore": "trash-arrow-up",
  "arrow-down-up-lock": "arrow-down-up-lock",
  "arrow-down-to-bracket": "arrow-down-to-bracket",
  "lines-leaning": "lines-leaning",
  "square-q": "square-q",
  "ruler-combined": "ruler-combined",
  symbols: "symbols",
  "icons-alt": "symbols",
  copyright: "copyright",
  "flask-gear": "flask-gear",
  "highlighter-line": "highlighter-line",
  "bracket-square": "bracket-square",
  bracket: "bracket-square",
  "bracket-left": "bracket-square",
  "island-tropical": "island-tropical",
  "island-tree-palm": "island-tropical",
  "arrow-right-from-line": "arrow-right-from-line",
  "arrow-from-left": "arrow-right-from-line",
  h2: "h2",
  equals: "equals",
  "cake-slice": "cake-slice",
  shortcake: "cake-slice",
  "building-magnifying-glass": "building-magnifying-glass",
  peanut: "peanut",
  "wrench-simple": "wrench-simple",
  blender: "blender",
  teeth: "teeth",
  "tally-2": "tally-2",
  "shekel-sign": "shekel-sign",
  ils: "shekel-sign",
  shekel: "shekel-sign",
  sheqel: "shekel-sign",
  "sheqel-sign": "shekel-sign",
  cars: "cars",
  "axe-battle": "axe-battle",
  "user-hair-long": "user-hair-long",
  map: "map",
  "arrow-left-from-arc": "arrow-left-from-arc",
  "file-circle-info": "file-circle-info",
  "face-disappointed": "face-disappointed",
  "lasso-sparkles": "lasso-sparkles",
  "clock-eleven": "clock-eleven",
  rocket: "rocket",
  "siren-on": "siren-on",
  "clock-ten": "clock-ten",
  "candle-holder": "candle-holder",
  "video-arrow-down-left": "video-arrow-down-left",
  "photo-film": "photo-film",
  "photo-video": "photo-film",
  "floppy-disk-circle-arrow-right": "floppy-disk-circle-arrow-right",
  "save-circle-arrow-right": "floppy-disk-circle-arrow-right",
  "folder-minus": "folder-minus",
  "planet-moon": "planet-moon",
  "face-eyes-xmarks": "face-eyes-xmarks",
  "chart-scatter": "chart-scatter",
  "circle-gf": "circle-gf",
  "display-arrow-down": "display-arrow-down",
  store: "store",
  "arrow-trend-up": "arrow-trend-up",
  "plug-circle-minus": "plug-circle-minus",
  "olive-branch": "olive-branch",
  angle: "angle",
  "vacuum-robot": "vacuum-robot",
  "sign-hanging": "sign-hanging",
  sign: "sign-hanging",
  "square-divide": "square-divide",
  "folder-check": "folder-check",
  "signal-stream-slash": "signal-stream-slash",
  "bezier-curve": "bezier-curve",
  "eye-dropper-half": "eye-dropper-half",
  "store-lock": "store-lock",
  "bell-slash": "bell-slash",
  "cloud-bolt-sun": "cloud-bolt-sun",
  "thunderstorm-sun": "cloud-bolt-sun",
  "camera-slash": "camera-slash",
  "comment-quote": "comment-quote",
  tablet: "tablet",
  "tablet-android": "tablet",
  "school-flag": "school-flag",
  "message-code": "message-code",
  "glass-half": "glass-half",
  "glass-half-empty": "glass-half",
  "glass-half-full": "glass-half",
  fill: "fill",
  "message-minus": "message-minus",
  "comment-alt-minus": "message-minus",
  "angle-up": "angle-up",
  dinosaur: "dinosaur",
  "drumstick-bite": "drumstick-bite",
  "link-horizontal-slash": "link-horizontal-slash",
  "chain-horizontal-slash": "link-horizontal-slash",
  "holly-berry": "holly-berry",
  nose: "nose",
  "arrow-left-to-arc": "arrow-left-to-arc",
  "chevron-left": "chevron-left",
  bacteria: "bacteria",
  clouds: "clouds",
  "money-bill-simple": "money-bill-simple",
  "hand-lizard": "hand-lizard",
  "table-pivot": "table-pivot",
  "filter-slash": "filter-slash",
  "trash-can-undo": "trash-can-undo",
  "trash-can-arrow-turn-left": "trash-can-undo",
  "trash-undo-alt": "trash-can-undo",
  notdef: "notdef",
  disease: "disease",
  "person-to-door": "person-to-door",
  turntable: "turntable",
  "briefcase-medical": "briefcase-medical",
  genderless: "genderless",
  "chevron-right": "chevron-right",
  "signal-weak": "signal-weak",
  "signal-1": "signal-weak",
  "clock-five": "clock-five",
  retweet: "retweet",
  "car-rear": "car-rear",
  "car-alt": "car-rear",
  "pump-soap": "pump-soap",
  "computer-classic": "computer-classic",
  frame: "frame",
  "video-slash": "video-slash",
  "battery-quarter": "battery-quarter",
  "battery-2": "battery-quarter",
  "ellipsis-stroke": "ellipsis-stroke",
  "ellipsis-h-alt": "ellipsis-stroke",
  radio: "radio",
  "baby-carriage": "baby-carriage",
  "carriage-baby": "baby-carriage",
  "face-expressionless": "face-expressionless",
  "down-to-dotted-line": "down-to-dotted-line",
  "cloud-music": "cloud-music",
  "traffic-light": "traffic-light",
  "cloud-minus": "cloud-minus",
  thermometer: "thermometer",
  "shield-minus": "shield-minus",
  "vr-cardboard": "vr-cardboard",
  "car-tilt": "car-tilt",
  "gauge-circle-minus": "gauge-circle-minus",
  "brightness-low": "brightness-low",
  "hand-middle-finger": "hand-middle-finger",
  percent: "percent",
  percentage: "percent",
  "truck-moving": "truck-moving",
  "glass-water-droplet": "glass-water-droplet",
  "conveyor-belt": "conveyor-belt",
  "location-check": "location-check",
  "map-marker-check": "location-check",
  "coin-vertical": "coin-vertical",
  display: "display",
  "person-sign": "person-sign",
  "face-smile": "face-smile",
  smile: "face-smile",
  "phone-hangup": "phone-hangup",
  "signature-slash": "signature-slash",
  thumbtack: "thumbtack",
  "thumb-tack": "thumbtack",
  "wheat-slash": "wheat-slash",
  trophy: "trophy",
  "clouds-sun": "clouds-sun",
  "person-praying": "person-praying",
  pray: "person-praying",
  hammer: "hammer",
  "face-vomit": "face-vomit",
  speakers: "speakers",
  "tty-answer": "tty-answer",
  "teletype-answer": "tty-answer",
  "mug-tea-saucer": "mug-tea-saucer",
  "diagram-lean-canvas": "diagram-lean-canvas",
  alt: "alt",
  dial: "dial",
  "dial-med-high": "dial",
  "hand-peace": "hand-peace",
  "circle-trash": "circle-trash",
  "trash-circle": "circle-trash",
  rotate: "rotate",
  "sync-alt": "rotate",
  "circle-quarters": "circle-quarters",
  spinner: "spinner",
  "tower-control": "tower-control",
  "arrow-up-triangle-square": "arrow-up-triangle-square",
  "sort-shapes-up": "arrow-up-triangle-square",
  whale: "whale",
  robot: "robot",
  peace: "peace",
  "party-horn": "party-horn",
  gears: "gears",
  cogs: "gears",
  "sun-bright": "sun-bright",
  "sun-alt": "sun-bright",
  warehouse: "warehouse",
  "conveyor-belt-arm": "conveyor-belt-arm",
  "lock-keyhole-open": "lock-keyhole-open",
  "lock-open-alt": "lock-keyhole-open",
  "square-fragile": "square-fragile",
  "box-fragile": "square-fragile",
  "square-wine-glass-crack": "square-fragile",
  "arrow-up-right-dots": "arrow-up-right-dots",
  "square-n": "square-n",
  splotch: "splotch",
  "face-grin-hearts": "face-grin-hearts",
  "grin-hearts": "face-grin-hearts",
  meter: "meter",
  mandolin: "mandolin",
  "dice-four": "dice-four",
  "sim-card": "sim-card",
  transgender: "transgender",
  "transgender-alt": "transgender",
  mercury: "mercury",
  "up-from-bracket": "up-from-bracket",
  "knife-kitchen": "knife-kitchen",
  "border-right": "border-right",
  "arrow-turn-down": "arrow-turn-down",
  "level-down": "arrow-turn-down",
  spade: "spade",
  "card-spade": "card-spade",
  "line-columns": "line-columns",
  ant: "ant",
  "arrow-right-to-line": "arrow-right-to-line",
  "arrow-to-right": "arrow-right-to-line",
  "person-falling-burst": "person-falling-burst",
  "flag-pennant": "flag-pennant",
  pennant: "flag-pennant",
  "conveyor-belt-empty": "conveyor-belt-empty",
  "user-group-simple": "user-group-simple",
  award: "award",
  "ticket-simple": "ticket-simple",
  "ticket-alt": "ticket-simple",
  building: "building",
  "angles-left": "angles-left",
  "angle-double-left": "angles-left",
  camcorder: "camcorder",
  "video-handheld": "camcorder",
  pancakes: "pancakes",
  "album-circle-user": "album-circle-user",
  "subtitles-slash": "subtitles-slash",
  qrcode: "qrcode",
  "dice-d10": "dice-d10",
  fireplace: "fireplace",
  browser: "browser",
  "pen-paintbrush": "pen-paintbrush",
  "pencil-paintbrush": "pen-paintbrush",
  "fish-cooked": "fish-cooked",
  "chair-office": "chair-office",
  "magnifying-glass-music": "magnifying-glass-music",
  "nesting-dolls": "nesting-dolls",
  "clock-rotate-left": "clock-rotate-left",
  history: "clock-rotate-left",
  trumpet: "trumpet",
  "face-grin-beam-sweat": "face-grin-beam-sweat",
  "grin-beam-sweat": "face-grin-beam-sweat",
  "fire-smoke": "fire-smoke",
  "phone-missed": "phone-missed",
  "file-export": "file-export",
  "arrow-right-from-file": "file-export",
  shield: "shield",
  "shield-blank": "shield",
  "arrow-up-short-wide": "arrow-up-short-wide",
  "sort-amount-up-alt": "arrow-up-short-wide",
  "arrows-repeat-1": "arrows-repeat-1",
  "repeat-1-alt": "arrows-repeat-1",
  "gun-slash": "gun-slash",
  avocado: "avocado",
  binary: "binary",
  "glasses-round": "glasses-round",
  "glasses-alt": "glasses-round",
  "phone-plus": "phone-plus",
  ditto: "ditto",
  "person-seat": "person-seat",
  "house-medical": "house-medical",
  "golf-ball-tee": "golf-ball-tee",
  "golf-ball": "golf-ball-tee",
  "circle-chevron-left": "circle-chevron-left",
  "chevron-circle-left": "circle-chevron-left",
  "house-chimney-window": "house-chimney-window",
  scythe: "scythe",
  "pen-nib": "pen-nib",
  "ban-parking": "ban-parking",
  "parking-circle-slash": "ban-parking",
  "tent-arrow-turn-left": "tent-arrow-turn-left",
  "face-diagonal-mouth": "face-diagonal-mouth",
  "diagram-cells": "diagram-cells",
  "cricket-bat-ball": "cricket-bat-ball",
  cricket: "cricket-bat-ball",
  tents: "tents",
  "wand-magic": "wand-magic",
  magic: "wand-magic",
  dog: "dog",
  "pen-line": "pen-line",
  "atom-simple": "atom-simple",
  "atom-alt": "atom-simple",
  ampersand: "ampersand",
  carrot: "carrot",
  "arrow-up-from-line": "arrow-up-from-line",
  "arrow-from-bottom": "arrow-up-from-line",
  moon: "moon",
  "pen-slash": "pen-slash",
  "wine-glass-empty": "wine-glass-empty",
  "wine-glass-alt": "wine-glass-empty",
  "square-star": "square-star",
  cheese: "cheese",
  "send-backward": "send-backward",
  "yin-yang": "yin-yang",
  music: "music",
  "compass-slash": "compass-slash",
  "clock-one": "clock-one",
  "file-music": "file-music",
  "code-commit": "code-commit",
  "temperature-low": "temperature-low",
  "person-biking": "person-biking",
  biking: "person-biking",
  "display-chart-up-circle-currency": "display-chart-up-circle-currency",
  skeleton: "skeleton",
  "circle-g": "circle-g",
  "circle-arrow-up-left": "circle-arrow-up-left",
  "coin-blank": "coin-blank",
  broom: "broom",
  vacuum: "vacuum",
  "shield-heart": "shield-heart",
  "card-heart": "card-heart",
  "lightbulb-cfl-on": "lightbulb-cfl-on",
  melon: "melon",
  gopuram: "gopuram",
  "earth-oceania": "earth-oceania",
  "globe-oceania": "earth-oceania",
  "container-storage": "container-storage",
  "face-pouting": "face-pouting",
  "square-xmark": "square-xmark",
  "times-square": "square-xmark",
  "xmark-square": "square-xmark",
  "face-explode": "face-explode",
  "exploding-head": "face-explode",
  hashtag: "hashtag",
  "up-right-and-down-left-from-center": "up-right-and-down-left-from-center",
  "expand-alt": "up-right-and-down-left-from-center",
  "oil-can": "oil-can",
  t: "t",
  "transformer-bolt": "transformer-bolt",
  hippo: "hippo",
  "chart-column": "chart-column",
  "cassette-vhs": "cassette-vhs",
  vhs: "cassette-vhs",
  infinity: "infinity",
  "vial-circle-check": "vial-circle-check",
  chimney: "chimney",
  "object-intersect": "object-intersect",
  "person-arrow-down-to-line": "person-arrow-down-to-line",
  voicemail: "voicemail",
  "block-brick": "block-brick",
  "wall-brick": "block-brick",
  fan: "fan",
  "bags-shopping": "bags-shopping",
  "paragraph-left": "paragraph-left",
  "paragraph-rtl": "paragraph-left",
  "person-walking-luggage": "person-walking-luggage",
  "caravan-simple": "caravan-simple",
  "caravan-alt": "caravan-simple",
  turtle: "turtle",
  "pencil-mechanical": "pencil-mechanical",
  "up-down": "up-down",
  "arrows-alt-v": "up-down",
  "cloud-moon-rain": "cloud-moon-rain",
  "booth-curtain": "booth-curtain",
  calendar: "calendar",
  "box-heart": "box-heart",
  trailer: "trailer",
  "user-doctor-message": "user-doctor-message",
  "user-md-chat": "user-doctor-message",
  bahai: "bahai",
  haykal: "bahai",
  lighthouse: "lighthouse",
  "amp-guitar": "amp-guitar",
  "sd-card": "sd-card",
  "volume-slash": "volume-slash",
  "border-bottom": "border-bottom",
  "wifi-weak": "wifi-weak",
  "wifi-1": "wifi-weak",
  dragon: "dragon",
  "shoe-prints": "shoe-prints",
  "circle-plus": "circle-plus",
  "plus-circle": "circle-plus",
  "face-grin-tongue-wink": "face-grin-tongue-wink",
  "grin-tongue-wink": "face-grin-tongue-wink",
  "hand-holding": "hand-holding",
  "plug-circle-exclamation": "plug-circle-exclamation",
  "link-slash": "link-slash",
  "chain-broken": "link-slash",
  "chain-slash": "link-slash",
  unlink: "link-slash",
  clone: "clone",
  "person-walking-arrow-loop-left": "person-walking-arrow-loop-left",
  "arrow-up-z-a": "arrow-up-z-a",
  "sort-alpha-up-alt": "arrow-up-z-a",
  "fire-flame-curved": "fire-flame-curved",
  "fire-alt": "fire-flame-curved",
  tornado: "tornado",
  "file-circle-plus": "file-circle-plus",
  "delete-right": "delete-right",
  "book-quran": "book-quran",
  quran: "book-quran",
  "circle-quarter": "circle-quarter",
  anchor: "anchor",
  "border-all": "border-all",
  function: "function",
  "face-angry": "face-angry",
  angry: "face-angry",
  "people-simple": "people-simple",
  "cookie-bite": "cookie-bite",
  "arrow-trend-down": "arrow-trend-down",
  rss: "rss",
  feed: "rss",
  "face-monocle": "face-monocle",
  "draw-polygon": "draw-polygon",
  "scale-balanced": "scale-balanced",
  "balance-scale": "scale-balanced",
  "calendar-lines": "calendar-lines",
  "calendar-note": "calendar-lines",
  "arrow-down-big-small": "arrow-down-big-small",
  "sort-size-down": "arrow-down-big-small",
  "gauge-simple-high": "gauge-simple-high",
  tachometer: "gauge-simple-high",
  "tachometer-fast": "gauge-simple-high",
  "do-not-enter": "do-not-enter",
  shower: "shower",
  "dice-d8": "dice-d8",
  desktop: "desktop",
  "desktop-alt": "desktop",
  m: "m",
  "spinner-scale": "spinner-scale",
  "grip-dots-vertical": "grip-dots-vertical",
  "face-viewfinder": "face-viewfinder",
  "soft-serve": "soft-serve",
  creemee: "soft-serve",
  h5: "h5",
  "hand-back-point-down": "hand-back-point-down",
  "table-list": "table-list",
  "th-list": "table-list",
  "basket-shopping-minus": "basket-shopping-minus",
  "comment-sms": "comment-sms",
  sms: "comment-sms",
  rectangle: "rectangle",
  "rectangle-landscape": "rectangle",
  "clipboard-list-check": "clipboard-list-check",
  turkey: "turkey",
  book: "book",
  "user-plus": "user-plus",
  "ice-skate": "ice-skate",
  check: "check",
  "battery-three-quarters": "battery-three-quarters",
  "battery-4": "battery-three-quarters",
  tomato: "tomato",
  "sword-laser": "sword-laser",
  "house-circle-check": "house-circle-check",
  buildings: "buildings",
  "angle-left": "angle-left",
  "cart-flatbed-boxes": "cart-flatbed-boxes",
  "dolly-flatbed-alt": "cart-flatbed-boxes",
  "diagram-successor": "diagram-successor",
  "truck-arrow-right": "truck-arrow-right",
  "square-w": "square-w",
  "arrows-split-up-and-left": "arrows-split-up-and-left",
  lamp: "lamp",
  airplay: "airplay",
  "hand-fist": "hand-fist",
  "fist-raised": "hand-fist",
  "shield-quartered": "shield-quartered",
  "slash-forward": "slash-forward",
  "location-pen": "location-pen",
  "map-marker-edit": "location-pen",
  "cloud-moon": "cloud-moon",
  "pot-food": "pot-food",
  briefcase: "briefcase",
  "person-falling": "person-falling",
  "image-portrait": "image-portrait",
  portrait: "image-portrait",
  "user-tag": "user-tag",
  rug: "rug",
  "print-slash": "print-slash",
  "earth-europe": "earth-europe",
  "globe-europe": "earth-europe",
  "cart-flatbed-suitcase": "cart-flatbed-suitcase",
  "luggage-cart": "cart-flatbed-suitcase",
  "hand-back-point-ribbon": "hand-back-point-ribbon",
  "rectangle-xmark": "rectangle-xmark",
  "rectangle-times": "rectangle-xmark",
  "times-rectangle": "rectangle-xmark",
  "window-close": "rectangle-xmark",
  "tire-rugged": "tire-rugged",
  "lightbulb-dollar": "lightbulb-dollar",
  cowbell: "cowbell",
  "baht-sign": "baht-sign",
  corner: "corner",
  "chevrons-right": "chevrons-right",
  "chevron-double-right": "chevrons-right",
  "book-open": "book-open",
  "book-journal-whills": "book-journal-whills",
  "journal-whills": "book-journal-whills",
  inhaler: "inhaler",
  handcuffs: "handcuffs",
  snake: "snake",
  "triangle-exclamation": "triangle-exclamation",
  "exclamation-triangle": "triangle-exclamation",
  warning: "triangle-exclamation",
  "note-medical": "note-medical",
  database: "database",
  "down-left": "down-left",
  share: "share",
  "mail-forward": "share",
  "face-thinking": "face-thinking",
  "turn-down-right": "turn-down-right",
  "bottle-droplet": "bottle-droplet",
  "mask-face": "mask-face",
  "hill-rockslide": "hill-rockslide",
  "scanner-keyboard": "scanner-keyboard",
  "circle-o": "circle-o",
  "grid-horizontal": "grid-horizontal",
  "message-dollar": "message-dollar",
  "comment-alt-dollar": "message-dollar",
  "right-left": "right-left",
  "exchange-alt": "right-left",
  "columns-3": "columns-3",
  "paper-plane": "paper-plane",
  "road-circle-exclamation": "road-circle-exclamation",
  dungeon: "dungeon",
  "hand-holding-box": "hand-holding-box",
  "input-text": "input-text",
  "window-flip": "window-flip",
  "window-alt": "window-flip",
  "align-right": "align-right",
  "scanner-gun": "scanner-gun",
  scanner: "scanner-gun",
  tire: "tire",
  engine: "engine",
  "money-bill-1-wave": "money-bill-1-wave",
  "money-bill-wave-alt": "money-bill-1-wave",
  "life-ring": "life-ring",
  hands: "hands",
  "sign-language": "hands",
  signing: "hands",
  "circle-caret-right": "circle-caret-right",
  "caret-circle-right": "circle-caret-right",
  "turn-left": "turn-left",
  wheat: "wheat",
  "file-spreadsheet": "file-spreadsheet",
  "audio-description-slash": "audio-description-slash",
  "bell-ring": "bell-ring",
  "calendar-day": "calendar-day",
  "water-ladder": "water-ladder",
  "ladder-water": "water-ladder",
  "swimming-pool": "water-ladder",
  "arrows-up-down": "arrows-up-down",
  "arrows-v": "arrows-up-down",
  "chess-pawn-piece": "chess-pawn-piece",
  "chess-pawn-alt": "chess-pawn-piece",
  "face-grimace": "face-grimace",
  grimace: "face-grimace",
  "wheelchair-move": "wheelchair-move",
  "wheelchair-alt": "wheelchair-move",
  "turn-down": "turn-down",
  "level-down-alt": "turn-down",
  "square-s": "square-s",
  "rectangle-barcode": "rectangle-barcode",
  "barcode-alt": "rectangle-barcode",
  "person-walking-arrow-right": "person-walking-arrow-right",
  "square-envelope": "square-envelope",
  "envelope-square": "square-envelope",
  dice: "dice",
  unicorn: "unicorn",
  "bowling-ball": "bowling-ball",
  pompebled: "pompebled",
  brain: "brain",
  "watch-smart": "watch-smart",
  "book-user": "book-user",
  "sensor-cloud": "sensor-cloud",
  "sensor-smoke": "sensor-cloud",
  "clapperboard-play": "clapperboard-play",
  bandage: "bandage",
  "band-aid": "bandage",
  "calendar-minus": "calendar-minus",
  "circle-xmark": "circle-xmark",
  "times-circle": "circle-xmark",
  "xmark-circle": "circle-xmark",
  "circle-4": "circle-4",
  gifts: "gifts",
  "album-collection": "album-collection",
  hotel: "hotel",
  "earth-asia": "earth-asia",
  "globe-asia": "earth-asia",
  "id-card-clip": "id-card-clip",
  "id-card-alt": "id-card-clip",
  "magnifying-glass-plus": "magnifying-glass-plus",
  "search-plus": "magnifying-glass-plus",
  "thumbs-up": "thumbs-up",
  "cloud-showers": "cloud-showers",
  "user-clock": "user-clock",
  onion: "onion",
  "clock-twelve-thirty": "clock-twelve-thirty",
  "arrow-down-to-dotted-line": "arrow-down-to-dotted-line",
  "hand-dots": "hand-dots",
  allergies: "hand-dots",
  "file-invoice": "file-invoice",
  "window-minimize": "window-minimize",
  "rectangle-wide": "rectangle-wide",
  "comment-arrow-up": "comment-arrow-up",
  garlic: "garlic",
  "mug-saucer": "mug-saucer",
  coffee: "mug-saucer",
  brush: "brush",
  "tree-decorated": "tree-decorated",
  mask: "mask",
  "calendar-heart": "calendar-heart",
  "magnifying-glass-minus": "magnifying-glass-minus",
  "search-minus": "magnifying-glass-minus",
  flower: "flower",
  "arrow-down-from-arc": "arrow-down-from-arc",
  "right-left-large": "right-left-large",
  "ruler-vertical": "ruler-vertical",
  "circles-overlap": "circles-overlap",
  "user-large": "user-large",
  "user-alt": "user-large",
  "starship-freighter": "starship-freighter",
  "train-tram": "train-tram",
  "bridge-suspension": "bridge-suspension",
  "trash-check": "trash-check",
  "user-nurse": "user-nurse",
  boombox: "boombox",
  syringe: "syringe",
  "cloud-sun": "cloud-sun",
  "shield-exclamation": "shield-exclamation",
  "stopwatch-20": "stopwatch-20",
  "square-full": "square-full",
  "grip-dots": "grip-dots",
  "comment-exclamation": "comment-exclamation",
  "pen-swirl": "pen-swirl",
  falafel: "falafel",
  "circle-2": "circle-2",
  magnet: "magnet",
  jar: "jar",
  gramophone: "gramophone",
  "dice-d12": "dice-d12",
  "note-sticky": "note-sticky",
  "sticky-note": "note-sticky",
  down: "down",
  "arrow-alt-down": "down",
  "hundred-points": "hundred-points",
  "paperclip-vertical": "paperclip-vertical",
  "wind-warning": "wind-warning",
  "wind-circle-exclamation": "wind-warning",
  "location-pin-slash": "location-pin-slash",
  "map-marker-slash": "location-pin-slash",
  "face-sad-sweat": "face-sad-sweat",
  "bug-slash": "bug-slash",
  cupcake: "cupcake",
  "light-switch-off": "light-switch-off",
  "toggle-large-off": "toggle-large-off",
  "pen-fancy-slash": "pen-fancy-slash",
  "truck-container": "truck-container",
  boot: "boot",
  "arrow-up-from-water-pump": "arrow-up-from-water-pump",
  "file-check": "file-check",
  bone: "bone",
  "cards-blank": "cards-blank",
  "circle-3": "circle-3",
  "bench-tree": "bench-tree",
  "keyboard-brightness-low": "keyboard-brightness-low",
  "ski-boot-ski": "ski-boot-ski",
  "brain-circuit": "brain-circuit",
  "user-injured": "user-injured",
  "block-brick-fire": "block-brick-fire",
  firewall: "block-brick-fire",
  "face-sad-tear": "face-sad-tear",
  "sad-tear": "face-sad-tear",
  plane: "plane",
  "tent-arrows-down": "tent-arrows-down",
  exclamation: "exclamation",
  "arrows-spin": "arrows-spin",
  "face-smile-relaxed": "face-smile-relaxed",
  "comment-xmark": "comment-xmark",
  "comment-times": "comment-xmark",
  print: "print",
  "turkish-lira-sign": "turkish-lira-sign",
  try: "turkish-lira-sign",
  "turkish-lira": "turkish-lira-sign",
  "face-nose-steam": "face-nose-steam",
  "circle-waveform-lines": "circle-waveform-lines",
  "waveform-circle": "circle-waveform-lines",
  "dollar-sign": "dollar-sign",
  dollar: "dollar-sign",
  usd: "dollar-sign",
  "ferris-wheel": "ferris-wheel",
  "computer-speaker": "computer-speaker",
  "skull-cow": "skull-cow",
  x: "x",
  "magnifying-glass-dollar": "magnifying-glass-dollar",
  "search-dollar": "magnifying-glass-dollar",
  "users-gear": "users-gear",
  "users-cog": "users-gear",
  "person-military-pointing": "person-military-pointing",
  "building-columns": "building-columns",
  bank: "building-columns",
  institution: "building-columns",
  museum: "building-columns",
  university: "building-columns",
  "circle-t": "circle-t",
  sack: "sack",
  "grid-2": "grid-2",
  "camera-cctv": "camera-cctv",
  cctv: "camera-cctv",
  umbrella: "umbrella",
  trowel: "trowel",
  "horizontal-rule": "horizontal-rule",
  "bed-front": "bed-front",
  "bed-alt": "bed-front",
  d: "d",
  stapler: "stapler",
  "masks-theater": "masks-theater",
  "theater-masks": "masks-theater",
  "file-gif": "file-gif",
  "kip-sign": "kip-sign",
  "face-woozy": "face-woozy",
  "cloud-question": "cloud-question",
  pineapple: "pineapple",
  "hand-point-left": "hand-point-left",
  "gallery-thumbnails": "gallery-thumbnails",
  "circle-j": "circle-j",
  eyes: "eyes",
  "handshake-simple": "handshake-simple",
  "handshake-alt": "handshake-simple",
  "page-caret-up": "page-caret-up",
  "file-caret-up": "page-caret-up",
  "jet-fighter": "jet-fighter",
  "fighter-jet": "jet-fighter",
  comet: "comet",
  "square-share-nodes": "square-share-nodes",
  "share-alt-square": "square-share-nodes",
  "reflect-vertical": "reflect-vertical",
  "shield-keyhole": "shield-keyhole",
  "file-mp4": "file-mp4",
  barcode: "barcode",
  bulldozer: "bulldozer",
  "plus-minus": "plus-minus",
  "square-sliders-vertical": "square-sliders-vertical",
  "sliders-v-square": "square-sliders-vertical",
  video: "video",
  "video-camera": "video",
  "message-middle": "message-middle",
  "comment-middle-alt": "message-middle",
  "graduation-cap": "graduation-cap",
  "mortar-board": "graduation-cap",
  "hand-holding-medical": "hand-holding-medical",
  "person-circle-check": "person-circle-check",
  "square-z": "square-z",
  "message-text": "message-text",
  "comment-alt-text": "message-text",
  "turn-up": "turn-up",
  "level-up-alt": "turn-up",
  monero: "monero",
  hooli: "hooli",
  yelp: "yelp",
  "cc-visa": "cc-visa",
  lastfm: "lastfm",
  shopware: "shopware",
  "creative-commons-nc": "creative-commons-nc",
  aws: "aws",
  redhat: "redhat",
  yoast: "yoast",
  cloudflare: "cloudflare",
  ups: "ups",
  pixiv: "pixiv",
  wpexplorer: "wpexplorer",
  dyalog: "dyalog",
  bity: "bity",
  stackpath: "stackpath",
  buysellads: "buysellads",
  "first-order": "first-order",
  modx: "modx",
  guilded: "guilded",
  vnv: "vnv",
  "square-js": "square-js",
  microsoft: "microsoft",
  qq: "qq",
  orcid: "orcid",
  java: "java",
  invision: "invision",
  "creative-commons-pd-alt": "creative-commons-pd-alt",
  centercode: "centercode",
  "glide-g": "glide-g",
  drupal: "drupal",
  jxl: "jxl",
  "hire-a-helper": "hire-a-helper",
  "creative-commons-by": "creative-commons-by",
  unity: "unity",
  whmcs: "whmcs",
  rocketchat: "rocketchat",
  vk: "vk",
  untappd: "untappd",
  mailchimp: "mailchimp",
  "css3-alt": "css3-alt",
  "square-reddit": "square-reddit",
  "vimeo-v": "vimeo-v",
  contao: "contao",
  "square-font-awesome": "square-font-awesome",
  deskpro: "deskpro",
  brave: "brave",
  sistrix: "sistrix",
  "square-instagram": "square-instagram",
  "battle-net": "battle-net",
  "the-red-yeti": "the-red-yeti",
  "square-hacker-news": "square-hacker-news",
  edge: "edge",
  threads: "threads",
  napster: "napster",
  "square-snapchat": "square-snapchat",
  "google-plus-g": "google-plus-g",
  artstation: "artstation",
  markdown: "markdown",
  sourcetree: "sourcetree",
  "google-plus": "google-plus",
  diaspora: "diaspora",
  foursquare: "foursquare",
  "stack-overflow": "stack-overflow",
  "github-alt": "github-alt",
  "phoenix-squadron": "phoenix-squadron",
  pagelines: "pagelines",
  algolia: "algolia",
  "red-river": "red-river",
  "creative-commons-sa": "creative-commons-sa",
  safari: "safari",
  google: "google",
  "square-font-awesome-stroke": "square-font-awesome-stroke",
  atlassian: "atlassian",
  "linkedin-in": "linkedin-in",
  "digital-ocean": "digital-ocean",
  nimblr: "nimblr",
  chromecast: "chromecast",
  evernote: "evernote",
  "hacker-news": "hacker-news",
  "creative-commons-sampling": "creative-commons-sampling",
  adversal: "adversal",
  "creative-commons": "creative-commons",
  "watchman-monitoring": "watchman-monitoring",
  fonticons: "fonticons",
  weixin: "weixin",
  shirtsinbulk: "shirtsinbulk",
  codepen: "codepen",
  "git-alt": "git-alt",
  lyft: "lyft",
  rev: "rev",
  windows: "windows",
  "wizards-of-the-coast": "wizards-of-the-coast",
  "square-viadeo": "square-viadeo",
  meetup: "meetup",
  centos: "centos",
  adn: "adn",
  cloudsmith: "cloudsmith",
  opensuse: "opensuse",
  "pied-piper-alt": "pied-piper-alt",
  "square-dribbble": "square-dribbble",
  codiepie: "codiepie",
  node: "node",
  mix: "mix",
  steam: "steam",
  "cc-apple-pay": "cc-apple-pay",
  scribd: "scribd",
  debian: "debian",
  openid: "openid",
  instalod: "instalod",
  expeditedssl: "expeditedssl",
  sellcast: "sellcast",
  "square-twitter": "square-twitter",
  "r-project": "r-project",
  delicious: "delicious",
  freebsd: "freebsd",
  vuejs: "vuejs",
  accusoft: "accusoft",
  ioxhost: "ioxhost",
  "fonticons-fi": "fonticons-fi",
  "app-store": "app-store",
  "cc-mastercard": "cc-mastercard",
  "itunes-note": "itunes-note",
  golang: "golang",
  kickstarter: "kickstarter",
  grav: "grav",
  weibo: "weibo",
  uncharted: "uncharted",
  firstdraft: "firstdraft",
  "square-youtube": "square-youtube",
  "wikipedia-w": "wikipedia-w",
  wpressr: "wpressr",
  angellist: "angellist",
  "galactic-republic": "galactic-republic",
  "nfc-directional": "nfc-directional",
  skype: "skype",
  joget: "joget",
  fedora: "fedora",
  "stripe-s": "stripe-s",
  meta: "meta",
  laravel: "laravel",
  hotjar: "hotjar",
  "bluetooth-b": "bluetooth-b",
  "square-letterboxd": "square-letterboxd",
  "sticker-mule": "sticker-mule",
  "creative-commons-zero": "creative-commons-zero",
  hips: "hips",
  behance: "behance",
  reddit: "reddit",
  discord: "discord",
  chrome: "chrome",
  "app-store-ios": "app-store-ios",
  "cc-discover": "cc-discover",
  wpbeginner: "wpbeginner",
  confluence: "confluence",
  shoelace: "shoelace",
  mdb: "mdb",
  dochub: "dochub",
  "accessible-icon": "accessible-icon",
  ebay: "ebay",
  amazon: "amazon",
  unsplash: "unsplash",
  yarn: "yarn",
  "square-steam": "square-steam",
  "500px": "500px",
  "square-vimeo": "square-vimeo",
  asymmetrik: "asymmetrik",
  gratipay: "gratipay",
  apple: "apple",
  hive: "hive",
  gitkraken: "gitkraken",
  keybase: "keybase",
  "apple-pay": "apple-pay",
  padlet: "padlet",
  "amazon-pay": "amazon-pay",
  "square-github": "square-github",
  stumbleupon: "stumbleupon",
  fedex: "fedex",
  "phoenix-framework": "phoenix-framework",
  shopify: "shopify",
  neos: "neos",
  "square-threads": "square-threads",
  hackerrank: "hackerrank",
  researchgate: "researchgate",
  swift: "swift",
  angular: "angular",
  speakap: "speakap",
  angrycreative: "angrycreative",
  "y-combinator": "y-combinator",
  empire: "empire",
  envira: "envira",
  "google-scholar": "google-scholar",
  "square-gitlab": "square-gitlab",
  studiovinari: "studiovinari",
  "pied-piper": "pied-piper",
  wordpress: "wordpress",
  "product-hunt": "product-hunt",
  firefox: "firefox",
  linode: "linode",
  goodreads: "goodreads",
  "square-odnoklassniki": "square-odnoklassniki",
  jsfiddle: "jsfiddle",
  sith: "sith",
  themeisle: "themeisle",
  page4: "page4",
  hashnode: "hashnode",
  react: "react",
  "cc-paypal": "cc-paypal",
  squarespace: "squarespace",
  "cc-stripe": "cc-stripe",
  "creative-commons-share": "creative-commons-share",
  bitcoin: "bitcoin",
  keycdn: "keycdn",
  opera: "opera",
  "itch-io": "itch-io",
  umbraco: "umbraco",
  "galactic-senate": "galactic-senate",
  ubuntu: "ubuntu",
  draft2digital: "draft2digital",
  stripe: "stripe",
  houzz: "houzz",
  gg: "gg",
  dhl: "dhl",
  "square-pinterest": "square-pinterest",
  xing: "xing",
  blackberry: "blackberry",
  "creative-commons-pd": "creative-commons-pd",
  playstation: "playstation",
  quinscape: "quinscape",
  less: "less",
  "blogger-b": "blogger-b",
  opencart: "opencart",
  vine: "vine",
  "signal-messenger": "signal-messenger",
  paypal: "paypal",
  gitlab: "gitlab",
  typo3: "typo3",
  "reddit-alien": "reddit-alien",
  yahoo: "yahoo",
  dailymotion: "dailymotion",
  affiliatetheme: "affiliatetheme",
  "pied-piper-pp": "pied-piper-pp",
  bootstrap: "bootstrap",
  odnoklassniki: "odnoklassniki",
  mintbit: "mintbit",
  ethereum: "ethereum",
  "speaker-deck": "speaker-deck",
  "creative-commons-nc-eu": "creative-commons-nc-eu",
  patreon: "patreon",
  avianex: "avianex",
  ello: "ello",
  gofore: "gofore",
  bimobject: "bimobject",
  "brave-reverse": "brave-reverse",
  "facebook-f": "facebook-f",
  "square-google-plus": "square-google-plus",
  mandalorian: "mandalorian",
  "first-order-alt": "first-order-alt",
  osi: "osi",
  "google-wallet": "google-wallet",
  "d-and-d-beyond": "d-and-d-beyond",
  periscope: "periscope",
  fulcrum: "fulcrum",
  cloudscale: "cloudscale",
  forumbee: "forumbee",
  mizuni: "mizuni",
  schlix: "schlix",
  "square-xing": "square-xing",
  bandcamp: "bandcamp",
  wpforms: "wpforms",
  cloudversify: "cloudversify",
  usps: "usps",
  megaport: "megaport",
  magento: "magento",
  spotify: "spotify",
  "optin-monster": "optin-monster",
  fly: "fly",
  aviato: "aviato",
  itunes: "itunes",
  cuttlefish: "cuttlefish",
  blogger: "blogger",
  flickr: "flickr",
  viber: "viber",
  soundcloud: "soundcloud",
  digg: "digg",
  "tencent-weibo": "tencent-weibo",
  letterboxd: "letterboxd",
  symfony: "symfony",
  maxcdn: "maxcdn",
  etsy: "etsy",
  "facebook-messenger": "facebook-messenger",
  audible: "audible",
  "think-peaks": "think-peaks",
  bilibili: "bilibili",
  erlang: "erlang",
  "x-twitter": "x-twitter",
  "cotton-bureau": "cotton-bureau",
  dashcube: "dashcube",
  "42-group": "42-group",
  "stack-exchange": "stack-exchange",
  elementor: "elementor",
  "square-pied-piper": "square-pied-piper",
  "creative-commons-nd": "creative-commons-nd",
  palfed: "palfed",
  superpowers: "superpowers",
  resolving: "resolving",
  xbox: "xbox",
  "square-web-awesome-stroke": "square-web-awesome-stroke",
  searchengin: "searchengin",
  tiktok: "tiktok",
  "square-facebook": "square-facebook",
  renren: "renren",
  linux: "linux",
  glide: "glide",
  linkedin: "linkedin",
  hubspot: "hubspot",
  deploydog: "deploydog",
  twitch: "twitch",
  ravelry: "ravelry",
  mixer: "mixer",
  "square-lastfm": "square-lastfm",
  vimeo: "vimeo",
  mendeley: "mendeley",
  uniregistry: "uniregistry",
  figma: "figma",
  "creative-commons-remix": "creative-commons-remix",
  "cc-amazon-pay": "cc-amazon-pay",
  dropbox: "dropbox",
  instagram: "instagram",
  cmplid: "cmplid",
  upwork: "upwork",
  facebook: "facebook",
  gripfire: "gripfire",
  "jedi-order": "jedi-order",
  uikit: "uikit",
  "fort-awesome-alt": "fort-awesome-alt",
  phabricator: "phabricator",
  ussunnah: "ussunnah",
  earlybirds: "earlybirds",
  "trade-federation": "trade-federation",
  autoprefixer: "autoprefixer",
  whatsapp: "whatsapp",
  "square-upwork": "square-upwork",
  slideshare: "slideshare",
  "google-play": "google-play",
  viadeo: "viadeo",
  line: "line",
  "google-drive": "google-drive",
  servicestack: "servicestack",
  simplybuilt: "simplybuilt",
  bitbucket: "bitbucket",
  imdb: "imdb",
  deezer: "deezer",
  "raspberry-pi": "raspberry-pi",
  jira: "jira",
  docker: "docker",
  screenpal: "screenpal",
  gitter: "gitter",
  "d-and-d": "d-and-d",
  microblog: "microblog",
  "cc-diners-club": "cc-diners-club",
  "gg-circle": "gg-circle",
  "pied-piper-hat": "pied-piper-hat",
  "kickstarter-k": "kickstarter-k",
  yandex: "yandex",
  readme: "readme",
  html5: "html5",
  sellsy: "sellsy",
  "square-web-awesome": "square-web-awesome",
  sass: "sass",
  wirsindhandwerk: "wirsindhandwerk",
  buromobelexperte: "buromobelexperte",
  salesforce: "salesforce",
  "octopus-deploy": "octopus-deploy",
  medapps: "medapps",
  ns8: "ns8",
  "pinterest-p": "pinterest-p",
  apper: "apper",
  "fort-awesome": "fort-awesome",
  waze: "waze",
  bluesky: "bluesky",
  "cc-jcb": "cc-jcb",
  snapchat: "snapchat",
  "fantasy-flight-games": "fantasy-flight-games",
  rust: "rust",
  wix: "wix",
  "square-behance": "square-behance",
  supple: "supple",
  webflow: "webflow",
  rebel: "rebel",
  css3: "css3",
  staylinked: "staylinked",
  kaggle: "kaggle",
  "space-awesome": "space-awesome",
  deviantart: "deviantart",
  cpanel: "cpanel",
  "goodreads-g": "goodreads-g",
  "square-git": "square-git",
  "square-tumblr": "square-tumblr",
  trello: "trello",
  "creative-commons-nc-jp": "creative-commons-nc-jp",
  "get-pocket": "get-pocket",
  perbyte: "perbyte",
  grunt: "grunt",
  weebly: "weebly",
  connectdevelop: "connectdevelop",
  leanpub: "leanpub",
  "black-tie": "black-tie",
  themeco: "themeco",
  python: "python",
  android: "android",
  bots: "bots",
  "free-code-camp": "free-code-camp",
  hornbill: "hornbill",
  js: "js",
  ideal: "ideal",
  git: "git",
  dev: "dev",
  sketch: "sketch",
  "yandex-international": "yandex-international",
  "cc-amex": "cc-amex",
  uber: "uber",
  github: "github",
  php: "php",
  alipay: "alipay",
  youtube: "youtube",
  skyatlas: "skyatlas",
  "firefox-browser": "firefox-browser",
  replyd: "replyd",
  suse: "suse",
  jenkins: "jenkins",
  twitter: "twitter",
  rockrms: "rockrms",
  pinterest: "pinterest",
  buffer: "buffer",
  npm: "npm",
  yammer: "yammer",
  btc: "btc",
  dribbble: "dribbble",
  "stumbleupon-circle": "stumbleupon-circle",
  "internet-explorer": "internet-explorer",
  stubber: "stubber",
  telegram: "telegram",
  "old-republic": "old-republic",
  odysee: "odysee",
  "square-whatsapp": "square-whatsapp",
  "node-js": "node-js",
  "edge-legacy": "edge-legacy",
  slack: "slack",
  medrt: "medrt",
  usb: "usb",
  tumblr: "tumblr",
  vaadin: "vaadin",
  quora: "quora",
  "square-x-twitter": "square-x-twitter",
  reacteurope: "reacteurope",
  medium: "medium",
  amilia: "amilia",
  mixcloud: "mixcloud",
  flipboard: "flipboard",
  viacoin: "viacoin",
  "critical-role": "critical-role",
  sitrox: "sitrox",
  discourse: "discourse",
  joomla: "joomla",
  mastodon: "mastodon",
  airbnb: "airbnb",
  "wolf-pack-battalion": "wolf-pack-battalion",
  "buy-n-large": "buy-n-large",
  gulp: "gulp",
  "creative-commons-sampling-plus": "creative-commons-sampling-plus",
  strava: "strava",
  ember: "ember",
  "canadian-maple-leaf": "canadian-maple-leaf",
  teamspeak: "teamspeak",
  pushed: "pushed",
  "wordpress-simple": "wordpress-simple",
  nutritionix: "nutritionix",
  wodu: "wodu",
  "google-pay": "google-pay",
  intercom: "intercom",
  zhihu: "zhihu",
  korvue: "korvue",
  pix: "pix",
  "steam-symbol": "steam-symbol",
  "bicycle-stacked-red-lockup-rgb": "bicycle-stacked-red-lockup-rgb",
  "bicycle-symbol-red-rgb": "bicycle-symbol-red-rgb",
  "bicycle-inline-red-lockup-rgb": "bicycle-inline-red-lockup-rgb",
  "bicycle-logotype-blue-rgb": "bicycle-logotype-blue-rgb"
};

const faIconNameToVariantsMap = {
  "0": ["solid", "regular"],
  "1": ["solid", "regular"],
  "2": ["solid", "regular"],
  "3": ["solid", "regular"],
  "4": ["solid", "regular"],
  "5": ["solid", "regular"],
  "6": ["solid", "regular"],
  "7": ["solid", "regular"],
  "8": ["solid", "regular"],
  "9": ["solid", "regular"],
  truck: ["solid", "regular"],
  viruses: ["solid", "regular"],
  "truck-utensils": ["solid", "regular"],
  "grid-round-5": ["solid", "regular"],
  "arrows-cross": ["solid", "regular"],
  "file-doc": ["solid", "regular"],
  dna: ["solid", "regular"],
  "face-zany": ["solid", "regular"],
  "laptop-medical": ["solid", "regular"],
  rug: ["solid", "regular"],
  "truck-front": ["solid", "regular"],
  spade: ["solid", "regular"],
  "suitcase-rolling": ["solid", "regular"],
  "person-carry-box": ["solid", "regular"],
  "watch-smart": ["solid", "regular"],
  "user-nurse": ["solid", "regular"],
  plane: ["solid", "regular"],
  "clipboard-medical": ["solid", "regular"],
  "horizontal-rule": ["solid", "regular"],
  "tree-deciduous": ["solid", "regular"],
  turtle: ["solid", "regular"],
  "up-right-and-down-left-from-center": ["solid", "regular"],
  garlic: ["solid", "regular"],
  "file-chart-pie": ["solid", "regular"],
  "circle-d": ["solid", "regular"],
  "bell-school-slash": ["solid", "regular"],
  "draw-circle": ["solid", "regular"],
  "naira-sign": ["solid", "regular"],
  "triangle-instrument": ["solid", "regular"],
  "face-grin-beam": ["solid", "regular"],
  hexagon: ["solid", "regular"],
  "hand-point-down": ["solid", "regular"],
  "bowl-scoops": ["solid", "regular"],
  "hand-lizard": ["solid", "regular"],
  "scale-balanced": ["solid", "regular"],
  "person-falling": ["solid", "regular"],
  "circle-xmark": ["solid", "regular"],
  "snowman-head": ["solid", "regular"],
  p: ["solid", "regular"],
  futbol: ["solid", "regular"],
  spoon: ["solid", "regular"],
  "clock-five": ["solid", "regular"],
  "hand-back-point-left": ["solid", "regular"],
  "basket-shopping": ["solid", "regular"],
  "file-invoice": ["solid", "regular"],
  "vial-virus": ["solid", "regular"],
  coconut: ["solid", "regular"],
  steak: ["solid", "regular"],
  apostrophe: ["solid", "regular"],
  olive: ["solid", "regular"],
  cheese: ["solid", "regular"],
  "microchip-ai": ["solid", "regular"],
  "display-chart-up": ["solid", "regular"],
  "location-crosshairs-slash": ["solid", "regular"],
  "closed-captioning": ["solid", "regular"],
  "square-up-right": ["solid", "regular"],
  "album-collection-circle-user": ["solid", "regular"],
  "arrow-up-to-line": ["solid", "regular"],
  "objects-align-center-vertical": ["solid", "regular"],
  "ban-smoking": ["solid", "regular"],
  "caret-left": ["solid", "regular"],
  "gun-slash": ["solid", "regular"],
  wallet: ["solid", "regular"],
  "wave-pulse": ["solid", "regular"],
  "eye-low-vision": ["solid", "regular"],
  "objects-align-right": ["solid", "regular"],
  rv: ["solid", "regular"],
  "delete-left": ["solid", "regular"],
  "gauge-high": ["solid", "regular"],
  "leafy-green": ["solid", "regular"],
  "face-frown-open": ["solid", "regular"],
  "arrows-rotate": ["solid", "regular"],
  drum: ["solid", "regular"],
  "table-pivot": ["solid", "regular"],
  copy: ["solid", "regular"],
  "square-l": ["solid", "regular"],
  "arrow-up-z-a": ["solid", "regular"],
  sack: ["solid", "regular"],
  "user-tag": ["solid", "regular"],
  "circle-phone-flip": ["solid", "regular"],
  "signal-strong": ["solid", "regular"],
  "bridge-suspension": ["solid", "regular"],
  "cedi-sign": ["solid", "regular"],
  tooth: ["solid", "regular"],
  "leaf-maple": ["solid", "regular"],
  "person-walking-dashed-line-arrow-right": ["solid", "regular"],
  "bell-plus": ["solid", "regular"],
  "cart-circle-xmark": ["solid", "regular"],
  "square-poll-horizontal": ["solid", "regular"],
  "code-pull-request-closed": ["solid", "regular"],
  "square-fragile": ["solid", "regular"],
  "face-pouting": ["solid", "regular"],
  "message-slash": ["solid", "regular"],
  "circle-envelope": ["solid", "regular"],
  "file-slash": ["solid", "regular"],
  "circle-arrow-down-right": ["solid", "regular"],
  "shirt-running": ["solid", "regular"],
  "square-caret-up": ["solid", "regular"],
  "file-xml": ["solid", "regular"],
  "car-bolt": ["solid", "regular"],
  "page-caret-down": ["solid", "regular"],
  "tower-broadcast": ["solid", "regular"],
  "square-ellipsis-vertical": ["solid", "regular"],
  starfighter: ["solid", "regular"],
  diploma: ["solid", "regular"],
  "gauge-low": ["solid", "regular"],
  pedestal: ["solid", "regular"],
  "square-divide": ["solid", "regular"],
  "face-confounded": ["solid", "regular"],
  "key-skeleton-left-right": ["solid", "regular"],
  "space-station-moon-construction": ["solid", "regular"],
  "universal-access": ["solid", "regular"],
  "sun-cloud": ["solid", "regular"],
  croissant: ["solid", "regular"],
  "diagram-sankey": ["solid", "regular"],
  "00": ["solid", "regular"],
  "chart-network": ["solid", "regular"],
  "sensor-on": ["solid", "regular"],
  "comment-text": ["solid", "regular"],
  "face-relieved": ["solid", "regular"],
  "arrow-turn-left-down": ["solid", "regular"],
  "cloud-xmark": ["solid", "regular"],
  drumstick: ["solid", "regular"],
  panorama: ["solid", "regular"],
  "diamond-exclamation": ["solid", "regular"],
  "bus-simple": ["solid", "regular"],
  "calendar-xmark": ["solid", "regular"],
  "circle-bolt": ["solid", "regular"],
  "face-smile-beam": ["solid", "regular"],
  "hand-holding-skull": ["solid", "regular"],
  meteor: ["solid", "regular"],
  "circle-dollar": ["solid", "regular"],
  "person-seat-reclined": ["solid", "regular"],
  a: ["solid", "regular"],
  mace: ["solid", "regular"],
  "building-magnifying-glass": ["solid", "regular"],
  receipt: ["solid", "regular"],
  virus: ["solid", "regular"],
  xmark: ["solid", "regular"],
  "gauge-max": ["solid", "regular"],
  "file-arrow-up": ["solid", "regular"],
  "plane-up-slash": ["solid", "regular"],
  leaf: ["solid", "regular"],
  "face-disguise": ["solid", "regular"],
  "mobile-screen-button": ["solid", "regular"],
  "people-dress": ["solid", "regular"],
  "crop-simple": ["solid", "regular"],
  "grip-dots-vertical": ["solid", "regular"],
  "money-bill": ["solid", "regular"],
  "down-right": ["solid", "regular"],
  banjo: ["solid", "regular"],
  "land-mine-on": ["solid", "regular"],
  "person-circle-xmark": ["solid", "regular"],
  "cloud-snow": ["solid", "regular"],
  "bed-pulse": ["solid", "regular"],
  "magnifying-glass-chart": ["solid", "regular"],
  "tree-city": ["solid", "regular"],
  "pen-line": ["solid", "regular"],
  "message-question": ["solid", "regular"],
  "heart-pulse": ["solid", "regular"],
  "comments-dollar": ["solid", "regular"],
  "envelope-dot": ["solid", "regular"],
  "chart-scatter": ["solid", "regular"],
  qrcode: ["solid", "regular"],
  "temperature-list": ["solid", "regular"],
  "oil-can-drip": ["solid", "regular"],
  "earth-africa": ["solid", "regular"],
  "moon-stars": ["solid", "regular"],
  "file-audio": ["solid", "regular"],
  "face-pensive": ["solid", "regular"],
  venus: ["solid", "regular"],
  "scanner-touchscreen": ["solid", "regular"],
  "usb-drive": ["solid", "regular"],
  "arrow-down-to-square": ["solid", "regular"],
  music: ["solid", "regular"],
  gopuram: ["solid", "regular"],
  "file-mov": ["solid", "regular"],
  "distribute-spacing-horizontal": ["solid", "regular"],
  "computer-classic": ["solid", "regular"],
  clipboard: ["solid", "regular"],
  "unlock-keyhole": ["solid", "regular"],
  sitemap: ["solid", "regular"],
  "face-flushed": ["solid", "regular"],
  "arrow-right-to-arc": ["solid", "regular"],
  "road-circle-xmark": ["solid", "regular"],
  "address-book": ["solid", "regular"],
  "box-ballot": ["solid", "regular"],
  book: ["solid", "regular"],
  "bring-front": ["solid", "regular"],
  "house-signal": ["solid", "regular"],
  "chess-queen-piece": ["solid", "regular"],
  "file-heart": ["solid", "regular"],
  "square-user": ["solid", "regular"],
  image: ["solid", "regular"],
  superscript: ["solid", "regular"],
  "desktop-arrow-down": ["solid", "regular"],
  "wifi-exclamation": ["solid", "regular"],
  "traffic-light-stop": ["solid", "regular"],
  "hill-rockslide": ["solid", "regular"],
  "kit-medical": ["solid", "regular"],
  shovel: ["solid", "regular"],
  gem: ["solid", "regular"],
  "circle-pause": ["solid", "regular"],
  "user-pilot-tie": ["solid", "regular"],
  "cart-arrow-up": ["solid", "regular"],
  "filter-list": ["solid", "regular"],
  "message-heart": ["solid", "regular"],
  clock: ["solid", "regular"],
  "magnifying-glass": ["solid", "regular"],
  "arrow-up-small-big": ["solid", "regular"],
  "comment-heart": ["solid", "regular"],
  lambda: ["solid", "regular"],
  "share-from-square": ["solid", "regular"],
  share: ["solid", "regular"],
  lemon: ["solid", "regular"],
  "file-zipper": ["solid", "regular"],
  "face-grin-wink": ["solid", "regular"],
  "umbrella-beach": ["solid", "regular"],
  "grip-dots": ["solid", "regular"],
  "face-grin-tears": ["solid", "regular"],
  "code-fork": ["solid", "regular"],
  "arrows-up-down-left-right": ["solid", "regular"],
  "money-bill-1-wave": ["solid", "regular"],
  "cards-blank": ["solid", "regular"],
  "gauge-simple-max": ["solid", "regular"],
  underline: ["solid", "regular"],
  "circle-h": ["solid", "regular"],
  "phone-intercom": ["solid", "regular"],
  icicles: ["solid", "regular"],
  otter: ["solid", "regular"],
  "send-backward": ["solid", "regular"],
  "tree-large": ["solid", "regular"],
  "rupee-sign": ["solid", "regular"],
  "camera-polaroid": ["solid", "regular"],
  "truck-plane": ["solid", "regular"],
  "display-arrow-down": ["solid", "regular"],
  pineapple: ["solid", "regular"],
  "clothes-hanger": ["solid", "regular"],
  "ear-deaf": ["solid", "regular"],
  "circle-l": ["solid", "regular"],
  "arrow-up-left": ["solid", "regular"],
  sunrise: ["solid", "regular"],
  chopsticks: ["solid", "regular"],
  comments: ["solid", "regular"],
  "blinds-open": ["solid", "regular"],
  "circle-up": ["solid", "regular"],
  "handshake-slash": ["solid", "regular"],
  cubes: ["solid", "regular"],
  "coffin-cross": ["solid", "regular"],
  "house-medical-circle-check": ["solid", "regular"],
  grate: ["solid", "regular"],
  "cloud-check": ["solid", "regular"],
  "mars-stroke-up": ["solid", "regular"],
  "file-magnifying-glass": ["solid", "regular"],
  "clock-two": ["solid", "regular"],
  stamp: ["solid", "regular"],
  "square-phone": ["solid", "regular"],
  "plane-lock": ["solid", "regular"],
  "face-grin-tongue": ["solid", "regular"],
  "chair-office": ["solid", "regular"],
  "arrow-up-from-line": ["solid", "regular"],
  "basket-shopping-plus": ["solid", "regular"],
  falafel: ["solid", "regular"],
  "oil-can": ["solid", "regular"],
  "plug-circle-exclamation": ["solid", "regular"],
  citrus: ["solid", "regular"],
  "spider-black-widow": ["solid", "regular"],
  sickle: ["solid", "regular"],
  "glass-empty": ["solid", "regular"],
  "backward-fast": ["solid", "regular"],
  "car-bump": ["solid", "regular"],
  cauldron: ["solid", "regular"],
  "dice-four": ["solid", "regular"],
  "building-wheat": ["solid", "regular"],
  "circle-g": ["solid", "regular"],
  "object-intersect": ["solid", "regular"],
  "people-simple": ["solid", "regular"],
  "shield-quartered": ["solid", "regular"],
  "house-medical": ["solid", "regular"],
  "input-text": ["solid", "regular"],
  "chess-pawn-piece": ["solid", "regular"],
  magnet: ["solid", "regular"],
  down: ["solid", "regular"],
  "arrow-turn-down-left": ["solid", "regular"],
  "inbox-full": ["solid", "regular"],
  "place-of-worship": ["solid", "regular"],
  "light-switch-off": ["solid", "regular"],
  "arrow-turn-left-up": ["solid", "regular"],
  "wagon-covered": ["solid", "regular"],
  "file-shield": ["solid", "regular"],
  "arrow-down-long": ["solid", "regular"],
  water: ["solid", "regular"],
  "police-box": ["solid", "regular"],
  "circle-7": ["solid", "regular"],
  "monitor-waveform": ["solid", "regular"],
  "road-circle-check": ["solid", "regular"],
  "chart-gantt": ["solid", "regular"],
  spinner: ["solid", "regular"],
  whale: ["solid", "regular"],
  "signal-slash": ["solid", "regular"],
  faucet: ["solid", "regular"],
  h3: ["solid", "regular"],
  squid: ["solid", "regular"],
  "weight-hanging": ["solid", "regular"],
  "photo-film": ["solid", "regular"],
  "file-gif": ["solid", "regular"],
  "file-lines": ["solid", "regular"],
  "envelope-circle-check": ["solid", "regular"],
  timer: ["solid", "regular"],
  "trash-can-list": ["solid", "regular"],
  "pipe-valve": ["solid", "regular"],
  "people-line": ["solid", "regular"],
  "person-drowning": ["solid", "regular"],
  backpack: ["solid", "regular"],
  "house-chimney-blank": ["solid", "regular"],
  "calendar-minus": ["solid", "regular"],
  "circle-microphone-lines": ["solid", "regular"],
  "pizza-slice": ["solid", "regular"],
  "signature-lock": ["solid", "regular"],
  "person-breastfeeding": ["solid", "regular"],
  "cart-xmark": ["solid", "regular"],
  "heart-circle-plus": ["solid", "regular"],
  landmark: ["solid", "regular"],
  "person-pregnant": ["solid", "regular"],
  "table-tennis-paddle-ball": ["solid", "regular"],
  "code-pull-request-draft": ["solid", "regular"],
  "graduation-cap": ["solid", "regular"],
  "face-sleepy": ["solid", "regular"],
  "holly-berry": ["solid", "regular"],
  "clouds-sun": ["solid", "regular"],
  "comment-middle-top": ["solid", "regular"],
  "face-angry-horns": ["solid", "regular"],
  "ball-pile": ["solid", "regular"],
  "money-bill-1": ["solid", "regular"],
  stopwatch: ["solid", "regular"],
  "conveyor-belt-arm": ["solid", "regular"],
  tomato: ["solid", "regular"],
  ethernet: ["solid", "regular"],
  "tank-water": ["solid", "regular"],
  slider: ["solid", "regular"],
  "manat-sign": ["solid", "regular"],
  "bread-slice-butter": ["solid", "regular"],
  "circle-calendar": ["solid", "regular"],
  "hands-holding-child": ["solid", "regular"],
  fire: ["solid", "regular"],
  "up-to-bracket": ["solid", "regular"],
  "van-shuttle": ["solid", "regular"],
  "up-right-from-square": ["solid", "regular"],
  "steering-wheel": ["solid", "regular"],
  "people-pulling": ["solid", "regular"],
  "window-frame": ["solid", "regular"],
  "phone-rotary": ["solid", "regular"],
  school: ["solid", "regular"],
  "flying-disc": ["solid", "regular"],
  "user-xmark": ["solid", "regular"],
  "clock-rotate-left": ["solid", "regular"],
  "floppy-disk": ["solid", "regular"],
  "grid-4": ["solid", "regular"],
  "house-circle-check": ["solid", "regular"],
  "battery-quarter": ["solid", "regular"],
  "face-thinking": ["solid", "regular"],
  "hand-holding-circle-dollar": ["solid", "regular"],
  display: ["solid", "regular"],
  "location-pin-slash": ["solid", "regular"],
  "bed-front": ["solid", "regular"],
  "book-open-reader": ["solid", "regular"],
  "sensor-cloud": ["solid", "regular"],
  "house-chimney": ["solid", "regular"],
  "earth-asia": ["solid", "regular"],
  church: ["solid", "regular"],
  "cloud-exclamation": ["solid", "regular"],
  "border-inner": ["solid", "regular"],
  "battery-slash": ["solid", "regular"],
  "piggy-bank": ["solid", "regular"],
  "dice-one": ["solid", "regular"],
  "code-merge": ["solid", "regular"],
  "circle-q": ["solid", "regular"],
  "calendar-star": ["solid", "regular"],
  "arrow-up-from-ground-water": ["solid", "regular"],
  "circle-arrow-up-left": ["solid", "regular"],
  trademark: ["solid", "regular"],
  shapes: ["solid", "regular"],
  "bullseye-arrow": ["solid", "regular"],
  farm: ["solid", "regular"],
  "face-smile-wink": ["solid", "regular"],
  "memo-circle-check": ["solid", "regular"],
  "star-of-david": ["solid", "regular"],
  "money-from-bracket": ["solid", "regular"],
  nose: ["solid", "regular"],
  ampersand: ["solid", "regular"],
  "mountain-city": ["solid", "regular"],
  crow: ["solid", "regular"],
  "olive-branch": ["solid", "regular"],
  "chart-bar": ["solid", "regular"],
  coin: ["solid", "regular"],
  "circle-chevron-right": ["solid", "regular"],
  "washing-machine": ["solid", "regular"],
  "hammer-crash": ["solid", "regular"],
  "face-grin-hearts": ["solid", "regular"],
  "mountain-sun": ["solid", "regular"],
  "face-laugh-beam": ["solid", "regular"],
  "paint-roller": ["solid", "regular"],
  "hand-scissors": ["solid", "regular"],
  "cloud-slash": ["solid", "regular"],
  "building-circle-exclamation": ["solid", "regular"],
  speakers: ["solid", "regular"],
  "square-x": ["solid", "regular"],
  "head-side-goggles": ["solid", "regular"],
  "wrench-simple": ["solid", "regular"],
  "brazilian-real-sign": ["solid", "regular"],
  bullhorn: ["solid", "regular"],
  "bowl-scoop": ["solid", "regular"],
  "arrows-down-to-people": ["solid", "regular"],
  "calendar-lines-pen": ["solid", "regular"],
  "hands-bound": ["solid", "regular"],
  "temperature-quarter": ["solid", "regular"],
  "face-kiss": ["solid", "regular"],
  "square-arrow-right": ["solid", "regular"],
  "message-plus": ["solid", "regular"],
  "arrow-right-long-to-line": ["solid", "regular"],
  "circle-p": ["solid", "regular"],
  "sign-posts-wrench": ["solid", "regular"],
  "tire-pressure-warning": ["solid", "regular"],
  "money-check-dollar-pen": ["solid", "regular"],
  "cart-circle-arrow-up": ["solid", "regular"],
  "anchor-lock": ["solid", "regular"],
  "ticket-perforated": ["solid", "regular"],
  "user-tie-hair-long": ["solid", "regular"],
  "users-between-lines": ["solid", "regular"],
  soap: ["solid", "regular"],
  "flask-round-potion": ["solid", "regular"],
  circle: ["solid", "regular"],
  "tent-arrow-turn-left": ["solid", "regular"],
  swap: ["solid", "regular"],
  turntable: ["solid", "regular"],
  "hand-back-point-down": ["solid", "regular"],
  igloo: ["solid", "regular"],
  mask: ["solid", "regular"],
  "clock-nine-thirty": ["solid", "regular"],
  "clock-three-thirty": ["solid", "regular"],
  "square-7": ["solid", "regular"],
  "pen-fancy": ["solid", "regular"],
  locust: ["solid", "regular"],
  "square-poll-vertical": ["solid", "regular"],
  caravan: ["solid", "regular"],
  "fish-cooked": ["solid", "regular"],
  mustache: ["solid", "regular"],
  "traffic-light": ["solid", "regular"],
  "hand-back-fist": ["solid", "regular"],
  "cart-flatbed-empty": ["solid", "regular"],
  "dice-d10": ["solid", "regular"],
  "hand-holding-heart": ["solid", "regular"],
  "store-slash": ["solid", "regular"],
  colon: ["solid", "regular"],
  "bell-concierge": ["solid", "regular"],
  "swords-laser": ["solid", "regular"],
  "arrow-turn-down-right": ["solid", "regular"],
  "clock-five-thirty": ["solid", "regular"],
  triangle: ["solid", "regular"],
  "transporter-7": ["solid", "regular"],
  neuter: ["solid", "regular"],
  snowman: ["solid", "regular"],
  certificate: ["solid", "regular"],
  "square-a-lock": ["solid", "regular"],
  "toilet-paper-check": ["solid", "regular"],
  vials: ["solid", "regular"],
  "clock-six-thirty": ["solid", "regular"],
  "mosquito-net": ["solid", "regular"],
  "left-from-line": ["solid", "regular"],
  "binary-lock": ["solid", "regular"],
  "diagram-next": ["solid", "regular"],
  stars: ["solid", "regular"],
  socks: ["solid", "regular"],
  table: ["solid", "regular"],
  "arrow-down-9-1": ["solid", "regular"],
  biohazard: ["solid", "regular"],
  "group-arrows-rotate": ["solid", "regular"],
  "phone-arrow-down-left": ["solid", "regular"],
  "lightbulb-on": ["solid", "regular"],
  deer: ["solid", "regular"],
  "road-spikes": ["solid", "regular"],
  "bars-staggered": ["solid", "regular"],
  peanut: ["solid", "regular"],
  "conveyor-belt-boxes": ["solid", "regular"],
  "octagon-minus": ["solid", "regular"],
  "objects-align-bottom": ["solid", "regular"],
  "chevron-left": ["solid", "regular"],
  "clock-one": ["solid", "regular"],
  trowel: ["solid", "regular"],
  "gauge-circle-minus": ["solid", "regular"],
  "circle-3": ["solid", "regular"],
  "bell-slash": ["solid", "regular"],
  "fire-burner": ["solid", "regular"],
  "thought-bubble": ["solid", "regular"],
  "binary-circle-check": ["solid", "regular"],
  "wheat-slash": ["solid", "regular"],
  shrimp: ["solid", "regular"],
  "container-storage": ["solid", "regular"],
  "face-laugh-squint": ["solid", "regular"],
  bullseye: ["solid", "regular"],
  "grid-5": ["solid", "regular"],
  "grid-round-4": ["solid", "regular"],
  "face-weary": ["solid", "regular"],
  "fish-bones": ["solid", "regular"],
  "bracket-square": ["solid", "regular"],
  palette: ["solid", "regular"],
  "face-hand-over-mouth": ["solid", "regular"],
  hose: ["solid", "regular"],
  "house-chimney-crack": ["solid", "regular"],
  "less-than-equal": ["solid", "regular"],
  "money-bill-simple": ["solid", "regular"],
  "hockey-mask": ["solid", "regular"],
  "bin-recycle": ["solid", "regular"],
  "glass-water": ["solid", "regular"],
  "arrows-minimize": ["solid", "regular"],
  lips: ["solid", "regular"],
  "hockey-sticks": ["solid", "regular"],
  "print-magnifying-glass": ["solid", "regular"],
  "drone-front": ["solid", "regular"],
  "notes-medical": ["solid", "regular"],
  "shop-lock": ["solid", "regular"],
  "guarani-sign": ["solid", "regular"],
  "table-cells-lock": ["solid", "regular"],
  flatbread: ["solid", "regular"],
  "car-battery": ["solid", "regular"],
  "teeth-open": ["solid", "regular"],
  "arrows-left-right-to-line": ["solid", "regular"],
  "face-tongue-sweat": ["solid", "regular"],
  duck: ["solid", "regular"],
  "clock-seven": ["solid", "regular"],
  "dial-low": ["solid", "regular"],
  "phone-office": ["solid", "regular"],
  tally: ["solid", "regular"],
  "person-sign": ["solid", "regular"],
  syringe: ["solid", "regular"],
  "square-sliders-vertical": ["solid", "regular"],
  swatchbook: ["solid", "regular"],
  "flower-daffodil": ["solid", "regular"],
  "temperature-arrow-down": ["solid", "regular"],
  "phone-arrow-up-right": ["solid", "regular"],
  genderless: ["solid", "regular"],
  "circle-bookmark": ["solid", "regular"],
  "star-sharp-half": ["solid", "regular"],
  "face-saluting": ["solid", "regular"],
  "file-circle-exclamation": ["solid", "regular"],
  "truck-ramp": ["solid", "regular"],
  caduceus: ["solid", "regular"],
  "screwdriver-wrench": ["solid", "regular"],
  "user-cowboy": ["solid", "regular"],
  "inbox-out": ["solid", "regular"],
  "truck-field": ["solid", "regular"],
  building: ["solid", "regular"],
  "up-down": ["solid", "regular"],
  lighthouse: ["solid", "regular"],
  "border-center-v": ["solid", "regular"],
  ship: ["solid", "regular"],
  "person-swimming": ["solid", "regular"],
  "train-tram": ["solid", "regular"],
  "bottle-baby": ["solid", "regular"],
  "tickets-perforated": ["solid", "regular"],
  "file-vector": ["solid", "regular"],
  "circle-i": ["solid", "regular"],
  "calendar-plus": ["solid", "regular"],
  "circle-u": ["solid", "regular"],
  "pen-to-square": ["solid", "regular"],
  "display-medical": ["solid", "regular"],
  "timeline-arrow": ["solid", "regular"],
  "map-location-dot": ["solid", "regular"],
  "venus-double": ["solid", "regular"],
  "face-anguished": ["solid", "regular"],
  "bell-exclamation": ["solid", "regular"],
  "person-chalkboard": ["solid", "regular"],
  "bore-hole": ["solid", "regular"],
  "hill-avalanche": ["solid", "regular"],
  radiation: ["solid", "regular"],
  "square-m": ["solid", "regular"],
  "block-brick": ["solid", "regular"],
  knife: ["solid", "regular"],
  tarp: ["solid", "regular"],
  "check-double": ["solid", "regular"],
  tamale: ["solid", "regular"],
  "right-to-bracket": ["solid", "regular"],
  window: ["solid", "regular"],
  mountain: ["solid", "regular"],
  "chart-pyramid": ["solid", "regular"],
  "user-doctor-message": ["solid", "regular"],
  "slash-back": ["solid", "regular"],
  "dong-sign": ["solid", "regular"],
  "money-simple-from-bracket": ["solid", "regular"],
  "circle-chevron-up": ["solid", "regular"],
  h6: ["solid", "regular"],
  staff: ["solid", "regular"],
  flag: ["solid", "regular"],
  pig: ["solid", "regular"],
  "gear-code": ["solid", "regular"],
  "image-slash": ["solid", "regular"],
  globe: ["solid", "regular"],
  "lightbulb-exclamation": ["solid", "regular"],
  "lari-sign": ["solid", "regular"],
  "golf-flag-hole": ["solid", "regular"],
  omega: ["solid", "regular"],
  "spinner-scale": ["solid", "regular"],
  "cloud-showers": ["solid", "regular"],
  "plus-minus": ["solid", "regular"],
  "book-medical": ["solid", "regular"],
  "piano-keyboard": ["solid", "regular"],
  "circle-caret-right": ["solid", "regular"],
  candy: ["solid", "regular"],
  "wheat-awn": ["solid", "regular"],
  "face-grin-squint": ["solid", "regular"],
  "book-tanakh": ["solid", "regular"],
  "square-star": ["solid", "regular"],
  "arrows-to-circle": ["solid", "regular"],
  popsicle: ["solid", "regular"],
  "person-to-portal": ["solid", "regular"],
  "arrow-rotate-left": ["solid", "regular"],
  "family-pants": ["solid", "regular"],
  gamepad: ["solid", "regular"],
  note: ["solid", "regular"],
  "arrow-down-to-arc": ["solid", "regular"],
  "folder-xmark": ["solid", "regular"],
  "phone-xmark": ["solid", "regular"],
  "heart-circle-minus": ["solid", "regular"],
  "truck-plow": ["solid", "regular"],
  "trash-arrow-up": ["solid", "regular"],
  "gauge-min": ["solid", "regular"],
  "arrow-right-to-city": ["solid", "regular"],
  "mobile-screen": ["solid", "regular"],
  "inbox-in": ["solid", "regular"],
  "cloud-arrow-down": ["solid", "regular"],
  ant: ["solid", "regular"],
  check: ["solid", "regular"],
  "baht-sign": ["solid", "regular"],
  memo: ["solid", "regular"],
  "wave-triangle": ["solid", "regular"],
  "thumbs-up": ["solid", "regular"],
  "chart-mixed-up-circle-currency": ["solid", "regular"],
  "comment-dots": ["solid", "regular"],
  people: ["solid", "regular"],
  "turn-left-up": ["solid", "regular"],
  "tugrik-sign": ["solid", "regular"],
  "anchor-circle-exclamation": ["solid", "regular"],
  map: ["solid", "regular"],
  heat: ["solid", "regular"],
  "square-9": ["solid", "regular"],
  "head-side-brain": ["solid", "regular"],
  "cloud-binary": ["solid", "regular"],
  "comments-question-check": ["solid", "regular"],
  "face-smile-upside-down": ["solid", "regular"],
  "fishing-rod": ["solid", "regular"],
  "arrows-up-down": ["solid", "regular"],
  bolt: ["solid", "regular"],
  "person-dress-fairy": ["solid", "regular"],
  "crate-empty": ["solid", "regular"],
  "starfighter-twin-ion-engine": ["solid", "regular"],
  "person-ski-jumping": ["solid", "regular"],
  "right-long": ["solid", "regular"],
  "square-sliders": ["solid", "regular"],
  "clock-six": ["solid", "regular"],
  "map-pin": ["solid", "regular"],
  "house-building": ["solid", "regular"],
  "calendar-users": ["solid", "regular"],
  "signal-bars-weak": ["solid", "regular"],
  "salt-shaker": ["solid", "regular"],
  unlock: ["solid", "regular"],
  "rectangle-history-circle-plus": ["solid", "regular"],
  "pipe-section": ["solid", "regular"],
  "house-tree": ["solid", "regular"],
  "panel-ews": ["solid", "regular"],
  "square-caret-left": ["solid", "regular"],
  "object-union": ["solid", "regular"],
  "flask-vial": ["solid", "regular"],
  "face-smile-tear": ["solid", "regular"],
  "badge-percent": ["solid", "regular"],
  "calendar-circle-user": ["solid", "regular"],
  "objects-column": ["solid", "regular"],
  "tickets-simple": ["solid", "regular"],
  gramophone: ["solid", "regular"],
  stapler: ["solid", "regular"],
  "plane-prop": ["solid", "regular"],
  "house-circle-exclamation": ["solid", "regular"],
  "paintbrush-pencil": ["solid", "regular"],
  "face-party": ["solid", "regular"],
  "user-chef": ["solid", "regular"],
  broccoli: ["solid", "regular"],
  "face-kiss-closed-eyes": ["solid", "regular"],
  "game-console-handheld-crank": ["solid", "regular"],
  "pallet-box": ["solid", "regular"],
  dove: ["solid", "regular"],
  "arrow-up-left-from-circle": ["solid", "regular"],
  ear: ["solid", "regular"],
  "luchador-mask": ["solid", "regular"],
  "chess-rook": ["solid", "regular"],
  pie: ["solid", "regular"],
  "text-width": ["solid", "regular"],
  "person-from-portal": ["solid", "regular"],
  "triangle-exclamation": ["solid", "regular"],
  "family-dress": ["solid", "regular"],
  clouds: ["solid", "regular"],
  "dice-five": ["solid", "regular"],
  "chart-candlestick": ["solid", "regular"],
  poop: ["solid", "regular"],
  mountains: ["solid", "regular"],
  "scanner-image": ["solid", "regular"],
  "transporter-5": ["solid", "regular"],
  "face-smirking": ["solid", "regular"],
  trophy: ["solid", "regular"],
  "list-ol": ["solid", "regular"],
  "spray-can-sparkles": ["solid", "regular"],
  "chart-simple-horizontal": ["solid", "regular"],
  "car-bus": ["solid", "regular"],
  "wheelchair-move": ["solid", "regular"],
  "face-icicles": ["solid", "regular"],
  "plug-circle-xmark": ["solid", "regular"],
  "briefcase-medical": ["solid", "regular"],
  elephant: ["solid", "regular"],
  fill: ["solid", "regular"],
  "signal-bars-good": ["solid", "regular"],
  "right-from-line": ["solid", "regular"],
  crutch: ["solid", "regular"],
  "tree-christmas": ["solid", "regular"],
  om: ["solid", "regular"],
  "building-user": ["solid", "regular"],
  synagogue: ["solid", "regular"],
  "up-right": ["solid", "regular"],
  excavator: ["solid", "regular"],
  "trophy-star": ["solid", "regular"],
  "apple-whole": ["solid", "regular"],
  "hammer-war": ["solid", "regular"],
  "arrow-up-1-9": ["solid", "regular"],
  alien: ["solid", "regular"],
  "file-code": ["solid", "regular"],
  "golf-club": ["solid", "regular"],
  "door-open": ["solid", "regular"],
  "barcode-scan": ["solid", "regular"],
  "face-kiss-beam": ["solid", "regular"],
  "user-doctor": ["solid", "regular"],
  "podium-star": ["solid", "regular"],
  "mars-stroke": ["solid", "regular"],
  "audio-description-slash": ["solid", "regular"],
  barcode: ["solid", "regular"],
  "not-equal": ["solid", "regular"],
  signal: ["solid", "regular"],
  "seal-question": ["solid", "regular"],
  "grill-hot": ["solid", "regular"],
  "face-smile-horns": ["solid", "regular"],
  "block-quote": ["solid", "regular"],
  "basketball-hoop": ["solid", "regular"],
  "user-police-tie": ["solid", "regular"],
  "table-layout": ["solid", "regular"],
  bomb: ["solid", "regular"],
  "circle-info": ["solid", "regular"],
  "ballot-check": ["solid", "regular"],
  crop: ["solid", "regular"],
  "cake-slice": ["solid", "regular"],
  "can-food": ["solid", "regular"],
  "diagram-successor": ["solid", "regular"],
  "hourglass-clock": ["solid", "regular"],
  "bowl-chopsticks": ["solid", "regular"],
  subscript: ["solid", "regular"],
  "location-crosshairs": ["solid", "regular"],
  "circle-phone-hangup": ["solid", "regular"],
  "tower-cell": ["solid", "regular"],
  maximize: ["solid", "regular"],
  "chalkboard-user": ["solid", "regular"],
  angel: ["solid", "regular"],
  pinata: ["solid", "regular"],
  "image-portrait": ["solid", "regular"],
  "note-medical": ["solid", "regular"],
  "arrow-up-9-1": ["solid", "regular"],
  "square-question": ["solid", "regular"],
  "person-cane": ["solid", "regular"],
  "road-circle-exclamation": ["solid", "regular"],
  "square-p": ["solid", "regular"],
  "mortar-pestle": ["solid", "regular"],
  "align-justify": ["solid", "regular"],
  "amp-guitar": ["solid", "regular"],
  "square-ring": ["solid", "regular"],
  "face-hand-yawn": ["solid", "regular"],
  "person-burst": ["solid", "regular"],
  "hashtag-lock": ["solid", "regular"],
  "bridge-circle-xmark": ["solid", "regular"],
  "location-dot-slash": ["solid", "regular"],
  "cup-straw": ["solid", "regular"],
  "file-mp3": ["solid", "regular"],
  "clock-two-thirty": ["solid", "regular"],
  "tv-retro": ["solid", "regular"],
  "mouse-field": ["solid", "regular"],
  vest: ["solid", "regular"],
  "link-horizontal": ["solid", "regular"],
  "user-nurse-hair-long": ["solid", "regular"],
  "border-top-left": ["solid", "regular"],
  "clipboard-check": ["solid", "regular"],
  "face-beam-hand-over-mouth": ["solid", "regular"],
  "grip-lines": ["solid", "regular"],
  "cat-space": ["solid", "regular"],
  "chess-rook-piece": ["solid", "regular"],
  "right-to-line": ["solid", "regular"],
  "slash-forward": ["solid", "regular"],
  "plane-circle-check": ["solid", "regular"],
  peach: ["solid", "regular"],
  merge: ["solid", "regular"],
  rotate: ["solid", "regular"],
  "chevrons-right": ["solid", "regular"],
  "person-walking-arrow-loop-left": ["solid", "regular"],
  "arrow-left-from-arc": ["solid", "regular"],
  "slot-machine": ["solid", "regular"],
  "bring-forward": ["solid", "regular"],
  microwave: ["solid", "regular"],
  "arrows-turn-right": ["solid", "regular"],
  "square-info": ["solid", "regular"],
  "user-large-slash": ["solid", "regular"],
  "head-side-gear": ["solid", "regular"],
  "quote-right": ["solid", "regular"],
  sportsball: ["solid", "regular"],
  "vacuum-robot": ["solid", "regular"],
  "id-card-clip": ["solid", "regular"],
  "hand-back-point-right": ["solid", "regular"],
  "mars-and-venus-burst": ["solid", "regular"],
  "baby-carriage": ["solid", "regular"],
  "text-height": ["solid", "regular"],
  pen: ["solid", "regular"],
  "arrow-turn-down": ["solid", "regular"],
  sprinkler: ["solid", "regular"],
  "message-code": ["solid", "regular"],
  shredder: ["solid", "regular"],
  print: ["solid", "regular"],
  database: ["solid", "regular"],
  khanda: ["solid", "regular"],
  shutters: ["solid", "regular"],
  "truck-medical": ["solid", "regular"],
  "face-frown": ["solid", "regular"],
  "envelope-open-dollar": ["solid", "regular"],
  "gauge-simple-min": ["solid", "regular"],
  "arrows-repeat": ["solid", "regular"],
  "kiwi-bird": ["solid", "regular"],
  "floppy-disk-circle-arrow-right": ["solid", "regular"],
  "cloud-music": ["solid", "regular"],
  cards: ["solid", "regular"],
  splotch: ["solid", "regular"],
  cross: ["solid", "regular"],
  "square-list": ["solid", "regular"],
  escalator: ["solid", "regular"],
  "shelves-empty": ["solid", "regular"],
  "house-tsunami": ["solid", "regular"],
  "magnifying-glass-waveform": ["solid", "regular"],
  "charging-station": ["solid", "regular"],
  desktop: ["solid", "regular"],
  corn: ["solid", "regular"],
  command: ["solid", "regular"],
  "link-simple": ["solid", "regular"],
  "volume-low": ["solid", "regular"],
  "wine-bottle": ["solid", "regular"],
  fort: ["solid", "regular"],
  "file-dashed-line": ["solid", "regular"],
  "grid-horizontal": ["solid", "regular"],
  x: ["solid", "regular"],
  "location-plus": ["solid", "regular"],
  "house-laptop": ["solid", "regular"],
  icons: ["solid", "regular"],
  pegasus: ["solid", "regular"],
  "shekel-sign": ["solid", "regular"],
  "face-raised-eyebrow": ["solid", "regular"],
  "virus-slash": ["solid", "regular"],
  "file-circle-info": ["solid", "regular"],
  "badger-honey": ["solid", "regular"],
  "ski-boot": ["solid", "regular"],
  sparkle: ["solid", "regular"],
  rhombus: ["solid", "regular"],
  "border-outer": ["solid", "regular"],
  pinball: ["solid", "regular"],
  "bridge-circle-check": ["solid", "regular"],
  paw: ["solid", "regular"],
  "vector-polygon": ["solid", "regular"],
  oven: ["solid", "regular"],
  dog: ["solid", "regular"],
  "arrow-right-arrow-left": ["solid", "regular"],
  fingerprint: ["solid", "regular"],
  "hand-point-right": ["solid", "regular"],
  "file-export": ["solid", "regular"],
  fan: ["solid", "regular"],
  cowbell: ["solid", "regular"],
  "comment-dollar": ["solid", "regular"],
  "circle-sterling": ["solid", "regular"],
  corner: ["solid", "regular"],
  "arrow-left-to-bracket": ["solid", "regular"],
  "window-frame-open": ["solid", "regular"],
  eggplant: ["solid", "regular"],
  "cart-circle-plus": ["solid", "regular"],
  "square-terminal": ["solid", "regular"],
  "folder-bookmark": ["solid", "regular"],
  "user-crown": ["solid", "regular"],
  "burger-lettuce": ["solid", "regular"],
  "building-columns": ["solid", "regular"],
  "umbrella-simple": ["solid", "regular"],
  "circle-ellipsis": ["solid", "regular"],
  "arrow-right": ["solid", "regular"],
  "user-shield": ["solid", "regular"],
  "comment-exclamation": ["solid", "regular"],
  infinity: ["solid", "regular"],
  "circle-arrow-up": ["solid", "regular"],
  "cable-car": ["solid", "regular"],
  "transporter-1": ["solid", "regular"],
  "game-board-simple": ["solid", "regular"],
  wrench: ["solid", "regular"],
  "face-holding-back-tears": ["solid", "regular"],
  "bags-shopping": ["solid", "regular"],
  "cabinet-filing": ["solid", "regular"],
  "file-chart-column": ["solid", "regular"],
  glasses: ["solid", "regular"],
  wheat: ["solid", "regular"],
  balloons: ["solid", "regular"],
  "cricket-bat-ball": ["solid", "regular"],
  "music-slash": ["solid", "regular"],
  "clock-one-thirty": ["solid", "regular"],
  podcast: ["solid", "regular"],
  "briefcase-arrow-right": ["solid", "regular"],
  "file-csv": ["solid", "regular"],
  "camera-rotate": ["solid", "regular"],
  "venus-mars": ["solid", "regular"],
  "plane-tail": ["solid", "regular"],
  grid: ["solid", "regular"],
  "binary-slash": ["solid", "regular"],
  "message-image": ["solid", "regular"],
  "gear-complex-code": ["solid", "regular"],
  "heart-half": ["solid", "regular"],
  "gear-complex": ["solid", "regular"],
  octagon: ["solid", "regular"],
  "file-minus": ["solid", "regular"],
  "down-to-bracket": ["solid", "regular"],
  "chevron-up": ["solid", "regular"],
  "book-font": ["solid", "regular"],
  "ruler-horizontal": ["solid", "regular"],
  "prescription-bottle-medical": ["solid", "regular"],
  burst: ["solid", "regular"],
  "align-slash": ["solid", "regular"],
  "arrow-left-long": ["solid", "regular"],
  z: ["solid", "regular"],
  "wand-sparkles": ["solid", "regular"],
  "sprinkler-ceiling": ["solid", "regular"],
  "nfc-magnifying-glass": ["solid", "regular"],
  "circle-k": ["solid", "regular"],
  "credit-card": ["solid", "regular"],
  fish: ["solid", "regular"],
  "ski-boot-ski": ["solid", "regular"],
  "user-helmet-safety": ["solid", "regular"],
  m: ["solid", "regular"],
  "up-from-bracket": ["solid", "regular"],
  "arrow-left-from-bracket": ["solid", "regular"],
  "lock-keyhole": ["solid", "regular"],
  "mask-snorkel": ["solid", "regular"],
  lollipop: ["solid", "regular"],
  "droplet-slash": ["solid", "regular"],
  megaphone: ["solid", "regular"],
  "face-downcast-sweat": ["solid", "regular"],
  "message-arrow-up-right": ["solid", "regular"],
  "building-circle-arrow-right": ["solid", "regular"],
  clover: ["solid", "regular"],
  "octagon-divide": ["solid", "regular"],
  "calendar-heart": ["solid", "regular"],
  "angle-left": ["solid", "regular"],
  "arrow-down-arrow-up": ["solid", "regular"],
  "wave-sine": ["solid", "regular"],
  "candy-corn": ["solid", "regular"],
  strikethrough: ["solid", "regular"],
  "up-to-dotted-line": ["solid", "regular"],
  scythe: ["solid", "regular"],
  "rabbit-running": ["solid", "regular"],
  h4: ["solid", "regular"],
  tag: ["solid", "regular"],
  "user-astronaut": ["solid", "regular"],
  "sterling-sign": ["solid", "regular"],
  "address-card": ["solid", "regular"],
  overline: ["solid", "regular"],
  "earth-oceania": ["solid", "regular"],
  "light-ceiling": ["solid", "regular"],
  thumbtack: ["solid", "regular"],
  italic: ["solid", "regular"],
  "chess-board": ["solid", "regular"],
  "circle-half": ["solid", "regular"],
  "skull-crossbones": ["solid", "regular"],
  "subtitles-slash": ["solid", "regular"],
  "face-smile-halo": ["solid", "regular"],
  "chess-knight-piece": ["solid", "regular"],
  "face-awesome": ["solid", "regular"],
  "trowel-bricks": ["solid", "regular"],
  "comment-captions": ["solid", "regular"],
  "square-ampersand": ["solid", "regular"],
  "hand-point-up": ["solid", "regular"],
  "key-skeleton": ["solid", "regular"],
  "face-head-bandage": ["solid", "regular"],
  retweet: ["solid", "regular"],
  "brightness-low": ["solid", "regular"],
  "octagon-exclamation": ["solid", "regular"],
  "hat-witch": ["solid", "regular"],
  "fan-table": ["solid", "regular"],
  "list-tree": ["solid", "regular"],
  "mp3-player": ["solid", "regular"],
  "mattress-pillow": ["solid", "regular"],
  "mobile-signal-out": ["solid", "regular"],
  "prescription-bottle": ["solid", "regular"],
  "camera-web": ["solid", "regular"],
  "nfc-signal": ["solid", "regular"],
  coins: ["solid", "regular"],
  "baseball-bat-ball": ["solid", "regular"],
  "location-check": ["solid", "regular"],
  dragon: ["solid", "regular"],
  "columns-3": ["solid", "regular"],
  "folder-minus": ["solid", "regular"],
  "diagram-nested": ["solid", "regular"],
  "clipboard-question": ["solid", "regular"],
  clarinet: ["solid", "regular"],
  registered: ["solid", "regular"],
  "calendar-check": ["solid", "regular"],
  "rectangle-code": ["solid", "regular"],
  "oil-well": ["solid", "regular"],
  "dice-d6": ["solid", "regular"],
  "arrows-from-line": ["solid", "regular"],
  "sun-haze": ["solid", "regular"],
  handshake: ["solid", "regular"],
  archway: ["solid", "regular"],
  "conveyor-belt": ["solid", "regular"],
  "plane-departure": ["solid", "regular"],
  headphones: ["solid", "regular"],
  "music-note-slash": ["solid", "regular"],
  "message-smile": ["solid", "regular"],
  "window-minimize": ["solid", "regular"],
  "arrows-to-dotted-line": ["solid", "regular"],
  "square-quote": ["solid", "regular"],
  "accent-grave": ["solid", "regular"],
  "hexagon-exclamation": ["solid", "regular"],
  "laptop-mobile": ["solid", "regular"],
  "signal-bars-fair": ["solid", "regular"],
  "pump-soap": ["solid", "regular"],
  "poll-people": ["solid", "regular"],
  "hand-peace": ["solid", "regular"],
  "shield-dog": ["solid", "regular"],
  "person-walking-luggage": ["solid", "regular"],
  computer: ["solid", "regular"],
  "file-circle-plus": ["solid", "regular"],
  "clock-eight": ["solid", "regular"],
  "prescription-bottle-pill": ["solid", "regular"],
  "gauge-circle-bolt": ["solid", "regular"],
  "rotate-right": ["solid", "regular"],
  "location-smile": ["solid", "regular"],
  "person-military-to-person": ["solid", "regular"],
  "hand-holding-dollar": ["solid", "regular"],
  "handshake-simple-slash": ["solid", "regular"],
  "money-bill-wheat": ["solid", "regular"],
  newspaper: ["solid", "regular"],
  r: ["solid", "regular"],
  "money-bill-trend-up": ["solid", "regular"],
  ufo: ["solid", "regular"],
  lock: ["solid", "regular"],
  "arrow-right-to-bracket": ["solid", "regular"],
  eclipse: ["solid", "regular"],
  "reply-all": ["solid", "regular"],
  "circle-down-left": ["solid", "regular"],
  typewriter: ["solid", "regular"],
  slash: ["solid", "regular"],
  scarf: ["solid", "regular"],
  "hat-santa": ["solid", "regular"],
  narwhal: ["solid", "regular"],
  "phone-slash": ["solid", "regular"],
  "square-e": ["solid", "regular"],
  "square-6": ["solid", "regular"],
  bugs: ["solid", "regular"],
  "album-circle-plus": ["solid", "regular"],
  puzzle: ["solid", "regular"],
  "hourglass-end": ["solid", "regular"],
  "share-nodes": ["solid", "regular"],
  "calendar-arrow-down": ["solid", "regular"],
  "circle-8": ["solid", "regular"],
  q: ["solid", "regular"],
  "ramp-loading": ["solid", "regular"],
  badminton: ["solid", "regular"],
  "user-music": ["solid", "regular"],
  "champagne-glass": ["solid", "regular"],
  wave: ["solid", "regular"],
  "earth-americas": ["solid", "regular"],
  sushi: ["solid", "regular"],
  keynote: ["solid", "regular"],
  hashtag: ["solid", "regular"],
  "staff-snake": ["solid", "regular"],
  "battery-low": ["solid", "regular"],
  "presentation-screen": ["solid", "regular"],
  "chess-king": ["solid", "regular"],
  "caravan-simple": ["solid", "regular"],
  "circle-video": ["solid", "regular"],
  "circle-a": ["solid", "regular"],
  "link-slash": ["solid", "regular"],
  "caret-down": ["solid", "regular"],
  bee: ["solid", "regular"],
  "cloud-moon": ["solid", "regular"],
  "feather-pointed": ["solid", "regular"],
  "face-grin-tongue-squint": ["solid", "regular"],
  "text-size": ["solid", "regular"],
  folder: ["solid", "regular"],
  "pen-clip": ["solid", "regular"],
  kazoo: ["solid", "regular"],
  "face-glasses": ["solid", "regular"],
  "phone-arrow-right": ["solid", "regular"],
  "cubes-stacked": ["solid", "regular"],
  timeline: ["solid", "regular"],
  "person-sledding": ["solid", "regular"],
  "image-polaroid-user": ["solid", "regular"],
  split: ["solid", "regular"],
  "brackets-square": ["solid", "regular"],
  "paw-simple": ["solid", "regular"],
  "calendar-arrow-up": ["solid", "regular"],
  tornado: ["solid", "regular"],
  "building-circle-check": ["solid", "regular"],
  "message-dollar": ["solid", "regular"],
  "hand-holding-box": ["solid", "regular"],
  "transporter-empty": ["solid", "regular"],
  "kip-sign": ["solid", "regular"],
  "alien-8bit": ["solid", "regular"],
  "magnifying-glass-arrow-right": ["solid", "regular"],
  "toilet-paper": ["solid", "regular"],
  "user-lock": ["solid", "regular"],
  "film-canister": ["solid", "regular"],
  "arrow-up-big-small": ["solid", "regular"],
  backward: ["solid", "regular"],
  tree: ["solid", "regular"],
  "eye-evil": ["solid", "regular"],
  download: ["solid", "regular"],
  "hexagon-check": ["solid", "regular"],
  "transporter-4": ["solid", "regular"],
  "window-restore": ["solid", "regular"],
  "card-club": ["solid", "regular"],
  "ban-bug": ["solid", "regular"],
  "dial-med-low": ["solid", "regular"],
  "masks-theater": ["solid", "regular"],
  "sort-up": ["solid", "regular"],
  highlighter: ["solid", "regular"],
  "tree-decorated": ["solid", "regular"],
  "ellipsis-stroke": ["solid", "regular"],
  teeth: ["solid", "regular"],
  "signature-slash": ["solid", "regular"],
  "circle-left": ["solid", "regular"],
  "comment-arrow-up-right": ["solid", "regular"],
  dolphin: ["solid", "regular"],
  "chart-area": ["solid", "regular"],
  "angles-right": ["solid", "regular"],
  "eye-dropper-half": ["solid", "regular"],
  "alarm-clock": ["solid", "regular"],
  "cloud-rain": ["solid", "regular"],
  "circle-arrow-down-left": ["solid", "regular"],
  "keyboard-brightness": ["solid", "regular"],
  vacuum: ["solid", "regular"],
  "mug-tea": ["solid", "regular"],
  "grid-round-2": ["solid", "regular"],
  "hands-holding": ["solid", "regular"],
  skull: ["solid", "regular"],
  "face-viewfinder": ["solid", "regular"],
  "arrows-split-up-and-left": ["solid", "regular"],
  "transporter-3": ["solid", "regular"],
  "rotate-left": ["solid", "regular"],
  "images-user": ["solid", "regular"],
  "face-angry": ["solid", "regular"],
  "house-user": ["solid", "regular"],
  "pipe-smoking": ["solid", "regular"],
  "house-flag": ["solid", "regular"],
  "display-slash": ["solid", "regular"],
  "face-tongue-money": ["solid", "regular"],
  "person-circle-question": ["solid", "regular"],
  "coin-vertical": ["solid", "regular"],
  "credit-card-front": ["solid", "regular"],
  "star-sharp": ["solid", "regular"],
  "circle-half-stroke": ["solid", "regular"],
  "check-to-slot": ["solid", "regular"],
  "file-circle-question": ["solid", "regular"],
  "chevrons-up": ["solid", "regular"],
  "clipboard-user": ["solid", "regular"],
  axe: ["solid", "regular"],
  "brake-warning": ["solid", "regular"],
  "shield-keyhole": ["solid", "regular"],
  "flag-checkered": ["solid", "regular"],
  "money-check-pen": ["solid", "regular"],
  "person-skating": ["solid", "regular"],
  "trash-can-xmark": ["solid", "regular"],
  "trash-xmark": ["solid", "regular"],
  crab: ["solid", "regular"],
  "hands-holding-diamond": ["solid", "regular"],
  grip: ["solid", "regular"],
  snowflakes: ["solid", "regular"],
  "camera-viewfinder": ["solid", "regular"],
  "arrow-down-from-dotted-line": ["solid", "regular"],
  "paper-plane-top": ["solid", "regular"],
  "chart-user": ["solid", "regular"],
  badge: ["solid", "regular"],
  option: ["solid", "regular"],
  nfc: ["solid", "regular"],
  "shield-check": ["solid", "regular"],
  bold: ["solid", "regular"],
  "square-root": ["solid", "regular"],
  "square-arrow-down-left": ["solid", "regular"],
  "square-up-left": ["solid", "regular"],
  "pump-medical": ["solid", "regular"],
  "cassette-tape": ["solid", "regular"],
  "comment-image": ["solid", "regular"],
  "arrow-down-wide-short": ["solid", "regular"],
  "comment-check": ["solid", "regular"],
  "card-diamond": ["solid", "regular"],
  "bridge-water": ["solid", "regular"],
  "flower-tulip": ["solid", "regular"],
  up: ["solid", "regular"],
  cookie: ["solid", "regular"],
  "box-dollar": ["solid", "regular"],
  "message-minus": ["solid", "regular"],
  popcorn: ["solid", "regular"],
  coffin: ["solid", "regular"],
  "calendar-day": ["solid", "regular"],
  "clouds-moon": ["solid", "regular"],
  "circle-trash": ["solid", "regular"],
  hyphen: ["solid", "regular"],
  "wand-magic-sparkles": ["solid", "regular"],
  "chart-simple": ["solid", "regular"],
  stroopwafel: ["solid", "regular"],
  crown: ["solid", "regular"],
  "dice-six": ["solid", "regular"],
  cabin: ["solid", "regular"],
  "grid-2-plus": ["solid", "regular"],
  "face-grin-stars": ["solid", "regular"],
  watch: ["solid", "regular"],
  "comment-question": ["solid", "regular"],
  mushroom: ["solid", "regular"],
  tractor: ["solid", "regular"],
  "bell-ring": ["solid", "regular"],
  "circle-t": ["solid", "regular"],
  "users-slash": ["solid", "regular"],
  "tally-4": ["solid", "regular"],
  football: ["solid", "regular"],
  sunset: ["solid", "regular"],
  "square-minus": ["solid", "regular"],
  "microphone-lines-slash": ["solid", "regular"],
  plug: ["solid", "regular"],
  "location-arrow": ["solid", "regular"],
  subtitles: ["solid", "regular"],
  "glass-citrus": ["solid", "regular"],
  "building-flag": ["solid", "regular"],
  "wheat-awn-circle-exclamation": ["solid", "regular"],
  route: ["solid", "regular"],
  "arrows-up-to-line": ["solid", "regular"],
  "crystal-ball": ["solid", "regular"],
  "circle-small": ["solid", "regular"],
  "trash-undo": ["solid", "regular"],
  ditto: ["solid", "regular"],
  "360-degrees": ["solid", "regular"],
  pompebled: ["solid", "regular"],
  rabbit: ["solid", "regular"],
  "planet-ringed": ["solid", "regular"],
  "left-long": ["solid", "regular"],
  "link-horizontal-slash": ["solid", "regular"],
  "face-drooling": ["solid", "regular"],
  "square-down": ["solid", "regular"],
  bucket: ["solid", "regular"],
  "face-persevering": ["solid", "regular"],
  "book-arrow-up": ["solid", "regular"],
  upload: ["solid", "regular"],
  "diagram-venn": ["solid", "regular"],
  "grid-round-2-plus": ["solid", "regular"],
  "money-bill-simple-wave": ["solid", "regular"],
  "trash-can-plus": ["solid", "regular"],
  "bell-on": ["solid", "regular"],
  "arrow-down": ["solid", "regular"],
  "plane-circle-exclamation": ["solid", "regular"],
  pancakes: ["solid", "regular"],
  "square-s": ["solid", "regular"],
  "ruble-sign": ["solid", "regular"],
  "chess-queen": ["solid", "regular"],
  "wifi-weak": ["solid", "regular"],
  "rocket-launch": ["solid", "regular"],
  "tire-rugged": ["solid", "regular"],
  "music-magnifying-glass": ["solid", "regular"],
  "clock-seven-thirty": ["solid", "regular"],
  "bottle-droplet": ["solid", "regular"],
  "scanner-keyboard": ["solid", "regular"],
  "hat-cowboy": ["solid", "regular"],
  peapod: ["solid", "regular"],
  "roller-coaster": ["solid", "regular"],
  airplay: ["solid", "regular"],
  "page-caret-up": ["solid", "regular"],
  "tent-arrow-down-to-line": ["solid", "regular"],
  "rectangle-vertical-history": ["solid", "regular"],
  burger: ["solid", "regular"],
  "gauge-simple-high": ["solid", "regular"],
  "arrow-up-long": ["solid", "regular"],
  "pen-nib-slash": ["solid", "regular"],
  "book-open": ["solid", "regular"],
  "clipboard-prescription": ["solid", "regular"],
  "users-rays": ["solid", "regular"],
  "phone-volume": ["solid", "regular"],
  "cart-flatbed-boxes": ["solid", "regular"],
  "folder-arrow-up": ["solid", "regular"],
  "compact-disc": ["solid", "regular"],
  "handshake-angle": ["solid", "regular"],
  "file-pen": ["solid", "regular"],
  "mobile-notch": ["solid", "regular"],
  angle: ["solid", "regular"],
  "floppy-disk-pen": ["solid", "regular"],
  "utensils-slash": ["solid", "regular"],
  lungs: ["solid", "regular"],
  "flag-swallowtail": ["solid", "regular"],
  "trash-can-undo": ["solid", "regular"],
  "plug-circle-plus": ["solid", "regular"],
  award: ["solid", "regular"],
  umbrella: ["solid", "regular"],
  bulldozer: ["solid", "regular"],
  cloud: ["solid", "regular"],
  "square-n": ["solid", "regular"],
  "chart-tree-map": ["solid", "regular"],
  "rotate-reverse": ["solid", "regular"],
  "circle-minus": ["solid", "regular"],
  "calendar-circle-minus": ["solid", "regular"],
  "road-lock": ["solid", "regular"],
  "cart-circle-arrow-down": ["solid", "regular"],
  "user-vneck": ["solid", "regular"],
  seal: ["solid", "regular"],
  "transformer-bolt": ["solid", "regular"],
  "square-5": ["solid", "regular"],
  dumpster: ["solid", "regular"],
  "circle-b": ["solid", "regular"],
  "square-phone-flip": ["solid", "regular"],
  "square-caret-right": ["solid", "regular"],
  "fire-flame-simple": ["solid", "regular"],
  "circle-exclamation": ["solid", "regular"],
  "shirt-tank-top": ["solid", "regular"],
  "arrow-left-to-arc": ["solid", "regular"],
  "map-location": ["solid", "regular"],
  "grid-2": ["solid", "regular"],
  "sushi-roll": ["solid", "regular"],
  "arrow-trend-up": ["solid", "regular"],
  guitar: ["solid", "regular"],
  vial: ["solid", "regular"],
  "cart-circle-exclamation": ["solid", "regular"],
  "diagram-previous": ["solid", "regular"],
  "people-robbery": ["solid", "regular"],
  "sun-dust": ["solid", "regular"],
  wheelchair: ["solid", "regular"],
  "bowl-hot": ["solid", "regular"],
  moped: ["solid", "regular"],
  "bag-shopping-minus": ["solid", "regular"],
  radio: ["solid", "regular"],
  "hexagon-image": ["solid", "regular"],
  "up-to-line": ["solid", "regular"],
  salad: ["solid", "regular"],
  "box-archive": ["solid", "regular"],
  "dial-high": ["solid", "regular"],
  "blender-phone": ["solid", "regular"],
  ruler: ["solid", "regular"],
  sliders: ["solid", "regular"],
  "bowl-chopsticks-noodles": ["solid", "regular"],
  guitars: ["solid", "regular"],
  "box-taped": ["solid", "regular"],
  "shirt-long-sleeve": ["solid", "regular"],
  "face-grin": ["solid", "regular"],
  "filter-circle-dollar": ["solid", "regular"],
  "angle-down": ["solid", "regular"],
  "square-chevron-right": ["solid", "regular"],
  store: ["solid", "regular"],
  "camera-slash": ["solid", "regular"],
  "user-alien": ["solid", "regular"],
  "square-8": ["solid", "regular"],
  films: ["solid", "regular"],
  "square-xmark": ["solid", "regular"],
  "do-not-enter": ["solid", "regular"],
  "album-circle-user": ["solid", "regular"],
  "align-left": ["solid", "regular"],
  "bowling-ball-pin": ["solid", "regular"],
  "bone-break": ["solid", "regular"],
  "play-pause": ["solid", "regular"],
  "square-nfi": ["solid", "regular"],
  percent: ["solid", "regular"],
  "circle-camera": ["solid", "regular"],
  "hexagon-xmark": ["solid", "regular"],
  "square-down-left": ["solid", "regular"],
  "arrow-down-to-dotted-line": ["solid", "regular"],
  tablets: ["solid", "regular"],
  "objects-align-left": ["solid", "regular"],
  egg: ["solid", "regular"],
  "arrows-from-dotted-line": ["solid", "regular"],
  "face-frown-slight": ["solid", "regular"],
  "house-night": ["solid", "regular"],
  "train-track": ["solid", "regular"],
  "comment-music": ["solid", "regular"],
  "heart-circle-check": ["solid", "regular"],
  "hand-sparkles": ["solid", "regular"],
  buoy: ["solid", "regular"],
  "dice-d4": ["solid", "regular"],
  toilet: ["solid", "regular"],
  "glass-water-droplet": ["solid", "regular"],
  "print-slash": ["solid", "regular"],
  "signal-bars-slash": ["solid", "regular"],
  "lacrosse-stick": ["solid", "regular"],
  "expand-wide": ["solid", "regular"],
  "money-bills-simple": ["solid", "regular"],
  "briefcase-blank": ["solid", "regular"],
  "fire-hydrant": ["solid", "regular"],
  block: ["solid", "regular"],
  "down-to-line": ["solid", "regular"],
  "toilet-paper-xmark": ["solid", "regular"],
  "square-envelope": ["solid", "regular"],
  "arrow-trend-down": ["solid", "regular"],
  "clock-twelve": ["solid", "regular"],
  microphone: ["solid", "regular"],
  "mobile-retro": ["solid", "regular"],
  "building-lock": ["solid", "regular"],
  "font-awesome": ["solid", "brands", "regular"],
  "nfc-symbol": ["solid", "brands", "regular"],
  campfire: ["solid", "regular"],
  "rectangle-terminal": ["solid", "regular"],
  "face-sad-sweat": ["solid", "regular"],
  "arrows-to-line": ["solid", "regular"],
  "object-exclude": ["solid", "regular"],
  "chart-kanban": ["solid", "regular"],
  "calendar-circle-plus": ["solid", "regular"],
  "ruler-combined": ["solid", "regular"],
  "arrow-up-to-bracket": ["solid", "regular"],
  "user-check": ["solid", "regular"],
  bell: ["solid", "regular"],
  film: ["solid", "regular"],
  w: ["solid", "regular"],
  joint: ["solid", "regular"],
  "water-arrow-up": ["solid", "regular"],
  "waveform-lines": ["solid", "regular"],
  "people-arrows": ["solid", "regular"],
  baseball: ["solid", "regular"],
  "candy-bar": ["solid", "regular"],
  "arrow-right-to-line": ["solid", "regular"],
  "circles-overlap": ["solid", "regular"],
  "comment-medical": ["solid", "regular"],
  "circle-user": ["solid", "regular"],
  "volume-slash": ["solid", "regular"],
  "siren-on": ["solid", "regular"],
  explosion: ["solid", "regular"],
  "hand-holding": ["solid", "regular"],
  "paperclip-vertical": ["solid", "regular"],
  paintbrush: ["solid", "regular"],
  "eye-slash": ["solid", "regular"],
  "calendar-week": ["solid", "regular"],
  "tally-1": ["solid", "regular"],
  walker: ["solid", "regular"],
  calculator: ["solid", "regular"],
  "toilet-paper-slash": ["solid", "regular"],
  "ice-cream": ["solid", "regular"],
  wand: ["solid", "regular"],
  "list-check": ["solid", "regular"],
  o: ["solid", "regular"],
  "lira-sign": ["solid", "regular"],
  "plug-circle-bolt": ["solid", "regular"],
  pear: ["solid", "regular"],
  pickleball: ["solid", "regular"],
  "gauge-simple-low": ["solid", "regular"],
  "user-hair-mullet": ["solid", "regular"],
  "shield-heart": ["solid", "regular"],
  "badge-check": ["solid", "regular"],
  "head-side-heart": ["solid", "regular"],
  paperclip: ["solid", "regular"],
  "arrow-down-to-bracket": ["solid", "regular"],
  raindrops: ["solid", "regular"],
  balloon: ["solid", "regular"],
  volleyball: ["solid", "regular"],
  "face-exhaling": ["solid", "regular"],
  "face-smile-tongue": ["solid", "regular"],
  mercury: ["solid", "regular"],
  "dial-med": ["solid", "regular"],
  "drum-steelpan": ["solid", "regular"],
  "align-right": ["solid", "regular"],
  "hand-back-point-ribbon": ["solid", "regular"],
  "border-none": ["solid", "regular"],
  "face-woozy": ["solid", "regular"],
  blanket: ["solid", "regular"],
  calendar: ["solid", "regular"],
  bacteria: ["solid", "regular"],
  "code-simple": ["solid", "regular"],
  "circle-s": ["solid", "regular"],
  "hand-holding-medical": ["solid", "regular"],
  "rectangle-wide": ["solid", "regular"],
  "message-arrow-down": ["solid", "regular"],
  "hand-heart": ["solid", "regular"],
  "battery-exclamation": ["solid", "regular"],
  "location-pin-lock": ["solid", "regular"],
  "hand-point-ribbon": ["solid", "regular"],
  "square-kanban": ["solid", "regular"],
  fence: ["solid", "regular"],
  "scalpel-line-dashed": ["solid", "regular"],
  "folder-user": ["solid", "regular"],
  snowplow: ["solid", "regular"],
  "shield-virus": ["solid", "regular"],
  divide: ["solid", "regular"],
  "distribute-spacing-vertical": ["solid", "regular"],
  "hands-holding-circle": ["solid", "regular"],
  "arrows-retweet": ["solid", "regular"],
  "arrow-up-triangle-square": ["solid", "regular"],
  "temperature-low": ["solid", "regular"],
  cannon: ["solid", "regular"],
  "head-side-cough-slash": ["solid", "regular"],
  mitten: ["solid", "regular"],
  "dice-three": ["solid", "regular"],
  microchip: ["solid", "regular"],
  "empty-set": ["solid", "regular"],
  "beer-mug": ["solid", "regular"],
  "circle-j": ["solid", "regular"],
  "lightbulb-cfl-on": ["solid", "regular"],
  d: ["solid", "regular"],
  "standard-definition": ["solid", "regular"],
  stairs: ["solid", "regular"],
  "scroll-old": ["solid", "regular"],
  recycle: ["solid", "regular"],
  "image-user": ["solid", "regular"],
  "user-tie": ["solid", "regular"],
  "book-skull": ["solid", "regular"],
  "person-dolly-empty": ["solid", "regular"],
  "temperature-arrow-up": ["solid", "regular"],
  "house-fire": ["solid", "regular"],
  "dice-d20": ["solid", "regular"],
  "table-columns": ["solid", "regular"],
  "left-long-to-line": ["solid", "regular"],
  shop: ["solid", "regular"],
  "clock-twelve-thirty": ["solid", "regular"],
  "wind-warning": ["solid", "regular"],
  "road-barrier": ["solid", "regular"],
  "box-circle-check": ["solid", "regular"],
  webhook: ["solid", "regular"],
  "gamepad-modern": ["solid", "regular"],
  "clapperboard-play": ["solid", "regular"],
  "message-check": ["solid", "regular"],
  "up-down-left-right": ["solid", "regular"],
  "thumbs-down": ["solid", "regular"],
  "window-maximize": ["solid", "regular"],
  "game-board": ["solid", "regular"],
  "square-chevron-down": ["solid", "regular"],
  radar: ["solid", "regular"],
  bars: ["solid", "regular"],
  "square-i": ["solid", "regular"],
  "alarm-plus": ["solid", "regular"],
  utensils: ["solid", "regular"],
  unicorn: ["solid", "regular"],
  "xmark-to-slot": ["solid", "regular"],
  bone: ["solid", "regular"],
  "conveyor-belt-empty": ["solid", "regular"],
  "box-open-full": ["solid", "regular"],
  "arrow-left-from-line": ["solid", "regular"],
  "fork-knife": ["solid", "regular"],
  ticket: ["solid", "regular"],
  "cent-sign": ["solid", "regular"],
  "hockey-puck": ["solid", "regular"],
  "circle-dollar-to-slot": ["solid", "regular"],
  "lock-hashtag": ["solid", "regular"],
  "file-circle-minus": ["solid", "regular"],
  "pipe-collar": ["solid", "regular"],
  brush: ["solid", "regular"],
  "person-snowboarding": ["solid", "regular"],
  "face-disappointed": ["solid", "regular"],
  screwdriver: ["solid", "regular"],
  "seat-airline": ["solid", "regular"],
  page: ["solid", "regular"],
  cupcake: ["solid", "regular"],
  "bolt-lightning": ["solid", "regular"],
  "circle-1": ["solid", "regular"],
  bridge: ["solid", "regular"],
  "car-rear": ["solid", "regular"],
  skeleton: ["solid", "regular"],
  "money-check": ["solid", "regular"],
  quotes: ["solid", "regular"],
  "angle-up": ["solid", "regular"],
  binary: ["solid", "regular"],
  "shield-slash": ["solid", "regular"],
  "box-open": ["solid", "regular"],
  "magnifying-glass-plus": ["solid", "regular"],
  "toggle-off": ["solid", "regular"],
  projector: ["solid", "regular"],
  "square-w": ["solid", "regular"],
  "video-plus": ["solid", "regular"],
  "grip-lines-vertical": ["solid", "regular"],
  smog: ["solid", "regular"],
  elevator: ["solid", "regular"],
  "arrow-up-wide-short": ["solid", "regular"],
  box: ["solid", "regular"],
  lasso: ["solid", "regular"],
  "scale-unbalanced": ["solid", "regular"],
  "business-time": ["solid", "regular"],
  "compass-drafting": ["solid", "regular"],
  "circle-ellipsis-vertical": ["solid", "regular"],
  u: ["solid", "regular"],
  aperture: ["solid", "regular"],
  "head-side-headphones": ["solid", "regular"],
  "french-fries": ["solid", "regular"],
  "court-sport": ["solid", "regular"],
  "square-pen": ["solid", "regular"],
  "tty-answer": ["solid", "regular"],
  starship: ["solid", "regular"],
  "face-vomit": ["solid", "regular"],
  "comment-xmark": ["solid", "regular"],
  "coin-front": ["solid", "regular"],
  "treasure-chest": ["solid", "regular"],
  smoke: ["solid", "regular"],
  "broom-wide": ["solid", "regular"],
  "hand-holding-hand": ["solid", "regular"],
  paste: ["solid", "regular"],
  right: ["solid", "regular"],
  "sensor-fire": ["solid", "regular"],
  gear: ["solid", "regular"],
  "battery-bolt": ["solid", "regular"],
  "person-walking-with-cane": ["solid", "regular"],
  message: ["solid", "regular"],
  menorah: ["solid", "regular"],
  "coffee-bean": ["solid", "regular"],
  "light-emergency": ["solid", "regular"],
  "toilet-paper-blank": ["solid", "regular"],
  "location-question": ["solid", "regular"],
  "hand-holding-magic": ["solid", "regular"],
  sidebar: ["solid", "regular"],
  "layer-plus": ["solid", "regular"],
  "i-cursor": ["solid", "regular"],
  "message-xmark": ["solid", "regular"],
  "hat-wizard": ["solid", "regular"],
  loader: ["solid", "regular"],
  scribble: ["solid", "regular"],
  "arrow-left-long-to-line": ["solid", "regular"],
  "faucet-drip": ["solid", "regular"],
  "bracket-round-right": ["solid", "regular"],
  "user-bounty-hunter": ["solid", "regular"],
  "rotate-exclamation": ["solid", "regular"],
  snake: ["solid", "regular"],
  "lightbulb-dollar": ["solid", "regular"],
  "file-mp4": ["solid", "regular"],
  ballot: ["solid", "regular"],
  "person-arrow-down-to-line": ["solid", "regular"],
  "memo-circle-info": ["solid", "regular"],
  equals: ["solid", "regular"],
  "coffee-pot": ["solid", "regular"],
  "left-to-bracket": ["solid", "regular"],
  "arrow-rotate-right": ["solid", "regular"],
  "truck-flatbed": ["solid", "regular"],
  "chart-mixed-up-circle-dollar": ["solid", "regular"],
  "tire-flat": ["solid", "regular"],
  microscope: ["solid", "regular"],
  "arrow-down-from-bracket": ["solid", "regular"],
  "dog-leashed": ["solid", "regular"],
  f: ["solid", "regular"],
  "calendar-circle-exclamation": ["solid", "regular"],
  "people-dress-simple": ["solid", "regular"],
  "money-check-dollar": ["solid", "regular"],
  minus: ["solid", "regular"],
  "lights-holiday": ["solid", "regular"],
  "person-simple": ["solid", "regular"],
  "user-hair-buns": ["solid", "regular"],
  "circle-gf": ["solid", "regular"],
  "brain-arrow-curved-right": ["solid", "regular"],
  "shield-minus": ["solid", "regular"],
  "circle-quarter": ["solid", "regular"],
  "train-subway-tunnel": ["solid", "regular"],
  "line-height": ["solid", "regular"],
  mosquito: ["solid", "regular"],
  tombstone: ["solid", "regular"],
  "chess-knight": ["solid", "regular"],
  "beer-mug-empty": ["solid", "regular"],
  bagel: ["solid", "regular"],
  "person-biking-mountain": ["solid", "regular"],
  "circle-arrow-right": ["solid", "regular"],
  "forward-fast": ["solid", "regular"],
  "face-kiss-wink-heart": ["solid", "regular"],
  "hands-clapping": ["solid", "regular"],
  "badge-dollar": ["solid", "regular"],
  "font-case": ["solid", "regular"],
  "chess-bishop": ["solid", "regular"],
  "tablet-screen": ["solid", "regular"],
  sparkles: ["solid", "regular"],
  "chess-bishop-piece": ["solid", "regular"],
  "light-switch-on": ["solid", "regular"],
  "file-eps": ["solid", "regular"],
  mound: ["solid", "regular"],
  "folder-medical": ["solid", "regular"],
  "trash-can-check": ["solid", "regular"],
  "virus-covid-slash": ["solid", "regular"],
  "cart-plus": ["solid", "regular"],
  "wine-glass": ["solid", "regular"],
  "cookie-bite": ["solid", "regular"],
  "moon-cloud": ["solid", "regular"],
  manhole: ["solid", "regular"],
  "sign-hanging": ["solid", "regular"],
  t: ["solid", "regular"],
  "user-plus": ["solid", "regular"],
  "face-nose-steam": ["solid", "regular"],
  "bin-bottles-recycle": ["solid", "regular"],
  "face-sad-tear": ["solid", "regular"],
  "trash-check": ["solid", "regular"],
  "rectangle-ad": ["solid", "regular"],
  "alarm-snooze": ["solid", "regular"],
  "angles-up-down": ["solid", "regular"],
  "arrow-down-right": ["solid", "regular"],
  "tent-double-peak": ["solid", "regular"],
  "arrow-right-from-bracket": ["solid", "regular"],
  thermometer: ["solid", "regular"],
  "cloud-question": ["solid", "regular"],
  moon: ["solid", "regular"],
  "up-left": ["solid", "regular"],
  "pan-frying": ["solid", "regular"],
  "square-rss": ["solid", "regular"],
  cars: ["solid", "regular"],
  "up-from-dotted-line": ["solid", "regular"],
  "cloud-bolt-moon": ["solid", "regular"],
  "calendar-lines": ["solid", "regular"],
  ring: ["solid", "regular"],
  "parachute-box": ["solid", "regular"],
  "list-timeline": ["solid", "regular"],
  "users-line": ["solid", "regular"],
  "utility-pole-double": ["solid", "regular"],
  "disc-drive": ["solid", "regular"],
  "film-simple": ["solid", "regular"],
  "comment-code": ["solid", "regular"],
  "person-arrow-up-from-line": ["solid", "regular"],
  "plane-engines": ["solid", "regular"],
  "turn-down": ["solid", "regular"],
  images: ["solid", "regular"],
  "garage-car": ["solid", "regular"],
  "watermelon-slice": ["solid", "regular"],
  "circle-y": ["solid", "regular"],
  bacon: ["solid", "regular"],
  "bitcoin-sign": ["solid", "regular"],
  "book-circle-arrow-up": ["solid", "regular"],
  "film-slash": ["solid", "regular"],
  "chevrons-left": ["solid", "regular"],
  joystick: ["solid", "regular"],
  "folder-plus": ["solid", "regular"],
  "circle-c": ["solid", "regular"],
  acorn: ["solid", "regular"],
  "square-g": ["solid", "regular"],
  "face-grimace": ["solid", "regular"],
  "file-import": ["solid", "regular"],
  "down-from-dotted-line": ["solid", "regular"],
  rocket: ["solid", "regular"],
  "pool-8-ball": ["solid", "regular"],
  "caret-right": ["solid", "regular"],
  "file-plus": ["solid", "regular"],
  "chart-waterfall": ["solid", "regular"],
  "rings-wedding": ["solid", "regular"],
  "turkish-lira-sign": ["solid", "regular"],
  "reflect-vertical": ["solid", "regular"],
  passport: ["solid", "regular"],
  "folder-closed": ["solid", "regular"],
  "phone-missed": ["solid", "regular"],
  "forward-step": ["solid", "regular"],
  asterisk: ["solid", "regular"],
  gauge: ["solid", "regular"],
  inhaler: ["solid", "regular"],
  "burger-soda": ["solid", "regular"],
  "arrow-up-a-z": ["solid", "regular"],
  "x-ray": ["solid", "regular"],
  question: ["solid", "regular"],
  "meter-fire": ["solid", "regular"],
  "bars-progress": ["solid", "regular"],
  hanukiah: ["solid", "regular"],
  frog: ["solid", "regular"],
  "teddy-bear": ["solid", "regular"],
  "mars-stroke-right": ["solid", "regular"],
  "square-left": ["solid", "regular"],
  lightbulb: ["solid", "regular"],
  "lightbulb-slash": ["solid", "regular"],
  "bridge-circle-exclamation": ["solid", "regular"],
  "circle-e": ["solid", "regular"],
  "house-window": ["solid", "regular"],
  ankh: ["solid", "regular"],
  "tower-observation": ["solid", "regular"],
  "arrow-turn-left": ["solid", "regular"],
  child: ["solid", "regular"],
  "arrows-turn-to-dots": ["solid", "regular"],
  "temperature-empty": ["solid", "regular"],
  "objects-align-center-horizontal": ["solid", "regular"],
  "face-melting": ["solid", "regular"],
  "truck-fire": ["solid", "regular"],
  cow: ["solid", "regular"],
  "code-branch": ["solid", "regular"],
  "house-turret": ["solid", "regular"],
  "tower-control": ["solid", "regular"],
  "rectangle-history-circle-user": ["solid", "regular"],
  "temperature-half": ["solid", "regular"],
  server: ["solid", "regular"],
  "helicopter-symbol": ["solid", "regular"],
  "burger-fries": ["solid", "regular"],
  "table-cells": ["solid", "regular"],
  mug: ["solid", "regular"],
  "bridge-lock": ["solid", "regular"],
  "trash-plus": ["solid", "regular"],
  "t-rex": ["solid", "regular"],
  "school-lock": ["solid", "regular"],
  "claw-marks": ["solid", "regular"],
  "file-xmark": ["solid", "regular"],
  blender: ["solid", "regular"],
  "cassette-vhs": ["solid", "regular"],
  "message-middle-top": ["solid", "regular"],
  family: ["solid", "regular"],
  "solar-panel": ["solid", "regular"],
  "boxes-stacked": ["solid", "regular"],
  "highlighter-line": ["solid", "regular"],
  transporter: ["solid", "regular"],
  "image-landscape": ["solid", "regular"],
  at: ["solid", "regular"],
  "arrows-to-dot": ["solid", "regular"],
  "car-mirrors": ["solid", "regular"],
  "chart-line-up-down": ["solid", "regular"],
  rectangle: ["solid", "regular"],
  cube: ["solid", "regular"],
  "file-check": ["solid", "regular"],
  "cloud-arrow-up": ["solid", "regular"],
  "spray-can": ["solid", "regular"],
  "angles-up": ["solid", "regular"],
  "jet-fighter": ["solid", "regular"],
  "repeat-1": ["solid", "regular"],
  spider: ["solid", "regular"],
  dryer: ["solid", "regular"],
  camcorder: ["solid", "regular"],
  "person-walking": ["solid", "regular"],
  "car-circle-bolt": ["solid", "regular"],
  "filter-circle-xmark": ["solid", "regular"],
  "cart-flatbed": ["solid", "regular"],
  "knife-kitchen": ["solid", "regular"],
  "face-smile-plus": ["solid", "regular"],
  "bracket-curly": ["solid", "regular"],
  "circle-sort": ["solid", "regular"],
  "house-medical-flag": ["solid", "regular"],
  "circle-w": ["solid", "regular"],
  "square-this-way-up": ["solid", "regular"],
  "arrow-down-from-line": ["solid", "regular"],
  "shield-exclamation": ["solid", "regular"],
  "bars-sort": ["solid", "regular"],
  "hexagon-vertical-nft": ["solid", "regular"],
  "angle-90": ["solid", "regular"],
  "folder-grid": ["solid", "regular"],
  "square-f": ["solid", "regular"],
  "field-hockey-stick-ball": ["solid", "regular"],
  shower: ["solid", "regular"],
  "turn-left-down": ["solid", "regular"],
  "arrows-spin": ["solid", "regular"],
  "cloud-hail": ["solid", "regular"],
  laptop: ["solid", "regular"],
  "building-memo": ["solid", "regular"],
  "reply-clock": ["solid", "regular"],
  video: ["solid", "regular"],
  "martini-glass": ["solid", "regular"],
  taco: ["solid", "regular"],
  h: ["solid", "regular"],
  "lock-open": ["solid", "regular"],
  "rupiah-sign": ["solid", "regular"],
  chimney: ["solid", "regular"],
  "note-sticky": ["solid", "regular"],
  crutches: ["solid", "regular"],
  "tablet-screen-button": ["solid", "regular"],
  reply: ["solid", "regular"],
  "diamond-half": ["solid", "regular"],
  "clipboard-list-check": ["solid", "regular"],
  "person-to-door": ["solid", "regular"],
  "user-secret": ["solid", "regular"],
  bug: ["solid", "regular"],
  "laptop-binary": ["solid", "regular"],
  satellite: ["solid", "regular"],
  "shield-plus": ["solid", "regular"],
  "circle-4": ["solid", "regular"],
  "sun-plant-wilt": ["solid", "regular"],
  "square-heart": ["solid", "regular"],
  gavel: ["solid", "regular"],
  "right-left-large": ["solid", "regular"],
  "party-bell": ["solid", "regular"],
  "truck-container": ["solid", "regular"],
  hospitals: ["solid", "regular"],
  "book-circle-arrow-right": ["solid", "regular"],
  "mug-hot": ["solid", "regular"],
  grill: ["solid", "regular"],
  cucumber: ["solid", "regular"],
  city: ["solid", "regular"],
  "fire-extinguisher": ["solid", "regular"],
  ghost: ["solid", "regular"],
  "person-circle-plus": ["solid", "regular"],
  tags: ["solid", "regular"],
  "location-minus": ["solid", "regular"],
  "heart-circle-exclamation": ["solid", "regular"],
  "pencil-slash": ["solid", "regular"],
  "circle-nodes": ["solid", "regular"],
  "car-on": ["solid", "regular"],
  "trash-can": ["solid", "regular"],
  "square-3": ["solid", "regular"],
  "diagram-predecessor": ["solid", "regular"],
  eraser: ["solid", "regular"],
  "web-awesome": ["solid", "brands", "regular"],
  "plate-wheat": ["solid", "regular"],
  inbox: ["solid", "regular"],
  "chess-king-piece": ["solid", "regular"],
  dharmachakra: ["solid", "regular"],
  "delete-right": ["solid", "regular"],
  "bench-tree": ["solid", "regular"],
  garage: ["solid", "regular"],
  "sack-dollar": ["solid", "regular"],
  "circle-play": ["solid", "regular"],
  "truck-ladder": ["solid", "regular"],
  "caret-up": ["solid", "regular"],
  racquet: ["solid", "regular"],
  "square-q": ["solid", "regular"],
  "turn-down-right": ["solid", "regular"],
  "flatbread-stuffed": ["solid", "regular"],
  "book-blank": ["solid", "regular"],
  "octagon-check": ["solid", "regular"],
  "face-zipper": ["solid", "regular"],
  buildings: ["solid", "regular"],
  sheep: ["solid", "regular"],
  language: ["solid", "regular"],
  restroom: ["solid", "regular"],
  "arrow-right-from-arc": ["solid", "regular"],
  warehouse: ["solid", "regular"],
  "comment-middle": ["solid", "regular"],
  "face-unamused": ["solid", "regular"],
  pump: ["solid", "regular"],
  "arrow-down-a-z": ["solid", "regular"],
  "book-bible": ["solid", "regular"],
  "toggle-large-on": ["solid", "regular"],
  "brackets-curly": ["solid", "regular"],
  shuffle: ["solid", "regular"],
  "nfc-trash": ["solid", "regular"],
  "laptop-file": ["solid", "regular"],
  "jug-detergent": ["solid", "regular"],
  disease: ["solid", "regular"],
  "ufo-beam": ["solid", "regular"],
  "user-police": ["solid", "regular"],
  "square-dashed": ["solid", "regular"],
  "cloud-word": ["solid", "regular"],
  "person-skiing-nordic": ["solid", "regular"],
  "input-pipe": ["solid", "regular"],
  "house-water": ["solid", "regular"],
  "cloud-showers-water": ["solid", "regular"],
  "messages-question": ["solid", "regular"],
  "message-exclamation": ["solid", "regular"],
  "shop-slash": ["solid", "regular"],
  diamond: ["solid", "regular"],
  apartment: ["solid", "regular"],
  "plane-up": ["solid", "regular"],
  "chart-line-down": ["solid", "regular"],
  hamsa: ["solid", "regular"],
  "floppy-disks": ["solid", "regular"],
  "train-subway": ["solid", "regular"],
  "hands-praying": ["solid", "regular"],
  "uniform-martial-arts": ["solid", "regular"],
  bicycle: ["solid", "regular"],
  "bed-bunk": ["solid", "regular"],
  "tenge-sign": ["solid", "regular"],
  tickets: ["solid", "regular"],
  "hand-love": ["solid", "regular"],
  "head-side-virus": ["solid", "regular"],
  indent: ["solid", "regular"],
  stop: ["solid", "regular"],
  "head-side-cough": ["solid", "regular"],
  play: ["solid", "regular"],
  outlet: ["solid", "regular"],
  "chart-bullet": ["solid", "regular"],
  "bowl-soft-serve": ["solid", "regular"],
  "user-shakespeare": ["solid", "regular"],
  "circle-dashed": ["solid", "regular"],
  "cake-candles": ["solid", "regular"],
  "camera-movie": ["solid", "regular"],
  "comment-lines": ["solid", "regular"],
  "lock-a": ["solid", "regular"],
  "toilet-portable": ["solid", "regular"],
  "comment-slash": ["solid", "regular"],
  "face-tissue": ["solid", "regular"],
  "phone-flip": ["solid", "regular"],
  "file-user": ["solid", "regular"],
  "arrow-up-right": ["solid", "regular"],
  baguette: ["solid", "regular"],
  "tally-3": ["solid", "regular"],
  "dolly-empty": ["solid", "regular"],
  campground: ["solid", "regular"],
  billboard: ["solid", "regular"],
  "champagne-glasses": ["solid", "regular"],
  "puzzle-piece": ["solid", "regular"],
  "apple-core": ["solid", "regular"],
  binoculars: ["solid", "regular"],
  "arrow-down-to-line": ["solid", "regular"],
  "circle-heart": ["solid", "regular"],
  meat: ["solid", "regular"],
  "bracket-curly-right": ["solid", "regular"],
  sailboat: ["solid", "regular"],
  "face-grin-wide": ["solid", "regular"],
  "file-contract": ["solid", "regular"],
  heart: ["solid", "regular"],
  "arrow-down-triangle-square": ["solid", "regular"],
  suitcase: ["solid", "regular"],
  "cart-circle-check": ["solid", "regular"],
  raygun: ["solid", "regular"],
  "face-laugh": ["solid", "regular"],
  "square-o": ["solid", "regular"],
  "arrow-up-to-dotted-line": ["solid", "regular"],
  "house-heart": ["solid", "regular"],
  "hard-drive": ["solid", "regular"],
  "compress-wide": ["solid", "regular"],
  "lacrosse-stick-ball": ["solid", "regular"],
  "volume-xmark": ["solid", "regular"],
  "circle-5": ["solid", "regular"],
  gun: ["solid", "regular"],
  "person-dress-burst": ["solid", "regular"],
  "arrow-up-from-bracket": ["solid", "regular"],
  compass: ["solid", "regular"],
  train: ["solid", "regular"],
  "solar-system": ["solid", "regular"],
  b: ["solid", "regular"],
  "trash-can-slash": ["solid", "regular"],
  "car-tunnel": ["solid", "regular"],
  "pan-food": ["solid", "regular"],
  "arrows-rotate-reverse": ["solid", "regular"],
  "xmark-large": ["solid", "regular"],
  "star-half": ["solid", "regular"],
  "tent-arrow-left-right": ["solid", "regular"],
  "table-cells-row-lock": ["solid", "regular"],
  "island-tropical": ["solid", "regular"],
  "arrow-down-left": ["solid", "regular"],
  "signal-stream-slash": ["solid", "regular"],
  "comment-quote": ["solid", "regular"],
  "person-biking": ["solid", "regular"],
  "file-invoice-dollar": ["solid", "regular"],
  ferry: ["solid", "regular"],
  "scanner-gun": ["solid", "regular"],
  "lightbulb-cfl": ["solid", "regular"],
  "sidebar-flip": ["solid", "regular"],
  "boxes-packing": ["solid", "regular"],
  "clock-desk": ["solid", "regular"],
  file: ["solid", "regular"],
  "hand-middle-finger": ["solid", "regular"],
  "shower-down": ["solid", "regular"],
  "glasses-round": ["solid", "regular"],
  "wave-square": ["solid", "regular"],
  "cloud-bolt": ["solid", "regular"],
  "watch-fitness": ["solid", "regular"],
  "head-side-mask": ["solid", "regular"],
  "lightbulb-exclamation-on": ["solid", "regular"],
  "shish-kebab": ["solid", "regular"],
  "tarp-droplet": ["solid", "regular"],
  "down-long": ["solid", "regular"],
  "trash-can-clock": ["solid", "regular"],
  dial: ["solid", "regular"],
  "circle-phone": ["solid", "regular"],
  "truck-container-empty": ["solid", "regular"],
  "gauge-circle-plus": ["solid", "regular"],
  marker: ["solid", "regular"],
  "circle-right": ["solid", "regular"],
  "books-medical": ["solid", "regular"],
  bird: ["solid", "regular"],
  "bag-seedling": ["solid", "regular"],
  "hat-cowboy-side": ["solid", "regular"],
  "border-center-h": ["solid", "regular"],
  "person-walking-arrow-right": ["solid", "regular"],
  "hourglass-half": ["solid", "regular"],
  "messages-dollar": ["solid", "regular"],
  intersection: ["solid", "regular"],
  "arrows-down-to-line": ["solid", "regular"],
  "lamp-floor": ["solid", "regular"],
  "sheet-plastic": ["solid", "regular"],
  "bin-bottles": ["solid", "regular"],
  "cheese-swiss": ["solid", "regular"],
  "face-confused": ["solid", "regular"],
  "ticket-airline": ["solid", "regular"],
  "crosshairs-simple": ["solid", "regular"],
  "ellipsis-stroke-vertical": ["solid", "regular"],
  "cloud-hail-mixed": ["solid", "regular"],
  "table-picnic": ["solid", "regular"],
  "face-fearful": ["solid", "regular"],
  "jet-fighter-up": ["solid", "regular"],
  "draw-polygon": ["solid", "regular"],
  "rectangle-xmark": ["solid", "regular"],
  "pallet-boxes": ["solid", "regular"],
  "border-bottom": ["solid", "regular"],
  bandage: ["solid", "regular"],
  "face-pleading": ["solid", "regular"],
  hotel: ["solid", "regular"],
  "ruler-vertical": ["solid", "regular"],
  "child-reaching": ["solid", "regular"],
  "pen-swirl": ["solid", "regular"],
  "message-sms": ["solid", "regular"],
  "plug-circle-check": ["solid", "regular"],
  "walkie-talkie": ["solid", "regular"],
  "computer-speaker": ["solid", "regular"],
  repeat: ["solid", "regular"],
  "wifi-slash": ["solid", "regular"],
  "head-side-medical": ["solid", "regular"],
  "suitcase-medical": ["solid", "regular"],
  "file-svg": ["solid", "regular"],
  "clock-eleven": ["solid", "regular"],
  "house-person-return": ["solid", "regular"],
  "block-question": ["solid", "regular"],
  "circle-waveform-lines": ["solid", "regular"],
  "person-ski-lift": ["solid", "regular"],
  "border-top": ["solid", "regular"],
  "jug-bottle": ["solid", "regular"],
  windsock: ["solid", "regular"],
  "star-christmas": ["solid", "regular"],
  "hexagon-plus": ["solid", "regular"],
  "glass-half": ["solid", "regular"],
  "square-share-nodes": ["solid", "regular"],
  "house-person-leave": ["solid", "regular"],
  "building-un": ["solid", "regular"],
  "keyboard-brightness-low": ["solid", "regular"],
  "book-atlas": ["solid", "regular"],
  inboxes: ["solid", "regular"],
  ban: ["solid", "regular"],
  sunglasses: ["solid", "regular"],
  "file-circle-xmark": ["solid", "regular"],
  "bullseye-pointer": ["solid", "regular"],
  "circle-up-left": ["solid", "regular"],
  "skull-cow": ["solid", "regular"],
  "circle-r": ["solid", "regular"],
  "ruler-triangle": ["solid", "regular"],
  "eye-dropper-full": ["solid", "regular"],
  "lightbulb-gear": ["solid", "regular"],
  flower: ["solid", "regular"],
  "face-grin-tongue-wink": ["solid", "regular"],
  blueberries: ["solid", "regular"],
  "plant-wilt": ["solid", "regular"],
  "square-h": ["solid", "regular"],
  "car-wash": ["solid", "regular"],
  "user-group-simple": ["solid", "regular"],
  "temperature-sun": ["solid", "regular"],
  font: ["solid", "regular"],
  "down-from-line": ["solid", "regular"],
  saxophone: ["solid", "regular"],
  "face-spiral-eyes": ["solid", "regular"],
  "circle-arrow-down": ["solid", "regular"],
  "tv-music": ["solid", "regular"],
  "truck-tow": ["solid", "regular"],
  "hands-bubbles": ["solid", "regular"],
  shirt: ["solid", "regular"],
  paragraph: ["solid", "regular"],
  "car-side": ["solid", "regular"],
  messages: ["solid", "regular"],
  "file-zip": ["solid", "regular"],
  abacus: ["solid", "regular"],
  "envelopes-bulk": ["solid", "regular"],
  "location-arrow-up": ["solid", "regular"],
  "cloud-plus": ["solid", "regular"],
  "house-chimney-user": ["solid", "regular"],
  telescope: ["solid", "regular"],
  chestnut: ["solid", "regular"],
  "lamp-street": ["solid", "regular"],
  k: ["solid", "regular"],
  droplet: ["solid", "regular"],
  "arrow-down-up-lock": ["solid", "regular"],
  "heart-crack": ["solid", "regular"],
  "code-compare": ["solid", "regular"],
  "file-prescription": ["solid", "regular"],
  tick: ["solid", "regular"],
  "arrow-up-to-arc": ["solid", "regular"],
  alicorn: ["solid", "regular"],
  "card-spade": ["solid", "regular"],
  "seal-exclamation": ["solid", "regular"],
  "poo-storm": ["solid", "regular"],
  "temperature-high": ["solid", "regular"],
  "candy-cane": ["solid", "regular"],
  "restroom-simple": ["solid", "regular"],
  "party-horn": ["solid", "regular"],
  h2: ["solid", "regular"],
  "cloud-sun-rain": ["solid", "regular"],
  "face-sleeping": ["solid", "regular"],
  "user-nurse-hair": ["solid", "regular"],
  "truck-ramp-box": ["solid", "regular"],
  "person-dress": ["solid", "regular"],
  "card-heart": ["solid", "regular"],
  forklift: ["solid", "regular"],
  "laptop-slash": ["solid", "regular"],
  trailer: ["solid", "regular"],
  "text-slash": ["solid", "regular"],
  "location-pin": ["solid", "regular"],
  "file-video": ["solid", "regular"],
  "up-from-line": ["solid", "regular"],
  "phone-plus": ["solid", "regular"],
  "truck-clock": ["solid", "regular"],
  "person-praying": ["solid", "regular"],
  "circle-9": ["solid", "regular"],
  "folder-tree": ["solid", "regular"],
  "file-music": ["solid", "regular"],
  "transporter-6": ["solid", "regular"],
  "landmark-flag": ["solid", "regular"],
  "shovel-snow": ["solid", "regular"],
  "down-from-bracket": ["solid", "regular"],
  "audio-description": ["solid", "regular"],
  "child-combatant": ["solid", "regular"],
  "person-skiing": ["solid", "regular"],
  "id-card": ["solid", "regular"],
  "house-medical-circle-xmark": ["solid", "regular"],
  "hand-dots": ["solid", "regular"],
  "horse-head": ["solid", "regular"],
  vault: ["solid", "regular"],
  "cloud-minus": ["solid", "regular"],
  link: ["solid", "regular"],
  "bowling-pins": ["solid", "regular"],
  "kitchen-set": ["solid", "regular"],
  terminal: ["solid", "regular"],
  "square-1": ["solid", "regular"],
  "video-slash": ["solid", "regular"],
  "face-smile-hearts": ["solid", "regular"],
  waveform: ["solid", "regular"],
  "diamond-half-stroke": ["solid", "regular"],
  "star-half-stroke": ["solid", "regular"],
  "arrow-pointer": ["solid", "regular"],
  "comment-smile": ["solid", "regular"],
  "chart-pie-simple": ["solid", "regular"],
  "martini-glass-empty": ["solid", "regular"],
  "swap-arrows": ["solid", "regular"],
  "cloud-showers-heavy": ["solid", "regular"],
  "heart-half-stroke": ["solid", "regular"],
  grapes: ["solid", "regular"],
  sandwich: ["solid", "regular"],
  "hand-holding-droplet": ["solid", "regular"],
  v: ["solid", "regular"],
  "square-j": ["solid", "regular"],
  "sim-card": ["solid", "regular"],
  jar: ["solid", "regular"],
  "photo-film-music": ["solid", "regular"],
  "square-parking": ["solid", "regular"],
  "left-to-line": ["solid", "regular"],
  "square-k": ["solid", "regular"],
  "clock-eleven-thirty": ["solid", "regular"],
  "calculator-simple": ["solid", "regular"],
  mailbox: ["solid", "regular"],
  "message-captions": ["solid", "regular"],
  list: ["solid", "regular"],
  "gingerbread-man": ["solid", "regular"],
  "arrow-down-1-9": ["solid", "regular"],
  pizza: ["solid", "regular"],
  "vest-patches": ["solid", "regular"],
  "wifi-fair": ["solid", "regular"],
  stocking: ["solid", "regular"],
  flashlight: ["solid", "regular"],
  "face-grin-squint-tears": ["solid", "regular"],
  "eye-dropper": ["solid", "regular"],
  forward: ["solid", "regular"],
  "diagram-subtask": ["solid", "regular"],
  symbols: ["solid", "regular"],
  "arrow-up-short-wide": ["solid", "regular"],
  helicopter: ["solid", "regular"],
  "square-d": ["solid", "regular"],
  "dice-d8": ["solid", "regular"],
  "square-parking-slash": ["solid", "regular"],
  "flag-pennant": ["solid", "regular"],
  "water-ladder": ["solid", "regular"],
  "file-image": ["solid", "regular"],
  "taxi-bus": ["solid", "regular"],
  mango: ["solid", "regular"],
  "globe-snow": ["solid", "regular"],
  "booth-curtain": ["solid", "regular"],
  exclamation: ["solid", "regular"],
  "arrow-progress": ["solid", "regular"],
  "message-dots": ["solid", "regular"],
  "diamond-turn-right": ["solid", "regular"],
  "dollar-sign": ["solid", "regular"],
  bed: ["solid", "regular"],
  blinds: ["solid", "regular"],
  "code-commit": ["solid", "regular"],
  hospital: ["solid", "regular"],
  "greater-than-equal": ["solid", "regular"],
  "colon-sign": ["solid", "regular"],
  "file-pdf": ["solid", "regular"],
  "folder-magnifying-glass": ["solid", "regular"],
  section: ["solid", "regular"],
  chalkboard: ["solid", "regular"],
  kerning: ["solid", "regular"],
  bahai: ["solid", "regular"],
  "user-pilot": ["solid", "regular"],
  "car-burst": ["solid", "regular"],
  "chart-mixed": ["solid", "regular"],
  "circle-location-arrow": ["solid", "regular"],
  bookmark: ["solid", "regular"],
  horse: ["solid", "regular"],
  "file-spreadsheet": ["solid", "regular"],
  "cart-minus": ["solid", "regular"],
  "folder-open": ["solid", "regular"],
  "meter-bolt": ["solid", "regular"],
  h1: ["solid", "regular"],
  heading: ["solid", "regular"],
  "square-bolt": ["solid", "regular"],
  "wheat-awn-slash": ["solid", "regular"],
  "border-bottom-right": ["solid", "regular"],
  "comment-minus": ["solid", "regular"],
  "saxophone-fire": ["solid", "regular"],
  bells: ["solid", "regular"],
  "circle-exclamation-check": ["solid", "regular"],
  "credit-card-blank": ["solid", "regular"],
  "microphone-lines": ["solid", "regular"],
  copyright: ["solid", "regular"],
  "helmet-safety": ["solid", "regular"],
  "gas-pump": ["solid", "regular"],
  "toilet-paper-blank-under": ["solid", "regular"],
  "traffic-light-slow": ["solid", "regular"],
  y: ["solid", "regular"],
  "ticket-simple": ["solid", "regular"],
  "train-tunnel": ["solid", "regular"],
  "arrow-up": ["solid", "regular"],
  "circle-0": ["solid", "regular"],
  "dial-min": ["solid", "regular"],
  "file-binary": ["solid", "regular"],
  "face-dizzy": ["solid", "regular"],
  "face-mask": ["solid", "regular"],
  "circle-chevron-down": ["solid", "regular"],
  "toggle-on": ["solid", "regular"],
  "table-cells-column-lock": ["solid", "regular"],
  sausage: ["solid", "regular"],
  "face-sad-cry": ["solid", "regular"],
  "radio-tuner": ["solid", "regular"],
  "file-certificate": ["solid", "regular"],
  "chevron-right": ["solid", "regular"],
  i: ["solid", "regular"],
  "people-pants": ["solid", "regular"],
  "arrow-up-from-water-pump": ["solid", "regular"],
  "message-medical": ["solid", "regular"],
  flute: ["solid", "regular"],
  "square-0": ["solid", "regular"],
  notes: ["solid", "regular"],
  republican: ["solid", "regular"],
  fax: ["solid", "regular"],
  seedling: ["solid", "regular"],
  "starfighter-twin-ion-engine-advanced": ["solid", "regular"],
  "location-exclamation": ["solid", "regular"],
  "filter-slash": ["solid", "regular"],
  "laptop-code": ["solid", "regular"],
  "arrow-left-to-line": ["solid", "regular"],
  "broom-ball": ["solid", "regular"],
  "light-emergency-on": ["solid", "regular"],
  "gauge-simple": ["solid", "regular"],
  "pen-field": ["solid", "regular"],
  cactus: ["solid", "regular"],
  screencast: ["solid", "regular"],
  bluetooth: ["solid", "brands", "regular"],
  "paper-plane": ["solid", "regular"],
  "circle-notch": ["solid", "regular"],
  "plane-circle-xmark": ["solid", "regular"],
  burrito: ["solid", "regular"],
  "square-y": ["solid", "regular"],
  "cloud-sleet": ["solid", "regular"],
  user: ["solid", "regular"],
  "shield-halved": ["solid", "regular"],
  "star-shooting": ["solid", "regular"],
  "album-collection-circle-plus": ["solid", "regular"],
  tricycle: ["solid", "regular"],
  "indian-rupee-sign": ["solid", "regular"],
  "cash-register": ["solid", "regular"],
  "temperature-full": ["solid", "regular"],
  "starship-freighter": ["solid", "regular"],
  "fire-flame": ["solid", "regular"],
  tty: ["solid", "regular"],
  mobile: ["solid", "regular"],
  cat: ["solid", "regular"],
  "box-check": ["solid", "regular"],
  volume: ["solid", "regular"],
  tablet: ["solid", "regular"],
  "game-console-handheld": ["solid", "regular"],
  "book-quran": ["solid", "regular"],
  "user-injured": ["solid", "regular"],
  compress: ["solid", "regular"],
  "circle-o": ["solid", "regular"],
  "hand-fist": ["solid", "regular"],
  pumpkin: ["solid", "regular"],
  prescription: ["solid", "regular"],
  scrubber: ["solid", "regular"],
  "mars-double": ["solid", "regular"],
  "lines-leaning": ["solid", "regular"],
  "truck-moving": ["solid", "regular"],
  meter: ["solid", "regular"],
  "paragraph-left": ["solid", "regular"],
  "triangle-person-digging": ["solid", "regular"],
  "users-gear": ["solid", "regular"],
  "face-astonished": ["solid", "regular"],
  "folder-gear": ["solid", "regular"],
  "scale-unbalanced-flip": ["solid", "regular"],
  siren: ["solid", "regular"],
  "keyboard-down": ["solid", "regular"],
  "circle-down": ["solid", "regular"],
  "ranking-star": ["solid", "regular"],
  "honey-pot": ["solid", "regular"],
  "calendar-pen": ["solid", "regular"],
  shield: ["solid", "regular"],
  broom: ["solid", "regular"],
  "bug-slash": ["solid", "regular"],
  "circle-radiation": ["solid", "regular"],
  "signal-bars": ["solid", "regular"],
  "user-visor": ["solid", "regular"],
  integral: ["solid", "regular"],
  "boot-heeled": ["solid", "regular"],
  "user-hair": ["solid", "regular"],
  "candle-holder": ["solid", "regular"],
  "microphone-stand": ["solid", "regular"],
  "life-ring": ["solid", "regular"],
  dumbbell: ["solid", "regular"],
  "user-headset": ["solid", "regular"],
  "people-roof": ["solid", "regular"],
  sword: ["solid", "regular"],
  notdef: ["solid", "regular"],
  "power-off": ["solid", "regular"],
  "clock-ten-thirty": ["solid", "regular"],
  "person-half-dress": ["solid", "regular"],
  "chart-scatter-bubble": ["solid", "regular"],
  loveseat: ["solid", "regular"],
  "circle-2": ["solid", "regular"],
  "person-booth": ["solid", "regular"],
  "garage-open": ["solid", "regular"],
  "reflect-both": ["solid", "regular"],
  router: ["solid", "regular"],
  "microphone-slash": ["solid", "regular"],
  "list-radio": ["solid", "regular"],
  "chevron-down": ["solid", "regular"],
  "alarm-exclamation": ["solid", "regular"],
  "cloud-fog": ["solid", "regular"],
  hotdog: ["solid", "regular"],
  "turn-up": ["solid", "regular"],
  "face-laugh-wink": ["solid", "regular"],
  "person-rays": ["solid", "regular"],
  "cruzeiro-sign": ["solid", "regular"],
  stomach: ["solid", "regular"],
  "heart-circle-xmark": ["solid", "regular"],
  "truck-arrow-right": ["solid", "regular"],
  "signs-post": ["solid", "regular"],
  waffle: ["solid", "regular"],
  "arrow-right-from-line": ["solid", "regular"],
  donut: ["solid", "regular"],
  files: ["solid", "regular"],
  "input-numeric": ["solid", "regular"],
  tv: ["solid", "regular"],
  lamp: ["solid", "regular"],
  "school-circle-exclamation": ["solid", "regular"],
  "school-flag": ["solid", "regular"],
  "message-pen": ["solid", "regular"],
  "hand-holding-seedling": ["solid", "regular"],
  "turn-right": ["solid", "regular"],
  "bag-shopping-plus": ["solid", "regular"],
  "house-lock": ["solid", "regular"],
  "chart-radar": ["solid", "regular"],
  "arrows-maximize": ["solid", "regular"],
  alt: ["solid", "regular"],
  "traffic-light-go": ["solid", "regular"],
  "rugby-ball": ["solid", "regular"],
  "watch-calculator": ["solid", "regular"],
  "flag-usa": ["solid", "regular"],
  text: ["solid", "regular"],
  ellipsis: ["solid", "regular"],
  "hexagon-minus": ["solid", "regular"],
  minimize: ["solid", "regular"],
  "square-virus": ["solid", "regular"],
  "boxing-glove": ["solid", "regular"],
  "toggle-large-off": ["solid", "regular"],
  "arrow-up-right-and-arrow-down-left-from-center": ["solid", "regular"],
  s: ["solid", "regular"],
  "dial-max": ["solid", "regular"],
  violin: ["solid", "regular"],
  volcano: ["solid", "regular"],
  "money-bills": ["solid", "regular"],
  "franc-sign": ["solid", "regular"],
  "file-medical": ["solid", "regular"],
  "battery-three-quarters": ["solid", "regular"],
  "satellite-dish": ["solid", "regular"],
  "shield-xmark": ["solid", "regular"],
  "face-surprise": ["solid", "regular"],
  "watch-apple": ["solid", "regular"],
  bat: ["solid", "regular"],
  "arrow-turn-right": ["solid", "regular"],
  "vent-damper": ["solid", "regular"],
  "people-pants-simple": ["solid", "regular"],
  ribbon: ["solid", "regular"],
  "star-of-life": ["solid", "regular"],
  "book-bookmark": ["solid", "regular"],
  "cloud-drizzle": ["solid", "regular"],
  "face-monocle": ["solid", "regular"],
  "nfc-pen": ["solid", "regular"],
  "badge-sheriff": ["solid", "regular"],
  "vial-circle-check": ["solid", "regular"],
  "cassette-betamax": ["solid", "regular"],
  "temperature-three-quarters": ["solid", "regular"],
  "fondue-pot": ["solid", "regular"],
  "clock-four-thirty": ["solid", "regular"],
  "person-seat": ["solid", "regular"],
  "users-rectangle": ["solid", "regular"],
  "square-check": ["solid", "regular"],
  "pen-circle": ["solid", "regular"],
  theta: ["solid", "regular"],
  "bolt-slash": ["solid", "regular"],
  hand: ["solid", "regular"],
  "robot-astromech": ["solid", "regular"],
  "truck-bolt": ["solid", "regular"],
  worm: ["solid", "regular"],
  "user-robot-xmarks": ["solid", "regular"],
  "bed-empty": ["solid", "regular"],
  handcuffs: ["solid", "regular"],
  "hourglass-start": ["solid", "regular"],
  "signal-fair": ["solid", "regular"],
  "memo-pad": ["solid", "regular"],
  "message-text": ["solid", "regular"],
  "house-crack": ["solid", "regular"],
  "grid-round": ["solid", "regular"],
  "circle-6": ["solid", "regular"],
  motorcycle: ["solid", "regular"],
  taxi: ["solid", "regular"],
  "peso-sign": ["solid", "regular"],
  "house-day": ["solid", "regular"],
  "left-right": ["solid", "regular"],
  "panel-fire": ["solid", "regular"],
  "angle-right": ["solid", "regular"],
  folders: ["solid", "regular"],
  rainbow: ["solid", "regular"],
  "square-up": ["solid", "regular"],
  "grate-droplet": ["solid", "regular"],
  "utility-pole": ["solid", "regular"],
  "curling-stone": ["solid", "regular"],
  "cowbell-circle-plus": ["solid", "regular"],
  flask: ["solid", "regular"],
  "house-circle-xmark": ["solid", "regular"],
  "down-to-dotted-line": ["solid", "regular"],
  anchor: ["solid", "regular"],
  "arrow-down-big-small": ["solid", "regular"],
  "magnifying-glass-location": ["solid", "regular"],
  "bracket-square-right": ["solid", "regular"],
  "fire-smoke": ["solid", "regular"],
  "mug-saucer": ["solid", "regular"],
  "face-rolling-eyes": ["solid", "regular"],
  stretcher: ["solid", "regular"],
  "book-heart": ["solid", "regular"],
  "oil-temperature": ["solid", "regular"],
  "jar-wheat": ["solid", "regular"],
  "droplet-percent": ["solid", "regular"],
  "circle-three-quarters-stroke": ["solid", "regular"],
  "hand-fingers-crossed": ["solid", "regular"],
  blog: ["solid", "regular"],
  "box-tissue": ["solid", "regular"],
  "square-4": ["solid", "regular"],
  gif: ["solid", "regular"],
  "circle-wifi": ["solid", "regular"],
  "lasso-sparkles": ["solid", "regular"],
  "sword-laser-alt": ["solid", "regular"],
  "face-smile": ["solid", "regular"],
  "compass-slash": ["solid", "regular"],
  "turn-down-left": ["solid", "regular"],
  custard: ["solid", "regular"],
  "greater-than": ["solid", "regular"],
  "chevrons-down": ["solid", "regular"],
  "helmet-un": ["solid", "regular"],
  "egg-fried": ["solid", "regular"],
  "square-v": ["solid", "regular"],
  calendars: ["solid", "regular"],
  "arrows-left-right": ["solid", "regular"],
  potato: ["solid", "regular"],
  browser: ["solid", "regular"],
  fireplace: ["solid", "regular"],
  "user-robot": ["solid", "regular"],
  kite: ["solid", "regular"],
  "puzzle-piece-simple": ["solid", "regular"],
  comment: ["solid", "regular"],
  "air-conditioner": ["solid", "regular"],
  "crate-apple": ["solid", "regular"],
  star: ["solid", "regular"],
  "face-smile-relaxed": ["solid", "regular"],
  "circle-arrow-left": ["solid", "regular"],
  "truck-ramp-couch": ["solid", "regular"],
  scarecrow: ["solid", "regular"],
  pepper: ["solid", "regular"],
  "mars-and-venus": ["solid", "regular"],
  "user-gear": ["solid", "regular"],
  "square-c": ["solid", "regular"],
  dash: ["solid", "regular"],
  tents: ["solid", "regular"],
  "file-ppt": ["solid", "regular"],
  dagger: ["solid", "regular"],
  "person-dots-from-line": ["solid", "regular"],
  "square-plus": ["solid", "regular"],
  "hands-holding-dollar": ["solid", "regular"],
  "pencil-mechanical": ["solid", "regular"],
  "dice-d12": ["solid", "regular"],
  "send-back": ["solid", "regular"],
  "display-code": ["solid", "regular"],
  "circle-caret-down": ["solid", "regular"],
  "users-medical": ["solid", "regular"],
  "file-lock": ["solid", "regular"],
  trees: ["solid", "regular"],
  "calendar-range": ["solid", "regular"],
  "rectangle-list": ["solid", "regular"],
  "hundred-points": ["solid", "regular"],
  spa: ["solid", "regular"],
  "citrus-slice": ["solid", "regular"],
  "message-lines": ["solid", "regular"],
  "building-circle-xmark": ["solid", "regular"],
  "gas-pump-slash": ["solid", "regular"],
  books: ["solid", "regular"],
  "pen-clip-slash": ["solid", "regular"],
  "square-arrow-down-right": ["solid", "regular"],
  robot: ["solid", "regular"],
  "signal-stream": ["solid", "regular"],
  monkey: ["solid", "regular"],
  left: ["solid", "regular"],
  "octagon-plus": ["solid", "regular"],
  "school-circle-check": ["solid", "regular"],
  "tennis-ball": ["solid", "regular"],
  "high-definition": ["solid", "regular"],
  "square-arrow-left": ["solid", "regular"],
  "star-exclamation": ["solid", "regular"],
  "chart-line": ["solid", "regular"],
  "mask-ventilator": ["solid", "regular"],
  "location-dot": ["solid", "regular"],
  "snowflake-droplets": ["solid", "regular"],
  peace: ["solid", "regular"],
  notebook: ["solid", "regular"],
  "turn-left": ["solid", "regular"],
  "person-rifle": ["solid", "regular"],
  "award-simple": ["solid", "regular"],
  "circle-v": ["solid", "regular"],
  "line-columns": ["solid", "regular"],
  dice: ["solid", "regular"],
  "calendar-exclamation": ["solid", "regular"],
  "arrow-left": ["solid", "regular"],
  "arrow-down-up-across-line": ["solid", "regular"],
  "axe-battle": ["solid", "regular"],
  "industry-windows": ["solid", "regular"],
  "face-smiling-hands": ["solid", "regular"],
  "book-copy": ["solid", "regular"],
  camera: ["solid", "regular"],
  "gallery-thumbnails": ["solid", "regular"],
  "ring-diamond": ["solid", "regular"],
  "list-music": ["solid", "regular"],
  road: ["solid", "regular"],
  "bread-loaf": ["solid", "regular"],
  "chart-pie": ["solid", "regular"],
  "chart-pie-simple-circle-currency": ["solid", "regular"],
  "arrows-to-eye": ["solid", "regular"],
  "wine-glass-crack": ["solid", "regular"],
  "mug-marshmallows": ["solid", "regular"],
  snooze: ["solid", "regular"],
  "table-tree": ["solid", "regular"],
  "circle-n": ["solid", "regular"],
  "face-clouds": ["solid", "regular"],
  "backward-step": ["solid", "regular"],
  "truck-pickup": ["solid", "regular"],
  "arrow-down-square-triangle": ["solid", "regular"],
  "blinds-raised": ["solid", "regular"],
  phone: ["solid", "regular"],
  mars: ["solid", "regular"],
  "hockey-stick-puck": ["solid", "regular"],
  carrot: ["solid", "regular"],
  "wine-glass-empty": ["solid", "regular"],
  pickaxe: ["solid", "regular"],
  "file-xls": ["solid", "regular"],
  "screen-users": ["solid", "regular"],
  brightness: ["solid", "regular"],
  "cart-shopping-fast": ["solid", "regular"],
  "hand-pointer": ["solid", "regular"],
  "toilet-paper-under-slash": ["solid", "regular"],
  "planet-moon": ["solid", "regular"],
  mandolin: ["solid", "regular"],
  castle: ["solid", "regular"],
  strawberry: ["solid", "regular"],
  "table-cells-large": ["solid", "regular"],
  mosque: ["solid", "regular"],
  turkey: ["solid", "regular"],
  "display-chart-up-circle-dollar": ["solid", "regular"],
  "face-tired": ["solid", "regular"],
  "layer-minus": ["solid", "regular"],
  "cloud-rainbow": ["solid", "regular"],
  "record-vinyl": ["solid", "regular"],
  "message-bot": ["solid", "regular"],
  "person-hiking": ["solid", "regular"],
  "paw-claws": ["solid", "regular"],
  "blanket-fire": ["solid", "regular"],
  "ear-listen": ["solid", "regular"],
  "grill-fire": ["solid", "regular"],
  smoking: ["solid", "regular"],
  "file-excel": ["solid", "regular"],
  dreidel: ["solid", "regular"],
  sleigh: ["solid", "regular"],
  "litecoin-sign": ["solid", "regular"],
  "chess-clock": ["solid", "regular"],
  "video-arrow-up-right": ["solid", "regular"],
  glass: ["solid", "regular"],
  "circle-arrow-up-right": ["solid", "regular"],
  "object-ungroup": ["solid", "regular"],
  "hand-back-point-up": ["solid", "regular"],
  "chart-pie-simple-circle-dollar": ["solid", "regular"],
  toolbox: ["solid", "regular"],
  "chart-line-up": ["solid", "regular"],
  "tickets-airline": ["solid", "regular"],
  pipe: ["solid", "regular"],
  "nfc-lock": ["solid", "regular"],
  "user-magnifying-glass": ["solid", "regular"],
  "tablet-rugged": ["solid", "regular"],
  "circle-yen": ["solid", "regular"],
  "magnifying-glass-dollar": ["solid", "regular"],
  onion: ["solid", "regular"],
  "user-large": ["solid", "regular"],
  "file-word": ["solid", "regular"],
  "arrow-up-arrow-down": ["solid", "regular"],
  "cart-arrow-down": ["solid", "regular"],
  "chart-column": ["solid", "regular"],
  "square-person-confined": ["solid", "regular"],
  trillium: ["solid", "regular"],
  "circle-ampersand": ["solid", "regular"],
  drone: ["solid", "regular"],
  "square-exclamation": ["solid", "regular"],
  "book-open-cover": ["solid", "regular"],
  "block-brick-fire": ["solid", "regular"],
  "calendar-clock": ["solid", "regular"],
  ornament: ["solid", "regular"],
  "calendar-image": ["solid", "regular"],
  "star-and-crescent": ["solid", "regular"],
  "square-down-right": ["solid", "regular"],
  "square-arrow-up": ["solid", "regular"],
  "file-cad": ["solid", "regular"],
  "hat-beach": ["solid", "regular"],
  "hands-asl-interpreting": ["solid", "regular"],
  club: ["solid", "regular"],
  squirrel: ["solid", "regular"],
  "folder-check": ["solid", "regular"],
  banana: ["solid", "regular"],
  "arrow-up-right-dots": ["solid", "regular"],
  "hood-cloak": ["solid", "regular"],
  kaaba: ["solid", "regular"],
  "table-list": ["solid", "regular"],
  "arrow-right-long": ["solid", "regular"],
  "cloud-moon-rain": ["solid", "regular"],
  "circle-up-right": ["solid", "regular"],
  "square-arrow-up-right": ["solid", "regular"],
  "florin-sign": ["solid", "regular"],
  "face-sunglasses": ["solid", "regular"],
  "truck-field-un": ["solid", "regular"],
  "deer-rudolph": ["solid", "regular"],
  "quote-left": ["solid", "regular"],
  "message-arrow-up": ["solid", "regular"],
  "book-user": ["solid", "regular"],
  "square-z": ["solid", "regular"],
  "keyboard-left": ["solid", "regular"],
  "peseta-sign": ["solid", "regular"],
  "dice-two": ["solid", "regular"],
  couch: ["solid", "regular"],
  "mask-face": ["solid", "regular"],
  "face-meh": ["solid", "regular"],
  "tally-2": ["solid", "regular"],
  "money-bill-transfer": ["solid", "regular"],
  hippo: ["solid", "regular"],
  "grid-dividers": ["solid", "regular"],
  "whiskey-glass-ice": ["solid", "regular"],
  "door-closed": ["solid", "regular"],
  "bolt-auto": ["solid", "regular"],
  "square-b": ["solid", "regular"],
  "route-highway": ["solid", "regular"],
  clone: ["solid", "regular"],
  "face-shush": ["solid", "regular"],
  "floppy-disk-circle-xmark": ["solid", "regular"],
  person: ["solid", "regular"],
  "horse-saddle": ["solid", "regular"],
  swords: ["solid", "regular"],
  "person-fairy": ["solid", "regular"],
  envelopes: ["solid", "regular"],
  "face-grin-beam-sweat": ["solid", "regular"],
  "face-nauseated": ["solid", "regular"],
  "comment-arrow-down": ["solid", "regular"],
  "right-from-bracket": ["solid", "regular"],
  g: ["solid", "regular"],
  dolly: ["solid", "regular"],
  "football-helmet": ["solid", "regular"],
  "computer-mouse-scrollwheel": ["solid", "regular"],
  "computer-mouse": ["solid", "regular"],
  refrigerator: ["solid", "regular"],
  "users-viewfinder": ["solid", "regular"],
  "hand-wave": ["solid", "regular"],
  "align-center": ["solid", "regular"],
  "square-arrow-down": ["solid", "regular"],
  "person-military-pointing": ["solid", "regular"],
  "circle-star": ["solid", "regular"],
  "chess-pawn": ["solid", "regular"],
  "arrow-up-from-square": ["solid", "regular"],
  "user-doctor-hair-long": ["solid", "regular"],
  album: ["solid", "regular"],
  "wind-turbine": ["solid", "regular"],
  "camera-cctv": ["solid", "regular"],
  "square-ellipsis": ["solid", "regular"],
  "circle-question": ["solid", "regular"],
  "reflect-horizontal": ["solid", "regular"],
  trumpet: ["solid", "regular"],
  "car-wrench": ["solid", "regular"],
  "sd-cards": ["solid", "regular"],
  "waves-sine": ["solid", "regular"],
  hurricane: ["solid", "regular"],
  "square-quarters": ["solid", "regular"],
  pallet: ["solid", "regular"],
  "xmarks-lines": ["solid", "regular"],
  "light-switch": ["solid", "regular"],
  "house-medical-circle-exclamation": ["solid", "regular"],
  "bowl-spoon": ["solid", "regular"],
  "display-chart-up-circle-currency": ["solid", "regular"],
  "comments-question": ["solid", "regular"],
  "rectangle-vertical": ["solid", "regular"],
  "person-digging": ["solid", "regular"],
  "pen-fancy-slash": ["solid", "regular"],
  "tent-arrows-down": ["solid", "regular"],
  "ferris-wheel": ["solid", "regular"],
  "battery-half": ["solid", "regular"],
  "bangladeshi-taka-sign": ["solid", "regular"],
  "hot-tub-person": ["solid", "regular"],
  sort: ["solid", "regular"],
  "space-station-moon": ["solid", "regular"],
  "circle-down-right": ["solid", "regular"],
  "shuttle-space": ["solid", "regular"],
  "list-dropdown": ["solid", "regular"],
  "pepper-hot": ["solid", "regular"],
  "sack-xmark": ["solid", "regular"],
  "sign-post": ["solid", "regular"],
  "circle-microphone": ["solid", "regular"],
  raccoon: ["solid", "regular"],
  "location-xmark": ["solid", "regular"],
  "magnifying-glass-arrows-rotate": ["solid", "regular"],
  signature: ["solid", "regular"],
  "camera-security": ["solid", "regular"],
  speaker: ["solid", "regular"],
  "user-vneck-hair-long": ["solid", "regular"],
  "yen-sign": ["solid", "regular"],
  "bow-arrow": ["solid", "regular"],
  "tombstone-blank": ["solid", "regular"],
  "hexagon-vertical-nft-slanted": ["solid", "regular"],
  pi: ["solid", "regular"],
  "message-middle": ["solid", "regular"],
  "ear-muffs": ["solid", "regular"],
  "cloud-sun": ["solid", "regular"],
  "face-hushed": ["solid", "regular"],
  "signal-weak": ["solid", "regular"],
  "circle-m": ["solid", "regular"],
  "diagram-cells": ["solid", "regular"],
  "shoe-prints": ["solid", "regular"],
  "border-all": ["solid", "regular"],
  pretzel: ["solid", "regular"],
  "cloud-meatball": ["solid", "regular"],
  "lungs-virus": ["solid", "regular"],
  podium: ["solid", "regular"],
  democrat: ["solid", "regular"],
  "user-doctor-hair": ["solid", "regular"],
  "car-garage": ["solid", "regular"],
  "landmark-magnifying-glass": ["solid", "regular"],
  "right-left": ["solid", "regular"],
  "arrow-down-z-a": ["solid", "regular"],
  "sort-down": ["solid", "regular"],
  "plus-large": ["solid", "regular"],
  "headphones-simple": ["solid", "regular"],
  "circle-caret-up": ["solid", "regular"],
  "hexagon-divide": ["solid", "regular"],
  "laptop-arrow-down": ["solid", "regular"],
  "austral-sign": ["solid", "regular"],
  clapperboard: ["solid", "regular"],
  hourglass: ["solid", "regular"],
  chess: ["solid", "regular"],
  "user-vneck-hair": ["solid", "regular"],
  "arrow-down-from-arc": ["solid", "regular"],
  "globe-stand": ["solid", "regular"],
  "cup-straw-swoosh": ["solid", "regular"],
  "book-arrow-right": ["solid", "regular"],
  "circle-sort-up": ["solid", "regular"],
  "flask-gear": ["solid", "regular"],
  "face-eyes-xmarks": ["solid", "regular"],
  "person-circle-check": ["solid", "regular"],
  "store-lock": ["solid", "regular"],
  "clock-three": ["solid", "regular"],
  "chf-sign": ["solid", "regular"],
  "earth-europe": ["solid", "regular"],
  "sd-card": ["solid", "regular"],
  j: ["solid", "regular"],
  "house-chimney-window": ["solid", "regular"],
  code: ["solid", "regular"],
  "bezier-curve": ["solid", "regular"],
  dungeon: ["solid", "regular"],
  "arrow-down-left-and-arrow-up-right-to-center": ["solid", "regular"],
  tire: ["solid", "regular"],
  browsers: ["solid", "regular"],
  "spaghetti-monster-flying": ["solid", "regular"],
  "square-phone-hangup": ["solid", "regular"],
  "temperature-snow": ["solid", "regular"],
  "user-tie-hair": ["solid", "regular"],
  "droplet-degree": ["solid", "regular"],
  "leaf-oak": ["solid", "regular"],
  "trash-clock": ["solid", "regular"],
  "bread-slice": ["solid", "regular"],
  "trash-can-arrow-up": ["solid", "regular"],
  "tachograph-digital": ["solid", "regular"],
  "hands-holding-heart": ["solid", "regular"],
  l: ["solid", "regular"],
  "id-badge": ["solid", "regular"],
  "folder-heart": ["solid", "regular"],
  butter: ["solid", "regular"],
  "route-interstate": ["solid", "regular"],
  "face-swear": ["solid", "regular"],
  car: ["solid", "regular"],
  "bell-school": ["solid", "regular"],
  "pen-ruler": ["solid", "regular"],
  "weight-scale": ["solid", "regular"],
  "truck-monster": ["solid", "regular"],
  "person-circle-exclamation": ["solid", "regular"],
  expand: ["solid", "regular"],
  "hryvnia-sign": ["solid", "regular"],
  mistletoe: ["solid", "regular"],
  "tree-palm": ["solid", "regular"],
  "water-arrow-down": ["solid", "regular"],
  "vector-circle": ["solid", "regular"],
  "magnifying-glass-play": ["solid", "regular"],
  "border-left": ["solid", "regular"],
  wind: ["solid", "regular"],
  "phone-hangup": ["solid", "regular"],
  bus: ["solid", "regular"],
  whistle: ["solid", "regular"],
  comma: ["solid", "regular"],
  monument: ["solid", "regular"],
  "car-building": ["solid", "regular"],
  "scroll-torah": ["solid", "regular"],
  "message-music": ["solid", "regular"],
  "grip-vertical": ["solid", "regular"],
  "sensor-triangle-exclamation": ["solid", "regular"],
  "battery-empty": ["solid", "regular"],
  "sun-bright": ["solid", "regular"],
  "face-diagonal-mouth": ["solid", "regular"],
  boombox: ["solid", "regular"],
  "face-explode": ["solid", "regular"],
  semicolon: ["solid", "regular"],
  "stopwatch-20": ["solid", "regular"],
  "won-sign": ["solid", "regular"],
  "arrow-down-short-wide": ["solid", "regular"],
  "drumstick-bite": ["solid", "regular"],
  "file-waveform": ["solid", "regular"],
  "building-ngo": ["solid", "regular"],
  "barcode-read": ["solid", "regular"],
  "comment-plus": ["solid", "regular"],
  "link-simple-slash": ["solid", "regular"],
  filter: ["solid", "regular"],
  "flask-round-poison": ["solid", "regular"],
  transgender: ["solid", "regular"],
  "bowling-ball": ["solid", "regular"],
  sensor: ["solid", "regular"],
  "martini-glass-citrus": ["solid", "regular"],
  gifts: ["solid", "regular"],
  "user-clock": ["solid", "regular"],
  pencil: ["solid", "regular"],
  "circle-dot": ["solid", "regular"],
  "face-cowboy-hat": ["solid", "regular"],
  "hose-reel": ["solid", "regular"],
  "cup-togo": ["solid", "regular"],
  trash: ["solid", "regular"],
  "house-flood-water-circle-arrow-right": ["solid", "regular"],
  "snow-blowing": ["solid", "regular"],
  "face-scream": ["solid", "regular"],
  "arrow-up-from-dotted-line": ["solid", "regular"],
  "nesting-dolls": ["solid", "regular"],
  users: ["solid", "regular"],
  "whiskey-glass": ["solid", "regular"],
  "sim-cards": ["solid", "regular"],
  pills: ["solid", "regular"],
  "arrow-down-small-big": ["solid", "regular"],
  feather: ["solid", "regular"],
  "user-graduate": ["solid", "regular"],
  "circle-quarter-stroke": ["solid", "regular"],
  "folder-arrow-down": ["solid", "regular"],
  "square-arrow-up-left": ["solid", "regular"],
  "gift-card": ["solid", "regular"],
  "person-circle-minus": ["solid", "regular"],
  "files-medical": ["solid", "regular"],
  "circle-book-open": ["solid", "regular"],
  kidneys: ["solid", "regular"],
  bacterium: ["solid", "regular"],
  wifi: ["solid", "regular"],
  "circle-euro": ["solid", "regular"],
  toothbrush: ["solid", "regular"],
  "book-journal-whills": ["solid", "regular"],
  "spider-web": ["solid", "regular"],
  "bottle-water": ["solid", "regular"],
  melon: ["solid", "regular"],
  "arrows-repeat-1": ["solid", "regular"],
  galaxy: ["solid", "regular"],
  avocado: ["solid", "regular"],
  "window-flip": ["solid", "regular"],
  "rectangle-history": ["solid", "regular"],
  chair: ["solid", "regular"],
  basketball: ["solid", "regular"],
  "hospital-user": ["solid", "regular"],
  "user-ninja": ["solid", "regular"],
  period: ["solid", "regular"],
  "angles-down": ["solid", "regular"],
  "hammer-brush": ["solid", "regular"],
  scalpel: ["solid", "regular"],
  voicemail: ["solid", "regular"],
  "meter-droplet": ["solid", "regular"],
  "skeleton-ribs": ["solid", "regular"],
  scissors: ["solid", "regular"],
  "house-flood-water": ["solid", "regular"],
  square: ["solid", "regular"],
  "bag-shopping": ["solid", "regular"],
  "square-dashed-circle-plus": ["solid", "regular"],
  "spinner-third": ["solid", "regular"],
  "table-rows": ["solid", "regular"],
  "fire-flame-curved": ["solid", "regular"],
  "square-a": ["solid", "regular"],
  "star-sharp-half-stroke": ["solid", "regular"],
  "up-long": ["solid", "regular"],
  "file-circle-check": ["solid", "regular"],
  memory: ["solid", "regular"],
  "virus-covid": ["solid", "regular"],
  "envelope-open": ["solid", "regular"],
  "kiwi-fruit": ["solid", "regular"],
  interrobang: ["solid", "regular"],
  "circle-f": ["solid", "regular"],
  "anchor-circle-xmark": ["solid", "regular"],
  "square-2": ["solid", "regular"],
  "person-military-rifle": ["solid", "regular"],
  hammer: ["solid", "regular"],
  "globe-pointer": ["solid", "regular"],
  "trash-slash": ["solid", "regular"],
  "face-lying": ["solid", "regular"],
  "comment-pen": ["solid", "regular"],
  "volume-off": ["solid", "regular"],
  "nfc-slash": ["solid", "regular"],
  "atom-simple": ["solid", "regular"],
  "location-pen": ["solid", "regular"],
  "lamp-desk": ["solid", "regular"],
  "square-r": ["solid", "regular"],
  "pipe-circle-check": ["solid", "regular"],
  outdent: ["solid", "regular"],
  "magnifying-glass-minus": ["solid", "regular"],
  "burger-glass": ["solid", "regular"],
  "anchor-circle-check": ["solid", "regular"],
  "heart-circle-bolt": ["solid", "regular"],
  "square-chevron-left": ["solid", "regular"],
  "signal-good": ["solid", "regular"],
  "clock-ten": ["solid", "regular"],
  "square-u": ["solid", "regular"],
  baby: ["solid", "regular"],
  "file-png": ["solid", "regular"],
  "spell-check": ["solid", "regular"],
  "clock-nine": ["solid", "regular"],
  "person-snowmobiling": ["solid", "regular"],
  "truck-fast": ["solid", "regular"],
  "car-tilt": ["solid", "regular"],
  "circle-x": ["solid", "regular"],
  "square-right": ["solid", "regular"],
  "leaf-heart": ["solid", "regular"],
  "circle-check": ["solid", "regular"],
  "cart-shopping": ["solid", "regular"],
  tilde: ["solid", "regular"],
  "flux-capacitor": ["solid", "regular"],
  "sliders-up": ["solid", "regular"],
  "circle-three-quarters": ["solid", "regular"],
  "bars-filter": ["solid", "regular"],
  pager: ["solid", "regular"],
  "person-falling-burst": ["solid", "regular"],
  "pen-nib": ["solid", "regular"],
  "box-heart": ["solid", "regular"],
  "vr-cardboard": ["solid", "regular"],
  pause: ["solid", "regular"],
  briefcase: ["solid", "regular"],
  "goal-net": ["solid", "regular"],
  "jack-o-lantern": ["solid", "regular"],
  crosshairs: ["solid", "regular"],
  sink: ["solid", "regular"],
  fork: ["solid", "regular"],
  "people-carry-box": ["solid", "regular"],
  "brackets-round": ["solid", "regular"],
  "clipboard-list": ["solid", "regular"],
  envelope: ["solid", "regular"],
  "fill-drip": ["solid", "regular"],
  "arrow-turn-up": ["solid", "regular"],
  "chess-clock-flip": ["solid", "regular"],
  headset: ["solid", "regular"],
  shelves: ["solid", "regular"],
  "message-quote": ["solid", "regular"],
  e: ["solid", "regular"],
  bath: ["solid", "regular"],
  "code-pull-request": ["solid", "regular"],
  "object-subtract": ["solid", "regular"],
  "angles-left": ["solid", "regular"],
  "pen-slash": ["solid", "regular"],
  "handshake-simple": ["solid", "regular"],
  "list-ul": ["solid", "regular"],
  "house-blank": ["solid", "regular"],
  "camera-web-slash": ["solid", "regular"],
  union: ["solid", "regular"],
  "closed-captioning-slash": ["solid", "regular"],
  "sign-posts": ["solid", "regular"],
  "warehouse-full": ["solid", "regular"],
  "down-left-and-up-right-to-center": ["solid", "regular"],
  "face-dotted": ["solid", "regular"],
  "cart-flatbed-suitcase": ["solid", "regular"],
  "money-bill-wave": ["solid", "regular"],
  "person-shelter": ["solid", "regular"],
  wreath: ["solid", "regular"],
  "circle-caret-left": ["solid", "regular"],
  "person-through-window": ["solid", "regular"],
  "moon-over-sun": ["solid", "regular"],
  "yin-yang": ["solid", "regular"],
  "ice-skate": ["solid", "regular"],
  "toilet-paper-under": ["solid", "regular"],
  "golf-ball-tee": ["solid", "regular"],
  "rectangles-mixed": ["solid", "regular"],
  "engine-warning": ["solid", "regular"],
  "face-anxious-sweat": ["solid", "regular"],
  "dryer-heat": ["solid", "regular"],
  "octagon-xmark": ["solid", "regular"],
  jedi: ["solid", "regular"],
  "head-side": ["solid", "regular"],
  "file-arrow-down": ["solid", "regular"],
  "less-than": ["solid", "regular"],
  "mailbox-flag-up": ["solid", "regular"],
  "envelope-open-text": ["solid", "regular"],
  "person-pinball": ["solid", "regular"],
  cannabis: ["solid", "regular"],
  sigma: ["solid", "regular"],
  "right-long-to-line": ["solid", "regular"],
  "objects-align-top": ["solid", "regular"],
  "file-exclamation": ["solid", "regular"],
  "mobile-button": ["solid", "regular"],
  "street-view": ["solid", "regular"],
  "sliders-simple": ["solid", "regular"],
  "square-dollar": ["solid", "regular"],
  "lock-keyhole-open": ["solid", "regular"],
  "pen-paintbrush": ["solid", "regular"],
  "rectangle-barcode": ["solid", "regular"],
  "toilets-portable": ["solid", "regular"],
  "rectangle-pro": ["solid", "regular"],
  "object-group": ["solid", "regular"],
  "face-expressionless": ["solid", "regular"],
  "circle-plus": ["solid", "regular"],
  function: ["solid", "regular"],
  "layer-group": ["solid", "regular"],
  "mobile-signal": ["solid", "regular"],
  n: ["solid", "regular"],
  "volume-high": ["solid", "regular"],
  rss: ["solid", "regular"],
  eyes: ["solid", "regular"],
  "ellipsis-vertical": ["solid", "regular"],
  "tablet-button": ["solid", "regular"],
  "plug-circle-minus": ["solid", "regular"],
  "bus-school": ["solid", "regular"],
  "shield-cross": ["solid", "regular"],
  "bracket-round": ["solid", "regular"],
  "square-full": ["solid", "regular"],
  "circle-chevron-left": ["solid", "regular"],
  scroll: ["solid", "regular"],
  "face-meh-blank": ["solid", "regular"],
  "user-unlock": ["solid", "regular"],
  plus: ["solid", "regular"],
  "coin-blank": ["solid", "regular"],
  "melon-slice": ["solid", "regular"],
  "guitar-electric": ["solid", "regular"],
  "folder-music": ["solid", "regular"],
  "down-left": ["solid", "regular"],
  "brain-circuit": ["solid", "regular"],
  "battery-full": ["solid", "regular"],
  capsules: ["solid", "regular"],
  "file-signature": ["solid", "regular"],
  braille: ["solid", "regular"],
  tent: ["solid", "regular"],
  "folder-image": ["solid", "regular"],
  "coffee-beans": ["solid", "regular"],
  "square-chevron-up": ["solid", "regular"],
  "traffic-cone": ["solid", "regular"],
  "wreath-laurel": ["solid", "regular"],
  "user-group-crown": ["solid", "regular"],
  peanuts: ["solid", "regular"],
  "tricycle-adult": ["solid", "regular"],
  "mug-tea-saucer": ["solid", "regular"],
  "user-hair-long": ["solid", "regular"],
  "trash-list": ["solid", "regular"],
  "magnifying-glass-music": ["solid", "regular"],
  children: ["solid", "regular"],
  brain: ["solid", "regular"],
  gift: ["solid", "regular"],
  dinosaur: ["solid", "regular"],
  "hat-winter": ["solid", "regular"],
  stethoscope: ["solid", "regular"],
  "user-pen": ["solid", "regular"],
  "diagram-lean-canvas": ["solid", "regular"],
  "draw-square": ["solid", "regular"],
  "file-plus-minus": ["solid", "regular"],
  "calendar-days": ["solid", "regular"],
  "wand-magic": ["solid", "regular"],
  "basket-shopping-minus": ["solid", "regular"],
  "chart-scatter-3d": ["solid", "regular"],
  "square-root-variable": ["solid", "regular"],
  "square-code": ["solid", "regular"],
  snowflake: ["solid", "regular"],
  "border-right": ["solid", "regular"],
  engine: ["solid", "regular"],
  atom: ["solid", "regular"],
  "square-caret-down": ["solid", "regular"],
  "share-all": ["solid", "regular"],
  "euro-sign": ["solid", "regular"],
  "basket-shopping-simple": ["solid", "regular"],
  "child-dress": ["solid", "regular"],
  shuttlecock: ["solid", "regular"],
  "car-side-bolt": ["solid", "regular"],
  "house-chimney-heart": ["solid", "regular"],
  "helmet-battle": ["solid", "regular"],
  "bowl-rice": ["solid", "regular"],
  ram: ["solid", "regular"],
  medal: ["solid", "regular"],
  "vector-square": ["solid", "regular"],
  "transporter-2": ["solid", "regular"],
  "diagram-project": ["solid", "regular"],
  "plane-slash": ["solid", "regular"],
  frame: ["solid", "regular"],
  "dial-off": ["solid", "regular"],
  "file-powerpoint": ["solid", "regular"],
  "hand-spock": ["solid", "regular"],
  "circle-stop": ["solid", "regular"],
  "left-from-bracket": ["solid", "regular"],
  h5: ["solid", "regular"],
  "sword-laser": ["solid", "regular"],
  "circle-parking": ["solid", "regular"],
  "circle-wifi-circle-wifi": ["solid", "regular"],
  key: ["solid", "regular"],
  "camera-retro": ["solid", "regular"],
  "hand-point-left": ["solid", "regular"],
  "school-circle-xmark": ["solid", "regular"],
  "arrow-up-square-triangle": ["solid", "regular"],
  "person-dolly": ["solid", "regular"],
  "circle-z": ["solid", "regular"],
  "file-jpg": ["solid", "regular"],
  "image-polaroid": ["solid", "regular"],
  "comment-arrow-up": ["solid", "regular"],
  "house-chimney-medical": ["solid", "regular"],
  poo: ["solid", "regular"],
  "soft-serve": ["solid", "regular"],
  "person-running": ["solid", "regular"],
  house: ["solid", "regular"],
  "burger-cheese": ["solid", "regular"],
  bong: ["solid", "regular"],
  "circle-sort-down": ["solid", "regular"],
  reel: ["solid", "regular"],
  "book-sparkles": ["solid", "regular"],
  "plane-arrival": ["solid", "regular"],
  "person-running-fast": ["solid", "regular"],
  "person-harassing": ["solid", "regular"],
  "building-shield": ["solid", "regular"],
  "gun-squirt": ["solid", "regular"],
  "ban-parking": ["solid", "regular"],
  hands: ["solid", "regular"],
  filters: ["solid", "regular"],
  "fish-fins": ["solid", "regular"],
  "user-group": ["solid", "regular"],
  "arrow-up-right-from-square": ["solid", "regular"],
  "clock-eight-thirty": ["solid", "regular"],
  info: ["solid", "regular"],
  "book-section": ["solid", "regular"],
  "square-small": ["solid", "regular"],
  sun: ["solid", "regular"],
  lobster: ["solid", "regular"],
  c: ["solid", "regular"],
  "comment-sms": ["solid", "regular"],
  "pot-food": ["solid", "regular"],
  comet: ["solid", "regular"],
  "face-worried": ["solid", "regular"],
  industry: ["solid", "regular"],
  tape: ["solid", "regular"],
  "network-wired": ["solid", "regular"],
  "face-thermometer": ["solid", "regular"],
  "hat-chef": ["solid", "regular"],
  "cloud-bolt-sun": ["solid", "regular"],
  "bookmark-slash": ["solid", "regular"],
  piano: ["solid", "regular"],
  "user-minus": ["solid", "regular"],
  "face-hand-peeking": ["solid", "regular"],
  keyboard: ["solid", "regular"],
  "paintbrush-fine": ["solid", "regular"],
  "torii-gate": ["solid", "regular"],
  "person-dress-simple": ["solid", "regular"],
  jug: ["solid", "regular"],
  "value-absolute": ["solid", "regular"],
  "hand-horns": ["solid", "regular"],
  eject: ["solid", "regular"],
  "arrow-up-from-arc": ["solid", "regular"],
  "video-arrow-down-left": ["solid", "regular"],
  "album-collection": ["solid", "regular"],
  "bowl-food": ["solid", "regular"],
  "plate-utensils": ["solid", "regular"],
  "user-slash": ["solid", "regular"],
  vihara: ["solid", "regular"],
  cherries: ["solid", "regular"],
  "music-note": ["solid", "regular"],
  "road-bridge": ["solid", "regular"],
  "people-group": ["solid", "regular"],
  "mill-sign": ["solid", "regular"],
  "truck-droplet": ["solid", "regular"],
  "landmark-dome": ["solid", "regular"],
  "circle-divide": ["solid", "regular"],
  "square-t": ["solid", "regular"],
  "shield-cat": ["solid", "regular"],
  "dumpster-fire": ["solid", "regular"],
  "buoy-mooring": ["solid", "regular"],
  eye: ["solid", "regular"],
  gears: ["solid", "regular"],
  "circle-quarters": ["solid", "regular"],
  boot: ["solid", "regular"],
  "bicycle-stacked-red-lockup-rgb": ["custom"],
  "bicycle-symbol-red-rgb": ["custom"],
  "bicycle-inline-red-lockup-rgb": ["custom"],
  "bicycle-logotype-blue-rgb": ["custom"],
  mixer: ["brands"],
  node: ["brands"],
  waze: ["brands"],
  jenkins: ["brands"],
  "google-scholar": ["brands"],
  weixin: ["brands"],
  "cc-discover": ["brands"],
  "42-group": ["brands"],
  gitlab: ["brands"],
  figma: ["brands"],
  "wordpress-simple": ["brands"],
  "square-tumblr": ["brands"],
  squarespace: ["brands"],
  "watchman-monitoring": ["brands"],
  "app-store": ["brands"],
  "octopus-deploy": ["brands"],
  airbnb: ["brands"],
  dyalog: ["brands"],
  joomla: ["brands"],
  slack: ["brands"],
  linux: ["brands"],
  firstdraft: ["brands"],
  "square-font-awesome": ["brands"],
  diaspora: ["brands"],
  "square-steam": ["brands"],
  "creative-commons-pd-alt": ["brands"],
  "stack-exchange": ["brands"],
  deezer: ["brands"],
  "google-wallet": ["brands"],
  "git-alt": ["brands"],
  "space-awesome": ["brands"],
  java: ["brands"],
  git: ["brands"],
  opensuse: ["brands"],
  tumblr: ["brands"],
  wordpress: ["brands"],
  jira: ["brands"],
  dhl: ["brands"],
  "square-lastfm": ["brands"],
  hips: ["brands"],
  wpressr: ["brands"],
  "x-twitter": ["brands"],
  "itch-io": ["brands"],
  slideshare: ["brands"],
  "d-and-d": ["brands"],
  mandalorian: ["brands"],
  "black-tie": ["brands"],
  "creative-commons-sampling-plus": ["brands"],
  "canadian-maple-leaf": ["brands"],
  intercom: ["brands"],
  staylinked: ["brands"],
  shopware: ["brands"],
  "stack-overflow": ["brands"],
  "fort-awesome-alt": ["brands"],
  hashnode: ["brands"],
  "square-pied-piper": ["brands"],
  "square-web-awesome-stroke": ["brands"],
  weebly: ["brands"],
  "edge-legacy": ["brands"],
  "hacker-news": ["brands"],
  firefox: ["brands"],
  yarn: ["brands"],
  letterboxd: ["brands"],
  threads: ["brands"],
  ioxhost: ["brands"],
  envira: ["brands"],
  alipay: ["brands"],
  "creative-commons-nc-jp": ["brands"],
  digg: ["brands"],
  readme: ["brands"],
  "creative-commons-nc-eu": ["brands"],
  deskpro: ["brands"],
  "y-combinator": ["brands"],
  google: ["brands"],
  playstation: ["brands"],
  gratipay: ["brands"],
  "bluetooth-b": ["brands"],
  twitch: ["brands"],
  houzz: ["brands"],
  qq: ["brands"],
  aviato: ["brands"],
  sitrox: ["brands"],
  docker: ["brands"],
  "yandex-international": ["brands"],
  pushed: ["brands"],
  keybase: ["brands"],
  "gg-circle": ["brands"],
  hive: ["brands"],
  studiovinari: ["brands"],
  forumbee: ["brands"],
  js: ["brands"],
  ubuntu: ["brands"],
  shopify: ["brands"],
  fedex: ["brands"],
  tiktok: ["brands"],
  wirsindhandwerk: ["brands"],
  jsfiddle: ["brands"],
  "square-letterboxd": ["brands"],
  ideal: ["brands"],
  avianex: ["brands"],
  grav: ["brands"],
  "wizards-of-the-coast": ["brands"],
  bity: ["brands"],
  "creative-commons-sa": ["brands"],
  cuttlefish: ["brands"],
  "github-alt": ["brands"],
  viber: ["brands"],
  opera: ["brands"],
  "creative-commons-nd": ["brands"],
  "pied-piper": ["brands"],
  "pied-piper-pp": ["brands"],
  etsy: ["brands"],
  viadeo: ["brands"],
  "creative-commons-zero": ["brands"],
  hubspot: ["brands"],
  buffer: ["brands"],
  phabricator: ["brands"],
  mizuni: ["brands"],
  hotjar: ["brands"],
  researchgate: ["brands"],
  "google-drive": ["brands"],
  patreon: ["brands"],
  mdb: ["brands"],
  "apple-pay": ["brands"],
  contao: ["brands"],
  leanpub: ["brands"],
  "pied-piper-alt": ["brands"],
  mendeley: ["brands"],
  nutritionix: ["brands"],
  android: ["brands"],
  "amazon-pay": ["brands"],
  autoprefixer: ["brands"],
  kaggle: ["brands"],
  angrycreative: ["brands"],
  dropbox: ["brands"],
  "trade-federation": ["brands"],
  korvue: ["brands"],
  python: ["brands"],
  shirtsinbulk: ["brands"],
  "square-dribbble": ["brands"],
  mailchimp: ["brands"],
  "cc-jcb": ["brands"],
  angular: ["brands"],
  "itunes-note": ["brands"],
  cloudsmith: ["brands"],
  bitbucket: ["brands"],
  ethereum: ["brands"],
  unsplash: ["brands"],
  pagelines: ["brands"],
  itunes: ["brands"],
  wix: ["brands"],
  perbyte: ["brands"],
  "500px": ["brands"],
  swift: ["brands"],
  cloudversify: ["brands"],
  "square-viadeo": ["brands"],
  apper: ["brands"],
  "galactic-senate": ["brands"],
  algolia: ["brands"],
  "phoenix-squadron": ["brands"],
  trello: ["brands"],
  deviantart: ["brands"],
  bots: ["brands"],
  "kickstarter-k": ["brands"],
  "optin-monster": ["brands"],
  stumbleupon: ["brands"],
  soundcloud: ["brands"],
  "creative-commons-remix": ["brands"],
  schlix: ["brands"],
  meetup: ["brands"],
  "square-hacker-news": ["brands"],
  amilia: ["brands"],
  aws: ["brands"],
  yammer: ["brands"],
  mastodon: ["brands"],
  "cc-apple-pay": ["brands"],
  gofore: ["brands"],
  "linkedin-in": ["brands"],
  "tencent-weibo": ["brands"],
  golang: ["brands"],
  bootstrap: ["brands"],
  pix: ["brands"],
  "signal-messenger": ["brands"],
  github: ["brands"],
  medrt: ["brands"],
  dashcube: ["brands"],
  opencart: ["brands"],
  "think-peaks": ["brands"],
  reddit: ["brands"],
  gitter: ["brands"],
  chrome: ["brands"],
  untappd: ["brands"],
  "fantasy-flight-games": ["brands"],
  whatsapp: ["brands"],
  salesforce: ["brands"],
  usb: ["brands"],
  ns8: ["brands"],
  bluesky: ["brands"],
  vuejs: ["brands"],
  quinscape: ["brands"],
  renren: ["brands"],
  freebsd: ["brands"],
  "hire-a-helper": ["brands"],
  rebel: ["brands"],
  whmcs: ["brands"],
  rev: ["brands"],
  jxl: ["brands"],
  spotify: ["brands"],
  css3: ["brands"],
  redhat: ["brands"],
  wpexplorer: ["brands"],
  atlassian: ["brands"],
  steam: ["brands"],
  centos: ["brands"],
  windows: ["brands"],
  periscope: ["brands"],
  apple: ["brands"],
  "square-snapchat": ["brands"],
  usps: ["brands"],
  "google-pay": ["brands"],
  "buy-n-large": ["brands"],
  speakap: ["brands"],
  "creative-commons-nc": ["brands"],
  "red-river": ["brands"],
  stripe: ["brands"],
  cmplid: ["brands"],
  "app-store-ios": ["brands"],
  youtube: ["brands"],
  upwork: ["brands"],
  odnoklassniki: ["brands"],
  webflow: ["brands"],
  telegram: ["brands"],
  "square-git": ["brands"],
  instalod: ["brands"],
  "raspberry-pi": ["brands"],
  ups: ["brands"],
  audible: ["brands"],
  codiepie: ["brands"],
  less: ["brands"],
  scribd: ["brands"],
  safari: ["brands"],
  monero: ["brands"],
  facebook: ["brands"],
  sketch: ["brands"],
  "digital-ocean": ["brands"],
  "square-facebook": ["brands"],
  line: ["brands"],
  "square-threads": ["brands"],
  "r-project": ["brands"],
  "battle-net": ["brands"],
  umbraco: ["brands"],
  php: ["brands"],
  lyft: ["brands"],
  pixiv: ["brands"],
  unity: ["brands"],
  joget: ["brands"],
  ussunnah: ["brands"],
  sellsy: ["brands"],
  fonticons: ["brands"],
  viacoin: ["brands"],
  openid: ["brands"],
  hooli: ["brands"],
  behance: ["brands"],
  orcid: ["brands"],
  invision: ["brands"],
  "square-google-plus": ["brands"],
  gripfire: ["brands"],
  "phoenix-framework": ["brands"],
  rust: ["brands"],
  supple: ["brands"],
  discourse: ["brands"],
  napster: ["brands"],
  xing: ["brands"],
  "vimeo-v": ["brands"],
  "cc-mastercard": ["brands"],
  "square-vimeo": ["brands"],
  snapchat: ["brands"],
  modx: ["brands"],
  "first-order-alt": ["brands"],
  "old-republic": ["brands"],
  "google-plus-g": ["brands"],
  wpforms: ["brands"],
  expeditedssl: ["brands"],
  "stripe-s": ["brands"],
  maxcdn: ["brands"],
  adn: ["brands"],
  "the-red-yeti": ["brands"],
  "pinterest-p": ["brands"],
  "wolf-pack-battalion": ["brands"],
  sith: ["brands"],
  yahoo: ["brands"],
  evernote: ["brands"],
  "jedi-order": ["brands"],
  medapps: ["brands"],
  zhihu: ["brands"],
  padlet: ["brands"],
  page4: ["brands"],
  accusoft: ["brands"],
  linkedin: ["brands"],
  ember: ["brands"],
  paypal: ["brands"],
  gg: ["brands"],
  "critical-role": ["brands"],
  buysellads: ["brands"],
  delicious: ["brands"],
  dochub: ["brands"],
  fedora: ["brands"],
  "square-font-awesome-stroke": ["brands"],
  codepen: ["brands"],
  sourcetree: ["brands"],
  grunt: ["brands"],
  dev: ["brands"],
  skype: ["brands"],
  affiliatetheme: ["brands"],
  microblog: ["brands"],
  brave: ["brands"],
  amazon: ["brands"],
  blogger: ["brands"],
  "square-upwork": ["brands"],
  pinterest: ["brands"],
  stubber: ["brands"],
  bimobject: ["brands"],
  discord: ["brands"],
  "creative-commons-by": ["brands"],
  "sticker-mule": ["brands"],
  fly: ["brands"],
  medium: ["brands"],
  instagram: ["brands"],
  kickstarter: ["brands"],
  "product-hunt": ["brands"],
  "square-instagram": ["brands"],
  "square-twitter": ["brands"],
  confluence: ["brands"],
  screenpal: ["brands"],
  "square-x-twitter": ["brands"],
  reacteurope: ["brands"],
  gulp: ["brands"],
  "fonticons-fi": ["brands"],
  yelp: ["brands"],
  flipboard: ["brands"],
  simplybuilt: ["brands"],
  deploydog: ["brands"],
  "cc-stripe": ["brands"],
  "free-code-camp": ["brands"],
  "square-whatsapp": ["brands"],
  "stumbleupon-circle": ["brands"],
  lastfm: ["brands"],
  quora: ["brands"],
  meta: ["brands"],
  npm: ["brands"],
  "cc-paypal": ["brands"],
  mixcloud: ["brands"],
  "cc-diners-club": ["brands"],
  searchengin: ["brands"],
  fulcrum: ["brands"],
  artstation: ["brands"],
  "brave-reverse": ["brands"],
  connectdevelop: ["brands"],
  blackberry: ["brands"],
  stackpath: ["brands"],
  elementor: ["brands"],
  themeisle: ["brands"],
  resolving: ["brands"],
  "cc-amazon-pay": ["brands"],
  skyatlas: ["brands"],
  yandex: ["brands"],
  dailymotion: ["brands"],
  imdb: ["brands"],
  weibo: ["brands"],
  laravel: ["brands"],
  ebay: ["brands"],
  glide: ["brands"],
  "first-order": ["brands"],
  "square-web-awesome": ["brands"],
  servicestack: ["brands"],
  flickr: ["brands"],
  gitkraken: ["brands"],
  "wikipedia-w": ["brands"],
  edge: ["brands"],
  "google-plus": ["brands"],
  debian: ["brands"],
  "square-youtube": ["brands"],
  "nfc-directional": ["brands"],
  "accessible-icon": ["brands"],
  centercode: ["brands"],
  mintbit: ["brands"],
  magento: ["brands"],
  earlybirds: ["brands"],
  twitter: ["brands"],
  bitcoin: ["brands"],
  erlang: ["brands"],
  wpbeginner: ["brands"],
  osi: ["brands"],
  cpanel: ["brands"],
  themeco: ["brands"],
  "square-github": ["brands"],
  dribbble: ["brands"],
  "glide-g": ["brands"],
  shoelace: ["brands"],
  "facebook-f": ["brands"],
  odysee: ["brands"],
  replyd: ["brands"],
  uniregistry: ["brands"],
  "square-js": ["brands"],
  hornbill: ["brands"],
  "firefox-browser": ["brands"],
  sass: ["brands"],
  cloudflare: ["brands"],
  btc: ["brands"],
  wodu: ["brands"],
  "steam-symbol": ["brands"],
  rockrms: ["brands"],
  "goodreads-g": ["brands"],
  vaadin: ["brands"],
  nimblr: ["brands"],
  "speaker-deck": ["brands"],
  "cc-amex": ["brands"],
  "cotton-bureau": ["brands"],
  bandcamp: ["brands"],
  mix: ["brands"],
  "blogger-b": ["brands"],
  cloudscale: ["brands"],
  draft2digital: ["brands"],
  superpowers: ["brands"],
  markdown: ["brands"],
  uikit: ["brands"],
  sistrix: ["brands"],
  "galactic-republic": ["brands"],
  "square-pinterest": ["brands"],
  "square-reddit": ["brands"],
  "css3-alt": ["brands"],
  uncharted: ["brands"],
  "pied-piper-hat": ["brands"],
  "creative-commons-sampling": ["brands"],
  "creative-commons-pd": ["brands"],
  empire: ["brands"],
  keycdn: ["brands"],
  "node-js": ["brands"],
  "creative-commons": ["brands"],
  guilded: ["brands"],
  "creative-commons-share": ["brands"],
  "google-play": ["brands"],
  "fort-awesome": ["brands"],
  strava: ["brands"],
  linode: ["brands"],
  chromecast: ["brands"],
  adversal: ["brands"],
  "facebook-messenger": ["brands"],
  bilibili: ["brands"],
  palfed: ["brands"],
  xbox: ["brands"],
  foursquare: ["brands"],
  hackerrank: ["brands"],
  react: ["brands"],
  "square-xing": ["brands"],
  "cc-visa": ["brands"],
  rocketchat: ["brands"],
  microsoft: ["brands"],
  drupal: ["brands"],
  sellcast: ["brands"],
  vimeo: ["brands"],
  vnv: ["brands"],
  angellist: ["brands"],
  vine: ["brands"],
  asymmetrik: ["brands"],
  "reddit-alien": ["brands"],
  teamspeak: ["brands"],
  "square-odnoklassniki": ["brands"],
  "internet-explorer": ["brands"],
  suse: ["brands"],
  symfony: ["brands"],
  "square-gitlab": ["brands"],
  vk: ["brands"],
  "get-pocket": ["brands"],
  "square-behance": ["brands"],
  neos: ["brands"],
  ravelry: ["brands"],
  buromobelexperte: ["brands"],
  html5: ["brands"],
  uber: ["brands"],
  goodreads: ["brands"],
  ello: ["brands"],
  "d-and-d-beyond": ["brands"],
  typo3: ["brands"],
  yoast: ["brands"],
  megaport: ["brands"]
};

const iconToViewBoxMap = {
  "0": "0 0 320 512",
  "1": "0 0 256 512",
  "2": "0 0 320 512",
  "3": "0 0 320 512",
  "4": "0 0 384 512",
  "5": "0 0 320 512",
  "6": "0 0 320 512",
  "7": "0 0 320 512",
  "8": "0 0 320 512",
  "9": "0 0 320 512",
  "fill-drip": "0 0 576 512",
  "arrows-to-circle": "0 0 640 512",
  "circle-chevron-right": "0 0 512 512",
  "wagon-covered": "0 0 640 512",
  "line-height": "0 0 576 512",
  bagel: "0 0 640 512",
  "transporter-7": "0 0 512 512",
  at: "0 0 512 512",
  "rectangles-mixed": "0 0 576 512",
  "phone-arrow-up-right": "0 0 512 512",
  "trash-can": "0 0 448 512",
  "circle-l": "0 0 512 512",
  "head-side-goggles": "0 0 512 512",
  "text-height": "0 0 576 512",
  "user-xmark": "0 0 640 512",
  "face-hand-yawn": "0 0 512 512",
  "gauge-simple-min": "0 0 512 512",
  stethoscope: "0 0 576 512",
  coffin: "0 0 384 512",
  message: "0 0 512 512",
  salad: "0 0 512 512",
  info: "0 0 192 512",
  "robot-astromech": "0 0 512 512",
  "ring-diamond": "0 0 384 512",
  "fondue-pot": "0 0 512 512",
  theta: "0 0 384 512",
  "face-hand-peeking": "0 0 640 512",
  "square-user": "0 0 448 512",
  "down-left-and-up-right-to-center": "0 0 512 512",
  explosion: "0 0 576 512",
  "file-lines": "0 0 384 512",
  "wave-square": "0 0 640 512",
  ring: "0 0 512 512",
  "building-un": "0 0 384 512",
  "dice-three": "0 0 448 512",
  "tire-pressure-warning": "0 0 512 512",
  "wifi-fair": "0 0 640 512",
  "calendar-days": "0 0 448 512",
  "mp3-player": "0 0 384 512",
  "anchor-circle-check": "0 0 640 512",
  "tally-4": "0 0 640 512",
  "rectangle-history": "0 0 512 512",
  "building-circle-arrow-right": "0 0 640 512",
  volleyball: "0 0 512 512",
  "sun-haze": "0 0 640 512",
  "text-size": "0 0 640 512",
  ufo: "0 0 640 512",
  fork: "0 0 512 512",
  "arrows-up-to-line": "0 0 576 512",
  "mobile-signal": "0 0 512 512",
  "barcode-scan": "0 0 640 512",
  "sort-down": "0 0 320 512",
  "folder-arrow-down": "0 0 512 512",
  "circle-minus": "0 0 512 512",
  "face-icicles": "0 0 512 512",
  shovel: "0 0 512 512",
  "door-open": "0 0 576 512",
  films: "0 0 576 512",
  "right-from-bracket": "0 0 512 512",
  "face-glasses": "0 0 512 512",
  nfc: "0 0 448 512",
  atom: "0 0 512 512",
  soap: "0 0 512 512",
  icons: "0 0 512 512",
  "microphone-lines-slash": "0 0 640 512",
  "closed-captioning-slash": "0 0 640 512",
  "calculator-simple": "0 0 512 512",
  "bridge-circle-check": "0 0 640 512",
  "sliders-up": "0 0 512 512",
  "location-minus": "0 0 384 512",
  "pump-medical": "0 0 448 512",
  fingerprint: "0 0 512 512",
  "ski-boot": "0 0 512 512",
  "standard-definition": "0 0 576 512",
  h1: "0 0 576 512",
  "hand-point-right": "0 0 512 512",
  "magnifying-glass-location": "0 0 512 512",
  "message-bot": "0 0 640 512",
  "forward-step": "0 0 320 512",
  "face-smile-beam": "0 0 512 512",
  "light-ceiling": "0 0 512 512",
  "message-exclamation": "0 0 512 512",
  "bowl-scoop": "0 0 448 512",
  "square-x": "0 0 448 512",
  "building-memo": "0 0 640 512",
  "utility-pole-double": "0 0 512 512",
  "flag-checkered": "0 0 448 512",
  "chevrons-up": "0 0 512 512",
  football: "0 0 512 512",
  "user-vneck": "0 0 448 512",
  "school-circle-exclamation": "0 0 640 512",
  crop: "0 0 512 512",
  "angles-down": "0 0 448 512",
  "users-rectangle": "0 0 640 512",
  "people-roof": "0 0 640 512",
  "square-arrow-right": "0 0 448 512",
  "location-plus": "0 0 384 512",
  "lightbulb-exclamation-on": "0 0 640 512",
  "people-line": "0 0 640 512",
  "beer-mug-empty": "0 0 512 512",
  "crate-empty": "0 0 512 512",
  "diagram-predecessor": "0 0 512 512",
  transporter: "0 0 512 512",
  "calendar-circle-user": "0 0 576 512",
  "arrow-up-long": "0 0 384 512",
  "person-carry-box": "0 0 384 512",
  "fire-flame-simple": "0 0 384 512",
  person: "0 0 320 512",
  laptop: "0 0 640 512",
  "file-csv": "0 0 512 512",
  menorah: "0 0 640 512",
  union: "0 0 384 512",
  "chevrons-left": "0 0 512 512",
  "circle-heart": "0 0 512 512",
  "truck-plane": "0 0 640 512",
  "record-vinyl": "0 0 512 512",
  "bring-forward": "0 0 512 512",
  "square-p": "0 0 448 512",
  "face-grin-stars": "0 0 512 512",
  sigma: "0 0 384 512",
  "camera-movie": "0 0 576 512",
  bong: "0 0 448 512",
  clarinet: "0 0 640 512",
  "truck-flatbed": "0 0 640 512",
  "spaghetti-monster-flying": "0 0 640 512",
  "arrow-down-up-across-line": "0 0 576 512",
  "arrows-rotate-reverse": "0 0 512 512",
  "leaf-heart": "0 0 512 512",
  "house-building": "0 0 640 512",
  "cheese-swiss": "0 0 512 512",
  spoon: "0 0 512 512",
  "jar-wheat": "0 0 320 512",
  "envelopes-bulk": "0 0 640 512",
  "file-circle-exclamation": "0 0 576 512",
  "bow-arrow": "0 0 512 512",
  "cart-xmark": "0 0 576 512",
  "hexagon-xmark": "0 0 512 512",
  "circle-h": "0 0 512 512",
  merge: "0 0 512 512",
  pager: "0 0 512 512",
  "cart-minus": "0 0 576 512",
  "address-book": "0 0 512 512",
  "pan-frying": "0 0 576 512",
  grid: "0 0 448 512",
  "football-helmet": "0 0 512 512",
  "hand-love": "0 0 512 512",
  trees: "0 0 640 512",
  strikethrough: "0 0 512 512",
  page: "0 0 384 512",
  k: "0 0 320 512",
  "diagram-previous": "0 0 512 512",
  "gauge-min": "0 0 512 512",
  "folder-grid": "0 0 512 512",
  eggplant: "0 0 512 512",
  excavator: "0 0 640 512",
  ram: "0 0 640 512",
  "landmark-flag": "0 0 512 512",
  lips: "0 0 576 512",
  pencil: "0 0 512 512",
  backward: "0 0 512 512",
  "caret-right": "0 0 256 512",
  comments: "0 0 640 512",
  paste: "0 0 512 512",
  "desktop-arrow-down": "0 0 576 512",
  "code-pull-request": "0 0 512 512",
  pumpkin: "0 0 576 512",
  "clipboard-list": "0 0 384 512",
  "pen-field": "0 0 640 512",
  blueberries: "0 0 512 512",
  "truck-ramp-box": "0 0 640 512",
  note: "0 0 448 512",
  "arrow-down-to-square": "0 0 512 512",
  "user-check": "0 0 640 512",
  "cloud-xmark": "0 0 640 512",
  "vial-virus": "0 0 512 512",
  "book-blank": "0 0 448 512",
  "golf-flag-hole": "0 0 512 512",
  "message-arrow-down": "0 0 512 512",
  "face-unamused": "0 0 512 512",
  "sheet-plastic": "0 0 384 512",
  "circle-9": "0 0 512 512",
  blog: "0 0 512 512",
  "user-ninja": "0 0 448 512",
  "pencil-slash": "0 0 640 512",
  "bowling-pins": "0 0 512 512",
  "person-arrow-up-from-line": "0 0 640 512",
  "down-right": "0 0 384 512",
  "scroll-torah": "0 0 640 512",
  webhook: "0 0 576 512",
  "blinds-open": "0 0 512 512",
  fence: "0 0 512 512",
  up: "0 0 384 512",
  "broom-ball": "0 0 576 512",
  drumstick: "0 0 512 512",
  "square-v": "0 0 448 512",
  "face-awesome": "0 0 512 512",
  "dial-off": "0 0 576 512",
  "toggle-off": "0 0 576 512",
  "face-smile-horns": "0 0 640 512",
  "box-archive": "0 0 512 512",
  grapes: "0 0 512 512",
  "person-drowning": "0 0 576 512",
  "dial-max": "0 0 576 512",
  "circle-m": "0 0 512 512",
  "calendar-image": "0 0 448 512",
  "circle-caret-down": "0 0 512 512",
  "arrow-down-9-1": "0 0 576 512",
  "face-grin-tongue-squint": "0 0 512 512",
  "shish-kebab": "0 0 512 512",
  "spray-can": "0 0 512 512",
  "alarm-snooze": "0 0 512 512",
  scarecrow: "0 0 448 512",
  "truck-monster": "0 0 640 512",
  "gift-card": "0 0 576 512",
  w: "0 0 576 512",
  "code-pull-request-draft": "0 0 448 512",
  "square-b": "0 0 448 512",
  elephant: "0 0 640 512",
  "earth-africa": "0 0 512 512",
  rainbow: "0 0 640 512",
  "circle-notch": "0 0 512 512",
  "tablet-screen-button": "0 0 448 512",
  paw: "0 0 512 512",
  "message-question": "0 0 512 512",
  cloud: "0 0 640 512",
  "trowel-bricks": "0 0 512 512",
  "square-3": "0 0 448 512",
  "face-flushed": "0 0 512 512",
  "hospital-user": "0 0 576 512",
  microwave: "0 0 576 512",
  "chf-sign": "0 0 640 512",
  "tent-arrow-left-right": "0 0 576 512",
  "cart-circle-arrow-up": "0 0 640 512",
  "trash-clock": "0 0 576 512",
  "reflect-both": "0 0 512 512",
  gavel: "0 0 512 512",
  "sprinkler-ceiling": "0 0 384 512",
  browsers: "0 0 576 512",
  trillium: "0 0 448 512",
  "music-slash": "0 0 640 512",
  "truck-ramp": "0 0 640 512",
  binoculars: "0 0 512 512",
  "microphone-slash": "0 0 640 512",
  "box-tissue": "0 0 512 512",
  "circle-c": "0 0 512 512",
  "star-christmas": "0 0 512 512",
  "chart-bullet": "0 0 512 512",
  motorcycle: "0 0 640 512",
  "tree-christmas": "0 0 448 512",
  "tire-flat": "0 0 512 512",
  sunglasses: "0 0 576 512",
  badge: "0 0 512 512",
  "message-pen": "0 0 512 512",
  "bell-concierge": "0 0 512 512",
  "pen-ruler": "0 0 512 512",
  "file-mp3": "0 0 512 512",
  "arrow-progress": "0 0 512 512",
  "chess-rook-piece": "0 0 320 512",
  "square-root": "0 0 576 512",
  "album-collection-circle-plus": "0 0 640 512",
  "people-arrows": "0 0 640 512",
  "sign-post": "0 0 512 512",
  "face-angry-horns": "0 0 640 512",
  "mars-and-venus-burst": "0 0 640 512",
  tombstone: "0 0 448 512",
  "square-caret-right": "0 0 448 512",
  scissors: "0 0 512 512",
  "list-music": "0 0 512 512",
  "sun-plant-wilt": "0 0 640 512",
  "toilets-portable": "0 0 576 512",
  "hockey-puck": "0 0 512 512",
  mustache: "0 0 640 512",
  hyphen: "0 0 320 512",
  table: "0 0 512 512",
  "user-chef": "0 0 448 512",
  "message-image": "0 0 512 512",
  "users-medical": "0 0 640 512",
  "sensor-triangle-exclamation": "0 0 640 512",
  "magnifying-glass-arrow-right": "0 0 512 512",
  "tachograph-digital": "0 0 640 512",
  "face-mask": "0 0 576 512",
  pickleball: "0 0 576 512",
  "star-sharp-half": "0 0 576 512",
  "users-slash": "0 0 640 512",
  clover: "0 0 448 512",
  meat: "0 0 512 512",
  reply: "0 0 512 512",
  "star-and-crescent": "0 0 512 512",
  "empty-set": "0 0 512 512",
  "house-fire": "0 0 640 512",
  "square-minus": "0 0 448 512",
  helicopter: "0 0 640 512",
  bird: "0 0 512 512",
  compass: "0 0 512 512",
  "square-caret-down": "0 0 448 512",
  "heart-half-stroke": "0 0 512 512",
  "file-circle-question": "0 0 576 512",
  "truck-utensils": "0 0 640 512",
  "laptop-code": "0 0 640 512",
  joystick: "0 0 448 512",
  "grill-fire": "0 0 576 512",
  "rectangle-vertical-history": "0 0 576 512",
  swatchbook: "0 0 512 512",
  "prescription-bottle": "0 0 384 512",
  bars: "0 0 448 512",
  "keyboard-left": "0 0 640 512",
  "people-group": "0 0 640 512",
  "hourglass-end": "0 0 384 512",
  "heart-crack": "0 0 512 512",
  "face-beam-hand-over-mouth": "0 0 512 512",
  "droplet-percent": "0 0 384 512",
  "square-up-right": "0 0 448 512",
  "face-kiss-beam": "0 0 512 512",
  corn: "0 0 512 512",
  "roller-coaster": "0 0 640 512",
  "photo-film-music": "0 0 640 512",
  radar: "0 0 512 512",
  sickle: "0 0 512 512",
  film: "0 0 512 512",
  coconut: "0 0 512 512",
  "ruler-horizontal": "0 0 640 512",
  "shield-cross": "0 0 512 512",
  "cassette-tape": "0 0 576 512",
  "square-terminal": "0 0 448 512",
  "people-robbery": "0 0 576 512",
  lightbulb: "0 0 384 512",
  "caret-left": "0 0 256 512",
  "comment-middle": "0 0 512 512",
  "trash-can-list": "0 0 640 512",
  block: "0 0 448 512",
  "circle-exclamation": "0 0 512 512",
  "school-circle-xmark": "0 0 640 512",
  "arrow-right-from-bracket": "0 0 512 512",
  "face-frown-slight": "0 0 512 512",
  "circle-chevron-down": "0 0 512 512",
  "sidebar-flip": "0 0 512 512",
  "unlock-keyhole": "0 0 448 512",
  "temperature-list": "0 0 512 512",
  "cloud-showers-heavy": "0 0 512 512",
  "headphones-simple": "0 0 512 512",
  sitemap: "0 0 576 512",
  "pipe-section": "0 0 640 512",
  "space-station-moon-construction": "0 0 512 512",
  "circle-dollar-to-slot": "0 0 512 512",
  memory: "0 0 576 512",
  "face-sleeping": "0 0 512 512",
  "road-spikes": "0 0 640 512",
  "fire-burner": "0 0 640 512",
  squirrel: "0 0 512 512",
  "arrow-up-to-line": "0 0 384 512",
  flag: "0 0 448 512",
  "face-cowboy-hat": "0 0 512 512",
  hanukiah: "0 0 640 512",
  "chart-scatter-3d": "0 0 512 512",
  "display-chart-up": "0 0 576 512",
  "square-code": "0 0 448 512",
  feather: "0 0 512 512",
  "volume-low": "0 0 448 512",
  "xmark-to-slot": "0 0 576 512",
  "box-taped": "0 0 448 512",
  "comment-slash": "0 0 640 512",
  swords: "0 0 512 512",
  "cloud-sun-rain": "0 0 640 512",
  album: "0 0 448 512",
  "circle-n": "0 0 512 512",
  compress: "0 0 448 512",
  "wheat-awn": "0 0 512 512",
  ankh: "0 0 320 512",
  "hands-holding-child": "0 0 640 512",
  asterisk: "0 0 384 512",
  "key-skeleton-left-right": "0 0 640 512",
  "comment-lines": "0 0 512 512",
  "luchador-mask": "0 0 448 512",
  "square-check": "0 0 448 512",
  shredder: "0 0 512 512",
  "book-open-cover": "0 0 640 512",
  sandwich: "0 0 512 512",
  "peseta-sign": "0 0 384 512",
  "square-parking-slash": "0 0 640 512",
  "train-tunnel": "0 0 512 512",
  heading: "0 0 448 512",
  ghost: "0 0 384 512",
  "face-anguished": "0 0 512 512",
  "hockey-sticks": "0 0 640 512",
  abacus: "0 0 576 512",
  "film-simple": "0 0 512 512",
  list: "0 0 512 512",
  "tree-palm": "0 0 576 512",
  "square-phone-flip": "0 0 448 512",
  "cart-plus": "0 0 576 512",
  gamepad: "0 0 640 512",
  "border-center-v": "0 0 448 512",
  "circle-dot": "0 0 512 512",
  "clipboard-medical": "0 0 384 512",
  "face-dizzy": "0 0 512 512",
  egg: "0 0 384 512",
  "up-to-line": "0 0 384 512",
  "house-medical-circle-xmark": "0 0 640 512",
  "watch-fitness": "0 0 384 512",
  "clock-nine-thirty": "0 0 512 512",
  campground: "0 0 576 512",
  "folder-plus": "0 0 512 512",
  jug: "0 0 448 512",
  futbol: "0 0 512 512",
  "snow-blowing": "0 0 640 512",
  paintbrush: "0 0 576 512",
  lock: "0 0 448 512",
  "arrow-down-from-line": "0 0 384 512",
  "gas-pump": "0 0 512 512",
  "signal-bars-slash": "0 0 640 512",
  monkey: "0 0 576 512",
  "rectangle-pro": "0 0 640 512",
  "house-night": "0 0 640 512",
  "hot-tub-person": "0 0 512 512",
  "globe-pointer": "0 0 512 512",
  blanket: "0 0 448 512",
  "map-location": "0 0 576 512",
  "house-flood-water": "0 0 576 512",
  "comments-question-check": "0 0 640 512",
  tree: "0 0 448 512",
  "arrows-cross": "0 0 448 512",
  backpack: "0 0 448 512",
  "square-small": "0 0 320 512",
  "folder-arrow-up": "0 0 512 512",
  "bridge-lock": "0 0 640 512",
  "crosshairs-simple": "0 0 512 512",
  "sack-dollar": "0 0 512 512",
  "pen-to-square": "0 0 512 512",
  "square-sliders": "0 0 448 512",
  "car-side": "0 0 640 512",
  "message-middle-top": "0 0 512 512",
  "lightbulb-on": "0 0 640 512",
  knife: "0 0 512 512",
  "share-nodes": "0 0 448 512",
  "display-chart-up-circle-dollar": "0 0 640 512",
  "wave-sine": "0 0 640 512",
  "heart-circle-minus": "0 0 576 512",
  "circle-w": "0 0 512 512",
  "circle-calendar": "0 0 512 512",
  "hourglass-half": "0 0 384 512",
  microscope: "0 0 512 512",
  sunset: "0 0 576 512",
  sink: "0 0 512 512",
  "calendar-exclamation": "0 0 448 512",
  "truck-container-empty": "0 0 640 512",
  "hand-heart": "0 0 512 512",
  "bag-shopping": "0 0 448 512",
  "arrow-down-z-a": "0 0 576 512",
  mitten: "0 0 448 512",
  "reply-clock": "0 0 640 512",
  "person-rays": "0 0 512 512",
  right: "0 0 448 512",
  "circle-f": "0 0 512 512",
  users: "0 0 640 512",
  "face-pleading": "0 0 512 512",
  "eye-slash": "0 0 640 512",
  "flask-vial": "0 0 640 512",
  "police-box": "0 0 384 512",
  cucumber: "0 0 512 512",
  "head-side-brain": "0 0 512 512",
  hand: "0 0 512 512",
  "person-biking-mountain": "0 0 640 512",
  "utensils-slash": "0 0 640 512",
  "print-magnifying-glass": "0 0 640 512",
  "turn-right": "0 0 512 512",
  "folder-bookmark": "0 0 512 512",
  "arrow-turn-left-down": "0 0 384 512",
  om: "0 0 512 512",
  pi: "0 0 448 512",
  "flask-round-potion": "0 0 448 512",
  "face-shush": "0 0 512 512",
  worm: "0 0 512 512",
  "house-circle-xmark": "0 0 640 512",
  plug: "0 0 384 512",
  "calendar-circle-exclamation": "0 0 576 512",
  "square-i": "0 0 448 512",
  "chevron-up": "0 0 512 512",
  "face-saluting": "0 0 640 512",
  "gauge-simple-low": "0 0 512 512",
  "face-persevering": "0 0 512 512",
  "circle-camera": "0 0 512 512",
  "hand-spock": "0 0 576 512",
  "spider-web": "0 0 576 512",
  "circle-microphone": "0 0 512 512",
  "book-arrow-up": "0 0 448 512",
  popsicle: "0 0 320 512",
  command: "0 0 512 512",
  blinds: "0 0 512 512",
  stopwatch: "0 0 448 512",
  saxophone: "0 0 640 512",
  "square-2": "0 0 448 512",
  "field-hockey-stick-ball": "0 0 512 512",
  "arrow-up-square-triangle": "0 0 576 512",
  "face-scream": "0 0 640 512",
  "square-m": "0 0 448 512",
  "camera-web": "0 0 448 512",
  "comment-arrow-down": "0 0 512 512",
  "lightbulb-cfl": "0 0 320 512",
  "window-frame-open": "0 0 512 512",
  "face-kiss": "0 0 512 512",
  "bridge-circle-xmark": "0 0 640 512",
  period: "0 0 192 512",
  "face-grin-tongue": "0 0 512 512",
  "up-to-dotted-line": "0 0 448 512",
  "thought-bubble": "0 0 512 512",
  "skeleton-ribs": "0 0 640 512",
  raygun: "0 0 576 512",
  flute: "0 0 640 512",
  acorn: "0 0 448 512",
  "video-arrow-up-right": "0 0 576 512",
  "grate-droplet": "0 0 576 512",
  "seal-exclamation": "0 0 512 512",
  "chess-bishop": "0 0 320 512",
  "message-sms": "0 0 512 512",
  "coffee-beans": "0 0 512 512",
  "hat-witch": "0 0 576 512",
  "face-grin-wink": "0 0 512 512",
  "clock-three-thirty": "0 0 512 512",
  "ear-deaf": "0 0 512 512",
  "alarm-clock": "0 0 512 512",
  eclipse: "0 0 640 512",
  "face-relieved": "0 0 512 512",
  "road-circle-check": "0 0 640 512",
  "dice-five": "0 0 448 512",
  "octagon-minus": "0 0 512 512",
  "square-rss": "0 0 448 512",
  "face-zany": "0 0 512 512",
  tricycle: "0 0 512 512",
  "land-mine-on": "0 0 640 512",
  "square-arrow-up-left": "0 0 448 512",
  "i-cursor": "0 0 256 512",
  "chart-mixed-up-circle-dollar": "0 0 576 512",
  "salt-shaker": "0 0 384 512",
  stamp: "0 0 512 512",
  "file-plus": "0 0 384 512",
  "draw-square": "0 0 448 512",
  "toilet-paper-under-slash": "0 0 640 512",
  stairs: "0 0 576 512",
  "drone-front": "0 0 640 512",
  "glass-empty": "0 0 384 512",
  "dial-high": "0 0 576 512",
  "user-helmet-safety": "0 0 448 512",
  i: "0 0 320 512",
  "hryvnia-sign": "0 0 384 512",
  "arrow-down-left-and-arrow-up-right-to-center": "0 0 512 512",
  pills: "0 0 576 512",
  "face-grin-wide": "0 0 512 512",
  tooth: "0 0 448 512",
  "basketball-hoop": "0 0 640 512",
  "objects-align-bottom": "0 0 512 512",
  v: "0 0 384 512",
  sparkles: "0 0 512 512",
  squid: "0 0 512 512",
  "leafy-green": "0 0 576 512",
  "circle-arrow-up-right": "0 0 512 512",
  calendars: "0 0 512 512",
  "bangladeshi-taka-sign": "0 0 384 512",
  bicycle: "0 0 640 512",
  "hammer-war": "0 0 384 512",
  "circle-d": "0 0 512 512",
  "spider-black-widow": "0 0 512 512",
  "staff-snake": "0 0 384 512",
  pear: "0 0 512 512",
  "head-side-cough-slash": "0 0 640 512",
  "file-mov": "0 0 512 512",
  triangle: "0 0 512 512",
  apartment: "0 0 576 512",
  "truck-medical": "0 0 640 512",
  pepper: "0 0 512 512",
  piano: "0 0 512 512",
  "gun-squirt": "0 0 576 512",
  "wheat-awn-circle-exclamation": "0 0 640 512",
  snowman: "0 0 512 512",
  "user-alien": "0 0 448 512",
  "shield-check": "0 0 512 512",
  "mortar-pestle": "0 0 512 512",
  "road-barrier": "0 0 640 512",
  "chart-candlestick": "0 0 512 512",
  "briefcase-blank": "0 0 512 512",
  school: "0 0 640 512",
  igloo: "0 0 576 512",
  "bracket-round": "0 0 192 512",
  joint: "0 0 640 512",
  "horse-saddle": "0 0 576 512",
  "mug-marshmallows": "0 0 512 512",
  filters: "0 0 640 512",
  "bell-on": "0 0 640 512",
  "angle-right": "0 0 320 512",
  "dial-med": "0 0 576 512",
  horse: "0 0 576 512",
  q: "0 0 448 512",
  "monitor-waveform": "0 0 576 512",
  "link-simple": "0 0 576 512",
  whistle: "0 0 640 512",
  g: "0 0 448 512",
  "wine-glass-crack": "0 0 320 512",
  "slot-machine": "0 0 640 512",
  "notes-medical": "0 0 512 512",
  "car-wash": "0 0 448 512",
  escalator: "0 0 640 512",
  "comment-image": "0 0 512 512",
  "temperature-half": "0 0 320 512",
  "dong-sign": "0 0 384 512",
  donut: "0 0 512 512",
  capsules: "0 0 576 512",
  "poo-storm": "0 0 448 512",
  "tally-1": "0 0 640 512",
  "file-vector": "0 0 384 512",
  "face-frown-open": "0 0 512 512",
  "square-dashed": "0 0 448 512",
  "bag-shopping-plus": "0 0 448 512",
  "square-j": "0 0 448 512",
  "hand-point-up": "0 0 384 512",
  "money-bill": "0 0 576 512",
  "arrow-up-big-small": "0 0 576 512",
  "barcode-read": "0 0 576 512",
  baguette: "0 0 640 512",
  "bowl-soft-serve": "0 0 512 512",
  "face-holding-back-tears": "0 0 512 512",
  "square-up": "0 0 448 512",
  "train-subway-tunnel": "0 0 512 512",
  "square-exclamation": "0 0 448 512",
  semicolon: "0 0 192 512",
  bookmark: "0 0 384 512",
  "fan-table": "0 0 448 512",
  "align-justify": "0 0 448 512",
  "battery-low": "0 0 576 512",
  "credit-card-front": "0 0 576 512",
  "brain-arrow-curved-right": "0 0 640 512",
  "umbrella-beach": "0 0 576 512",
  "helmet-un": "0 0 512 512",
  "location-smile": "0 0 384 512",
  "arrow-left-to-line": "0 0 448 512",
  bullseye: "0 0 512 512",
  sushi: "0 0 576 512",
  "message-captions": "0 0 512 512",
  "trash-list": "0 0 640 512",
  bacon: "0 0 576 512",
  option: "0 0 640 512",
  raccoon: "0 0 512 512",
  "hand-point-down": "0 0 384 512",
  "arrow-up-from-bracket": "0 0 448 512",
  "head-side-gear": "0 0 512 512",
  "trash-plus": "0 0 448 512",
  "file-cad": "0 0 512 512",
  "objects-align-top": "0 0 512 512",
  folder: "0 0 512 512",
  "face-anxious-sweat": "0 0 576 512",
  "credit-card-blank": "0 0 576 512",
  "file-waveform": "0 0 448 512",
  "microchip-ai": "0 0 512 512",
  mug: "0 0 576 512",
  "plane-up-slash": "0 0 640 512",
  radiation: "0 0 512 512",
  "pen-circle": "0 0 512 512",
  "bag-seedling": "0 0 512 512",
  "chart-simple": "0 0 448 512",
  crutches: "0 0 640 512",
  "circle-parking": "0 0 512 512",
  "mars-stroke": "0 0 512 512",
  "leaf-oak": "0 0 512 512",
  "square-bolt": "0 0 448 512",
  vial: "0 0 512 512",
  gauge: "0 0 512 512",
  "wand-magic-sparkles": "0 0 576 512",
  lambda: "0 0 448 512",
  e: "0 0 320 512",
  pizza: "0 0 576 512",
  "bowl-chopsticks-noodles": "0 0 512 512",
  h3: "0 0 640 512",
  "pen-clip": "0 0 512 512",
  "bridge-circle-exclamation": "0 0 640 512",
  "badge-percent": "0 0 512 512",
  "rotate-reverse": "0 0 512 512",
  user: "0 0 448 512",
  sensor: "0 0 448 512",
  comma: "0 0 192 512",
  "school-circle-check": "0 0 640 512",
  "toilet-paper-under": "0 0 512 512",
  "light-emergency": "0 0 448 512",
  "arrow-down-to-arc": "0 0 512 512",
  dumpster: "0 0 576 512",
  "van-shuttle": "0 0 640 512",
  "building-user": "0 0 640 512",
  "light-switch": "0 0 384 512",
  "square-caret-left": "0 0 448 512",
  highlighter: "0 0 576 512",
  "wave-pulse": "0 0 640 512",
  key: "0 0 512 512",
  "arrow-left-to-bracket": "0 0 512 512",
  "hat-santa": "0 0 640 512",
  tamale: "0 0 320 512",
  "box-check": "0 0 448 512",
  bullhorn: "0 0 512 512",
  steak: "0 0 576 512",
  "location-crosshairs-slash": "0 0 640 512",
  "person-dolly": "0 0 512 512",
  globe: "0 0 512 512",
  synagogue: "0 0 640 512",
  "file-chart-column": "0 0 384 512",
  "person-half-dress": "0 0 320 512",
  "folder-image": "0 0 512 512",
  "calendar-pen": "0 0 448 512",
  "road-bridge": "0 0 640 512",
  "face-smile-tear": "0 0 512 512",
  "message-plus": "0 0 512 512",
  "location-arrow": "0 0 448 512",
  c: "0 0 384 512",
  "tablet-button": "0 0 448 512",
  "person-dress-fairy": "0 0 640 512",
  "rectangle-history-circle-user": "0 0 640 512",
  "building-lock": "0 0 576 512",
  "chart-line-up": "0 0 512 512",
  mailbox: "0 0 576 512",
  "sign-posts": "0 0 576 512",
  "truck-bolt": "0 0 640 512",
  "pizza-slice": "0 0 512 512",
  "money-bill-wave": "0 0 576 512",
  "chart-area": "0 0 512 512",
  "house-flag": "0 0 640 512",
  "circle-three-quarters-stroke": "0 0 512 512",
  "person-circle-minus": "0 0 576 512",
  scalpel: "0 0 512 512",
  ban: "0 0 512 512",
  "bell-exclamation": "0 0 448 512",
  "circle-bookmark": "0 0 512 512",
  "egg-fried": "0 0 512 512",
  "face-weary": "0 0 512 512",
  "uniform-martial-arts": "0 0 640 512",
  "camera-rotate": "0 0 640 512",
  "sun-dust": "0 0 512 512",
  "comment-text": "0 0 512 512",
  "spray-can-sparkles": "0 0 512 512",
  "signal-bars": "0 0 640 512",
  "diamond-exclamation": "0 0 512 512",
  star: "0 0 576 512",
  "dial-min": "0 0 576 512",
  repeat: "0 0 512 512",
  cross: "0 0 384 512",
  "page-caret-down": "0 0 384 512",
  box: "0 0 448 512",
  "venus-mars": "0 0 640 512",
  "clock-seven-thirty": "0 0 512 512",
  "arrow-pointer": "0 0 320 512",
  "clock-four-thirty": "0 0 512 512",
  "signal-bars-good": "0 0 640 512",
  cactus: "0 0 512 512",
  "lightbulb-gear": "0 0 576 512",
  maximize: "0 0 512 512",
  "charging-station": "0 0 576 512",
  shapes: "0 0 512 512",
  "plane-tail": "0 0 512 512",
  "gauge-simple-max": "0 0 512 512",
  "circle-u": "0 0 512 512",
  "shield-slash": "0 0 640 512",
  "square-phone-hangup": "0 0 448 512",
  "arrow-up-left": "0 0 384 512",
  "transporter-1": "0 0 512 512",
  peanuts: "0 0 640 512",
  shuffle: "0 0 512 512",
  "person-running": "0 0 448 512",
  "mobile-retro": "0 0 320 512",
  "grip-lines-vertical": "0 0 192 512",
  "bin-bottles-recycle": "0 0 640 512",
  "arrow-up-from-square": "0 0 512 512",
  "file-dashed-line": "0 0 640 512",
  "bracket-curly-right": "0 0 256 512",
  spider: "0 0 512 512",
  "clock-three": "0 0 512 512",
  "hands-bound": "0 0 640 512",
  "scalpel-line-dashed": "0 0 576 512",
  "file-invoice-dollar": "0 0 384 512",
  "pipe-smoking": "0 0 640 512",
  "face-astonished": "0 0 512 512",
  window: "0 0 512 512",
  "plane-circle-exclamation": "0 0 640 512",
  ear: "0 0 384 512",
  "file-lock": "0 0 576 512",
  "diagram-venn": "0 0 640 512",
  "arrow-down-from-bracket": "0 0 448 512",
  "x-ray": "0 0 512 512",
  "goal-net": "0 0 640 512",
  "coffin-cross": "0 0 384 512",
  "spell-check": "0 0 576 512",
  "location-xmark": "0 0 384 512",
  "circle-quarter-stroke": "0 0 512 512",
  lasso: "0 0 576 512",
  slash: "0 0 640 512",
  "person-to-portal": "0 0 512 512",
  "calendar-star": "0 0 448 512",
  "computer-mouse": "0 0 384 512",
  "arrow-right-to-bracket": "0 0 512 512",
  pegasus: "0 0 576 512",
  "files-medical": "0 0 448 512",
  cannon: "0 0 640 512",
  "nfc-lock": "0 0 576 512",
  "person-ski-lift": "0 0 512 512",
  "square-6": "0 0 448 512",
  "shop-slash": "0 0 640 512",
  "wind-turbine": "0 0 512 512",
  "sliders-simple": "0 0 512 512",
  "grid-round": "0 0 448 512",
  "badge-sheriff": "0 0 448 512",
  server: "0 0 512 512",
  "virus-covid-slash": "0 0 640 512",
  intersection: "0 0 384 512",
  "shop-lock": "0 0 640 512",
  family: "0 0 512 512",
  "hourglass-start": "0 0 384 512",
  "user-hair-buns": "0 0 448 512",
  "blender-phone": "0 0 576 512",
  "hourglass-clock": "0 0 576 512",
  "person-seat-reclined": "0 0 512 512",
  "paper-plane-top": "0 0 512 512",
  "message-arrow-up": "0 0 512 512",
  "lightbulb-exclamation": "0 0 384 512",
  "layer-minus": "0 0 576 512",
  "chart-pie-simple-circle-currency": "0 0 640 512",
  "circle-e": "0 0 512 512",
  "building-wheat": "0 0 640 512",
  "gauge-max": "0 0 512 512",
  "person-breastfeeding": "0 0 448 512",
  apostrophe: "0 0 192 512",
  "file-png": "0 0 512 512",
  "fire-hydrant": "0 0 384 512",
  "right-to-bracket": "0 0 512 512",
  "video-plus": "0 0 576 512",
  "square-right": "0 0 448 512",
  "comment-smile": "0 0 512 512",
  venus: "0 0 384 512",
  passport: "0 0 448 512",
  "inbox-in": "0 0 512 512",
  "heart-pulse": "0 0 512 512",
  "circle-8": "0 0 512 512",
  "clouds-moon": "0 0 640 512",
  "clock-ten-thirty": "0 0 512 512",
  "people-carry-box": "0 0 640 512",
  "folder-user": "0 0 512 512",
  "trash-can-xmark": "0 0 448 512",
  "temperature-high": "0 0 512 512",
  microchip: "0 0 512 512",
  "left-long-to-line": "0 0 640 512",
  crown: "0 0 576 512",
  "weight-hanging": "0 0 512 512",
  "xmarks-lines": "0 0 640 512",
  "file-prescription": "0 0 384 512",
  "table-cells-lock": "0 0 640 512",
  "calendar-range": "0 0 448 512",
  "flower-daffodil": "0 0 512 512",
  "hand-back-point-up": "0 0 448 512",
  "weight-scale": "0 0 512 512",
  "arrow-up-to-arc": "0 0 512 512",
  "star-exclamation": "0 0 576 512",
  books: "0 0 512 512",
  "user-group": "0 0 640 512",
  "arrow-up-a-z": "0 0 576 512",
  "layer-plus": "0 0 576 512",
  "play-pause": "0 0 640 512",
  "block-question": "0 0 448 512",
  snooze: "0 0 448 512",
  "scanner-image": "0 0 576 512",
  "tv-retro": "0 0 512 512",
  "square-t": "0 0 448 512",
  farm: "0 0 576 512",
  "chess-knight": "0 0 448 512",
  "bars-sort": "0 0 448 512",
  "pallet-boxes": "0 0 640 512",
  "face-laugh-squint": "0 0 512 512",
  "code-simple": "0 0 576 512",
  "bolt-slash": "0 0 640 512",
  "panel-fire": "0 0 640 512",
  "binary-circle-check": "0 0 640 512",
  "comment-minus": "0 0 512 512",
  burrito: "0 0 512 512",
  violin: "0 0 640 512",
  "objects-column": "0 0 448 512",
  "square-chevron-down": "0 0 448 512",
  "comment-plus": "0 0 512 512",
  "triangle-instrument": "0 0 576 512",
  wheelchair: "0 0 512 512",
  "user-pilot-tie": "0 0 448 512",
  "piano-keyboard": "0 0 576 512",
  "bed-empty": "0 0 640 512",
  "circle-arrow-up": "0 0 512 512",
  "toggle-on": "0 0 576 512",
  "rectangle-vertical": "0 0 384 512",
  "person-walking": "0 0 320 512",
  l: "0 0 320 512",
  "signal-stream": "0 0 576 512",
  "down-to-bracket": "0 0 448 512",
  "circle-z": "0 0 512 512",
  stars: "0 0 512 512",
  fire: "0 0 448 512",
  "bed-pulse": "0 0 640 512",
  "house-day": "0 0 640 512",
  "shuttle-space": "0 0 640 512",
  "shirt-long-sleeve": "0 0 640 512",
  "chart-pie-simple": "0 0 512 512",
  "face-laugh": "0 0 512 512",
  "folder-open": "0 0 576 512",
  "album-collection-circle-user": "0 0 640 512",
  candy: "0 0 640 512",
  "bowl-hot": "0 0 512 512",
  flatbread: "0 0 512 512",
  "heart-circle-plus": "0 0 576 512",
  "code-fork": "0 0 448 512",
  city: "0 0 640 512",
  "signal-bars-weak": "0 0 640 512",
  "microphone-lines": "0 0 384 512",
  "clock-twelve": "0 0 512 512",
  "pepper-hot": "0 0 512 512",
  "citrus-slice": "0 0 512 512",
  sheep: "0 0 640 512",
  unlock: "0 0 448 512",
  "colon-sign": "0 0 384 512",
  headset: "0 0 512 512",
  "badger-honey": "0 0 640 512",
  h4: "0 0 640 512",
  "store-slash": "0 0 640 512",
  "road-circle-xmark": "0 0 640 512",
  "signal-slash": "0 0 640 512",
  "user-minus": "0 0 640 512",
  "mars-stroke-up": "0 0 320 512",
  "champagne-glasses": "0 0 640 512",
  taco: "0 0 512 512",
  "hexagon-plus": "0 0 512 512",
  clipboard: "0 0 384 512",
  "house-circle-exclamation": "0 0 640 512",
  "file-arrow-up": "0 0 384 512",
  wifi: "0 0 640 512",
  messages: "0 0 640 512",
  bath: "0 0 512 512",
  "umbrella-simple": "0 0 512 512",
  "rectangle-history-circle-plus": "0 0 640 512",
  underline: "0 0 448 512",
  "prescription-bottle-pill": "0 0 576 512",
  "user-pen": "0 0 640 512",
  "binary-slash": "0 0 640 512",
  "square-o": "0 0 448 512",
  caduceus: "0 0 512 512",
  signature: "0 0 640 512",
  stroopwafel: "0 0 512 512",
  bold: "0 0 384 512",
  "anchor-lock": "0 0 640 512",
  "building-ngo": "0 0 384 512",
  "transporter-3": "0 0 512 512",
  "engine-warning": "0 0 640 512",
  "circle-down-right": "0 0 512 512",
  "square-k": "0 0 448 512",
  "manat-sign": "0 0 384 512",
  "money-check-pen": "0 0 640 512",
  "not-equal": "0 0 448 512",
  "border-top-left": "0 0 448 512",
  "map-location-dot": "0 0 576 512",
  tilde: "0 0 448 512",
  jedi: "0 0 576 512",
  "square-poll-vertical": "0 0 448 512",
  "arrow-down-square-triangle": "0 0 576 512",
  "mug-hot": "0 0 512 512",
  "dog-leashed": "0 0 576 512",
  "car-battery": "0 0 512 512",
  "face-downcast-sweat": "0 0 512 512",
  "mailbox-flag-up": "0 0 576 512",
  "memo-circle-info": "0 0 576 512",
  gift: "0 0 512 512",
  "dice-two": "0 0 448 512",
  volume: "0 0 576 512",
  "transporter-5": "0 0 512 512",
  "gauge-circle-bolt": "0 0 640 512",
  "coin-front": "0 0 512 512",
  "file-slash": "0 0 640 512",
  "message-arrow-up-right": "0 0 512 512",
  "treasure-chest": "0 0 576 512",
  "chess-queen": "0 0 512 512",
  "paintbrush-fine": "0 0 576 512",
  glasses: "0 0 576 512",
  "hood-cloak": "0 0 576 512",
  "square-quote": "0 0 448 512",
  "up-left": "0 0 384 512",
  "bring-front": "0 0 640 512",
  "chess-board": "0 0 448 512",
  "burger-cheese": "0 0 512 512",
  "building-circle-check": "0 0 640 512",
  "repeat-1": "0 0 512 512",
  "arrow-down-to-line": "0 0 384 512",
  "grid-5": "0 0 448 512",
  "swap-arrows": "0 0 640 512",
  "right-long-to-line": "0 0 640 512",
  "person-chalkboard": "0 0 640 512",
  "mars-stroke-right": "0 0 640 512",
  "hand-back-fist": "0 0 448 512",
  "grid-round-5": "0 0 448 512",
  tally: "0 0 640 512",
  "square-caret-up": "0 0 448 512",
  "cloud-showers-water": "0 0 576 512",
  "chart-bar": "0 0 512 512",
  "hands-bubbles": "0 0 608 512",
  "less-than-equal": "0 0 448 512",
  train: "0 0 448 512",
  "up-from-dotted-line": "0 0 448 512",
  "eye-low-vision": "0 0 640 512",
  "traffic-light-go": "0 0 320 512",
  "face-exhaling": "0 0 576 512",
  "sensor-fire": "0 0 640 512",
  "user-unlock": "0 0 640 512",
  "hexagon-divide": "0 0 512 512",
  "00": "0 0 640 512",
  crow: "0 0 640 512",
  "cassette-betamax": "0 0 576 512",
  sailboat: "0 0 576 512",
  "window-restore": "0 0 512 512",
  "nfc-magnifying-glass": "0 0 640 512",
  "file-binary": "0 0 384 512",
  "circle-v": "0 0 512 512",
  "square-plus": "0 0 448 512",
  "bowl-scoops": "0 0 448 512",
  mistletoe: "0 0 576 512",
  custard: "0 0 576 512",
  "lacrosse-stick": "0 0 512 512",
  "hockey-mask": "0 0 448 512",
  sunrise: "0 0 576 512",
  subtitles: "0 0 576 512",
  "panel-ews": "0 0 512 512",
  "torii-gate": "0 0 512 512",
  "cloud-exclamation": "0 0 640 512",
  "message-lines": "0 0 512 512",
  frog: "0 0 576 512",
  bucket: "0 0 448 512",
  "floppy-disk-pen": "0 0 576 512",
  image: "0 0 512 512",
  "window-frame": "0 0 512 512",
  microphone: "0 0 384 512",
  cow: "0 0 640 512",
  "file-zip": "0 0 512 512",
  "square-ring": "0 0 448 512",
  "down-from-line": "0 0 384 512",
  "caret-up": "0 0 320 512",
  "shield-xmark": "0 0 512 512",
  screwdriver: "0 0 512 512",
  "circle-sort-down": "0 0 512 512",
  "folder-closed": "0 0 512 512",
  "house-tsunami": "0 0 576 512",
  "square-nfi": "0 0 448 512",
  forklift: "0 0 640 512",
  "arrow-up-from-ground-water": "0 0 576 512",
  "bracket-square-right": "0 0 192 512",
  "martini-glass": "0 0 512 512",
  "rotate-left": "0 0 512 512",
  "table-columns": "0 0 512 512",
  "square-a": "0 0 448 512",
  tick: "0 0 192 512",
  lemon: "0 0 448 512",
  "head-side-mask": "0 0 576 512",
  handshake: "0 0 640 512",
  gem: "0 0 512 512",
  dolly: "0 0 576 512",
  smoking: "0 0 640 512",
  minimize: "0 0 512 512",
  refrigerator: "0 0 384 512",
  monument: "0 0 384 512",
  "octagon-xmark": "0 0 512 512",
  "align-slash": "0 0 640 512",
  snowplow: "0 0 640 512",
  "angles-right": "0 0 512 512",
  "truck-ramp-couch": "0 0 640 512",
  cannabis: "0 0 512 512",
  "circle-play": "0 0 512 512",
  "arrow-up-right-and-arrow-down-left-from-center": "0 0 512 512",
  "location-arrow-up": "0 0 448 512",
  tablets: "0 0 640 512",
  "360-degrees": "0 0 640 512",
  ethernet: "0 0 512 512",
  "euro-sign": "0 0 320 512",
  chair: "0 0 448 512",
  "circle-check": "0 0 512 512",
  "square-dashed-circle-plus": "0 0 576 512",
  "hand-holding-circle-dollar": "0 0 576 512",
  "money-simple-from-bracket": "0 0 640 512",
  bat: "0 0 576 512",
  "circle-stop": "0 0 512 512",
  "head-side-headphones": "0 0 512 512",
  "phone-rotary": "0 0 512 512",
  "arrow-up-to-bracket": "0 0 448 512",
  "compass-drafting": "0 0 512 512",
  "plate-wheat": "0 0 512 512",
  "calendar-circle-minus": "0 0 576 512",
  chopsticks: "0 0 640 512",
  "car-wrench": "0 0 512 512",
  icicles: "0 0 512 512",
  "person-shelter": "0 0 512 512",
  neuter: "0 0 384 512",
  "id-badge": "0 0 384 512",
  kazoo: "0 0 640 512",
  marker: "0 0 512 512",
  "bin-bottles": "0 0 640 512",
  "face-laugh-beam": "0 0 512 512",
  "square-arrow-down-left": "0 0 448 512",
  "battery-bolt": "0 0 576 512",
  "tree-large": "0 0 448 512",
  "helicopter-symbol": "0 0 512 512",
  aperture: "0 0 512 512",
  "universal-access": "0 0 512 512",
  "gear-complex": "0 0 512 512",
  "file-magnifying-glass": "0 0 384 512",
  "up-right": "0 0 384 512",
  "circle-chevron-up": "0 0 512 512",
  "user-police": "0 0 448 512",
  "lari-sign": "0 0 384 512",
  volcano: "0 0 512 512",
  "teddy-bear": "0 0 576 512",
  stocking: "0 0 384 512",
  "person-walking-dashed-line-arrow-right": "0 0 640 512",
  "image-slash": "0 0 640 512",
  "mask-snorkel": "0 0 576 512",
  smoke: "0 0 640 512",
  "sterling-sign": "0 0 320 512",
  "battery-exclamation": "0 0 576 512",
  viruses: "0 0 640 512",
  "square-person-confined": "0 0 448 512",
  "user-tie": "0 0 448 512",
  "up-to-bracket": "0 0 448 512",
  "arrow-down-long": "0 0 384 512",
  "tent-arrow-down-to-line": "0 0 640 512",
  certificate: "0 0 512 512",
  "crystal-ball": "0 0 448 512",
  "reply-all": "0 0 576 512",
  suitcase: "0 0 512 512",
  "person-skating": "0 0 448 512",
  "star-shooting": "0 0 512 512",
  "binary-lock": "0 0 640 512",
  "filter-circle-dollar": "0 0 576 512",
  "camera-retro": "0 0 512 512",
  "circle-arrow-down": "0 0 512 512",
  "comment-pen": "0 0 512 512",
  "file-import": "0 0 512 512",
  banjo: "0 0 512 512",
  "square-arrow-up-right": "0 0 448 512",
  "light-emergency-on": "0 0 640 512",
  kerning: "0 0 640 512",
  "box-open": "0 0 640 512",
  "square-f": "0 0 448 512",
  scroll: "0 0 576 512",
  spa: "0 0 576 512",
  "arrow-left-from-line": "0 0 448 512",
  strawberry: "0 0 512 512",
  "location-pin-lock": "0 0 512 512",
  pause: "0 0 320 512",
  "clock-eight-thirty": "0 0 512 512",
  "plane-engines": "0 0 576 512",
  "hill-avalanche": "0 0 576 512",
  "temperature-empty": "0 0 320 512",
  bomb: "0 0 512 512",
  "gauge-low": "0 0 512 512",
  registered: "0 0 512 512",
  "trash-can-plus": "0 0 448 512",
  "address-card": "0 0 576 512",
  "scale-unbalanced-flip": "0 0 640 512",
  "globe-snow": "0 0 448 512",
  subscript: "0 0 512 512",
  "diamond-turn-right": "0 0 512 512",
  integral: "0 0 320 512",
  burst: "0 0 512 512",
  "house-laptop": "0 0 640 512",
  "face-tired": "0 0 512 512",
  "money-bills": "0 0 640 512",
  "blinds-raised": "0 0 512 512",
  smog: "0 0 640 512",
  "ufo-beam": "0 0 576 512",
  "circle-caret-up": "0 0 512 512",
  "user-vneck-hair-long": "0 0 448 512",
  "square-a-lock": "0 0 576 512",
  crutch: "0 0 512 512",
  "gas-pump-slash": "0 0 640 512",
  "font-awesome": "0 0 512 512",
  "cloud-arrow-up": "0 0 640 512",
  palette: "0 0 512 512",
  "transporter-4": "0 0 512 512",
  "chart-mixed-up-circle-currency": "0 0 576 512",
  "objects-align-right": "0 0 512 512",
  "arrows-turn-right": "0 0 448 512",
  vest: "0 0 448 512",
  pig: "0 0 640 512",
  "inbox-full": "0 0 512 512",
  "circle-envelope": "0 0 512 512",
  "triangle-person-digging": "0 0 640 512",
  ferry: "0 0 576 512",
  "bullseye-arrow": "0 0 512 512",
  "arrows-down-to-people": "0 0 640 512",
  seedling: "0 0 512 512",
  "clock-seven": "0 0 512 512",
  "left-right": "0 0 512 512",
  "boxes-packing": "0 0 640 512",
  "circle-arrow-left": "0 0 512 512",
  flashlight: "0 0 640 512",
  "file-jpg": "0 0 512 512",
  "group-arrows-rotate": "0 0 512 512",
  "bowl-food": "0 0 512 512",
  "square-9": "0 0 448 512",
  "candy-cane": "0 0 512 512",
  "arrow-down-wide-short": "0 0 576 512",
  "square-dollar": "0 0 448 512",
  "phone-arrow-right": "0 0 512 512",
  "hand-holding-seedling": "0 0 576 512",
  "message-check": "0 0 512 512",
  "cloud-bolt": "0 0 512 512",
  "chart-line-up-down": "0 0 512 512",
  "text-slash": "0 0 640 512",
  watch: "0 0 384 512",
  "circle-down-left": "0 0 512 512",
  text: "0 0 448 512",
  projector: "0 0 640 512",
  "face-smile-wink": "0 0 512 512",
  "tombstone-blank": "0 0 448 512",
  "chess-king-piece": "0 0 256 512",
  "circle-6": "0 0 512 512",
  "waves-sine": "0 0 640 512",
  left: "0 0 448 512",
  "file-word": "0 0 384 512",
  "file-powerpoint": "0 0 384 512",
  "square-down": "0 0 448 512",
  "objects-align-center-vertical": "0 0 512 512",
  "arrows-left-right": "0 0 512 512",
  "house-lock": "0 0 640 512",
  "cloud-arrow-down": "0 0 640 512",
  wreath: "0 0 448 512",
  children: "0 0 640 512",
  "meter-droplet": "0 0 640 512",
  chalkboard: "0 0 576 512",
  "user-large-slash": "0 0 640 512",
  "signal-strong": "0 0 640 512",
  lollipop: "0 0 512 512",
  "list-tree": "0 0 512 512",
  "envelope-open": "0 0 512 512",
  "draw-circle": "0 0 512 512",
  "cat-space": "0 0 640 512",
  "handshake-simple-slash": "0 0 640 512",
  "rabbit-running": "0 0 576 512",
  "memo-pad": "0 0 448 512",
  "mattress-pillow": "0 0 640 512",
  "alarm-plus": "0 0 512 512",
  alicorn: "0 0 640 512",
  "comment-question": "0 0 512 512",
  "gingerbread-man": "0 0 448 512",
  "guarani-sign": "0 0 384 512",
  "burger-fries": "0 0 640 512",
  "mug-tea": "0 0 576 512",
  "border-top": "0 0 448 512",
  "arrows-rotate": "0 0 512 512",
  "circle-book-open": "0 0 512 512",
  "arrows-to-dotted-line": "0 0 448 512",
  "fire-extinguisher": "0 0 512 512",
  "magnifying-glass-arrows-rotate": "0 0 512 512",
  "garage-open": "0 0 640 512",
  "shelves-empty": "0 0 640 512",
  "cruzeiro-sign": "0 0 448 512",
  "watch-apple": "0 0 384 512",
  "watch-calculator": "0 0 384 512",
  "list-dropdown": "0 0 512 512",
  "cabinet-filing": "0 0 448 512",
  "burger-soda": "0 0 640 512",
  "square-arrow-up": "0 0 448 512",
  "greater-than-equal": "0 0 448 512",
  "pallet-box": "0 0 640 512",
  "face-confounded": "0 0 512 512",
  "shield-halved": "0 0 512 512",
  "truck-plow": "0 0 640 512",
  "book-atlas": "0 0 448 512",
  virus: "0 0 512 512",
  "grid-round-2": "0 0 512 512",
  "comment-middle-top": "0 0 512 512",
  wave: "0 0 576 512",
  "envelope-circle-check": "0 0 640 512",
  "layer-group": "0 0 576 512",
  "restroom-simple": "0 0 640 512",
  "arrows-to-dot": "0 0 512 512",
  "border-outer": "0 0 448 512",
  "hashtag-lock": "0 0 576 512",
  "clock-two-thirty": "0 0 512 512",
  archway: "0 0 512 512",
  "heart-circle-check": "0 0 576 512",
  "house-chimney-crack": "0 0 576 512",
  "file-zipper": "0 0 384 512",
  "ticket-perforated": "0 0 640 512",
  "heart-half": "0 0 512 512",
  "comment-check": "0 0 512 512",
  square: "0 0 448 512",
  memo: "0 0 384 512",
  "martini-glass-empty": "0 0 512 512",
  couch: "0 0 640 512",
  "cedi-sign": "0 0 384 512",
  italic: "0 0 384 512",
  "glass-citrus": "0 0 512 512",
  "calendar-lines-pen": "0 0 576 512",
  "table-cells-column-lock": "0 0 640 512",
  church: "0 0 640 512",
  "person-snowmobiling": "0 0 640 512",
  "face-hushed": "0 0 512 512",
  "comments-dollar": "0 0 640 512",
  "tickets-simple": "0 0 640 512",
  pickaxe: "0 0 512 512",
  "link-simple-slash": "0 0 640 512",
  democrat: "0 0 640 512",
  "face-confused": "0 0 512 512",
  pinball: "0 0 448 512",
  z: "0 0 384 512",
  "person-skiing": "0 0 512 512",
  deer: "0 0 512 512",
  "input-pipe": "0 0 640 512",
  "road-lock": "0 0 640 512",
  a: "0 0 384 512",
  "bookmark-slash": "0 0 640 512",
  "temperature-arrow-down": "0 0 576 512",
  mace: "0 0 512 512",
  "feather-pointed": "0 0 512 512",
  sausage: "0 0 512 512",
  "trash-can-clock": "0 0 576 512",
  p: "0 0 320 512",
  "broom-wide": "0 0 512 512",
  snowflake: "0 0 448 512",
  stomach: "0 0 512 512",
  newspaper: "0 0 512 512",
  "rectangle-ad": "0 0 576 512",
  "guitar-electric": "0 0 512 512",
  "arrow-turn-down-right": "0 0 512 512",
  "moon-cloud": "0 0 640 512",
  "bread-slice-butter": "0 0 512 512",
  "circle-arrow-right": "0 0 512 512",
  "user-group-crown": "0 0 640 512",
  "circle-i": "0 0 512 512",
  "toilet-paper-check": "0 0 640 512",
  "filter-circle-xmark": "0 0 576 512",
  locust: "0 0 576 512",
  sort: "0 0 320 512",
  "list-ol": "0 0 512 512",
  "chart-waterfall": "0 0 512 512",
  sparkle: "0 0 448 512",
  "face-party": "0 0 640 512",
  kidneys: "0 0 640 512",
  "wifi-exclamation": "0 0 640 512",
  "chart-network": "0 0 640 512",
  "person-dress-burst": "0 0 640 512",
  "dice-d4": "0 0 512 512",
  "money-check-dollar": "0 0 576 512",
  "vector-square": "0 0 448 512",
  "bread-slice": "0 0 512 512",
  language: "0 0 640 512",
  "wheat-awn-slash": "0 0 640 512",
  "face-kiss-wink-heart": "0 0 512 512",
  dagger: "0 0 384 512",
  podium: "0 0 448 512",
  "memo-circle-check": "0 0 576 512",
  "route-highway": "0 0 448 512",
  "down-to-line": "0 0 384 512",
  filter: "0 0 512 512",
  "square-g": "0 0 448 512",
  "circle-phone": "0 0 512 512",
  "clipboard-prescription": "0 0 384 512",
  "user-nurse-hair": "0 0 448 512",
  question: "0 0 320 512",
  "file-signature": "0 0 576 512",
  "toggle-large-on": "0 0 576 512",
  "up-down-left-right": "0 0 512 512",
  "dryer-heat": "0 0 448 512",
  "house-chimney-user": "0 0 576 512",
  "hand-holding-heart": "0 0 576 512",
  "arrow-up-small-big": "0 0 576 512",
  "train-track": "0 0 576 512",
  "puzzle-piece": "0 0 512 512",
  "money-check": "0 0 576 512",
  "star-half-stroke": "0 0 576 512",
  "file-exclamation": "0 0 384 512",
  code: "0 0 640 512",
  "whiskey-glass": "0 0 512 512",
  "moon-stars": "0 0 512 512",
  "building-circle-exclamation": "0 0 640 512",
  "clothes-hanger": "0 0 640 512",
  "mobile-notch": "0 0 384 512",
  "magnifying-glass-chart": "0 0 512 512",
  "arrow-up-right-from-square": "0 0 512 512",
  "cubes-stacked": "0 0 448 512",
  "images-user": "0 0 576 512",
  "won-sign": "0 0 512 512",
  "image-polaroid-user": "0 0 448 512",
  "virus-covid": "0 0 512 512",
  "square-ellipsis": "0 0 448 512",
  pie: "0 0 576 512",
  "chess-knight-piece": "0 0 320 512",
  "austral-sign": "0 0 448 512",
  "cloud-plus": "0 0 640 512",
  f: "0 0 320 512",
  leaf: "0 0 512 512",
  "bed-bunk": "0 0 576 512",
  road: "0 0 576 512",
  taxi: "0 0 512 512",
  "person-circle-plus": "0 0 576 512",
  "chart-pie": "0 0 576 512",
  "bolt-lightning": "0 0 384 512",
  "clock-eight": "0 0 512 512",
  "sack-xmark": "0 0 512 512",
  "file-xls": "0 0 512 512",
  "file-excel": "0 0 384 512",
  "file-contract": "0 0 384 512",
  "fish-fins": "0 0 576 512",
  "circle-q": "0 0 512 512",
  "building-flag": "0 0 640 512",
  "face-grin-beam": "0 0 512 512",
  "object-ungroup": "0 0 640 512",
  "face-disguise": "0 0 512 512",
  "circle-arrow-down-right": "0 0 512 512",
  "alien-8bit": "0 0 576 512",
  "hand-point-ribbon": "0 0 448 512",
  poop: "0 0 512 512",
  "object-exclude": "0 0 512 512",
  telescope: "0 0 576 512",
  "location-pin": "0 0 384 512",
  "square-list": "0 0 448 512",
  kaaba: "0 0 576 512",
  "toilet-paper": "0 0 640 512",
  "helmet-safety": "0 0 576 512",
  "comment-code": "0 0 512 512",
  "sim-cards": "0 0 448 512",
  starship: "0 0 640 512",
  eject: "0 0 448 512",
  "circle-right": "0 0 512 512",
  "plane-circle-check": "0 0 640 512",
  seal: "0 0 512 512",
  "user-cowboy": "0 0 448 512",
  "hexagon-vertical-nft": "0 0 448 512",
  "face-rolling-eyes": "0 0 512 512",
  "bread-loaf": "0 0 640 512",
  "rings-wedding": "0 0 512 512",
  "object-group": "0 0 576 512",
  "french-fries": "0 0 384 512",
  "chart-line": "0 0 512 512",
  "calendar-arrow-down": "0 0 448 512",
  "send-back": "0 0 640 512",
  "mask-ventilator": "0 0 640 512",
  tickets: "0 0 640 512",
  "signature-lock": "0 0 640 512",
  "arrow-right": "0 0 448 512",
  "signs-post": "0 0 512 512",
  "octagon-plus": "0 0 512 512",
  "cash-register": "0 0 512 512",
  "person-circle-question": "0 0 576 512",
  "melon-slice": "0 0 512 512",
  "space-station-moon": "0 0 512 512",
  "message-smile": "0 0 512 512",
  "cup-straw": "0 0 384 512",
  "left-from-line": "0 0 448 512",
  h: "0 0 384 512",
  "basket-shopping-simple": "0 0 576 512",
  "hands-holding-heart": "0 0 640 512",
  "clock-nine": "0 0 512 512",
  "hammer-brush": "0 0 640 512",
  tarp: "0 0 576 512",
  "face-sleepy": "0 0 512 512",
  "hand-horns": "0 0 384 512",
  "screwdriver-wrench": "0 0 512 512",
  "arrows-to-eye": "0 0 640 512",
  "circle-three-quarters": "0 0 512 512",
  "trophy-star": "0 0 576 512",
  "plug-circle-bolt": "0 0 576 512",
  "face-thermometer": "0 0 576 512",
  "grid-round-4": "0 0 448 512",
  "sign-posts-wrench": "0 0 640 512",
  "shirt-running": "0 0 384 512",
  "book-circle-arrow-up": "0 0 640 512",
  "face-nauseated": "0 0 512 512",
  heart: "0 0 512 512",
  "file-chart-pie": "0 0 384 512",
  "mars-and-venus": "0 0 512 512",
  "house-user": "0 0 576 512",
  "circle-arrow-down-left": "0 0 512 512",
  "dumpster-fire": "0 0 640 512",
  "hexagon-minus": "0 0 512 512",
  "left-to-line": "0 0 448 512",
  "house-crack": "0 0 576 512",
  "paw-simple": "0 0 448 512",
  "arrow-left-long-to-line": "0 0 640 512",
  "brackets-round": "0 0 512 512",
  "martini-glass-citrus": "0 0 576 512",
  "user-shakespeare": "0 0 448 512",
  "arrow-right-to-arc": "0 0 512 512",
  "face-surprise": "0 0 512 512",
  "bottle-water": "0 0 320 512",
  "circle-pause": "0 0 512 512",
  "gauge-circle-plus": "0 0 640 512",
  folders: "0 0 576 512",
  angel: "0 0 576 512",
  "value-absolute": "0 0 512 512",
  rabbit: "0 0 512 512",
  "toilet-paper-slash": "0 0 640 512",
  "circle-euro": "0 0 512 512",
  "apple-whole": "0 0 448 512",
  "kitchen-set": "0 0 576 512",
  "diamond-half": "0 0 512 512",
  "lock-keyhole": "0 0 448 512",
  r: "0 0 320 512",
  "temperature-quarter": "0 0 320 512",
  "square-info": "0 0 448 512",
  "wifi-slash": "0 0 640 512",
  "toilet-paper-xmark": "0 0 512 512",
  "hands-holding-dollar": "0 0 640 512",
  cube: "0 0 512 512",
  "arrow-down-triangle-square": "0 0 576 512",
  "bitcoin-sign": "0 0 320 512",
  shutters: "0 0 512 512",
  "shield-dog": "0 0 512 512",
  "solar-panel": "0 0 640 512",
  "lock-open": "0 0 576 512",
  "table-tree": "0 0 512 512",
  "house-chimney-heart": "0 0 576 512",
  "tally-3": "0 0 640 512",
  elevator: "0 0 512 512",
  "money-bill-transfer": "0 0 640 512",
  "money-bill-trend-up": "0 0 512 512",
  "house-flood-water-circle-arrow-right": "0 0 640 512",
  "square-poll-horizontal": "0 0 448 512",
  circle: "0 0 512 512",
  "left-to-bracket": "0 0 512 512",
  "cart-circle-exclamation": "0 0 640 512",
  sword: "0 0 512 512",
  "backward-fast": "0 0 512 512",
  recycle: "0 0 512 512",
  "user-astronaut": "0 0 448 512",
  interrobang: "0 0 320 512",
  "plane-slash": "0 0 640 512",
  "circle-dashed": "0 0 512 512",
  trademark: "0 0 640 512",
  basketball: "0 0 512 512",
  "fork-knife": "0 0 576 512",
  "satellite-dish": "0 0 512 512",
  "badge-check": "0 0 512 512",
  "circle-up": "0 0 512 512",
  slider: "0 0 512 512",
  "mobile-screen-button": "0 0 384 512",
  "clock-one-thirty": "0 0 512 512",
  "inbox-out": "0 0 512 512",
  "cloud-slash": "0 0 640 512",
  "volume-high": "0 0 640 512",
  "users-rays": "0 0 640 512",
  wallet: "0 0 512 512",
  "octagon-check": "0 0 512 512",
  "flatbread-stuffed": "0 0 576 512",
  "clipboard-check": "0 0 384 512",
  "cart-circle-plus": "0 0 640 512",
  "truck-clock": "0 0 640 512",
  "pool-8-ball": "0 0 512 512",
  "file-audio": "0 0 384 512",
  "turn-down-left": "0 0 512 512",
  "lock-hashtag": "0 0 448 512",
  "chart-radar": "0 0 512 512",
  staff: "0 0 512 512",
  burger: "0 0 512 512",
  "utility-pole": "0 0 512 512",
  "transporter-6": "0 0 512 512",
  "arrow-turn-left": "0 0 512 512",
  wrench: "0 0 512 512",
  bugs: "0 0 576 512",
  "vector-polygon": "0 0 448 512",
  "diagram-nested": "0 0 448 512",
  "rupee-sign": "0 0 448 512",
  "file-image": "0 0 384 512",
  "circle-question": "0 0 512 512",
  "tickets-perforated": "0 0 640 512",
  "image-user": "0 0 512 512",
  buoy: "0 0 576 512",
  "plane-departure": "0 0 640 512",
  "handshake-slash": "0 0 640 512",
  "book-bookmark": "0 0 448 512",
  "border-center-h": "0 0 448 512",
  "can-food": "0 0 384 512",
  typewriter: "0 0 512 512",
  "arrow-right-from-arc": "0 0 512 512",
  "circle-k": "0 0 512 512",
  "face-hand-over-mouth": "0 0 512 512",
  popcorn: "0 0 448 512",
  "house-water": "0 0 576 512",
  "object-subtract": "0 0 512 512",
  "code-branch": "0 0 448 512",
  "warehouse-full": "0 0 640 512",
  "hat-cowboy": "0 0 640 512",
  bridge: "0 0 576 512",
  "phone-flip": "0 0 512 512",
  "arrow-down-from-dotted-line": "0 0 448 512",
  "file-doc": "0 0 512 512",
  "square-quarters": "0 0 448 512",
  "truck-front": "0 0 512 512",
  cat: "0 0 576 512",
  "trash-xmark": "0 0 448 512",
  "circle-caret-left": "0 0 512 512",
  files: "0 0 448 512",
  "anchor-circle-exclamation": "0 0 640 512",
  "face-clouds": "0 0 640 512",
  "user-crown": "0 0 448 512",
  "basket-shopping-plus": "0 0 576 512",
  "truck-field": "0 0 640 512",
  route: "0 0 512 512",
  "cart-circle-check": "0 0 640 512",
  "clipboard-question": "0 0 384 512",
  panorama: "0 0 640 512",
  "comment-medical": "0 0 512 512",
  "teeth-open": "0 0 576 512",
  "user-tie-hair-long": "0 0 448 512",
  "file-circle-minus": "0 0 576 512",
  "head-side-medical": "0 0 512 512",
  "arrow-turn-right": "0 0 512 512",
  tags: "0 0 512 512",
  "wine-glass": "0 0 320 512",
  "forward-fast": "0 0 512 512",
  "face-meh-blank": "0 0 512 512",
  "user-robot": "0 0 448 512",
  "square-parking": "0 0 448 512",
  "card-diamond": "0 0 384 512",
  "face-zipper": "0 0 512 512",
  "face-raised-eyebrow": "0 0 512 512",
  "house-signal": "0 0 576 512",
  "square-chevron-up": "0 0 448 512",
  "nfc-symbol": "0 0 512 512",
  "bars-progress": "0 0 512 512",
  "faucet-drip": "0 0 512 512",
  "arrows-to-line": "0 0 448 512",
  dolphin: "0 0 512 512",
  "arrow-up-right": "0 0 384 512",
  "circle-r": "0 0 512 512",
  "cart-flatbed": "0 0 640 512",
  "ban-smoking": "0 0 512 512",
  "circle-sort-up": "0 0 512 512",
  terminal: "0 0 576 512",
  "mobile-button": "0 0 384 512",
  "house-medical-flag": "0 0 640 512",
  "basket-shopping": "0 0 576 512",
  tape: "0 0 576 512",
  chestnut: "0 0 448 512",
  "bus-simple": "0 0 448 512",
  eye: "0 0 576 512",
  "face-sad-cry": "0 0 512 512",
  heat: "0 0 448 512",
  "ticket-airline": "0 0 640 512",
  "boot-heeled": "0 0 512 512",
  "arrows-minimize": "0 0 512 512",
  "audio-description": "0 0 576 512",
  "person-military-to-person": "0 0 512 512",
  "file-shield": "0 0 576 512",
  hexagon: "0 0 512 512",
  manhole: "0 0 512 512",
  "user-slash": "0 0 640 512",
  pen: "0 0 512 512",
  "tower-observation": "0 0 512 512",
  "floppy-disks": "0 0 512 512",
  "toilet-paper-blank-under": "0 0 512 512",
  "file-code": "0 0 384 512",
  signal: "0 0 640 512",
  pump: "0 0 640 512",
  bus: "0 0 576 512",
  "heart-circle-xmark": "0 0 576 512",
  "arrow-up-left-from-circle": "0 0 512 512",
  "house-chimney": "0 0 576 512",
  "window-maximize": "0 0 512 512",
  dryer: "0 0 448 512",
  "face-frown": "0 0 512 512",
  "chess-bishop-piece": "0 0 256 512",
  "shirt-tank-top": "0 0 384 512",
  diploma: "0 0 640 512",
  screencast: "0 0 576 512",
  walker: "0 0 448 512",
  prescription: "0 0 448 512",
  shop: "0 0 640 512",
  "floppy-disk": "0 0 448 512",
  vihara: "0 0 640 512",
  "face-kiss-closed-eyes": "0 0 512 512",
  "scale-unbalanced": "0 0 640 512",
  "file-user": "0 0 384 512",
  "user-police-tie": "0 0 448 512",
  "face-tongue-money": "0 0 512 512",
  "tennis-ball": "0 0 512 512",
  "square-l": "0 0 448 512",
  "sort-up": "0 0 320 512",
  "calendar-arrow-up": "0 0 448 512",
  "comment-dots": "0 0 512 512",
  "plant-wilt": "0 0 512 512",
  scarf: "0 0 512 512",
  "album-circle-plus": "0 0 576 512",
  "user-nurse-hair-long": "0 0 448 512",
  diamond: "0 0 512 512",
  "square-left": "0 0 448 512",
  "face-grin-squint": "0 0 512 512",
  "circle-ellipsis-vertical": "0 0 512 512",
  "hand-holding-dollar": "0 0 576 512",
  "grid-dividers": "0 0 512 512",
  bacterium: "0 0 512 512",
  "hand-pointer": "0 0 448 512",
  "drum-steelpan": "0 0 576 512",
  "hand-scissors": "0 0 544 512",
  "hands-praying": "0 0 640 512",
  "face-pensive": "0 0 512 512",
  "user-music": "0 0 640 512",
  "arrow-rotate-right": "0 0 512 512",
  "messages-dollar": "0 0 640 512",
  "sensor-on": "0 0 640 512",
  balloon: "0 0 384 512",
  "web-awesome": "0 0 640 512",
  biohazard: "0 0 576 512",
  "chess-queen-piece": "0 0 256 512",
  "location-crosshairs": "0 0 512 512",
  "mars-double": "0 0 640 512",
  "left-from-bracket": "0 0 512 512",
  "house-person-leave": "0 0 640 512",
  "ruler-triangle": "0 0 512 512",
  "card-club": "0 0 384 512",
  "child-dress": "0 0 320 512",
  "users-between-lines": "0 0 640 512",
  "lungs-virus": "0 0 640 512",
  "spinner-third": "0 0 512 512",
  "face-grin-tears": "0 0 640 512",
  phone: "0 0 512 512",
  "computer-mouse-scrollwheel": "0 0 384 512",
  "calendar-xmark": "0 0 448 512",
  "child-reaching": "0 0 384 512",
  "table-layout": "0 0 512 512",
  narwhal: "0 0 640 512",
  "ramp-loading": "0 0 448 512",
  "calendar-circle-plus": "0 0 576 512",
  toothbrush: "0 0 576 512",
  "border-inner": "0 0 448 512",
  "paw-claws": "0 0 512 512",
  "kiwi-fruit": "0 0 448 512",
  "traffic-light-slow": "0 0 320 512",
  "rectangle-code": "0 0 512 512",
  "head-side-virus": "0 0 512 512",
  "keyboard-brightness": "0 0 640 512",
  "books-medical": "0 0 576 512",
  "lightbulb-slash": "0 0 640 512",
  "house-blank": "0 0 576 512",
  "square-5": "0 0 448 512",
  "square-heart": "0 0 448 512",
  puzzle: "0 0 512 512",
  "user-gear": "0 0 640 512",
  "pipe-circle-check": "0 0 640 512",
  "arrow-up-1-9": "0 0 576 512",
  "octagon-exclamation": "0 0 512 512",
  "dial-low": "0 0 576 512",
  "door-closed": "0 0 576 512",
  "laptop-mobile": "0 0 640 512",
  "conveyor-belt-boxes": "0 0 640 512",
  "shield-virus": "0 0 512 512",
  "starfighter-twin-ion-engine-advanced": "0 0 640 512",
  "dice-six": "0 0 448 512",
  "starfighter-twin-ion-engine": "0 0 576 512",
  "rocket-launch": "0 0 512 512",
  "mosquito-net": "0 0 640 512",
  "vent-damper": "0 0 640 512",
  "bridge-water": "0 0 576 512",
  "ban-bug": "0 0 512 512",
  "person-booth": "0 0 576 512",
  "text-width": "0 0 448 512",
  "garage-car": "0 0 640 512",
  "square-kanban": "0 0 448 512",
  "hat-wizard": "0 0 512 512",
  "chart-kanban": "0 0 448 512",
  "pen-fancy": "0 0 512 512",
  "coffee-pot": "0 0 512 512",
  "mouse-field": "0 0 512 512",
  "person-digging": "0 0 576 512",
  "shower-down": "0 0 384 512",
  "box-circle-check": "0 0 576 512",
  brightness: "0 0 512 512",
  "car-side-bolt": "0 0 640 512",
  "file-xml": "0 0 512 512",
  ornament: "0 0 384 512",
  "phone-arrow-down-left": "0 0 512 512",
  "cloud-word": "0 0 640 512",
  "hand-fingers-crossed": "0 0 384 512",
  trash: "0 0 448 512",
  "gauge-simple": "0 0 512 512",
  "arrow-down-small-big": "0 0 576 512",
  "book-medical": "0 0 448 512",
  "face-melting": "0 0 640 512",
  poo: "0 0 512 512",
  "pen-clip-slash": "0 0 640 512",
  "quote-right": "0 0 448 512",
  "scroll-old": "0 0 576 512",
  guitars: "0 0 512 512",
  "phone-xmark": "0 0 640 512",
  hose: "0 0 448 512",
  "clock-six": "0 0 512 512",
  shirt: "0 0 640 512",
  billboard: "0 0 640 512",
  "square-r": "0 0 448 512",
  cubes: "0 0 576 512",
  "envelope-open-dollar": "0 0 512 512",
  divide: "0 0 448 512",
  "sun-cloud": "0 0 640 512",
  "lamp-floor": "0 0 640 512",
  "square-7": "0 0 448 512",
  "tenge-sign": "0 0 384 512",
  headphones: "0 0 512 512",
  "hands-holding": "0 0 640 512",
  campfire: "0 0 512 512",
  "circle-ampersand": "0 0 512 512",
  snowflakes: "0 0 640 512",
  "hands-clapping": "0 0 512 512",
  republican: "0 0 640 512",
  "leaf-maple": "0 0 512 512",
  "arrow-left": "0 0 448 512",
  "person-circle-xmark": "0 0 576 512",
  ruler: "0 0 512 512",
  "arrow-left-from-bracket": "0 0 512 512",
  "cup-straw-swoosh": "0 0 384 512",
  "temperature-sun": "0 0 640 512",
  "align-left": "0 0 448 512",
  "dice-d6": "0 0 448 512",
  restroom: "0 0 640 512",
  "high-definition": "0 0 576 512",
  j: "0 0 320 512",
  galaxy: "0 0 512 512",
  "users-viewfinder": "0 0 640 512",
  "file-video": "0 0 384 512",
  cherries: "0 0 640 512",
  "up-right-from-square": "0 0 512 512",
  "circle-sort": "0 0 512 512",
  "table-cells": "0 0 512 512",
  "bag-shopping-minus": "0 0 448 512",
  "file-pdf": "0 0 512 512",
  siren: "0 0 448 512",
  "arrow-up-to-dotted-line": "0 0 448 512",
  "image-landscape": "0 0 576 512",
  "tank-water": "0 0 448 512",
  "curling-stone": "0 0 576 512",
  "gamepad-modern": "0 0 640 512",
  "messages-question": "0 0 640 512",
  "book-bible": "0 0 448 512",
  o: "0 0 448 512",
  "suitcase-medical": "0 0 512 512",
  "briefcase-arrow-right": "0 0 512 512",
  "expand-wide": "0 0 512 512",
  "clock-eleven-thirty": "0 0 512 512",
  rv: "0 0 640 512",
  "user-secret": "0 0 448 512",
  otter: "0 0 640 512",
  dreidel: "0 0 448 512",
  "person-dress": "0 0 320 512",
  "comment-dollar": "0 0 512 512",
  "business-time": "0 0 640 512",
  "flower-tulip": "0 0 512 512",
  "people-pants-simple": "0 0 512 512",
  "cloud-drizzle": "0 0 512 512",
  "table-cells-large": "0 0 512 512",
  "book-tanakh": "0 0 448 512",
  "solar-system": "0 0 512 512",
  "seal-question": "0 0 512 512",
  "phone-volume": "0 0 512 512",
  "disc-drive": "0 0 512 512",
  "hat-cowboy-side": "0 0 640 512",
  "table-rows": "0 0 512 512",
  "location-exclamation": "0 0 384 512",
  "face-fearful": "0 0 512 512",
  "clipboard-user": "0 0 384 512",
  "bus-school": "0 0 512 512",
  "film-slash": "0 0 640 512",
  "square-arrow-down-right": "0 0 448 512",
  "book-sparkles": "0 0 448 512",
  "washing-machine": "0 0 448 512",
  child: "0 0 320 512",
  "lira-sign": "0 0 320 512",
  "user-visor": "0 0 448 512",
  "file-plus-minus": "0 0 384 512",
  "chess-clock-flip": "0 0 640 512",
  satellite: "0 0 512 512",
  "truck-fire": "0 0 640 512",
  "plane-lock": "0 0 640 512",
  "steering-wheel": "0 0 512 512",
  tag: "0 0 448 512",
  stretcher: "0 0 640 512",
  "book-section": "0 0 448 512",
  inboxes: "0 0 512 512",
  "coffee-bean": "0 0 448 512",
  "circle-yen": "0 0 512 512",
  "brackets-curly": "0 0 576 512",
  "ellipsis-stroke-vertical": "0 0 192 512",
  comment: "0 0 512 512",
  "square-1": "0 0 448 512",
  "cake-candles": "0 0 448 512",
  "head-side": "0 0 512 512",
  "truck-ladder": "0 0 640 512",
  envelope: "0 0 512 512",
  "dolly-empty": "0 0 576 512",
  "face-tissue": "0 0 512 512",
  "angles-up": "0 0 448 512",
  "bin-recycle": "0 0 576 512",
  paperclip: "0 0 448 512",
  "chart-line-down": "0 0 512 512",
  "arrow-right-to-city": "0 0 640 512",
  "lock-a": "0 0 448 512",
  ribbon: "0 0 448 512",
  lungs: "0 0 640 512",
  "person-pinball": "0 0 640 512",
  "arrow-up-9-1": "0 0 576 512",
  "apple-core": "0 0 384 512",
  "circle-y": "0 0 512 512",
  h6: "0 0 640 512",
  "litecoin-sign": "0 0 384 512",
  "bottle-baby": "0 0 512 512",
  "circle-small": "0 0 320 512",
  "border-none": "0 0 448 512",
  "arrow-turn-down-left": "0 0 512 512",
  "circle-wifi-circle-wifi": "0 0 640 512",
  "circle-nodes": "0 0 512 512",
  "parachute-box": "0 0 512 512",
  "reflect-horizontal": "0 0 512 512",
  "message-medical": "0 0 512 512",
  "rugby-ball": "0 0 512 512",
  "comment-music": "0 0 512 512",
  indent: "0 0 448 512",
  "tree-deciduous": "0 0 512 512",
  "puzzle-piece-simple": "0 0 640 512",
  "truck-field-un": "0 0 640 512",
  "nfc-trash": "0 0 640 512",
  hourglass: "0 0 384 512",
  mountain: "0 0 512 512",
  "file-xmark": "0 0 384 512",
  "house-heart": "0 0 576 512",
  "house-chimney-blank": "0 0 576 512",
  "meter-bolt": "0 0 640 512",
  "user-doctor": "0 0 448 512",
  "slash-back": "0 0 320 512",
  "circle-info": "0 0 512 512",
  "fishing-rod": "0 0 448 512",
  "hammer-crash": "0 0 640 512",
  "message-heart": "0 0 512 512",
  "cloud-meatball": "0 0 512 512",
  "camera-polaroid": "0 0 576 512",
  camera: "0 0 512 512",
  "square-virus": "0 0 448 512",
  "cart-arrow-up": "0 0 576 512",
  meteor: "0 0 512 512",
  "car-on": "0 0 512 512",
  sleigh: "0 0 640 512",
  "arrow-down-1-9": "0 0 576 512",
  "buoy-mooring": "0 0 576 512",
  "square-4": "0 0 448 512",
  "hand-holding-droplet": "0 0 576 512",
  "file-eps": "0 0 512 512",
  "tricycle-adult": "0 0 640 512",
  waveform: "0 0 640 512",
  water: "0 0 576 512",
  "star-sharp-half-stroke": "0 0 576 512",
  "nfc-signal": "0 0 512 512",
  "plane-prop": "0 0 576 512",
  "calendar-check": "0 0 448 512",
  "clock-desk": "0 0 448 512",
  "calendar-clock": "0 0 576 512",
  braille: "0 0 640 512",
  "prescription-bottle-medical": "0 0 384 512",
  "plate-utensils": "0 0 640 512",
  "family-pants": "0 0 512 512",
  "hose-reel": "0 0 640 512",
  "house-window": "0 0 576 512",
  landmark: "0 0 512 512",
  truck: "0 0 640 512",
  "music-magnifying-glass": "0 0 640 512",
  crosshairs: "0 0 512 512",
  "cloud-rainbow": "0 0 576 512",
  "person-cane": "0 0 448 512",
  alien: "0 0 448 512",
  tent: "0 0 576 512",
  "laptop-binary": "0 0 640 512",
  "vest-patches": "0 0 448 512",
  "people-dress-simple": "0 0 512 512",
  "check-double": "0 0 448 512",
  "arrow-down-a-z": "0 0 576 512",
  "bowling-ball-pin": "0 0 576 512",
  "bell-school-slash": "0 0 640 512",
  "plus-large": "0 0 512 512",
  "money-bill-wheat": "0 0 512 512",
  "camera-viewfinder": "0 0 512 512",
  "message-music": "0 0 512 512",
  "car-building": "0 0 640 512",
  "border-bottom-right": "0 0 448 512",
  octagon: "0 0 512 512",
  "comment-arrow-up-right": "0 0 512 512",
  "octagon-divide": "0 0 512 512",
  cookie: "0 0 512 512",
  "arrow-rotate-left": "0 0 512 512",
  "tv-music": "0 0 640 512",
  "hard-drive": "0 0 512 512",
  reel: "0 0 448 512",
  "face-grin-squint-tears": "0 0 512 512",
  dumbbell: "0 0 640 512",
  "rectangle-list": "0 0 576 512",
  "tarp-droplet": "0 0 576 512",
  "alarm-exclamation": "0 0 512 512",
  "house-medical-circle-check": "0 0 640 512",
  "traffic-cone": "0 0 512 512",
  grate: "0 0 448 512",
  "arrow-down-right": "0 0 384 512",
  "person-skiing-nordic": "0 0 576 512",
  "calendar-plus": "0 0 448 512",
  "person-from-portal": "0 0 512 512",
  "plane-arrival": "0 0 640 512",
  "cowbell-circle-plus": "0 0 576 512",
  "circle-left": "0 0 512 512",
  "distribute-spacing-vertical": "0 0 512 512",
  "signal-bars-fair": "0 0 640 512",
  sportsball: "0 0 512 512",
  "game-console-handheld-crank": "0 0 576 512",
  "train-subway": "0 0 448 512",
  "chart-gantt": "0 0 512 512",
  "face-smile-upside-down": "0 0 512 512",
  "ball-pile": "0 0 576 512",
  "badge-dollar": "0 0 512 512",
  "money-bills-simple": "0 0 640 512",
  "list-timeline": "0 0 512 512",
  "indian-rupee-sign": "0 0 320 512",
  "crop-simple": "0 0 512 512",
  "money-bill-1": "0 0 576 512",
  "left-long": "0 0 512 512",
  "keyboard-down": "0 0 576 512",
  "circle-up-right": "0 0 512 512",
  "cloud-bolt-moon": "0 0 576 512",
  "turn-left-up": "0 0 384 512",
  dna: "0 0 448 512",
  "virus-slash": "0 0 640 512",
  "bracket-round-right": "0 0 192 512",
  "circle-sterling": "0 0 512 512",
  "circle-5": "0 0 512 512",
  minus: "0 0 448 512",
  "fire-flame": "0 0 384 512",
  "right-to-line": "0 0 448 512",
  gif: "0 0 576 512",
  chess: "0 0 512 512",
  "trash-slash": "0 0 640 512",
  "arrow-left-long": "0 0 512 512",
  "plug-circle-check": "0 0 576 512",
  "font-case": "0 0 640 512",
  "street-view": "0 0 512 512",
  "arrow-down-left": "0 0 384 512",
  "franc-sign": "0 0 320 512",
  "flask-round-poison": "0 0 448 512",
  "volume-off": "0 0 320 512",
  "book-circle-arrow-right": "0 0 640 512",
  "chart-user": "0 0 640 512",
  "hands-asl-interpreting": "0 0 640 512",
  "presentation-screen": "0 0 576 512",
  "circle-bolt": "0 0 512 512",
  "face-smile-halo": "0 0 512 512",
  "cart-circle-arrow-down": "0 0 640 512",
  "house-person-return": "0 0 640 512",
  "message-xmark": "0 0 512 512",
  "file-certificate": "0 0 512 512",
  "user-doctor-hair-long": "0 0 448 512",
  "camera-security": "0 0 448 512",
  gear: "0 0 512 512",
  "droplet-slash": "0 0 640 512",
  "book-heart": "0 0 448 512",
  mosque: "0 0 640 512",
  duck: "0 0 512 512",
  mosquito: "0 0 640 512",
  "star-of-david": "0 0 512 512",
  "flag-swallowtail": "0 0 448 512",
  "person-military-rifle": "0 0 512 512",
  "car-garage": "0 0 640 512",
  "cart-shopping": "0 0 576 512",
  "book-font": "0 0 448 512",
  "shield-plus": "0 0 512 512",
  vials: "0 0 512 512",
  "eye-dropper-full": "0 0 512 512",
  "distribute-spacing-horizontal": "0 0 512 512",
  "tablet-rugged": "0 0 448 512",
  "temperature-snow": "0 0 576 512",
  moped: "0 0 640 512",
  "face-smile-plus": "0 0 640 512",
  "radio-tuner": "0 0 512 512",
  "face-swear": "0 0 640 512",
  "water-arrow-down": "0 0 576 512",
  "scanner-touchscreen": "0 0 512 512",
  "circle-7": "0 0 512 512",
  "plug-circle-plus": "0 0 576 512",
  "person-ski-jumping": "0 0 512 512",
  "place-of-worship": "0 0 640 512",
  "water-arrow-up": "0 0 576 512",
  "waveform-lines": "0 0 640 512",
  split: "0 0 512 512",
  "film-canister": "0 0 576 512",
  "folder-xmark": "0 0 512 512",
  "toilet-paper-blank": "0 0 640 512",
  "tablet-screen": "0 0 448 512",
  "hexagon-vertical-nft-slanted": "0 0 448 512",
  "folder-music": "0 0 512 512",
  "display-medical": "0 0 576 512",
  "share-all": "0 0 576 512",
  peapod: "0 0 512 512",
  "chess-clock": "0 0 640 512",
  axe: "0 0 640 512",
  "square-d": "0 0 448 512",
  "grip-vertical": "0 0 320 512",
  "mobile-signal-out": "0 0 512 512",
  "arrow-turn-up": "0 0 384 512",
  u: "0 0 384 512",
  "arrow-up-from-dotted-line": "0 0 448 512",
  "square-root-variable": "0 0 576 512",
  "light-switch-on": "0 0 384 512",
  "arrow-down-arrow-up": "0 0 576 512",
  raindrops: "0 0 448 512",
  dash: "0 0 512 512",
  clock: "0 0 512 512",
  "input-numeric": "0 0 640 512",
  "truck-tow": "0 0 640 512",
  "backward-step": "0 0 320 512",
  pallet: "0 0 640 512",
  "car-bolt": "0 0 512 512",
  "arrows-maximize": "0 0 512 512",
  faucet: "0 0 512 512",
  "cloud-sleet": "0 0 512 512",
  "lamp-street": "0 0 512 512",
  "list-radio": "0 0 512 512",
  "pen-nib-slash": "0 0 640 512",
  "baseball-bat-ball": "0 0 512 512",
  "square-up-left": "0 0 448 512",
  overline: "0 0 448 512",
  s: "0 0 320 512",
  timeline: "0 0 640 512",
  keyboard: "0 0 576 512",
  "arrows-from-dotted-line": "0 0 448 512",
  "usb-drive": "0 0 640 512",
  ballot: "0 0 448 512",
  "caret-down": "0 0 320 512",
  "location-dot-slash": "0 0 640 512",
  cards: "0 0 640 512",
  "house-chimney-medical": "0 0 576 512",
  "boxing-glove": "0 0 448 512",
  "temperature-three-quarters": "0 0 320 512",
  "bell-school": "0 0 512 512",
  "mobile-screen": "0 0 384 512",
  "plane-up": "0 0 512 512",
  "folder-heart": "0 0 512 512",
  "circle-location-arrow": "0 0 512 512",
  "face-head-bandage": "0 0 576 512",
  "sushi-roll": "0 0 448 512",
  "car-bump": "0 0 640 512",
  "piggy-bank": "0 0 576 512",
  racquet: "0 0 512 512",
  "car-mirrors": "0 0 576 512",
  "industry-windows": "0 0 576 512",
  "bolt-auto": "0 0 576 512",
  "battery-half": "0 0 576 512",
  "flux-capacitor": "0 0 448 512",
  "mountain-city": "0 0 640 512",
  coins: "0 0 512 512",
  "honey-pot": "0 0 448 512",
  olive: "0 0 448 512",
  khanda: "0 0 512 512",
  "filter-list": "0 0 512 512",
  outlet: "0 0 448 512",
  sliders: "0 0 512 512",
  cauldron: "0 0 448 512",
  people: "0 0 640 512",
  "folder-tree": "0 0 576 512",
  "network-wired": "0 0 640 512",
  croissant: "0 0 512 512",
  "map-pin": "0 0 320 512",
  hamsa: "0 0 512 512",
  "cent-sign": "0 0 384 512",
  "swords-laser": "0 0 640 512",
  flask: "0 0 448 512",
  "person-pregnant": "0 0 384 512",
  "square-u": "0 0 448 512",
  "wand-sparkles": "0 0 512 512",
  router: "0 0 576 512",
  "ellipsis-vertical": "0 0 128 512",
  "sword-laser-alt": "0 0 512 512",
  ticket: "0 0 576 512",
  "power-off": "0 0 512 512",
  coin: "0 0 512 512",
  "laptop-slash": "0 0 640 512",
  "right-long": "0 0 512 512",
  "circle-b": "0 0 512 512",
  "person-dress-simple": "0 0 256 512",
  "pipe-collar": "0 0 512 512",
  "lights-holiday": "0 0 640 512",
  citrus: "0 0 512 512",
  "flag-usa": "0 0 448 512",
  "laptop-file": "0 0 640 512",
  tty: "0 0 512 512",
  "chart-tree-map": "0 0 512 512",
  "diagram-next": "0 0 512 512",
  "person-rifle": "0 0 576 512",
  "clock-five-thirty": "0 0 512 512",
  "pipe-valve": "0 0 512 512",
  "arrow-up-from-arc": "0 0 512 512",
  "face-spiral-eyes": "0 0 512 512",
  "compress-wide": "0 0 512 512",
  "circle-phone-hangup": "0 0 512 512",
  "gear-complex-code": "0 0 512 512",
  "house-medical-circle-exclamation": "0 0 640 512",
  badminton: "0 0 576 512",
  "closed-captioning": "0 0 576 512",
  "person-hiking": "0 0 384 512",
  "right-from-line": "0 0 448 512",
  "venus-double": "0 0 640 512",
  images: "0 0 576 512",
  calculator: "0 0 384 512",
  shuttlecock: "0 0 512 512",
  "user-hair": "0 0 448 512",
  "eye-evil": "0 0 640 512",
  "people-pulling": "0 0 576 512",
  n: "0 0 384 512",
  swap: "0 0 640 512",
  garage: "0 0 640 512",
  "cable-car": "0 0 512 512",
  "shovel-snow": "0 0 512 512",
  "cloud-rain": "0 0 512 512",
  "face-lying": "0 0 512 512",
  sprinkler: "0 0 512 512",
  "building-circle-xmark": "0 0 640 512",
  "person-sledding": "0 0 512 512",
  "game-console-handheld": "0 0 384 512",
  ship: "0 0 576 512",
  "clock-six-thirty": "0 0 512 512",
  "battery-slash": "0 0 640 512",
  "tugrik-sign": "0 0 384 512",
  "arrows-down-to-line": "0 0 576 512",
  download: "0 0 512 512",
  "angles-up-down": "0 0 448 512",
  shelves: "0 0 640 512",
  "cloud-snow": "0 0 512 512",
  "face-grin": "0 0 512 512",
  "delete-left": "0 0 576 512",
  oven: "0 0 448 512",
  "cloud-binary": "0 0 640 512",
  "eye-dropper": "0 0 512 512",
  "comment-captions": "0 0 512 512",
  "comments-question": "0 0 640 512",
  scribble: "0 0 512 512",
  "rotate-exclamation": "0 0 512 512",
  "file-circle-check": "0 0 576 512",
  glass: "0 0 384 512",
  loader: "0 0 512 512",
  forward: "0 0 512 512",
  "user-pilot": "0 0 448 512",
  mobile: "0 0 384 512",
  "code-pull-request-closed": "0 0 512 512",
  "face-meh": "0 0 512 512",
  "align-center": "0 0 448 512",
  "book-skull": "0 0 448 512",
  "id-card": "0 0 576 512",
  "face-dotted": "0 0 512 512",
  "face-worried": "0 0 512 512",
  outdent: "0 0 448 512",
  "court-sport": "0 0 640 512",
  "heart-circle-exclamation": "0 0 576 512",
  house: "0 0 576 512",
  "vector-circle": "0 0 512 512",
  "car-circle-bolt": "0 0 640 512",
  "calendar-week": "0 0 448 512",
  "flying-disc": "0 0 448 512",
  "laptop-medical": "0 0 640 512",
  "square-down-right": "0 0 448 512",
  b: "0 0 320 512",
  "seat-airline": "0 0 448 512",
  "moon-over-sun": "0 0 512 512",
  pipe: "0 0 64 512",
  "file-medical": "0 0 384 512",
  potato: "0 0 512 512",
  "dice-one": "0 0 448 512",
  "circle-a": "0 0 512 512",
  "helmet-battle": "0 0 576 512",
  butter: "0 0 640 512",
  "blanket-fire": "0 0 640 512",
  "kiwi-bird": "0 0 576 512",
  castle: "0 0 640 512",
  "golf-club": "0 0 512 512",
  "arrow-right-arrow-left": "0 0 448 512",
  "rotate-right": "0 0 512 512",
  utensils: "0 0 448 512",
  "arrow-up-wide-short": "0 0 576 512",
  "chart-pie-simple-circle-dollar": "0 0 640 512",
  balloons: "0 0 640 512",
  "mill-sign": "0 0 384 512",
  "bowl-rice": "0 0 512 512",
  "timeline-arrow": "0 0 640 512",
  skull: "0 0 512 512",
  "game-board-simple": "0 0 448 512",
  "circle-video": "0 0 512 512",
  "chart-scatter-bubble": "0 0 512 512",
  "house-turret": "0 0 640 512",
  banana: "0 0 576 512",
  "hand-holding-skull": "0 0 576 512",
  "people-dress": "0 0 640 512",
  loveseat: "0 0 512 512",
  "tower-broadcast": "0 0 576 512",
  "truck-pickup": "0 0 640 512",
  "block-quote": "0 0 448 512",
  "up-long": "0 0 320 512",
  stop: "0 0 384 512",
  "code-merge": "0 0 448 512",
  "money-check-dollar-pen": "0 0 640 512",
  "up-from-line": "0 0 384 512",
  upload: "0 0 512 512",
  hurricane: "0 0 384 512",
  "grid-round-2-plus": "0 0 512 512",
  "people-pants": "0 0 640 512",
  mound: "0 0 576 512",
  windsock: "0 0 512 512",
  "circle-half": "0 0 512 512",
  "brake-warning": "0 0 640 512",
  "toilet-portable": "0 0 320 512",
  "compact-disc": "0 0 512 512",
  "file-arrow-down": "0 0 384 512",
  "saxophone-fire": "0 0 640 512",
  "camera-web-slash": "0 0 640 512",
  "folder-medical": "0 0 512 512",
  "folder-gear": "0 0 512 512",
  "hand-wave": "0 0 512 512",
  "arrow-up-arrow-down": "0 0 576 512",
  caravan: "0 0 640 512",
  "shield-cat": "0 0 512 512",
  "message-slash": "0 0 640 512",
  bolt: "0 0 448 512",
  "trash-can-check": "0 0 448 512",
  "glass-water": "0 0 384 512",
  "oil-well": "0 0 576 512",
  "person-simple": "0 0 256 512",
  "arrow-turn-left-up": "0 0 384 512",
  vault: "0 0 576 512",
  mars: "0 0 448 512",
  toilet: "0 0 448 512",
  "plane-circle-xmark": "0 0 640 512",
  "yen-sign": "0 0 320 512",
  "gear-code": "0 0 512 512",
  notes: "0 0 512 512",
  "ruble-sign": "0 0 384 512",
  "trash-undo": "0 0 448 512",
  "champagne-glass": "0 0 320 512",
  "objects-align-center-horizontal": "0 0 512 512",
  sun: "0 0 512 512",
  "trash-can-slash": "0 0 640 512",
  "screen-users": "0 0 640 512",
  guitar: "0 0 512 512",
  "square-arrow-left": "0 0 448 512",
  "square-8": "0 0 448 512",
  "face-smile-hearts": "0 0 640 512",
  "brackets-square": "0 0 448 512",
  "laptop-arrow-down": "0 0 640 512",
  "hockey-stick-puck": "0 0 640 512",
  "house-tree": "0 0 640 512",
  "signal-fair": "0 0 640 512",
  "face-laugh-wink": "0 0 512 512",
  "circle-dollar": "0 0 512 512",
  "horse-head": "0 0 640 512",
  "arrows-repeat": "0 0 512 512",
  "bore-hole": "0 0 512 512",
  industry: "0 0 576 512",
  "image-polaroid": "0 0 448 512",
  "wave-triangle": "0 0 640 512",
  "turn-left-down": "0 0 384 512",
  "person-running-fast": "0 0 512 512",
  "circle-down": "0 0 512 512",
  grill: "0 0 448 512",
  "arrows-turn-to-dots": "0 0 512 512",
  "chart-mixed": "0 0 512 512",
  "florin-sign": "0 0 384 512",
  "arrow-down-short-wide": "0 0 576 512",
  "less-than": "0 0 384 512",
  "display-code": "0 0 576 512",
  "face-drooling": "0 0 512 512",
  "oil-temperature": "0 0 576 512",
  "square-question": "0 0 448 512",
  "air-conditioner": "0 0 576 512",
  "angle-down": "0 0 448 512",
  mountains: "0 0 640 512",
  omega: "0 0 448 512",
  "car-tunnel": "0 0 512 512",
  "person-dolly-empty": "0 0 512 512",
  "pan-food": "0 0 640 512",
  "head-side-cough": "0 0 640 512",
  "grip-lines": "0 0 448 512",
  "thumbs-down": "0 0 512 512",
  "user-lock": "0 0 640 512",
  "arrow-right-long": "0 0 512 512",
  "tickets-airline": "0 0 640 512",
  "tent-double-peak": "0 0 576 512",
  "anchor-circle-xmark": "0 0 640 512",
  ellipsis: "0 0 448 512",
  "nfc-slash": "0 0 640 512",
  "chess-pawn": "0 0 320 512",
  "kit-medical": "0 0 576 512",
  "grid-2-plus": "0 0 512 512",
  bells: "0 0 640 512",
  "person-through-window": "0 0 640 512",
  toolbox: "0 0 512 512",
  "envelope-dot": "0 0 576 512",
  "magnifying-glass-waveform": "0 0 512 512",
  "hands-holding-circle": "0 0 640 512",
  bug: "0 0 512 512",
  "bowl-chopsticks": "0 0 512 512",
  "credit-card": "0 0 576 512",
  "circle-s": "0 0 512 512",
  "box-ballot": "0 0 576 512",
  car: "0 0 512 512",
  "hand-holding-hand": "0 0 576 512",
  "user-tie-hair": "0 0 448 512",
  "podium-star": "0 0 448 512",
  "user-hair-mullet": "0 0 448 512",
  "microphone-stand": "0 0 512 512",
  "book-open-reader": "0 0 512 512",
  "family-dress": "0 0 512 512",
  "circle-x": "0 0 512 512",
  cabin: "0 0 512 512",
  "mountain-sun": "0 0 640 512",
  "chart-simple-horizontal": "0 0 448 512",
  bluetooth: "0 0 384 512",
  "arrows-left-right-to-line": "0 0 640 512",
  "hand-back-point-left": "0 0 512 512",
  "message-dots": "0 0 512 512",
  "file-heart": "0 0 384 512",
  "beer-mug": "0 0 512 512",
  "dice-d20": "0 0 512 512",
  drone: "0 0 512 512",
  "truck-droplet": "0 0 640 512",
  "file-circle-xmark": "0 0 576 512",
  "temperature-arrow-up": "0 0 576 512",
  medal: "0 0 512 512",
  "person-fairy": "0 0 640 512",
  bed: "0 0 640 512",
  "book-copy": "0 0 576 512",
  "square-h": "0 0 448 512",
  "square-c": "0 0 448 512",
  "clock-two": "0 0 512 512",
  "square-ellipsis-vertical": "0 0 448 512",
  "calendar-users": "0 0 640 512",
  podcast: "0 0 448 512",
  bee: "0 0 576 512",
  "temperature-full": "0 0 320 512",
  bell: "0 0 448 512",
  "candy-bar": "0 0 640 512",
  "xmark-large": "0 0 448 512",
  pinata: "0 0 512 512",
  "file-ppt": "0 0 512 512",
  "arrows-from-line": "0 0 448 512",
  superscript: "0 0 512 512",
  "bowl-spoon": "0 0 512 512",
  "hexagon-check": "0 0 512 512",
  "plug-circle-xmark": "0 0 576 512",
  "star-of-life": "0 0 512 512",
  "phone-slash": "0 0 640 512",
  "traffic-light-stop": "0 0 320 512",
  "paint-roller": "0 0 512 512",
  "accent-grave": "0 0 192 512",
  "handshake-angle": "0 0 640 512",
  "circle-0": "0 0 512 512",
  "dial-med-low": "0 0 576 512",
  "location-dot": "0 0 384 512",
  crab: "0 0 512 512",
  "box-open-full": "0 0 640 512",
  file: "0 0 384 512",
  "greater-than": "0 0 384 512",
  quotes: "0 0 576 512",
  pretzel: "0 0 512 512",
  "t-rex": "0 0 640 512",
  "person-swimming": "0 0 576 512",
  "arrow-down": "0 0 384 512",
  "user-robot-xmarks": "0 0 448 512",
  "message-quote": "0 0 512 512",
  "candy-corn": "0 0 640 512",
  "folder-magnifying-glass": "0 0 512 512",
  notebook: "0 0 512 512",
  "circle-wifi": "0 0 512 512",
  droplet: "0 0 384 512",
  "bullseye-pointer": "0 0 512 512",
  eraser: "0 0 576 512",
  "hexagon-image": "0 0 512 512",
  "earth-americas": "0 0 512 512",
  "file-svg": "0 0 512 512",
  "crate-apple": "0 0 512 512",
  "person-burst": "0 0 640 512",
  "game-board": "0 0 448 512",
  "hat-chef": "0 0 512 512",
  "hand-back-point-right": "0 0 512 512",
  dove: "0 0 512 512",
  "snowflake-droplets": "0 0 640 512",
  "battery-empty": "0 0 576 512",
  "grid-4": "0 0 448 512",
  socks: "0 0 512 512",
  "face-sunglasses": "0 0 512 512",
  inbox: "0 0 512 512",
  "square-0": "0 0 448 512",
  section: "0 0 256 512",
  "square-this-way-up": "0 0 448 512",
  "gauge-high": "0 0 512 512",
  "square-ampersand": "0 0 448 512",
  "envelope-open-text": "0 0 512 512",
  "lamp-desk": "0 0 512 512",
  hospital: "0 0 640 512",
  "poll-people": "0 0 640 512",
  "whiskey-glass-ice": "0 0 512 512",
  "wine-bottle": "0 0 512 512",
  "chess-rook": "0 0 448 512",
  "user-bounty-hunter": "0 0 448 512",
  "bars-staggered": "0 0 512 512",
  "diagram-sankey": "0 0 576 512",
  "cloud-hail-mixed": "0 0 512 512",
  "circle-up-left": "0 0 512 512",
  dharmachakra: "0 0 512 512",
  "objects-align-left": "0 0 512 512",
  "oil-can-drip": "0 0 640 512",
  "face-smiling-hands": "0 0 640 512",
  broccoli: "0 0 512 512",
  "route-interstate": "0 0 512 512",
  "ear-muffs": "0 0 640 512",
  hotdog: "0 0 512 512",
  "transporter-empty": "0 0 512 512",
  "person-walking-with-cane": "0 0 512 512",
  "angle-90": "0 0 448 512",
  "rectangle-terminal": "0 0 512 512",
  kite: "0 0 640 512",
  drum: "0 0 512 512",
  scrubber: "0 0 512 512",
  "ice-cream": "0 0 448 512",
  "heart-circle-bolt": "0 0 576 512",
  "fish-bones": "0 0 576 512",
  "deer-rudolph": "0 0 576 512",
  fax: "0 0 512 512",
  paragraph: "0 0 448 512",
  "head-side-heart": "0 0 512 512",
  "square-e": "0 0 448 512",
  "meter-fire": "0 0 640 512",
  "cloud-hail": "0 0 512 512",
  "check-to-slot": "0 0 576 512",
  "money-from-bracket": "0 0 640 512",
  "star-half": "0 0 576 512",
  "car-bus": "0 0 640 512",
  speaker: "0 0 384 512",
  timer: "0 0 512 512",
  "boxes-stacked": "0 0 576 512",
  "landmark-magnifying-glass": "0 0 640 512",
  "grill-hot": "0 0 448 512",
  "ballot-check": "0 0 448 512",
  link: "0 0 640 512",
  "ear-listen": "0 0 512 512",
  "file-minus": "0 0 384 512",
  "tree-city": "0 0 640 512",
  play: "0 0 384 512",
  font: "0 0 448 512",
  "cup-togo": "0 0 448 512",
  "square-down-left": "0 0 448 512",
  "burger-lettuce": "0 0 512 512",
  "table-cells-row-lock": "0 0 640 512",
  "rupiah-sign": "0 0 512 512",
  "magnifying-glass": "0 0 512 512",
  "table-tennis-paddle-ball": "0 0 512 512",
  "person-dots-from-line": "0 0 576 512",
  "chevrons-down": "0 0 512 512",
  "trash-can-arrow-up": "0 0 448 512",
  "signal-good": "0 0 640 512",
  "location-question": "0 0 384 512",
  "floppy-disk-circle-xmark": "0 0 576 512",
  "naira-sign": "0 0 448 512",
  peach: "0 0 512 512",
  "taxi-bus": "0 0 640 512",
  "bracket-curly": "0 0 256 512",
  lobster: "0 0 512 512",
  "cart-flatbed-empty": "0 0 640 512",
  colon: "0 0 192 512",
  "cart-arrow-down": "0 0 576 512",
  wand: "0 0 512 512",
  "walkie-talkie": "0 0 384 512",
  "file-pen": "0 0 576 512",
  receipt: "0 0 384 512",
  "table-picnic": "0 0 512 512",
  "square-pen": "0 0 448 512",
  "circle-microphone-lines": "0 0 512 512",
  "display-slash": "0 0 640 512",
  "suitcase-rolling": "0 0 384 512",
  "person-circle-exclamation": "0 0 576 512",
  "transporter-2": "0 0 512 512",
  "hands-holding-diamond": "0 0 640 512",
  "money-bill-simple-wave": "0 0 576 512",
  "chevron-down": "0 0 512 512",
  "battery-full": "0 0 576 512",
  "bell-plus": "0 0 448 512",
  "book-arrow-right": "0 0 640 512",
  hospitals: "0 0 576 512",
  club: "0 0 512 512",
  "skull-crossbones": "0 0 448 512",
  "droplet-degree": "0 0 512 512",
  "code-compare": "0 0 512 512",
  "list-ul": "0 0 512 512",
  "hand-holding-magic": "0 0 576 512",
  "watermelon-slice": "0 0 512 512",
  "circle-ellipsis": "0 0 512 512",
  "school-lock": "0 0 640 512",
  "tower-cell": "0 0 576 512",
  "sd-cards": "0 0 448 512",
  "jug-bottle": "0 0 640 512",
  "down-long": "0 0 320 512",
  envelopes: "0 0 576 512",
  "phone-office": "0 0 576 512",
  "ranking-star": "0 0 640 512",
  "chess-king": "0 0 448 512",
  "nfc-pen": "0 0 576 512",
  "person-harassing": "0 0 576 512",
  "magnifying-glass-play": "0 0 512 512",
  "hat-winter": "0 0 512 512",
  "brazilian-real-sign": "0 0 512 512",
  "landmark-dome": "0 0 512 512",
  "bone-break": "0 0 640 512",
  "arrow-up": "0 0 384 512",
  "down-from-dotted-line": "0 0 448 512",
  tv: "0 0 640 512",
  "border-left": "0 0 448 512",
  "circle-divide": "0 0 512 512",
  shrimp: "0 0 512 512",
  "list-check": "0 0 512 512",
  "diagram-subtask": "0 0 512 512",
  "jug-detergent": "0 0 384 512",
  "circle-user": "0 0 512 512",
  "square-y": "0 0 448 512",
  "user-doctor-hair": "0 0 448 512",
  "planet-ringed": "0 0 512 512",
  mushroom: "0 0 512 512",
  "user-shield": "0 0 640 512",
  megaphone: "0 0 576 512",
  "wreath-laurel": "0 0 640 512",
  "circle-exclamation-check": "0 0 640 512",
  wind: "0 0 512 512",
  "box-dollar": "0 0 448 512",
  "car-burst": "0 0 640 512",
  y: "0 0 384 512",
  "user-headset": "0 0 448 512",
  "arrows-retweet": "0 0 640 512",
  "person-snowboarding": "0 0 512 512",
  "square-chevron-right": "0 0 448 512",
  "lacrosse-stick-ball": "0 0 576 512",
  "truck-fast": "0 0 640 512",
  "user-magnifying-glass": "0 0 640 512",
  "star-sharp": "0 0 576 512",
  "comment-heart": "0 0 512 512",
  "circle-1": "0 0 512 512",
  "circle-star": "0 0 512 512",
  fish: "0 0 576 512",
  "cloud-fog": "0 0 640 512",
  waffle: "0 0 512 512",
  "music-note": "0 0 384 512",
  "hexagon-exclamation": "0 0 512 512",
  "cart-shopping-fast": "0 0 640 512",
  "object-union": "0 0 512 512",
  "user-graduate": "0 0 448 512",
  starfighter: "0 0 640 512",
  "circle-half-stroke": "0 0 512 512",
  "arrow-right-long-to-line": "0 0 640 512",
  "square-arrow-down": "0 0 448 512",
  "diamond-half-stroke": "0 0 512 512",
  clapperboard: "0 0 512 512",
  "square-chevron-left": "0 0 448 512",
  "phone-intercom": "0 0 512 512",
  "link-horizontal": "0 0 640 512",
  mango: "0 0 512 512",
  "music-note-slash": "0 0 640 512",
  "circle-radiation": "0 0 512 512",
  "face-tongue-sweat": "0 0 576 512",
  "globe-stand": "0 0 448 512",
  baseball: "0 0 512 512",
  "circle-p": "0 0 512 512",
  "award-simple": "0 0 384 512",
  "jet-fighter-up": "0 0 512 512",
  "diagram-project": "0 0 576 512",
  pedestal: "0 0 448 512",
  "chart-pyramid": "0 0 512 512",
  sidebar: "0 0 512 512",
  "snowman-head": "0 0 448 512",
  copy: "0 0 448 512",
  "burger-glass": "0 0 640 512",
  "volume-xmark": "0 0 576 512",
  "hand-sparkles": "0 0 640 512",
  "bars-filter": "0 0 448 512",
  "paintbrush-pencil": "0 0 576 512",
  "party-bell": "0 0 512 512",
  "user-vneck-hair": "0 0 448 512",
  "jack-o-lantern": "0 0 576 512",
  grip: "0 0 448 512",
  "share-from-square": "0 0 576 512",
  keynote: "0 0 512 512",
  "child-combatant": "0 0 576 512",
  gun: "0 0 576 512",
  "square-phone": "0 0 448 512",
  "hat-beach": "0 0 640 512",
  plus: "0 0 448 512",
  expand: "0 0 448 512",
  computer: "0 0 640 512",
  fort: "0 0 640 512",
  "cloud-check": "0 0 640 512",
  xmark: "0 0 384 512",
  "face-smirking": "0 0 512 512",
  "arrows-up-down-left-right": "0 0 512 512",
  "chalkboard-user": "0 0 640 512",
  rhombus: "0 0 448 512",
  "claw-marks": "0 0 576 512",
  "peso-sign": "0 0 384 512",
  "face-smile-tongue": "0 0 512 512",
  "cart-circle-xmark": "0 0 640 512",
  "building-shield": "0 0 576 512",
  "circle-phone-flip": "0 0 512 512",
  baby: "0 0 448 512",
  "users-line": "0 0 640 512",
  "quote-left": "0 0 448 512",
  tractor: "0 0 640 512",
  "down-from-bracket": "0 0 448 512",
  "key-skeleton": "0 0 448 512",
  "trash-arrow-up": "0 0 448 512",
  "arrow-down-up-lock": "0 0 640 512",
  "arrow-down-to-bracket": "0 0 448 512",
  "lines-leaning": "0 0 384 512",
  "square-q": "0 0 448 512",
  "ruler-combined": "0 0 512 512",
  symbols: "0 0 512 512",
  copyright: "0 0 512 512",
  "flask-gear": "0 0 640 512",
  "highlighter-line": "0 0 576 512",
  "bracket-square": "0 0 192 512",
  "island-tropical": "0 0 512 512",
  "arrow-right-from-line": "0 0 448 512",
  h2: "0 0 640 512",
  equals: "0 0 448 512",
  "cake-slice": "0 0 512 512",
  "building-magnifying-glass": "0 0 603 512",
  peanut: "0 0 512 512",
  "wrench-simple": "0 0 384 512",
  blender: "0 0 512 512",
  teeth: "0 0 576 512",
  "tally-2": "0 0 640 512",
  "shekel-sign": "0 0 448 512",
  cars: "0 0 640 512",
  "axe-battle": "0 0 512 512",
  "user-hair-long": "0 0 448 512",
  map: "0 0 576 512",
  "arrow-left-from-arc": "0 0 512 512",
  "file-circle-info": "0 0 576 512",
  "face-disappointed": "0 0 512 512",
  "lasso-sparkles": "0 0 576 512",
  "clock-eleven": "0 0 512 512",
  rocket: "0 0 512 512",
  "siren-on": "0 0 640 512",
  "clock-ten": "0 0 512 512",
  "candle-holder": "0 0 448 512",
  "video-arrow-down-left": "0 0 576 512",
  "photo-film": "0 0 640 512",
  "floppy-disk-circle-arrow-right": "0 0 576 512",
  "folder-minus": "0 0 512 512",
  "planet-moon": "0 0 576 512",
  "face-eyes-xmarks": "0 0 512 512",
  "chart-scatter": "0 0 512 512",
  "circle-gf": "0 0 512 512",
  "display-arrow-down": "0 0 576 512",
  store: "0 0 576 512",
  "arrow-trend-up": "0 0 576 512",
  "plug-circle-minus": "0 0 576 512",
  "olive-branch": "0 0 640 512",
  angle: "0 0 448 512",
  "vacuum-robot": "0 0 512 512",
  "sign-hanging": "0 0 512 512",
  "square-divide": "0 0 448 512",
  "folder-check": "0 0 512 512",
  "signal-stream-slash": "0 0 640 512",
  "bezier-curve": "0 0 640 512",
  "eye-dropper-half": "0 0 512 512",
  "store-lock": "0 0 640 512",
  "bell-slash": "0 0 640 512",
  "cloud-bolt-sun": "0 0 640 512",
  "camera-slash": "0 0 640 512",
  "comment-quote": "0 0 512 512",
  tablet: "0 0 448 512",
  "school-flag": "0 0 576 512",
  "message-code": "0 0 512 512",
  "glass-half": "0 0 384 512",
  fill: "0 0 512 512",
  "message-minus": "0 0 512 512",
  "angle-up": "0 0 448 512",
  dinosaur: "0 0 640 512",
  "drumstick-bite": "0 0 512 512",
  "link-horizontal-slash": "0 0 640 512",
  "holly-berry": "0 0 512 512",
  nose: "0 0 448 512",
  "arrow-left-to-arc": "0 0 512 512",
  "chevron-left": "0 0 320 512",
  bacteria: "0 0 640 512",
  clouds: "0 0 640 512",
  "money-bill-simple": "0 0 576 512",
  "hand-lizard": "0 0 512 512",
  "table-pivot": "0 0 512 512",
  "filter-slash": "0 0 640 512",
  "trash-can-undo": "0 0 448 512",
  notdef: "0 0 384 512",
  disease: "0 0 512 512",
  "person-to-door": "0 0 576 512",
  turntable: "0 0 576 512",
  "briefcase-medical": "0 0 512 512",
  genderless: "0 0 384 512",
  "chevron-right": "0 0 320 512",
  "signal-weak": "0 0 640 512",
  "clock-five": "0 0 512 512",
  retweet: "0 0 576 512",
  "car-rear": "0 0 512 512",
  "pump-soap": "0 0 448 512",
  "computer-classic": "0 0 448 512",
  frame: "0 0 448 512",
  "video-slash": "0 0 640 512",
  "battery-quarter": "0 0 576 512",
  "ellipsis-stroke": "0 0 512 512",
  radio: "0 0 512 512",
  "baby-carriage": "0 0 512 512",
  "face-expressionless": "0 0 512 512",
  "down-to-dotted-line": "0 0 448 512",
  "cloud-music": "0 0 640 512",
  "traffic-light": "0 0 320 512",
  "cloud-minus": "0 0 640 512",
  thermometer: "0 0 512 512",
  "shield-minus": "0 0 512 512",
  "vr-cardboard": "0 0 640 512",
  "car-tilt": "0 0 640 512",
  "gauge-circle-minus": "0 0 640 512",
  "brightness-low": "0 0 512 512",
  "hand-middle-finger": "0 0 448 512",
  percent: "0 0 384 512",
  "truck-moving": "0 0 640 512",
  "glass-water-droplet": "0 0 384 512",
  "conveyor-belt": "0 0 640 512",
  "location-check": "0 0 384 512",
  "coin-vertical": "0 0 384 512",
  display: "0 0 576 512",
  "person-sign": "0 0 512 512",
  "face-smile": "0 0 512 512",
  "phone-hangup": "0 0 640 512",
  "signature-slash": "0 0 640 512",
  thumbtack: "0 0 384 512",
  "wheat-slash": "0 0 640 512",
  trophy: "0 0 576 512",
  "clouds-sun": "0 0 640 512",
  "person-praying": "0 0 448 512",
  hammer: "0 0 576 512",
  "face-vomit": "0 0 576 512",
  speakers: "0 0 640 512",
  "tty-answer": "0 0 640 512",
  "mug-tea-saucer": "0 0 640 512",
  "diagram-lean-canvas": "0 0 640 512",
  alt: "0 0 640 512",
  dial: "0 0 576 512",
  "hand-peace": "0 0 512 512",
  "circle-trash": "0 0 512 512",
  rotate: "0 0 512 512",
  "circle-quarters": "0 0 512 512",
  spinner: "0 0 512 512",
  "tower-control": "0 0 448 512",
  "arrow-up-triangle-square": "0 0 576 512",
  whale: "0 0 640 512",
  robot: "0 0 640 512",
  peace: "0 0 512 512",
  "party-horn": "0 0 512 512",
  gears: "0 0 640 512",
  "sun-bright": "0 0 512 512",
  warehouse: "0 0 640 512",
  "conveyor-belt-arm": "0 0 640 512",
  "lock-keyhole-open": "0 0 576 512",
  "square-fragile": "0 0 448 512",
  "arrow-up-right-dots": "0 0 576 512",
  "square-n": "0 0 448 512",
  splotch: "0 0 512 512",
  "face-grin-hearts": "0 0 512 512",
  meter: "0 0 512 512",
  mandolin: "0 0 512 512",
  "dice-four": "0 0 448 512",
  "sim-card": "0 0 384 512",
  transgender: "0 0 512 512",
  mercury: "0 0 384 512",
  "up-from-bracket": "0 0 448 512",
  "knife-kitchen": "0 0 576 512",
  "border-right": "0 0 448 512",
  "arrow-turn-down": "0 0 384 512",
  spade: "0 0 512 512",
  "card-spade": "0 0 384 512",
  "line-columns": "0 0 512 512",
  ant: "0 0 640 512",
  "arrow-right-to-line": "0 0 448 512",
  "person-falling-burst": "0 0 640 512",
  "flag-pennant": "0 0 448 512",
  "conveyor-belt-empty": "0 0 640 512",
  "user-group-simple": "0 0 640 512",
  award: "0 0 384 512",
  "ticket-simple": "0 0 576 512",
  building: "0 0 384 512",
  "angles-left": "0 0 512 512",
  camcorder: "0 0 576 512",
  pancakes: "0 0 512 512",
  "album-circle-user": "0 0 576 512",
  "subtitles-slash": "0 0 640 512",
  qrcode: "0 0 448 512",
  "dice-d10": "0 0 512 512",
  fireplace: "0 0 640 512",
  browser: "0 0 512 512",
  "pen-paintbrush": "0 0 576 512",
  "fish-cooked": "0 0 576 512",
  "chair-office": "0 0 448 512",
  "magnifying-glass-music": "0 0 512 512",
  "nesting-dolls": "0 0 640 512",
  "clock-rotate-left": "0 0 512 512",
  trumpet: "0 0 640 512",
  "face-grin-beam-sweat": "0 0 512 512",
  "fire-smoke": "0 0 640 512",
  "phone-missed": "0 0 640 512",
  "file-export": "0 0 576 512",
  shield: "0 0 512 512",
  "arrow-up-short-wide": "0 0 576 512",
  "arrows-repeat-1": "0 0 512 512",
  "gun-slash": "0 0 640 512",
  avocado: "0 0 512 512",
  binary: "0 0 384 512",
  "glasses-round": "0 0 576 512",
  "phone-plus": "0 0 640 512",
  ditto: "0 0 320 512",
  "person-seat": "0 0 384 512",
  "house-medical": "0 0 576 512",
  "golf-ball-tee": "0 0 384 512",
  "circle-chevron-left": "0 0 512 512",
  "house-chimney-window": "0 0 576 512",
  scythe: "0 0 640 512",
  "pen-nib": "0 0 512 512",
  "ban-parking": "0 0 512 512",
  "tent-arrow-turn-left": "0 0 576 512",
  "face-diagonal-mouth": "0 0 512 512",
  "diagram-cells": "0 0 512 512",
  "cricket-bat-ball": "0 0 512 512",
  tents: "0 0 640 512",
  "wand-magic": "0 0 512 512",
  dog: "0 0 576 512",
  "pen-line": "0 0 576 512",
  "atom-simple": "0 0 512 512",
  ampersand: "0 0 448 512",
  carrot: "0 0 512 512",
  "arrow-up-from-line": "0 0 384 512",
  moon: "0 0 384 512",
  "pen-slash": "0 0 640 512",
  "wine-glass-empty": "0 0 320 512",
  "square-star": "0 0 448 512",
  cheese: "0 0 512 512",
  "send-backward": "0 0 512 512",
  "yin-yang": "0 0 512 512",
  music: "0 0 512 512",
  "compass-slash": "0 0 640 512",
  "clock-one": "0 0 512 512",
  "file-music": "0 0 384 512",
  "code-commit": "0 0 640 512",
  "temperature-low": "0 0 512 512",
  "person-biking": "0 0 640 512",
  "display-chart-up-circle-currency": "0 0 640 512",
  skeleton: "0 0 512 512",
  "circle-g": "0 0 512 512",
  "circle-arrow-up-left": "0 0 512 512",
  "coin-blank": "0 0 512 512",
  broom: "0 0 576 512",
  vacuum: "0 0 640 512",
  "shield-heart": "0 0 512 512",
  "card-heart": "0 0 384 512",
  "lightbulb-cfl-on": "0 0 640 512",
  melon: "0 0 512 512",
  gopuram: "0 0 512 512",
  "earth-oceania": "0 0 512 512",
  "container-storage": "0 0 640 512",
  "face-pouting": "0 0 512 512",
  "square-xmark": "0 0 448 512",
  "face-explode": "0 0 512 512",
  hashtag: "0 0 448 512",
  "up-right-and-down-left-from-center": "0 0 512 512",
  "oil-can": "0 0 640 512",
  t: "0 0 384 512",
  "transformer-bolt": "0 0 384 512",
  hippo: "0 0 640 512",
  "chart-column": "0 0 512 512",
  "cassette-vhs": "0 0 576 512",
  infinity: "0 0 640 512",
  "vial-circle-check": "0 0 512 512",
  chimney: "0 0 448 512",
  "object-intersect": "0 0 512 512",
  "person-arrow-down-to-line": "0 0 640 512",
  voicemail: "0 0 640 512",
  "block-brick": "0 0 448 512",
  fan: "0 0 512 512",
  "bags-shopping": "0 0 576 512",
  "paragraph-left": "0 0 384 512",
  "person-walking-luggage": "0 0 576 512",
  "caravan-simple": "0 0 640 512",
  turtle: "0 0 576 512",
  "pencil-mechanical": "0 0 512 512",
  "up-down": "0 0 256 512",
  "cloud-moon-rain": "0 0 576 512",
  "booth-curtain": "0 0 512 512",
  calendar: "0 0 448 512",
  "box-heart": "0 0 448 512",
  trailer: "0 0 640 512",
  "user-doctor-message": "0 0 640 512",
  bahai: "0 0 576 512",
  lighthouse: "0 0 640 512",
  "amp-guitar": "0 0 512 512",
  "sd-card": "0 0 384 512",
  "volume-slash": "0 0 640 512",
  "border-bottom": "0 0 448 512",
  "wifi-weak": "0 0 640 512",
  dragon: "0 0 640 512",
  "shoe-prints": "0 0 640 512",
  "circle-plus": "0 0 512 512",
  "face-grin-tongue-wink": "0 0 512 512",
  "hand-holding": "0 0 576 512",
  "plug-circle-exclamation": "0 0 576 512",
  "link-slash": "0 0 640 512",
  clone: "0 0 512 512",
  "person-walking-arrow-loop-left": "0 0 640 512",
  "arrow-up-z-a": "0 0 576 512",
  "fire-flame-curved": "0 0 384 512",
  tornado: "0 0 448 512",
  "file-circle-plus": "0 0 576 512",
  "delete-right": "0 0 576 512",
  "book-quran": "0 0 448 512",
  "circle-quarter": "0 0 512 512",
  anchor: "0 0 576 512",
  "border-all": "0 0 448 512",
  function: "0 0 640 512",
  "face-angry": "0 0 512 512",
  "people-simple": "0 0 512 512",
  "cookie-bite": "0 0 512 512",
  "arrow-trend-down": "0 0 576 512",
  rss: "0 0 448 512",
  "face-monocle": "0 0 512 512",
  "draw-polygon": "0 0 448 512",
  "scale-balanced": "0 0 640 512",
  "calendar-lines": "0 0 448 512",
  "arrow-down-big-small": "0 0 576 512",
  "gauge-simple-high": "0 0 512 512",
  "do-not-enter": "0 0 512 512",
  shower: "0 0 512 512",
  "dice-d8": "0 0 512 512",
  desktop: "0 0 576 512",
  m: "0 0 448 512",
  "spinner-scale": "0 0 512 512",
  "grip-dots-vertical": "0 0 256 512",
  "face-viewfinder": "0 0 512 512",
  "soft-serve": "0 0 384 512",
  h5: "0 0 640 512",
  "hand-back-point-down": "0 0 448 512",
  "table-list": "0 0 512 512",
  "basket-shopping-minus": "0 0 576 512",
  "comment-sms": "0 0 512 512",
  rectangle: "0 0 512 512",
  "clipboard-list-check": "0 0 384 512",
  turkey: "0 0 640 512",
  book: "0 0 448 512",
  "user-plus": "0 0 640 512",
  "ice-skate": "0 0 576 512",
  check: "0 0 448 512",
  "battery-three-quarters": "0 0 576 512",
  tomato: "0 0 512 512",
  "sword-laser": "0 0 512 512",
  "house-circle-check": "0 0 640 512",
  buildings: "0 0 512 512",
  "angle-left": "0 0 320 512",
  "cart-flatbed-boxes": "0 0 640 512",
  "diagram-successor": "0 0 512 512",
  "truck-arrow-right": "0 0 640 512",
  "square-w": "0 0 448 512",
  "arrows-split-up-and-left": "0 0 512 512",
  lamp: "0 0 448 512",
  airplay: "0 0 576 512",
  "hand-fist": "0 0 448 512",
  "shield-quartered": "0 0 512 512",
  "slash-forward": "0 0 320 512",
  "location-pen": "0 0 384 512",
  "cloud-moon": "0 0 640 512",
  "pot-food": "0 0 640 512",
  briefcase: "0 0 512 512",
  "person-falling": "0 0 512 512",
  "image-portrait": "0 0 384 512",
  "user-tag": "0 0 640 512",
  rug: "0 0 640 512",
  "print-slash": "0 0 640 512",
  "earth-europe": "0 0 512 512",
  "cart-flatbed-suitcase": "0 0 640 512",
  "hand-back-point-ribbon": "0 0 448 512",
  "rectangle-xmark": "0 0 512 512",
  "tire-rugged": "0 0 512 512",
  "lightbulb-dollar": "0 0 384 512",
  cowbell: "0 0 448 512",
  "baht-sign": "0 0 320 512",
  corner: "0 0 448 512",
  "chevrons-right": "0 0 512 512",
  "book-open": "0 0 576 512",
  "book-journal-whills": "0 0 448 512",
  inhaler: "0 0 576 512",
  handcuffs: "0 0 640 512",
  snake: "0 0 512 512",
  "triangle-exclamation": "0 0 512 512",
  "note-medical": "0 0 448 512",
  database: "0 0 448 512",
  "down-left": "0 0 384 512",
  share: "0 0 512 512",
  "face-thinking": "0 0 512 512",
  "turn-down-right": "0 0 512 512",
  "bottle-droplet": "0 0 320 512",
  "mask-face": "0 0 640 512",
  "hill-rockslide": "0 0 576 512",
  "scanner-keyboard": "0 0 512 512",
  "circle-o": "0 0 512 512",
  "grid-horizontal": "0 0 448 512",
  "message-dollar": "0 0 512 512",
  "right-left": "0 0 512 512",
  "columns-3": "0 0 640 512",
  "paper-plane": "0 0 512 512",
  "road-circle-exclamation": "0 0 640 512",
  dungeon: "0 0 512 512",
  "hand-holding-box": "0 0 576 512",
  "input-text": "0 0 640 512",
  "window-flip": "0 0 512 512",
  "align-right": "0 0 448 512",
  "scanner-gun": "0 0 576 512",
  tire: "0 0 512 512",
  engine: "0 0 640 512",
  "money-bill-1-wave": "0 0 576 512",
  "life-ring": "0 0 512 512",
  hands: "0 0 608 512",
  "circle-caret-right": "0 0 512 512",
  "turn-left": "0 0 512 512",
  wheat: "0 0 512 512",
  "file-spreadsheet": "0 0 384 512",
  "audio-description-slash": "0 0 640 512",
  "bell-ring": "0 0 512 512",
  "calendar-day": "0 0 448 512",
  "water-ladder": "0 0 576 512",
  "arrows-up-down": "0 0 320 512",
  "chess-pawn-piece": "0 0 256 512",
  "face-grimace": "0 0 512 512",
  "wheelchair-move": "0 0 448 512",
  "turn-down": "0 0 384 512",
  "square-s": "0 0 448 512",
  "rectangle-barcode": "0 0 576 512",
  "person-walking-arrow-right": "0 0 640 512",
  "square-envelope": "0 0 448 512",
  dice: "0 0 640 512",
  unicorn: "0 0 640 512",
  "bowling-ball": "0 0 512 512",
  pompebled: "0 0 512 512",
  brain: "0 0 512 512",
  "watch-smart": "0 0 384 512",
  "book-user": "0 0 448 512",
  "sensor-cloud": "0 0 640 512",
  "clapperboard-play": "0 0 512 512",
  bandage: "0 0 640 512",
  "calendar-minus": "0 0 448 512",
  "circle-xmark": "0 0 512 512",
  "circle-4": "0 0 512 512",
  gifts: "0 0 640 512",
  "album-collection": "0 0 512 512",
  hotel: "0 0 512 512",
  "earth-asia": "0 0 512 512",
  "id-card-clip": "0 0 576 512",
  "magnifying-glass-plus": "0 0 512 512",
  "thumbs-up": "0 0 512 512",
  "cloud-showers": "0 0 512 512",
  "user-clock": "0 0 640 512",
  onion: "0 0 448 512",
  "clock-twelve-thirty": "0 0 512 512",
  "arrow-down-to-dotted-line": "0 0 448 512",
  "hand-dots": "0 0 512 512",
  "file-invoice": "0 0 384 512",
  "window-minimize": "0 0 512 512",
  "rectangle-wide": "0 0 640 512",
  "comment-arrow-up": "0 0 512 512",
  garlic: "0 0 512 512",
  "mug-saucer": "0 0 640 512",
  brush: "0 0 384 512",
  "tree-decorated": "0 0 448 512",
  mask: "0 0 576 512",
  "calendar-heart": "0 0 448 512",
  "magnifying-glass-minus": "0 0 512 512",
  flower: "0 0 448 512",
  "arrow-down-from-arc": "0 0 512 512",
  "right-left-large": "0 0 640 512",
  "ruler-vertical": "0 0 256 512",
  "circles-overlap": "0 0 640 512",
  "user-large": "0 0 512 512",
  "starship-freighter": "0 0 576 512",
  "train-tram": "0 0 448 512",
  "bridge-suspension": "0 0 640 512",
  "trash-check": "0 0 448 512",
  "user-nurse": "0 0 448 512",
  boombox: "0 0 640 512",
  syringe: "0 0 512 512",
  "cloud-sun": "0 0 640 512",
  "shield-exclamation": "0 0 512 512",
  "stopwatch-20": "0 0 448 512",
  "square-full": "0 0 512 512",
  "grip-dots": "0 0 448 512",
  "comment-exclamation": "0 0 512 512",
  "pen-swirl": "0 0 512 512",
  falafel: "0 0 576 512",
  "circle-2": "0 0 512 512",
  magnet: "0 0 448 512",
  jar: "0 0 320 512",
  gramophone: "0 0 384 512",
  "dice-d12": "0 0 512 512",
  "note-sticky": "0 0 448 512",
  down: "0 0 384 512",
  "hundred-points": "0 0 512 512",
  "paperclip-vertical": "0 0 384 512",
  "wind-warning": "0 0 640 512",
  "location-pin-slash": "0 0 640 512",
  "face-sad-sweat": "0 0 576 512",
  "bug-slash": "0 0 640 512",
  cupcake: "0 0 448 512",
  "light-switch-off": "0 0 384 512",
  "toggle-large-off": "0 0 576 512",
  "pen-fancy-slash": "0 0 640 512",
  "truck-container": "0 0 640 512",
  boot: "0 0 512 512",
  "arrow-up-from-water-pump": "0 0 576 512",
  "file-check": "0 0 384 512",
  bone: "0 0 576 512",
  "cards-blank": "0 0 640 512",
  "circle-3": "0 0 512 512",
  "bench-tree": "0 0 640 512",
  "keyboard-brightness-low": "0 0 640 512",
  "ski-boot-ski": "0 0 640 512",
  "brain-circuit": "0 0 512 512",
  "user-injured": "0 0 448 512",
  "block-brick-fire": "0 0 640 512",
  "face-sad-tear": "0 0 512 512",
  plane: "0 0 576 512",
  "tent-arrows-down": "0 0 576 512",
  exclamation: "0 0 64 512",
  "arrows-spin": "0 0 512 512",
  "face-smile-relaxed": "0 0 512 512",
  "comment-xmark": "0 0 512 512",
  print: "0 0 512 512",
  "turkish-lira-sign": "0 0 384 512",
  "face-nose-steam": "0 0 512 512",
  "circle-waveform-lines": "0 0 512 512",
  "dollar-sign": "0 0 320 512",
  "ferris-wheel": "0 0 512 512",
  "computer-speaker": "0 0 640 512",
  "skull-cow": "0 0 640 512",
  x: "0 0 384 512",
  "magnifying-glass-dollar": "0 0 512 512",
  "users-gear": "0 0 640 512",
  "person-military-pointing": "0 0 576 512",
  "building-columns": "0 0 512 512",
  "circle-t": "0 0 512 512",
  sack: "0 0 512 512",
  "grid-2": "0 0 512 512",
  "camera-cctv": "0 0 576 512",
  umbrella: "0 0 576 512",
  trowel: "0 0 512 512",
  "horizontal-rule": "0 0 640 512",
  "bed-front": "0 0 512 512",
  d: "0 0 384 512",
  stapler: "0 0 640 512",
  "masks-theater": "0 0 640 512",
  "file-gif": "0 0 512 512",
  "kip-sign": "0 0 384 512",
  "face-woozy": "0 0 512 512",
  "cloud-question": "0 0 640 512",
  pineapple: "0 0 512 512",
  "hand-point-left": "0 0 512 512",
  "gallery-thumbnails": "0 0 576 512",
  "circle-j": "0 0 512 512",
  eyes: "0 0 640 512",
  "handshake-simple": "0 0 640 512",
  "page-caret-up": "0 0 384 512",
  "jet-fighter": "0 0 640 512",
  comet: "0 0 512 512",
  "square-share-nodes": "0 0 448 512",
  "reflect-vertical": "0 0 512 512",
  "shield-keyhole": "0 0 512 512",
  "file-mp4": "0 0 512 512",
  barcode: "0 0 512 512",
  bulldozer: "0 0 640 512",
  "plus-minus": "0 0 384 512",
  "square-sliders-vertical": "0 0 448 512",
  video: "0 0 576 512",
  "message-middle": "0 0 512 512",
  "graduation-cap": "0 0 640 512",
  "hand-holding-medical": "0 0 576 512",
  "person-circle-check": "0 0 576 512",
  "square-z": "0 0 448 512",
  "message-text": "0 0 512 512",
  "turn-up": "0 0 384 512",
  monero: "0 0 496 512",
  hooli: "0 0 640 512",
  yelp: "0 0 384 512",
  "cc-visa": "0 0 576 512",
  lastfm: "0 0 512 512",
  shopware: "0 0 512 512",
  "creative-commons-nc": "0 0 496 512",
  aws: "0 0 640 512",
  redhat: "0 0 512 512",
  yoast: "0 0 448 512",
  cloudflare: "0 0 640 512",
  ups: "0 0 384 512",
  pixiv: "0 0 448 512",
  wpexplorer: "0 0 512 512",
  dyalog: "0 0 416 512",
  bity: "0 0 496 512",
  stackpath: "0 0 448 512",
  buysellads: "0 0 448 512",
  "first-order": "0 0 448 512",
  modx: "0 0 448 512",
  guilded: "0 0 448 512",
  vnv: "0 0 640 512",
  "square-js": "0 0 448 512",
  microsoft: "0 0 448 512",
  qq: "0 0 448 512",
  orcid: "0 0 512 512",
  java: "0 0 384 512",
  invision: "0 0 448 512",
  "creative-commons-pd-alt": "0 0 496 512",
  centercode: "0 0 512 512",
  "glide-g": "0 0 448 512",
  drupal: "0 0 448 512",
  jxl: "0 0 448 512",
  "hire-a-helper": "0 0 512 512",
  "creative-commons-by": "0 0 496 512",
  unity: "0 0 448 512",
  whmcs: "0 0 448 512",
  rocketchat: "0 0 576 512",
  vk: "0 0 448 512",
  untappd: "0 0 640 512",
  mailchimp: "0 0 448 512",
  "css3-alt": "0 0 384 512",
  "square-reddit": "0 0 448 512",
  "vimeo-v": "0 0 448 512",
  contao: "0 0 512 512",
  "square-font-awesome": "0 0 448 512",
  deskpro: "0 0 480 512",
  brave: "0 0 448 512",
  sistrix: "0 0 448 512",
  "square-instagram": "0 0 448 512",
  "battle-net": "0 0 512 512",
  "the-red-yeti": "0 0 512 512",
  "square-hacker-news": "0 0 448 512",
  edge: "0 0 512 512",
  threads: "0 0 448 512",
  napster: "0 0 496 512",
  "square-snapchat": "0 0 448 512",
  "google-plus-g": "0 0 640 512",
  artstation: "0 0 512 512",
  markdown: "0 0 640 512",
  sourcetree: "0 0 448 512",
  "google-plus": "0 0 512 512",
  diaspora: "0 0 512 512",
  foursquare: "0 0 368 512",
  "stack-overflow": "0 0 384 512",
  "github-alt": "0 0 480 512",
  "phoenix-squadron": "0 0 512 512",
  pagelines: "0 0 384 512",
  algolia: "0 0 512 512",
  "red-river": "0 0 448 512",
  "creative-commons-sa": "0 0 496 512",
  safari: "0 0 512 512",
  google: "0 0 488 512",
  "square-font-awesome-stroke": "0 0 448 512",
  atlassian: "0 0 512 512",
  "linkedin-in": "0 0 448 512",
  "digital-ocean": "0 0 512 512",
  nimblr: "0 0 384 512",
  chromecast: "0 0 512 512",
  evernote: "0 0 384 512",
  "hacker-news": "0 0 448 512",
  "creative-commons-sampling": "0 0 496 512",
  adversal: "0 0 512 512",
  "creative-commons": "0 0 496 512",
  "watchman-monitoring": "0 0 512 512",
  fonticons: "0 0 448 512",
  weixin: "0 0 576 512",
  shirtsinbulk: "0 0 448 512",
  codepen: "0 0 512 512",
  "git-alt": "0 0 448 512",
  lyft: "0 0 512 512",
  rev: "0 0 448 512",
  windows: "0 0 448 512",
  "wizards-of-the-coast": "0 0 640 512",
  "square-viadeo": "0 0 448 512",
  meetup: "0 0 512 512",
  centos: "0 0 448 512",
  adn: "0 0 496 512",
  cloudsmith: "0 0 512 512",
  opensuse: "0 0 640 512",
  "pied-piper-alt": "0 0 576 512",
  "square-dribbble": "0 0 448 512",
  codiepie: "0 0 472 512",
  node: "0 0 640 512",
  mix: "0 0 448 512",
  steam: "0 0 496 512",
  "cc-apple-pay": "0 0 576 512",
  scribd: "0 0 384 512",
  debian: "0 0 448 512",
  openid: "0 0 448 512",
  instalod: "0 0 512 512",
  expeditedssl: "0 0 496 512",
  sellcast: "0 0 448 512",
  "square-twitter": "0 0 448 512",
  "r-project": "0 0 581 512",
  delicious: "0 0 448 512",
  freebsd: "0 0 448 512",
  vuejs: "0 0 448 512",
  accusoft: "0 0 640 512",
  ioxhost: "0 0 640 512",
  "fonticons-fi": "0 0 384 512",
  "app-store": "0 0 512 512",
  "cc-mastercard": "0 0 576 512",
  "itunes-note": "0 0 384 512",
  golang: "0 0 640 512",
  kickstarter: "0 0 448 512",
  grav: "0 0 512 512",
  weibo: "0 0 512 512",
  uncharted: "0 0 448 512",
  firstdraft: "0 0 384 512",
  "square-youtube": "0 0 448 512",
  "wikipedia-w": "0 0 640 512",
  wpressr: "0 0 496 512",
  angellist: "0 0 448 512",
  "galactic-republic": "0 0 496 512",
  "nfc-directional": "0 0 512 512",
  skype: "0 0 448 512",
  joget: "0 0 496 512",
  fedora: "0 0 448 512",
  "stripe-s": "0 0 384 512",
  meta: "0 0 640 512",
  laravel: "0 0 512 512",
  hotjar: "0 0 512 512",
  "bluetooth-b": "0 0 320 512",
  "square-letterboxd": "0 0 448 512",
  "sticker-mule": "0 0 576 512",
  "creative-commons-zero": "0 0 496 512",
  hips: "0 0 640 512",
  behance: "0 0 576 512",
  reddit: "0 0 512 512",
  discord: "0 0 640 512",
  chrome: "0 0 512 512",
  "app-store-ios": "0 0 448 512",
  "cc-discover": "0 0 576 512",
  wpbeginner: "0 0 512 512",
  confluence: "0 0 512 512",
  shoelace: "0 0 512 512",
  mdb: "0 0 576 512",
  dochub: "0 0 416 512",
  "accessible-icon": "0 0 448 512",
  ebay: "0 0 640 512",
  amazon: "0 0 448 512",
  unsplash: "0 0 448 512",
  yarn: "0 0 496 512",
  "square-steam": "0 0 448 512",
  "500px": "0 0 448 512",
  "square-vimeo": "0 0 448 512",
  asymmetrik: "0 0 576 512",
  gratipay: "0 0 496 512",
  apple: "0 0 384 512",
  hive: "0 0 512 512",
  gitkraken: "0 0 592 512",
  keybase: "0 0 448 512",
  "apple-pay": "0 0 640 512",
  padlet: "0 0 640 512",
  "amazon-pay": "0 0 640 512",
  "square-github": "0 0 448 512",
  stumbleupon: "0 0 512 512",
  fedex: "0 0 640 512",
  "phoenix-framework": "0 0 640 512",
  shopify: "0 0 448 512",
  neos: "0 0 512 512",
  "square-threads": "0 0 448 512",
  hackerrank: "0 0 512 512",
  researchgate: "0 0 448 512",
  swift: "0 0 448 512",
  angular: "0 0 448 512",
  speakap: "0 0 448 512",
  angrycreative: "0 0 640 512",
  "y-combinator": "0 0 448 512",
  empire: "0 0 496 512",
  envira: "0 0 448 512",
  "google-scholar": "0 0 512 512",
  "square-gitlab": "0 0 448 512",
  studiovinari: "0 0 512 512",
  "pied-piper": "0 0 480 512",
  wordpress: "0 0 512 512",
  "product-hunt": "0 0 512 512",
  firefox: "0 0 512 512",
  linode: "0 0 448 512",
  goodreads: "0 0 448 512",
  "square-odnoklassniki": "0 0 448 512",
  jsfiddle: "0 0 576 512",
  sith: "0 0 448 512",
  themeisle: "0 0 512 512",
  page4: "0 0 496 512",
  hashnode: "0 0 512 512",
  react: "0 0 512 512",
  "cc-paypal": "0 0 576 512",
  squarespace: "0 0 512 512",
  "cc-stripe": "0 0 576 512",
  "creative-commons-share": "0 0 496 512",
  bitcoin: "0 0 512 512",
  keycdn: "0 0 512 512",
  opera: "0 0 496 512",
  "itch-io": "0 0 512 512",
  umbraco: "0 0 510 512",
  "galactic-senate": "0 0 512 512",
  ubuntu: "0 0 576 512",
  draft2digital: "0 0 480 512",
  stripe: "0 0 640 512",
  houzz: "0 0 448 512",
  gg: "0 0 512 512",
  dhl: "0 0 640 512",
  "square-pinterest": "0 0 448 512",
  xing: "0 0 384 512",
  blackberry: "0 0 512 512",
  "creative-commons-pd": "0 0 496 512",
  playstation: "0 0 576 512",
  quinscape: "0 0 512 512",
  less: "0 0 640 512",
  "blogger-b": "0 0 448 512",
  opencart: "0 0 640 512",
  vine: "0 0 384 512",
  "signal-messenger": "0 0 512 512",
  paypal: "0 0 384 512",
  gitlab: "0 0 512 512",
  typo3: "0 0 448 512",
  "reddit-alien": "0 0 512 512",
  yahoo: "0 0 512 512",
  dailymotion: "0 0 448 512",
  affiliatetheme: "0 0 512 512",
  "pied-piper-pp": "0 0 448 512",
  bootstrap: "0 0 576 512",
  odnoklassniki: "0 0 320 512",
  mintbit: "0 0 512 512",
  ethereum: "0 0 320 512",
  "speaker-deck": "0 0 512 512",
  "creative-commons-nc-eu": "0 0 496 512",
  patreon: "0 0 512 512",
  avianex: "0 0 512 512",
  ello: "0 0 496 512",
  gofore: "0 0 400 512",
  bimobject: "0 0 448 512",
  "brave-reverse": "0 0 448 512",
  "facebook-f": "0 0 320 512",
  "square-google-plus": "0 0 448 512",
  mandalorian: "0 0 448 512",
  "first-order-alt": "0 0 496 512",
  osi: "0 0 512 512",
  "google-wallet": "0 0 448 512",
  "d-and-d-beyond": "0 0 640 512",
  periscope: "0 0 448 512",
  fulcrum: "0 0 320 512",
  cloudscale: "0 0 448 512",
  forumbee: "0 0 448 512",
  mizuni: "0 0 496 512",
  schlix: "0 0 448 512",
  "square-xing": "0 0 448 512",
  bandcamp: "0 0 512 512",
  wpforms: "0 0 448 512",
  cloudversify: "0 0 616 512",
  usps: "0 0 576 512",
  megaport: "0 0 496 512",
  magento: "0 0 448 512",
  spotify: "0 0 496 512",
  "optin-monster": "0 0 576 512",
  fly: "0 0 384 512",
  aviato: "0 0 640 512",
  itunes: "0 0 448 512",
  cuttlefish: "0 0 440 512",
  blogger: "0 0 448 512",
  flickr: "0 0 448 512",
  viber: "0 0 512 512",
  soundcloud: "0 0 640 512",
  digg: "0 0 512 512",
  "tencent-weibo": "0 0 384 512",
  letterboxd: "0 0 640 512",
  symfony: "0 0 512 512",
  maxcdn: "0 0 512 512",
  etsy: "0 0 384 512",
  "facebook-messenger": "0 0 512 512",
  audible: "0 0 640 512",
  "think-peaks": "0 0 576 512",
  bilibili: "0 0 512 512",
  erlang: "0 0 640 512",
  "x-twitter": "0 0 512 512",
  "cotton-bureau": "0 0 512 512",
  dashcube: "0 0 448 512",
  "42-group": "0 0 640 512",
  "stack-exchange": "0 0 448 512",
  elementor: "0 0 512 512",
  "square-pied-piper": "0 0 448 512",
  "creative-commons-nd": "0 0 496 512",
  palfed: "0 0 576 512",
  superpowers: "0 0 448 512",
  resolving: "0 0 496 512",
  xbox: "0 0 512 512",
  "square-web-awesome-stroke": "0 0 448 512",
  searchengin: "0 0 460 512",
  tiktok: "0 0 448 512",
  "square-facebook": "0 0 448 512",
  renren: "0 0 512 512",
  linux: "0 0 448 512",
  glide: "0 0 448 512",
  linkedin: "0 0 448 512",
  hubspot: "0 0 512 512",
  deploydog: "0 0 512 512",
  twitch: "0 0 512 512",
  ravelry: "0 0 512 512",
  mixer: "0 0 512 512",
  "square-lastfm": "0 0 448 512",
  vimeo: "0 0 448 512",
  mendeley: "0 0 640 512",
  uniregistry: "0 0 384 512",
  figma: "0 0 384 512",
  "creative-commons-remix": "0 0 496 512",
  "cc-amazon-pay": "0 0 576 512",
  dropbox: "0 0 528 512",
  instagram: "0 0 448 512",
  cmplid: "0 0 640 512",
  upwork: "0 0 641 512",
  facebook: "0 0 512 512",
  gripfire: "0 0 384 512",
  "jedi-order": "0 0 448 512",
  uikit: "0 0 448 512",
  "fort-awesome-alt": "0 0 512 512",
  phabricator: "0 0 496 512",
  ussunnah: "0 0 482 512",
  earlybirds: "0 0 480 512",
  "trade-federation": "0 0 496 512",
  autoprefixer: "0 0 640 512",
  whatsapp: "0 0 448 512",
  "square-upwork": "0 0 448 512",
  slideshare: "0 0 512 512",
  "google-play": "0 0 512 512",
  viadeo: "0 0 448 512",
  line: "0 0 512 512",
  "google-drive": "0 0 512 512",
  servicestack: "0 0 496 512",
  simplybuilt: "0 0 512 512",
  bitbucket: "0 0 512 512",
  imdb: "0 0 448 512",
  deezer: "0 0 576 512",
  "raspberry-pi": "0 0 407 512",
  jira: "0 0 496 512",
  docker: "0 0 640 512",
  screenpal: "0 0 512 512",
  gitter: "0 0 384 512",
  "d-and-d": "0 0 576 512",
  microblog: "0 0 448 512",
  "cc-diners-club": "0 0 576 512",
  "gg-circle": "0 0 512 512",
  "pied-piper-hat": "0 0 640 512",
  "kickstarter-k": "0 0 448 512",
  yandex: "0 0 256 512",
  readme: "0 0 576 512",
  html5: "0 0 384 512",
  sellsy: "0 0 640 512",
  "square-web-awesome": "0 0 448 512",
  sass: "0 0 640 512",
  wirsindhandwerk: "0 0 512 512",
  buromobelexperte: "0 0 448 512",
  salesforce: "0 0 640 512",
  "octopus-deploy": "0 0 512 512",
  medapps: "0 0 320 512",
  ns8: "0 0 640 512",
  "pinterest-p": "0 0 384 512",
  apper: "0 0 640 512",
  "fort-awesome": "0 0 512 512",
  waze: "0 0 512 512",
  bluesky: "0 0 576 512",
  "cc-jcb": "0 0 576 512",
  snapchat: "0 0 512 512",
  "fantasy-flight-games": "0 0 512 512",
  rust: "0 0 512 512",
  wix: "0 0 640 512",
  "square-behance": "0 0 448 512",
  supple: "0 0 640 512",
  webflow: "0 0 640 512",
  rebel: "0 0 512 512",
  css3: "0 0 512 512",
  staylinked: "0 0 440 512",
  kaggle: "0 0 320 512",
  "space-awesome": "0 0 512 512",
  deviantart: "0 0 320 512",
  cpanel: "0 0 640 512",
  "goodreads-g": "0 0 384 512",
  "square-git": "0 0 448 512",
  "square-tumblr": "0 0 448 512",
  trello: "0 0 448 512",
  "creative-commons-nc-jp": "0 0 496 512",
  "get-pocket": "0 0 448 512",
  perbyte: "0 0 448 512",
  grunt: "0 0 384 512",
  weebly: "0 0 512 512",
  connectdevelop: "0 0 576 512",
  leanpub: "0 0 576 512",
  "black-tie": "0 0 448 512",
  themeco: "0 0 448 512",
  python: "0 0 448 512",
  android: "0 0 576 512",
  bots: "0 0 640 512",
  "free-code-camp": "0 0 576 512",
  hornbill: "0 0 512 512",
  js: "0 0 448 512",
  ideal: "0 0 576 512",
  git: "0 0 512 512",
  dev: "0 0 448 512",
  sketch: "0 0 512 512",
  "yandex-international": "0 0 320 512",
  "cc-amex": "0 0 576 512",
  uber: "0 0 448 512",
  github: "0 0 496 512",
  php: "0 0 640 512",
  alipay: "0 0 448 512",
  youtube: "0 0 576 512",
  skyatlas: "0 0 640 512",
  "firefox-browser": "0 0 512 512",
  replyd: "0 0 448 512",
  suse: "0 0 640 512",
  jenkins: "0 0 512 512",
  twitter: "0 0 512 512",
  rockrms: "0 0 496 512",
  pinterest: "0 0 496 512",
  buffer: "0 0 448 512",
  npm: "0 0 576 512",
  yammer: "0 0 512 512",
  btc: "0 0 384 512",
  dribbble: "0 0 512 512",
  "stumbleupon-circle": "0 0 496 512",
  "internet-explorer": "0 0 512 512",
  stubber: "0 0 448 512",
  telegram: "0 0 496 512",
  "old-republic": "0 0 496 512",
  odysee: "0 0 512 512",
  "square-whatsapp": "0 0 448 512",
  "node-js": "0 0 448 512",
  "edge-legacy": "0 0 512 512",
  slack: "0 0 448 512",
  medrt: "0 0 544 512",
  usb: "0 0 640 512",
  tumblr: "0 0 320 512",
  vaadin: "0 0 448 512",
  quora: "0 0 448 512",
  "square-x-twitter": "0 0 448 512",
  reacteurope: "0 0 576 512",
  medium: "0 0 640 512",
  amilia: "0 0 448 512",
  mixcloud: "0 0 640 512",
  flipboard: "0 0 448 512",
  viacoin: "0 0 384 512",
  "critical-role": "0 0 448 512",
  sitrox: "0 0 448 512",
  discourse: "0 0 448 512",
  joomla: "0 0 448 512",
  mastodon: "0 0 448 512",
  airbnb: "0 0 448 512",
  "wolf-pack-battalion": "0 0 512 512",
  "buy-n-large": "0 0 576 512",
  gulp: "0 0 256 512",
  "creative-commons-sampling-plus": "0 0 496 512",
  strava: "0 0 384 512",
  ember: "0 0 640 512",
  "canadian-maple-leaf": "0 0 512 512",
  teamspeak: "0 0 576 512",
  pushed: "0 0 432 512",
  "wordpress-simple": "0 0 512 512",
  nutritionix: "0 0 400 512",
  wodu: "0 0 640 512",
  "google-pay": "0 0 640 512",
  intercom: "0 0 448 512",
  zhihu: "0 0 640 512",
  korvue: "0 0 446 512",
  pix: "0 0 512 512",
  "steam-symbol": "0 0 448 512",
  "bicycle-stacked-red-lockup-rgb": "0 0 629 512",
  "bicycle-symbol-red-rgb": "0 0 1012 512",
  "bicycle-inline-red-lockup-rgb": "0 0 1941 512",
  "bicycle-logotype-blue-rgb": "0 0 1718 512"
};

type SpriteId = keyof typeof faIconNameToVariantsMap;

export type IncFaIconName = keyof typeof iconNameToSpriteIdMap;
export type IncFaIconVariant = "regular" | "solid" | "custom" | "brands";

const brandIconNames = [
  "monero",
  "hooli",
  "yelp",
  "cc-visa",
  "lastfm",
  "shopware",
  "creative-commons-nc",
  "aws",
  "redhat",
  "yoast",
  "cloudflare",
  "ups",
  "pixiv",
  "wpexplorer",
  "dyalog",
  "bity",
  "stackpath",
  "buysellads",
  "first-order",
  "modx",
  "guilded",
  "vnv",
  "square-js",
  "microsoft",
  "qq",
  "orcid",
  "java",
  "invision",
  "creative-commons-pd-alt",
  "centercode",
  "glide-g",
  "drupal",
  "jxl",
  "hire-a-helper",
  "creative-commons-by",
  "unity",
  "whmcs",
  "rocketchat",
  "vk",
  "untappd",
  "mailchimp",
  "css3-alt",
  "square-reddit",
  "vimeo-v",
  "contao",
  "square-font-awesome",
  "deskpro",
  "brave",
  "sistrix",
  "square-instagram",
  "battle-net",
  "the-red-yeti",
  "square-hacker-news",
  "edge",
  "threads",
  "napster",
  "square-snapchat",
  "google-plus-g",
  "artstation",
  "markdown",
  "sourcetree",
  "google-plus",
  "diaspora",
  "foursquare",
  "stack-overflow",
  "github-alt",
  "phoenix-squadron",
  "pagelines",
  "algolia",
  "red-river",
  "creative-commons-sa",
  "safari",
  "google",
  "square-font-awesome-stroke",
  "atlassian",
  "linkedin-in",
  "digital-ocean",
  "nimblr",
  "chromecast",
  "evernote",
  "hacker-news",
  "creative-commons-sampling",
  "adversal",
  "creative-commons",
  "watchman-monitoring",
  "fonticons",
  "weixin",
  "shirtsinbulk",
  "codepen",
  "git-alt",
  "lyft",
  "rev",
  "windows",
  "wizards-of-the-coast",
  "square-viadeo",
  "meetup",
  "centos",
  "adn",
  "cloudsmith",
  "opensuse",
  "pied-piper-alt",
  "square-dribbble",
  "codiepie",
  "node",
  "mix",
  "steam",
  "cc-apple-pay",
  "scribd",
  "debian",
  "openid",
  "instalod",
  "expeditedssl",
  "sellcast",
  "square-twitter",
  "r-project",
  "delicious",
  "freebsd",
  "vuejs",
  "accusoft",
  "ioxhost",
  "fonticons-fi",
  "app-store",
  "cc-mastercard",
  "itunes-note",
  "golang",
  "kickstarter",
  "grav",
  "weibo",
  "uncharted",
  "firstdraft",
  "square-youtube",
  "wikipedia-w",
  "wpressr",
  "angellist",
  "galactic-republic",
  "nfc-directional",
  "skype",
  "joget",
  "fedora",
  "stripe-s",
  "meta",
  "laravel",
  "hotjar",
  "bluetooth-b",
  "square-letterboxd",
  "sticker-mule",
  "creative-commons-zero",
  "hips",
  "behance",
  "reddit",
  "discord",
  "chrome",
  "app-store-ios",
  "cc-discover",
  "wpbeginner",
  "confluence",
  "shoelace",
  "mdb",
  "dochub",
  "accessible-icon",
  "ebay",
  "amazon",
  "unsplash",
  "yarn",
  "square-steam",
  "500px",
  "square-vimeo",
  "asymmetrik",
  "font-awesome",
  "gratipay",
  "apple",
  "hive",
  "gitkraken",
  "keybase",
  "apple-pay",
  "padlet",
  "amazon-pay",
  "square-github",
  "stumbleupon",
  "fedex",
  "phoenix-framework",
  "shopify",
  "neos",
  "square-threads",
  "hackerrank",
  "researchgate",
  "swift",
  "angular",
  "speakap",
  "angrycreative",
  "y-combinator",
  "empire",
  "envira",
  "google-scholar",
  "square-gitlab",
  "studiovinari",
  "pied-piper",
  "wordpress",
  "product-hunt",
  "firefox",
  "linode",
  "goodreads",
  "square-odnoklassniki",
  "jsfiddle",
  "sith",
  "themeisle",
  "page4",
  "hashnode",
  "react",
  "cc-paypal",
  "squarespace",
  "cc-stripe",
  "creative-commons-share",
  "bitcoin",
  "keycdn",
  "opera",
  "itch-io",
  "umbraco",
  "galactic-senate",
  "ubuntu",
  "draft2digital",
  "stripe",
  "houzz",
  "gg",
  "dhl",
  "square-pinterest",
  "xing",
  "blackberry",
  "creative-commons-pd",
  "playstation",
  "quinscape",
  "less",
  "blogger-b",
  "opencart",
  "vine",
  "signal-messenger",
  "paypal",
  "gitlab",
  "typo3",
  "reddit-alien",
  "yahoo",
  "dailymotion",
  "affiliatetheme",
  "pied-piper-pp",
  "bootstrap",
  "odnoklassniki",
  "nfc-symbol",
  "mintbit",
  "ethereum",
  "speaker-deck",
  "creative-commons-nc-eu",
  "patreon",
  "avianex",
  "ello",
  "gofore",
  "bimobject",
  "brave-reverse",
  "facebook-f",
  "square-google-plus",
  "web-awesome",
  "mandalorian",
  "first-order-alt",
  "osi",
  "google-wallet",
  "d-and-d-beyond",
  "periscope",
  "fulcrum",
  "cloudscale",
  "forumbee",
  "mizuni",
  "schlix",
  "square-xing",
  "bandcamp",
  "wpforms",
  "cloudversify",
  "usps",
  "megaport",
  "magento",
  "spotify",
  "optin-monster",
  "fly",
  "aviato",
  "itunes",
  "cuttlefish",
  "blogger",
  "flickr",
  "viber",
  "soundcloud",
  "digg",
  "tencent-weibo",
  "letterboxd",
  "symfony",
  "maxcdn",
  "etsy",
  "facebook-messenger",
  "audible",
  "think-peaks",
  "bilibili",
  "erlang",
  "x-twitter",
  "cotton-bureau",
  "dashcube",
  "42-group",
  "stack-exchange",
  "elementor",
  "square-pied-piper",
  "creative-commons-nd",
  "palfed",
  "superpowers",
  "resolving",
  "xbox",
  "square-web-awesome-stroke",
  "searchengin",
  "tiktok",
  "square-facebook",
  "renren",
  "linux",
  "glide",
  "linkedin",
  "hubspot",
  "deploydog",
  "twitch",
  "ravelry",
  "mixer",
  "square-lastfm",
  "vimeo",
  "mendeley",
  "uniregistry",
  "figma",
  "creative-commons-remix",
  "cc-amazon-pay",
  "dropbox",
  "instagram",
  "cmplid",
  "upwork",
  "facebook",
  "gripfire",
  "jedi-order",
  "uikit",
  "fort-awesome-alt",
  "phabricator",
  "ussunnah",
  "earlybirds",
  "trade-federation",
  "autoprefixer",
  "whatsapp",
  "square-upwork",
  "slideshare",
  "google-play",
  "viadeo",
  "line",
  "google-drive",
  "servicestack",
  "simplybuilt",
  "bitbucket",
  "imdb",
  "deezer",
  "raspberry-pi",
  "jira",
  "docker",
  "screenpal",
  "bluetooth",
  "gitter",
  "d-and-d",
  "microblog",
  "cc-diners-club",
  "gg-circle",
  "pied-piper-hat",
  "kickstarter-k",
  "yandex",
  "readme",
  "html5",
  "sellsy",
  "square-web-awesome",
  "sass",
  "wirsindhandwerk",
  "buromobelexperte",
  "salesforce",
  "octopus-deploy",
  "medapps",
  "ns8",
  "pinterest-p",
  "apper",
  "fort-awesome",
  "waze",
  "bluesky",
  "cc-jcb",
  "snapchat",
  "fantasy-flight-games",
  "rust",
  "wix",
  "square-behance",
  "supple",
  "webflow",
  "rebel",
  "css3",
  "staylinked",
  "kaggle",
  "space-awesome",
  "deviantart",
  "cpanel",
  "goodreads-g",
  "square-git",
  "square-tumblr",
  "trello",
  "creative-commons-nc-jp",
  "get-pocket",
  "perbyte",
  "grunt",
  "weebly",
  "connectdevelop",
  "leanpub",
  "black-tie",
  "themeco",
  "python",
  "android",
  "bots",
  "free-code-camp",
  "hornbill",
  "js",
  "ideal",
  "git",
  "dev",
  "sketch",
  "yandex-international",
  "cc-amex",
  "uber",
  "github",
  "php",
  "alipay",
  "youtube",
  "skyatlas",
  "firefox-browser",
  "replyd",
  "suse",
  "jenkins",
  "twitter",
  "rockrms",
  "pinterest",
  "buffer",
  "npm",
  "yammer",
  "btc",
  "dribbble",
  "stumbleupon-circle",
  "internet-explorer",
  "stubber",
  "telegram",
  "old-republic",
  "odysee",
  "square-whatsapp",
  "node-js",
  "edge-legacy",
  "slack",
  "medrt",
  "usb",
  "tumblr",
  "vaadin",
  "quora",
  "square-x-twitter",
  "reacteurope",
  "medium",
  "amilia",
  "mixcloud",
  "flipboard",
  "viacoin",
  "critical-role",
  "sitrox",
  "discourse",
  "joomla",
  "mastodon",
  "airbnb",
  "wolf-pack-battalion",
  "buy-n-large",
  "gulp",
  "creative-commons-sampling-plus",
  "strava",
  "ember",
  "canadian-maple-leaf",
  "teamspeak",
  "pushed",
  "wordpress-simple",
  "nutritionix",
  "wodu",
  "google-pay",
  "intercom",
  "zhihu",
  "korvue",
  "pix",
  "steam-symbol",
  "bicycle-stacked-red-lockup-rgb",
  "bicycle-symbol-red-rgb",
  "bicycle-inline-red-lockup-rgb",
  "bicycle-logotype-blue-rgb"
];
export const incFaIconsList = Object.keys(iconNameToSpriteIdMap) as IncFaIconName[];
export const nonBrandIncFaIconsList = incFaIconsList.filter(iconName => !brandIconNames.includes(iconName));

export const getIconSpriteId = (iconName: IncFaIconName) => {
  const spriteId = iconNameToSpriteIdMap[iconName] as SpriteId;
  return {
    iconSpriteId: spriteId,
    iconVariants: faIconNameToVariantsMap[spriteId] || ["solid"],
    viewBox: iconToViewBoxMap[spriteId] || "0 0 512 512"
  };
};
