import React, { useCallback, useEffect, useState, useMemo, FC, useRef } from "react";
import LoadingSpinner from "../Loading/Loading";
import { fetchEntityPropertiesForId, MISSING } from "../../utils/EntityUtils";
import { Entity } from "../../core";
import { logger } from "../../core/logging/Logger";
import { EntityNameRenderer } from "./EntityNameRenderer";
import { EntityPropertiesPopover, EntityPropertiesPopoverContent } from "./EntityPropertiesPopover";
import { EntityDetails } from "./types";

export interface SimpleEntityNameRendererProps {
  id: string;
  name?: string; // If name is passed it will not be fetched from the server
  entityType?: string;
  entityDetails?: EntityDetails; // If details are passed it will not be fetched from the server
  extSections?: Map<string, Map<string, string>>;
  hideIcon?: boolean;

  showPropertiesPopover?: boolean;
  minimalLoader?: boolean;
  propertiesOnly?: boolean;

  onDetailsFetched?: (entityDetails: EntityDetails) => void;
}

export const SimpleEntityNameRenderer: FC<SimpleEntityNameRendererProps> = React.memo(props => {
  const {
    id,
    name,
    entityType,
    entityDetails: pEntityDetails,
    showPropertiesPopover = false,
    minimalLoader = false,
    hideIcon = false,
    propertiesOnly = false,
    extSections,
    onDetailsFetched
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hoveredOnce, setHoveredOnce] = useState<boolean>(false);
  const [entityDetails, setEntityDetails] = useState<EntityDetails>({
    id,
    name,
    type: entityType,
    ...(pEntityDetails || {})
  });
  const detailsFetchedRef = useRef<boolean>(false);

  const getEntityNameFromProperties = (entity: Entity) =>
    entity.properties?.find(x => x.name === "displayName")?.value ||
    entity.properties?.find(x => x.name === "name")?.value ||
    "";

  const fetchEntityDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const result: Entity = await fetchEntityPropertiesForId(id);

      if (onDetailsFetched) {
        onDetailsFetched(result);
      }

      if (result) {
        setEntityDetails({
          ...result,
          name: result.name || getEntityNameFromProperties(result) || result.id
        });
      } else {
        setEntityDetails({
          id,
          name: id,
          properties: []
        });
      }
    } catch (error) {
      setEntityDetails({
        id,
        name: id,
        properties: []
      });
      logger.error("SimpleEntityNameRenderer", "Error occurred while fetching displayName", error);
    } finally {
      setIsLoading(false);
    }
  }, [id, onDetailsFetched]);

  useEffect(() => {
    const idExists = id && id !== MISSING;
    const shouldFetchProperties =
      !name || (!pEntityDetails && (showPropertiesPopover ? propertiesOnly || hoveredOnce : false));

    if (idExists && shouldFetchProperties && !detailsFetchedRef.current) {
      fetchEntityDetails();
      detailsFetchedRef.current = true;
    }
  }, [fetchEntityDetails, id, showPropertiesPopover, name, pEntityDetails, propertiesOnly, hoveredOnce]);

  useEffect(() => {
    if (name) {
      setEntityDetails(prevState => ({
        ...prevState,
        name
      }));
    }
  }, [name]);

  const nameComponent = useMemo(() => {
    if (entityDetails) {
      return (
        <EntityNameRenderer
          entityDetails={entityDetails}
          hideIcon={hideIcon}
          hideTooltipOnEllipsis={showPropertiesPopover}
        />
      );
    }
  }, [entityDetails, hideIcon, showPropertiesPopover]);

  if (!id || id === MISSING) {
    return <>-</>;
  }

  if (isLoading && !name) {
    const titleText = minimalLoader ? " " : ""; // Empty string will show "Loading...""
    return <LoadingSpinner titleText={titleText} />;
  }

  if (propertiesOnly) {
    return (
      <EntityPropertiesPopoverContent
        entityDetails={entityDetails}
        extSections={extSections}
      />
    );
  }

  return showPropertiesPopover ? (
    <EntityPropertiesPopover
      entityDetails={entityDetails}
      extSections={extSections}
      isLoading={isLoading}
      onHover={() => setHoveredOnce(true)}
    >
      {nameComponent}
    </EntityPropertiesPopover>
  ) : (
    nameComponent
  );
});
