import { cx } from "emotion";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip, TooltipProps } from "antd";
import { IncTooltipProps, Placement } from "./types";

export const IncToolTip: React.FC<IncTooltipProps> = (props: IncTooltipProps) => {
  const {
    placement: pPlacement = "top",
    titleId = "",
    titleText = "",
    titleValues = [],
    titleElement = null,
    showArrow = false,
    children,
    className = "",
    variant = "default",
    noWrap
  } = props;

  const placement = placementMap[pPlacement];

  const values = useMemo(() => {
    const values: Record<string, string | number> = {};
    titleValues.map((value: string | number, index: number) => (values[index] = value));
    return values;
  }, [titleValues]);

  const hasCustomTooltip = !isEmpty(titleElement);

  const tooltipClassName = useMemo(
    () =>
      cx("inc-tooltip", `inc-tooltip--${variant}`, className, {
        "inc-tooltip--no-wrap": noWrap,
        "inc-tooltip--custom-element": hasCustomTooltip
      }),
    [className, hasCustomTooltip, noWrap, variant]
  );

  const getToolTipText = useCallback(() => {
    if (titleId) {
      return (
        <FormattedMessage
          id={titleId}
          values={values}
        />
      );
    }
    return <>{titleText}</>;
  }, [titleId, titleText, values]);

  const titleJsx = useMemo(
    () => <div className="inc-tooltip--content">{titleElement || getToolTipText()}</div>,
    [getToolTipText, titleElement]
  );

  return (
    <Tooltip
      align={{ offset: [0, 4] }}
      arrowPointAtCenter
      autoAdjustOverflow
      className="inc-flex-row inc-flex-center-vertical"
      destroyTooltipOnHide
      overlayClassName={tooltipClassName}
      placement={placement}
      showArrow={showArrow}
      title={titleJsx}
    >
      {children}
    </Tooltip>
  );
};

const placementMap: Record<Placement, TooltipProps["placement"]> = {
  "auto-end": "topRight",
  "auto-start": "topLeft",
  auto: "top",
  bottom: "bottom",
  "bottom-end": "bottomRight",
  "bottom-start": "bottomLeft",
  left: "left",
  "left-end": "leftTop",
  "left-start": "leftBottom",
  right: "right",
  "right-end": "rightTop",
  "right-start": "rightBottom",
  top: "top",
  "top-end": "topRight",
  "top-start": "topLeft"
};
