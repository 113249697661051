import { useRef, useCallback } from "react";

interface ScrollIntoViewOptions {
  behavior?: ScrollBehavior;
  block?: ScrollLogicalPosition;
  inline?: ScrollLogicalPosition;
}

export const useScrollIntoView = () => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollIntoView = useCallback((options?: ScrollIntoViewOptions) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        ...(options || {})
      });
    }
  }, []);

  return {
    ref,
    scrollIntoView
  };
};
