import {
  BizEntityTypeList,
  FieldSchemaResponse,
  WidgetData,
  WidgetQuerySchemaResponse
} from "../../../../../src/platform/services/api/explore";

export const KPIPreview = {
  mockRequests: {
    "/api-proxy/api/bizEntity/list": {
      body: {
        resultEntityTypes: [
          {
            name: "SearchCategory",
            entityTypeId: "SearchCategory",
            count: "5213754",
            metadata: {
              icon: "search"
            }
          },
          {
            name: "city",
            entityTypeId: "city",
            count: "2179",
            metadata: {
              icon: "search-location"
            }
          },
          {
            name: "hub",
            entityTypeId: "hub",
            count: "13130",
            metadata: {
              icon: "store-alt"
            }
          },
          {
            name: "SKU",
            entityTypeId: "SKU",
            count: "784211",
            metadata: {}
          }
        ]
      } as unknown as BizEntityTypeList,
      statusCode: 200
    },
    "api-proxy/api/userService/i_userService:00000000-0000-0000-0000-0025d51040c8:0/schema/widget/querySchema": {
      body: {
        querySchema: [
          {
            metricId: "TUQSrVDx",
            metricName: "Average Basket Size",
            sliceSet: {
              slices: []
            },
            resultKey: "_inception_TUQSrVDx_ALL_",
            defaultTagAgg: "avg",
            defaultTimeAgg: "avg",
            sourceUserServiceField: {
              userServices: [
                {
                  userServiceEntityId: "i_userService:00000000-0000-0000-0000-0025d51040c8:0"
                }
              ],
              fieldName: "fields.final_quantity",
              dataType: "DOUBLE",
              bizEntityFieldName: "",
              displayBizEntityFieldName: "",
              allUserService: false,
              isMappingIncomplete: false,
              isAvailableInTenantContext: true,
              rawFieldName: "",
              isListDataType: false,
              displayDataType: "NA"
            },
            sourceFieldType: "LONG",
            subType: "",
            labels: {},
            sourceUserServiceFields: [],
            baseEventNavigation: {
              eventFilter: 'userService.__id__ in ["i_userService:00000000-0000-0000-0000-0025d51040c8:0"]'
            },
            componentsEventNavigation: {},
            componentSourceFields: {},
            defaultAgg: "avg",
            componentLookBacks: {}
          }
        ]
      } as unknown as WidgetQuerySchemaResponse,
      statusCode: 200
    },
    "api-proxy/api/userService/i_userService:00000000-0000-0000-0000-0025d51040c8:0/schema/fields?**": {
      body: {
        bizFields: [],
        userServiceFields: [
          {
            userServiceField: {
              userServices: [
                {
                  userServiceEntityId: "i_userService:00000000-0000-0000-0000-0025d51040c8:0"
                }
              ],
              fieldName: "fields.SourceHub",
              dataType: "ENTITY",
              entityField: {
                entityType: "hub",
                relNames: [],
                propType: "MAP",
                propName: "i_metadata",
                kindDescriptor: {
                  type: "not_set",
                  customTypeName: ""
                }
              },
              bizEntityFieldName: "",
              displayBizEntityFieldName: "",
              allUserService: false,
              isMappingIncomplete: false,
              isAvailableInTenantContext: false,
              rawFieldName: "",
              isListDataType: false,
              displayDataType: "NA"
            },
            userServiceMetadata: {
              diagnostic: false,
              business: true,
              subType: "",
              category: "BUSINESS",
              endUser: false,
              journey: false
            }
          },
          {
            userServiceField: {
              userServices: [
                {
                  userServiceEntityId: "i_userService:00000000-0000-0000-0000-0025d51040c8:0"
                }
              ],
              fieldName: "fields.data_source",
              dataType: "STRING",
              bizEntityFieldName: "",
              displayBizEntityFieldName: "",
              allUserService: false,
              isMappingIncomplete: false,
              isAvailableInTenantContext: false,
              rawFieldName: "",
              isListDataType: false,
              displayDataType: "NA"
            },
            userServiceMetadata: {
              diagnostic: false,
              business: true,
              subType: "",
              category: "BUSINESS",
              endUser: false,
              journey: false
            }
          },
          {
            userServiceField: {
              userServices: [
                {
                  userServiceEntityId: "i_userService:00000000-0000-0000-0000-0025d51040c8:0"
                }
              ],
              fieldName: "fields.search_query",
              dataType: "STRING",
              bizEntityFieldName: "",
              displayBizEntityFieldName: "",
              allUserService: false,
              isMappingIncomplete: false,
              isAvailableInTenantContext: false,
              rawFieldName: "",
              isListDataType: false,
              displayDataType: "NA"
            },
            userServiceMetadata: {
              diagnostic: false,
              business: true,
              subType: "",
              category: "BUSINESS",
              endUser: false,
              journey: false
            }
          },
          {
            userServiceField: {
              userServices: [
                {
                  userServiceEntityId: "i_userService:00000000-0000-0000-0000-0025d51040c8:0"
                }
              ],
              fieldName: "fields.traffic_type",
              dataType: "STRING",
              bizEntityFieldName: "",
              displayBizEntityFieldName: "",
              allUserService: false,
              isMappingIncomplete: false,
              isAvailableInTenantContext: false,
              rawFieldName: "",
              isListDataType: false,
              displayDataType: "NA"
            },
            userServiceMetadata: {
              diagnostic: false,
              business: true,
              subType: "",
              category: "BUSINESS",
              endUser: false,
              journey: false
            }
          },
          {
            userServiceField: {
              userServices: [
                {
                  userServiceEntityId: "i_userService:00000000-0000-0000-0000-0025d51040c8:0"
                }
              ],
              fieldName: "fields.SourceHub",
              dataType: "ENTITY",
              entityField: {
                entityType: "hub",
                relNames: [],
                propType: "STRING",
                propName: "city",
                kindDescriptor: {
                  type: "not_set",
                  customTypeName: ""
                }
              },
              bizEntityFieldName: "",
              displayBizEntityFieldName: "",
              allUserService: false,
              isMappingIncomplete: false,
              isAvailableInTenantContext: false,
              rawFieldName: "",
              isListDataType: false,
              displayDataType: "NA"
            },
            userServiceMetadata: {
              diagnostic: false,
              business: true,
              subType: "",
              category: "BUSINESS",
              endUser: false,
              journey: false
            }
          },
          {
            userServiceField: {
              userServices: [
                {
                  userServiceEntityId: "i_userService:00000000-0000-0000-0000-0025d51040c8:0"
                }
              ],
              fieldName: "fields.SourceCity",
              dataType: "ENTITY",
              entityField: {
                entityType: "city",
                relNames: [],
                propType: "MAP",
                propName: "user_labels",
                kindDescriptor: {
                  type: "not_set",
                  customTypeName: ""
                }
              },
              bizEntityFieldName: "",
              displayBizEntityFieldName: "",
              allUserService: false,
              isMappingIncomplete: false,
              isAvailableInTenantContext: false,
              rawFieldName: "",
              isListDataType: false,
              displayDataType: "NA"
            },
            userServiceMetadata: {
              diagnostic: false,
              business: true,
              subType: "",
              category: "BUSINESS",
              endUser: false,
              journey: false
            }
          },
          {
            userServiceField: {
              userServices: [
                {
                  userServiceEntityId: "i_userService:00000000-0000-0000-0000-0025d51040c8:0"
                }
              ],
              fieldName: "fields.SearchCategory",
              dataType: "ENTITY",
              entityField: {
                entityType: "SearchCategory",
                relNames: [],
                propType: "STRING",
                propName: "leaf_slug",
                kindDescriptor: {
                  type: "not_set",
                  customTypeName: ""
                }
              },
              bizEntityFieldName: "",
              displayBizEntityFieldName: "",
              allUserService: false,
              isMappingIncomplete: false,
              isAvailableInTenantContext: false,
              rawFieldName: "",
              isListDataType: false,
              displayDataType: "NA"
            },
            userServiceMetadata: {
              diagnostic: false,
              business: true,
              subType: "",
              category: "BUSINESS",
              endUser: false,
              journey: false
            }
          },
          {
            userServiceField: {
              userServices: [
                {
                  userServiceEntityId: "i_userService:00000000-0000-0000-0000-0025d51040c8:0"
                }
              ],
              fieldName: "eventID",
              dataType: "STRING",
              bizEntityFieldName: "",
              displayBizEntityFieldName: "",
              allUserService: false,
              isMappingIncomplete: false,
              isAvailableInTenantContext: false,
              rawFieldName: "",
              isListDataType: false,
              displayDataType: "NA"
            },
            userServiceMetadata: {
              diagnostic: true,
              business: false,
              subType: "",
              category: "DIAGNOSTIC",
              endUser: false,
              journey: false
            }
          }
        ],
        userServiceMetrics: [],
        bizMetrics: [],
        resultMetadata: {
          resultEntityMap: {
            "i_userService:00000000-0000-0000-0000-0025d51040c8:0": {
              name: "BasketInteraction",
              entityId: "i_userService:00000000-0000-0000-0000-0025d51040c8:0",
              metadata: {
                icon: "basket-shopping-minus"
              }
            }
          }
        },
        status: 0,
        errors: []
      } as unknown as FieldSchemaResponse,
      statusCode: 200
    },
    "api-proxy/api/userService/i_userService:00000000-0000-0000-0000-0025d51040c8:0/schema/widget/data?**": {
      body: {
        metricResults: [
          {
            dataDefinitionId: "TUQSrVDx",
            data: {},
            compareConfigData: {},
            entityLookupData: {},
            incidents: {},
            dataQueryConfig: {
              timeRange: {
                from: 1722450600000,
                to: 1724697000000
              },
              aggregations: {
                results: [
                  {
                    name: "_inception_TUQSrVDx_ALL_",
                    field: "fields.final_quantity",
                    type: "METRICS",
                    metricType: "avg",
                    params: {
                      type: "double",
                      periodicitySecs: 43200,
                      limit: 30,
                      includeSchema: true
                    }
                  }
                ]
              },
              filters: {
                type: "string",
                value: 'userService.__id__ in ["i_userService:00000000-0000-0000-0000-0025d51040c8:0"]'
              },
              virtualColumns: [],
              timeoutMillis: 60000,
              queryId: "822ef39dccf746618af4b000db0921ac,822ef39dccf746618af4b000db0921ac",
              datasourceType: "events",
              queryOrigin: "biz-entity-service"
            },
            postAggResult: {
              data: {
                _inception_TUQSrVDx_ALL_: {
                  status: "success",
                  data: {
                    resultType: "matrix",
                    result: [
                      {
                        metric: {
                          _inception_display_name: "_inception_TUQSrVDx_ALL_"
                        },
                        values: [
                          [1722450600, "2.563679688887805"],
                          [1722493800, "3.2451612546479756"],
                          [1722537000, "3.2359560846744886"],
                          [1722580200, "3.6062069229232283"],
                          [1722623400, "2.3775128553572675"],
                          [1722666600, "2.620585460364533"],
                          [1722709800, "1.5083167487867932"],
                          [1722753000, "1.7176886927072432"],
                          [1722796200, "3.003732511620133"],
                          [1722839400, "3.932044888117524"],
                          [1722882600, "3.254310376997391"],
                          [1722925800, "4.996764857321551"],
                          [1722969000, "3.2956642630491544"],
                          [1723012200, "3.8043657092075795"],
                          [1723055400, "4.675744925557067"],
                          [1723098600, "4.5660137532651826"],
                          [1723141800, "3.1223518914638904"],
                          [1723185000, "4.693030789555891"],
                          [1723228200, "3.449744441966179"],
                          [1723271400, "3.855583074553464"],
                          [1723314600, "1.2510569777942184"],
                          [1723357800, "2.0748029838477544"],
                          [1723401000, "2.7127714384804213"],
                          [1723444200, "4.318104803235045"],
                          [1723487400, "3.1707159567566934"],
                          [1723530600, "5.510442150882581"],
                          [1723573800, "3.6314360916062576"],
                          [1723617000, "4.133179987647809"],
                          [1723660200, "2.08296974680979"],
                          [1723703400, "2.2220337594611346"],
                          [1723746600, "3.8116392987514582"],
                          [1723789800, "4.305135903837518"],
                          [1723833000, "3.0614475096472296"],
                          [1723876200, "4.090501313708381"],
                          [1723919400, "1.8333173203748698"],
                          [1723962600, "2.1077878032059605"],
                          [1724005800, "1.9637912647462608"],
                          [1724049000, "3.425408404660053"],
                          [1724092200, "3.6002238873864907"],
                          [1724135400, "5.420410349106908"],
                          [1724178600, "2.803639066035484"],
                          [1724221800, "5.044414701061237"],
                          [1724265000, "3.4332620189896743"],
                          [1724308200, "4.0431881717130915"],
                          [1724351400, "3.354921645522696"],
                          [1724394600, "4.256488121069114"],
                          [1724437800, "3.1794481643962134"],
                          [1724481000, "4.573473376769957"],
                          [1724524200, "1.85060968940438"],
                          [1724567400, "2.572343520433005"],
                          [1724610600, "2.396035561716118"],
                          [1724653800, "3.204934548930345"]
                        ]
                      }
                    ],
                    stats: {
                      timings: {
                        evalTotalTime: 0.315138666,
                        resultSortTime: 3.7e-7,
                        queryPreparationTime: 0.314999716,
                        innerEvalTime: 0.00012205,
                        execQueueTime: 0.00000997,
                        execTotalTime: 0.315155326
                      },
                      samples: {
                        totalQueryableSamples: 53,
                        peakSamples: 107
                      }
                    },
                    resultSchema: [{}],
                    preLimitSelectionCount: 1
                  }
                }
              },
              timeShiftedData: {},
              percentageChangeData: {},
              entityLookupData: {},
              deltaData: "{}",
              trendData: "{}"
            },
            warnings: [],
            forecastResult: {
              data: {},
              percentageChangeData: {},
              lowerBoundData: {},
              upperBoundData: {},
              delta: {},
              entityLookupData: {}
            }
          }
        ],
        fieldResult: [],
        metadata: {
          metricErrors: {},
          fieldErrors: {}
        }
      } as unknown as WidgetData,
      statusCode: 200
    }
  }
};
