import { isNil, toString } from "lodash";
import moment from "moment";
import React from "react";
import { Cell } from "react-table";
import TagsRenderer from "../renderers/TagsRenderer";
import { TableDataColumn, TableDataItem } from "../types";
import { IncToolTip } from "../../antd-components";
import { formatNumber, getFormattedDateTime, IncDateTimeFormat } from "../../../utils";

const useCellRenderer = <T extends TableDataItem>(
  cell: Cell<T>,
  type: TableDataColumn<T>["type"],
  renderer: TableDataColumn<T>["renderer"]
) => {
  const { value, row } = cell;

  if (renderer) {
    return renderer(value, row.original, row.depth > 0, row.depth);
  }

  switch (type) {
    case "alphanumeric":
    case "string":
      return (value || ("" as string | number)).toString();
    case "number":
      return !isNil(value) ? String(value) : "";
    case "formattednumber": {
      return formatNumber(value as number);
    }
    case "custom": {
      let finVal: string;
      const dType = typeof value;

      if (dType === "object") {
        finVal = JSON.stringify(value);
      } else {
        finVal = toString(value);
      }

      return <code className="inc-tabe-object-renderer">{finVal}</code>;
    }
    case "datetime": {
      const millis = parseInt(value?.valueOf() || -1, 10);
      return millis < 0 ? "-" : getFormattedDateTime(millis, IncDateTimeFormat.minimal);
    }
    case "relativedatetime": {
      const millis = parseInt(value?.valueOf() || -1, 10);
      const formattedDT = getFormattedDateTime(millis, IncDateTimeFormat.minimal);
      return millis < 0 ? (
        "-"
      ) : (
        <IncToolTip
          placement="top"
          titleText={formattedDT}
        >
          <span>{moment(millis).fromNow()}</span>
        </IncToolTip>
      );
    }
    case "tags": {
      return <TagsRenderer tags={value as string[]} />;
    }
    case "currency": {
      return `$${value}`;
    }
    default:
      return "Invalid renderer / type";
  }
};

export default useCellRenderer;
