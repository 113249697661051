import { IncDateTimeFormat, getCurrencyInfo, CurrencyType, getSymbolForCurrency } from "@inception/ui";
import { flatten, isEmpty } from "lodash";
import { Entity, logger } from "../core";
import { BEGINNING_OF_TIME_VALUE, END_OF_TIME_VALUE } from "../components/time-range/TimeRangeConstants";
import {
  BizFieldInfo,
  BizFieldPredicate,
  BizServiceMetric,
  EntityField,
  EntityMeta,
  FieldPickerContextDTO,
  FieldPickerOptionData,
  Slice,
  SliceSet,
  UserServiceField,
  UserServiceMetric
} from "../services/api/explore";
import appConfig from "../../appConfig";
import { FieldPickerRow } from "../field-picker";
import { entityEnricherRegistry } from "./EntityEnricher";
import timeRangeUtils from "./TimeRangeUtils";
import { getOpFromEntityOperation } from "./PredicateUtils";
import { getPromSanitizedName } from "./Utils";
import { eventFieldUtils } from "./EventFieldUtils";
import { ALL_USERSERVICES_ENTITY_TYPE_ID, ALL_USERSERVICES_EVENT_TYPE_ID } from "./ExploreUtils";

const getMockUSField = (fieldName: string): UserServiceField => ({
  bizEntityFieldName: "",
  dataType: "STRING",
  displayBizEntityFieldName: "",
  fieldName,
  userServices: []
});

export interface Options {
  currency?: CurrencyType;
}

export class FieldPickerUtils {
  private static hasErrorFieldName = "hasError";
  private static eventIDFieldName = "eventID";

  private static mockHasErrorField = getMockUSField(this.hasErrorFieldName);
  private static mockEventIDField = getMockUSField(this.eventIDFieldName);

  static isHasErrorField(fieldName: string) {
    return fieldName === this.hasErrorFieldName;
  }

  static isEventIDField(fieldName: string) {
    return fieldName === this.eventIDFieldName;
  }

  static getMockHasErrorField() {
    return { ...this.mockHasErrorField };
  }

  static getMockEventIDField() {
    return { ...this.mockEventIDField };
  }

  static getEntityFromEntityMeta(entityMeta: Record<string, EntityMeta>): Entity[] {
    const entities: Entity[] = [];

    for (const [key, meta] of Object.entries(entityMeta)) {
      entities.push({
        id: key,
        name: meta.name,
        type: meta.entityType
      });
    }

    return entities;
  }

  private static sanitizeName(name: string): string {
    if (isEmpty(name)) {
      return name;
    }

    const withoutFields = eventFieldUtils.removeFieldsPrefix(name);

    logger.debug("sanitizeName", `input ${name} , output ${withoutFields}`);
    return withoutFields;
  }

  static nameJoiner(arr: string[]): string {
    return (arr || []).filter(Boolean).join(" ");
  }

  static getEntityFieldName(entityField: EntityField): string {
    if (!entityField) {
      return "";
    }

    const { propName, relNames } = entityField;
    const sanitizedPropName = FieldPickerUtils.sanitizeName(propName);
    if (!isEmpty(relNames)) {
      const extractedNames = relNames.map(relName => FieldPickerUtils.sanitizeName(relName.relName));
      const relSuffix = FieldPickerUtils.nameJoiner(extractedNames);
      const generatedName = FieldPickerUtils.nameJoiner([relSuffix, sanitizedPropName]);
      logger.debug("getEntityFieldName", generatedName);
      return generatedName;
    }
    return sanitizedPropName;
  }

  static generateMetricNamefromUSF(usf: UserServiceField, aggr: string): string {
    const name = FieldPickerUtils.generateNamefromUSF(usf);
    const metricName = FieldPickerUtils.nameJoiner([aggr, name]);
    return metricName;
  }

  static generateNamefromUSF(usf: UserServiceField): string {
    const { fieldName, entityField, dataType } = usf || {};
    const sanitizedFieldName = FieldPickerUtils.sanitizeName(fieldName);
    if (dataType === "ENTITY" && entityField) {
      const entityPropNameWithRel = FieldPickerUtils.getEntityFieldName(entityField);
      return FieldPickerUtils.nameJoiner([sanitizedFieldName, entityPropNameWithRel]);
    }
    return sanitizedFieldName;
  }

  static getUserServiceFieldLabel(field: UserServiceField) {
    const generatedFieldName = FieldPickerUtils.generateNamefromUSF(field);
    return ((generatedFieldName || "") as any).replaceAll(" ", ".") as string;
  }

  static getMetricLabel(metric: BizServiceMetric | UserServiceMetric) {
    return metric.metricName;
  }

  static getBizFieldLabel(bizField: BizFieldInfo, promSanitize = false) {
    const generatedName = FieldPickerUtils.getEntityFieldName(bizField.bizField.entityField);
    const label = ((generatedName || "") as any).replaceAll(" ", ".");
    return promSanitize ? getPromSanitizedName(label) : label;
  }

  static getBizFieldValue(predicate: BizFieldPredicate, options?: Options) {
    const isDateTimeType = predicate.bizField.entityField.propType === "DATETIME";
    const isDateType = predicate.bizField.entityField.propType === "DATE";
    const dateFormat = isDateType ? IncDateTimeFormat.cohortNumericDate : IncDateTimeFormat.cohortNumericDateTime;
    const isCurrencyType =
      predicate.bizField.entityField.propType === "LONG" &&
      predicate.bizField.entityField.kindDescriptor.type === "currency";

    if (isDateType || isDateTimeType) {
      if (predicate.op === "range") {
        const fromMs = timeRangeUtils.isRelativeTime(predicate.values[0])
          ? predicate.values[0]
          : Date.parse(predicate.values[0]).toString();
        const toMs = timeRangeUtils.isRelativeTime(predicate.values[1])
          ? predicate.values[1]
          : Date.parse(predicate.values[1]).toString();
        return timeRangeUtils.getLabelByTimeRange(fromMs, toMs, isDateTimeType, true, dateFormat);
      }
      const valueMs = timeRangeUtils.isRelativeTime(predicate.value)
        ? predicate.value
        : Date.parse(predicate.value).toString();
      if (predicate.op === "lt") {
        return timeRangeUtils.getLabelByTimeRange(BEGINNING_OF_TIME_VALUE, valueMs, isDateTimeType, true, dateFormat);
      }
      if (predicate.op === "gt") {
        return timeRangeUtils.getLabelByTimeRange(valueMs, END_OF_TIME_VALUE, isDateTimeType, true, dateFormat);
      }
      if (predicate.op === "eq") {
        return timeRangeUtils.getLabelByTimeRange(valueMs, valueMs, isDateTimeType, true, dateFormat);
      }
    }
    if (isCurrencyType) {
      const currencyType = options?.currency ? options.currency : (appConfig.defaultCurrencyType as CurrencyType);
      const currencySymbol = getSymbolForCurrency(currencyType);
      if (predicate.op === "range") {
        return `between ${currencySymbol}${getCurrencyInfo(predicate.values[0], currencyType, false).abbreviatedValue} and
        ${currencySymbol}${getCurrencyInfo(predicate.values[1], currencyType, false).abbreviatedValue}`;
      } else {
        return `${currencySymbol}${getCurrencyInfo(predicate.value, currencyType, false).abbreviatedValue}`;
      }
    }
    if (predicate?.op === "in" || predicate?.op === "range") {
      return [...predicate.values.map(x => entityEnricherRegistry.lookupEntityCache(x))];
    }

    return entityEnricherRegistry.lookupEntityCache(predicate.value);
  }

  static getBizFieldPredicatePillLabelAndInfo(predicate: BizFieldPredicate, options?: Options) {
    const label = this.getBizFieldLabel({
      bizField: predicate.bizField,
      metadata: null
    });
    const isDateTimeType = predicate.bizField.entityField.propType === "DATETIME";
    const isDateType = predicate.bizField.entityField.propType === "DATE";
    const isCurrencyType =
      predicate.bizField.entityField.propType === "LONG" &&
      predicate.bizField.entityField.kindDescriptor.type === "currency";

    const op = getOpFromEntityOperation(predicate.op);
    const isEqualOp = predicate.op === "eq";
    const opValue =
      !isEqualOp && (op === "range" || isDateTimeType || isDateType) ? (isCurrencyType ? "" : ":") : op ?? predicate.op;
    const value = this.getBizFieldValue(predicate, options);

    const { label: pillLabel, info: infoStr } = this.getLabelAndInfo(label, opValue, value);
    return {
      label: pillLabel,
      info: infoStr
    };
  }

  static validateBizPredicate(predicate: BizFieldPredicate) {
    if (predicate?.op === "in" || predicate?.op === "range") {
      return predicate?.values.length > 0;
    } else {
      return Boolean(predicate?.value || "");
    }
  }

  static getPromSanitizedUSFName(field: UserServiceField) {
    return getPromSanitizedName(FieldPickerUtils.getUserServiceFieldLabel(field));
  }

  static findSliceWithTagName(tag: string, slicesets: SliceSet[]): Slice {
    if (tag && !isEmpty(slicesets)) {
      const arrSlices = slicesets.map(sliceset => sliceset.slices);
      const match = flatten(arrSlices).find(s => s.tagName === tag);
      return match ? match : null;
    }
    return null;
  }

  static getSourcesToPreSelect(data: Array<FieldPickerRow<"userServiceField">>) {
    const selectedRow = data.filter(r => r.rowSelected);
    if (selectedRow.length === 0) {
      return [];
    }
    return selectedRow.map(row => row.selectedSources).flat();
  }

  static getLabelAndInfo(fieldName: string, op: string, value: string | string[], maxValues = 2) {
    const valueArr = Array.isArray(value) ? value : [value];
    const valueArrLength = valueArr.length;

    let valueStr = valueArr.slice(0, maxValues).join(", ");
    const moreExists = valueArrLength > maxValues;
    valueStr += moreExists ? ` & ${valueArrLength - maxValues} more` : "";
    const infoStr = moreExists ? this.getInfoText(valueArr) : "";

    const label = `${fieldName} ${op} ${valueStr}`;
    return {
      label,
      info: infoStr
    };
  }

  static executeOnFieldPickerOptionData(
    fields: FieldPickerOptionData[],
    callBacks: {
      onBizField: (f: BizFieldInfo) => void;
      onUSF: (f: UserServiceField) => void;
      onUSM: (f: UserServiceMetric) => void;
      onBizMetric: (f: BizServiceMetric) => void;
    }
  ): void {
    (fields || []).forEach(f => {
      if (f.type === "bizEntityField") {
        callBacks?.onBizField(f.payload as BizFieldInfo);
      } else if (f.type === "userServiceField") {
        callBacks?.onUSF(f.payload as UserServiceField);
      } else if (f.type === "userServiceMetric") {
        callBacks?.onUSM(f.payload as UserServiceMetric);
      } else if (f.type === "bizEntityMetric") {
        callBacks?.onBizMetric(f.payload as BizServiceMetric);
      } else {
        console.log("Invalid payload found", f);
      }
    });
  }

  static isEntityField(field: UserServiceField) {
    if (
      ["LIST_ENTITY", "ENTITY"].includes(field?.dataType) &&
      field.entityField?.propType === "NA" &&
      !field?.entityField?.relNames?.length
    ) {
      return true;
    }
    return false;
  }

  static getFieldPickerContext(
    entityTypeId: string,
    eventTypeIds: string[],
    userServiceToBizEntityFieldName?: Record<string, string>
  ): FieldPickerContextDTO {
    entityTypeId = eventTypeIds.includes(ALL_USERSERVICES_EVENT_TYPE_ID)
      ? ALL_USERSERVICES_ENTITY_TYPE_ID
      : !entityTypeId && eventTypeIds.length > 1
        ? ALL_USERSERVICES_ENTITY_TYPE_ID
        : entityTypeId;

    const eventTypeId = entityTypeId ? "" : eventTypeIds[0];
    eventTypeIds = eventTypeId ? [] : eventTypeIds.filter(e => e !== ALL_USERSERVICES_EVENT_TYPE_ID);

    return {
      entityId: eventTypeId,
      entityName: entityTypeId,
      entityType: entityTypeId,
      showFields: true,
      showMetrics: false,
      userServices: eventTypeIds.map(eventTypeId => ({ userServiceEntityId: eventTypeId })),
      userServiceToBizEntityFieldName
    };
  }

  private static getInfoText(valuesArr: string[], entriesPerLine = 3) {
    let infoText = ``;
    const valuesArrLength = valuesArr.length;
    const lines = Math.ceil(valuesArrLength / entriesPerLine);
    for (let i = 0; i < lines; i++) {
      const start = i * entriesPerLine;
      const end = start + entriesPerLine;
      const lineValues = valuesArr.slice(start, end);
      infoText = `${infoText}${lineValues.join(", ")}`;
      if (i < lines - 1) {
        infoText = `${infoText},\n`;
      }
    }
    return infoText;
  }
}
