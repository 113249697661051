import React from "react";
import { setInceptionTheme } from "@inception/ui";
import { renderReactComponent } from "./AppRenderer";

import { THEME_KEY } from "./platform/core/hooks/useInceptionTheme";

const App = React.lazy(() => import("./app/App"));

const renderApp = () => {
  renderReactComponent(<App />, document.getElementById("root"));
};

const fetchThemeAndRenderApp = () => {
  // Fetch the theme from local storage
  const themeType = window.localStorage.getItem(THEME_KEY);
  if (!themeType) {
    setInceptionTheme("dark");
  } else {
    themeType === "dark" ? setInceptionTheme("dark") : setInceptionTheme("light");
  }

  renderApp();
};

fetchThemeAndRenderApp();
