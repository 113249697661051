export type MetricAggregator =
  | "avg"
  | "count"
  | "min"
  | "max"
  | "sum"
  | "distinctCount"
  | "quantile"
  | "last"
  | "contribution";
export type MetricAggregatorExpanded =
  | "avg"
  | "count"
  | "min"
  | "max"
  | "sum"
  | "distinctCount"
  | "P25"
  | "P50"
  | "P90"
  | "P95"
  | "P99"
  | "last"
  | "contribution";

export type QuantileFraction = "0.25" | "0.5" | "0.9" | "0.95" | "0.99";

export enum MetricAggregations {
  Average = "Average",
  Count = "Count",
  DistinctCount = "Distinct Count",
  Min = "Min",
  Max = "Max",
  Sum = "Sum",
  P25 = "P25",
  P50 = "P50",
  P90 = "P90",
  P95 = "P95",
  P99 = "P99",
  Last = "Last",
  Contribution = "Contributions"
}
