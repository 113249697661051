/* eslint-disable react-hooks/exhaustive-deps */
import { RefObject, useEffect, useMemo, useRef, useState } from "react";

/**
 * Hook to check if a component is visible in the viewport OR based on what is passed in options
 * @param ref React Ref for HTMLElement which is to be checked
 * @param options options of type IntersectionObserverInit
 * @returns true if visible, false otherwise
 */
export const useIsVisible = (ref: RefObject<HTMLElement>, options?: IntersectionObserverInit) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([elem]) => {
    setIntersecting(elem.isIntersecting);
  }, options);

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  if (!ref) {
    return false;
  }

  return isIntersecting;
};

/**
 * Hook to check if a component is visible in the viewport OR based on what is passed in options
 * @param ref React Ref for HTMLElement which is to be checked
 * @param options options of type IntersectionObserverInit
 */

export const useIsVisibleOnce = (ref: RefObject<HTMLElement>, options?: IntersectionObserverInit) => {
  const isVisibleOnceRef = useRef(false);

  const isVisible = useIsVisible(ref, options);

  useMemo(() => {
    if (isVisible && !isVisibleOnceRef.current) {
      isVisibleOnceRef.current = true;
    }
  }, [isVisible]);

  return {
    isVisible,
    wasVisibleOnce: isVisibleOnceRef.current
  };
};
