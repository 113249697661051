export const AddTenant = {
  attributes: {
    tenantName: "tenant-name",
    tenantShortName: "tenant-short-name",
    userName: "user-name",
    userEmail: "user-email",
    tenantPurpose: "tenant-purpose",
    metricShard: "metric-shard",
    anomMetricShardId: "anom-metric-shard",
    eventShard: "event-shard",
    retentionPeriod: "retention-period",
    timeZone: "time-zone",
    customTimeRanges: "custom-time-ranges",
    addTenantButton: "add-tenant-button"
  },
  mockRequests: {
    "api-proxy/api/tenants/shards": {
      body: {
        i_tracesShard: [
          {
            shardId: "3",
            shardPrefix: "shard3-",
            shardEntityId: "i_tracesShard:00000000-0000-0000-0000-0000668e1020:0",
            shardType: "i_tracesShard",
            default: true
          },
          {
            shardId: "2",
            shardPrefix: "",
            shardEntityId: "i_tracesShard:00000000-0000-0000-0000-00006ddd2078:0",
            shardType: "i_tracesShard",
            default: false
          }
        ],
        i_metricsShard: [
          {
            shardId: "2",
            shardPrefix: "mcs-0",
            shardEntityId: "i_metricsShard:00000000-0000-0000-0000-000055745008:0",
            shardType: "i_metricsShard",
            default: true
          }
        ],
        i_anomMetricsShard: [
          {
            shardId: "2",
            shardPrefix: "anom-mcs-0",
            shardEntityId: "i_anomMetricsShard:00000000-0000-0000-0000-000053028020:0",
            shardType: "i_anomMetricsShard",
            default: true
          }
        ]
      },
      statusCode: 200
    },
    "api-proxy/api/tenants": {
      body: {},
      statusCode: 200
    }
  }
};
