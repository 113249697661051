import {
  EntityAggregationSuggestion,
  InceptionResponse,
  EntityAggregationSuggestionResponse,
  EntityAggregationResponse,
  EntityAggregationRequest
} from "../../types";
import { suggestionsResponse } from "./suggestions";
import { aggregationsResponse } from "./aggregations";

const wrapDataInPromise = <T>(data: T): Promise<InceptionResponse<T>> =>
  Promise.resolve({
    data,
    status: 200,
    statusText: "OK",
    config: {},
    headers: {}
  });

export const getMockSuggestions = (fieldNames: string[]) => {
  const { entityType, suggestedAggregations } = suggestionsResponse;
  const fSuggestedAggregations: Record<string, EntityAggregationSuggestion> = {};

  Object.keys(suggestedAggregations).forEach(fieldName => {
    if (fieldNames.includes(fieldName)) {
      fSuggestedAggregations[fieldName] = suggestedAggregations[fieldName];
    }
  });

  return wrapDataInPromise({
    suggestedAggregations: fSuggestedAggregations,
    entityType
  } as EntityAggregationSuggestionResponse);
};

export const getMockAggregations = (requests: EntityAggregationRequest[]) => {
  const { aggregations, entityType } = aggregationsResponse;
  const fAggregations: typeof aggregations = {};

  const fieldNames = requests.map(r => r.field).filter(f => f);
  Object.keys(aggregations).forEach(fieldName => {
    if (fieldNames.includes(fieldName)) {
      fAggregations[fieldName] = aggregations[fieldName];
    }
  });

  return wrapDataInPromise({
    aggregations: fAggregations,
    entityType
  } as EntityAggregationResponse);
};
