import { isEmpty } from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { useFetchDataStatus } from "../../../../core";
import exploreApiService from "../ExploreApiService";
import { WidgetResponseDTO } from "../types/exploreTypes";

interface WidgetConfigEntry {
  data: WidgetResponseDTO[];
  error: string;
}

const useFetchExploreWidgetsWithCache = (
  entityTypeOrId: string,
  mode: "bizEntity" | "userService",
  cohortId?: string,
  filterLabels?: Record<string, string>,
  includeOpInfo?: boolean,
  initialLoading?: boolean
) => {
  const widgetConfigCache = useRef<Map<string, WidgetConfigEntry>>(new Map());

  const {
    data: widgetConfigs,
    error,
    isError,
    isFetching,
    isSuccess,
    setStatus
  } = useFetchDataStatus<WidgetResponseDTO[], string>({
    data: [],
    isFetching: initialLoading || false
  });

  const setData = useCallback(async () => {
    setStatus([], true, false, false, "");
    const mapKey = `${entityTypeOrId}-${cohortId}`;
    const entry = widgetConfigCache.current.get(mapKey);
    if (entityTypeOrId) {
      if (entry) {
        const { data, error } = entry;
        const hasErr = !isEmpty(error);
        setStatus(data, false, !hasErr, hasErr, error);
      } else {
        const entityType = mode === "bizEntity" ? entityTypeOrId : null;
        const entityId = mode === "userService" ? entityTypeOrId : null;

        const {
          data = [],
          error,
          message
        } = await exploreApiService.getWidgetConfigs(
          entityId,
          entityType,
          cohortId,
          undefined,
          filterLabels,
          includeOpInfo
        );

        const newEntry: WidgetConfigEntry = {
          data,
          error: error ? message : ""
        };

        widgetConfigCache.current.set(mapKey, newEntry);
        setStatus(newEntry.data, false, !error, error, message);
      }
    } else {
      setStatus([], false, false, true, "Invalid entityTypeOrId");
    }
  }, [setStatus, entityTypeOrId, cohortId, mode, filterLabels, includeOpInfo]);

  useEffect(() => {
    setData();
  }, [setData]);

  const setWidgetConfigs = useCallback(
    (data: WidgetResponseDTO[]) => {
      setStatus(data, false, false, false, "");
    },
    [setStatus]
  );

  return {
    setWidgetConfigs,
    widgetConfigs,
    isError,
    isFetching,
    isSuccess,
    error
  };
};

export default useFetchExploreWidgetsWithCache;
