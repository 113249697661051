import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { ApplicationState } from "../../../../store/configureStore";
import { schemaApiService } from "../../../services/api";
import { EntityType } from "../../../services/api/entity-mapping";
import { logger } from "../../logging/Logger";
import { setSchemaStore, setFetchState } from "./reducer";

class SchemaCache {
  private schemaCache: Record<string, EntityType> = {};

  setSchema = (schema: Record<string, EntityType>) => (this.schemaCache = schema);

  getSchema = () => this.schemaCache;

  getEntityTypeById = (entityId: string) => this.schemaCache[entityId];
}

const schemaCache = new SchemaCache();

const useSchemaStore = () => {
  const dispatch = useDispatch();
  const { entityTypes, rawEntityTypesData, fetchInProgress } = useSelector(
    (state: ApplicationState) => state.schema,
    shallowEqual
  );

  const fetchAndInitializeSchema = useCallback(async () => {
    dispatch(setFetchState(true));
    try {
      const response = await schemaApiService.getEntityTypes();
      const types = response?.data?.types;
      if (types) {
        const latestEntityTypes: Record<string, EntityType> = {};
        (types as any[]).forEach(x => {
          if (x?.entityType?.typeReference?.id) {
            latestEntityTypes[x.entityType.typeReference.id] = x.entityType;
          }
        });
        schemaCache.setSchema(latestEntityTypes);
        dispatch(
          setSchemaStore({
            entityTypes: latestEntityTypes,
            rawEntityTypesData: types,
            fetchInProgress: false
          })
        );
      }
    } catch (e) {
      logger.error("Error while fetching entity types from schema servive", e);
      dispatch(
        setSchemaStore({
          entityTypes: {},
          rawEntityTypesData: [],
          fetchInProgress: false
        })
      );
    }
  }, [dispatch]);

  return {
    fetchOrRefreshSchema: fetchAndInitializeSchema,
    entityTypes,
    rawEntityTypesData,
    fetchInProgress
  };
};

const useFetchEntityTypeName = (entityTypeId: string) => {
  const { entityTypes, fetchInProgress } = useSchemaStore();
  const entityTypeName = entityTypes?.[entityTypeId]?.typeReference?.typeName;
  return {
    entityTypeName,
    fetchInProgress
  };
};

const schemaCacheService = {
  getEntityTypes: () => schemaCache.getSchema(),
  getEntityTypeById: (entityId: string) => schemaCache.getEntityTypeById(entityId),
  getEntityTypeName: function (entityId: string) {
    const entityType = this.getEntityTypeById(entityId);
    return entityType?.typeReference?.typeName;
  }
};

export { useSchemaStore, useFetchEntityTypeName, schemaCacheService };
