import { duration } from "moment";
import { isNull, isUndefined } from "lodash";
import { OpCreationConfig, UIIntegrationActionConfig, CompareOperator } from "../../services/api/operationalise";
import { SourceTypeInfoByCategory, OpConfigErrors, OP_ACTION_ERROR_PREFIX } from "../types";
import { UI_SCHEDULE_KEY, SCHEDULE_TYPES, UI_THRESHOLD_KEY, THRESHOLD_TYPES } from "../constants";
import { USFieldWidgetUtils } from "../../dashboard/widgets/USField/USFieldWidgetUtils";
import { validateOpAnalysisConfig } from "../anaysis-operationalize";
import { getValueFromParamValue } from ".";

export const validateOpConfig = (
  opCreationConfig: OpCreationConfig,
  uiIntegrationActionConfigsMap: Record<string, UIIntegrationActionConfig>,
  sourceTypeInfoByCategory: SourceTypeInfoByCategory,
  ignoreScheduleStartAndEnd = false,
  ignoreActionConnectionErrors = false
) => {
  let isValid = true;
  let errors: Partial<OpConfigErrors> = {};

  const { name, bizActions, labels, opCreationConfigDef, outlierConfig, opAnalysisConfig } = opCreationConfig || {};

  if (!name) {
    isValid = false;
    errors.name = "Name is required";
  }

  if (opAnalysisConfig) {
    const analysisErrors = validateOpAnalysisConfig(opAnalysisConfig);

    const isOpAnalysisValid = !Object.values(analysisErrors).length;
    isValid = isValid && isOpAnalysisValid;
    if (!isOpAnalysisValid) {
      errors.opAnalysisConfig = Object.values(analysisErrors).join(", ");
    }
  } else {
    const { errors: opCreationConfigErrors, isValid: opCreationConfigIsValid } = validateOpCreationConfigDef(
      opCreationConfigDef,
      outlierConfig,
      labels,
      ignoreScheduleStartAndEnd
    );

    isValid = isValid && opCreationConfigIsValid;
    errors = {
      ...errors,
      ...opCreationConfigErrors
    };
  }

  const { errors: bizActionsErrors, isValid: bizActionsIsValid } = validateBizActions(
    bizActions,
    uiIntegrationActionConfigsMap,
    sourceTypeInfoByCategory,
    ignoreActionConnectionErrors
  );

  isValid = isValid && bizActionsIsValid;
  errors = {
    ...errors,
    ...bizActionsErrors
  };

  return {
    isValid,
    errors
  };
};

const validateOpCreationConfigDef = (
  opCreationConfigDef: OpCreationConfig["opCreationConfigDef"],
  outlierConfig: OpCreationConfig["outlierConfig"],
  labels: Record<string, string>,
  ignoreScheduleStartAndEnd = false
) => {
  let isValid = true;
  const errors: Partial<OpConfigErrors> = {};

  const entityAggregated = labels?.entityAggregated === "true";

  if (!opCreationConfigDef && !outlierConfig) {
    isValid = false;
    errors.opCreationConfigDef = "OpCreationConfigDef is missing";
  }

  const {
    bizDataQuery,
    rollingFreq: opRollingFreq,
    rollingFunction: opRollingFunc,
    schedule,
    threshold,
    startTrigger
  } = opCreationConfigDef || {};

  const { bizDataQuery: outlierOpBizDataQuery, schedule: outlierSchedule } = outlierConfig || {};

  const {
    bizDataQuery: outlierBizDataQuery,
    rollingFreq: outlierRollingFreq,
    rollingFunction: outlierRollingFunc
  } = outlierOpBizDataQuery || {};

  const rollingFreq = opRollingFreq || outlierRollingFreq;
  const rollingFunction = opRollingFunc || outlierRollingFunc;

  if (!bizDataQuery && !outlierBizDataQuery) {
    isValid = false;
    errors.bizDataQuery = "BizDataQuery is required";
  }

  if (!schedule && !outlierSchedule) {
    isValid = false;
    errors.schedule = "Schedule is required";
  }

  let thresholdValid = true;
  let thresholdCanBeEmpty = false;
  let triggerConditionValid = true;
  let triggerConditionCanBeEmpty = false;

  const {
    sliceSpec,
    buildingBlockConfig: buildingBlocksConfig,
    dataQuery,
    id,
    widgetConfig
  } = bizDataQuery || outlierBizDataQuery || {};

  const { schedule: nSchedule, labels: scheduleLabels, endOffset, startOffset } = schedule || outlierSchedule || {};
  const { endTimeEpochSecs, startTimeEpochSecs, specificScheduleConfig } = nSchedule || {};

  const { aboveThresholdDef, belowThresholdDef, comparator, labels: thresholdLabels } = threshold || {};

  const uiSchedule = scheduleLabels?.[UI_SCHEDULE_KEY];
  const isEventOccurranceSchedule = uiSchedule === SCHEDULE_TYPES.whenEventOccurs;
  const isContinuousSchedule = uiSchedule === SCHEDULE_TYPES.everyMinute;

  const uiThresholdType = thresholdLabels?.[UI_THRESHOLD_KEY];
  const isAutomaticThreshold = uiThresholdType === THRESHOLD_TYPES.automatic;
  const isPeerThreshold = uiThresholdType === THRESHOLD_TYPES.peerGroup;
  const isStaticThreshold = uiThresholdType === THRESHOLD_TYPES.static;
  const isTimeshiftThreshold = uiThresholdType === THRESHOLD_TYPES.timeShift;

  if (!sliceSpec) {
    isValid = false;
    errors["bizDataQuery.sliceSpec"] = "sliceSpec is required";
  } else {
    const { buildingBlockConfigId, fieldId, metricId } = sliceSpec || {};
    if (!fieldId && !metricId && !buildingBlockConfigId) {
      isValid = false;
      errors["bizDataQuery.sliceSpec"] = "fieldId or metricId or buildingBlockConfigId is required";
    }
  }

  if (!buildingBlocksConfig && !dataQuery && !id && !widgetConfig) {
    isValid = false;
    errors["bizDataQuery.queryConfig"] = "BuildingBlocksConfig or Id or DataQuery or WidgetConfig is required";
  } else {
    if (buildingBlocksConfig) {
      const { aggregator, buildingBlockDef } = buildingBlocksConfig;
      if (isEventOccurranceSchedule && aggregator) {
        isValid = false;
        errors["bizDataQuery.buildingBlocksConfig.cohortAggregator"] =
          "Cohort aggregator should not exist for event occurrance schedule";
      } else if (entityAggregated && !aggregator) {
        isValid = false;
        errors["bizDataQuery.buildingBlocksConfig.cohortAggregator"] =
          "Cohort aggregator is required when entity is aggregated";
      } else {
        const { aggregator, fieldConfig } = buildingBlockDef || {};
        if (isEventOccurranceSchedule && aggregator) {
          isValid = false;
          errors["bizDataQuery.buildingBlocksConfig.aggregator"] =
            "Aggregator should not exist for event occurrance schedule";
        }

        const fieldName = fieldConfig?.userServiceField?.fieldName;
        const isEventIDOperationalize = USFieldWidgetUtils.isEventIDField(fieldName);
        const isHasErrorField = USFieldWidgetUtils.isHasErrorField(fieldName);

        thresholdCanBeEmpty = isEventIDOperationalize || isHasErrorField;
        triggerConditionCanBeEmpty = !aggregator || isEventIDOperationalize || isHasErrorField;
        if (opCreationConfigDef) {
          if (aggregator && !threshold) {
            isValid = false;
            errors.threshold = "Threshold is required";
            thresholdValid = false;
          }

          if (aggregator && !startTrigger) {
            isValid = false;
            errors.triggerCondition = "Triggers are required";
            triggerConditionValid = false;
          }
        }
        if (aggregator && !rollingFreq?.value) {
          isValid = false;
          errors["opCreationConfig.rollingFreq"] = "Rolling frequency is required for metrics";
        } else if (aggregator && !rollingFunction) {
          isValid = false;
          errors["opCreationConfig.rollingFunction"] = "Rolling function is required for metrics";
        } else if (!aggregator && isContinuousSchedule && rollingFreq?.value) {
          isValid = false;
          errors["opCreationConfig.rollingFreq"] =
            "Rolling frequency shouldn't exist for event field with continuous schedule";
        } else if (!aggregator && rollingFunction) {
          isValid = false;
          errors["opCreationConfig.rollingFunction"] = "Rolling function shouldn't exist for event field";
        }

        const { bizField, userServiceField } = fieldConfig || {};
        if (!bizField && !userServiceField) {
          isValid = false;
          errors["bizDataQuery.buildingBlocksConfig.fieldConfig"] = "FieldConfig is required";
        }
      }
    }
  }

  if (startOffset && endOffset) {
    const startOffsetDuration = duration(startOffset.value, startOffset.unit as any);
    const endOffsetDuration = duration(endOffset.value, endOffset.unit as any);

    if (!startOffsetDuration.isValid()) {
      isValid = false;
      errors["schedule.startOffset"] = "Invalid startOffset";
    }

    if (!endOffsetDuration.isValid()) {
      isValid = false;
      errors["schedule.endOffset"] = "Invalid endOffset";
    }

    const durDiffValid = startOffsetDuration.asMilliseconds() > endOffsetDuration.asMilliseconds();
    if (!durDiffValid) {
      isValid = false;
      errors["schedule.startOffset"] = "startOffset must be before endOffset";
      errors["schedule.endOffset"] = "endOffset must be after startOffset";
    }
  }

  if (!ignoreScheduleStartAndEnd && startTimeEpochSecs && endTimeEpochSecs && startTimeEpochSecs > endTimeEpochSecs) {
    isValid = false;
    errors["schedule.startTimeEpochSecs"] = "startTime must be before endTime";
    errors["schedule.endTimeEpochSecs"] = "endTime must be after startTime";
  }

  const { month, dayOfMonth, dayOfWeek, hour, minute } = specificScheduleConfig || {};
  const specSchedule = month || dayOfMonth || dayOfWeek || hour || minute;
  if (opCreationConfigDef) {
    if (!specSchedule) {
      isValid = false;
      errors["schedule.specificScheduleConfig"] = "SpecificScheduleConfig is required";
    } else {
      const { list, range, step } = specSchedule;
      if (!list?.list?.length && !range?.max && !range?.min && !step) {
        isValid = false;
        errors["schedule.specificScheduleConfig"] = "One of list, range, step is required";
      }
    }
  }
  if (opCreationConfigDef) {
    if (thresholdValid && !thresholdCanBeEmpty) {
      if (!aboveThresholdDef && !belowThresholdDef) {
        if (!isAutomaticThreshold) {
          isValid = false;
          errors["threshold.thresholdDef"] = "ThresholdDef is required";
        }
      } else {
        const thresholdDef = aboveThresholdDef || belowThresholdDef;
        if (comparator === CompareOperator.AboveOrBelow && (!aboveThresholdDef || !belowThresholdDef)) {
          isValid = false;
          errors["threshold.thresholdDef"] = "AboveThresholdDef and BelowThresholdDef are required";
        } else if (thresholdDef) {
          const { offsetThreshold, peerThreshold, thresholdOp, scalar } = thresholdDef;
          if (isPeerThreshold) {
            const { peerAgg, peerOfField, peerOfSlice } = peerThreshold || {};
            if (!peerAgg) {
              isValid = false;
              errors["threshold.thresholdDef.peerThreshold.peerAgg"] = "Aggregator for peer field is required";
            }

            if (!peerOfField && !peerOfSlice) {
              isValid = false;
              errors["threshold.thresholdDef.peerThreshold"] = "Peer field/slice is required";
            }
          } else if (isTimeshiftThreshold) {
            if (!offsetThreshold?.offset?.value) {
              isValid = false;
              errors["threshold.thresholdDef.offsetThreshold"] = "OffsetThreshold is required";
            }
          }

          if (isPeerThreshold || isTimeshiftThreshold) {
            if (!thresholdOp?.value) {
              isValid = false;
              errors["threshold.thresholdDef.thresholdOp"] = "ThresholdOp is required";
            }
          }

          if ((isNull(scalar) || isUndefined(scalar)) && isStaticThreshold) {
            isValid = false;
            errors["threshold.thresholdDef.scalar"] = "Scalar is required";
          }
        }
      }
    }
  }

  if (opCreationConfigDef) {
    if (triggerConditionValid && !triggerConditionCanBeEmpty) {
      const { allMatchStartTrigger, anyMatchStartTrigger } = startTrigger || {};
      const triggerConditions =
        allMatchStartTrigger?.triggerConditions || anyMatchStartTrigger?.triggerConditions || [];

      triggerConditions.forEach(trigger => {
        const { accumulatorTrigger, windowTrigger } = trigger;

        if (accumulatorTrigger) {
          const { unitDiff, time } = accumulatorTrigger;

          if (!unitDiff) {
            isValid = false;
            errors["triggerCondition.unitDiff"] = "Trigger UnitDiff is required";
          }

          if (!time) {
            isValid = false;
            errors["triggerCondition.time"] = "Trigger Time is required";
          }
        } else if (windowTrigger) {
          const { howManyTimesToViolate, outOfPoints, outOfTime } = windowTrigger;
          const outOfValue = outOfTime || outOfPoints;

          if (!howManyTimesToViolate) {
            isValid = false;
            errors["triggerCondition.howManyTimesToViolate"] = "No. of times to violate is required";
          }

          if (!outOfValue) {
            isValid = false;
            errors["triggerCondition.outOfTime"] = "Total count cannot be empty";
            errors["triggerCondition.outOfPoints"] = "Total count cannot be empty";
          }

          if (howManyTimesToViolate > (outOfValue as number)) {
            isValid = false;
            errors["triggerCondition.howManyTimesToViolate"] =
              "Total count cannot be lesser than no.of times to violate";
            errors["triggerCondition.outOfTime"] = "Total count cannot be lesser than no.of times to violate";
            errors["triggerCondition.outOfPoints"] = "Total count cannot be lesser than no.of times to violate";
          }
        }
      });
    }
  }

  if (opCreationConfigDef) {
    const { suppression: suppressions } = opCreationConfigDef;
    suppressions.forEach(suppression => {
      if (!suppression.bizDataQuery) {
        isValid = false;
        errors["suppression.bizDataQuery"] = "Metric definition is required for suppression";
      } else if (!suppression.operator) {
        isValid = false;
        errors["suppression.operator"] = "Operator is required for suppression";
      } else if (!suppression.value) {
        isValid = false;
        errors["suppression.value"] = "Value is required for suppression";
      }
    });
  }

  return {
    isValid,
    errors: errors as OpConfigErrors
  };
};

const validateBizActions = (
  bizActions: OpCreationConfig["bizActions"],
  uiIntegrationActionConfigsMap: Record<string, UIIntegrationActionConfig>,
  sourceTypeInfoByCategory: SourceTypeInfoByCategory,
  ignoreActionConnectionErrors: boolean
) => {
  let isValid = true;
  const errors: Partial<OpConfigErrors> = {};

  const bizActionIds = Object.keys(bizActions || {});
  bizActionIds.forEach(bizActionId => {
    const nestedKeys = [bizActionId];
    const bizActionConfig = bizActions[bizActionId];
    const { alertActionId, alertActionConfig } = bizActionConfig;

    if (!alertActionId && !alertActionConfig) {
      isValid = false;
      const key1 = getActionErrorKey([...nestedKeys, "alertActionConfig"]);
      const key2 = getActionErrorKey([...nestedKeys, "alertActionId"]);
      errors[key1] = errors[key2] = "One of alertActionConfig and alertActionId is required";
    }

    const { alertActionConfigDef, actionId } = alertActionConfig || {};

    if (alertActionConfig) {
      if (!alertActionConfigDef && !actionId) {
        isValid = false;
        const key1 = getActionErrorKey([...nestedKeys, "alertActionConfigDef"]);
        const key2 = getActionErrorKey([...nestedKeys, "actionId"]);
        errors[key1] = errors[key2] = "One of alertActionConfigDef and actionId is required";
      }

      if (alertActionConfigDef) {
        const _nestedKeys = [...nestedKeys, "alertActionConfigDef"];
        const { alertActionDef } = alertActionConfigDef;

        if (!alertActionDef) {
          isValid = false;
          const key = getActionErrorKey([..._nestedKeys, "alertActionDef"]);
          errors[key] = "alertActionDef cannot be empty";
        } else {
          const __nestedKeys = [..._nestedKeys, "alertActionDef"];
          const {
            actionCategoryType,
            connectorId: connectionId,
            integrationActionConfigId,
            params,
            sourceTypeId
          } = alertActionDef;

          const connectorMandatory = ignoreActionConnectionErrors
            ? false
            : !sourceTypeInfoByCategory?.[actionCategoryType]?.[sourceTypeId]?.noConnector;

          const key = [integrationActionConfigId, connectionId].join("-");
          const integrationActionConfig = uiIntegrationActionConfigsMap[key];

          if (!actionCategoryType) {
            isValid = false;
            const key = getActionErrorKey([...__nestedKeys, "actionCategoryType"]);
            errors[key] = "actionCategoryType cannot be empty";
          }

          if (!connectionId && connectorMandatory) {
            isValid = false;
            const key = getActionErrorKey([...__nestedKeys, "connectionId"]);
            errors[key] = "connectionId cannot be empty";
          }

          if (!integrationActionConfigId) {
            isValid = ignoreActionConnectionErrors ? true : false;
            if (!ignoreActionConnectionErrors) {
              const key = getActionErrorKey([...__nestedKeys, "integrationActionConfigId"]);
              errors[key] = "integrationActionConfigId cannot be empty";
            }
          } else {
            const paramsInfo = integrationActionConfig?.uiParams || {};
            const paramNames = Object.keys(paramsInfo);
            paramNames.forEach(paramName => {
              const paramValue = params[paramName];
              const uiParamInfo = paramsInfo[paramName];

              if (uiParamInfo) {
                const { type, required, subtype } = uiParamInfo;
                const value = paramValue ? getValueFromParamValue(paramValue, type, subtype) : null;

                if (required && !value) {
                  isValid = false;
                  const key = getActionErrorKey([...__nestedKeys, "params", paramName]);
                  errors[key] = `${paramName} is required`;
                }
              }
            });
          }
        }
      }
    }
  });

  return {
    isValid,
    errors
  };
};

const getActionErrorKey = (subKeys: string | string[]) => {
  subKeys = Array.isArray(subKeys) ? subKeys : [subKeys];
  return [OP_ACTION_ERROR_PREFIX, ...subKeys].join(".");
};
