import { useCallback } from "react";

const useClipboard = () => {
  const addToClipboard = useCallback((data: string) => clipboardUtils.addToClipboard(data), []);
  const readFromClipboard = useCallback(async (): Promise<string> => await clipboardUtils.readFromClipboard(), []);

  return {
    addToClipboard,
    readFromClipboard
  };
};

/**
 * @deprecated Prefer useClipboard over this.
 */
const clipboardUtils = {
  addToClipboard: async (data: string) => await navigator.clipboard.writeText(data),
  readFromClipboard: async (): Promise<string> => await navigator.clipboard.readText()
};

export { clipboardUtils, useClipboard };
