import { isEmpty } from "lodash";
import { checkIsNaN, convertToNumber, defNumberFormatter, NumericDataConguration, NumericDataInfo } from "../numeric";
import { getDuration } from "../date-time";

export const getDurationInfo = (durationValue: string, disableAbbreviations: boolean): NumericDataInfo => {
  const durationDataInfo: NumericDataInfo = {
    isValid: false,
    abbreviatedValue: "",
    absoluteValue: ""
  };

  if (isEmpty(durationDataInfo)) {
    return durationDataInfo;
  }

  if (durationValue === "No Min" || durationValue === "No Max") {
    durationDataInfo.isValid = true;
    durationDataInfo.abbreviatedValue = durationValue;
    return durationDataInfo;
  }

  const durationDataConfig = getDurationDataConfiguration();
  //check if duration value is valid
  if (durationDataConfig.regex.test(durationValue)) {
    durationDataInfo.isValid = true;
    durationDataInfo.abbreviatedValue = durationValue;

    //compute absolute value
    durationDataInfo.absoluteValue = !checkIsNaN(durationValue)
      ? Number(durationValue).toString()
      : durationDataConfig.convertToNumber(durationValue).toString();

    //compute abbreviated value
    if (!disableAbbreviations && !checkIsNaN(durationValue)) {
      durationDataInfo.abbreviatedValue = getDuration(durationValue);
    }
  }
  return durationDataInfo;
};

const locale = "en-US";

export const durationConfigMap: Record<string, NumericDataConguration> = {
  [locale]: {
    quickValues: ["1h", "2h", "6h", "1d", "2d"],
    regex: new RegExp(/^([0-9]*\.?[0-9]+)(y|M|d|h|m)?$/),
    abbreviation: {
      y: 31556952000,
      M: 2592000000,
      w: 604800000,
      d: 86400000,
      h: 3600000,
      m: 60000
    },
    formatter: defNumberFormatter,
    convertToNumber: function (input: string): number {
      return convertToNumber(input, {
        abbreviationMap: this.abbreviation,
        regex: this.regex
      });
    }
  }
};

export const getDurationDataConfiguration = (): NumericDataConguration => durationConfigMap["en-US"];
