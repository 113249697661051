import { logger } from "../../core";
import { ApptuitDatasource } from "../datasources/apptuit/ApptuitDatasource";
import datasourceApiManager from "./DatasourceApiService";
import { EntityMetricResult } from "./types";

export interface EntityMetricResponse {
  metricResults: EntityMetricResult[];
}

class MetricSchemaApiService {
  private datasource: ApptuitDatasource;

  async init() {
    this.datasource = await datasourceApiManager.getDefault();
  }

  async getMetricsForEntityTypeOrId(entityTypeOrId: string, showInternal = false): Promise<EntityMetricResult[]> {
    await this.init();
    const url = `/api/metric-schema/v1/${entityTypeOrId}/metrics?showInternal=${showInternal}`;
    try {
      const { data } = await this.datasource.get<EntityMetricResponse, null>(url);
      if (data?.metricResults) {
        return data.metricResults;
      } else {
        logger.info("Schema API service", `Empty response for entity metrics for entity type or id ${entityTypeOrId}`);
        return [];
      }
    } catch (err) {
      logger.error("Schema API service", `Error fetching mertics for entity type or id ${entityTypeOrId}`, err);
      return [];
    }
  }
}

const metricSchemaApiService = new MetricSchemaApiService();
export { metricSchemaApiService };
