import { BizService } from "../explore/BizServiceCommon";
import { dateTime } from "../../../core";
import {
  ActionConfigResponse,
  ActionDetailsResponse,
  ActionCountResponse,
  IncidentsCountResponse
} from "../explore/types/exploreTypes";

class CohortOperationalizeApiService extends BizService {
  private getDefaultTimeRange() {
    return {
      defaultStart: dateTime().subtract(1, "year").valueOf(),
      defaultEnd: dateTime().valueOf()
    };
  }

  private getBaseSubUrl(
    entityTypeId: string,
    cohortId: string,
    type: string,
    endpoint: string,
    startTime: number,
    endTime: number,
    onlyActive = false
  ) {
    const { defaultStart, defaultEnd } = this.getDefaultTimeRange();
    const startMillis = startTime || defaultStart;
    const endMillis = endTime || defaultEnd;
    const queryParams = [`startTimeMillis=${startMillis}`, `endTimeMillis=${endMillis}`];
    if (onlyActive) {
      queryParams.push("onlyActive=true");
    }
    const subUrl = `${entityTypeId}/cohort/${cohortId}/${type}/${endpoint}?${queryParams.join("&")}`;
    return this.getBizEntityUrl(subUrl);
  }

  async getActionCount(entityTypeId: string, cohortId: string, startTime: number, endTime: number, onlyActive = false) {
    await this.init();
    const url = this.getBaseSubUrl(entityTypeId, cohortId, "action", "count", startTime, endTime, onlyActive);
    const response = await this.datasource.get<ActionCountResponse, never>(url);
    return response?.data?.actionCount;
  }

  async getActionConfigs(entityTypeId: string, cohortId: string, startTime: number, endTime: number) {
    await this.init();
    const url = this.getBaseSubUrl(entityTypeId, cohortId, "action", "configs", startTime, endTime);
    const response = await this.datasource.get<ActionConfigResponse, never>(url);
    return response?.data?.actionConfigDetails;
  }

  async getActionDetails(entityTypeId: string, cohortId: string, startTime: number, endTime: number) {
    await this.init();
    const url = this.getBaseSubUrl(entityTypeId, cohortId, "action", "details", startTime, endTime);
    const response = await this.datasource.get<ActionDetailsResponse, never>(url);
    return response?.data;
  }

  async getCohortIncidentsCount(
    entityTypeId: string,
    cohortId: string,
    startTime: number,
    endTime: number,
    onlyActive = false
  ) {
    await this.init();
    const url = this.getBaseSubUrl(entityTypeId, cohortId, "incidents", "count", startTime, endTime, onlyActive);
    const response = await this.datasource.get<IncidentsCountResponse, never>(url);
    return response?.data;
  }

  // async getActionEntities(entityTypeId: string, cohortId: string, startTime: number, endTime: number) {
  //   const url = this.getBaseSubUrl(entityTypeId, cohortId, '/entities', startTime, endTime);
  //   const response = this.datasource.get(url);
  //   return response;
  // }
}

const cohortOperationalizeApiService = new CohortOperationalizeApiService();

export { cohortOperationalizeApiService };
