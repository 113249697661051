import { defaults } from "lodash";
import { useState, useCallback } from "react";

interface DataStates<R, E> {
  data: R;
  error: E;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
}

export interface FetchDataStatus<R, E> extends DataStates<R, E> {
  setStatus: (data: R, isFetching: boolean, isSuccess: boolean, isError: boolean, error: E) => void;
  resetStates: () => void;
}

export const useFetchDataStatus = <R, E = unknown>(initialState?: Partial<DataStates<R, E>>): FetchDataStatus<R, E> => {
  const [dataStates, setDataStates] = useState<DataStates<R, E>>(
    defaults(initialState, {
      data: null,
      error: null,
      isFetching: false,
      isError: false,
      isSuccess: false
    })
  );

  const setStatus = useCallback((data: R, isFetching: boolean, isSuccess: boolean, isError: boolean, error: E) => {
    setDataStates({
      data,
      error,
      isError,
      isSuccess,
      isFetching
    });
  }, []);

  const resetStates = useCallback(() => {
    setStatus(null, false, false, false, null);
  }, [setStatus]);

  return {
    ...dataStates,
    setStatus,
    resetStates
  };
};
