import appConfig from "../../../appConfig";
import { getBooleanParamValue } from "../../core/UrlParamUtils";

export enum LogLevel {
  TRACE = 0,
  DEBUG,
  INFO,
  WARN,
  ERROR
}

// Keep in sync with above
const COLORS = {
  TRACE: "#888",
  DEBUG: "green",
  INFO: "blue",
  WARN: "orange",
  ERROR: "#FF0000"
};

class Logger {
  private DEFAULT_LOG_LEVEL: LogLevel = LogLevel.INFO;

  private readonly URL_QUERY_PARAM_KEY: string = "debug";

  constructor() {
    const isDebugMode: boolean = getBooleanParamValue(this.URL_QUERY_PARAM_KEY);
    if (isDebugMode) {
      this.DEFAULT_LOG_LEVEL = LogLevel.DEBUG;
    }
  }

  log(level: LogLevel, module: string, message: string): void {
    switch (level) {
      case LogLevel.TRACE: {
        this.trace(module, message);
        break;
      }

      case LogLevel.DEBUG: {
        this.debug(module, message);
        break;
      }

      case LogLevel.INFO: {
        this.info(module, message);
        break;
      }

      case LogLevel.WARN: {
        this.warn(module, message);
        break;
      }

      case LogLevel.ERROR: {
        this.error(module, message);
        break;
      }

      default:
        break;
    }
  }

  trace(module: string, message: string): void {
    if (this.allowLoggingForLevel(LogLevel.TRACE)) {
      console.log(`%c ${appConfig.logPrefixes.trace}: ${module}: ${message}`, `color: ${COLORS["TRACE"]}`);
    }
  }

  debug(module: string, message: string, payload?: unknown): void {
    if (this.allowLoggingForLevel(LogLevel.DEBUG)) {
      console.log(
        `%c ${appConfig.logPrefixes.debug}: ${module}: ${message}`,
        `color: ${COLORS["DEBUG"]}`,
        payload ? payload : ""
      );
    }
  }

  info(module: string, message: string, payload?: unknown): void {
    if (this.allowLoggingForLevel(LogLevel.INFO)) {
      console.log(
        `%c ${appConfig.logPrefixes.info}: ${module}: ${message}`,
        `color: ${COLORS["INFO"]}`,
        payload ? payload : ""
      );
    }
  }

  warn(module: string, message: string, payload?: unknown): void {
    if (this.allowLoggingForLevel(LogLevel.WARN)) {
      console.log(
        `%c ${appConfig.logPrefixes.warn}: ${module}: ${message} \n ${payload}`,
        `color: ${COLORS["WARN"]}`,
        payload ? payload : ""
      );
    }
  }

  error(module: string, message: string, error?: any): void {
    error = error || "";
    if (this.allowLoggingForLevel(LogLevel.ERROR)) {
      console.log(
        `%c ${appConfig.logPrefixes.error}: ${module}: ${message} \n ${error}`,
        `color: ${COLORS["ERROR"]}`,
        error
      );
    }
  }

  fatal(module: string, message: string, error?: any): void {
    error = error || "";
    if (this.allowLoggingForLevel(LogLevel.ERROR)) {
      console.error(
        `%c ${appConfig.logPrefixes.error}: ${module}: ${message} \n ${error}`,
        `color: ${COLORS["ERROR"]}`,
        error
      );
    }
  }

  network(module: string, message: string, payload?: unknown): void {
    if (this.allowLoggingForLevel(LogLevel.DEBUG)) {
      // eslint-disable-next-line no-console
      console.debug(
        `%c ${appConfig.logPrefixes.network}: ${module}: ${message}`,
        `color: ${COLORS["INFO"]}`,
        payload ? payload : ""
      );
    }
  }

  private allowLoggingForLevel(level: LogLevel): boolean {
    return level >= this.DEFAULT_LOG_LEVEL;
  }

  // public: only for testing purpose
  setDefaultLogLevel(level: LogLevel): void {
    this.DEFAULT_LOG_LEVEL = level;
  }
}

const loggerInstance = new Logger();

export { loggerInstance as logger };
