import { IncSelectOption } from "@inception/ui";
import { SpecificScheduleConfig } from "../../services/api/operationalise";
import { SCHEDULE_TYPES } from ".";

export const CUSTOM_OPT_VALUE = "custom";

export const scheduleTypeOpts: Array<IncSelectOption<SpecificScheduleConfig>> = [
  {
    label: "On event",
    value: SCHEDULE_TYPES.whenEventOccurs,
    data: {
      minute: {
        step: 1
      }
    }
  },
  {
    label: "Every minute",
    value: SCHEDULE_TYPES.everyMinute,
    data: {
      minute: {
        step: 1
      }
    }
  },
  {
    label: "Every hour",
    value: SCHEDULE_TYPES.everyHour,
    data: {
      hour: {
        step: 1
      }
    }
  },
  {
    label: "Every day",
    value: SCHEDULE_TYPES.everyDay,
    data: {
      dayOfWeek: {
        step: 1
      }
    }
  },
  {
    label: "Every week",
    value: SCHEDULE_TYPES.everyWeek,
    data: {
      dayOfWeek: {
        step: 1
      }
    }
  },
  {
    label: "Every month",
    value: SCHEDULE_TYPES.everyMonth,
    data: {
      dayOfMonth: {
        step: 1
      }
    }
  },
  {
    label: "Custom",
    value: SCHEDULE_TYPES.custom,
    data: {
      hour: {
        step: 12
      }
    }
  }
];

export const startTimeOptions: IncSelectOption[] = [
  {
    label: "now",
    value: "now"
  },
  {
    label: "custom",
    value: CUSTOM_OPT_VALUE
  }
];

export const endTimeOptions: IncSelectOption[] = [
  {
    label: "forever",
    value: "forever"
  },
  {
    label: "custom",
    value: CUSTOM_OPT_VALUE
  }
];
