import { isEmpty, padEnd, pick } from "lodash";
import moment from "moment-timezone";
import { createIntl } from "react-intl";
import { format, parse } from "date-fns";
import { dateTimeFormats, withSecondsOptions, hour12Options, hour24Options } from "./formats";
import {
  IncDateTimeFormat,
  IncHighchartsDateTimeFormat,
  IncDateFormatOptions,
  IncTimeFormatOptions,
  IncDateTimeFormatOptions,
  DateTimeOptions
} from "./formatter-types";
import { TimeUnit, TimeZone } from "./types";

export const getDateTimeByFormat = (value: number | Date, dateFormat: IncDateTimeFormat) => {
  const stringFormat: Record<IncDateTimeFormat, string[]> = {
    full: ["DDD, MMM dd, yyyy HH:mm"],
    minimal: ["MMM dd, yyyy HH:mm"],
    numeric: ["MM-dd-yyyy HH:mm"],
    cohortNumericDate: ["yyyy-MM-dd"],
    cohortNumericDateTime: ["yyyy-MM-dd HH:mm"]
  };
  const formatStr = dateFormat ? stringFormat[dateFormat][0] : "MM-dd-yyyy HH:mm";
  return format(value, formatStr);
};

export const getDateBasedOnTimeZone = (dateVal: number | Date, timeZone?: TimeZone) => {
  if (timeZone && timeZone !== BROWSER_TIME_ZONE) {
    const dateWithoutZone = moment.tz(dateVal, timeZone).format("YYYY-MM-DDTHH:mm:ss.SSS");
    const localZone = moment(dateWithoutZone).format("Z");
    const dateWithLocalZone = [dateWithoutZone, localZone].join("");
    return new Date(dateWithLocalZone);
  } else {
    return new Date(dateVal);
  }
};

/**
 * @description Utility method to format date and time based on given locale and format
 * @param value Date or timestamp in millis.
 * @param format Format for the date.
 * @param options Additional options for formatting
 * @returns Returns the formatted datetime string based on the locale and format.
 */
export const getFormattedDateTime = (
  value: number | Date,
  format: IncDateTimeFormat | IncHighchartsDateTimeFormat,
  options?: DateTimeOptions,
  timeZone?: TimeZone,
  hour12 = false
) => {
  const {
    dateTimeSeparator = " ",
    locale = navigator.language,
    withMilliSeconds = false,
    withSeconds = false,
    dateSeparator = "-",
    relative,
    skipTime
  } = options || {};

  const intl = createIntl({
    locale
  });

  const date = getDateBasedOnTimeZone(value, timeZone);

  // Currently we default time to be 24h format
  const [dateFormatOptions, timeFormatOptions] = getFormatOptions(format, hour12, withSeconds);

  let formattedDate = "";

  if (relative) {
    const valueDateTime = moment(date);
    const calendarValue = valueDateTime.calendar().split(" ")[0];
    formattedDate = ["today", "yesterday"].includes(calendarValue.toLocaleLowerCase()) ? calendarValue : "";
  }

  if (!formattedDate) {
    formattedDate = isEmpty(dateFormatOptions) ? "" : intl.formatDate(date, dateFormatOptions);
    formattedDate = formattedDate ? formattedDate.split("/").join(dateSeparator) : "";
  }

  let formattedTime = isEmpty(timeFormatOptions) || skipTime ? "" : intl.formatTime(date, timeFormatOptions);
  formattedTime = formattedTime
    ? adjustTime(date, formattedTime, timeFormatOptions.hour12 || false, withSeconds && withMilliSeconds)
    : "";

  if (timeZone && timeZone !== BROWSER_TIME_ZONE) {
    const zone = moment().tz(timeZone).format("z");
    const dateString = [formattedDate, formattedTime].filter(s => !isEmpty(s)).join(dateTimeSeparator);
    return `${dateString} ${zone}`;
  }
  return [formattedDate, formattedTime].filter(s => !isEmpty(s)).join(dateTimeSeparator);
};

/**
 * @description Utility method to get string format for specified IncDateTimeFormat
 * @param format Format for the date.
 * @param withSeconds Whether to show seconds in the time. Default is false, i.e without seconds.
 * @param locale Locale to be used for formatting the datetime.
 * @returns Returns the formatted datetime string based on the locale and format.
 */
export const getDateTimeStringFormat = (
  format: IncDateTimeFormat,
  options?: Pick<DateTimeOptions, "withSeconds" | "locale" | "i18nDisabled">
) => {
  const stringFormats: Record<IncDateTimeFormat, string[]> = {
    full: ["DDD, MMM dd, yyyy HH:mm"],
    minimal: ["MMM dd, yyyy HH:mm"],
    numeric: ["MM-dd-yyyy HH:mm", "dd-MM-yyyy HH:mm"],
    cohortNumericDate: ["yyyy-MM-dd"],
    cohortNumericDateTime: ["yyyy-MM-dd HH:mm"]
  } as any;

  let formatStr = "MM-dd-yyyy HH:mm";
  if (options?.i18nDisabled) {
    formatStr = stringFormats[format][0];
  } else {
    const formats = stringFormats[format];
    formats.forEach(f => {
      const ft = options?.withSeconds ? `${f}:ss` : f;

      const d = new Date().setSeconds(0, 0);
      const ds = getFormattedDateTime(d, format, options);
      const pd = parse(ds, ft, d).setSeconds(0, 0);
      if (pd.valueOf() === d.valueOf()) {
        formatStr = ft;
      }
    });
  }
  return formatStr;
};

export const getFormatOptions = (
  format: IncDateTimeFormat | IncHighchartsDateTimeFormat,
  hour12: boolean,
  withSeconds: boolean
): [IncDateFormatOptions, IncTimeFormatOptions] => {
  let formatOptions: IncDateTimeFormatOptions = {
    ...dateTimeFormats[format]
  };

  if (withSeconds) {
    formatOptions = {
      ...formatOptions,
      ...withSecondsOptions
    };
  }

  if (hour12) {
    formatOptions = {
      ...formatOptions,
      ...hour12Options
    };
  } else {
    formatOptions = {
      ...formatOptions,
      ...hour24Options
    };
  }

  const dateFormatOptions: IncDateFormatOptions = pick(formatOptions, ["dateStyle", "day", "month", "weekday", "year"]);
  const timeFormatOptions: IncTimeFormatOptions = pick(formatOptions, [
    "hour",
    "hourCycle",
    "minute",
    "second",
    "timeStyle",
    "hour12"
  ]);

  return [dateFormatOptions, timeFormatOptions];
};

/**
 * This adjustment is required due to a bug in chrome that adds 24 for 00 in hours for 24 hours clock and 00 for 12 in hours for 12 hours clock.
 * Refer to https://github.com/formatjs/formatjs/issues/1577
 */
export const adjustTime = (dateTime: Date, timeStr: string, hour12: boolean, addMillis: boolean): string => {
  const timeStrArr = timeStr.split(":");
  if (hour12) {
    timeStrArr[0] = timeStrArr[0] === "00" ? "12" : timeStrArr[0];
  } else {
    timeStrArr[0] = timeStrArr[0] === "24" ? "00" : timeStrArr[0];
  }

  // Add millis to seconds. We do not do this the Intl way since it adds millis as 11:20:30.452
  if (addMillis && timeStrArr.length === 3) {
    // Split the seconds part to make sure we don't append the millis after am/pm is 12 hours clock is used
    const [secs, unit = ""] = timeStrArr[2].split(" ");
    const millis = padEnd(dateTime.getMilliseconds().toString(), 3, "0");
    timeStrArr[2] = `${secs}:${millis} ${unit}`;
  }

  return timeStrArr.join(":").toUpperCase();
};

const getDurationString = (duration: number, format: TimeUnit) => {
  const durationString = duration >= 1 ? `${duration}${format} ` : "";

  return durationString;
};

/**
 * @returns duration string like '5h 16m 23s'.
 */
export const getDuration = (milliseconds: string | number) => {
  const duration = moment.duration(+milliseconds);

  const years = getDurationString(duration.years(), "y");
  const month = getDurationString(duration.months(), "M");
  const days = getDurationString(duration.days(), "d");
  const hour = getDurationString(duration.hours(), "h");
  const minutes = getDurationString(duration.minutes(), "m");
  const seconds = getDurationString(duration.seconds(), "s");

  return `${years}${month}${days}${hour}${minutes}${seconds}`.trim();
};

export const BROWSER_TIME_ZONE = "browser";
