import { gql } from "@apollo/client";
import { IncSelectOption } from "@inception/ui";
import { inRange } from "lodash";
import {
  Entity,
  Property,
  EntityFieldType,
  EntityQueryPredicate,
  EQPredicateOperation,
  TimeRangeMillis
} from "../core";
import { entityApiService } from "../services/api";
import { logger } from "../core/logging/Logger";
import {
  processAggregationsResponse,
  getAggregationRequestPayload,
  AUTO_DS_INTERVAL
} from "../dashboard/widgets/utils";
import { isErrorResponse } from "../services/api/utils";
import entityStoreApiService from "../services/api/EntityStoreApiService";
import {
  EntityAggregationResponse,
  EntityAggregationSuggestion,
  EntityAggregationValue,
  EntitySearchResultEntry
} from "../services/api/types";
import {
  BizFieldInfo,
  fieldPickerApiService,
  FieldPickerContext,
  UserServiceFieldWithMeta
} from "../services/api/explore";
import kbn from "../services/datasources/core/kbn";

export const NameEntityFieldLabel = "Name";
export const HighCardinalityEntityFieldNames = [NameEntityFieldLabel, "Id"];
export const EventIdFieldName = "eventID";

let entityIdToDetailsMap: Map<string, Entity> = new Map();
let cachedTime: number;
export const MISSING = "__missing__";
export const ENTITY = "ENTITY";
export const LIST_ENTITY = "LIST_ENTITY";
export const LIST_STRING = "LIST_STRING";

const entityQuery = gql`
  query entityLookup($id: [String]!, $startTime: DateTime!, $endTime: DateTime!, $properties: [String]!) {
    entityLookup(ids: $id, timeRange: { st: $startTime, et: $endTime }) {
      id
      name
      properties(names: $properties) {
        name
        value
      }
      type
    }
  }
`;
const getDefaultTimeRange = () => {
  const endTime = Date.now();
  const startTime = endTime - 1000 * 60 * 60 * 10; // 1 hour less than current time

  return {
    startTime,
    endTime
  };
};
const defaultTimeRange = getDefaultTimeRange();
const constructEntityQuery = (
  entityIds: string[],
  startTime: number,
  endTime: number,
  properties: string[] = ["*"]
) => {
  const from: string = new Date(startTime).toISOString();
  const to: string = new Date(endTime).toISOString();
  entityIds = filterMissingEntityIds(entityIds);

  return {
    query: entityQuery,
    variables: {
      id: entityIds,
      startTime: from,
      endTime: to,
      properties
    }
  };
};

const clearCache = () => {
  const currentTime = Date.now();
  const elapsedTime = 1000 * 60 * 5; // 5 min;

  if (Math.abs(currentTime - cachedTime) >= elapsedTime) {
    entityIdToDetailsMap = new Map();
  }
};

export const fetchEntityPropertiesForIds = async (
  entityIds: Set<string>,
  startTime: number,
  endTime: number,
  properties?: string[]
): Promise<Map<string, Entity>> => {
  const query = constructEntityQuery(Array.from(entityIds), startTime, endTime, properties);
  const result: any = await entityApiService.fetchData(query);

  if (result && result.data && result.data.entityLookup) {
    const entityRes: any[] = result.data.entityLookup;
    cachedTime = Date.now();
    entityRes.forEach(res => {
      const { id, name, properties: eProperties = [], type } = res;

      const properties: Property[] = eProperties.map((p: any) => ({
        name: p.name,
        value: p.value
      }));

      entityIdToDetailsMap.set(id, {
        id,
        name,
        properties,
        type
      });
    });
  }
  return entityIdToDetailsMap;
};

/**
 * Use this API when list of entityIds is expected to be > 300.
 * Divides the list into 6 segments of equal size and makes parallel request for lookup.
 * Wont make parallel request for small list of ids
 * @param entityIds
 * @param startTime
 * @param endTime
 * @param properties
 */
export const fetchEntityPropertiesForLargeListOfIds = (
  entityIds: Set<string>,
  startTime: number,
  endTime: number,
  properties?: string[]
): Promise<Map<string, Entity>> => {
  const entityList = Array.from(entityIds);
  const curSize = entityIds.size;

  const THRESHOLD = 300;
  const SET_SIZE = curSize > THRESHOLD ? curSize / 6 : curSize;

  const entitySets: Array<Set<string>> = [];
  for (let i = 0, j = entityList.length; i < j; i += SET_SIZE) {
    const tempSet = new Set(entityList.slice(i, i + SET_SIZE));
    entitySets.push(tempSet);
  }
  const promiseList = entitySets.map(set => fetchEntityPropertiesForIds(set, startTime, endTime, properties));
  return Promise.all(promiseList).then(maps => {
    let concatenatedMap: Map<string, Entity> = new Map();
    maps.forEach(map => (concatenatedMap = new Map([...concatenatedMap].concat([...map]))));
    return concatenatedMap;
  });
};

export const fetchEntityPropertiesForId = async (entityId: string) => {
  clearCache();
  if (entityIdToDetailsMap.has(entityId)) {
    return entityIdToDetailsMap.get(entityId);
  }
  const entityPropertiesMap = await fetchEntityPropertiesForIds(new Set([entityId]), 0, defaultTimeRange.endTime);
  return entityPropertiesMap.get(entityId);
};

export const filterMissingEntityIds = (ids: string[]) => ids.filter(id => id !== MISSING);

export const EntityAccessor = {
  getPropertyValue: function (e: Entity, propertyKey: string, propertyType: string, key?: string) {
    const properties = e.properties || [];

    const propertyPayload = properties.find(p => p.name === propertyKey);

    if (propertyPayload) {
      if (propertyType === "MAP_VALUE") {
        const mapValue: any[] = propertyPayload.mapValue || [];
        const mapKeyVal = mapValue.find(mapVal => mapVal.key === key);
        return mapKeyVal.value;
      } else {
        return propertyPayload.value;
      }
    }
    return undefined;
  }
};

export const getBizFieldsWithSuggestedAggregations = async (
  cohortId: string,
  entityTypeId: string,
  startMillis: number,
  endMillis: number
) => {
  const fieldPickerContext: FieldPickerContext = {
    bizEntityType: entityTypeId,
    cohort: cohortId,
    showFields: true
  };

  try {
    const { bizFields: eBizFields, userServiceFields } = await fieldPickerApiService.getBizEntityFields(
      entityTypeId,
      fieldPickerContext,
      startMillis,
      endMillis
    );
    const bizFields = eBizFields.filter(bfInfo => !bfInfo.bizField?.entityField?.relNames?.length);

    let fieldNames = bizFields.map(x => x.bizField.entityField.propName);
    fieldNames = fieldNames.filter(x => !HighCardinalityEntityFieldNames.includes(x));

    const suggestionsCallback = entityStoreApiService.suggestEntityAggregation(
      startMillis,
      endMillis,
      entityTypeId,
      cohortId,
      fieldNames,
      false
    );

    const { data: aggResponse, status, statusText } = await suggestionsCallback;

    const suggAggMap = aggResponse.suggestedAggregations || {};
    const isError = isErrorResponse(status);
    const error = isError ? statusText : "";
    const aggregationsMap: Record<string, EntityAggregationValue[]> = {};

    if (isError || !suggAggMap) {
      logger.error("Cohort variable suggestions", `Error fetching suggestions`, error);
      return {
        bizFields: bizFields,
        suggAggregationsMap: {}
      };
    }

    if (suggAggMap) {
      const promises = bizFields.map(async field => {
        const { propName } = field.bizField.entityField;
        const suggAggregationResult = suggAggMap[propName];
        const [aggRequest, isValid] = getAggregationRequestPayload(suggAggregationResult);
        if (isValid) {
          const {
            data: aggData,
            status,
            statusText
          } = await entityStoreApiService.getEntityAggregation(startMillis, endMillis, entityTypeId, cohortId, [
            aggRequest
          ]);

          const isError = isErrorResponse(status);
          if (isError) {
            logger.error("aggregate suggestions ", "Error fetching aggregation suggestions", statusText);
          } else {
            const { aggValues } = processAggregationsResponse(aggData, null, propName);
            aggregationsMap[propName] = aggValues;
          }
          return null;
        }
        aggregationsMap[propName] = [];
        return null;
      }, []);

      await Promise.allSettled(promises);
    }

    return {
      bizFields,
      userServiceFields,
      suggAggregationsMap: suggAggMap,
      aggregationsMap
    };
  } catch (error) {
    logger.error("Entity Utils", "Error fetching business fields", error);
    return {
      bizFields: [],
      userServiceFields: [],
      suggAggregationsMap: {},
      aggregationsMap: {}
    };
  }
};

export type BizFieldsWithAggregations = {
  bizFields: BizFieldInfo[];
  userServiceFields: UserServiceFieldWithMeta[];
  suggAggregationsMap: Record<string, EntityAggregationSuggestion>;
  aggregations: EntityAggregationResponse["aggregations"];
};

export const getBizFieldsWithAggregations = async (
  cohortId: string,
  entityTypeId: string,
  startMillis: number,
  endMillis: number
): Promise<BizFieldsWithAggregations> => {
  // const fieldPickerContext: FieldPickerContext = {
  //   bizEntityType: entityTypeId,
  //   cohort: cohortId,
  //   showFields: true,
  // };

  try {
    const { bizFields: eBizFields, userServiceFields } = await fieldPickerApiService.getBizEntityFilterFields(
      entityTypeId,
      startMillis,
      endMillis
    );
    const bizFields = eBizFields.filter(bfInfo => !bfInfo.bizField?.entityField?.relNames?.length);

    let fieldNames = bizFields.map(x => x.bizField.entityField.propName);
    fieldNames = fieldNames.filter(x => !HighCardinalityEntityFieldNames.includes(x));

    const {
      data: aggResponse,
      status,
      statusText
    } = await entityStoreApiService.getFieldValues(startMillis, endMillis, entityTypeId, cohortId, fieldNames);

    const { aggregations, suggestedAggregations } = aggResponse || {};

    const isError = isErrorResponse(status);
    const error = isError ? statusText : "";

    if (isError) {
      logger.error("Cohort variable suggestions", `Error fetching suggestions`, error);
      return {
        bizFields,
        suggAggregationsMap: {} as any,
        aggregations,
        userServiceFields: []
      };
    }

    return {
      userServiceFields,
      bizFields,
      suggAggregationsMap: suggestedAggregations,
      aggregations
    };
  } catch (e) {
    logger.error("Entity  Utils", "Error fetching business fields", e);
    return {
      bizFields: [] as any,
      userServiceFields: [] as any,
      suggAggregationsMap: {} as any,
      aggregations: {} as any
    };
  }
};

export type SearchEntityResult = Array<IncSelectOption<EntitySearchResultEntry["entity"]>>;

export const searchEntities = (
  inputValue: string,
  propName: string,
  entityTypeId: string,
  startTime: number,
  endTime: number,
  cohortId: string,
  maxEntries: number,
  matchType: MatchType = "contains",
  fetchProps: string[] = [propName]
): Promise<SearchEntityResult> => {
  const regex = getRegexString(inputValue, matchType);

  const isNameProp = propName === "Name" || propName === "displayName";
  const searchPropNameKey = isNameProp ? "displayName" : propName;
  const type: EntityFieldType = isNameProp ? "displayName" : "prop";

  const predicates: EntityQueryPredicate[] = regex
    ? [
        {
          key: searchPropNameKey,
          op: EQPredicateOperation.regex,
          value: {
            stringVal: regex
          },
          type
        }
      ]
    : [];

  return entityStoreApiService
    .searchEntities(entityTypeId, startTime, endTime, cohortId, predicates, null, fetchProps, maxEntries)
    .then(data => {
      const options: SearchEntityResult = [];
      data.forEach(({ entity }) => {
        const { props } = entity;
        const value = props?.[propName]?.stringVal;

        if (value) {
          options.push({
            label: value,
            value,
            data: entity
          });
        }
      });

      const sortedOptions = sortEntityOptions(options);

      return sortedOptions;
    });
};

export const searchEntities2 = (
  entityTypeId: string,
  searchText: string,
  startTime: number,
  endTime: number,
  cohortId: string
) =>
  entityStoreApiService.searchEntitiesV2(entityTypeId, searchText, startTime, endTime, cohortId).then(data => {
    const options: SearchEntityResult = [];
    data.forEach(({ entity }) => {
      const { props } = entity;
      const value = props?.["Name"]?.stringVal;

      if (value) {
        options.push({
          label: value,
          value,
          data: entity
        });
      }
    });

    const sortedOptions = sortEntityOptions(options);

    return sortedOptions;
  });

export const sortEntityOptions = (options: IncSelectOption[]) => {
  const sortedOptions = options.sort((optA, optB) => {
    const { label: labelA } = optA;
    const { label: labelB } = optB;

    const lowerCaseLabelA = labelA.toLocaleLowerCase();
    const lowerCaseLabelB = labelB.toLocaleLowerCase();

    return lowerCaseLabelA.localeCompare(lowerCaseLabelB);
  });

  return sortedOptions;
};

const getRegexString = (value: string, matchType: MatchType = "contains"): string => {
  let regexStr = "";

  if (value) {
    const stringItems = value.split("");

    stringItems.forEach(alpha => {
      const ascii = alpha.charCodeAt(0);
      // Range of alphabets in ASCII
      const isAlphabet = inRange(ascii, 65, 91) || inRange(ascii, 97, 123);

      if (isAlphabet) {
        const lower = alpha.toLowerCase();
        const upper = alpha.toUpperCase();
        regexStr += `[${lower}${upper}]`;
      } else {
        regexStr += alpha;
      }
    });

    regexStr = matchType === "contains" ? `.*${regexStr}.*` : `${regexStr}.*`;
  }

  return regexStr;
};

export const getDownSampleInterval = (
  dsIntervalStr: string,
  dashboardTimeRange: TimeRangeMillis,
  maxNumDataPoints: number = null
) => {
  let { interval } = kbn.calculateInterval(dashboardTimeRange, maxNumDataPoints, "60s");

  if (dsIntervalStr && dsIntervalStr !== AUTO_DS_INTERVAL) {
    try {
      kbn.describe_interval(dsIntervalStr);
      interval = dsIntervalStr;
    } catch (err) {
      logger.error("BizEntityDataQuery", "Error parsing interval", err);
    }
  }

  return interval;
};

type MatchType = "startsWith" | "contains";
