import { IncSelectOption } from "@inception/ui";
import { startCase } from "lodash";
import { MetricAggregatorExpanded, FieldSubType, DataType, DataTypeAggregationManager } from "../../core";
import { FieldConfig } from "../../services/api/explore";
import { eventFieldUtils, dataTypeManager } from "../../utils";
import { USFieldWidgetUtils } from "../../dashboard/widgets/USField/USFieldWidgetUtils";

export const getFieldOrMetricNameOptions = (fieldConfig: FieldConfig, skipEventOpt = false) => {
  const usFieldName = eventFieldUtils.removeFieldsPrefix(fieldConfig?.userServiceField?.fieldName || "");
  const bizFieldName = fieldConfig?.bizField?.entityField?.propName;
  const fieldName = usFieldName || bizFieldName || "";

  const aggOptions = getAggOptions(fieldConfig);
  const options: Array<IncSelectOption<MetricAggregatorExpanded>> = aggOptions.map(({ value, longDisplayName }) => {
    const displayFieldName = getDisplayFieldName(fieldName);
    const label =
      value === "count"
        ? `${startCase(displayFieldName)} count`
        : value === "distinctCount"
          ? `Distinct ${fieldName} count`
          : `${longDisplayName} ${fieldName}`;

    return {
      value,
      data: value,
      label
    };
  });

  if (!skipEventOpt) {
    options.push({
      label: `Any ${fieldName}`,
      value: "",
      data: "" as any
    });
  }

  return options;
};

export const entityAggregatedOptions: IncSelectOption[] = [
  {
    label: "individually for each",
    value: "false"
  },
  {
    label: "aggregated across",
    value: "true"
  }
];

const getAggOptions = (fieldConfig: FieldConfig) => {
  let subType: FieldSubType = "not_set";
  let dataType: DataType = null;

  const { bizField, userServiceField } = fieldConfig;
  if (userServiceField) {
    const { dataType: dType, entityField } = userServiceField;
    dataType = dType;
    subType = entityField?.kindDescriptor?.type || subType;
  } else if (bizField) {
    const { propType, kindDescriptor } = bizField.entityField;
    dataType = propType as DataType;
    subType = kindDescriptor?.type || subType;
  }

  const [, uiDataType] = dataTypeManager.getDataTypeWithNameFromKind(dataType, subType);
  const aggResponse = DataTypeAggregationManager.getAggregationsByUIDataType(uiDataType);
  return aggResponse.aggOpts;
};

const getDisplayFieldName = (fieldName: string) => {
  switch (fieldName) {
    case USFieldWidgetUtils.durationFieldName:
      return "latency";

    case USFieldWidgetUtils.eventIDFieldName:
      return "requests";

    case USFieldWidgetUtils.hasErrorFieldName:
      return "errors";

    default:
      return fieldName;
  }
};
