import { DataType, Visualisations } from "../data";
import { VisualisationConfig, QueryType, VizToQueryConfig, VizQueryOptions, BicycleOOTBMetrics } from "./types";
import { DataTypeManagerUtils } from "./ManagerUtils";

export class DataTypeVisualisationManager extends DataTypeManagerUtils {
  static getVisualisationsByUIDataType(uiDataType: DataType, cardinality: number): VisualisationConfig {
    switch (uiDataType) {
      // Boolean data type
      case "BOOLEAN": {
        const basic = [
          this.getVisualisationEntry(Visualisations.gauge, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
          this.getVisualisationEntry(Visualisations.donut, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
          this.getVisualisationEntry(
            Visualisations.pieChart,
            QueryType.aggregatedTimeseries,
            this.includeSliceTagOption
          ),
          this.getVisualisationEntry(
            Visualisations.treeMap,
            QueryType.aggregatedTimeseries,
            this.includeSliceTagOption
          ),
          this.getVisualisationEntry(
            Visualisations.barChart,
            QueryType.aggregatedTimeseries,
            this.includeSliceTagOption
          ),
          this.getVisualisationEntry(Visualisations.table, QueryType.aggregatedTimeseries, this.includeSliceTagOption)
        ];

        const timeseries = [
          this.getVisualisationEntry(
            Visualisations.timeseries,
            QueryType.aggregatedTimeseriesOverTag,
            this.includeSliceTagOption
          ), // Temporary
          this.getVisualisationEntry(Visualisations.sparkLine, QueryType.aggregatedTimeseriesOverTag)
        ];

        return this.getVisualisationConfig(basic, timeseries);
      }

      // Entity based data types
      case "ENTITY":
      case "LIST_ENTITY": {
        return this.getDefaultVisualisationByCardinality(cardinality);
      }

      // Numeric based data types
      case "LONG":
      case "LIST_LONG":
      case "DOUBLE":
      case "LIST_DOUBLE":
      case "NUMBER": {
        return this.getDefaultVisualisationByCardinality(cardinality, true);
      }

      case "CURRENCY": {
        const basic = [
          this.getVisualisationEntry(
            Visualisations.timeseries,
            QueryType.aggregatedTimeseriesOverTag,
            this.includeSliceTagOption
          ), // Temporary
          this.getVisualisationEntry(Visualisations.gauge, QueryType.aggregatedTimeseries), // Temporary
          this.getVisualisationEntry(
            Visualisations.insights,
            QueryType.aggregatedTimeseries,
            this.includeSliceTagOption
          ),
          this.getVisualisationEntry(Visualisations.singleStat, QueryType.aggregatedTimeseries),
          this.getVisualisationEntry(Visualisations.sparkLine, QueryType.aggregatedTimeseriesOverTag)
        ];

        const timeseries = [
          this.getVisualisationEntry(Visualisations.timeseries, QueryType.aggregatedTimeseriesOverTag)
        ];

        return this.getVisualisationConfig(basic, timeseries);
      }

      case "DURATION":
      case "DURATION_MILLIS":
      case "DURATION_SECS": {
        const basic = [
          this.getVisualisationEntry(Visualisations.timeseries, QueryType.aggregatedTimeseriesOverTag), // Temporary
          this.getVisualisationEntry(
            Visualisations.insights,
            QueryType.aggregatedTimeseries,
            this.includeSliceTagOption
          ),
          this.getVisualisationEntry(Visualisations.singleStat, QueryType.aggregatedTimeseries),
          this.getVisualisationEntry(Visualisations.sparkLine, QueryType.aggregatedTimeseriesOverTag),
          this.getVisualisationEntry(Visualisations.table, QueryType.aggregatedTimeseries, this.includeSliceTagOption)
        ];
        const timeseries = [
          this.getVisualisationEntry(Visualisations.gauge, QueryType.aggregatedTimeseries) // Temporary
        ];

        return this.getVisualisationConfig(basic, timeseries);
      }

      case "EPOCH":
      case "EPOCH_MILLIS":
      case "EPOCH_SECS": {
        const basic = [
          this.getVisualisationEntry(Visualisations.timeseries, QueryType.aggregatedTimeseriesOverTag), // Temporary
          this.getVisualisationEntry(Visualisations.table, QueryType.aggregatedTimeseries, this.includeSliceTagOption)
        ];
        const timeseries = [
          this.getVisualisationEntry(Visualisations.gauge, QueryType.aggregatedTimeseries), // Temporary
          this.getVisualisationEntry(Visualisations.singleStat, QueryType.aggregatedTimeseries),
          this.getVisualisationEntry(Visualisations.sparkLine, QueryType.aggregatedTimeseriesOverTag)
        ];

        return this.getVisualisationConfig(basic, timeseries);
      }

      // Entity and string based data types
      case "GEOLOCATION":
      case "ZIP_CODE": {
        const cardinalityType = this.getCardinalityCategory(cardinality);
        switch (cardinalityType) {
          case "very-low":
            return this.getVeryLowGeoCardinalityViz();
          case "low":
            return this.getLowGeoCardinalityViz();
          case "high":
            return this.getHighGeoCardinalityViz();
          case "very-high":
            return this.getVeryHighGeoCardinalityViz();
          default:
            return this.getDefaultVisualisationByCardinality(cardinality);
        }
      }

      // String based data types
      case "STRING":
      case "LIST_STRING":
      case "URL":
      case "IP_ADDRESS":
      case "ADDRESS":
      case "TELEPHONE":
      case "EMAIL": {
        return this.getDefaultVisualisationByCardinality(cardinality);
      }

      case "DATE":
      case "DATETIME": {
        const basic: VisualisationConfig["basic"] = [
          this.getVisualisationEntry(Visualisations.timeseries, QueryType.aggregatedTimeseriesOverTag), // Temporary
          this.getVisualisationEntry(Visualisations.table, QueryType.aggregatedTimeseries, this.includeSliceTagOption)
        ];

        const timeseries: VisualisationConfig["timeseries"] = [
          this.getVisualisationEntry(Visualisations.gauge, QueryType.aggregatedTimeseries) // Temporary
        ];

        return this.getVisualisationConfig(basic, timeseries);
      }

      default: {
        return this.getDefaultVisualisationByCardinality(cardinality);
      }
    }
  }

  static getVisualisationsForOOTBMetrics(ootbMetric: BicycleOOTBMetrics) {
    const options = ootbMetric === BicycleOOTBMetrics.errors ? this.includeSliceTagOption : null;

    const basic = [
      this.getVisualisationEntry(Visualisations.timeseries, QueryType.aggregatedTimeseriesOverTag),
      this.getVisualisationEntry(Visualisations.gauge, QueryType.aggregatedTimeseries, options)
    ];

    const timeseries: VizToQueryConfig[] = [
      this.getVisualisationEntry(Visualisations.donut, QueryType.aggregatedTimeseries, options),
      this.getVisualisationEntry(Visualisations.pieChart, QueryType.aggregatedTimeseries, options),
      this.getVisualisationEntry(Visualisations.barChart, QueryType.aggregatedTimeseries, options),
      this.getVisualisationEntry(Visualisations.treeMap, QueryType.aggregatedTimeseries, options),
      this.getVisualisationEntry(Visualisations.insights, QueryType.aggregatedTimeseries, options),
      this.getVisualisationEntry(Visualisations.singleStat, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.sparkLine, QueryType.aggregatedTimeseriesOverTag),
      this.getVisualisationEntry(Visualisations.table, QueryType.aggregatedTimeseries, options)
    ];

    return this.getVisualisationConfig(basic, timeseries);
  }

  static getAllVisualisations() {
    const basic = [
      this.getVisualisationEntry(Visualisations.donut, QueryType.aggregatedTimeseries, this.includeEntityTagOption),
      this.getVisualisationEntry(Visualisations.pieChart, QueryType.aggregatedTimeseries, this.includeEntityTagOption),
      this.getVisualisationEntry(Visualisations.barChart, QueryType.aggregatedTimeseries, this.includeEntityTagOption),
      this.getVisualisationEntry(Visualisations.treeMap, QueryType.aggregatedTimeseries, this.includeEntityTagOption),
      this.getVisualisationEntry(Visualisations.insights, QueryType.aggregatedTimeseries, this.includeEntityTagOption),
      this.getVisualisationEntry(Visualisations.singleStat, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.table, QueryType.aggregatedTimeseries, this.includeSliceTagOption)
    ];

    const timeseries = [
      this.getVisualisationEntry(Visualisations.singleStat, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.gauge, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.timeseries, QueryType.aggregatedTimeseriesOverTag),
      this.getVisualisationEntry(Visualisations.sparkLine, QueryType.aggregatedTimeseriesOverTag)
    ];

    return this.getVisualisationConfig(basic, timeseries);
  }

  private static getVeryLowCardinalityViz(isNumericType: boolean): VisualisationConfig {
    const insightsVizOptions = isNumericType ? this.includeEntityTagOption : this.includeSliceTagOption;
    const basic = [
      this.getVisualisationEntry(Visualisations.barChart, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.donut, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.pieChart, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.gauge, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.insights, QueryType.aggregatedTimeseries, insightsVizOptions),
      this.getVisualisationEntry(Visualisations.treeMap, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.singleStat, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.sparkLine, QueryType.aggregatedTimeseriesOverTag),
      this.getVisualisationEntry(Visualisations.table, QueryType.aggregatedTimeseries, this.includeSliceTagOption)
    ];

    const timeseries = [
      this.getVisualisationEntry(
        Visualisations.timeseries,
        QueryType.aggregatedTimeseriesOverTag,
        this.includeSliceTagOption
      ) // Temporary
    ];

    return this.getVisualisationConfig(basic, timeseries);
  }

  private static getLowCardinalityViz(isNumericType: boolean): VisualisationConfig {
    const insightsVizOptions = isNumericType ? this.includeEntityTagOption : this.includeSliceTagOption;
    const basic = [
      this.getVisualisationEntry(Visualisations.insights, QueryType.aggregatedTimeseries, insightsVizOptions),
      this.getVisualisationEntry(Visualisations.barChart, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.treeMap, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.donut, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.pieChart, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.gauge, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.singleStat, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(
        Visualisations.treeMap,
        QueryType.aggregatedTimeseriesOverTag,
        this.includeSliceTagOption
      ),
      this.getVisualisationEntry(Visualisations.table, QueryType.aggregatedTimeseries, this.includeSliceTagOption)
    ];

    const timeseries = [
      this.getVisualisationEntry(
        Visualisations.timeseries,
        QueryType.aggregatedTimeseriesOverTag,
        this.includeSliceTagOption
      ) // Temporary
    ];

    return this.getVisualisationConfig(basic, timeseries);
  }

  private static getHighCardinalityViz(isNumericType: boolean): VisualisationConfig {
    const insightsVizOptions = isNumericType ? this.includeEntityTagOption : this.includeSliceTagOption;
    const basic = [
      this.getVisualisationEntry(Visualisations.insights, QueryType.aggregatedTimeseries, insightsVizOptions),
      this.getVisualisationEntry(Visualisations.barChart, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.treeMap, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.donut, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.pieChart, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.gauge, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.singleStat, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.sparkLine, QueryType.aggregatedTimeseriesOverTag),
      this.getVisualisationEntry(Visualisations.table, QueryType.aggregatedTimeseries, this.includeSliceTagOption)
    ];

    const timeseries = [
      this.getVisualisationEntry(
        Visualisations.timeseries,
        QueryType.aggregatedTimeseriesOverTag,
        this.includeSliceTagOption
      )
    ];

    return this.getVisualisationConfig(basic, timeseries);
  }

  private static getVeryHighCardinalityViz(isNumericType: boolean): VisualisationConfig {
    const insightsVizOptions = isNumericType ? this.includeEntityTagOption : this.includeSliceTagOption;
    const basic = [
      this.getVisualisationEntry(Visualisations.barChart, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.treeMap, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.donut, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.pieChart, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.insights, QueryType.aggregatedTimeseries, insightsVizOptions),
      this.getVisualisationEntry(Visualisations.gauge, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.singleStat, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.sparkLine, QueryType.aggregatedTimeseriesOverTag),
      this.getVisualisationEntry(Visualisations.table, QueryType.aggregatedTimeseries, this.includeSliceTagOption)
    ];

    const timeseries = [
      this.getVisualisationEntry(
        Visualisations.timeseries,
        QueryType.aggregatedTimeseriesOverTag,
        this.includeSliceTagOption
      )
    ];

    return this.getVisualisationConfig(basic, timeseries);
  }

  private static getVeryLowGeoCardinalityViz(): VisualisationConfig {
    const basic = [
      this.getVisualisationEntry(Visualisations.geoMap, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.donut, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.barChart, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.treeMap, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.pieChart, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.gauge, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.singleStat, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.sparkLine, QueryType.aggregatedTimeseriesOverTag)
    ];

    const timeseries = [
      this.getVisualisationEntry(
        Visualisations.timeseries,
        QueryType.aggregatedTimeseriesOverTag,
        this.includeSliceTagOption
      ) // Temporary
    ];

    return this.getVisualisationConfig(basic, timeseries);
  }

  private static getLowGeoCardinalityViz(): VisualisationConfig {
    const basic = [
      this.getVisualisationEntry(Visualisations.geoMap, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.treeMap, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.donut, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.barChart, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.pieChart, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.gauge, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.singleStat, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.sparkLine, QueryType.aggregatedTimeseriesOverTag)
    ];

    const timeseries = [
      this.getVisualisationEntry(
        Visualisations.timeseries,
        QueryType.aggregatedTimeseriesOverTag,
        this.includeSliceTagOption
      )
    ];

    return this.getVisualisationConfig(basic, timeseries);
  }

  private static getHighGeoCardinalityViz(): VisualisationConfig {
    const basic = [
      this.getVisualisationEntry(Visualisations.geoMap, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.treeMap, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.donut, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.barChart, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.pieChart, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.gauge, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.sparkLine, QueryType.aggregatedTimeseriesOverTag)
    ];

    const timeseries = [
      this.getVisualisationEntry(
        Visualisations.timeseries,
        QueryType.aggregatedTimeseriesOverTag,
        this.includeSliceTagOption
      )
    ];

    return this.getVisualisationConfig(basic, timeseries);
  }

  private static getVeryHighGeoCardinalityViz(): VisualisationConfig {
    const basic = [
      this.getVisualisationEntry(Visualisations.geoMap, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.treeMap, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.donut, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.barChart, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.pieChart, QueryType.aggregatedTimeseries, this.includeSliceTagOption),
      this.getVisualisationEntry(Visualisations.gauge, QueryType.aggregatedTimeseries),
      this.getVisualisationEntry(Visualisations.sparkLine, QueryType.aggregatedTimeseriesOverTag)
    ];

    const timeseries = [
      this.getVisualisationEntry(
        Visualisations.timeseries,
        QueryType.aggregatedTimeseriesOverTag,
        this.includeSliceTagOption
      )
    ];

    return this.getVisualisationConfig(basic, timeseries);
  }

  private static getDefaultVisualisationByCardinality(cardinality: number, isNumericType = false): VisualisationConfig {
    const cardinalityType = this.getCardinalityCategory(cardinality);
    switch (cardinalityType) {
      case "very-low":
        return this.getVeryLowCardinalityViz(isNumericType);
      case "low":
        return this.getLowCardinalityViz(isNumericType);
      case "high":
        return this.getHighCardinalityViz(isNumericType);
      case "very-high":
        return this.getVeryHighCardinalityViz(isNumericType);
      default:
        return this.getLowCardinalityViz(isNumericType);
    }
  }

  private static getVisualisationEntry(
    visualisation: Visualisations,
    queryType: QueryType,
    options?: VizQueryOptions
  ): VizToQueryConfig {
    return {
      queryType,
      visualisation,
      options
    };
  }

  private static getVisualisationConfig(
    basic: VizToQueryConfig[],
    timeseries: VizToQueryConfig[]
  ): VisualisationConfig {
    return {
      basic,
      timeseries
    };
  }

  private static includeSliceTagOption: VizQueryOptions = {
    aggregateBySliceTag: true
  };

  private static includeEntityTagOption: VizQueryOptions = {
    aggregateByEntityTag: true
  };
}
