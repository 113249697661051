import { isEmpty, round } from "lodash";
import {
  Currency,
  CurrencyConfiguration,
  CurrencyType,
  checkIsNaN,
  getNumericDataConfiguration,
  getSymbolForCurrency,
  isCurrencyTypeValid
} from "../datatypes";

const compactNumberFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  notation: "compact",
  compactDisplay: "short",
  roundingMode: "trunc"
} as any);

const compactNumberFormatterWithoutFraction = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  notation: "compact",
  compactDisplay: "short"
} as any);

const getPercentFormatter = (decimalPoints = 0) =>
  new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalPoints,
    style: "percent",
    notation: "compact",
    signDisplay: "never"
  } as any);

const displayNumberFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
} as any);

export const formatNumber = (num: number, fractionLess = false, skipRoundOffLimit = false): string => {
  const sign = Math.sign(num);
  const unsignedInt = sign === -1 ? -1 * num : num;

  if (unsignedInt === 0) {
    return String(unsignedInt);
  }

  if (!skipRoundOffLimit && unsignedInt < 1000000) {
    return String(round(num, 2));
  }

  return fractionLess ? compactNumberFormatterWithoutFraction.format(num) : compactNumberFormatter.format(num);
};

export const formatPercent = (num1: number, num2 = 100, decimalPoints?: number): string =>
  getPercentFormatter(decimalPoints).format(num1 / num2);

export const formatDisplayNumber = (num: number, compact = false): string => {
  const max = 99999;
  return num > max || compact ? compactNumberFormatter.format(num) : displayNumberFormatter.format(num);
};

export const isFloat = (n: number) => Number(n) === n && n % 1 !== 0;

const currencyTypeVsLocale: Partial<Record<CurrencyType, string>> = {
  INR: "en-IN",
  USD: "en-US"
};

export const getCurrencyFormatter = (currencyType: CurrencyType, compact = false, locale?: string) => {
  const numberFormatOptions: Intl.NumberFormatOptions = {
    style: "currency",
    currency: currencyType,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  };

  if (!isCurrencyTypeValid(currencyType)) {
    delete numberFormatOptions.currency;
    delete numberFormatOptions.style;
  }

  if (compact) {
    (numberFormatOptions as any).notation = "compact";
    (numberFormatOptions as any).compactDisplay = "short";
  }

  locale = locale || currencyTypeVsLocale[currencyType] || "en-US";
  return new Intl.NumberFormat(locale, numberFormatOptions);
};

export const getCurrencyInfo = (
  currencyValue: string,
  currencyType: CurrencyType,
  disableAbbreviations: boolean,
  withSymbol = false
): Currency => {
  const currency: Currency = {
    isValid: false,
    abbreviatedValue: "",
    absoluteValue: ""
  };

  if (isEmpty(currency)) {
    return currency;
  }

  if (currencyValue === "No Min" || currencyValue === "No Max") {
    currency.isValid = true;
    currency.abbreviatedValue = currencyValue;
    return currency;
  }

  const currencyConfig = getCurrencyConfig(currencyType);
  //check if currency value is valid
  if (currencyConfig.regex.test(currencyValue)) {
    currency.isValid = true;
    currency.abbreviatedValue = currencyValue;

    //compute absolute value
    currency.absoluteValue = !checkIsNaN(currencyValue)
      ? Number(currencyValue).toString()
      : currencyConfig.convertToNumber(currencyValue).toString();

    //compute abbreviated value
    if (!disableAbbreviations && !checkIsNaN(currencyValue)) {
      if (withSymbol) {
        currency.abbreviatedValue = currencyConfig.formatter.format(parseInt(currencyValue, 10));
      } else {
        const parts = currencyConfig.formatter.formatToParts(parseInt(currencyValue, 10));
        //Removing currency symbol as UI already shows it
        const abbvValue = parts
          .filter(p => p.type !== "currency")
          .map(p => p.value)
          .join("");
        currency.abbreviatedValue = abbvValue;
      }
    }
  }
  return currency;
};

const currencyTypeVsConfig: Partial<Record<CurrencyType, CurrencyConfiguration>> = {
  USD: {
    ...getNumericDataConfiguration("en-US"),
    symbol: getSymbolForCurrency("USD"),
    regex: new RegExp(/^(\$*[0-9]*\.?[0-9]+)(M|m|B|b|K|k|T|t)?$/),
    formatter: getCurrencyFormatter("USD", true)
  },
  INR: {
    ...getNumericDataConfiguration("en-IN"),
    symbol: getSymbolForCurrency("INR"),
    quickValues: ["1L", "5L", "10L", "1Cr", "5Cr"],
    regex: new RegExp(/^(₹*[0-9]*\.?[0-9]+)(L|l|Cr|cr|T|t)?$/),
    formatter: getCurrencyFormatter("INR", true)
  }
};

export const getCurrencyConfig = (currency: CurrencyType): CurrencyConfiguration =>
  currencyTypeVsConfig[currency] || {
    ...getNumericDataConfiguration("en-GB"),
    symbol: getSymbolForCurrency(currency),
    regex: new RegExp(/^([0-9]*\.?[0-9]+)$/),
    formatter: getCurrencyFormatter(currency, true)
  };
