import React, { FC, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Transition } from "react-transition-group";
import { Modal } from "react-overlays";
import { CypressConstants } from "@bicycle/tests";
import { IncFaIcon } from "../../icons";
import IncButton from "../Button/Button";
import { IncModalProps } from "./types";

const FADE_DURATION = 100;
const fadeStyles = {
  entering: "side-pane",
  entered: "side-pane-open",
  exited: "side-pane-close",
  exiting: "side-pane-close"
};

const Fade = ({ children, ...props }: any) => (
  <Transition
    {...props}
    timeout={FADE_DURATION}
  >
    {(status: keyof typeof fadeStyles, innerProps: any) =>
      React.cloneElement(children, {
        ...innerProps,
        className: `${fadeStyles[status]} ${children.props.className}`
      })
    }
  </Transition>
);

const IncModal: FC<IncModalProps> = props => {
  const {
    children,
    show,
    showBackdrop = true,
    contentClassName: uContentClass = "",
    onClose: pOnClose = () => {},
    onHide = () => {},
    onShow = () => {},
    showClose = true,
    titleActions = <></>,
    titleClassName: uTitleClass = "",
    titleId = "",
    titleValues = {},
    titleText = "",
    actions,
    size = "md",
    withTitleBorder = false,
    withActionsBorder = false,
    className = "",
    closeOnBackdrop = true,
    disableFocusOnLoad = true,
    footerChildren,
    closeOnEscape = true,
    footerChildrenClassName = ""
  } = props;

  const contentClassName = `inc-modal--content ${!actions?.primary && !actions?.secondary ? "no-actions" : ""} ${uContentClass}`;
  const titleClassName = `inc-modal--title ${uTitleClass}`;

  const onClose = useCallback(
    (e?: React.MouseEvent) => {
      e?.stopPropagation();
      pOnClose();
    },
    [pOnClose]
  );

  const getTitleString = useCallback(() => {
    if (titleId) {
      return (
        <FormattedMessage
          id={titleId}
          values={titleValues}
        />
      );
    }
    return titleText;
  }, [titleId, titleText, titleValues]);

  const onBackdropClick = useCallback(() => (closeOnBackdrop ? onClose() : () => {}), [closeOnBackdrop, onClose]);
  const onEscapeKeyDown = useCallback(() => {
    if (closeOnEscape && onClose) {
      onClose();
    }
  }, [closeOnEscape, onClose]);

  const titleElement = useMemo(() => {
    const titleClass = `${titleClassName} ${withTitleBorder ? "with-border " : ""}`;
    return (
      <div className={titleClass}>
        <span className="inc-modal--title--text">{getTitleString()}</span>
        <span className="inc-modal--title--actions">{titleActions}</span>
        {showClose && (
          <span
            className="inc-modal--title--icon"
            data-cy={CypressConstants.application.attributes.modalCloseIcon}
            onClick={onClose}
          >
            <IncFaIcon iconName="remove" />
          </span>
        )}
      </div>
    );
  }, [getTitleString, onClose, showClose, titleActions, titleClassName, withTitleBorder]);

  const actionsElement = useMemo(() => {
    const actionsClass = `inc-modal--actions ${withActionsBorder ? "with-border " : ""} ${footerChildrenClassName}`;

    const {
      id: primaryActionId,
      label: primaryActionLabel,
      onClick: primaryOnClick,
      color: primaryBtnColor = "primary",
      disabled: primaryActionDisabled = false,
      showLoader: showPrimaryLoader = false,
      icon: primaryIcon
    } = actions?.primary || {};

    const {
      id: secondaryActionId,
      label: secondaryActionLabel,
      onClick: secondaryOnClick,
      color: secondaryBtnColor = "secondary",
      disabled: secondaryActionDisabled = false,
      showLoader: showSecondaryLoader = false,
      icon: secondaryIcon
    } = actions?.secondary || {};

    return (
      <div className={actionsClass}>
        {actions?.preJSX}

        {actions?.primary && (
          <IncButton
            color={primaryBtnColor}
            data-cy={CypressConstants.application.attributes.modalPrimaryAction}
            disabled={primaryActionDisabled}
            iconName={primaryIcon}
            onClick={primaryOnClick}
          >
            {showPrimaryLoader && (
              <IncFaIcon
                className="marginRt6"
                iconName="spinner"
                spin
              />
            )}
            {primaryActionId && <FormattedMessage id={primaryActionId} />}
            {!primaryActionId && Boolean(primaryActionLabel) && primaryActionLabel}
          </IncButton>
        )}

        {actions?.secondary && (
          <IncButton
            color={secondaryBtnColor}
            disabled={secondaryActionDisabled}
            iconName={secondaryIcon}
            onClick={secondaryOnClick}
          >
            {showSecondaryLoader && (
              <IncFaIcon
                className="marginRt6"
                iconName="spinner"
                spin
              />
            )}
            {secondaryActionId && <FormattedMessage id={secondaryActionId} />}
            {!secondaryActionId && Boolean(secondaryActionLabel) && secondaryActionLabel}
          </IncButton>
        )}

        {actions?.postJSX}

        {footerChildren}
      </div>
    );
  }, [actions, footerChildren, footerChildrenClassName, withActionsBorder]);

  const Backdrop = (props: any) => {
    const backdropClassName = `inc-modal-backdrop ${className}--backdrop`;

    return (
      <div
        {...props}
        className={backdropClassName}
      />
    );
  };

  const modalClassName = `inc-modal inc-modal--${size} ${className}`;

  return (
    <Modal
      backdrop={showBackdrop}
      className={modalClassName}
      enforceFocus={!disableFocusOnLoad}
      onBackdropClick={onBackdropClick}
      onEscapeKeyDown={onEscapeKeyDown}
      onHide={onHide}
      onShow={onShow}
      renderBackdrop={Backdrop}
      show={show}
      transition={size === "side-pane" ? Fade : undefined}
    >
      <>
        {titleElement}
        <div className={contentClassName}>{children}</div>
        {(actions || footerChildren) && actionsElement}
      </>
    </Modal>
  );
};

export default IncModal;
