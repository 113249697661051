/* eslint-disable @typescript-eslint/no-namespace */
import { ResponseInfo, UserServiceFieldSliceSet } from "../../explore";
import { UberOperationalizeTypes } from "./uber-operationalize";
import { OpSchedule, WhatsNewConfig } from "./CommonTypesV2";
import { Op10zeSetupResponse, Op10zeSetupRequest } from "./operationalise-api";
import { SuppressionConfigDef } from "./suppressions";
import { OpThreshold } from "./thresholds";
import { OpStartTrigger } from "./triggers";

export namespace UberOpertionalizeApiTypes {
  export interface UberOpSetupRequest {
    kpis: Record<string, UberOperationalizeTypes.OpBizDataQuery>;
    sliceSetsMap: Record<string, UserServiceFieldSliceSet>;
    schedule: OpSchedule;
    commonThreshold: OpThreshold;
    commonTrigger: OpStartTrigger;
    commonSuppression: SuppressionConfigDef[];
    commonWhatsNewConfig: WhatsNewConfig;
    overrides: Record<string, UberOperationalizeTypes.Override>;

    useCaseId: string;
  }

  export interface UberOpSetupResponse {
    jobId: string;
    /**
     * @description Map of KPIId vs setup status
     */
    kpiStatus: Record<string, UberOpSetupStatus>;
    responseInfo: ResponseInfo;
    statusCode: number;
    status: Status;
  }

  export enum Status {
    na = "na",
    in_progress = "in_progress",
    finished = "finished",
    failed = "failed"
  }

  export interface UberOpSetupStatus {
    status: Status;
    /**
     * @description Map of sliceSetId vs Op10zeSetupResponse
     */
    op10zeResponse: Record<string, Op10zeSetupResponse>;
    sliceSetStatus: Record<string, Status>;
  }
}

export namespace UberOperationalizePreviewTypes {
  export interface UberOpPreviewResponse {
    opPreviewConfig: UberOpPreviewConfig[];
  }

  export interface UberOpPreviewConfig {
    kpiId: string;
    sliceSetId: string;
    opSetupReq: Op10zeSetupRequest;
  }
}
