import React, { forwardRef, HTMLAttributes, memo } from "react";

export const ListIcon = memo(
  forwardRef<SVGSVGElement, HTMLAttributes<SVGElement>>((props, ref) => (
    <svg
      {...props}
      aria-hidden="true"
      className="SvgIcon-root list-icon"
      focusable="false"
      ref={ref}
      viewBox="0 0 24 24"
    >
      <path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z"></path>
    </svg>
  ))
);

export const AccountTree = memo(
  forwardRef<SVGSVGElement, HTMLAttributes<SVGElement>>((props, ref) => (
    <svg
      {...props}
      aria-hidden="true"
      className="SvgIcon-root account-tree-icon"
      focusable="false"
      ref={ref}
      viewBox="0 0 24 24"
    >
      <path d="M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3z"></path>
    </svg>
  ))
);

export const CodeIcon = memo(
  forwardRef<SVGSVGElement, HTMLAttributes<SVGElement>>((props, ref) => (
    <svg
      {...props}
      aria-hidden="true"
      className="SvgIcon-root code-icon"
      focusable="false"
      ref={ref}
      viewBox="0 0 24 24"
    >
      <path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"></path>
    </svg>
  ))
);

export const DashboardIcon = memo(
  forwardRef<SVGSVGElement, HTMLAttributes<SVGElement>>((props, ref) => (
    <svg
      {...props}
      aria-hidden="true"
      className="SvgIcon-root dashboard-icon"
      focusable="false"
      ref={ref}
      viewBox="0 0 24 24"
    >
      <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path>
    </svg>
  ))
);

export const SettingsIcon = memo(
  forwardRef<SVGSVGElement, HTMLAttributes<SVGElement>>((props, ref) => (
    <svg
      {...props}
      aria-hidden="true"
      className="SvgIcon-root settings-icon"
      focusable="false"
      ref={ref}
      viewBox="0 0 24 24"
    >
      <path
        d="M15.95 10.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34c-.03-.2-.2-.34-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"
        transform="scale(1.2, 1.2)"
      ></path>
    </svg>
  ))
);

export const DataUsageIcon = memo(
  forwardRef<SVGSVGElement, HTMLAttributes<SVGElement>>((props, ref) => (
    <svg
      {...props}
      aria-hidden="true"
      className="SvgIcon-root data-usage-icon"
      focusable="false"
      ref={ref}
      viewBox="0 0 24 24"
    >
      <path d="M13 2.05v3.03c3.39.49 6 3.39 6 6.92 0 .9-.18 1.75-.48 2.54l2.6 1.53c.56-1.24.88-2.62.88-4.07 0-5.18-3.95-9.45-9-9.95zM12 19c-3.87 0-7-3.13-7-7 0-3.53 2.61-6.43 6-6.92V2.05c-5.06.5-9 4.76-9 9.95 0 5.52 4.47 10 9.99 10 3.31 0 6.24-1.61 8.06-4.09l-2.6-1.53C16.17 17.98 14.21 19 12 19z"></path>
    </svg>
  ))
);

export const HomeRoundedIcon = memo(
  forwardRef<SVGSVGElement, HTMLAttributes<SVGElement>>((props, ref) => (
    <svg
      {...props}
      aria-hidden="true"
      className="SvgIcon-root home-rounded-icon"
      focusable="false"
      ref={ref}
      viewBox="0 0 24 24"
    >
      <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z"></path>
    </svg>
  ))
);

export const ReportProblemOutlined = memo(
  forwardRef<SVGSVGElement, HTMLAttributes<SVGElement>>((props, ref) => (
    <svg
      {...props}
      aria-hidden="true"
      className={`fa svg-inline--fa report-problem-outlined-icon ${props.className || ""}`}
      focusable="false"
      ref={ref}
      viewBox="0 0 24 24"
    >
      <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"></path>
    </svg>
  ))
);

export const InfoOutlined = memo(
  forwardRef<SVGSVGElement, HTMLAttributes<SVGElement>>((props, ref) => (
    <svg
      {...props}
      aria-hidden="true"
      className={`fa svg-inline--fa info-outlined-icon ${props.className || ""}`}
      focusable="false"
      ref={ref}
      viewBox="0 0 24 24"
    >
      <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
    </svg>
  ))
);

export const NotificationsIcon = memo(
  forwardRef<SVGSVGElement, HTMLAttributes<SVGElement>>((props, ref) => (
    <svg
      {...props}
      aria-hidden="true"
      className="SvgIcon-root notifications-icon"
      focusable="false"
      ref={ref}
      viewBox="0 0 24 24"
    >
      <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"></path>
    </svg>
  ))
);

export const VerticalSplitIcon = memo(
  forwardRef<SVGSVGElement, HTMLAttributes<SVGElement>>((props, ref) => (
    <svg
      {...props}
      aria-hidden="true"
      className="SvgIcon-root vertical-split-icon"
      focusable="false"
      ref={ref}
      viewBox="0 0 24 24"
    >
      <path d="M3 15h8v-2H3v2zm0 4h8v-2H3v2zm0-8h8V9H3v2zm0-6v2h8V5H3zm10 0h8v14h-8V5z"></path>
    </svg>
  ))
);
