import { useMemo, useRef } from "react";

export const useRefState = <T,>(state: T) => {
  const ref = useRef<T>(state);

  useMemo(() => {
    ref.current = state;
  }, [state]);

  return ref;
};
