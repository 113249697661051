import React from "react";
import IncSmartText from "../SmartText/SmartText";
import { IncToolTip } from "../antd-components";
import { IncInfoIcon } from "../../icons";

interface Props {
  text: string;
  className?: string;
  helpText?: string;
  textClass?: string;
  onInfoClick?: () => void;
}

const TextWithInfo: React.FC<Props> = props => {
  const { text, helpText, textClass, className, onInfoClick } = props;
  return (
    <div
      className={`inc-flex-row inc-flex-center-vertical ${className || ""}`}
      style={{ width: "100%" }}
    >
      <IncSmartText
        text={text}
        textClass={textClass}
      />
      {helpText && (
        <IncToolTip
          placement="top-start"
          showArrow
          titleText={helpText}
        >
          <span
            className={onInfoClick ? "inc-cursor-pointer flex inc-flex-self-center" : "flex inc-flex-self-center"}
            onClick={onInfoClick}
          >
            <IncInfoIcon />
          </span>
        </IncToolTip>
      )}
    </div>
  );
};

export default TextWithInfo;
