import React, { FC } from "react";
import { css, cx } from "emotion";
import { IncSpin } from "../antd-wrapper";
import IncBackdropProps from "./types";

const backdropCSS = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 1000;
  border-radius: 8px;
`;
const backdropClassName = cx("inc-flex-row inc-flex-center", backdropCSS);

const defaultLoader = (
  <div className={backdropClassName}>
    <IncSpin
      size="default"
      spinning
    />
    <span style={{ marginLeft: "0.5rem" }}>Loading...</span>
  </div>
);

const IncBackdrop: FC<IncBackdropProps> = props => {
  const { children = null, loader = defaultLoader, loading = false, className = "" } = props;

  const wrapperClass = `inc-flex-column width-100 height-100 inc-flex-center ${className} position-relative`;

  return (
    <div className={wrapperClass}>
      {loading && loader}
      {!loading && children}
    </div>
  );
};

export default IncBackdrop;
