import React, { HTMLAttributes, forwardRef } from "react";
import { cx } from "emotion";

interface Props extends HTMLAttributes<HTMLDivElement> {}

export const VerticallyCenteredRow = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className: pClassName, children, ...restProps } = props;
  const className = cx("inc-flex-row", "inc-flex-center-vertical", {
    [pClassName]: Boolean(pClassName)
  });

  return (
    <div
      {...restProps}
      className={className}
      ref={ref}
    >
      {children}
    </div>
  );
});
