import { cloneDeep, find, isEmpty, map, sortBy } from "lodash";
import { generateUUID } from "@inception/ui";
import datasourceApiManager from "../DatasourceApiService";
import schemaApiService from "../SchemaApiService";
import { ConfigType, SchemaType } from "../types";
import { logger } from "../../../core";
import ConfigApiServiceInterface, { ConfigResult } from "./IConfigApiService";
import {
  ConfigMetaModel,
  ConfigScope,
  Configuration,
  ConfigurationModel,
  SAMLConfiguration
} from "./types/configuration";
import { configurationUtils } from "./utils";

class ConfigApiService implements ConfigApiServiceInterface {
  private CONFIG_URL = "/config-store/api/v1/config";
  // cache to store configuration types and their validity in cache
  private CONFIG_TYPE_CACHE: ConfigType[] = [];
  private CONFIG_TYPE_CACHE_VALID = true;
  // cache to store configurations of each type and their validity in cache
  private CONFIG_CACHE: Map<string, { configs: ConfigMetaModel[]; cacheValid: boolean }> = new Map();

  private INTEGRATIONS_CONFIG_TYPES_CACHE: ConfigType[] = [];
  private TENANT_CONFIG_TYPE_ID = "tenant_bicycle_config";
  private TENANT_UI_CONFIG_PROP_NAME = "ui-tenant_config";

  async call(method: "fetch" | "add" | "delete" | "update", path: string, payload = {}): Promise<any> {
    const url = `${this.CONFIG_URL}/${path}`;
    const request = datasourceApiManager.getDefault();
    let response = null;
    switch (method) {
      case "fetch":
        response = await request.get(url, payload);
        return response.data;
      case "add":
        response = await request.post(url, payload);
        return response.data;
      case "delete":
        response = await request.delete(url);
        return response.data;
      case "update":
        response = await request.put(url, payload);
        return response.data;
      default:
        response = await request.get(url, payload);
        return response.data;
    }
  }

  async getScopes() {
    try {
      const result = await this.call("fetch", "scopes");
      return this.successResponse(result);
    } catch (err) {
      return this.errorResponse([], err.message);
    }
  }

  async getConfigListForIntegrationConnectors(): Promise<ConfigMetaModel[]> {
    if (isEmpty(this.INTEGRATIONS_CONFIG_TYPES_CACHE)) {
      const configTypesFetch = await this.getConfigTypes();
      if (configTypesFetch.error) {
        throw new Error(configTypesFetch.message);
      } else {
        const configTypes = configTypesFetch.data || [];
        this.INTEGRATIONS_CONFIG_TYPES_CACHE = [];

        // Filter out the configs for integrations. The integrations start with connector-*
        configTypes.forEach((cType: ConfigType) => {
          if (cType.configType.typeReference.id.indexOf("connector_") !== -1) {
            this.INTEGRATIONS_CONFIG_TYPES_CACHE.push(cType);
          }
        });
      }
    }

    const integrationTypeIds: string[] = this.INTEGRATIONS_CONFIG_TYPES_CACHE.map(
      configType => configType.configType.typeReference.id
    );

    let configList: ConfigMetaModel[] = [];
    await Promise.all(
      map(integrationTypeIds, async (typeId: string) => {
        const configListFetch = await this.getConfigList(typeId);
        if (!configListFetch.error) {
          configList.push(...configListFetch.data);
        }
      })
    );
    configList = sortBy(configList, config => (config.typeStr || "").toLowerCase());

    return configList;
  }

  async getConfigList(configType: string) {
    const cacheResult = this.CONFIG_CACHE.get(configType);
    if (!isEmpty(cacheResult) && cacheResult.cacheValid) {
      this.log(`MESSAGE: Configuration list from cache. Type: ${configType}`);
      return this.successResponse(cloneDeep(cacheResult.configs));
    } else {
      try {
        if (configType === "all") {
          const configTypesFetch = await this.getConfigTypes();
          if (configTypesFetch.error) {
            throw new Error(configTypesFetch.message);
          } else {
            const configTypes = configTypesFetch.data || [];
            let configList: ConfigMetaModel[] = [];
            await Promise.all(
              map(configTypes, async (configType: ConfigType) => {
                const typeId = configType.configType.typeReference.id;
                const configListFetch = await this.getConfigList(typeId);
                if (!configListFetch.error) {
                  configList.push(...configListFetch.data);
                }
              })
            );
            configList = sortBy(configList, config => (config.typeStr || "").toLowerCase());
            this.CONFIG_CACHE.set("all", {
              configs: configList,
              cacheValid: true
            });
            return this.successResponse(configList);
          }
        } else {
          const payload = {
            configType,
            includeDisabled: true
          };
          const result = await this.call("add", "search", payload);
          const data: Array<Partial<Configuration>> = result.configs;
          const typeName = await this.getTypeNameFromId(configType);
          let configList = map(data, config => configurationUtils.payloadToMeta(config, typeName));
          configList = sortBy(configList, config => (config.name || "").toLowerCase());
          this.CONFIG_CACHE.set(configType, {
            configs: configList,
            cacheValid: true
          });
          return this.successResponse(configList);
        }
      } catch (err) {
        return this.errorResponse([] as ConfigMetaModel[], err.message);
      }
    }
  }

  async getConfig(configId: string, configType: string) {
    try {
      const typePayloadProperties = await this.getPayloadPropertiesForType(configType);
      const result = await this.call("fetch", `${configType}/${configId}`);
      const model = configurationUtils.payloadToModel(result as Configuration, typePayloadProperties);
      return this.successResponse(model);
    } catch (err) {
      return this.errorResponse({} as ConfigurationModel, err.message);
    }
  }

  async getConfigTypes() {
    if (this.CONFIG_TYPE_CACHE_VALID && !isEmpty(this.CONFIG_TYPE_CACHE)) {
      this.log(`MESSAGE: Configuration types from cache.`);
      return this.successResponse(cloneDeep(this.CONFIG_TYPE_CACHE));
    }
    try {
      const result = await schemaApiService.getConfigTypes();
      let data = result.data.types;
      data = sortBy(data, ct => (ct?.configType?.typeReference?.typeName || "").toLowerCase());
      this.CONFIG_TYPE_CACHE = data;
      this.CONFIG_TYPE_CACHE_VALID = true;
      return this.successResponse(data);
    } catch (err) {
      return this.errorResponse([], err.message);
    }
  }

  async addScope(scope: ConfigScope) {
    try {
      const result = await this.call("add", "scope", scope);
      return this.successResponse(result);
    } catch (err) {
      return this.errorResponse({}, err.message);
    }
  }

  async addConfig(config: ConfigurationModel) {
    try {
      const typePayloadProperties = await this.getPayloadPropertiesForType(config.type);
      const payload = configurationUtils.modelToPayload(config, typePayloadProperties);
      const result = await this.call("add", "", payload);

      const configTypeCache = this.CONFIG_CACHE.get(config.type);
      const allConfigsCache = this.CONFIG_CACHE.get("all");

      configTypeCache && (configTypeCache.cacheValid = false);
      allConfigsCache && (allConfigsCache.cacheValid = false);

      return this.successResponse(result);
    } catch (err) {
      return this.errorResponse({}, err.message);
    }
  }

  async addConfigType(configType: ConfigType) {
    try {
      const result = await schemaApiService.addSchema(configType);
      this.CONFIG_TYPE_CACHE_VALID = false;
      return this.successResponse(result);
    } catch (err) {
      return this.errorResponse({} as SchemaType, err.message);
    }
  }

  async updateScope(scope: ConfigScope) {
    try {
      const result = await this.call("update", "scope", scope);
      return this.successResponse(result);
    } catch (err) {
      return this.errorResponse({}, err.message);
    }
  }

  async updateConfig(config: ConfigurationModel) {
    try {
      const typePayloadProperties = await this.getPayloadPropertiesForType(config.type);
      const payload = configurationUtils.modelToPayload(config, typePayloadProperties);
      const result = await this.call("update", "", payload);

      const configTypeCache = this.CONFIG_CACHE.get(config.type);
      const allConfigsCache = this.CONFIG_CACHE.get("all");

      configTypeCache && (configTypeCache.cacheValid = false);
      allConfigsCache && (allConfigsCache.cacheValid = false);

      return this.successResponse(result);
    } catch (err) {
      return this.errorResponse({}, err.message);
    }
  }

  updateConfigType(configType: ConfigType) {
    this.CONFIG_TYPE_CACHE_VALID = false;
    return Promise.resolve(this.successResponse(configType));
  }

  async deleteScope(scope: ConfigScope) {
    try {
      const result = await this.call("delete", "scope", scope);
      return this.successResponse(result);
    } catch (err) {
      return this.errorResponse({}, err.message);
    }
  }

  async deleteConfig(configType: string, configId: string) {
    try {
      const subPath = `${configType}/${configId}?purge=false`;
      const result = await this.call("delete", subPath);

      const configTypeCache = this.CONFIG_CACHE.get(configType);
      const allConfigsCache = this.CONFIG_CACHE.get("all");

      configTypeCache && (configTypeCache.cacheValid = false);
      allConfigsCache && (allConfigsCache.cacheValid = false);

      return this.successResponse(result);
    } catch (err) {
      return this.errorResponse({}, err.message);
    }
  }

  deleteConfigType(configType: ConfigType) {
    this.CONFIG_TYPE_CACHE_VALID = false;
    return Promise.resolve(this.successResponse(configType));
  }

  async getDefaultConfig(configTypeId: string): Promise<ConfigurationModel> {
    const typePayloadProperties = await this.getPayloadPropertiesForType(configTypeId);
    const propertiesKeys = Object.keys(typePayloadProperties);
    const data: Record<string, string> = {};
    propertiesKeys.forEach(payloadProperty => {
      data[payloadProperty] = "";
    });

    return {
      id: generateUUID(),
      type: configTypeId,
      createdTime: new Date().getTime(),
      data: data,
      deleted: false,
      enabled: true,
      name: "New configuration",
      tags: [],
      version: 1
    };
  }

  private async getTypeNameFromId(typeId: string) {
    await this.getConfigTypes();
    return find(this.CONFIG_TYPE_CACHE, configType => configType?.configType?.typeReference?.id === typeId)?.configType
      ?.typeReference?.typeName;
  }

  private async getPayloadPropertiesForType(typeId: string) {
    await this.getConfigTypes();
    const configTypeProperties =
      find(this.CONFIG_TYPE_CACHE, configType => configType?.configType?.typeReference?.id === typeId)?.configType
        ?.properties || {};
    if (typeId === this.TENANT_CONFIG_TYPE_ID) {
      configTypeProperties[this.TENANT_UI_CONFIG_PROP_NAME] = "_str";
    }
    return configTypeProperties;
  }

  private successResponse<T>(data: T, message = "Fetch success"): ConfigResult<T> {
    return {
      error: false,
      status: "success",
      message,
      data
    };
  }

  private errorResponse<T>(data = {} as T, message = "Fetch failed"): ConfigResult<T> {
    return {
      error: true,
      status: "error",
      message,
      data
    };
  }

  private log(message: string) {
    logger.info("configApiService", message);
  }

  async getSAMLConfig(configType: string) {
    try {
      const payload = {
        configType,
        includeDisabled: true
      };
      const result = await this.call("add", "search", payload);
      const data: SAMLConfiguration[] = result.configs;
      return this.successResponse(data);
    } catch (err) {
      return this.errorResponse([] as SAMLConfiguration[], err.message);
    }
  }

  async updateSAMLConfig(config: SAMLConfiguration) {
    try {
      const result = await this.call(config?.id?.uuid ? "update" : "add", "", config);

      const configTypeCache = this.CONFIG_CACHE.get(config?.id?.typeId);
      const allConfigsCache = this.CONFIG_CACHE.get("all");

      configTypeCache && (configTypeCache.cacheValid = false);
      allConfigsCache && (allConfigsCache.cacheValid = false);

      return this.successResponse(result);
    } catch (err) {
      return this.errorResponse({}, err.message);
    }
  }
}

export const configApiService: ConfigApiService = new ConfigApiService();
