import React from "react";
import { cx } from "emotion";
import IncSmartText from "../SmartText/SmartText";

interface IncIconTextProps {
  text: string;
  icon?: JSX.Element;
  textClass?: string; //by default it is inc-text-body
  iconClass?: string;
  hideTooltip?: boolean; //hide tooltip for text that has ellipsis
  className?: string;
}

const IncIconText: React.FC<IncIconTextProps> = props => {
  const { text, icon, hideTooltip = false, textClass = "", iconClass = "", className = "" } = props;

  const classes = cx(["inc-flex-row", className]);

  return (
    <div className={classes}>
      {icon ? <div className={`marginRt6 ${iconClass}`}>{icon}</div> : <></>}
      <IncSmartText
        hideTooltip={hideTooltip}
        text={text}
        textClass={textClass}
      />
    </div>
  );
};

export default IncIconText;
