import { map } from "lodash";
import { FEATURE_FLAGS, featureFlagCustomistions } from "./FeatureFlags";

interface FeatureFlagService {
  isFeatureEnabled(name: FEATURE_FLAGS): boolean;
  setFeatureFlag(name: FEATURE_FLAGS, enabled: boolean): void;
  getAll(): FeaturesKeyCustomisationPair;
  clear(): void;
}

export type FeaturesKeyBooleanPair = Record<FEATURE_FLAGS, boolean>;
export type FeaturesKeyCustomisationPair = Record<
  FEATURE_FLAGS,
  {
    enabled: boolean;
    customisation?: JSX.Element;
  }
>;

export class LocalStorageFeatureFlagService implements FeatureFlagService {
  private readonly localStorage: Storage = window.localStorage;
  private readonly FEATURE_FLAG_STORE_ID: string = "INCEPTION_FEATURE_FLAGS";
  private featureFlags: Partial<FeaturesKeyCustomisationPair> = {};
  private currentlySupportedFeatureFlags: FEATURE_FLAGS[] = [];
  private initialDefaultTrueFlags: FEATURE_FLAGS[] = [];

  constructor(supportedFeatureFlags: FEATURE_FLAGS[], defaultTrueFlags?: FEATURE_FLAGS[]) {
    this.currentlySupportedFeatureFlags = supportedFeatureFlags || [];
    this.initialDefaultTrueFlags = defaultTrueFlags || [];
    this.load();
  }

  setFeatureFlag(name: FEATURE_FLAGS, enabled: boolean): void {
    this.featureFlags[name] = this.featureFlags[name] || {
      enabled: false,
      customisation: featureFlagCustomistions[name]
    };
    this.featureFlags[name].enabled = enabled;
    this.save();
  }

  isFeatureEnabled(name: FEATURE_FLAGS): boolean {
    const val = this.featureFlags[name];
    return val?.enabled === true;
  }

  isDebugMode(): boolean {
    const val = this.featureFlags[FEATURE_FLAGS.enableDebugMode];
    return val?.enabled === true;
  }

  isDemoMode(): boolean {
    const val = this.featureFlags[FEATURE_FLAGS.enableDemoMode];
    return val?.enabled === true;
  }

  getAll() {
    return this.featureFlags as FeaturesKeyCustomisationPair;
  }

  clear(): void {
    this.localStorage.clear();
    this.featureFlags = {};
  }

  private load(): void {
    const featureFlagValues =
      JSON.parse(this.localStorage.getItem(this.FEATURE_FLAG_STORE_ID) || "{}") || ({} as FeaturesKeyBooleanPair);
    // Set the entries in currentlySupportedFeatureFlags to false
    this.currentlySupportedFeatureFlags.forEach(name => {
      const setValue = featureFlagValues[name];
      //set any newly introduced flag to true if it part of initialDefaultTrueFlags
      const enabled = setValue === undefined ? this.initialDefaultTrueFlags.includes(name) : setValue;
      this.featureFlags[name] = {
        enabled,
        customisation: featureFlagCustomistions[name]
      };
    });

    // Filter out the one's which are not currently supported now since it might contain stale entries
    const featureFlagKeys = Object.keys(this.featureFlags) as FEATURE_FLAGS[];
    for (const key of featureFlagKeys) {
      if (this.currentlySupportedFeatureFlags.indexOf(key) === -1) {
        delete this.featureFlags[key];
      }
    }
  }

  private save(): void {
    const saveFeatureFlags = {} as FeaturesKeyBooleanPair;
    const featureFlagKeys = Object.keys(this.featureFlags) as FEATURE_FLAGS[];
    for (const key of featureFlagKeys) {
      saveFeatureFlags[key] = this.featureFlags[key].enabled;
    }
    this.localStorage.setItem(this.FEATURE_FLAG_STORE_ID, JSON.stringify(saveFeatureFlags));
  }
}

const featureFlagValues = map(FEATURE_FLAGS, v => v);
const featureFlagService = new LocalStorageFeatureFlagService(featureFlagValues, [
  FEATURE_FLAGS.dryRunOnboardingDemoData
]);
export default featureFlagService;
