import React from "react";
import { IncLoadingOutlined, IncSpin } from "./components";

export interface IncLoadingOutlinedSpinProps {
  size?: number;
  color?: string;
}

export const IncLoadingOutlinedSpin = React.memo<IncLoadingOutlinedSpinProps>(({ size = 24, color = "inherit" }) => (
  <IncSpin
    indicator={
      <IncLoadingOutlined
        spin
        style={{
          fontSize: size,
          color: color
        }}
      />
    }
  />
));
