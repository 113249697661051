import { UserServiceFieldSlice } from "../types";
import { FieldPickerUtils } from "../../../../utils";

export const getDisplayTagNameForUSFieldSlice = (slice: UserServiceFieldSlice) => {
  const { tagName, userServiceField } = slice;
  const { dataType, entityField } = userServiceField;

  let displayTagName = tagName;
  let isEntityTag = false;
  let entityType = "";

  if (dataType === "ENTITY" || dataType === "LIST_ENTITY") {
    const isEntityFieldPropType = !entityField?.propType || entityField?.propType === "NA";
    const isEntityFieldPropName = !entityField?.propName;
    entityType = entityField?.entityType;

    if (isEntityFieldPropType && isEntityFieldPropName && entityType) {
      displayTagName = entityType;
      isEntityTag = true;
    } else {
      displayTagName = FieldPickerUtils.generateNamefromUSF(userServiceField);
    }
  }

  return {
    displayTagName,
    isEntityTag,
    entityType,
    tagName,
    userServiceField
  };
};
