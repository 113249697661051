import { isEqual, isObject, keys, min, isEmpty, isArray, intersection, pick } from "lodash";
import pluralize from "pluralize";
import { PREFIX_FIELDS, PREFIX_TAGS } from "../services/api/traces/constants";
import { ENTITY_TAG } from "./MetricNameUtils";

/**
 * Returns a deep copy of the string.
 * @param str the string to deep copy
 */
function copyString(str: string): string {
  return `${str}`.slice(0);
}

function getStringFromBoolean(value: boolean): string {
  return value === true ? "True" : "False";
}

/**
 * Converts a string to boolean value. Returns boolean if string can be converted
 * @param input The string which is potentially a boolean
 */
function convertToBoolean(input: string): boolean | undefined {
  try {
    return JSON.parse(input);
  } catch (e) {
    return undefined;
  }
}

const isValidEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return regex.test(email);
};

const getMaxLengthStringFromList = (list: string[] = []): string => {
  const maxLengthString = list.reduce((acc, item) => {
    if (item.length > acc.length) {
      return item;
    }
    return acc;
  }, "");

  return maxLengthString;
};

const isTraceRoute = (history: any) => history && history.location.pathname.indexOf("/trace") > -1;

const getDefaultLegendFormat = (keys: string[], prefix = "", entityTagPrefix = ENTITY_TAG) => {
  let defaultLegendFormat = "";
  const entityTag = entityTagPrefix ? `{{${entityTagPrefix}}}` : "";
  if (keys.length > 0) {
    defaultLegendFormat = `${prefix}${entityTag} {${keys.map((key: string) => `${key}: {{${key}}}`).join(", ")}}`;
  } else {
    defaultLegendFormat = `${prefix}${entityTag}`;
  }
  return defaultLegendFormat;
};

export const traceFieldPrefixes = [PREFIX_TAGS, PREFIX_FIELDS];

function prettyString(s: string) {
  const entityDelimiter = "$";
  const entityTypeIndex = s.indexOf(entityDelimiter);
  // prettify entity string
  // e.g api$i_api -> api
  if (entityTypeIndex > 0) {
    return s.substring(0, entityTypeIndex);
  }

  const relKeyword = "rel:";
  const relIndex = s.indexOf(relKeyword);
  // prettify relation string
  // e.g rel: i_api2apiInstance -> apiInstance
  if (relIndex >= 0) {
    let relation = s.substring(relIndex + relKeyword.length);
    const relDelimiter = "2";
    const relDelimiterIndex = relation.indexOf(relDelimiter);
    if (relDelimiterIndex > 0) {
      relation = relation.substring(relDelimiterIndex + relDelimiter.length);
    }
    return relation;
  }

  return s;
}

export function getTagNameFromFieldName(field: string) {
  let _field = field;
  traceFieldPrefixes.every(prefix => {
    if (_field.startsWith(prefix)) {
      _field = _field.substr(prefix.length);
      return false;
    }
    return true;
  });

  const fieldDelimiter = ".";
  const key = _field
    .split(fieldDelimiter)
    .map(s => prettyString(s))
    .join(fieldDelimiter);

  return getPromSanitizedName(key);
}

export function getPromSanitizedName(keyword: string) {
  const delimiter = "_";
  const whitespace = /\s/g;

  let sanitizedName = keyword.trim().replace(whitespace, delimiter);
  if (/^\d/.test(sanitizedName)) {
    sanitizedName = `_${sanitizedName}`;
  }
  sanitizedName = sanitizedName.replace(/[^a-zA-Z0-9_]/g, "_").replace(/[_]+/g, "_");

  return sanitizedName;
}

/**
 *
 * @param fn The function to call which returns T. Usually an api call.
 * @param callBack This function gets executed on every @fn call. The return value decides whether to attempt a retry.
 * @param isExponentialPoll If true, the gap between each retry increases every time a retry is attempted
 * @param minPollInterval Minimum ms to wait before retry. If @isExponentialPoll is false, the wait is always this value. Defaults to 200 ms.
 * @param maxPollCount Maximum retries to attempt.
 * @param maxPollInterval Maximum ms to wait before retry. The value is used if @isExponentialPoll is set to true.
 *
 * @returns a function to cancel the polling.
 */
export function asyncPoller<T>(
  fn: () => Promise<T>,
  callBack: (value: T, count: number, isError: boolean, error: any) => boolean,
  isExponentialPoll = false,
  minPollInterval = 250,
  maxPollInterval = 16000,
  maxPollCount = Number.MAX_SAFE_INTEGER
) {
  const defaultMaxPollCount = 5;
  let counter = 1;
  const getNextInterval = () => {
    if (isExponentialPoll && counter > defaultMaxPollCount) {
      return min([maxPollInterval, Math.pow(2, counter - defaultMaxPollCount) * minPollInterval]);
    }
    return minPollInterval;
  };

  let timeoutId: number = null;
  let shouldStopPolling = false;

  const cancel = () => {
    if (timeoutId) {
      console.log("cancelling async poll");
      clearTimeout(timeoutId);
      shouldStopPolling = true;
    }
  };

  const poller = (timer: number) => {
    if (shouldStopPolling) {
      return;
    }

    timeoutId = window.setTimeout(async () => {
      let shouldContinue = true;
      try {
        const value: T = await fn();
        if (!value) {
          shouldContinue = callBack(null, counter, true, new Error("Function returned no value"));
        } else {
          shouldContinue = callBack(value, counter, false, null);
        }
      } catch (error) {
        shouldContinue = callBack(null, counter, true, error);
      }

      if (shouldContinue && counter <= maxPollCount) {
        console.log("Retrying poll...");
        poller(getNextInterval());
        ++counter;
      }
    }, timer);
  };
  poller(getNextInterval());

  return cancel;
}

const mapToObject = <T = string>(map: Map<string, T>): Record<string, T> => {
  const record: Record<string, T> = {};
  map.forEach((value, key) => {
    record[key] = value;
  });
  return record;
};

/**
 * Get the plural of a word
 * @param word Word to pluralize
 * @param count Count of the word
 * @param includeCount Include the count in the result. If not specified and count is passed, the count will be included in the result.
 * @returns The plural of the word (inclusive of count based on the includeCount param).
 */
const incPluralize = (word: string, count?: number, includeCount?: boolean) => {
  // Wrapper over pluralise to handle specific cases in future
  includeCount = includeCount === undefined ? (count === undefined ? false : true) : includeCount;
  return pluralize(word || "", count, includeCount);
};

/**
 * get key by value
 * @param value
 * @param object
 * @returns
 */
const getKeyByValue = (value: any, object: Record<string, any>) =>
  keys(object).find(key => isEqual(object[key], value));

const flattenObject = (object: Record<string, any>, stringifyParent = false): Record<string, string | string[]> => {
  const result: Record<string, string | string[]> = {};
  const reduce = (object: Record<string, any>, result: Record<string, any>, label: string) => {
    if (!isObject(object) || isArray(object)) {
      result[label] = object;
      return;
    }

    if (isEmpty(object)) {
      result[label] = "";
      return;
    }

    if (label) {
      result[label] = stringifyParent ? JSON.stringify(object) : object;
    }

    for (const k in object) {
      reduce((object as Record<string, any>)[k], result, label ? `${label}.${k}` : k);
    }
  };
  reduce(object, result, "");
  return result;
};

const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (err) {
    return false;
  }
  return true;
};

const getObjectPathValue = (object: Record<string, any>, path: string) => {
  const pathKeys = path.split(".");
  let obj = object;
  let pathVal = null;
  for (let i = 0; i < pathKeys.length; i++) {
    if (!obj[pathKeys[i]]) {
      return null;
    }
    if (i === pathKeys.length - 1) {
      pathVal = obj[pathKeys[i]];
    } else {
      obj = obj[pathKeys[i]];
    }
  }
  return pathVal;
};

/**
 * Downloads a json object as file
 * @param jsonData Array of objects
 * @param fileName file name to download
 */
const downloadJSONFile = (jsonData: Array<Record<string, any>>, fileName: string) => {
  const _fileName = fileName || "download";
  const _testData = [
    {
      actionName: "download",
      description: "test"
    }
  ];
  const _jsonData = jsonData || _testData;
  const json = JSON.stringify(_jsonData, null, 2);
  const blob = new Blob([json], { type: "application/json" });
  const href = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = href;
  link.download = `${_fileName}.json`;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

/**
 * Download a blob as a file
 * @param blob
 * @param fileName
 */
const downloadBlobFile = (blob: Blob, fileName: string) => {
  const _fileName = fileName || "download";
  try {
    const href = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = href;
    link.download = _fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (e) {
    console.error("DownloadBlobFile", "failed to download blob", e);
  }
};

export const downloadHtmlFile = (htmlString: string, fileName?: string) => {
  const _fileName = fileName ? `${fileName}.html` : "download.html";

  const blob = new Blob([htmlString], { type: "text/html" });
  downloadBlobFile(blob, _fileName);
};

const checkIfTagEntryMatches = (tagEntry: Record<string, string>, matchTags: Record<string, string>) => {
  const tagKeys = Object.keys(tagEntry);
  return tagKeys.every(tag => tagEntry[tag] === matchTags[tag]);
};

const getEnvironmentType = () => {
  const windowHref = window.location.href;
  if (windowHref.includes("app.dev")) {
    return "dev";
  } else if (windowHref.includes("app.ione")) {
    return "prod";
  }
  return "local";
};

export const generateCombinations = (arrays: any[], current: any[] = [], index = 0): any[][] => {
  if (index === arrays.length) {
    return [current];
  }

  const currentArray = arrays[index];
  const combinations: any[] = [];

  for (const item of currentArray) {
    const newCurrent = current.slice();
    newCurrent.push(item);

    const subCombinations = generateCombinations(arrays, newCurrent, index + 1);
    combinations.push(...subCombinations);
  }

  return combinations;
};

export const noOp = () => {};

export const MAX_INT32 = 2147483647;

export function compareObjectsWithSameProperties(
  obj1: Record<string, any>,
  obj2: Record<string, any>,
  skipProps: string[] = []
): boolean {
  if (!obj1 || !obj2) {
    if (!obj1 && !obj2) {
      return true;
    }

    return false;
  }

  const widgetProps1 = Object.keys(obj1 || {});
  const widgetProps2 = Object.keys(obj2 || {});

  /**
   * Compare only the common properties, since the UI can add new properties for various reasons.
   * Comparing these new properties and showing unsaved changes is incorrect. So we skip them.
   */

  const commonWidgetProps = intersection(widgetProps1, widgetProps2);
  const propsToCompare = commonWidgetProps.filter(prop => !skipProps.includes(prop));

  const compareModel1 = pick(obj1, propsToCompare);
  const compareModel2 = pick(obj2, propsToCompare);

  return propsToCompare.reduce((acc, prop) => {
    if (!acc) {
      return acc;
    }

    const propValue1 = compareModel1[prop];
    const propValue2 = compareModel2[prop];

    if (isArray(propValue1) && isArray(propValue2)) {
      return isEqual(propValue1, propValue2);
    } else if (isObject(propValue1) && isObject(propValue2)) {
      return compareObjectsWithSameProperties(propValue1, propValue2);
    }

    return propValue1 === propValue2;
  }, true as boolean);
}

export {
  copyString,
  convertToBoolean,
  isValidEmail,
  getStringFromBoolean,
  isTraceRoute,
  getMaxLengthStringFromList,
  getDefaultLegendFormat,
  mapToObject,
  incPluralize as pluralizeWord,
  getKeyByValue,
  flattenObject,
  isJsonString,
  getObjectPathValue,
  downloadJSONFile,
  downloadBlobFile,
  checkIfTagEntryMatches,
  getEnvironmentType
};
