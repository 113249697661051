import { Action } from "./features";

//place all actions under busniess entity here
export enum INTEGRATIONS_ACTIONS {
  addSource = "addSource",
  editSource = "editSource",
  mapUserServices = "mapUserServices",
  cloneSource = "cloneSource",
  deleteSource = "deleteSource",
  downloadPreview = "downloadPreview"
}

export const IntegrationsFeatureActions: Record<keyof typeof Action, Array<keyof typeof INTEGRATIONS_ACTIONS>> = {
  //All CREATE actions goes here
  //Enabled only for Admins,Editors
  CREATE: ["addSource", "cloneSource", "downloadPreview"],

  //All UPDATE actions goes here
  //Enabled only for Admins,Editors
  UPDATE: ["editSource"],

  //All READ actions goes here
  //Enabled for All
  READ: ["mapUserServices"],

  //All DELETE actions goes here
  //Enabled only for Admins,Editors
  DELETE: ["deleteSource"]
};
