import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isNil } from "lodash";
import { ApplicationState } from "../../../../store/configureStore";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { routeManager } from "../../InceptionRouteManager";
import { TimeZone } from "../../moment_wrapper";
import { useTenantConfig } from "../tenant-config";
import { useTypedQueryParams } from "../useQueryParamsHook";
import {
  refreshAction,
  setTimeRangeAction,
  setCompareTimeRangeAction,
  setCompareEnabledAction,
  setTimeRangeEnabledAction,
  setTimeSelectorsEnabledAction,
  setTimeRangeSelectorDisabledAction,
  setRefreshDisabledAction,
  setRestrictedTimeRangeAction,
  setIsCustomTimeRangeInitialized
} from "./reducer";
import { RawTimeRange, TCCustomTimeRange, TimeRangeState } from "./types";
import timeSrv from "./TimeSrv";

const useTimeRange = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const timeRangeState: Readonly<TimeRangeState> = useSelector((storeState: ApplicationState) => storeState.timeRange);

  const urlTimeParams = useTypedQueryParams<RawTimeRange>();
  const urlTimeParamsRef = useRef(urlTimeParams);

  const { isCustomTimeRangeInitialized } = timeRangeState || {};

  const updateUrlInternal = useCallback(
    (from: string, to: string, cFrom: string, timeZone?: TimeZone) => {
      const path = history.location.pathname;
      const routeRequiresTimeRange = routeManager.isTimeRangeRequired(path);
      const routeRequiresCompareTimeRange = routeManager.isCompareTimeRangeRequired(path);

      const url = window.location.pathname + window.location.search;

      from = routeRequiresTimeRange ? from : "";
      to = routeRequiresTimeRange ? to : "";
      cFrom = routeRequiresCompareTimeRange ? cFrom : "";

      const newUrl = timeRangeUtils.getUrl(from, to, url, cFrom, timeZone);
      history.replace(newUrl);
    },
    [history]
  );

  const setTimeRangeInternal = useCallback(
    (newTimeRange: RawTimeRange, updateUrl = true, isCompare = false) => {
      // Doing this to prevent lag since this may trigger data fetch operations
      window.setTimeout(() => {
        const action = isCompare ? setCompareTimeRangeAction : setTimeRangeAction;
        dispatch(action({ ...newTimeRange }));

        const rawFrom = newTimeRange.from;
        const rawTo = newTimeRange.to;

        const oCTRRaw = timeSrv.getCompareTimeRange().raw;
        const oTRRaw = timeSrv.getTimeRange().raw;

        if (updateUrl && rawFrom) {
          const from = isCompare ? oTRRaw.from : rawFrom;
          const to = isCompare ? oTRRaw.to : rawTo;
          const cFrom = isCompare ? rawFrom : oCTRRaw.from;
          const { timeZone } = newTimeRange;

          updateUrlInternal(from, to, cFrom, timeZone);
        }
      }, 0);
    },
    [dispatch, updateUrlInternal]
  );

  const setShowCompareSelector = useCallback(
    (enabled: boolean) => {
      dispatch(setCompareEnabledAction(enabled));
    },
    [dispatch]
  );

  const setShowTimeRangeSelector = useCallback(
    (enabled: boolean) => {
      dispatch(setTimeRangeEnabledAction(enabled));
    },
    [dispatch]
  );

  const setDisableTimeRangeSelector = useCallback(
    (disabled: boolean) => {
      dispatch(setTimeRangeSelectorDisabledAction(disabled));
    },
    [dispatch]
  );

  const setDisableRefresh = useCallback(
    (disabled: boolean) => {
      dispatch(setRefreshDisabledAction(disabled));
    },
    [dispatch]
  );

  const setTimeSelectorsEnabled = useCallback(
    (enabledArr: [boolean, boolean]) => {
      dispatch(setTimeSelectorsEnabledAction(enabledArr));
    },
    [dispatch]
  );

  const setTimeRange = useCallback(
    (newTimeRange: RawTimeRange, updateUrl = true) => {
      setTimeRangeInternal(newTimeRange, updateUrl, false);
    },
    [setTimeRangeInternal]
  );

  const setRestrictedTimeRange = useCallback(
    (newTimeRange: RawTimeRange) => {
      const action = setRestrictedTimeRangeAction;
      dispatch(action({ ...newTimeRange }));
    },
    [dispatch]
  );

  const setCompareTimeRange = useCallback(
    (newTimeRange: RawTimeRange, updateUrl = true) => {
      setTimeRangeInternal(newTimeRange, updateUrl, true);
    },
    [setTimeRangeInternal]
  );

  const getLatestTimeRange = useCallback(
    () => timeRangeUtils.getTimeRangeFromRaw(timeRangeState.timeRange.raw),
    [timeRangeState]
  );

  const getLatestCompareTimeRange = useCallback(
    () => timeRangeUtils.getTimeRangeFromRaw(timeRangeState.compareTimeRange.raw),
    [timeRangeState]
  );

  const refresh = useCallback(() => {
    dispatch(refreshAction());
  }, [dispatch]);

  const updateTimeRangeParams = useCallback(
    (from: string, to: string, timeZone?: TimeZone) => {
      const { from: cFrom } = timeSrv.getCompareTimeRange().raw;
      updateUrlInternal(from, to, cFrom, timeZone);
    },
    [updateUrlInternal]
  );

  const updateCompareTimeRangeParams = useCallback(
    (cFrom: string) => {
      const { from, to, timeZone } = timeSrv.getTimeRange().raw;
      updateUrlInternal(from, to, cFrom, timeZone);
    },
    [updateUrlInternal]
  );

  const setIsCustomTRangeInitialized = useCallback(
    (isInitialized: boolean) => {
      dispatch(setIsCustomTimeRangeInitialized(isInitialized));
    },
    [dispatch]
  );

  const { tenantConfigState, isTenantConfigFetched } = useTenantConfig();
  const customTimeRanges = useMemo(() => tenantConfigState?.customTimeRanges || [], [tenantConfigState]);

  useEffect(() => {
    const { from, to } = urlTimeParamsRef.current;

    if (
      (!from || !to) &&
      isTenantConfigFetched &&
      customTimeRanges.length &&
      !isNil(isCustomTimeRangeInitialized) &&
      !isCustomTimeRangeInitialized
    ) {
      let defCustomTimeRange: TCCustomTimeRange;

      for (let index = 0; index < customTimeRanges.length; index++) {
        const item = customTimeRanges[index];
        if (item && item.isDefault && item.from && item.to) {
          defCustomTimeRange = item;
          break;
        }
      }

      if (defCustomTimeRange) {
        const timeRange: RawTimeRange = {
          from: defCustomTimeRange.from,
          to: defCustomTimeRange.to
        };

        timeRangeUtils.setDefaultTimeRange(timeRange.from, timeRange.to);
        setIsCustomTRangeInitialized(true);
        setTimeRange(timeRange);
      }
    }
  }, [
    customTimeRanges,
    isCustomTimeRangeInitialized,
    isTenantConfigFetched,
    setIsCustomTRangeInitialized,
    setTimeRange
  ]);

  return {
    hasInitialTimeRange: timeRangeState?.hasInitialTimeRange,
    hasInitialCompareTimeRange: timeRangeState?.hasInitialCompareTimeRange,
    timeRange: timeRangeState?.timeRange,
    restrictedTimeRange: timeRangeState?.restrictedTimeRange,
    getLatestTimeRange,
    setTimeRange,
    setRestrictedTimeRange,
    compareTimeRange: timeRangeState?.compareTimeRange,
    getLatestCompareTimeRange,
    setCompareTimeRange,
    refresh,
    showCompareSelector: timeRangeState?.showCompareSelector,
    setShowCompareSelector,
    showTimeRangeSelector: timeRangeState?.showTimeRangeSelector,
    setShowTimeRangeSelector,
    setTimeSelectorsEnabled,
    disableTimeRangeSelector: timeRangeState?.disableTimeRangeSelector,
    setDisableTimeRangeSelector,
    disableRefresh: timeRangeState?.disableRefresh,
    setDisableRefresh,
    updateTimeRangeParams,
    updateCompareTimeRangeParams
  };
};

export { useTimeRange };
