import { CancelToken } from "axios";
import { isNil, merge } from "lodash";
import {
  AddDrillDownStepRequest,
  CompareQuerySchemaRequest,
  CompareQuerySchemaResponse,
  DemoDataParams,
  GetWorkflowRequest,
  ResponseInfo,
  SelectorSpec,
  ToolMetaDataList,
  UpdateDrillDownStepRequest,
  Workflow,
  WorkflowList,
  WorkflowResponse
} from "../explore";
import { BizService } from "../explore/BizServiceCommon";
import { InceptionRequestConfig } from "../types";
import { UseCaseOpQueryConfig } from "../use-case-catalog/types";
import { SimulationConfig } from "../../../operationalise-v2/context/types";
import { OpFragment } from "../chat";
import {
  ActionCategoryList,
  ActionCategoryType,
  ActionDeleteResponse,
  ActionDisableResponse,
  ActionSourceTypeList,
  ActionSourceTypes,
  AlertCountResponse,
  AlertFilterResponse,
  AlertSnapshot,
  BizActionConfig,
  BizActionResponse,
  BizActionSetupRequest,
  BizIdProps,
  FetchIncidentDataRequest,
  Op10zeAlertParams,
  Op10zeSearchRequest,
  Op10zeSearchResponse,
  Op10zeSetupRequest,
  Op10zeSetupResponse,
  Op10zeStage,
  OpCreationConfig,
  OpDeleteResponse,
  OpDisableResponse,
  OpPreviewDataRequest,
  OpSchema,
  OpSimulationRequest,
  OpSimulationResponse,
  OpSimulationState,
  OpStateListResponse,
  ParamValue,
  SingleParamValuesResponse,
  RenameOp10zeRequest,
  UIIntegrationActionConfigList,
  ActionTemplateElementsRequest,
  ActionTemplateElementsResponse,
  ActionRunMode,
  BizActionParamValuesRequest,
  BizActionParamValuesResponse,
  OpTriageConfigResponse,
  UpdateOpTriageRequest,
  OpTriageConfig,
  OpTriageConfigType,
  RawAlertOperationlize,
  InsightPreviewDataRequest,
  InsightPreviewDataResponse,
  GetIncidentInfoRequest,
  GetIncidentInfoResponse,
  InsightMemberShipResponse,
  AlertsRequestPayload,
  AlertCountResponses,
  AlertFilterResponses,
  MuteTimeRange,
  ActionEvalSpec,
  IncidentType,
  MonitoredDataSchema,
  OpMetaDataEditRequest,
  Op10zeEditResponse,
  GetDefaultDiagnosticKpiRequest,
  DefaultDiagnosticKpiResponse,
  OpEditRequest,
  OpEditViaFragmentsRequest,
  OpCreationFragmentEditType,
  GetBizImpactConfigResponse
} from "./types";

interface Result<T> {
  data: T;
  error: boolean;
  message: string;
  cancelled: boolean;
}

class OperationaliseV2ApiService extends BizService {
  getOpConfigs(
    widgetId?: string,
    op10zeId?: string | string[],
    idProps?: BizIdProps,
    alertCountParams?: Op10zeAlertParams,
    incidentId?: string,
    version?: string,
    widgetIds?: string[],
    generateDemoData?: boolean
  ) {
    const subUrl = "/op10ze/search";
    const payload: Op10zeSearchRequest = {};
    const params: Record<string, string> = {};

    if (op10zeId) {
      if (typeof op10zeId === "string") {
        payload.op10zeId = op10zeId;
      } else {
        payload.op10zeIds = {
          id: op10zeId
        };
      }
    }

    widgetId && (payload.widgetId = widgetId);
    idProps && (payload.idProps = idProps);
    incidentId && (payload.incidentId = incidentId);
    version && (payload.version = version);
    widgetIds &&
      (payload.widgetIds = {
        id: widgetIds
      });

    if (alertCountParams) {
      merge(payload, alertCountParams || {});
      params.startTimeMillis = String(alertCountParams.startTimeMillis);
      params.endTimeMillis = String(alertCountParams.endTimeMillis);
    }

    if (generateDemoData) {
      params.generateDemoData = "true";
    }

    return this.call<Op10zeSearchResponse, Op10zeSearchRequest>("post", subUrl, payload, params);
  }

  getOpAlertCounts(filterParams: Record<string, any> = {}) {
    const subUrl = "/op10ze/incidents/count";
    return this.call<RawAlertOperationlize, unknown>("get", subUrl, {}, filterParams);
  }

  renameOp10ze(op10zeId: string, name: string) {
    const subUrl = `/op10ze/${op10zeId}/rename`;
    const payload: RenameOp10zeRequest = {
      name
    };
    return this.call<Op10zeSetupResponse, RenameOp10zeRequest>("post", subUrl, payload);
  }

  createOpConfig(opCreationConfig: OpCreationConfig, widgetId?: string, dryRun = false) {
    // Labels validation check, since proto doesn't allow null in map<string, string>
    this.sanitiseOpConfig(opCreationConfig);

    const subUrl = `/op10ze?dryRun=${dryRun}`;
    const payload: Op10zeSetupRequest = {
      opCreationConfig
    };

    widgetId && (payload.widgetId = widgetId);

    return this.call<Op10zeSetupResponse, Op10zeSetupRequest>("post", subUrl, payload);
  }

  editOpConfig(opCreationConfig: OpCreationConfig, opId: string, widgetId?: string) {
    const subUrl = `/op10ze/${opId}/edit`;
    const payload: Op10zeSetupRequest = {
      opCreationConfig
    };

    widgetId && (payload.widgetId = widgetId);

    return this.call<Op10zeSetupResponse, Op10zeSetupRequest>("post", subUrl, payload);
  }

  editOpConfigMetaV2(opId: string, metadataEditRequest: OpMetaDataEditRequest) {
    const subUrl = `/op10ze/${opId}/edit/meta/v2`;
    return this.call<Op10zeEditResponse, OpEditRequest>("post", subUrl, {
      metadataEditRequest
    });
  }

  fetchRunHistory(op10zeId: string) {
    const subUrl = `/op10ze/${op10zeId}/state`;
    return this.call<OpStateListResponse>("get", subUrl);
  }

  fetchOpConfigSchema(op10zeId: string, incidentId: string, generateDemoData = false) {
    const subUrl = `/op10ze/${op10zeId}/schema`;
    return this.call<OpSchema>("get", subUrl, null, {
      incidentId,
      generateDemoData
    });
  }

  disableOpConfig(op10zeId: string) {
    const subUrl = `/op10ze/${op10zeId}/disable`;
    return this.call<OpDisableResponse>("post", subUrl);
  }

  updateOpConfigStage(op10zeId: string, stage: Op10zeStage) {
    const subUrl = `/op10ze/${op10zeId}/stage/${stage}`;
    return this.call<OpDisableResponse>("post", subUrl);
  }

  deleteOpConfig(op10zeId: string) {
    const subUrl = `/op10ze/${op10zeId}`;
    return this.call<OpDeleteResponse>("delete", subUrl);
  }

  addBizAction(bizActionConfig: BizActionConfig, op10zeId: string, actionId: string) {
    const subUrl = `/op10ze/${op10zeId}/action/${actionId}`;
    const payload: BizActionSetupRequest = {
      bizActionConfig
    };

    return this.call<BizActionResponse, BizActionSetupRequest>("put", subUrl, payload);
  }

  fetchWorkFlowForOp(opId: string, incidentId: string) {
    const subUrl = `/op/${opId}/incident/${incidentId}/workflow`;
    return this.call<WorkflowResponse, any>("get", subUrl);
  }

  // /op/{opId}/default/workflow
  fetchDefaultWorkFlowForOp(opId: string) {
    const subUrl = `/op/${opId}/default/workflow`;
    return this.call<WorkflowResponse, any>("get", subUrl);
  }

  fetchToolSpec(opId?: string) {
    const subUrl = opId ? `/op/${opId}/triage/tools` : "/triage/tools";
    return this.call<ToolMetaDataList, any>("get", subUrl);
  }

  markOrUnmarkDemo(opId: string, incidentId: string, workflowId: string, stepId: string, markAsDemo: boolean) {
    const subUrl = `/op/${opId}/incident/${incidentId}/workflow/${workflowId}/step/${stepId}/markAsDemo/${markAsDemo}`;
    return this.call<WorkflowResponse, any>("put", subUrl);
  }

  addDrilldownStep(addDrillDownStepRequest: AddDrillDownStepRequest, opId: string, workflowId: string) {
    const subUrl = `/op/${opId}/workflow/${workflowId}/step/add`;
    return this.call<WorkflowResponse, AddDrillDownStepRequest>("put", subUrl, addDrillDownStepRequest);
  }

  updateDrilldownStep(updateDrillDownStepRequest: UpdateDrillDownStepRequest, opId: string, workflowId: string) {
    const subUrl = `/op/${opId}/workflow/${workflowId}/step/update`;
    return this.call<WorkflowResponse, UpdateDrillDownStepRequest>("post", subUrl, updateDrillDownStepRequest);
  }

  batchUpdateWorkflow(batchRequest: Workflow, opId: string, workflowId: string) {
    const subUrl = `/op/${opId}/workflow/${workflowId}/update`;
    return this.call<ResponseInfo, Workflow>("post", subUrl, batchRequest);
  }

  deleteStep(opId: string, workflowId: string, stepId: string) {
    const subUrl = `/op/${opId}/workflow/${workflowId}/step/${stepId}/delete`;
    return this.call<WorkflowResponse, any>("delete", subUrl);
  }

  deleteWorkflowForOp(opId: string, workflowId: string) {
    const subUrl = `/op/${opId}/workflow/${workflowId}`;
    return this.call<ResponseInfo, any>("delete", subUrl);
  }

  fetchWorkflows(workflowIds: string[]) {
    const subUrl = `/fetch/workflows`;
    return this.call<WorkflowList, GetWorkflowRequest>("post", subUrl, {
      workflowIds
    });
  }

  deleteWorkflow(workflowId: string) {
    const subUrl = `/delete/workflow/${workflowId}`;
    return this.call<ResponseInfo, any>("delete", subUrl);
  }

  overrideAnalysisKpi(kpiRequest: GetDefaultDiagnosticKpiRequest) {
    const subUrl = `/op10ze/fetch/diagnostic-kpi-default`;
    return this.call<DefaultDiagnosticKpiResponse, GetDefaultDiagnosticKpiRequest>("post", subUrl, kpiRequest);
  }

  cloneOpConfig(opId: string) {
    const subUrl = `/op10ze/${opId}/clone`;
    return this.call<OpCreationConfig>("get", subUrl);
  }

  getCloneOpCreationConfig(opCreationConfig: OpCreationConfig) {
    const subUrl = `/op10ze/clone`;
    return this.call<OpCreationConfig, Op10zeSetupRequest>("post", subUrl, {
      opCreationConfig
    });
  }

  getOpSchemaFromOpCreationConfig(opCreationConfig: OpCreationConfig) {
    const subUrl = `/op10ze/schema/draft`;
    return this.call<OpSchema, Op10zeSetupRequest>("post", subUrl, {
      opCreationConfig
    });
  }

  cloneOpSimulationConfig(opId: string, simulationId: string) {
    const subUrl = `/op10ze/${opId}/simulation/${simulationId}/clone`;
    return this.call<SimulationConfig>("get", subUrl);
  }

  clonePrimaryOpSimulationConfig(opId: string) {
    const subUrl = `/op10ze/${opId}/simulation/clone`;
    return this.call<SimulationConfig>("get", subUrl);
  }

  cloneOpActionConfig(opId: string, actionId: string) {
    const subUrl = `/op10ze/${opId}/action/${actionId}/clone`;
    return this.call<BizActionConfig>("get", subUrl);
  }

  disableBizActionConfig(op10zeId: string, actionId: string) {
    const subUrl = `/op10ze/${op10zeId}/action/${actionId}/disable`;
    return this.call<ActionDisableResponse>("post", subUrl);
  }

  enableBizActionConfig(op10zeId: string, actionId: string) {
    const subUrl = `/op10ze/${op10zeId}/action/${actionId}/enable`;
    return this.call<ActionDisableResponse>("post", subUrl);
  }

  snoozeBizActionConfig(op10zeId: string, actionId: string, timeRange: MuteTimeRange) {
    const subUrl = `/op10ze/${op10zeId}/action/${actionId}/snooze`;
    return this.call<ActionDisableResponse>("post", subUrl, timeRange);
  }

  snoozeSeriesConfig(op10zeId: string, alertSpec: ActionEvalSpec) {
    const subUrl = `/op10ze/${op10zeId}/snooze`;
    return this.call<ActionDisableResponse>("post", subUrl, alertSpec);
  }

  deleteBizActionConfig(op10zeId: string, actionId: string) {
    const subUrl = `/op10ze/${op10zeId}/action/${actionId}`;
    return this.call<ActionDeleteResponse>("delete", subUrl);
  }

  getActionCategoryList(opCreationConfig?: OpCreationConfig) {
    const subUrl = `/action/category`;
    return this.call<ActionCategoryList, Op10zeSetupRequest>(opCreationConfig ? "post" : "get", subUrl, {
      opCreationConfig
    });
  }

  getSourceTypeList() {
    const subUrl = `/action/sourceType`;
    return this.call<ActionSourceTypeList>("get", subUrl);
  }

  deleteSimulation(op10zeId: string, simulationId: string) {
    const subUrl = `/op10ze/${op10zeId}/delete/simulation/${simulationId}`;
    return this.call<OpSimulationResponse>("delete", subUrl);
  }

  getIntegrationActionConfigs(
    categoryId: ActionCategoryType,
    sourceTypeId: ActionSourceTypes,
    opCreationConfig: OpCreationConfig
  ) {
    const subUrl = `/op10ze/action/category/${categoryId}/sourceTypeId/${sourceTypeId}/configs`;
    return this.call<UIIntegrationActionConfigList>("post", subUrl, {
      opCreationConfig
    });
  }

  getTemplateElements(
    categoryId: ActionCategoryType,
    sourceTypeId: ActionSourceTypes,
    paramName: string,
    bizActionConfig: BizActionConfig,
    opId?: string,
    opConfig?: OpCreationConfig
  ) {
    const subUrl = `/op10ze/action/category/${categoryId}/sourceTypeId/${sourceTypeId}/params/${paramName}/template/elements`;
    const payload: ActionTemplateElementsRequest = opId
      ? {
          opId,
          bizActionConfig
        }
      : {
          opConfig,
          bizActionConfig
        };
    return this.call<ActionTemplateElementsResponse, ActionTemplateElementsRequest>("post", subUrl, payload);
  }

  getParamSuggestions(
    categoryId: ActionCategoryType,
    sourceTypeId: ActionSourceTypes,
    integrationActionConfigId: string,
    op10zeId: string,
    opCreationConfig: OpCreationConfig,
    actionRunMode: ActionRunMode
  ) {
    const subUrl = `/op10ze/action/category/${categoryId}/sourceTypeId/${sourceTypeId}/config/${integrationActionConfigId}/values`;
    const requestPayload: BizActionParamValuesRequest = op10zeId
      ? {
          opId: op10zeId,
          actionRunMode
        }
      : {
          opConfig: opCreationConfig,
          actionRunMode
        };

    return this.call<BizActionParamValuesResponse, BizActionParamValuesRequest>("post", subUrl, requestPayload);
  }

  getBizImpactConfig(impactConfigId: string) {
    const subUrl = `/op10ze/biz-impact/${impactConfigId}`;
    return this.call<GetBizImpactConfigResponse>("get", subUrl);
  }

  async getParamSuggestionsForParams(
    categoryId: ActionCategoryType,
    sourceTypeId: ActionSourceTypes,
    integrationActionConfigId: string,
    paramNames: string[],
    opCreationConfig: OpCreationConfig
  ) {
    const lookupValues: Record<string, ParamValue[]> = {};
    const messages: string[] = [];

    const promises = paramNames.map(async paramName => {
      const subUrl = `/op10ze/action/category/${categoryId}/sourceTypeId/${sourceTypeId}/config/${integrationActionConfigId}/params/${paramName}/values`;
      try {
        const { data, error, message } = await this.call<SingleParamValuesResponse, Op10zeSetupRequest>(
          "post",
          subUrl,
          {
            opCreationConfig
          }
        );

        if (!error && data?.value) {
          lookupValues[paramName] = data?.value;
        } else {
          const msg = `Error fetching values for ${paramName}: ${message || "No values found"}`;
          messages.push(msg);
        }
      } catch (err) {
        const msg = `Error fetching values for ${paramName}: ${err.toString()}`;
        messages.push(msg);
      }
    });

    await Promise.allSettled(promises);

    const message = messages.join(", ");
    return {
      data: lookupValues,
      error: Boolean(message),
      message
    };
  }

  testBizAction(
    op10zeId: string,
    opCreationConfig: OpCreationConfig,
    bizActionConfig: BizActionConfig,
    alertId?: string,
    alertSnapshot?: AlertSnapshot,
    alertTimeMillis?: number
  ) {
    const subUrl = op10zeId ? `/op10ze/${op10zeId}/action/test` : "/op10ze/action/test";
    return this.call<BizActionResponse, BizActionSetupRequest>("post", subUrl, {
      opConfig: opCreationConfig,
      bizActionConfig,
      alertId,
      alertSnapshot,
      alertTimeMillis
    });
  }

  getIncidentsData(
    opId: string,
    startTimeMillis: number,
    endTimeMillis: number,
    includeSnapshots = false,
    lookupData = false,
    additionalData = false,
    actions = false,
    generateDemoData = false,
    incidentType: IncidentType = IncidentType.real_time
  ) {
    const subUrl = `/op10ze/${opId}/fetch/incidents/data`;
    return this.call<AlertFilterResponse, FetchIncidentDataRequest>("post", subUrl, null, {
      startTimeMillis,
      endTimeMillis,
      includeSnapshots,
      lookupData,
      additionalData,
      generateDemoData,
      actions,
      incidentType
    });
  }

  getIncidentsDataForMultipleOp10ze(
    opIds: string[],
    startTimeMillis: number,
    endTimeMillis: number,
    includeSnapshots = false,
    lookupData = false,
    additionalData = false,
    actions = false,
    generateDemoData = false,
    incidentType: IncidentType = IncidentType.real_time
  ) {
    const subUrl = `/op10ze/fetch/incidents/data`;
    return this.call<AlertFilterResponses, AlertsRequestPayload>(
      "post",
      subUrl,
      { opIds },
      {
        startTimeMillis,
        endTimeMillis,
        includeSnapshots,
        lookupData,
        additionalData,
        generateDemoData,
        actions,
        incidentType
      }
    );
  }

  getIncidentsDataForOpConfig(
    opCreationConfig: OpCreationConfig,
    startTimeMillis: number,
    endTimeMillis: number,
    includeSnapshots = false,
    lookupData = false,
    additionalData = false,
    actions = false,
    generateDemoData = false,
    incidentType: IncidentType = IncidentType.real_time
  ) {
    const subUrl = `/op10ze/fetch/incidents/data`;
    return this.call<AlertFilterResponses, AlertsRequestPayload>(
      "post",
      subUrl,
      { opCreationConfig },
      {
        startTimeMillis,
        endTimeMillis,
        includeSnapshots,
        lookupData,
        additionalData,
        generateDemoData,
        actions,
        incidentType
      }
    );
  }

  getOp10zeSchema(
    opId: string,
    incidentId?: string,
    version?: string,
    simulationId?: string,
    generateDemoData?: boolean
  ) {
    const subUrl = simulationId
      ? `/op10ze/${opId}/simulation/${simulationId}/fetch/schema`
      : `/op10ze/${opId}/fetch/schema`;

    const payload: CompareQuerySchemaRequest = {};
    version && (payload.version = version);
    incidentId && !version && (payload.incidentId = incidentId);
    generateDemoData && (payload.generateDemoData = generateDemoData);

    return this.call<CompareQuerySchemaResponse, unknown>("post", subUrl, null, payload);
  }

  getOp10zeSchemaV2(
    opId: string,
    incidentId?: string,
    version?: string,
    simulationId?: string,
    generateDemoData?: boolean
  ) {
    const subUrl = simulationId
      ? `/op10ze/${opId}/simulation/${simulationId}/fetch/schema`
      : `/op10ze/${opId}/fetch/schema`;

    const payload: CompareQuerySchemaRequest = {
      v2Api: true
    };
    version && (payload.version = version);
    incidentId && !version && (payload.incidentId = incidentId);
    generateDemoData && (payload.generateDemoData = generateDemoData);

    return this.call<MonitoredDataSchema, unknown>("post", subUrl, null, payload);
  }

  getOp10zePreviewSchema(
    opSetupReq: Op10zeSetupRequest,
    startTimeMillis: number,
    endTimeMillis: number,
    demoDataParams?: DemoDataParams
  ) {
    const subUrl = `/op10ze/fetch/preview-schema`;
    const qPayload: OpPreviewDataRequest = {
      opSetupReq,
      startTimeMillis,
      endTimeMillis,
      selectorSpec: undefined
    };

    return this.call<CompareQuerySchemaResponse, OpPreviewDataRequest>("post", subUrl, qPayload, demoDataParams);
  }

  getOp10zePreviewSchemaV2(
    opSetupReq: Op10zeSetupRequest,
    startTimeMillis: number,
    endTimeMillis: number,
    demoDataParams?: DemoDataParams
  ) {
    const subUrl = `/op10ze/fetch/preview-schema/v2`;
    const qPayload: OpPreviewDataRequest = {
      opSetupReq,
      startTimeMillis,
      endTimeMillis,
      selectorSpec: undefined
    };

    return this.call<MonitoredDataSchema, OpPreviewDataRequest>("post", subUrl, qPayload, demoDataParams);
  }

  getInsightPreviewData(
    useCaseOpQueryConfig: UseCaseOpQueryConfig,
    selectorSpec?: SelectorSpec,
    demoDataParams?: DemoDataParams
  ) {
    const subUrl = `/op10ze/fetch/insight-preview-data`;
    const qPayload: InsightPreviewDataRequest = {
      opQueryConfig: useCaseOpQueryConfig,
      selectorSpec
    };

    return this.call<InsightPreviewDataResponse, InsightPreviewDataRequest>("post", subUrl, qPayload, demoDataParams);
  }

  getIncidentsCount(
    opId: string,
    startTimeMillis: number,
    endTimeMillis: number,
    timeIntervalMillis: number,
    simulationId?: string,
    generateDemoData?: boolean
  ) {
    const subUrl = !simulationId
      ? `/op10ze/${opId}/fetch/incidents/count`
      : `/op10ze/${opId}/simulation/${simulationId}/incidents/count`;
    return this.call<AlertCountResponse>("post", subUrl, null, {
      startTimeMillis,
      endTimeMillis,
      timeIntervalMillis,
      generateDemoData,
      incidentType: IncidentType.real_time
    });
  }

  getIncidentsCountForMultipleOp10ze(
    opIds: string[],
    startTimeMillis: number,
    endTimeMillis: number,
    timeIntervalMillis: number,
    generateDemoData?: boolean
  ) {
    const subUrl = `/op10ze/fetch/incidents/count`;
    return this.call<AlertCountResponses, AlertsRequestPayload>(
      "post",
      subUrl,
      { opIds },
      {
        startTimeMillis,
        endTimeMillis,
        timeIntervalMillis,
        generateDemoData,
        incidentType: IncidentType.real_time
      }
    );
  }

  getIncidentsCountForOpConfig(
    opCreationConfig: OpCreationConfig,
    startTimeMillis: number,
    endTimeMillis: number,
    timeIntervalMillis: number,
    generateDemoData?: boolean
  ) {
    const subUrl = `/op10ze/fetch/incidents/count`;
    return this.call<AlertCountResponses, AlertsRequestPayload>(
      "post",
      subUrl,
      { opCreationConfig },
      {
        startTimeMillis,
        endTimeMillis,
        timeIntervalMillis,
        generateDemoData,
        incidentType: IncidentType.real_time
      }
    );
  }

  runSimulation(simulationId: string, opSimulationRequest: OpSimulationRequest) {
    const subUrl = `/op10ze/simulation/${simulationId}`;
    return this.call<OpSimulationResponse, OpSimulationRequest>("post", subUrl, opSimulationRequest);
  }

  overrideOpWithSimulation(op10zeId: string, simulationId: string) {
    const subUrl = `/op10ze/${op10zeId}/simulation/${simulationId}`;
    return this.call<Op10zeSetupResponse, any>("post", subUrl, {});
  }

  getSimulationState(opId: string, simulationId: string) {
    const subUrl = `/op10ze/${opId}/simulation/${simulationId}/state`;
    return this.call<OpSimulationState, null>("post", subUrl, null);
  }

  getDefaultOpTriageConfig(opCreationConfig: OpCreationConfig, widgetId?: string) {
    const subUrl = `/op10ze/fetch/triage-default`;
    return this.call<OpTriageConfigResponse, Op10zeSetupRequest>("post", subUrl, {
      opCreationConfig,
      widgetId
    });
  }

  updateOpTriageConfig(opId: string, opTriageConfig: Partial<OpTriageConfig>) {
    const subUrl = `/op10ze/${opId}/triage-configs`;

    const payload: UpdateOpTriageRequest = {
      opTriageConfigType: [],
      ...opTriageConfig
    };

    opTriageConfig.diagnosticFields && payload.opTriageConfigType.push(OpTriageConfigType.CONTRIBUTING_FIELDS);
    opTriageConfig.impactFields && payload.opTriageConfigType.push(OpTriageConfigType.IMPACT_FIELDS);
    opTriageConfig.impactedWidgets && payload.opTriageConfigType.push(OpTriageConfigType.IMPACTED_WIDGETS);

    return this.call<Op10zeSetupResponse, UpdateOpTriageRequest>("post", subUrl, payload);
  }

  editExistingOperationalizeViaFragments(
    opId: string,
    opCreationConfig: OpCreationConfig,
    opFragment: OpFragment,
    fragmentEditType: OpCreationFragmentEditType
  ) {
    const subUrl = `/op10ze/${opId}/edit/fragments`;

    const payload: OpEditViaFragmentsRequest = {
      opCreationConfig,
      editType: fragmentEditType,
      opFragment
    };

    return this.call<Op10zeSetupResponse, OpEditViaFragmentsRequest>("post", subUrl, payload);
  }

  getUpdatedOperationalizeViaFragments(
    opCreationConfig: OpCreationConfig,
    opFragment: OpFragment,
    fragmentEditType: OpCreationFragmentEditType
  ) {
    const subUrl = `/op10ze/edit/fragments`;

    const payload: OpEditViaFragmentsRequest = {
      opCreationConfig,
      editType: fragmentEditType,
      opFragment
    };

    return this.call<Op10zeSetupResponse, OpEditViaFragmentsRequest>("post", subUrl, payload);
  }

  getInsightMembership(
    opConfigId: string,
    incidentId: string,
    startTimeMillis: number,
    endTimeMillis: number,
    generateDemoData = false
  ) {
    const subUrl = `/triage/getMembership/${opConfigId}/${incidentId}`;
    return this.call<InsightMemberShipResponse, null>("get", subUrl, null, {
      startTimeMillis,
      endTimeMillis,
      generateDemoData
    });
  }

  getInsightSummaryInfo(
    opConfigId: string,
    incidentId: string,
    startTimeMillis: number,
    endTimeMillis: number,
    projection: GetIncidentInfoRequest["projection"],
    generateDemoData = false
  ) {
    const subUrl = `/triage/getIncidentInfo`;
    const payload: GetIncidentInfoRequest = {
      opConfigId,
      incidentId,
      projection
    };

    return this.call<GetIncidentInfoResponse, GetIncidentInfoRequest>("post", subUrl, payload, {
      startTimeMillis,
      endTimeMillis,
      generateDemoData
    });
  }

  async getInstance() {
    await this.init();
    return this.datasource;
  }

  private async call<T, P = any>(
    method: "get" | "post" | "put" | "delete",
    subUrl: string,
    payload?: P,
    params?: Record<string, any>,
    cancelToken?: CancelToken
  ): Promise<Result<T>> {
    await this.init();

    const url = this.getUrl(subUrl);
    const result: Result<any> = {
      data: {},
      error: false,
      message: "",
      cancelled: false
    };

    const config: InceptionRequestConfig = {};
    params && (config.params = params);
    cancelToken && (config.cancelToken = cancelToken);

    const callbackPromise =
      method === "get"
        ? this.datasource.get<T, P>(url, payload, config)
        : method === "post"
          ? this.datasource.post<T, P>(url, payload, config)
          : method === "put"
            ? this.datasource.put<T, P>(url, payload, config)
            : this.datasource.delete<T>(url, config);

    try {
      const response = await callbackPromise;
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      const responseData = (err as any).response?.data || err.message || String(err);
      result.message = typeof responseData === "object" ? responseData?.msg || String(responseData) : responseData;
      result.cancelled = (err as any).cancelled;
    }

    return result;
  }

  private getUrl(subUrl: string) {
    // Super method adds / already
    subUrl = subUrl.startsWith("/") ? subUrl.slice(1) : subUrl;
    return this.getBizEntityUrl() + subUrl;
  }

  private sanitiseOpConfig(opCreationConfig: OpCreationConfig) {
    this.sanitizeLabels(opCreationConfig.labels);
    this.sanitizeLabels(opCreationConfig.opCreationConfigDef?.schedule?.labels);
    this.sanitizeLabels(opCreationConfig.opCreationConfigDef?.bizDataQuery?.labels);
  }

  private sanitizeLabels(labels: Record<string, string>) {
    Object.keys(labels || {}).forEach(key => {
      if (isNil(labels[key])) {
        delete labels[key];
      }
    });
  }
}

export const operationaliseV2ApiService = new OperationaliseV2ApiService();
