import { useCallback, useMemo } from "react";
import { FetchCallBackResult, FetchFn, InitialState, logger, useDataFetch } from "../../../../core";
import { ResponseStatus, Workflow } from "../../explore";
import { operationaliseV2ApiService } from "../OperationaliseV2Api";

export const useFetchWorkFlowForOp = (opId: string, incidentId: string, disableAutoFetch = false) => {
  const initialState = useMemo<InitialState<Workflow, string>>(
    () => ({
      data: null,
      error: null,
      isError: false,
      isFetching: !disableAutoFetch,
      isSuccess: false
    }),
    [disableAutoFetch]
  );

  const fetchFn = useCallback<FetchFn<Workflow, string>>(async () => {
    const result: FetchCallBackResult<Workflow, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const { data, error, message } = await operationaliseV2ApiService.fetchWorkFlowForOp(opId, incidentId);
      if (error) {
        result.isError = error;
        result.isSuccess = !error;
        result.error = error ? message : null;
      } else {
        const { responseInfo, workflow } = data || {};
        if (responseInfo.status === ResponseStatus.SUCCESS) {
          result.data = workflow;
          result.isSuccess = true;
          result.isError = false;
        } else {
          result.isError = true;
          result.isSuccess = false;
          result.error = responseInfo.errors?.[0]?.message || "Error fetching workflow";
        }
      }
    } catch (err: any) {
      logger.error("useFetchWorkFlow", "Error fetching workflow", err);
      result.isError = true;
      result.error = err?.message?.toString() || err?.toString();
    }

    return result;
  }, [opId, incidentId]);

  return useDataFetch(fetchFn, initialState, disableAutoFetch);
};
